/* eslint-disable react/display-name */

import i18n from "i18n";
import moment from "moment";

const LiquidWithViegoColumn = {};
const POINT_TO_MONEY = 3;

const renderDataValue = (value, isBold = false) => {
  const style = value ? {} : { color: "#c0c0c0" };
  if (isBold) {
    style.fontWeight = "bold";
  }
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

export const renderStatusValue = (value) => {
  let color = "#7C590A";
  let text = "Đang chờ";
  switch (value) {
    case "pending":
      color = "#7C590A";
      text = "Đang chờ";
      break;

    case "success":
      color = "#00A206";
      text = "Hoàn tất";
      break;

    case "cancelled":
      color = "#FF0000";
      text = "Đã hủy";
      break;

    default:
      break;
  }
  return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
};

LiquidWithViegoColumn.id = {
  dataIndex: "transaction",
  key: "_id",
  title: "#",
  render: (transaction) => renderDataValue(transaction._id),
  width: 120,
};

LiquidWithViegoColumn.createdAt = {
  title: "Thời điểm",
  dataIndex: "transaction",
  key: "createdAt",
  render: (transaction) =>
    renderDataValue(moment(transaction.createdAt).format("HH:MM DD/MM/YYYY")),
  width: 150,
};

LiquidWithViegoColumn.account = {
  title: "Tài khoản",
  dataIndex: "user",
  key: "name",
  render: (user) => renderDataValue(user.name),
  width: 200,
};

LiquidWithViegoColumn.email = {
  title: "Email",
  dataIndex: "user",
  key: "email",
  render: (user) => renderDataValue(user?.email),
};

LiquidWithViegoColumn.phone = {
  title: "SĐT",
  dataIndex: "user",
  key: "phone",
  render: (user) => renderDataValue(user?.phone),
};

LiquidWithViegoColumn.bankName = {
  title: "Ngân hàng",
  dataIndex: "transaction",
  key: "bankName",
  render: (transaction) =>
    renderDataValue(transaction?.metaData?.bankInfo?.bankName),
};

LiquidWithViegoColumn.branchName = {
  title: "Chi nhánh",
  dataIndex: "transaction",
  key: "branchName",
  render: (transaction) =>
    renderDataValue(transaction?.metaData?.bankInfo?.branchName),
};

LiquidWithViegoColumn.accountNumber = {
  title: "STK ngân hàng",
  dataIndex: "transaction",
  key: "accountNumber",
  render: (transaction) =>
    renderDataValue(transaction?.metaData?.bankInfo?.accountNumber),
};

LiquidWithViegoColumn.points = {
  title: "Số điểm",
  dataIndex: "transaction",
  key: "amount",
  render: (transaction) => renderDataValue(transaction?.amount, true),
};
LiquidWithViegoColumn.viegoPointToMoney = {
  title: `Số tiền qui đổi (${POINT_TO_MONEY} điểm 1 JPY)`,
  dataIndex: "transaction",
  key: "amount",
  render: (transaction) =>
    renderDataValue(
      `${(transaction?.amount / POINT_TO_MONEY)?.toLocaleString()} JPY`
    ),
};

LiquidWithViegoColumn.state = {
  title: "Trạng thái",
  dataIndex: "transaction",
  key: "state",
  render: (transaction) => renderStatusValue(transaction.status),
};
export default LiquidWithViegoColumn;
