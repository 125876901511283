import { Modal, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import _get from "lodash/get";
import {
  setPriceModalData,
  getProductListRequest,
  setProductsPriceRequest,
} from "providers/ProductProvider/slice";
import { Formik, Form } from "formik";

import "./styles.less";
import { TextInput, RadioField } from "components/common/Form";

const SetPriceModal = () => {
  const priceModalData = useSelector((state) =>
    _get(state, "product.priceModalData", {})
  );
  const selectedRowKeys = useSelector((state) =>
    _get(state, "product.selectedRowKeys", [])
  );
  const dispatch = useDispatch();
  const [query] = useQuery();

  const handleCloseModal = () => {
    dispatch(
      setPriceModalData({
        visible: false,
      })
    );
  };

  const handleSubmit = (values) => {
    dispatch(
      setProductsPriceRequest({
        productIds: selectedRowKeys,
        ...values,
      })
    ).then(() => {
      dispatch(getProductListRequest(query));
      handleCloseModal();
    });
  };

  return (
    <Modal
      onCancel={handleCloseModal}
      title="Tăng/giảm giá"
      visible={
        _get(priceModalData, "visible", false) && selectedRowKeys.length > 0
      }
      width={442}
      className="set-price-modal"
      footer={null}
      destroyOnClose
    >
      <Formik
        validateOnMount
        initialValues={{
          type: "increase",
          value: 0,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <RadioField
              name="type"
              options={[
                {
                  label: "Tăng giá",
                  value: "increase",
                },
                {
                  label: "Giảm giá",
                  value: "decrease",
                },
              ]}
            />
            <TextInput type="number" name="value" suffix="JPY" min="1" />
            <div className="submit-wrapper">
              <Button htmlType="submit" type="primary">
                Xác nhận
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default SetPriceModal;
