import { Row, Typography, Menu, Dropdown, Button, Tabs } from "antd";
import _get from "lodash/get";
import { CommentAndRatingColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import { setSelectedBusinessIds } from "providers//AdminManagerProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import "./styles.less";
import i18n from "i18n";
import {
  getListCommentRatingRequest,
  deleteRatingRequest,
  getUnreadRatingRequest,
  setCommentRatingModal,
  getRatingDetailRequest,
  setReadRatingRecordRequest,
} from "providers/CommentAndRatingProvider/slice";
import { IconSeeMore } from "assets/images";
import TabHeader from "components/TabHeader";
import { useEffect } from "react";
import SearchBar from "./SearchBar";
import CommentAndRatingDetail from "./CommentAndRatingDetail";

const { TabPane } = Tabs;
const CommentAndRating = () => {
  const dispatch = useDispatch();

  const ratingList = useSelector((state) => _get(state, "ratingComment.docs"));
  const ratingTotal = useSelector((state) =>
    _get(state, "ratingComment.totalDocs")
  );
  const unreadSummary = useSelector((state) =>
    _get(state, "ratingComment.unreadSummary")
  );
  const totalRating = useSelector((state) =>
    _get(state, "ratingComment.totalRating")
  );
  const isLoading = useSelector((state) =>
    _get(state, "ratingComment.isLoading")
  );
  const selectedRowKeys = useSelector((state) =>
    _get(state, "ratingComment.selectedRowKeys", [])
  );
  const unreadRating = {
    All: totalRating,
    1: unreadSummary.filter((item) => item.type === 1)[0]?.count ?? 0,
    2: unreadSummary.filter((item) => item.type === 2)[0]?.count ?? 0,
    3: unreadSummary.filter((item) => item.type === 3)[0]?.count ?? 0,
    4: unreadSummary.filter((item) => item.type === 4)[0]?.count ?? 0,
    5: unreadSummary.filter((item) => item.type === 5)[0]?.count ?? 0,
  };
  const [searchObject, setSearch] = useQuery(getListCommentRatingRequest);
  useEffect(() => {
    dispatch(getUnreadRatingRequest());
  }, [dispatch]);

  const handleSeeDetailReport = (record) => {
    if (!record.isReadByAdmin) {
      dispatch(setReadRatingRecordRequest({ idPost: record._id }))
        .then(() => dispatch(getUnreadRatingRequest()))
        .then(() => dispatch(getListCommentRatingRequest(searchObject)));
    }
    dispatch(getRatingDetailRequest({ idPost: record._id }));
    dispatch(
      setCommentRatingModal({
        visible: true,
        data: record,
      })
    );
  };
  const menu = (record) => {
    const { _id } = record;
    return (
      <Menu>
        <Menu.Item onClick={() => handleSeeDetailReport(record)}>
          {i18n.t("reportManager.seeDetail")}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            dispatch(
              setPopup({
                isOpen: true,
                data: {
                  title: "Xác nhận Xoá Đánh giá ?",
                  content: "Xác nhận xoá đánh giá này",
                  onOk: () =>
                    dispatch(
                      deleteRatingRequest({
                        idPost: _id,
                      })
                    ).then(() => {
                      dispatch(getListCommentRatingRequest(searchObject));
                      dispatch(getUnreadRatingRequest());
                    }),
                },
              })
            )
          }
        >
          <div style={{ color: "red", fontWeight: "bolder" }}>
            {i18n.t("ratingComment.deleteComment")}
          </div>
        </Menu.Item>
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );
  const columns = [
    CommentAndRatingColumn._id,
    CommentAndRatingColumn.username,
    CommentAndRatingColumn.storeName,
    CommentAndRatingColumn.type,
    CommentAndRatingColumn.address,
    CommentAndRatingColumn.phone,
    CommentAndRatingColumn.rating,
    {
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      dispatch(setSelectedBusinessIds({ record, selected, selectedRows }));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      dispatch(setSelectedBusinessIds({ selected, selectedRows, changeRows }));
    },
  };
  const checkingUnreadRating = (isReadByAdmin) => {
    if (isReadByAdmin) {
      return "";
    }
    return "status-new";
  };

  return (
    <div className="register-list-wrapper">
      <CommentAndRatingDetail setCommentRatingModal={setCommentRatingModal} />
      <Row className="register-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("ratingComment.listTitle")}
        </Typography.Title>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Tabs
          className="cosmetic-tabs-container"
          activeKey={searchObject.mine}
          type="card"
          style={{ marginTop: 12 }}
          onChange={(key) => {
            setSearch(
              {
                rating: key,
                keyword: searchObject.keyword,
              },
              true
            );
          }}
        >
          {["All", 5, 4, 3, 2, 1].map((value) => (
            <TabPane
              className="status-tab-pane"
              tab={
                <TabHeader
                  unreadNumbers={unreadRating[value]}
                  status={value}
                  total={100}
                />
              }
              key={value}
            >
              <Table
                rowSelection={rowSelection}
                scroll={{ x: 1000 }}
                loading={isLoading}
                bordered
                rowClassName={(data) =>
                  checkingUnreadRating(data.isReadByAdmin)
                }
                columns={columns}
                dataSource={ratingList}
                rowKey={(data) => data._id}
                total={ratingTotal}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default CommentAndRating;
