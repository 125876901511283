import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    currentUser: {},
    userDetail: {},
    otpScreen: false,
    confirmation: null,
    recaptcha: null,
    isLoadingFb: false,
    isLoadingGoogle: false,
    isLoadingApple: false,
    resources: {},
    userDetailById: {},
  },
  reducers: {
    getResourceRequest(state) {
      return { ...state, isLoading: true };
    },
    getResourceSuccess(state, action) {
      const { payload = {} } = action;
      return { ...state, isLoading: true, resources: payload };
    },
    loginRequest(state) {
      return { ...state, isLoading: true };
    },
    loginSuccess(state, action) {
      return { ...state, isLoading: false, currentUser: action.payload };
    },
    stopLoading(state) {
      return { ...state, isLoading: false };
    },
    getCurrentUserRequest(state) {
      return { ...state, isLoading: true };
    },
    getCurrentUserSuccess(state, action) {
      return { ...state, currentUser: action.payload };
    },
    logoutRequest(state) {
      return { ...state, isLoading: true };
    },
    getUserDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getUserDetailSuccess(state, action) {
      return { ...state, userDetail: action.payload };
    },
    getUserDetailbyIdRequest(state) {
      return { ...state, isLoading: true };
    },
    getUserDetailbyIdSuccess(state, action) {
      return { ...state, userDetailById: action.payload };
    },
    resetUserDetail(state) {
      return {
        ...state,
        userDetail: {},
      };
    },
    showOtpScreen(state, action) {
      return { ...state, showOtpScreen: action.payload, isLoading: false };
    },
    setConfirmation(state, action) {
      return { ...state, confirmation: action.payload };
    },
    setRecaptcha(state, action) {
      return { ...state, recaptcha: action.payload };
    },
    resendOtpRequest(state) {
      return { ...state, isLoading: true };
    },
    resendOtpSuccess(state) {
      return { ...state, isLoading: false };
    },
    superAdminLoginRequest(state) {
      return { ...state, isLoading: true };
    },
    superAdminLoginSuccess(state) {
      return { ...state, isLoading: false };
    },
    loginFacebookRequest(state) {
      return { ...state, isLoadingFb: true };
    },
    loginFacebookSuccess(state) {
      return { ...state, isLoadingFb: false };
    },
    loginGoogleRequest(state) {
      return { ...state, isLoadingGoogle: true };
    },
    loginGoogleSuccess(state) {
      return { ...state, isLoadingGoogle: false };
    },
    loginAppleRequest(state) {
      return { ...state, isLoadingApple: true };
    },
    loginAppleSuccess(state) {
      return { ...state, isLoadingApple: false };
    },
  },
});

export const {
  getResourceSuccess,
  getResourceRequest,
  getUserDetailbyIdRequest,
  getUserDetailbyIdSuccess,
  loginRequest,
  loginSuccess,
  getCurrentUserRequest,
  stopLoading,
  getCurrentUserSuccess,
  logoutRequest,
  getUserDetailRequest,
  getUserDetailSuccess,
  resetUserDetail,
  showOtpScreen,
  setRecaptcha,
  setConfirmation,
  resendOtpRequest,
  resendOtpSuccess,
  superAdminLoginRequest,
  superAdminLoginSuccess,
  loginFacebookRequest,
  loginFacebookSuccess,
  loginGoogleRequest,
  loginGoogleSuccess,
  loginAppleRequest,
  loginAppleSuccess,
} = authSlice.actions;

export default authSlice.reducer;
