import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";

const SupportSlice = createSlice({
  name: "support",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    detail: {},
    isCreating: false,
    isLocking: false,
    allSupportUnread: 0,
    errorReportUnread: 0,
    feedbackUnread: 0,
    reportUnread: 0,
    supportRequestUnread: 0,
  },
  reducers: {
    getSupportListRequest(state) {
      return { ...state, isLoading: true };
    },
    getSupportListSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs,
      };
    },
    setSelectedIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    getSupportDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getSupportDetailSuccess(state, action) {
      return { ...state, isLoading: false, detail: action.payload };
    },
    resetSupportDetail(state) {
      return { ...state, detail: {} };
    },
    getCountNotReadSupportRequest(state) {
      return { ...state, isLoading: true };
    },
    getCountNotReadSupportSuccess(state, action) {
      const {
        errorReport = 0,
        feedback = 0,
        report = 0,
        supportRequest = 0,
      } = action.payload;
      const unreadSupportNumber =
        Number(errorReport) +
        Number(feedback) +
        Number(report) +
        Number(supportRequest);
      return {
        ...state,
        isLoading: false,
        allSupportUnread: unreadSupportNumber,
        errorReportUnread: errorReport,
        feedbackUnread: feedback,
        reportUnread: report,
        supportRequestUnread: supportRequest,
      };
    },
    deleteSupportRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    deleteSupportSuccess: {
      reducer: (state) => ({
        ...state,
        selectedRowKeys: [],
        selectedRows: [],
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isSettingTag: false };
    },
  },
});

export const {
  setSelectedIds,
  getSupportListRequest,
  getSupportListSuccess,
  stopLoading,
  getSupportDetailRequest,
  getSupportDetailSuccess,
  resetSupportDetail,
  getCountNotReadSupportRequest,
  getCountNotReadSupportSuccess,
  deleteSupportRequest,
  deleteSupportSuccess,
} = SupportSlice.actions;

export default SupportSlice.reducer;
