import { Row, Typography, Button, Dropdown, Menu } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _uniq from "lodash/uniq";
import { useNavigate, Link } from "react-router-dom";

import { VisaColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import {
  getAllVisaRequest,
  setSelectedVisaIds,
  updateStatusVisaRequest,
} from "providers/VisaProvider/slice";

import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import SearchBar from "./SearchBar";

const VisaList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const agentList = useSelector((state) => _get(state, "visa.docs"));
  const agentTotal = useSelector((state) => _get(state, "visa.totalDocs"));
  const isLoading = useSelector((state) => _get(state, "visa.isLoading"));
  const userId = useSelector((state) => _get(state, "auth.currentUser._id"));
  const selectedRowKeys = useSelector((state) =>
    _get(state, "visa.selectedRowKeys", [])
  );
  const selectedRows = useSelector((state) =>
    _get(state, "visa.selectedRows", [])
  );
  const isDisableApproveBtn = !!selectedRows.find(
    (item) => item.status === "approved"
  );
  const isDisableRefuseBtn = !!selectedRows.find(
    (item) => item.status === "refuse"
  );
  const VisaIdList = [];
  selectedRows.map((item) => VisaIdList.push(item.VisaId));

  const [searchObject] = useQuery(getAllVisaRequest, {
    arrayParams: ["status"],
  });

  const menuAll = () => (
    <Menu>
      <Menu.Item
        disabled={isDisableApproveBtn || _isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            updateStatusVisaRequest({
              status: "approved",
              idVisa: selectedRowKeys,
              VisaId: _uniq(VisaIdList),
            })
          ).then(() => dispatch(getAllVisaRequest(searchObject)))
        }
      >
        {i18n.t("visacreen.publishVisa")}
      </Menu.Item>
      <Menu.Item
        disabled={isDisableRefuseBtn || _isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            updateStatusVisaRequest({
              status: "refuse",
              idVisa: selectedRowKeys,
              VisaId: _uniq(VisaIdList),
            })
          ).then(() => dispatch(getAllVisaRequest(searchObject)))
        }
      >
        {i18n.t("visacreen.unPublishVisa")}
      </Menu.Item>
    </Menu>
  );
  const menu = (record) => {
    const { _id, VisaId } = record;
    return (
      <Menu>
        <Menu.Item>
          <Link to={`/visa/${_id}`} state={{ canGoBack: true }}>
            {i18n.t("visacreen.newDetail")}
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={record.status === "approved"}
          onClick={() =>
            dispatch(
              updateStatusVisaRequest({
                status: "approved",
                idVisa: [_id],
                VisaId: [VisaId],
              })
            ).then(() => dispatch(getAllVisaRequest(searchObject)))
          }
        >
          {i18n.t("visacreen.publishVisa")}
        </Menu.Item>
        <Menu.Item
          disabled={record.status === "refuse"}
          onClick={() =>
            dispatch(
              updateStatusVisaRequest({
                status: "refuse",
                idVisa: [_id],
                VisaId: [VisaId],
              })
            ).then(() => dispatch(getAllVisaRequest(searchObject)))
          }
        >
          {i18n.t("visacreen.unPublishVisa")}
        </Menu.Item>
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedVisaIds({ record, selected, selectedRows: _selectedRows })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedVisaIds({
          selected,
          selectedRows: _selectedRows,
          changeRows,
        })
      );
    },
  };

  const columns = [
    VisaColumn.id,
    VisaColumn.createdAt,
    VisaColumn.title,
    VisaColumn.username,
    VisaColumn.status,
    {
      title: (
        <Dropdown overlay={menuAll} placement="bottomLeft" arrow>
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  return (
    <div className="artcle-list-wrapper">
      <Row className="artcle-list-header" justify="space-between">
        <Typography.Title level={4}>Danh sách Visa</Typography.Title>
        <Button
          size="large"
          type="primary"
          onClick={() =>
            navigate("/visa/visa-create", { state: { canGoBack: true } })
          }
        >
          Đăng bài
        </Button>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Table
          rowSelection={rowSelection}
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={agentList}
          rowKey={(data) => data._id}
          total={agentTotal}
          rowClassName={(data) =>
            _get(data, "seen", []).includes(userId)
              ? "visa-readed"
              : "visa-unread"
          }
          onRow={(record) => ({
            onDoubleClick: () => {
              navigate(`/visa/${record._id}`, { state: { canGoBack: true } });
            },
          })}
        />
      </div>
    </div>
  );
};

export default VisaList;
