/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import { Upload, Button } from "antd";
import { LoadingOutlined, CameraOutlined } from "@ant-design/icons";
import UploadIcon from "assets/images/upload.svg";
import _isEmpty from "lodash/isEmpty";
import ImgCrop from "antd-img-crop";

const BannerImage = (props) => {
  const {
    handleRemove,
    customRequest,
    beforeUpload,
    uploadRef,
    isLoading,
    image,
    aspect,
    disabled,
  } = props;

  return (
    <div className="banner-image-wrapper">
      <ImgCrop grid rotate aspect={aspect}>
        <Upload
          listType="picture-card"
          fileList={image}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onRemove={handleRemove}
          customRequest={customRequest}
          disabled={disabled}
        >
          <div ref={uploadRef} className={"upload-iamge-wrapper"}>
            {isLoading ? (
              <LoadingOutlined />
            ) : !_isEmpty(image) ? (
              image.map((img) => (
                <img
                  className="uploaded-image"
                  key={img.uid}
                  alt=""
                  src={img.url}
                />
              ))
            ) : (
              <img src={UploadIcon} alt="" />
            )}
          </div>
        </Upload>
      </ImgCrop>
      <Button
        disabled={disabled}
        className="open-file-btn"
        onClick={() => uploadRef.current.click()}
      >
        <CameraOutlined />
      </Button>
    </div>
  );
};

BannerImage.propTypes = {
  handleRemove: PropTypes.func,
  customRequest: PropTypes.func,
  beforeUpload: PropTypes.func,
  uploadRef: PropTypes.object,
  isLoading: PropTypes.bool,
  image: PropTypes.object,
  aspect: PropTypes.number,
  disabled: PropTypes.bool,
};
export default BannerImage;
