import { Modal, Row, Col, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import { LeftArrowIcon } from "assets/images";
import { getWifiRegistrationDetailSuccess } from "providers/SimProvider/slice";
import "./styles.less";

const RegistrationWifiDetailModal = () => {
  const dispatch = useDispatch();
  const { wifiRegistrationDetail } = useSelector((state) => state.sim);

  const handleCloseModal = () => {
    dispatch(getWifiRegistrationDetailSuccess({}));
  };

  return (
    <Modal
      visible={!_isEmpty(wifiRegistrationDetail)}
      footer={null}
      closable={false}
      centered
      width={1228}
      className="sim-registration-modal-wrapper"
    >
      <Row align="middle" className="modal-header">
        <Col>
          <img
            onClick={handleCloseModal}
            className="icon-close"
            src={LeftArrowIcon}
            alt=""
          />
        </Col>
        <Col>Xem yêu cầu đăng ký Wifi</Col>
      </Row>
      <Row className="modal-content" wrap={false} justify="space-between">
        <Col className="sim-detail" span={14}>
          <div className="detail-content">
            <span className="label">Họ và tên tiếng Nhật:</span>
            <span className="content">
              {_get(wifiRegistrationDetail, "japaneseName", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Họ và tên tiếng Việt:</span>
            <span className="content">
              {_get(wifiRegistrationDetail, "vietnameseName", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Email:</span>
            <span className="content">
              {_get(wifiRegistrationDetail, "email", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Giới tính:</span>
            <span className="content">
              {_get(wifiRegistrationDetail, "gender", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Ngày sinh:</span>
            <span className="content">
              {_get(wifiRegistrationDetail, "dateOfBirth", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Địa chỉ:</span>
            <span className="content">
              {_get(wifiRegistrationDetail, "address", "")}
            </span>
          </div>
        </Col>
        <Col className="sim-detail" span={14}>
          <div className="detail-content">
            <span className="label">Số điện thoại:</span>
            <span className="content">
              {_get(wifiRegistrationDetail, "phoneNumber", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Mã bưu điện</span>
            <span className="content">
              {_get(wifiRegistrationDetail, "postalCode", "")}
            </span>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default RegistrationWifiDetailModal;
