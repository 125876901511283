import { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Select, Form, Empty, Avatar } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import i18n from "i18n";
import DropDownIcon from "assets/images/caret-down-dark.svg";
import TrashIcon from "assets/images/trash-icon.svg";

const { Option } = Select;

const SelectMultiTypeBanner = forwardRef(
  (
    {
      label,
      maxLength,
      size = "large",
      options = [],
      className = "",
      disabled,
      placeholder = "",
      required,
      prefix,
      name,
      showError = true,
      helpComponent = null,
      onChange,
      isRemove = false,
      handleRemoveItem,
      ...props
    },
    ref
  ) => {
    const [field, meta, helper] = useField(name);
    const isError = meta.touched && meta.error;

    const handleChange = (data) => {
      helper.setValue(data);
      if (typeof onChange === "function") {
        onChange(data);
      }
    };

    const horizontalBannerList = [
      "home_1",
      "home_2",
      "home_3",
      "community_1",
      "community_2",
      "sim",
      "sim_2",
      "fonehouse",
    ];
    const verticleBannerList = ["buy_now", "hot"];

    const handleRemoveOtion = (e, option) => {
      e.stopPropagation();
      handleRemoveItem(option);
    };

    const _renderOption = (option) => {
      let optionIcon;
      let optionId = option;
      let optionName = option;
      const valueOptionlist = _get(field, "value", []);
      if (typeof option === "object") {
        optionIcon = _get(option, "icon");
        optionId = _get(option, "value");
        optionName = _get(option, "label", "");
      }
      return (
        <Option
          value={optionId}
          key={optionId}
          label={optionName}
          disabled={
            !_isEmpty(valueOptionlist) &&
            !(
              horizontalBannerList.includes(optionId) &&
              horizontalBannerList.includes(valueOptionlist[0])
            ) &&
            !(
              verticleBannerList.includes(optionId) &&
              verticleBannerList.includes(valueOptionlist[0])
            )
          }
        >
          {
            <>
              {optionIcon && (
                <Avatar
                  size={24}
                  src={optionIcon}
                  style={{ marginRight: "9px" }}
                />
              )}
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className={optionIcon ? "icon-option" : "no-icon-option"}
              >
                {optionName}{" "}
                {horizontalBannerList.includes(optionId) ? " (Ngang)" : "(Dọc)"}
                {isRemove && (
                  <img
                    width="20px"
                    src={TrashIcon}
                    alt=""
                    onClick={(e) => handleRemoveOtion(e, option)}
                  />
                )}
              </div>
            </>
          }
        </Option>
      );
    };

    return (
      <div
        className={`input-container select-multiple-type-container ${className} ${
          disabled ? "disabled-input" : ""
        }`}
      >
        {label && (
          <div className="label-wrapper">
            <>{label}</>
            {required && <div className="required-mark">*</div>}
          </div>
        )}
        <Form.Item
          validateStatus={isError ? "error" : ""}
          help={
            showError ? (
              <div className="helper-wrapper">
                <div className="error-text">
                  {isError &&
                    meta.error !== i18n.t("message.required") &&
                    meta.error}
                </div>
              </div>
            ) : (
              helpComponent
            )
          }
        >
          <Select
            {...field}
            {...props}
            size={size}
            ref={ref}
            disabled={disabled}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={() => helper.setTouched(true)}
            suffixIcon={<img src={DropDownIcon} />}
            optionLabelProp="label"
            mode="multiple"
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={i18n.t("errors.noData")}
              />
            }
          >
            <>{options.map((option) => _renderOption(option))}</>
          </Select>
        </Form.Item>
      </div>
    );
  }
);
SelectMultiTypeBanner.displayName = "SelectMultiTypeBanner";

SelectMultiTypeBanner.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  inputType: PropTypes.string,
  size: PropTypes.string,
  allowClear: PropTypes.bool,
  options: PropTypes.array,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  prefix: PropTypes.any,
  name: PropTypes.string.isRequired,
  showError: PropTypes.bool,
  helpComponent: PropTypes.any,
  onChange: PropTypes.func,
  isRemove: PropTypes.bool,
  handleRemoveItem: PropTypes.func,
};

export default memo(SelectMultiTypeBanner);
