import { memo } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import Spin from "antd/lib/spin";
import Row from "antd/lib/row";
import ConversationCell from "./ConversationCell";

import "./styles.less";

const ConversationList = ({
  conversationIds,
  conversationEntities,
  onConversationCellClick,
  activeConversationId,
  getConversationList,
  hasMore = true,
}) => (
  <div
    className="conversation-list-container"
    id="scrollable-conversation-list"
  >
    <InfiniteScroll
      scrollThreshold="100px"
      dataLength={conversationIds.length} // This is important field to render the next data
      next={getConversationList}
      hasMore={hasMore}
      loader={
        <Row style={{ padding: 8 }} justify="center">
          <Spin />
        </Row>
      }
      scrollableTarget="scrollable-conversation-list"
    >
      {conversationIds.map((id) => (
        <ConversationCell
          className={activeConversationId === id ? "active-conversation" : ""}
          onClick={() => onConversationCellClick(id)}
          key={id}
          data={conversationEntities[id]}
        />
      ))}
    </InfiniteScroll>
  </div>
);

ConversationList.propTypes = {
  conversationIds: PropTypes.array.isRequired,
  conversationEntities: PropTypes.object.isRequired,
  onConversationCellClick: PropTypes.func.isRequired,
  activeConversationId: PropTypes.string.isRequired,
  getConversationList: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export default memo(ConversationList);
