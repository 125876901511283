import { object, string } from "yup";
import i18n from "i18n";

export const loginByMailValidate = object().shape({
  password: string().trim().required(i18n.t("errors.required")),
  email: string().trim().required(i18n.t("errors.required")),
});

export const loginByPhoneValidate = object().shape({
  password: string().trim().required(i18n.t("errors.required")),
  phone: string().trim().required(i18n.t("errors.required")),
});
