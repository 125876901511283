import { useEffect } from "react";
import moment from "moment";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { Empty, Button } from "antd";
import i18n from "i18n";
import { ROLES_LIST } from "utils/constants";
import {
  getUserDetailRequest,
  resetUserDetail,
} from "providers/AuthProvider/slice";
import { createConversationRequest } from "providers/MessengerProvider/slice";
import { useDispatch, useSelector } from "react-redux";
import {
  UserIcon,
  IconEarth,
  IconBirthdayCycle,
  IconHeart,
  IconHeartCycle,
  IconHome,
  IconMailCycle,
  IconPhoneCycle,
  IconUserCycle,
  IconWork,
  EmptyUser,
  MessageIcon,
} from "assets/images";
import BackButton from "components/BackButton";
import { useParams, useNavigate } from "react-router-dom";

import "./style.less";

const UserInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => _get(state, "auth.userDetail"));
  const isCreatingConversation = useSelector((state) =>
    _get(state, "messenger.isCreatingConversation")
  );
  const {
    avatar,
    idUser = {},
    placeLive = [],
    education = [],
    relationshipStatus = {},
    groups = [],
    jobs = [],
    familyMembers = [],
    basicInfo = {},
  } = userDetail;
  const { name, email, phone, roles = [] } = idUser;
  const { birthday, sex } = basicInfo;
  const formatBirthday = `${moment(birthday).format("DD")} tháng ${moment(
    birthday
  ).format("MM")}, ${moment(birthday).format("YYYY")}`;
  const infoContactEmpty =
    _isEmpty(phone) && _isEmpty(email) && _isEmpty(basicInfo);
  const infoIntroductionEmpty =
    _isEmpty(jobs) &&
    _isEmpty(placeLive) &&
    _isEmpty(education) &&
    _isEmpty(relationshipStatus);

  useEffect(() => {
    dispatch(getUserDetailRequest({ idUser: id }));
  }, [id, dispatch]);

  useEffect(() => {
    if (id !== idUser._id) {
      dispatch(resetUserDetail());
    }
  }, [id, idUser._id, dispatch]);

  const handleCreateConversation = () => {
    dispatch(createConversationRequest({ receiverId: id })).then((result) => {
      navigate(`/fonehouse/messengers/${result._id}`);
    });
  };

  const renderUserInfoBasic = () => (
    <div className="user-info-basic-wrapper">
      <div className="info-basic-title">
        {i18n.t("titleUserInfo.basicInfomation")}
      </div>
      <div className="info-basic-detail">
        <div className="user-avatar">
          <img src={avatar || UserIcon} alt="" />
        </div>
        <div className="user-info">
          <div className="user-name">
            {name}
            <Button
              loading={isCreatingConversation}
              onClick={handleCreateConversation}
              className="message-btn"
              icon={<img src={MessageIcon} alt="" />}
              alt=""
            />
          </div>
          {email && (
            <div className="user-contact">Contact for work: {email}</div>
          )}
        </div>
      </div>
    </div>
  );

  const renderUserInfoFollower = () => (
    <div className="user-info-follower-wrapper">
      <div className="info-follower-title">
        {i18n.t("titleUserInfo.followers")}
      </div>
      <div className="info-follower-number">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    </div>
  );

  const renderUserInfoDecentralization = () => (
    <div className="user-info-decentralization-wrapper">
      <div className="info-decentralization-title">
        {i18n.t("titleUserInfo.decentralization")}
      </div>
      <div className="info-decentralization-list">
        {!_isEmpty(roles) ? (
          <>
            {roles.map((role, index) => (
              <div key={index} className="item-role">
                <div className={`type-role ${role}-role`}></div>
                <div className="type-text">
                  {ROLES_LIST.find((item) => item.value === role)?.label}{" "}
                </div>
              </div>
            ))}
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </div>
  );

  const renderUserInfoIntroduction = () => (
    <div className="user-info-introdution-wrapper">
      <div className="info-introdution-title">
        {i18n.t("titleUserInfo.introduce")}
      </div>
      <div className="info-introdution-detail">
        {infoIntroductionEmpty ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <>
            {!_isEmpty(jobs) &&
              jobs.map((job) => (
                <div key={job.id} className="info-introduction-content">
                  <img className="icon-introduction" src={IconWork} alt="" />{" "}
                  Làm việc tại {job.workplace}
                </div>
              ))}
            {!_isEmpty(placeLive) && (
              <div className="info-introduction-content">
                <img className="icon-introduction" src={IconHome} alt="" />
                Sống tại
                {placeLive.map((item, index) => (
                  <div key={item.id} className="high-line-info">
                    {`${index !== 0 ? "," : ":"} ${item.address}`}{" "}
                  </div>
                ))}
              </div>
            )}
            {!_isEmpty(relationshipStatus) && (
              <div className="info-introduction-content">
                <img className="icon-introduction" src={IconHeart} alt="" />
                <div className="high-line-info">
                  {" "}
                  {relationshipStatus.relationshipStatus}
                </div>
              </div>
            )}
            {!_isEmpty(education) &&
              education.map((item) => (
                <div key={item.id} className="info-introduction-content">
                  <img className="icon-introduction" src={IconEarth} alt="" />
                  {item.studyingArea}
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
  const renderItemInfoContact = (data) => {
    const { icon, name: _name, value } = data;
    return (
      <div className="item-info-wrapper">
        <img src={icon} alt="" className="icon-info" />
        <div className="item-info-detail-wrapper">
          <div className="item-value">{value}</div>
          <div className="item-name">{_name}</div>
        </div>
      </div>
    );
  };

  const renderUserInfoContact = () => (
    <div className="user-info-contact-wrapper">
      <div className="info-contact-title">
        {i18n.t("titleUserInfo.contactInfo")}
      </div>
      <div
        className={`info-contact-detail-wrapper ${
          infoContactEmpty ? "empty-data" : ""
        }`}
      >
        {infoContactEmpty ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <>
            {phone &&
              renderItemInfoContact({
                icon: IconPhoneCycle,
                name: "Di động",
                value: phone,
              })}
            {sex &&
              renderItemInfoContact({
                icon: IconUserCycle,
                name: "Giới tính",
                value: sex === "male" ? "Nam" : "Nữ",
              })}
            {email &&
              renderItemInfoContact({
                icon: IconMailCycle,
                name: "Email",
                value: email,
              })}
            {birthday &&
              renderItemInfoContact({
                icon: IconBirthdayCycle,
                name: "Ngày sinh",
                value: formatBirthday,
              })}
          </>
        )}
      </div>
      <div className="info-contact-title second">
        {i18n.t("titleUserInfo.relationshipStatus")}
      </div>
      <div
        className={`relationship-status-detail-wrapper ${
          _isEmpty(relationshipStatus) ? "empty-data" : ""
        }`}
      >
        {_isEmpty(relationshipStatus) ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          renderItemInfoContact({
            icon: IconHeartCycle,
            name: "Quan hệ",
            value: relationshipStatus.relationshipStatus,
          })
        )}
      </div>
    </div>
  );

  const renderItemInfoFamily = (data) => {
    const { image, name: _name, relationship } = data;
    return (
      <div className="item-info-wrapper">
        <img className="icon-info" src={image || EmptyUser} alt="" />
        <div className="item-info-detail-wrapper">
          <div className="item-name">{_name}</div>
          <div className="item-relationship">{relationship}</div>
        </div>
      </div>
    );
  };

  const renderUserInfoFamilyMember = () => (
    <div className="user-info-family-member-wrapper">
      <div className="info-family-member-title">
        {i18n.t("titleUserInfo.memberOfFamily")}
      </div>
      <div className="info-family-member-detail-wrapper">
        {_isEmpty(familyMembers) ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <>
            {familyMembers.map((item) => (
              <div key={item.id}>
                {renderItemInfoFamily({
                  image: item.image,
                  name: item.name,
                  relationship: item.relationship,
                })}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );

  const renderItemInfoGroup = (data) => {
    const { name: _name, members, image } = data;
    return (
      <div className="item-info-wrapper">
        <img className="icon-info" src={image || EmptyUser} alt="" />
        <div className="item-info-detail-wrapper">
          <div className="item-name">{_name}</div>
          <div className="item-members">{members} thành viên</div>
        </div>
      </div>
    );
  };

  const renderUserInfoGroupMember = () => (
    <div className="user-info-group-member-wrapper">
      <div className="info-group-member-title">
        {i18n.t("titleUserInfo.group")}
      </div>
      <div className="info-group-member-detail-wrapper">
        {_isEmpty(groups) ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <>
            {groups.map((item) => (
              <div key={item.id}>
                {renderItemInfoGroup({
                  image: item.image,
                  name: item.name,
                  members: item.members,
                })}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="user-info-wrapper">
      <div className="user-info-header">
        <BackButton /> Thông tin User
      </div>
      <div className="user-info-body-wrapper">
        <div className="user-info-follower-decentralization-wrapper">
          {renderUserInfoBasic()}
          <div className="break-line"></div>
          {renderUserInfoFollower()}
          <div className="break-line"></div>
          {renderUserInfoDecentralization()}
        </div>
        <div className="user-info-introdution-contact-wrapper">
          {renderUserInfoIntroduction()}
          {renderUserInfoContact()}
        </div>
        <div className="user-info-family-group-member-wrapper">
          {renderUserInfoFamilyMember()}
          {renderUserInfoGroupMember()}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
