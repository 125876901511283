/* eslint-disable react/display-name */
import i18n from "i18n";
import SummaryTooltip from "components/SummaryTooltip";
import SimSummaryTooltip from "components/SummaryTooltip/SimSummaryTooltip";
import moment from "moment";

const SIM_REGISTER_STATUS = {
  NEW: "ĐƠN HÀNG MỚI",
  BEING_TRANSPORTED: "ĐÃ GỬI HÀNG",
  DELIVERED: "ĐÃ GIAO",
  CANCELLED: "ĐÃ HUỶ ",
};

const Columns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.id = {
  dataIndex: "_id",
  title: "#",
  render: (id, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<SimSummaryTooltip detail={data} />}
        widthTooltipInner={300}
      >
        <div>#{id}</div>
      </SummaryTooltip>
    ),
  width: 120,
};

Columns.name = {
  dataIndex: "name",
  title: "Tên",
  sorter: true,
  render: (name, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<SimSummaryTooltip detail={data} />}
        widthTooltipInner={300}
      >
        <div>{name}</div>
      </SummaryTooltip>
    ),
  width: 300,
};
Columns.accountName = {
  dataIndex: "name",
  title: "Tên KH",
  render: (name) => renderDataValue(<div>{name}</div>),
};

Columns.type = {
  title: "Loại",
  dataIndex: ["type", "name"],
  render: (type, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<SimSummaryTooltip detail={data} />}
        widthTooltipInner={300}
      >
        <div>{type}</div>
      </SummaryTooltip>
    ),
};

Columns.price = {
  dataIndex: "price",
  title: "Giá bán",
  render: (price, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<SimSummaryTooltip detail={data} />}
        widthTooltipInner={300}
      >
        <div style={{ fontWeight: "bold" }}>
          {(price || 0).toLocaleString()} JPY
        </div>
      </SummaryTooltip>
    ),
};

Columns.simRegisterId = {
  dataIndex: "_id",
  title: "#",
  render: (id) => renderDataValue(<div>#{id}</div>),
  width: 120,
};
Columns.customOrderId = {
  dataIndex: "customOrderId",
  title: "Mã đơn hàng",
  render: (customOrderId) => renderDataValue(<div>{customOrderId}</div>),
};
Columns.phone = {
  dataIndex: "phone",
  title: "SĐT sau đăng ký",
  render: (phone) => renderDataValue(<div>{phone}</div>),
};
Columns.simRegisterAccount = {
  dataIndex: "name",
  title: "Tên đăng ký",
  render: (name) => renderDataValue(<div>{name}</div>),
};

Columns.simRegisterName = {
  dataIndex: ["sim", "name"],
  title: "Tên Sim",
  render: (name) => renderDataValue(<div>{name}</div>),
};

Columns.simName = {
  dataIndex: ["name"],
  title: "Tên Sim",
  render: (name) => renderDataValue(<div>{name}</div>),
};
Columns.initialPrice = {
  dataIndex: ["initialPrice"],
  title: "SIM (giá gốc)",
  render: (initialPrice) => renderDataValue(<div>{initialPrice}</div>),
};
Columns.initialConnectionFee = {
  dataIndex: ["initialConnectionFee"],
  title: "Cước hòa mạng (giá gốc)",
  render: (initialConnectionFee) =>
    renderDataValue(<div>{initialConnectionFee}</div>),
};
Columns.connectionFee = {
  dataIndex: ["connectionFee"],
  title: "Cước hòa mạng (giá bán)",
  render: (connectionFee) => renderDataValue(<div>{connectionFee}</div>),
};

Columns.subTotal = {
  dataIndex: ["discountedPrice"],
  title: "Cước Sim",
  render: (subTotal) => renderDataValue(<div>{subTotal} JPY</div>),
};
Columns.discountedConnectionFee = {
  dataIndex: ["discountedConnectionFee"],
  title: "Phí hòa mạng sau khi trừ KM",
  render: (discountedConnectionFee) =>
    renderDataValue(<div>{discountedConnectionFee} JPY</div>),
};
Columns.simRegisterType = {
  dataIndex: ["sim", "type", "name"],
  title: "Loại",
  render: (type) => renderDataValue(<div>{type}</div>),
};

Columns.simRegisterPrice = {
  dataIndex: "totalPrice",
  title: "Giá sim sau KM",
  render: (price) =>
    renderDataValue(
      <div style={{ fontWeight: "bold" }}>
        {(price || 0).toLocaleString()} JPY
      </div>
    ),
};

Columns.simRegisterStatus = {
  dataIndex: "status",
  title: "Trạng thái",
  render: (status) =>
    renderDataValue(
      <div className="sim-status" style={{ fontSize: 16 }}>
        {SIM_REGISTER_STATUS[status]}
      </div>
    ),
};

Columns.title = {
  dataIndex: "title",
  title: "Tiêu đề",
  render: (title) =>
    renderDataValue(<div style={{ fontSize: 14, maxWidth: 600 }}>{title}</div>),
  width: 800,
};
Columns.description = {
  dataIndex: "description",
  title: "Mô tả",
  render: (description) =>
    renderDataValue(<div style={{ fontSize: 14 }}>{description}</div>),
};
Columns.viewCount = {
  dataIndex: "viewCount",
  title: "Lượt xem",
  render: (viewCount) =>
    renderDataValue(
      <div className="sim-status" style={{ fontSize: 16 }}>
        {viewCount}
      </div>
    ),
};
Columns.createdAt = {
  dataIndex: "createdAt",
  title: "Ngày đặt hàng",
  render: (createdAt) =>
    renderDataValue(
      <div className="sim-status" style={{ fontSize: 16 }}>
        {moment(createdAt).format("DD/MM/YYYY")}
      </div>
    ),
};
Columns.postalCode = {
  dataIndex: "postalCode",
  title: "Mã Bưu Điện",
  render: (postalCode) =>
    renderDataValue(
      <div className="sim-status" style={{ fontSize: 16 }}>
        {postalCode}
      </div>
    ),
};
Columns.japaneseName = {
  dataIndex: "japaneseName",
  title: "Tên tiếng Nhật",
  render: (japaneseName) =>
    renderDataValue(
      <div className="sim-status" style={{ fontSize: 16 }}>
        {japaneseName}
      </div>
    ),
};
Columns.vietnameseName = {
  dataIndex: "vietnameseName",
  title: "Tên tiếng Việt",
  render: (vietnameseName) =>
    renderDataValue(
      <div className="sim-status" style={{ fontSize: 16 }}>
        {vietnameseName}
      </div>
    ),
};
Columns.phoneNumber = {
  dataIndex: "phoneNumber",
  title: "Số điện thoại",
  render: (phoneNumber) =>
    renderDataValue(
      <div className="sim-status" style={{ fontSize: 16 }}>
        {phoneNumber}
      </div>
    ),
};
Columns.email = {
  dataIndex: "email",
  title: "Email",
  render: (email) =>
    renderDataValue(
      <div className="sim-status" style={{ fontSize: 16 }}>
        {email}
      </div>
    ),
};
Columns.account = {
  dataIndex: ["createdBy", "name"],
  title: "Tài khoản",
  render: (account) =>
    renderDataValue(
      <div className="sim-status" style={{ fontSize: 16 }}>
        {account}
      </div>
    ),
};
Columns.gender = {
  dataIndex: "gender",
  title: "Giới tính",
  render: (gender) =>
    renderDataValue(
      <div className="sim-status" style={{ fontSize: 16 }}>
        {gender}
      </div>
    ),
};
Columns.alienRegistrationAddress = {
  dataIndex: "alienRegistrationAddress",
  title: "Địa chỉ",
  render: (alienRegistrationAddress) =>
    renderDataValue(
      <div className="sim-status" style={{ fontSize: 16 }}>
        {alienRegistrationAddress}
      </div>
    ),
};
Columns.imageUrl = {
  title: "Banner",
  dataIndex: "thumbnail",
  key: "thumbnail",
  width: 330,
  render: (thumbnail) =>
    renderDataValue(
      <div className={`banner-image ${"vertical-banner"}`}>
        <img style={{ width: 200, height: 200 }} src={thumbnail} alt="" />
      </div>
    ),
};
export default Columns;
