import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import { DatePicker, Select, TextInput } from "components/common";
import { Button } from "antd";
import i18n from "i18n";
import { promotionSimValidate } from "./validate";

const promotionOption = [
  {
    value: "percent",
    label: "Phần trăm (%)",
  },
  {
    value: "value",
    label: "Tiền",
  },
];
const CreatePromotionForm = ({
  initialValues,
  onSubmit,
  createPromotionRef,
}) => (
  <Formik
    innerRef={createPromotionRef}
    validateOnMount
    initialValues={initialValues}
    validationSchema={promotionSimValidate}
    onSubmit={onSubmit}
  >
    {({ handleSubmit, values }) => (
      <Form>
        <Select
          name="promotionType"
          required
          label="Khuyến mãi"
          options={promotionOption}
        />
        {values.promotionType === "value" && (
          <TextInput label="Giá khuyến mãi" name="discountValue" />
        )}
        {values.promotionType === "percent" && (
          <TextInput label="% khuyến mãi" name="discountPercentage" />
        )}

        <DatePicker label="Ngày bắt đầu" name="start" format="DD/MM/YYYY" />
        <DatePicker label="Ngày kết thúc" name="end" format="DD/MM/YYYY" />

        <TextInput label="Mô tả" name="displayText" />
        <Button
          className="submit-btn"
          htmlType="submit"
          loading={false}
          type="primary"
          onClick={(value) => {
            handleSubmit(value);
          }}
        >
          {i18n.t("actionButton.update")}
        </Button>
      </Form>
    )}
  </Formik>
);
CreatePromotionForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};
export default CreatePromotionForm;
