import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";

const todosSlice = createSlice({
  name: "post",
  initialState: {
    isGettingList: false,
    list: [],
    total: 0,
  },
  reducers: {
    getPostListRequest(state) {
      return { ...state, isGettingList: true };
    },
    getPostListSuccess(state, action) {
      const list = _get(action.payload, "list", []);
      const total = _get(action.payload, "total", 0);
      return { ...state, isGettingList: false, list, total };
    },
    stopLoading(state) {
      return { ...state, isGettingList: false };
    },
  },
});

export const { getPostListRequest, getPostListSuccess, stopLoading } =
  todosSlice.actions;

export default todosSlice.reducer;
