import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";

const businessSlice = createSlice({
  name: "business",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    detail: {},
  },
  reducers: {
    getBusinessListRequest(state) {
      return { ...state, isLoading: true };
    },
    getBusinessListSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs,
      };
    },
    confirmBusinessRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    confirmBusinessSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
        selectedRowKeys: [],
        selectedRows: [],
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    setSelectedBusinessIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    getBusinessByIdRequest(state) {
      return { ...state, isLoading: true };
    },
    getBusinessByIdSuccess(state, action) {
      const { payload = {} } = action;
      const { data } = payload;
      return {
        ...state,
        isLoading: false,
        detail: data,
      };
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isSettingTag: false };
    },
  },
});

export const {
  getBusinessByIdRequest,
  getBusinessByIdSuccess,
  setSelectedBusinessIds,
  confirmBusinessRequest,
  confirmBusinessSuccess,
  getBusinessListRequest,
  getBusinessListSuccess,
  stopLoading,
} = businessSlice.actions;

export default businessSlice.reducer;
