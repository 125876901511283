import { memo } from "react";
import PropTypes from "prop-types";
import { Layout, Affix, Avatar, Dropdown } from "antd";
import {
  MenuOutlined,
  CaretDownOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import NotificationPopover from "components/NotificationPopover";
import { setOpenNotificationPopover } from "providers/NotificationProvider/slice";
import "./styles.less";
import _ from "lodash";

const { Header } = Layout;

const Navbar = ({ currentUser = {}, menu }) => {
  const dispatch = useDispatch();

  const handleOpenPopover = (value) => {
    dispatch(setOpenNotificationPopover({ isOpen: value }));
  };

  return (
    <Affix offsetTop={0} className="affix-nav">
      <Header className="header">
        <div className="header-content">
          <div className="toggle-collapse-btn">
            {/* {!collapseMenu
              ? <MenuFoldOutlined onClick={() => toggleCollapseMenu(true)} />
              : <MenuUnfoldOutlined onClick={() => toggleCollapseMenu(false)} />
            } */}
            <MenuOutlined />
          </div>
          <div className="full-flex">
            <NotificationPopover handleOpenPopover={handleOpenPopover} />
            <Avatar
              shape="circle"
              size={34}
              icon={<UserOutlined />}
              src={
                _.isEmpty(currentUser.avatarUrl) ? "" : currentUser.avatarUrl
              }
            />
            <Dropdown trigger="click" overlay={menu} placement="bottomRight">
              <div className="user-menu">
                <div>
                  {!_.isEmpty(currentUser.name)
                    ? currentUser.name
                    : currentUser.email}
                </div>
                <CaretDownOutlined className="full-flex" />
              </div>
            </Dropdown>
          </div>
        </div>
      </Header>
    </Affix>
  );
};

Navbar.propTypes = {
  toggleCollapseMenu: PropTypes.func,
  collapseMenu: PropTypes.bool,
  logOut: PropTypes.func,
  currentUser: PropTypes.object,
  menu: PropTypes.func,
};

export default memo(Navbar);
