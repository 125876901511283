import { Row, Typography, Menu, Dropdown, Button, Tabs } from "antd";
import _get from "lodash/get";
import { RewardPointColumns } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import "../ReportManager/styles.less";
import { IconSeeMore } from "assets/images";
import TabHeader from "components/TabHeader";
import { useMemo } from "react";
import {
  getRewardPointListRequest,
  exchangePointsToUserRequest,
  getRewardBalanceRequest,
} from "providers/RewardPointManager/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import RewardBalanceSection from "./components/RewardBalanceSection";

const TITLE = "Quản lý điểm thưởng";
const TITLE_CHANGE_POINT = "Hoàn điểm cho User";
const CONFIRM_TITLE = "Xác nhận hoàn tiền cho user";
const CANCEL = "Huỷ";
const CONTENT_CONFIRM = "Bạn có muốn hoàn tiền cho user?";
const CONFIRM = "Xác nhận";

const { TabPane } = Tabs;
const RewardPointManager = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) =>
    _get(state, "rewardPointManagerSlice.isLoading", false)
  );
  const roles = useSelector((state) =>
    _get(state, "auth.currentUser.roles", [])
  );
  const role = useSelector((state) =>
    _get(state, "auth.currentUser.role", "user")
  );
  const rewardPointTabType = useSelector((state) =>
    _get(
      state,
      "rewardPointManagerSlice.rewardPointTabType",
      "POINT_PERSONAL_MANAGE"
    )
  );
  const rewardPointList = useSelector((state) =>
    _get(state, "rewardPointManagerSlice.rewardPointList", [])
  );
  const totalDocs = useSelector((state) =>
    _get(state, "rewardPointManagerSlice.totalDocs", 0)
  );

  const [searchObject, setSearch] = useQuery(getRewardPointListRequest);

  const headerTabs = useMemo(() => {
    if (role !== "user" || roles?.length > 0) {
      return ["POINT_PERSONAL_MANAGE", "POINT_STORE_MANAGE", "LIQUID_VIEGO"];
    }
    return ["POINT_PERSONAL_MANAGE"];
  }, [role, roles?.length]);

  const _onChangeRewardPointTab = (tab) => {
    setSearch(
      {
        type: tab,
        keyword: searchObject.keyword,
        status: searchObject.status,
        activeStatus: searchObject.activeStatus,
      },
      true
    );
  };

  const _onExchangePointsToUser = (record) => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          okText: CONFIRM,
          cancelText: CANCEL,
          title: CONFIRM_TITLE,
          content: CONTENT_CONFIRM,
          onOk: () => {
            dispatch(
              exchangePointsToUserRequest({
                type: "transfer_to_user",
                fromId: record?.store?._id,
                toId: record?.user?._id,
                amount: record.transaction.amount,
                callback: () => {
                  dispatch(
                    getRewardPointListRequest({
                      ...searchObject,
                      type: rewardPointTabType,
                    })
                  );
                  dispatch(getRewardBalanceRequest());
                },
              })
            );
          },
        },
      })
    );
  };

  const menu = (record) => {
    const status = record?.transaction?.status;
    return (
      <Menu>
        {rewardPointTabType === "POINT_STORE_MANAGE" && status === "success" && (
          <Menu.Item
            style={{ color: "green" }}
            onClick={() => {
              _onExchangePointsToUser(record);
            }}
          >
            {TITLE_CHANGE_POINT}
          </Menu.Item>
        )}
      </Menu>
    );
  };
  const actionButtons = (record) => {
    if (
      ["POINT_PERSONAL_MANAGE", "LIQUID_VIEGO"].includes(rewardPointTabType) ||
      record?.type === "transfer_to_user"
    ) {
      return <div></div>;
    }
    return (
      <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
        <Button
          className="see-more-btn"
          type="ghost"
          icon={<img src={IconSeeMore} alt="see-more" />}
        />
      </Dropdown>
    );
  };

  const headerTitleColumns = useMemo(() => {
    let result = [];
    switch (rewardPointTabType) {
      case "POINT_PERSONAL_MANAGE":
        result = [
          RewardPointColumns._id,
          RewardPointColumns.time,
          RewardPointColumns.type,
          RewardPointColumns.point,
          {
            width: 100,
            render: (record) => actionButtons(record),
          },
        ];
        break;
      case "POINT_STORE_MANAGE":
        result = [
          RewardPointColumns._id,
          RewardPointColumns.timeLiquid,
          RewardPointColumns.contentTransferStore,
          RewardPointColumns.store,
          RewardPointColumns.point,
          RewardPointColumns.storePointToMoney,
          RewardPointColumns.state,
          {
            width: 100,
            render: (record) => actionButtons(record),
          },
        ];
        break;
      case "LIQUID_VIEGO":
        result = [
          RewardPointColumns._id,
          RewardPointColumns.timeLiquid,
          RewardPointColumns.store,
          RewardPointColumns.point,
          RewardPointColumns.viegoPointToMoney,
          RewardPointColumns.status,
          {
            width: 100,
            render: (record) => actionButtons(record),
          },
        ];
        break;
      default:
        break;
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardPointTabType]);

  return (
    <div className="register-list-wrapper">
      <Row className="register-list-header" justify="space-between">
        <Typography.Title level={4}>{TITLE}</Typography.Title>
      </Row>
      <RewardBalanceSection />
      <div className="content-container">
        <Tabs
          className="cosmetic-tabs-container"
          activeKey={searchObject.mine}
          type="card"
          style={{ marginTop: 12 }}
          onChange={_onChangeRewardPointTab}
        >
          {headerTabs.map((value) => (
            <TabPane
              className="status-tab-pane"
              tab={<TabHeader status={value} total={100} />}
              key={value}
            >
              <Table
                scroll={{ x: 1000 }}
                loading={isLoading}
                bordered
                columns={headerTitleColumns}
                dataSource={rewardPointList}
                rowKey={(data) => data._id}
                total={totalDocs}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default RewardPointManager;
