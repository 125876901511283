import PropTypes from "prop-types";
import "./styles.less";

const statusClassName = {
  true: "active-status",
  false: "de-active-status",
};

export const StatusTag = (props) => {
  const { status, statusText } = props;
  return (
    <div className={`status-tag-wrapper ${statusClassName[status]}`}>
      <div className="status-dot" /> {statusText}
    </div>
  );
};

StatusTag.propTypes = {
  status: PropTypes.string,
  statusText: PropTypes.object,
};

export default StatusTag;
