import { Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "components/BackButton";
import {
  updateRegisteredSimRequest,
  getSimRegistrationDetailRequest,
} from "providers/SimProvider/slice";
import "./styles.less";
import EditForm from "./EditForm";

const EditRegisteredSim = () => {
  const dispatch = useDispatch();
  const { simRegistrationDetail, isLoading } = useSelector(
    (state) => state.sim
  );
  const navigate = useNavigate();

  const handleRegisteredSim = (values) => {
    dispatch(
      updateRegisteredSimRequest({
        ...values,
        simRegistrationId: simRegistrationDetail._id,
        callback: () => {
          dispatch(
            getSimRegistrationDetailRequest({ _id: simRegistrationDetail._id })
          );
          navigate(-1);
        },
      })
    );
  };

  const initialValues = {
    name: simRegistrationDetail?.name,
    customOrderId: simRegistrationDetail?.customOrderId,
    postalCode: simRegistrationDetail?.postalCode,
    alienRegistrationAddress: simRegistrationDetail?.alienRegistrationAddress,
    deliveryAddress: simRegistrationDetail?.deliveryAddress,
    email: simRegistrationDetail?.email,
    transactionType: simRegistrationDetail?.transactionType,
    prefixPhoneNumber: simRegistrationDetail?.prefixPhoneNumber,
    phoneNumber: simRegistrationDetail?.phoneNumber,
    facebookURL: simRegistrationDetail?.facebookURL,
    alienRegistrationFrontImages:
      simRegistrationDetail?.alienRegistrationFrontImages ?? [],
    alienRegistrationBackImages:
      simRegistrationDetail?.alienRegistrationBackImages ?? [],
    alienRegistrationsWithFaceImages:
      simRegistrationDetail?.alienRegistrationsWithFaceImages ?? [],
  };

  return (
    <div className="product-detail-page">
      <Row className="product-detail-header" justify="space-between">
        <Col className="back-btn">
          <BackButton />
          <Typography.Title level={4}>
            {"Đơn đăng ký sim chi tiết"}
          </Typography.Title>
        </Col>
      </Row>
      <div className="form-section">
        <Row justify="center">
          <Col span={20} className="form-wrapper">
            <EditForm
              onSubmit={handleRegisteredSim}
              title="Sản phẩm chi tiết"
              initialValues={initialValues}
              loading={isLoading}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditRegisteredSim;
