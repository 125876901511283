import { useEffect } from "react";
import { Row, Typography, Button, Image } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "components/BackButton";
import {
  getSupportDetailRequest,
  resetSupportDetail,
} from "providers/SupportProvider/slice";
import { createConversationRequest } from "providers/MessengerProvider/slice";
import i18n from "i18n";
import "./styles.less";
import { PlaceHolderIMG } from "assets/images";

const SupportDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const supportDetail = useSelector((state) => _get(state, "support.detail"));
  const { title, content, attachments = [], userId = {} } = supportDetail;

  useEffect(() => {
    dispatch(getSupportDetailRequest({ feedbackId: id }));
    return () => {
      dispatch(resetSupportDetail());
    };
  }, [id, dispatch]);

  const handleRedirectToChat = () => {
    dispatch(createConversationRequest({ receiverId: userId._id })).then(
      (result) => {
        navigate(`/fonehouse/messengers/${result._id}`);
      }
    );
  };

  return (
    <div className="support-feedback-detail-wrapper">
      <Row className="support-feedback-header" justify="space-between">
        <div className="back-btn-container">
          <BackButton />
          <Typography.Title level={4}>
            {i18n.t("support.supportFeedback")}
          </Typography.Title>
        </div>
        <Button size="small" type="primary" onClick={handleRedirectToChat}>
          <MessageOutlined /> NHẮN TIN
        </Button>
      </Row>
      <Row className="support-feedback-body">
        <div className="support-feedback-title">{title}</div>
        <div className="support-feedback-note">
          <div className="title">Ghi chú</div>
          <div className="content">{content} </div>
          {!_isEmpty(attachments) && (
            <div className="attachments">
              {attachments.map((item) => {
                if (item.type === "jpeg" || item.type === "jpg") {
                  return (
                    <div key={item._id} className="image-support-feedback">
                      <Image fallback={PlaceHolderIMG} src={item.url} />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      </Row>
    </div>
  );
};

export default SupportDetail;
