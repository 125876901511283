import { Col, Row, Image, Button } from "antd";
import _isEmpty from "lodash/isEmpty";
import { MinusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import _get from "lodash/get";

import DefaultImage from "assets/images/photo.png";
import { WORKING_TIME_TEXT, STORE_SUB_TYPE, STORE_TYPE } from "utils/constants";
import "./styles.less";
import { PlaceHolderIMG } from "assets/images";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getDiscountConditionsRequest,
  getDiscountPercentagesRequest,
} from "providers/StoreProvider/slice";
import _ from "lodash";
import Helper from "utils/helpers";

const context = {
  [STORE_TYPE.CAR_AND_OLD_STUFF]: {
    storeNameLabel: "Tên cửa hàng",
  },
  [STORE_TYPE.TRAVEL]: {
    storeNameLabel: "Tên cửa hàng",
  },
  [STORE_TYPE.SHIPPER]: {
    storeNameLabel: "Tên doanh nghiệp",
  },
  [STORE_TYPE.ENTERTAINMENT]: {
    storeNameLabel: "Tên giải trí",
  },
};

const StoreDetail = ({
  isPermissionToEdit,
  detail = {},
  onEdit,
  storeType,
}) => {
  const storeContext = context[storeType];
  const dispatch = useDispatch();

  const storeLocationTags = _get(detail, "locationTags", []);

  const locationNames = storeLocationTags
    .map((tag) => tag.name || "")
    .join(", ");

  const createMarkup = (html) => ({
    __html: DOMPurify.sanitize(html, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
    }),
  });
  useEffect(() => {
    dispatch(getDiscountConditionsRequest());
    dispatch(getDiscountPercentagesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderWorkingTime = () => {
    const d = new Date();
    const day = d.getDay();
    const workingTimeOfDay = detail.workingTime[day === 0 ? 6 : day - 1];

    if (_isEmpty(detail.workingTime) || workingTimeOfDay.alwaysClose) {
      return <div className="s-text">Đóng cửa</div>;
    }

    return workingTimeOfDay.time?.map((item, index) => (
      <div key={index} className="s-text">
        {item.from} <MinusOutlined /> {item.to}
      </div>
    ));
  };

  return (
    <div className="store-detail-component-wrapper">
      <Row>
        <Col span={14}>
          <Row>
            <Col>
              <Image
                fallback={PlaceHolderIMG}
                className="banner-wrapper"
                width={160}
                height={100}
                src={detail.thumbnail || DefaultImage}
              />
            </Col>
            <Col flex="auto" className="store-info">
              <Row className="info-row">
                <Col className="info-col" span={16}>
                  <div className="s-text">{storeContext.storeNameLabel}</div>
                  <div className="s-text">{detail.name}</div>
                </Col>
                {STORE_SUB_TYPE[detail.subType] && (
                  <Col className="info-col" span={8}>
                    <div className="s-text">Loại hình</div>
                    <div className="s-text">
                      {STORE_SUB_TYPE[detail.subType]}
                    </div>
                  </Col>
                )}
              </Row>
              <Row className="info-row">
                <Col className="info-col">
                  <div className="s-text">Giờ làm việc</div>
                  {detail.workingTimeMethod !== "custom" ? (
                    <div className="s-text">
                      {WORKING_TIME_TEXT[detail.workingTimeMethod]}
                    </div>
                  ) : (
                    renderWorkingTime()
                  )}
                </Col>
              </Row>
              <Row className="info-row">
                <Col className="info-col" span={8}>
                  <div className="s-text">Địa chỉ</div>
                  <div className="s-text">{detail.address}</div>
                </Col>
                {locationNames && (
                  <Col className="info-col" span={8}>
                    <div className="s-text">Địa điểm</div>
                    <div className="s-text">{locationNames}</div>
                  </Col>
                )}
              </Row>
              <Row className="info-row">
                <Col className="info-col" span={8}>
                  <div className="s-text">Số điện thoại</div>
                  <div className="s-text">{detail.phone}</div>
                </Col>
                <Col className="info-col" span={8}>
                  <div className="s-text">Đánh giá</div>
                  <div className="s-text bold">{detail.rating}</div>
                </Col>
              </Row>
              {detail?.discountCondition && (
                <Row className="info-row">
                  <Col className="info-col" span={8}>
                    <div className="s-text">Điều kiện KM</div>
                    <div className="s-text">
                      {detail?.discountCondition?.displayText}
                    </div>
                  </Col>
                  <Col className="info-col" span={8}>
                    <div className="s-text">Phần trăm KM</div>
                    <div className="s-text bold">
                      {detail?.discountPercentage?.displayText}
                    </div>
                  </Col>
                </Row>
              )}
              {detail?.customDiscountCondition && (
                <Row className="info-row">
                  <Col className="info-col" span={8}>
                    <div className="s-text">Giờ bắt đầu</div>
                    <div className="s-text">
                      {detail?.customDiscountCondition?.start}
                    </div>
                  </Col>
                  <Col className="info-col" span={8}>
                    <div className="s-text">Giờ kết thúc</div>
                    <div className="s-text">
                      {detail?.customDiscountCondition?.end}
                    </div>
                  </Col>
                </Row>
              )}
              <Row className="info-row">
                <Col className="info-col" span={8}>
                  <div className="s-text">
                    Thời gian khuyến mãi kết thúc sau:
                  </div>
                  <div className="s-text">
                    {Helper.calculateRemainingTime(detail.promotionEndedAt)}
                  </div>
                </Col>
              </Row>
              <Row className="info-row">
                {_.get(detail, "businessImages", []).map((url) => (
                  <Image
                    key={url}
                    fallback={PlaceHolderIMG}
                    className="banner-wrapper"
                    width={160}
                    height={100}
                    style={{ padding: 5 }}
                    src={url || DefaultImage}
                  />
                ))}
              </Row>
            </Col>
          </Row>
          <Row className="info-row">
            <Col className="info-col" span={8}>
              <div className="s-text">Ảnh cửa hàng</div>
            </Col>
          </Row>
        </Col>
        <Col flex="auto">
          <Row justify="end">
            {isPermissionToEdit && (
              <Button
                style={{ maxWidth: 140 }}
                onClick={onEdit}
                type="primary"
                size="small"
              >
                CẬP NHẬT
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <div className="business-description-container">
          <div className="title">Mô tả doanh nghiệp</div>
          <div
            className="business-description-content"
            dangerouslySetInnerHTML={createMarkup(detail.description)}
          ></div>
        </div>
      </Row>
    </div>
  );
};

StoreDetail.propTypes = {
  detail: PropTypes.object,
  onEdit: PropTypes.func,
  isPermissionToEdit: PropTypes.bool,
  storeType: PropTypes.string,
};
export default StoreDetail;
