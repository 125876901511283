import { object, string, array } from "yup";
import i18n from "i18n";

export const createNewType = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
  image: object().nullable().required(i18n.t("errors.required")),
});

export const createMachineSeries = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
  image: object().nullable().required(i18n.t("errors.required")),
  type: string().required(i18n.t("errors.required")),
  images: array().min(0, i18n.t("errors.required")),
});
