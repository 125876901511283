const IconFilter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 28 28"
  >
    <path
      id="Icon_awesome-filter"
      data-name="Icon awesome-filter"
      d="M26.686,0H1.314A1.313,1.313,0,0,0,.386,2.241L10.5,12.356V23.625a1.313,1.313,0,0,0,.56,1.075l4.375,3.061A1.313,1.313,0,0,0,17.5,26.686V12.356L27.614,2.241A1.314,1.314,0,0,0,26.686,0Z"
    />
  </svg>
);

export default IconFilter;
