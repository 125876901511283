import { useRef, useMemo, useState, useEffect } from "react";
import { Col, Row, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import { TextInput, Select } from "components/common";
import EditArticleTypeModal from "components/EditArticleTypeModal";
import {
  createSimTypeRequest,
  updateSimTypeRequest,
  deleteSimTypeRequest,
  updateConnectionFeePromotion,
  updatePriceDiscountPromotion,
  resetPromotionValues,
  removeConnectionFeePromotion,
  removePriceDiscountPromotion,
  getInitialArrayPromotion,
} from "providers/SimProvider/slice";
import CreatePromotionModal from "components/CreatePromotionModal";
import moment from "moment";
import _get from "lodash/get";
import { simFormValidate } from "./validate";
import SimTypeForm from "./SimTypeForm";
import "./styles.less";
import CreatePromotionForm from "./CreatePromotionForm";

const SimForm = ({
  initialValues = {},
  onSubmit,
  isCreating,
  loading,
  simTypes,
  refetchTypes,
  detail,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const createPromotionRef = useRef(null);
  const [editType, setEditType] = useState({});
  const [isOpenPromotionModal, setIsOpenPromotionModal] = useState(false);
  const [isConnectionFeePromotion, setIsConnectionFeePromotion] =
    useState(false);

  const connectionFeePromotionList = useSelector((state) =>
    _get(state, "sim.connectionFeeDiscount", [])
  );
  const priceDiscountPromotionList = useSelector((state) =>
    _get(state, "sim.priceDiscount", [])
  );
  const typeOptions = useMemo(
    () => simTypes.map((type) => ({ value: type._id, label: type.name })),
    [simTypes]
  );

  useEffect(() => {
    dispatch(getInitialArrayPromotion(detail));
  }, [detail, dispatch]);
  useEffect(() => {
    dispatch(resetPromotionValues());
  }, []);
  const handleOpenEditTypeModal = (data) => {
    setEditType(data);
  };

  const handleAddType = () => {
    const newType = formikRef.current?.values.newType;
    if (!_isEmpty(newType)) {
      dispatch(
        createSimTypeRequest({
          name: newType,
        })
      ).then(() => {
        refetchTypes();
        formikRef.current?.setFieldValue("newType", "");
      });
    }
  };
  const handleSavePromotion = (values) => {
    const params = values;
    delete params.promotionType;
    if (isConnectionFeePromotion) {
      dispatch(updateConnectionFeePromotion(params));
    } else {
      dispatch(updatePriceDiscountPromotion(params));
    }

    setIsOpenPromotionModal(false);
  };
  const handleEditType = (data) => {
    dispatch(
      updateSimTypeRequest({
        _id: editType.value,
        name: data.name,
      })
    ).then(() => {
      refetchTypes();
      setEditType({});
    });
  };

  const handleDeleteType = (data) => {
    dispatch(deleteSimTypeRequest({ _id: data.value })).then(() => {
      refetchTypes();
    });
  };
  const renderPromotionItem = (item, index, isConnectionFee) => (
    <Row style={{ margin: 10 }} justify="space-between" align="middle">
      <div>
        Từ {moment(item.start).format("DD/MM/YYYY")} đến{" "}
        {moment(item.end).format("DD/MM/YYYY")}
      </div>
      {item.discountValue !== 0 && (
        <div style={{ fontWeight: "bold" }}>Giảm {item.discountValue} JPY</div>
      )}
      {item.discountPercentage !== 0 && (
        <div style={{ fontWeight: "bold" }}>
          Giảm {item.discountPercentage}%
        </div>
      )}
      <Button
        onClick={() => {
          if (isConnectionFee) {
            dispatch(removeConnectionFeePromotion(index));
          } else {
            dispatch(removePriceDiscountPromotion(index));
          }
        }}
        style={{ width: 40 }}
      >
        Xóa
      </Button>
    </Row>
  );
  const renderPromotionForm = (isConnectionFee, promotionList) => (
    <Col style={{ marginTop: 10, marginBottom: 20 }}>
      <Row>
        <div>
          Điều chỉnh khuyến mãi {isConnectionFee ? "Cước hòa mạng" : "Giá Sim"}
        </div>
        <Button
          onClick={() => {
            setIsConnectionFeePromotion(isConnectionFee);
            setIsOpenPromotionModal(!isOpenPromotionModal);
            createPromotionRef?.current?.resetForm();
          }}
        >
          Tạo khuyến mãi
        </Button>
      </Row>
      {promotionList.length > 0 &&
        promotionList.map((item, index) =>
          renderPromotionItem(item, index, isConnectionFee)
        )}
    </Col>
  );
  const handleCloseModal = () => {
    setIsOpenPromotionModal(false);
  };

  return (
    <>
      {!_isEmpty(editType) && (
        <EditArticleTypeModal
          title="Thay đổi tên loại"
          data={editType}
          handleCloseModal={handleOpenEditTypeModal}
        >
          <SimTypeForm
            initialValues={{ name: editType.label }}
            onSubmit={handleEditType}
          />
        </EditArticleTypeModal>
      )}
      <CreatePromotionModal
        handleCloseModal={handleCloseModal}
        isVisible={isOpenPromotionModal}
      >
        <CreatePromotionForm
          createPromotionRef={createPromotionRef}
          initialValues={{
            discountValue: 0,
            discountPercentage: 0,
            displayText: "",
            start: moment(),
            end: moment(),
          }}
          isConnectionFee
          onSubmit={handleSavePromotion}
        />
      </CreatePromotionModal>
      <Formik
        validateOnMount
        initialValues={initialValues}
        enableReinitialize
        validationSchema={simFormValidate}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ handleSubmit }) => (
          <Form className="sim-form-wrapper">
            <TextInput label="Tên SIM" name="name" required />
            <Row gutter={16}>
              <Col span={12}>
                <Select
                  name="type"
                  required
                  label="Loại sim"
                  options={typeOptions}
                  isRemove
                  handleRemoveItem={handleDeleteType}
                  isEditProductType
                  handleEditItem={handleOpenEditTypeModal}
                />
              </Col>
              <Col span={12} className="add-new-sim-type">
                <TextInput label="Thêm mới loại" name="newType" />
                <Button
                  type="primary"
                  shape="circle"
                  onClick={handleAddType}
                  icon={<PlusOutlined />}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <TextInput
                  label="Cước hoà mạng (giá gốc)"
                  name="initialConnectionFee"
                />
              </Col>
              <Col span={12}>
                <TextInput
                  label="Cước hoà mạng (giá bán)"
                  name="connectionFee"
                />
              </Col>
            </Row>
            {renderPromotionForm(true, connectionFeePromotionList)}
            <Row gutter={16}>
              <Col span={12}>
                <TextInput label="Giá SIM (giá gốc)" name="initialPrice" />
              </Col>
              <Col span={12}>
                <TextInput label="Giá SIM (giá bán)" name="price" required />
              </Col>
            </Row>
            {renderPromotionForm(false, priceDiscountPromotionList)}
            <TextInput
              label="Mô tả"
              name="description"
              inputType="textarea"
              autoSize={{ minRows: 6 }}
            />
            <Row justify="space-between" className="button-wrapper" gutter={16}>
              <Col flex="1">
                <Button
                  loading={loading}
                  onClick={handleSubmit}
                  type="primary"
                  size="large"
                >
                  {isCreating ? "Tạo Sim mới" : "Cập nhật"}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export const PromotionItem = (
  start,
  end,
  discountValue,
  discountPercentage
) => (
  <Row justify="space-between" align="middle">
    <div>
      Từ {moment(start).format("DD/MM/YYYY")} đến{" "}
      {moment(end).format("DD/MM/YYYY")}
    </div>
    {discountValue !== 0 && (
      <div style={{ fontWeight: "bold" }}>Giảm {discountValue} JPY</div>
    )}
    {discountPercentage !== 0 && (
      <div style={{ marginLeft: 10 }}>Giảm {discountPercentage}%</div>
    )}
  </Row>
);

SimForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  isCreating: PropTypes.bool,
};

export default SimForm;
