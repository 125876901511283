import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import Helper from "utils/helpers";
import {
  getSupportListRequest,
  getSupportListSuccess,
  stopLoading,
  getSupportDetailRequest,
  getSupportDetailSuccess,
  getCountNotReadSupportRequest,
  getCountNotReadSupportSuccess,
  deleteSupportRequest,
  deleteSupportSuccess,
} from "./slice";

function* getSupportList(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "type",
    "sortStatus",
    "keyword",
    "status",
    "sortName",
    "activeStatus",
    "mine",
  ]);
  if (params.mine === "pin") {
    params.pin = 1;
    params.mine = false;
  }
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  const { data } = yield call(
    request,
    "api/admin/support/feedback/list",
    params,
    { method: "GET" }
  );
  yield put(getSupportListSuccess(data));
}

function* getSupportDetail(action) {
  const params = _pick(action.payload, ["feedbackId"]);
  const { data } = yield call(
    request,
    `api/admin/support/feedback/detail/${params.feedbackId}`,
    {},
    { method: "GET" }
  );
  yield put(getSupportDetailSuccess(data));
  yield put(getCountNotReadSupportRequest());
}

function* getCountNotReadSupport() {
  const { data } = yield call(
    request,
    "api/admin/support/feedback/count",
    {},
    { method: "GET" }
  );
  yield put(getCountNotReadSupportSuccess(data));
}

function* deleteSupport(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["feedbackIds"]);
  const { data } = yield call(request, "api/admin/support/feedback/", params, {
    method: "DELETE",
  });
  Helper.toastr("Xóa hỗ trợ thành công");
  yield put(deleteSupportSuccess(data, meta));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchSupport() {
  yield takeEvery(getSupportListRequest.type, safe(getSupportList, onError));
  yield takeEvery(
    getSupportDetailRequest.type,
    safe(getSupportDetail, onError)
  );
  yield takeLeading(
    getCountNotReadSupportRequest.type,
    safe(getCountNotReadSupport, onError)
  );
  yield takeLeading(deleteSupportRequest.type, safe(deleteSupport, onError));
}
