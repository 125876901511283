/* eslint-disable react/display-name */

import i18n from "i18n";
import moment from "moment";

const Columns = {};

const renderDataValue = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};
const renderStatusValue = (value) => {
  const insuranceDate = moment(value).format("DD/MM/YYYY");
  const status = moment(insuranceDate, "DD/MM/YYYY").isAfter();
  return (
    <span
      style={{ color: status === false ? "red" : "green", fontWeight: "bold" }}
    >
      {status === false
        ? i18n.t("products.expire")
        : i18n.t("products.noExpire")}
    </span>
  );
};
Columns.productId = {
  dataIndex: "productId",
  key: "productId",
  title: "#",
  render: (productId) => renderDataValue(`#${productId}`),
};

Columns.saleDate = {
  dataIndex: "saleDate",
  key: "saleDate",
  title: i18n.t("products.saleDate"),
  width: 150,
  render: (saleDate) => renderDataValue(moment(saleDate).format("DD/MM/YYYY")),
};

Columns.name = {
  title: i18n.t("products.name"),
  dataIndex: "name",
  key: "name",
  sorter: true,
  render: (name) => renderDataValue(name),
  width: 200,
};

Columns.imei = {
  dataIndex: "imei",
  key: "imei",
  title: "IMEI",
  render: (imei) => renderDataValue(`${imei}`),
};

Columns.insuranceDate = {
  title: i18n.t("products.insuranceDate"),
  dataIndex: "saleDate",
  key: "saleDate",
  sorter: true,
  render: (insuranceDate) =>
    renderDataValue(moment(insuranceDate).format("DD/MM/YYYY")),
  width: 200,
};

Columns.warrantyExpirationDate = {
  title: i18n.t("products.warrantyExpirationDate"),
  dataIndex: "warrantyExpirationDate",
  key: "warrantyExpirationDate",
  sorter: true,
  render: (warrantyExpirationDate) =>
    renderDataValue(moment(warrantyExpirationDate).format("DD/MM/YYYY")),
  width: 200,
};

Columns.status = {
  title: i18n.t("products.status"),
  dataIndex: "warrantyExpirationDate",
  key: "status",
  render: (warrantyExpirationDate) => renderStatusValue(warrantyExpirationDate),
};

export default Columns;
