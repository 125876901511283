import { Row, Typography, Button, Dropdown, Menu, Tabs } from "antd";
import { Link, useNavigate } from "react-router-dom";
import _get from "lodash/get";
import { BillingColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import TabHeaderFonehouseBill from "components/TabHeaderFonehouseBill";
import {
  getBillingListRequest,
  setSelectedBusinessIds,
  updateBillStatusRequest,
} from "providers/BillingProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import SearchBar from "./SearchBar";

const { TabPane } = Tabs;

const BillingList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminList = useSelector((state) => _get(state, "billingList.docs"));
  const adminTotal = useSelector((state) =>
    _get(state, "billingList.totalDocs")
  );
  const isLoading = useSelector((state) =>
    _get(state, "billingList.isLoading")
  );
  const selectedRowKeys = useSelector((state) =>
    _get(state, "billingList.selectedRowKeys", [])
  );
  const totalNewOrders = useSelector((state) =>
    _get(state, "billingList.totalNewOrders", 0)
  );

  const [searchObject, setSearch] = useQuery(getBillingListRequest, {
    arrayParams: ["status"],
  });
  const menu = (record) => {
    const { _id } = record;
    return (
      <Menu>
        <Menu.Item>
          <Link
            to={`/fonehouse/billing_list/${_id}`}
            state={{ canGoBack: true }}
          >
            {i18n.t("billingList.seeDetail")}
          </Link>
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            dispatch(
              setPopup({
                isOpen: true,
                data: {
                  title: "Xác nhận trạng thái",
                  content: 'Xác nhận trạng thái "Đã giao" cho đơn hàng này?',
                  onOk: () =>
                    dispatch(
                      updateBillStatusRequest({
                        orderId: _id,
                        status: "success",
                      })
                    ).then(() => dispatch(getBillingListRequest(searchObject))),
                },
              })
            )
          }
        >
          {i18n.t("billingList.ordered")}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            dispatch(
              setPopup({
                isOpen: true,
                data: {
                  title: "Xác nhận trạng thái",
                  content:
                    'Xác nhận trạng thái "Đã gửi hàng" cho đơn hàng này?',
                  onOk: () =>
                    dispatch(
                      updateBillStatusRequest({
                        orderId: _id,
                        status: "shipped",
                      })
                    ).then(() => dispatch(getBillingListRequest(searchObject))),
                },
              })
            )
          }
        >
          {i18n.t("billingList.waitting")}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            dispatch(
              setPopup({
                isOpen: true,
                data: {
                  title: "Xác nhận huỷ đơn hàng",
                  content: 'Xác nhận trạng thái "Đã huỷ" cho đơn hàng này',
                  onOk: () =>
                    dispatch(
                      updateBillStatusRequest({
                        orderId: _id,
                        status: "cancel",
                      })
                    ).then(() => dispatch(getBillingListRequest(searchObject))),
                },
              })
            )
          }
        >
          {i18n.t("billingList.cancelOrder")}
        </Menu.Item>
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const columns = [
    BillingColumn.idBill,
    BillingColumn.name,
    BillingColumn.phone,
    BillingColumn.createAt,
    BillingColumn.status,
    BillingColumn.type,
    BillingColumn.products,
    BillingColumn.price,
    {
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      dispatch(setSelectedBusinessIds({ record, selected, selectedRows }));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      dispatch(setSelectedBusinessIds({ selected, selectedRows, changeRows }));
    },
  };

  const checkingBillStatus = (status) => {
    switch (status) {
      case "new":
        return "status-new";
      case "shipped":
        return "status-shipped";
      default:
        return "";
    }
  };
  return (
    <div className="bill-list-wrapper">
      <Row className="bill-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("billingList.title")}
        </Typography.Title>
        <Button
          size="large"
          type="primary"
          onClick={() =>
            navigate("/fonehouse/create_bill", { state: { canGoBack: true } })
          }
        >
          Tạo đơn hàng
        </Button>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Tabs
          className="restaurant-tabs-container"
          activeKey={searchObject.mine}
          type="card"
          onChange={(key) => {
            if (key === "true") {
              setSearch(
                {
                  keyword: searchObject.keyword,
                  status: "new",
                },
                true
              );
            } else {
              setSearch(
                {
                  keyword: searchObject.keyword,
                },
                true
              );
            }
          }}
        >
          {[false, true].map((value) => (
            <TabPane
              className="status-tab-pane"
              tab={
                <TabHeaderFonehouseBill
                  status={value}
                  total={100}
                  totalNewOrders={totalNewOrders}
                />
              }
              key={value}
            >
              <Table
                rowSelection={rowSelection}
                scroll={{ x: 1000 }}
                loading={isLoading}
                columns={columns}
                dataSource={adminList}
                rowKey={(data) => data._id}
                rowClassName={(data) => checkingBillStatus(data.status)}
                total={adminTotal}
                onRow={(record) => ({
                  onDoubleClick: () => {
                    navigate(`/fonehouse/billing_list/${record._id}`, {
                      state: { canGoBack: true },
                    });
                  },
                })}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default BillingList;
