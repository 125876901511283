import { createSlice } from "@reduxjs/toolkit";

const accessorySlice = createSlice({
  name: "accessory",
  initialState: {
    isLoading: false,
    accessoryList: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    totalDocs: 0,
  },
  reducers: {
    getAccessoryListRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getAccessoryListSuccess(state, action) {
      const { docs, totalDocs, page } = action.payload.data;
      return {
        ...state,
        isLoading: false,
        accessoryList: docs,
        page,
        totalDocs,
      };
    },
    createOrUpdateAccessoryRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    createAccessorySuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteAccessoryRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    requestFail(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getAccessoryListRequest,
  getAccessoryListSuccess,
  requestFail,
  createOrUpdateAccessoryRequest,
  createAccessorySuccess,
  deleteAccessoryRequest,
} = accessorySlice.actions;

export default accessorySlice.reducer;
