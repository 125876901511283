import { Modal, Input, Button, Typography } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";

import {
  getStoreLocationTagsRequest,
  createStoreLocationTagsRequest,
  editStoreLocationTagsRequest,
  deleteStoreLocationTagsRequest,
} from "providers/StoreProvider/slice";
import { LeftArrowIcon } from "assets/images";
import "./styles.less";

const LocationListModal = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState("");

  const { locationTags, isLoading } = useSelector((state) => state.store);

  useEffect(() => {
    dispatch(getStoreLocationTagsRequest());
  }, [dispatch]);

  const handleAddLocation = () => {
    dispatch(createStoreLocationTagsRequest({ name: input })).then(() => {
      setInput("");
      dispatch(getStoreLocationTagsRequest());
    });
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      closable={false}
      width={600}
      className="location-list-modal-wrapper"
    >
      <div className="modal-header">
        <img
          onClick={onClose}
          className="icon-close"
          src={LeftArrowIcon}
          alt=""
        />
        Tạo địa điểm
      </div>
      <Input.Search
        placeholder="Nhập tên địa điểm"
        onSearch={handleAddLocation}
        onChange={(e) => {
          setInput(e.target.value);
        }}
        loading={isLoading}
        value={input}
        enterButton="Thêm"
        className="add-location-input"
      />
      <div className="location-list-wrapper">
        {locationTags.map((location) => (
          <div key={location._id} className="location-item">
            <Typography.Text
              editable={{
                onChange: (value) => {
                  dispatch(
                    editStoreLocationTagsRequest({
                      name: value,
                      _id: location._id,
                    })
                  ).then(() => {
                    dispatch(getStoreLocationTagsRequest());
                  });
                },
                tooltip: false,
              }}
            >
              {location.name}
            </Typography.Text>
            <Button
              type="ghost"
              danger
              shape="circle"
              onClick={() => {
                dispatch(
                  deleteStoreLocationTagsRequest({ _id: location._id })
                ).then(() => {
                  dispatch(getStoreLocationTagsRequest());
                });
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default LocationListModal;
