import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import BackIcon from "assets/images/back.svg";
import {
  getBillDetailRequest,
  updateBillRequest,
} from "providers/BillingProvider/slice";
import i18n from "i18n";
import _get from "lodash/get";
import { Modal } from "antd";
import { useParams } from "react-router";
import Helper from "utils/helpers";
import DiscountDirectForm from "../DiscountDirectForm";

const DiscountDirectModal = (props) => {
  const dispatch = useDispatch();
  const { setDiscountDirectModal } = props;
  const discountDirectModalData = useSelector(
    (state) => state.billingList.discountDirectModalData
  );
  const isLoading = useSelector((state) => state.billingList.isLoading);
  const billDetail = useSelector((state) =>
    _get(state, "billingList.billDetail")
  );
  const productData = useSelector((state) =>
    _get(state, "billingList.productData")
  );
  const productIDs = productData.map((item) => item._id);
  const { visible } = discountDirectModalData;
  const params = useParams();
  const { idBill } = params;
  const initialValues = {
    transactionType: billDetail.transactionType ?? "",
    orderId: billDetail._id,
    products: productIDs ?? [],
    name: billDetail.name ?? "",
    postCode: billDetail.postCode ?? "",
    timeFrame: billDetail.timeFrame ?? "",
    orderFrom: billDetail.orderFrom ?? "",
    address: billDetail.address ?? "",
    phone: billDetail.name ?? "",
    currency: billDetail.currency ?? "",
    shippingFee: billDetail.shippingFee ?? "",
    coordinates: billDetail.coordinates ?? [0, 0],
    promotion: billDetail.promotion,
    percentagePromotion: billDetail.percentagePromotion,
    promotionType: "percentagePromotion",
  };
  const handleCloseModal = () => {
    dispatch(
      setDiscountDirectModal({
        visible: false,
      })
    );
  };
  const handleConfirmDiscount = (values) => {
    values.promotion = parseFloat(values.promotion);
    // eslint-disable-next-line radix
    values.percentagePromotion = parseInt(values.percentagePromotion);
    if (values.promotionType === "percentagePromotion") {
      values.promotion = 0;
    } else {
      values.percentagePromotion = 0;
    }
    handleCloseModal();
    dispatch(updateBillRequest(values)).then(() =>
      dispatch(getBillDetailRequest({ orderId: idBill }))
    );
    Helper.toastr("Giảm giá thành công!", "Thông báo", "success");
  };

  return (
    <div>
      {visible && (
        <Modal
          title={i18n.t("billingList.discountDirectTitle")}
          visible={visible}
          width={860}
          className="market-product-modal-wrapper"
          footer={null}
          onCancel={handleCloseModal}
          maskClosable={false}
          closeIcon={<img src={BackIcon} alt="" />}
        >
          <DiscountDirectForm
            loading={isLoading}
            initialValues={initialValues}
            onSubmit={handleConfirmDiscount}
          />
        </Modal>
      )}
    </div>
  );
};

DiscountDirectModal.propTypes = {
  setDiscountDirectModal: PropTypes.func,
};

export default DiscountDirectModal;
