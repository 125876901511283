import { memo, useState } from "react";
import { Avatar, Button, Typography } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import moment from "moment";

import TagBillDetail from "components/TagBillDetail";
import TagTransactionType from "components/TagTransactionType";
import DefaultLogo from "assets/images/logovietgrp.svg";
import LastestBillingModal from "../BillingModal/index";
import "./styles.less";

const ConversationHeader = ({ conversationDetail = {}, latestBill = {} }) => {
  const [openLatestOrder, setOpenLatestOrder] = useState(false);
  const user = _get(conversationDetail, "users[0]", {});
  const updatedAt = _get(conversationDetail, "updatedAt");
  const avatar = _get(user, "userDetail.avatar") || DefaultLogo;

  const handleCloseLatestBillModal = () => {
    setOpenLatestOrder(false);
  };
  return (
    <div className="conversation-header-container flex flex-between">
      <div className="flex">
        <Avatar size={48} src={avatar} className="avatar" />
        <div className="conversation-header-content">
          <div className="username">{user.name}</div>
          <div className="message-date">
            {updatedAt ? moment(updatedAt).format("DD/MM/YYYY") : ""}
          </div>
        </div>
      </div>
      {!_isEmpty(latestBill) && (
        <div className="latest-order flex items-center">
          <div>
            <Typography.Text
              type="secondary"
              underline
              onClick={() => setOpenLatestOrder(!openLatestOrder)}
            >
              <strong>Đơn hàng mới nhất:{latestBill.orderId}</strong>{" "}
            </Typography.Text>
            <br />
            <Typography.Text type="secondary">
              <strong>
                Ngày đặt hàng:{" "}
                {moment(latestBill.createdAt).format("DD/MM/YYYY")}
              </strong>{" "}
            </Typography.Text>
          </div>
          <TagTransactionType status={latestBill.transactionType} />
          <TagBillDetail status={latestBill.status} />
          <a
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_DOMAIN_NAME}/fonehouse/billing_list/${latestBill._id}`}
          >
            <Button size="small" type="primary" style={{ width: "unset" }}>
              Xem chi tiết
            </Button>
          </a>
        </div>
      )}
      {latestBill && (
        <LastestBillingModal
          billDetail={latestBill}
          open={openLatestOrder}
          onClose={handleCloseLatestBillModal}
        />
      )}
    </div>
  );
};

ConversationHeader.propTypes = {
  conversationDetail: PropTypes.object.isRequired,
  latestBill: PropTypes.object,
};

export default memo(ConversationHeader);
