import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import _isEmpty from "lodash/isEmpty";
import Helper from "utils/helpers";
import { safe } from "providers/GeneralProvider/saga";
import {
  getAllGuideFonehouseRequest,
  getAllGuideFonehouseSuccess,
  stopLoading,
  getGuideFonehouseDetailRequest,
  getGuideFonehouseDetailSuccess,
  createGuideFonehouseSuccess,
  createGuideFonehouseRequest,
  updateGuideFonehouseRequest,
  updateGuideFonehouseSuccess,
  deleteGuideFonehouseRequest,
  deleteGuideFonehouseSuccess,
  pinGuideFonehouseRequest,
  pinGuideFonehouseSuccess,
} from "providers/GuideFonehouseProvider/slice";

function* getAllGuideFonehouse(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "keyword",
    "roles",
    "status",
    "seen",
  ]);
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  const { data } = yield call(
    request,
    "api/fonehouse/guide-manage/guides",
    params,
    { method: "GET" }
  );
  yield put(getAllGuideFonehouseSuccess(data));
}

function* createGuideFonehouse(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "type",
    "title",
    "thumbnail",
    "content",
  ]);
  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  const data = yield call(request, "api/fonehouse/guide-manage/guide", params, {
    method: "POST",
  });
  yield put(createGuideFonehouseSuccess(data, meta));
  Helper.toastr(
    `ID: #${data.data?.guildId}`,
    "Đăng huớng dẫn thành công",
    "success"
  );
}

function* getGuideFonehouseDetail(action) {
  const params = _pick(action.payload, ["guideId"]);
  const { data } = yield call(
    request,
    `api/fonehouse/guide-manage/guide/${params.guideId}`,
    undefined,
    { method: "GET" }
  );
  yield put(getGuideFonehouseDetailSuccess(data));
}

function* updateGuideFonehouse(action) {
  const { meta } = action;

  const params = _pick(action.payload, [
    "type",
    "title",
    "thumbnail",
    "content",
  ]);
  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  const result = yield call(
    request,
    `/api/fonehouse/guide-manage/guide/${action.payload.idGuild}`,
    params,
    {
      method: "PUT",
    }
  );
  yield put(updateGuideFonehouseSuccess(result, meta));
  Helper.toastr(
    `ID: #${action.payload.idGuild}`,
    "Cập nhật hướng dẫn thành công",
    "success"
  );
}

function* deleteGuideFonehouse(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["guideIds"]);
  const result = yield call(
    request,
    `api/fonehouse/guide-manage/guide`,
    params,
    { method: "DELETE" }
  );
  yield put(deleteGuideFonehouseSuccess(result, meta));
  Helper.toastr("Xóa hướng dẫn thành công", "success");
}

function* pinGuideFonehouse(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["guideId", "pin"]);
  const result = yield call(
    request,
    `api/fonehouse/guide-manage/guide/${params.guideId}`,
    { pin: params.pin },
    { method: "PATCH" }
  );
  yield put(pinGuideFonehouseSuccess(result, meta));
  Helper.toastr(
    `${params.pin === true ? "Ghim" : "Bỏ ghim"} hướng dẫn thành công,`,
    "success"
  );
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchAuth() {
  yield takeEvery(
    getAllGuideFonehouseRequest.type,
    safe(getAllGuideFonehouse, onError)
  );
  yield takeEvery(
    getGuideFonehouseDetailRequest.type,
    safe(getGuideFonehouseDetail)
  );
  yield takeLeading(
    updateGuideFonehouseRequest.type,
    safe(updateGuideFonehouse, onError)
  );
  yield takeLeading(
    createGuideFonehouseRequest.type,
    safe(createGuideFonehouse, onError)
  );
  yield takeLeading(
    deleteGuideFonehouseRequest.type,
    safe(deleteGuideFonehouse, onError)
  );
  yield takeLeading(
    pinGuideFonehouseRequest.type,
    safe(pinGuideFonehouse, onError)
  );
}
