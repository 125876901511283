/* eslint-disable */

import React from "react";

const Policy = () => {
  return (
    <div className='account-deletion-info'>
      <h1>Xóa Tài Khoản VieGo</h1>

      <div className='alert'>
        <strong>Lưu ý quan trọng:</strong>
        <p>
          Việc xóa tài khoản là không thể hoàn tác. Hãy chắc chắn rằng bạn muốn
          tiếp tục.
        </p>
      </div>

      <h2>Video hướng dẫn:</h2>
      <div className='video-container'>
        <a
          href='https://www.youtube.com/shorts/N5qVzUo9H4E'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src='https://img.youtube.com/vi/N5qVzUo9H4E/0.jpg'
            alt='Video hướng dẫn xóa tài khoản'
          />
          <div className='play-button'></div>
        </a>
        <p>Nhấp vào hình ảnh để xem video hướng dẫn chi tiết</p>
      </div>

      <h2>Quy trình xóa tài khoản:</h2>
      <ol>
        <li>Mở ứng dụng VieGo trên thiết bị của bạn</li>
        <li>Đăng nhập vào tài khoản của bạn (nếu chưa đăng nhập)</li>
        <li>Truy cập vào phần 'Cài đặt' hoặc 'Tài khoản'</li>
        <li>Tìm và chọn tùy chọn 'Xóa tài khoản'</li>
        <li>Đọc kỹ thông tin về hậu quả của việc xóa tài khoản</li>
        <li>
          Xác nhận yêu cầu xóa tài khoản bằng cách nhập mật khẩu hoặc mã xác
          nhận
        </li>
        <li>Nhấn nút 'Xác nhận xóa tài khoản'</li>
      </ol>

      <h2>Xử lý dữ liệu:</h2>
      <ul>
        <li>
          <strong>Dữ liệu bị xóa:</strong>
          <ul>
            <li>Thông tin cá nhân (tên, email, số điện thoại)</li>
            <li>Thông tin hồ sơ người dùng</li>
            <li>Lịch sử tương tác trong ứng dụng</li>
          </ul>
        </li>
        <li>
          <strong>Dữ liệu được giữ lại:</strong>
          <ul>
            <li>
              Dữ liệu giao dịch (nếu có) - lưu giữ trong thời gian theo quy định
              pháp luật Việt Nam
            </li>
            <li>
              Logs hệ thống ẩn danh - lưu giữ trong 30 ngày để phục vụ mục đích
              bảo mật và điều tra
            </li>
          </ul>
        </li>
      </ul>

      <p className='footer-note'>
        Nếu bạn gặp khó khăn trong quá trình xóa tài khoản hoặc có bất kỳ câu
        hỏi nào, vui lòng liên hệ với đội ngũ hỗ trợ của VieGo qua email
        support@vietgroup.jp hoặc qua mục 'Hỗ trợ' trong ứng dụng.
      </p>

      <style jsx>{`
        .account-deletion-info {
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
          font-family: Arial, sans-serif;
          line-height: 1.6;
          color: #333;
        }

        h1 {
          color: #1a73e8;
          font-size: 24px;
          margin-bottom: 20px;
        }

        h2 {
          color: #202124;
          font-size: 20px;
          margin-top: 20px;
          margin-bottom: 10px;
        }

        .alert {
          background-color: #fef7e0;
          border: 1px solid #ffeeba;
          border-radius: 4px;
          padding: 12px;
          margin-bottom: 20px;
        }

        .alert strong {
          color: #856404;
        }

        .video-container {
          position: relative;
          width: 100%;
          max-width: 480px;
          margin: 20px auto;
          text-align: center;
        }

        .video-container img {
          width: 100%;
          height: auto;
          border-radius: 8px;
        }

        .play-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 20px 0 20px 40px;
          border-color: transparent transparent transparent white;
          opacity: 0.8;
        }

        .video-container p {
          margin-top: 10px;
          font-style: italic;
        }

        ol,
        ul {
          margin-bottom: 20px;
          padding-left: 20px;
        }

        li {
          margin-bottom: 8px;
        }

        ul ul {
          margin-top: 8px;
        }

        .footer-note {
          font-size: 14px;
          color: #666;
          margin-top: 30px;
        }
      `}</style>
    </div>
  );
};

export default Policy;
