import { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import Helper from "utils/helpers";

const PrivateRoute = ({ Component, ...props }) => {
  const token = Helper.getAuthToken();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userRole = useSelector((state) => _get(state, "auth.currentUser.role"));
  const userRoles = useSelector((state) =>
    _get(state, "auth.currentUser.roles", [])
  );

  const sortedUserRoles = Helper.sortUserPermission(userRoles);
  const sortedUserRolesString = sortedUserRoles.toString();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
    if (userRole && (userRole === "user" || userRole === "admin")) {
      const allAccessibleRoutes = sortedUserRoles.map((role) => `/${role}`);
      const pathNameFormat = `/${pathname.split("/")[1]}`;
      if (userRole === "admin") {
        if (["/home", "/cms_admin_manager"].includes(pathNameFormat)) {
          navigate("/banners", { replace: true });
        }
      } else {
        if (
          sortedUserRoles[0] &&
          !allAccessibleRoutes.includes(pathNameFormat)
        ) {
          navigate(`/${sortedUserRoles[0]}`, { replace: true });
        }
        if (!sortedUserRoles[0]) {
          navigate("/404", { replace: true });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, navigate, userRole, sortedUserRolesString]);

  return <Component {...props} />;
};

PrivateRoute.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

export default PrivateRoute;
