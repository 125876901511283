import { createSlice } from "@reduxjs/toolkit";

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    isLoading: false,
    docs: [],
    totalDocs: 0,
    bannerDetail: {},
  },
  reducers: {
    getBannerListRequest(state) {
      return { ...state, isLoading: true };
    },
    getBannerListSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs,
      };
    },
    createBannerRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createBannerSuccess: {
      reducer: (state) => ({ ...state, isLoading: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateBannerRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateBannerSuccess: {
      reducer: (state) => ({ ...state, isLoading: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteBannerRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    deleteBannerSuccess: {
      reducer: (state) => ({ ...state, isLoading: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    getBannerDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getBannerDetailSuccess(state, action) {
      return { ...state, isLoading: false, bannerDetail: action.payload };
    },
    resetBannerDetail(state) {
      return { ...state, bannerDetail: {} };
    },
    stopLoading(state) {
      return { ...state, isLoading: false };
    },
  },
});

export const {
  getBannerListRequest,
  getBannerListSuccess,
  createBannerRequest,
  createBannerSuccess,
  updateBannerRequest,
  updateBannerSuccess,
  deleteBannerRequest,
  deleteBannerSuccess,
  getBannerDetailRequest,
  getBannerDetailSuccess,
  stopLoading,
  resetBannerDetail,
} = bannerSlice.actions;

export default bannerSlice.reducer;
