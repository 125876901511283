import SummaryTooltip from "components/SummaryTooltip";
import StoreSummaryTooltip from "components/SummaryTooltip/StoreSummaryTooltip";
import { STORE_TYPE, STORE_SUB_TYPE } from "utils/constants";

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || "Trống"}</span>;
};

const STORE_NAME = {
  [STORE_TYPE.TRAVEL]: "Du lịch",
  [STORE_TYPE.SHIPPER]: "Tên doanh nghiệp",
};

const Columns = ({ storeType }) => {
  const storeName = STORE_NAME[storeType] || "Tên cửa hàng";
  return {
    id: {
      dataIndex: "_id",
      title: "#",
      render: (id, data) =>
        renderDataValue(
          <SummaryTooltip
            placement="bottomLeft"
            component={<StoreSummaryTooltip detail={data} />}
            widthTooltipInner={680}
          >
            <div>#{id}</div>
          </SummaryTooltip>
        ),
      width: 120,
    },
    name: {
      dataIndex: "name",
      title: storeName,
      sorter: true,
      render: (name, data) =>
        renderDataValue(
          <SummaryTooltip
            placement="bottomLeft"
            component={<StoreSummaryTooltip detail={data} />}
            widthTooltipInner={680}
          >
            <div>{name}</div>
          </SummaryTooltip>
        ),
      width: 140,
    },
    phone: {
      dataIndex: "user",
      title: "Phone",
      sorter: true,
      render: (user, data) =>
        renderDataValue(
          <SummaryTooltip
            placement="bottomLeft"
            component={<StoreSummaryTooltip detail={data} />}
            widthTooltipInner={680}
          >
            <div>{user.phone}</div>
          </SummaryTooltip>
        ),
      width: 140,
    },
    email: {
      dataIndex: "user",
      title: "Email",
      sorter: true,
      render: (user, data) =>
        renderDataValue(
          <SummaryTooltip
            placement="bottomLeft"
            component={<StoreSummaryTooltip detail={data} />}
            widthTooltipInner={680}
          >
            <div>{user.email}</div>
          </SummaryTooltip>
        ),
      width: 140,
    },
    address: {
      dataIndex: "address",
      title: "Địa chỉ",
      sorter: true,
      render: (address, data) =>
        renderDataValue(
          <SummaryTooltip
            placement="bottomLeft"
            component={<StoreSummaryTooltip detail={data} />}
            widthTooltipInner={680}
          >
            <div>{address}</div>
          </SummaryTooltip>
        ),
      width: 140,
    },
    location: {
      dataIndex: "locationTags",
      title: "Địa điểm",
      render: (locationTags, data) =>
        renderDataValue(
          <SummaryTooltip
            placement="bottomLeft"
            component={<StoreSummaryTooltip detail={data} />}
            widthTooltipInner={680}
          >
            <div>{locationTags.map((tag) => tag.name || "").join(", ")}</div>
          </SummaryTooltip>
        ),
      width: 140,
    },
    type: {
      dataIndex: "subType",
      title: "Loại",
      render: (subType, data) =>
        renderDataValue(
          <SummaryTooltip
            placement="bottomLeft"
            component={<StoreSummaryTooltip detail={data} />}
            widthTooltipInner={680}
          >
            <div>{STORE_SUB_TYPE[subType]}</div>
          </SummaryTooltip>
        ),
      width: 120,
    },
    accountHolder: {
      dataIndex: "user",
      title: "Chủ tài khoản",
      render: (user, data) =>
        renderDataValue(
          <SummaryTooltip
            placement="bottomLeft"
            component={<StoreSummaryTooltip detail={data} />}
            widthTooltipInner={680}
          >
            <div>{user.name}</div>
          </SummaryTooltip>
        ),
      width: 140,
    },
    rating: {
      title: "Đánh giá",
      dataIndex: "rating",
      key: "rating",
      render: (rating) => renderDataValue(<div>{rating}</div>),
      width: 120,
    },
    status: {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (status, data) => {
        const { activeStatus } = data;
        const isOpen = activeStatus === "open";
        return renderDataValue(
          <div
            className={
              status === "active"
                ? `${isOpen ? "shop-opening" : "shop-closed"}`
                : "shop-lock"
            }
          >
            {status === "active" ? (
              <div>{isOpen ? "Mở cửa" : "Đóng cửa"}</div>
            ) : (
              "Khoá"
            )}
          </div>
        );
      },
      width: 120,
    },
    pin: {
      title: "Quảng cáo",
      dataIndex: "pin",
      width: 110,
      render: (pin) => {
        if (pin === 1) {
          return renderDataValue(
            <div className="store-advertising">Quảng cáo</div>
          );
        }
        return null;
      },
    },
    isPromotionActive: {
      title: "Đặc quyền VieGo",
      dataIndex: "isPromotionActive",
      width: 110,
      render: (isPromotionActive) => {
        if (isPromotionActive) {
          return renderDataValue(
            <div className="store-promotion">Đặc quyền VieGo</div>
          );
        }
        return null;
      },
    },
  };
};

export default Columns;
