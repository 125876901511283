import { useRef } from "react";
import { Row, Button, Typography } from "antd";
import { Formik, Form } from "formik";
import _get from "lodash/get";
import { useNavigate } from "react-router-dom";

import { TextInput, TextEditor, ImageUpload } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "components/BackButton";
import { createNewsRequest } from "providers/NewsProvider/slice";
import { createNewsValidate } from "./validate";
import "./styles.less";

const NewsCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCreating = useSelector((state) => _get(state, "news.isCreating"));
  const formikRef = useRef();

  const handleCreateNews = (values) => {
    dispatch(createNewsRequest(values)).then((data) =>
      navigate(`/news/${data._id}`, {
        state: { canGoBack: true },
        replace: true,
      })
    );
  };

  return (
    <div className="news-create-page">
      <Row className="news-create-header" justify="space-between">
        <Row>
          <BackButton />
          <Typography.Title level={4}>Đăng bài</Typography.Title>
        </Row>
        <Button
          loading={isCreating}
          size="large"
          type="primary"
          onClick={() => formikRef.current.handleSubmit()}
        >
          Đăng bài
        </Button>
      </Row>
      <div className="create-form-wrapper">
        <Formik
          innerRef={formikRef}
          initialValues={{ content: "", title: "", thumbnail: null }}
          validationSchema={createNewsValidate}
          onSubmit={handleCreateNews}
        >
          {() => (
            <Form>
              <Row justify="space-between">
                <TextInput name="title" placeholder="Tiêu đề" size="large" />
              </Row>
              <Row justify="space-between" className="editor-wrapper">
                <ImageUpload name="thumbnail" />
                <TextEditor name="content" />
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewsCreate;
