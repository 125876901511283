import { Row, Typography, Menu, Dropdown, Button, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import _get from "lodash/get";
import { ReportStoreManagerColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import { setSelectedBusinessIds } from "providers//AdminManagerProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import "./styles.less";
import i18n from "i18n";
import { createConversationRequest } from "providers/MessengerProvider/slice";
import {
  getListReportRequest,
  getUnreadReportRequest,
  setReadReportRecordRequest,
  setReportStoreManagerModal,
  updateReportStatusRequest,
} from "providers/ReportManagerProvider/slice";
import { IconSeeMore } from "assets/images";
import TabHeader from "components/TabHeader";
import { getUserDetailbyIdRequest } from "providers/AuthProvider/slice";
import { useEffect } from "react";
import ReportStoreManagerModal from "./ReportStoreManagerModal";
import SearchBar from "./SearchBar";

const { TabPane } = Tabs;
const ReportManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reportList = useSelector((state) => _get(state, "reportManager.docs"));
  const reportTotal = useSelector((state) =>
    _get(state, "reportManager.totalDocs")
  );
  const isLoading = useSelector((state) =>
    _get(state, "reportManager.isLoading")
  );
  const unreadSummary = useSelector((state) =>
    _get(state, "reportManager.unreadSummary")
  );
  const totalReport = useSelector((state) =>
    _get(state, "reportManager.totalReport")
  );
  const selectedRowKeys = useSelector((state) =>
    _get(state, "reportManager.selectedRowKeys", [])
  );
  const unreadReport = {
    All: totalReport,
    OTHER: unreadSummary.filter((item) => item.type === "OTHER")[0]?.count ?? 0,
    STORE_DOES_NOT_EXIST:
      unreadSummary.filter((item) => item.type === "STORE_DOES_NOT_EXIST")[0]
        ?.count ?? 0,
    WRONG_ADDRESS:
      unreadSummary.filter((item) => item.type === "WRONG_ADDRESS")[0]?.count ??
      0,
    WRONG_PHONE:
      unreadSummary.filter((item) => item.type === "WRONG_PHONE")[0]?.count ??
      0,
    INVALID_PROMOTION:
      unreadSummary.filter((item) => item.type === "INVALID_PROMOTION")[0]
        ?.count ?? 0,
    REQUEST_TO_BECOME_OWNER:
      unreadSummary.filter((item) => item.type === "REQUEST_TO_BECOME_OWNER")[0]
        ?.count ?? 0,
  };
  useEffect(() => {
    dispatch(getUnreadReportRequest());
  }, [dispatch]);
  const [searchObject, setSearch] = useQuery(getListReportRequest);
  const handleCreateConversation = (userId) => {
    dispatch(createConversationRequest({ receiverId: userId })).then(
      (result) => {
        navigate(`/fonehouse/messengers/${result._id}`);
      }
    );
  };

  const handleGoToStore = (store) => {
    switch (store.type) {
      case "Restaurants":
        navigate(`/restaurant/${store.ref._id}`, {
          state: { canGoBack: true },
        });
        break;
      case "Supermarkets":
        navigate(`/supermarket/${store.ref._id}`, {
          state: { canGoBack: true },
        });
        break;
      case "Entertainments":
        navigate(`/entertainmen/${store.ref._id}`, {
          state: { canGoBack: true },
        });
        break;
      case "Cosmetics":
        navigate(`/cosmetic/${store.ref._id}`, { state: { canGoBack: true } });
        break;
      default:
        break;
    }
  };
  const setReadRecord = (id) => {
    dispatch(setReadReportRecordRequest({ idPost: id }))
      .then(() => dispatch(getUnreadReportRequest()))
      .then(() => dispatch(getListReportRequest(searchObject)));
  };
  const handleSeeDetailReport = (record) => {
    setReadRecord(record._id);
    dispatch(getUserDetailbyIdRequest({ idUser: record?.reporter }));
    dispatch(
      setReportStoreManagerModal({
        visible: true,
        data: record,
      })
    );
  };
  const checkingUnreadReport = (isReadByAdmin) => {
    if (isReadByAdmin) {
      return "";
    }
    return "status-new";
  };

  const menu = (record) => {
    const { _id, store, reporter, type } = record;
    return (
      <Menu>
        {type === "REQUEST_TO_BECOME_OWNER" && (
          <Menu.Item onClick={() => handleSeeDetailReport(record)}>
            {i18n.t("reportManager.seeDetail")}
          </Menu.Item>
        )}
        {store?.ref?._id && (
          <Menu.Item
            onClick={() => {
              setReadRecord(_id);
              handleGoToStore(store);
            }}
          >
            {i18n.t("reportManager.goToStore")}
          </Menu.Item>
        )}

        <Menu.Item
          onClick={() => {
            setReadRecord(_id);
            handleCreateConversation(reporter);
          }}
        >
          {i18n.t("reportManager.message")}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            dispatch(
              setPopup({
                isOpen: true,
                data: {
                  title: "Xác nhận Duyệt Báo Cáo ?",
                  content: "Xác nhận duyệt báo cáo này?",
                  onOk: () =>
                    dispatch(
                      updateReportStatusRequest({
                        idPost: _id,
                        status: "APPROVED",
                      })
                    ).then(() => setReadRecord(_id)),
                },
              })
            )
          }
        >
          {i18n.t("reportManager.approveReport")}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            dispatch(
              setPopup({
                isOpen: true,
                data: {
                  title: "Xác nhận Từ chối Báo Cáo?",
                  content: "Xác nhận từ chối báo cáo này?",
                  onOk: () =>
                    dispatch(
                      updateReportStatusRequest({
                        idPost: _id,
                        status: "CANCEL",
                      })
                    ).then(() => setReadRecord(_id)),
                },
              })
            )
          }
        >
          {i18n.t("reportManager.cancelReport")}
        </Menu.Item>
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );
  const columns = [
    ReportStoreManagerColumn._id,
    ReportStoreManagerColumn.name,
    ReportStoreManagerColumn.reportType,
    ReportStoreManagerColumn.type,
    ReportStoreManagerColumn.phone,
    ReportStoreManagerColumn.address,
    ReportStoreManagerColumn.content,
    ReportStoreManagerColumn.status,
    {
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      dispatch(setSelectedBusinessIds({ record, selected, selectedRows }));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      dispatch(setSelectedBusinessIds({ selected, selectedRows, changeRows }));
    },
  };
  return (
    <div className="register-list-wrapper">
      <Row className="register-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("reportManager.listTitle")}
        </Typography.Title>
      </Row>
      <div className="content-container">
        <SearchBar />
        <ReportStoreManagerModal
          setReportStoreManagerModal={setReportStoreManagerModal}
        />
        <Tabs
          className="cosmetic-tabs-container"
          activeKey={searchObject.mine}
          type="card"
          style={{ marginTop: 12 }}
          onChange={(key) => {
            setSearch(
              {
                type: key,
                keyword: searchObject.keyword,
                status: searchObject.status,
                activeStatus: searchObject.activeStatus,
              },
              true
            );
          }}
        >
          {[
            "",
            "STORE_DOES_NOT_EXIST",
            "WRONG_ADDRESS",
            "WRONG_PHONE",
            "INVALID_PROMOTION",
            "REQUEST_TO_BECOME_OWNER",
            "OTHER",
          ].map((value) => (
            <TabPane
              className="status-tab-pane"
              tab={
                <TabHeader
                  unreadNumbers={unreadReport[value]}
                  status={value}
                  total={100}
                />
              }
              key={value}
            >
              <Table
                rowSelection={rowSelection}
                scroll={{ x: 1000 }}
                loading={isLoading}
                bordered
                rowClassName={(data) =>
                  checkingUnreadReport(data.isReadByAdmin)
                }
                columns={columns}
                dataSource={reportList}
                rowKey={(data) => data._id}
                total={reportTotal}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default ReportManager;
