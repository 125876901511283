import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Row, Typography } from "antd";
import { useEffect, useCallback } from "react";
import _get from "lodash/get";

import BackButton from "components/BackButton";
import SimForm from "components/Sim/Form";
import {
  createSimRequest,
  updateSimRequest,
  getSimTypesRequest,
  getSimDetailRequest,
  getSimDetailSuccess,
} from "providers/SimProvider/slice";
import "./styles.less";

const SimDetail = ({ isCreating = false }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const canGoBack = _get(location, "state.canGoBack");
  const connectionFeePromotionList = useSelector((state) =>
    _get(state, "sim.connectionFeeDiscount", [])
  );
  const priceDiscountPromotionList = useSelector((state) =>
    _get(state, "sim.priceDiscount", [])
  );
  const { simTypes, detail, isLoading } = useSelector((state) => state.sim);
  const initialValues = {
    name: _get(detail, "name"),
    type: _get(detail, "type._id"),
    initialPrice: _get(detail, "initialPrice"),
    price: _get(detail, "price"),
    initialConnectionFee: _get(detail, "initialConnectionFee"),
    connectionFee: _get(detail, "connectionFee"),
    description: _get(detail, "description"),
  };

  const refetchTypes = useCallback(() => {
    dispatch(
      getSimTypesRequest({
        page: 1,
        limit: 9999,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    refetchTypes();
  }, [refetchTypes]);

  useEffect(() => {
    if (id && !isCreating) {
      dispatch(
        getSimDetailRequest({
          _id: id,
        })
      );
    }
    return () => {
      dispatch(getSimDetailSuccess({}));
    };
  }, [id, isCreating, dispatch]);

  const handleSubmit = (values) => {
    const params = values;
    params.connectionFeeDiscount = connectionFeePromotionList;
    params.priceDiscount = priceDiscountPromotionList;

    if (isCreating) {
      dispatch(createSimRequest(params)).then(() => {
        if (canGoBack) {
          navigate(-1);
        } else {
          navigate("/sim", {
            replace: true,
          });
        }
      });
    } else {
      dispatch(updateSimRequest({ ...params, _id: id })).then(() => {
        if (canGoBack) {
          navigate(-1);
        } else {
          navigate("/sim", {
            replace: true,
          });
        }
      });
    }
  };
  return (
    <div className="sim-create-wrapper">
      <Row className="sim-create-header" align="middle">
        <BackButton />
        <Typography.Title level={4}>
          {isCreating ? "Tạo Sim mới" : "Chỉnh sửa"}
        </Typography.Title>
      </Row>
      <div className="detail-wrapper">
        <SimForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          detail={detail}
          simTypes={simTypes}
          loading={isLoading}
          isCreating={isCreating}
          refetchTypes={refetchTypes}
        />
      </div>
    </div>
  );
};

export default SimDetail;
