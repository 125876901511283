import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import { safe } from "providers/GeneralProvider/saga";
import _pick from "lodash/pick";
import _get from "lodash/get";
import Helper from "utils/helpers";

import {
  getBannerListRequest,
  getBannerListSuccess,
  createBannerRequest,
  createBannerSuccess,
  updateBannerRequest,
  updateBannerSuccess,
  deleteBannerRequest,
  deleteBannerSuccess,
  getBannerDetailRequest,
  getBannerDetailSuccess,
  stopLoading,
} from "./slice";

function* getBannerList(action) {
  const params = _pick(action.payload, ["page", "limit"]);
  const { data } = yield call(
    request,
    "/api/fonehouse/banner-manager/banners/admin",
    params,
    { method: "GET" }
  );
  yield put(getBannerListSuccess(data));
}

function* createBanner(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["name", "type", "url", "image"]);
  if (params.image) {
    params.image = params.image.url;
  }
  const { data } = yield call(
    request,
    "api/fonehouse/banner-manager/banner",
    params,
    { method: "POST" }
  );
  Helper.toastr(
    `ID:${_get(data, "bannerId")}`,
    "Tạo banner thành thông",
    "success"
  );
  yield put(createBannerSuccess({}, meta));
}

function* updateBanner(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "name",
    "type",
    "url",
    "image",
    "idBanner",
    "active",
  ]);
  if (params.image) {
    params.image = params.image.url;
  }
  yield call(request, "api/fonehouse/banner-manager/banner", params, {
    method: "PUT",
  });
  Helper.toastr(
    `ID:${_get(action, "payload.bannerId")}`,
    "Cập nhật banner thành thông",
    "success"
  );
  yield put(updateBannerSuccess({}, meta));
}

function* deleteBanner(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idBanner"]);
  yield call(request, "api/fonehouse/banner-manager/banner", params, {
    method: "DELETE",
  });
  Helper.toastr(
    `ID:${_get(action, "payload.bannerId")}`,
    "Xóa banner thành thông",
    "success"
  );
  yield put(deleteBannerSuccess({}, meta));
}

function* getBannerDetail(action) {
  const params = _pick(action.payload, ["idBanner"]);
  const { data } = yield call(
    request,
    "api/fonehouse/banner-manager/banner",
    params,
    { method: "GET" }
  );
  yield put(getBannerDetailSuccess(data));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchBanner() {
  yield takeEvery(getBannerListRequest.type, safe(getBannerList, onError));
  yield takeLeading(createBannerRequest.type, safe(createBanner, onError));
  yield takeLeading(updateBannerRequest.type, safe(updateBanner, onError));
  yield takeLeading(deleteBannerRequest.type, safe(deleteBanner, onError));
  yield takeLeading(
    getBannerDetailRequest.type,
    safe(getBannerDetail, onError)
  );
}
