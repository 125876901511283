import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Typography, Menu, Dropdown, Button } from "antd";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _uniq from "lodash/uniq";

import {
  MARKET_PRODUCT_MODAL_TYPE,
  ROLES,
  STORE_TYPE,
  DELETE_BANNER_CONTENT,
} from "utils/constants";
import BackButton from "components/BackButton";
import {
  getMarketProductListRequest,
  setSelectedIds,
  setMarketProductModal,
  deleteProductMarketRequest,
  setTagProductArticleModalData,
  setTagProductMarketRequest,
} from "providers/MarketProductProvider/slice";
import {
  getStoreDetailRequest,
  updateStoreRequest,
  changeStoreOwnerRequest,
  resetStoreState,
  createStoreBannerRequest,
  deleteStoreBannerRequest,
  editStoreBannerRequest,
  getStoreBannerListRequest,
} from "providers/StoreProvider/slice";
import SuperMarketDetailComponent from "components/Supermarket/Detail";
import SuperMarketEdit from "components/Supermarket/Edit";
import Table from "components/common/Table";
import { useQuery } from "hooks";
import { MarketProductColumn } from "components/Columns";
import i18n from "i18n";
import { IconSeeMore } from "assets/images";
import { resetSelectedRowKey } from "providers/AdminProvider/slice";
import {
  setPopup,
  setChangeStoreOnwerModal,
} from "providers/GeneralProvider/slice";
import SetTagProductArticleModal from "components/SetTagProductArticleModal";
import ArticleShopOwner from "components/ArticleShopOwner";
import { Tabs } from "components/common";
import StoreBannerList from "components/StoreBanner/List";
import StoreBannerDetail from "components/StoreBanner/Detail";
import Helper from "utils/helpers";
import moment from "moment";
import SearchBar from "./SearchBar";
import MarketProductModal from "../MarketProduct/marketProductModal";
import "./styles.less";

const SupermarketDetail = () => {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const { detail, isLoading, banners, isLoadingBanner } = useSelector(
    (state) => state.store
  );
  const [currentTab, setCurrentTab] = useState("detail");
  const [banner, setBanner] = useState({});
  const { userId = {} } = detail;
  const idCurrentUser = useSelector((state) =>
    _get(state, "auth.currentUser._id")
  );
  const roleCurrentUser = useSelector((state) =>
    _get(state, "auth.currentUser.role")
  );
  const isPermissionToEdit =
    idCurrentUser === userId._id ||
    [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(roleCurrentUser);
  const selectedRowKeys = useSelector((state) =>
    _get(state, "marketProduct.selectedRowKeys", [])
  );
  const selectedRows = useSelector((state) =>
    _get(state, "marketProduct.selectedRows", [])
  );

  const productMarketIdList = [];
  const productMarketNameList = [];
  selectedRows.map((item) => productMarketIdList.push(item.productMarketId));
  selectedRows.map((item) => productMarketNameList.push(item.name));

  const { idMarket } = useParams();
  const [searchObject, setSearch] = useQuery(getMarketProductListRequest, {
    arrayParams: ["productType"],
    customParameter: {
      storeId: idMarket,
    },
  });

  useEffect(() => {
    if (idMarket) {
      dispatch(getStoreDetailRequest({ _id: idMarket }));
      dispatch(getStoreBannerListRequest({ storeId: idMarket }));
    }
    return () => {
      dispatch(resetStoreState());
    };
  }, [idMarket, dispatch]);

  const menuAll = () => (
    <Menu>
      <Menu.Item
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: "Xác nhận",
                cancelText: "Huy",
                title: "Xác nhận xóa sản phẩm",
                content: `Bạn có muốn xóa: ${productMarketNameList.join(", ")}`,
                onOk: () =>
                  dispatch(
                    deleteProductMarketRequest({
                      storeId: idMarket,
                      productIds: [selectedRowKeys],
                    })
                  ).then(() =>
                    dispatch(getMarketProductListRequest(searchObject))
                  ),
              },
            })
          )
        }
        disabled={_isEmpty(selectedRowKeys)}
      >
        {i18n.t("marketProduct.delete")}
      </Menu.Item>
      <Menu.Item
        disabled={_isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            setTagProductArticleModalData({
              visible: true,
              data: {
                products: selectedRows,
                originalProducts: selectedRows,
              },
            })
          )
        }
      >
        {i18n.t("products.setTag")}
      </Menu.Item>
    </Menu>
  );
  const menu = (record) => {
    const { _id, name, productMarketId } = record;
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            dispatch(
              setMarketProductModal({
                visible: true,
                data: record,
                type: MARKET_PRODUCT_MODAL_TYPE.DETAIL,
              })
            )
          }
        >
          {i18n.t("marketProduct.seeDetail")}
        </Menu.Item>
        {isPermissionToEdit && (
          <>
            <Menu.Item
              onClick={() =>
                dispatch(
                  setMarketProductModal({
                    visible: true,
                    data: record,
                    type: MARKET_PRODUCT_MODAL_TYPE.EDIT,
                  })
                )
              }
            >
              {i18n.t("marketProduct.edit")}
            </Menu.Item>
            <Menu.Item
              className="delete-product-btn"
              onClick={() =>
                dispatch(
                  setPopup({
                    isOpen: true,
                    data: {
                      okText: "Xác nhận",
                      cancelText: "Huy",
                      title: "Xác nhận xóa sản phẩm",
                      content: `Bạn có muốn xóa: ${name}`,
                      onOk: () =>
                        dispatch(
                          deleteProductMarketRequest({
                            storeId: idMarket,
                            productIds: [_id],
                          })
                        ).then(() =>
                          dispatch(getMarketProductListRequest(searchObject))
                        ),
                    },
                  })
                )
              }
            >
              {i18n.t("marketProduct.delete")}
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                dispatch(
                  setTagProductArticleModalData({
                    visible: true,
                    data: {
                      products: [record],
                      originalProducts: [record],
                    },
                  })
                )
              }
            >
              {i18n.t("products.setTag")}
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const marketProductList = useSelector((state) =>
    _get(state, "marketProduct.docs")
  );
  const marketProductTotal = useSelector((state) =>
    _get(state, "marketProduct.totalDocs")
  );
  const visible = useSelector(
    (state) => state.marketProduct.setTagProductArticleModalData.visible
  );
  const products = useSelector((state) =>
    _get(state.marketProduct.setTagProductArticleModalData, "data.products", [])
  );
  const originalProducts = useSelector((state) =>
    _get(
      state.marketProduct.setTagProductArticleModalData,
      "data.originalProducts",
      []
    )
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedIds({ record, selected, selectedRows: _selectedRows })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedIds({ selected, selectedRows: _selectedRows, changeRows })
      );
    },
  };

  const columns = [
    MarketProductColumn.productId,
    MarketProductColumn.name,
    MarketProductColumn.type,
    MarketProductColumn.mass,
    MarketProductColumn.amount,
    MarketProductColumn.promotion,
    MarketProductColumn.price,
    MarketProductColumn.promotionPrice,
    MarketProductColumn.tag,
    {
      title: (
        <Dropdown
          overlay={isPermissionToEdit ? menuAll : ""}
          placement="bottomLeft"
          arrow={isPermissionToEdit}
        >
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  const handleSubmit = async (values) => {
    const latLng = await Helper.getLatLng(values.address, {
      lng: values.lng,
      lat: values.lat,
    });
    values.longitude = latLng.lng;
    values.latitude = latLng.lat;

    if (
      values?.discountPercentage?.value === detail?.discountPercentage?.value
    ) {
      values.discountPercentage = detail?.discountPercentage?.value;
    }
    if (values?.start) {
      values.customDiscountCondition = {
        start: values?.start,
        end: values?.end,
      };
    }

    dispatch(
      updateStoreRequest({
        ...values,
        _id: idMarket,
        type: STORE_TYPE.MARKET,
      })
    ).then(() => {
      dispatch(getStoreDetailRequest({ _id: idMarket }));
      setShowEdit(false);
    });
  };

  const handleChangeStoreOnwer = ({ type, idNewOnwer }) => {
    dispatch(
      changeStoreOwnerRequest({
        type,
        _id: idMarket,
        ownerId: idNewOnwer,
      })
    ).then(() => {
      dispatch(resetSelectedRowKey());
      dispatch(getStoreDetailRequest({ _id: idMarket }));
      dispatch(setChangeStoreOnwerModal({ isOpen: false }));
    });
  };

  const handleChangeTab = (activeKey) => {
    setCurrentTab(activeKey);
    setBanner({});
  };

  const handleSubmitBanner = (values) => {
    const imageUrl = _get(values, "imageUrl.url", "");
    if (banner.imageUrl === null) {
      dispatch(
        createStoreBannerRequest({ ...values, imageUrl, storeId: idMarket })
      ).then(() => {
        dispatch(getStoreBannerListRequest({ storeId: idMarket }));
      });
    } else {
      dispatch(
        editStoreBannerRequest({
          ...values,
          imageUrl,
          storeId: idMarket,
          bannerId: banner._id,
        })
      ).then(() => {
        dispatch(getStoreBannerListRequest({ storeId: idMarket }));
      });
    }
    setBanner({});
  };

  const handleRemoveBanner = (record) => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          ...DELETE_BANNER_CONTENT,
          onOk: () =>
            dispatch(
              deleteStoreBannerRequest({
                storeId: idMarket,
                bannerId: record._id,
              })
            ).then(() => {
              dispatch(getStoreBannerListRequest({ storeId: idMarket }));
            }),
        },
      })
    );
  };

  const handleToggleBannerStatus = (record) => {
    dispatch(
      editStoreBannerRequest({
        ...record,
        storeId: idMarket,
        bannerId: record._id,
        isActive: !record.isActive,
      })
    ).then(() => {
      dispatch(getStoreBannerListRequest({ storeId: idMarket }));
    });
  };

  return (
    <div className="supermarket-detail-wrapper">
      <SetTagProductArticleModal
        category="supermarket"
        setTagProductArticleModalData={setTagProductArticleModalData}
        visible={visible}
        products={_uniq(products)}
        originalProducts={_uniq(originalProducts)}
        setTagProductArticleRequest={setTagProductMarketRequest}
        getProductArticleListRequest={getMarketProductListRequest}
        searchObject={searchObject}
      />
      <MarketProductModal
        isPermissionToEdit={isPermissionToEdit}
        searchObject={searchObject}
        setMarketProductModal={setMarketProductModal}
        setSearch={setSearch}
      />
      <Row
        className="supermarket-detail-header"
        justify="space-between"
        align="middle"
      >
        <Row>
          <BackButton />
          <Typography.Title level={4}>Thông tin siêu thị</Typography.Title>
        </Row>
      </Row>
      <div className="detail-wrapper">
        <ArticleShopOwner
          category="supermarket"
          shopOwnerInfoDetail={_get(detail, "userId", {})}
          handleChangeStoreOnwer={handleChangeStoreOnwer}
        />
        <Tabs
          type="card"
          activeKey={currentTab}
          onChange={handleChangeTab}
          destroyInactiveTabPane
          contents={[
            {
              key: "detail",
              header: "Thông tin cơ bản",
              content: showEdit ? (
                <SuperMarketEdit
                  initialValues={detail}
                  onCancel={() => setShowEdit(false)}
                  onSubmit={handleSubmit}
                />
              ) : (
                <SuperMarketDetailComponent
                  isPermissionToEdit={isPermissionToEdit}
                  detail={detail}
                  onEdit={() => setShowEdit(true)}
                />
              ),
            },
            {
              key: "banner",
              header: "Set banner",
              content: _isEmpty(banner) ? (
                <StoreBannerList
                  list={banners}
                  total={banners.length}
                  loading={isLoadingBanner}
                  handleOpenBannerDetail={(data) => setBanner(data)}
                  handleRemoveBanner={handleRemoveBanner}
                  handleToggleStatus={handleToggleBannerStatus}
                />
              ) : (
                <StoreBannerDetail
                  initialValues={banner}
                  isEditBanner={banner.imageUrl !== null}
                  handleCancel={() => setBanner({})}
                  handleSubmit={handleSubmitBanner}
                />
              ),
            },
          ]}
        />
        <div className="content-container">
          <SearchBar isPermissionToEdit={isPermissionToEdit} />
          <Table
            rowSelection={rowSelection}
            scroll={{ x: 1000 }}
            loading={isLoading}
            bordered
            columns={columns}
            dataSource={marketProductList}
            rowKey={(data) => data._id}
            total={marketProductTotal}
          />
        </div>
      </div>
    </div>
  );
};

export default SupermarketDetail;
