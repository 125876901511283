import { useRef, useCallback } from "react";
import { useField } from "formik";
import { Form } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import Helper from "utils/helpers";
import "./styles.less";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module";

Quill.register("modules/imageResize", ImageResize);

const EditorContainer = ({ name }) => {
  const [field, meta, helper] = useField(name);
  const isError = meta.touched && meta.error;
  const quillRef = useRef();

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();

      formData.append("image", file);
      const quillEditor = quillRef.current.getEditor();
      const range = quillEditor.getSelection(true);
      quillEditor.insertEmbed(
        range.index,
        "image",
        `${window.location.origin}/images/loaders/placeholder.gif`
      );
      quillEditor.setSelection(range.index + 1);
      const res = await Helper.uploadFile({ file }); // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
      quillEditor.deleteText(range.index, 1);
      quillEditor.insertEmbed(range.index, "image", res.url);
    };
  }, []);

  return (
    <div className="editor-container">
      <Form.Item
        validateStatus={isError ? "error" : ""}
        help={
          <div className="helper-wrapper">
            <div className="error-text">
              {isError && (
                <>
                  <InfoCircleOutlined
                    className="info-icon"
                    type="info-circle"
                  />
                  {meta.error}
                </>
              )}
            </div>
          </div>
        }
      >
        <ReactQuill
          ref={quillRef}
          value={
            (field.value === "<p><br></p>" ? "<p> </p>" : field.value) || ""
          }
          onChange={(e) => helper.setValue(e)}
          formats={[
            "background",
            "bold",
            "color",
            "font",
            "code",
            "italic",
            "link",
            "size",
            "strike",
            "script",
            "underline",
            "blockquote",
            "header",
            "indent",
            "list",
            "align",
            "direction",
            "code-block",
            "formula",
            "image",
            "width",
            "style",
            "height",
            "video",
          ]}
          modules={{
            toolbar: {
              container: [
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ size: ["small", false, "large", "huge"] }, { color: [] }],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                  { align: [] },
                ],
                ["link", "image", "video"],
                ["clean"],
              ],
              handlers: {
                image: imageHandler,
              },
            },
            imageResize: {
              handleStyles: {
                displaySize: true,
                backgroundColor: "black",
                border: "none",
                color: "white",
              },
              modules: ["Resize", "DisplaySize", "Toolbar"],
            },
            clipboard: { matchVisual: false },
          }}
        />
      </Form.Item>
    </div>
  );
};

EditorContainer.propTypes = {
  name: PropTypes.string,
};
export default EditorContainer;
