import React, { useRef } from "react";
import { Modal, Button, Row } from "antd";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "antd/dist/antd.css";
import "./styles.less";
import { useSelector, useDispatch } from "react-redux";
import {
  triggerModalSetTag,
  createTagRequest,
} from "providers/MessengerProvider/slice";
import { Select } from "components/common";

const SetTagModal = () => {
  const {
    isOpenModalSetTag,
    conversationId,
    conversationName,
    fonehouseTagsSelect,
  } = useSelector((state) => state.messenger);
  const dispatch = useDispatch();
  const formikRef = useRef();

  const handleCloseModal = () => {
    dispatch(
      triggerModalSetTag({
        visible: false,
      })
    );
  };

  const onSubmit = (values) => {
    handleCloseModal();
    dispatch(
      createTagRequest({
        conversationId,
        name: values.tagName.split("-")[0],
        color: values.tagName.split("-")[1],
      })
    );
  };

  return (
    <div>
      {isOpenModalSetTag && (
        <Modal
          title={`Gắn nhãn dán cho ${conversationName}`}
          visible={isOpenModalSetTag}
          onCancel={handleCloseModal}
          footer={null}
        >
          <Formik
            innerRef={formikRef}
            initialValues={{ tagName: "" }}
            validationSchema={Yup.object({
              tagName: Yup.string().required("Yêu cầu dữ liệu"),
            })}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group">
                  <label>Tên nhãn dán</label>
                  <Select
                    helpComponent={<div>CC</div>}
                    name="tagName"
                    options={fonehouseTagsSelect}
                  />
                </div>
                {formikRef.current?.values?.tagName && (
                  <div>
                    <label>Màu nhãn dán</label>
                    <Row style={{ alignItems: "center" }}>
                      <div
                        style={{
                          borderRadius: 8,
                          width: 100,
                          height: 30,
                          backgroundColor:
                            formikRef.current?.values?.tagName.split("-")[1],
                        }}
                      />
                      <div style={{ marginLeft: 5 }}>
                        - {formikRef.current?.values?.tagName.split("-")[0]}
                      </div>
                    </Row>
                  </div>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                  style={{ width: "100%", marginTop: 10 }}
                >
                  XÁC NHẬN
                </Button>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </div>
  );
};

export default SetTagModal;
