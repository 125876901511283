import { useEffect } from "react";
import { useRoutes, useLocation, useNavigate } from "react-router-dom";
import _get from "lodash/get";
import Modal from "components/Modal";
import { getCurrentUserRequest } from "providers/AuthProvider/slice";
import { getProductTypeRequest } from "providers/ProductProvider/slice";
import { getCountNotSeenNotificationRequest } from "providers/NotificationProvider/slice";
import Helper from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import SocketClient from "utils/socket";
import indexRoutes from "./routes/indexRoutes";

const App = () => {
  const dispatch = useDispatch();
  const element = useRoutes(indexRoutes);
  const navigate = useNavigate();
  window.navigate = navigate;
  const location = useLocation();
  const { search, pathname } = location;
  const token = Helper.getAuthToken();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userId = _get(currentUser, "_id");
  useEffect(() => {
    dispatch(getCurrentUserRequest());
    dispatch(getProductTypeRequest());
    if (token) {
      dispatch(getCountNotSeenNotificationRequest());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    SocketClient.connect();
    return () => {
      SocketClient.disconnect();
    };
  }, [userId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  return (
    <div className="app-container">
      <Modal />
      {element}
    </div>
  );
};

export default App;
