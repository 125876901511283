import { createSlice } from "@reduxjs/toolkit";
import _uniqBy from "lodash/uniqBy";
import _get from "lodash/get";
import { NOTIFICATION_LIST_LIMIT } from "utils/constants";

const todosSlice = createSlice({
  name: "notification",
  initialState: {
    notificationList: [],
    total: 0,
    isLoading: false,
    hasMore: false,
    page: 1,
    unreadDataNumber: 0,
    isOpenNotification: false,
  },
  reducers: {
    getNotificationListRequest(state) {
      return { ...state, isLoading: true };
    },
    getNotificationListSuccess(state, action) {
      const notificationList = _get(action.payload, "docs", []);
      const notificationPage = _get(action.payload, "page", 1);
      state.loading = false;
      state.error = false;
      if (notificationList.length < NOTIFICATION_LIST_LIMIT) {
        state.hasMore = false;
      } else {
        state.hasMore = true;
      }
      state.notificationList =
        notificationPage === 1
          ? notificationList
          : _uniqBy(state.notificationList.concat(notificationList), "_id");
      state.page = notificationPage;
      return state;
      // return { ...state, isLoading: false, notificationList, total };
    },
    getCountNotSeenNotificationRequest(state) {
      return { ...state, isLoading: true };
    },
    getCountNotSeenNotificationSuccess(state, action) {
      const unreadDataNumber = action.payload;
      return { ...state, unreadDataNumber, isLoading: false };
    },
    updateSeenNotificationRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateSeenNotificationSuccess: {
      reducer: (state, action) => {
        const notificationList = state.notificationList.map((item) => {
          if (item._id === _get(action, "payload.notificationId", "")) {
            return {
              ...item,
              seen: item.seen.concat(_get(action, "payload.currentUserId", "")),
            };
          }
          return item;
        });
        return { ...state, notificationList, isLoading: false };
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    setOpenNotificationPopover(state, action) {
      return { ...state, isOpenNotification: action.payload.isOpen };
    },
    stopLoading(state) {
      return { ...state, isLoading: false };
    },
  },
});

export const {
  getNotificationListRequest,
  getNotificationListSuccess,
  getCountNotSeenNotificationRequest,
  getCountNotSeenNotificationSuccess,
  updateSeenNotificationRequest,
  updateSeenNotificationSuccess,
  setOpenNotificationPopover,
  stopLoading,
} = todosSlice.actions;

export default todosSlice.reducer;
