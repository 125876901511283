import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";

const liquidWithViegoManager = createSlice({
  name: "liquidWithViegoManager",
  initialState: {
    isLoading: false,
    liquidWithViegoList: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    selectedRowKeys: [],
    selectedRows: [],
    isDisableActionAll: false,
  },
  reducers: {
    getLiquidWithViegoListRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getLiquidWithViegoListSuccess(state, action) {
      const { docs, totalDocs } = action.payload.data;
      const transferData = docs.map((item) => ({
        ...item,
        _id: item.transaction._id,
      }));
      return {
        ...state,
        isLoading: false,
        liquidWithViegoList: transferData,
        totalDocs,
        selectedRowKeys: [],
      };
    },
    changeStateOfLiquidWithViegoRequest(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    setSelectedIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      state.isDisableActionAll = state.selectedRows.some(
        (item) => item?.transaction?.status
      );
      return state;
    },
    requestFail(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getLiquidWithViegoListRequest,
  getLiquidWithViegoListSuccess,
  requestFail,
  changeStateOfLiquidWithViegoRequest,
  setSelectedIds,
} = liquidWithViegoManager.actions;

export default liquidWithViegoManager.reducer;
