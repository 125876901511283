import { put, takeLeading, call } from "redux-saga/effects";
import request from "utils/api";
import { safe } from "providers/GeneralProvider/saga";
import {
  getRewardPointListRequest,
  getRewardPointListSuccess,
  requestFail,
  exchangePointsToUserRequest,
  getRewardBalanceRequest,
  getRewardBalanceSuccess,
} from "providers/RewardPointManager/slice";

function* handleGetRewardPointList(action) {
  try {
    const { page, limit, type } = action.payload;
    let endpoint = `/api/reward/user-transfers?page=${page}&limit=${limit}`;
    if (type) {
      switch (type) {
        case "POINT_PERSONAL_MANAGE":
          endpoint = `/api/reward/user-transfers?page=${page}&limit=${limit}`;
          break;

        case "POINT_STORE_MANAGE":
          endpoint = `/api/reward/store-transfers?page=${page}&limit=${limit}`;
          break;

        case "LIQUID_VIEGO":
          endpoint = `/api/reward/withdrawals?page=${page}&limit=${limit}`;
          break;

        default:
          break;
      }
    }
    const response = yield call(request, endpoint, undefined, {
      method: "GET",
    });
    yield put(
      getRewardPointListSuccess({
        ...response,
        type: type ?? "POINT_PERSONAL_MANAGE",
      })
    );
  } catch (error) {
    yield put(requestFail());
  }
}

function* handleExchangePointsToUser(action) {
  try {
    const { type, fromId, toId, amount, callback } = action.payload;
    const endpoint = "/api/reward/transfers";
    const params = { type, fromId, toId, amount };
    yield call(request, endpoint, params, {
      method: "POST",
    });
    yield callback && callback();
  } catch (error) {
    yield put(requestFail());
  }
}

function* handleGetRewardBalance() {
  try {
    const endpoint = "/api/reward/accounts";
    const response = yield call(request, endpoint, undefined, {
      method: "GET",
    });
    yield put(getRewardBalanceSuccess(response));
  } catch (error) {
    yield put(requestFail());
  }
}

function* onError() {
  yield put(requestFail());
}

export default function* watchRewardPoint() {
  yield takeLeading(
    getRewardPointListRequest.type,
    safe(handleGetRewardPointList, onError)
  );
  yield takeLeading(
    exchangePointsToUserRequest.type,
    safe(handleExchangePointsToUser, onError)
  );
  yield takeLeading(
    getRewardBalanceRequest.type,
    safe(handleGetRewardBalance, onError)
  );
}
