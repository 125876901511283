import { memo } from "react";
import { Avatar, Row } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import moment from "moment";
import DefaultLogo from "assets/images/logovietgrp.svg";
import "./styles.less";
import { isArray } from "lodash";
import { TagOutlined, DeleteOutlined } from "@ant-design/icons";

const ConversationCell = ({
  data,
  onClick,
  className = "",
  currentUserId,
  onClickTag,
  onDeleteTag,
}) => {
  const messageCreatedDate = _get(data, "latestMessage.createdAt");
  const unseenMessages = _get(data, "unseenMessages");
  const user = _get(data, "users[0]", {});
  const tags = _get(data, "tags", []);
  const avatar = _get(user, "userDetail.avatar") || DefaultLogo;

  const latestSenderName =
    _get(data, "latestMessage.sender") !== currentUserId ? user.name : "Bạn";
  let latestMessage = _get(data, "latestMessage.text");
  if (!_isEmpty(_get(data, "latestMessage.attachments", []))) {
    latestMessage = `${latestSenderName} vừa gửi tệp`;
  } else if (_get(data, "latestMessage.type") === "product") {
    latestMessage = `${latestSenderName} vừa gửi sản phẩm`;
  }

  return (
    <div
      className={`conversation-cell-container ${className} ${
        unseenMessages ? "unseen-conversation" : ""
      }`}
      onClick={onClick}
    >
      <Avatar size={40} src={avatar} className="avatar" />
      <div className="conversation-cell-content">
        {isArray(tags) && tags.length > 0 && (
          <Row>
            {tags.map((tag, index) => {
              if (tag && tag.name.trim() !== "") {
                return (
                  <div
                    key={index}
                    className="vip-view"
                    style={{
                      borderColor: tag.color,
                      color: tag.color,
                      marginRight: 5,
                    }}
                  >
                    {tag.name}
                  </div>
                );
              }
              return null;
            })}
          </Row>
        )}
        <div className="username">{user.name}</div>
        <div className="last-message">{latestMessage}</div>
        <div className="message-date">
          {messageCreatedDate
            ? moment(messageCreatedDate).format("DD/MM/YYYY")
            : ""}
        </div>
      </div>
      <div onClick={() => onClickTag && onClickTag(data)}>
        <TagOutlined />
      </div>
      <div
        style={{ marginLeft: 10 }}
        onClick={() => onDeleteTag && onDeleteTag(data)}
      >
        <DeleteOutlined />
      </div>
    </div>
  );
};

ConversationCell.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  currentUserId: PropTypes.string.isRequired,
};

export default memo(ConversationCell);
