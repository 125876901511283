import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Image } from "antd";
import _get from "lodash/get";

import {
  createStorePhotoRequest,
  getStorePhotoListRequest,
} from "providers/StoreProvider/slice";
import { Tabs, Table } from "components/common";
import { StorePhotoColumn } from "components/Columns";
import { IconSeeMore } from "assets/images";
import ImageGalleryForm from "./form";
import MenuOverlay from "./MenuOverlay";
import "./styles.less";

const StoreImageGallery = ({ storeId }) => {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [currentTab, setCurrentTab] = useState("gallery");
  const { photos } = useSelector((state) => state.store);

  const refetchList = useCallback(() => {
    if (storeId) {
      dispatch(getStorePhotoListRequest({ _id: storeId }));
    }
  }, [dispatch, storeId]);

  useEffect(() => {
    refetchList();
  }, [refetchList]);

  const handleChangeTab = (activeKey) => {
    setCurrentTab(activeKey);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
  };

  const handleUpdatePhoto = (values) => {
    const params = {
      ...values,
      imageUrl: _get(values, "imageUrl.url", ""),
    };
    dispatch(
      createStorePhotoRequest({
        ...params,
        storeId,
      })
    ).then(() => {
      refetchList();
      setShowEdit(false);
    });
  };

  return (
    <Tabs
      className="store-image-gallery-wrapper"
      type="card"
      activeKey={currentTab}
      onChange={handleChangeTab}
      destroyInactiveTabPane
      contents={[
        {
          key: "gallery",
          header: "Bộ sưu tập",
          content: showEdit ? (
            <ImageGalleryForm
              initialValues={{ photos: [] }}
              onCancel={handleCloseEdit}
              onSubmit={handleUpdatePhoto}
            />
          ) : (
            <div className="image-gallery-grid">
              <div className="gallery-header">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => setShowEdit(true)}
                >
                  Thêm ảnh
                </Button>
              </div>
              {photos.map((photo) => (
                <Image key={photo._id} src={photo.imageUrl} />
              ))}
            </div>
          ),
        },
        {
          key: "contribution",
          header: "Danh sách đóng góp",
          content: (
            <Table
              scroll={{ x: "max-content" }}
              loading={false}
              bordered
              columns={[
                StorePhotoColumn.id,
                StorePhotoColumn.accountHolder,
                StorePhotoColumn.accountEmail,
                StorePhotoColumn.accountPhone,
                StorePhotoColumn.accountAddress,
                StorePhotoColumn.comment,
                StorePhotoColumn.status,
                {
                  title: "",
                  width: 100,
                  render: (record) => (
                    <Dropdown
                      overlay={
                        <MenuOverlay
                          record={record}
                          storeId={storeId}
                          refetchList={refetchList}
                        />
                      }
                      placement="bottomLeft"
                      arrow
                    >
                      <Button
                        className="see-more-btn"
                        type="ghost"
                        icon={<img src={IconSeeMore} alt="see-more" />}
                      />
                    </Dropdown>
                  ),
                },
              ]}
              dataSource={photos}
              rowKey={(data) => data._id}
              total={photos.length}
            />
          ),
        },
      ]}
    />
  );
};

export default StoreImageGallery;
