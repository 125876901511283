import { memo, useRef, useLayoutEffect } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import Spin from "antd/lib/spin";
import Row from "antd/lib/row";
import MessageCell from "./MessageCell";

const MessageList = ({
  messageIds = [],
  messageEntities = {},
  getMessageList,
  hasMore,
  currentUserId,
  currentUserRole,
}) => {
  const latestMessage = messageIds[0];
  const messageListRef = useRef();

  useLayoutEffect(() => {
    messageListRef.current.scrollTo(0, 0);
  }, [latestMessage]);

  return (
    <div
      className="message-list-container"
      id="scrollable-message-list"
      ref={messageListRef}
    >
      <InfiniteScroll
        scrollThreshold="100px"
        dataLength={messageIds.length} // This is important field to render the next data
        next={getMessageList}
        hasMore={hasMore}
        loader={
          <Row style={{ padding: 8 }} justify="center">
            <Spin />
          </Row>
        }
        style={{ display: "flex", flexDirection: "column-reverse" }} // To put endMessage and loader to the top.
        inverse //
        scrollableTarget="scrollable-message-list"
      >
        {messageIds.map((id) => {
          const senderId =
            _get(messageEntities, `${id}.sender._id`) ||
            _get(messageEntities, `${id}.sender`);
          return (
            <MessageCell
              isOwnCell={
                senderId === currentUserId ||
                (["admin", "super_admin"].includes(currentUserRole) &&
                  _get(messageEntities[id], "isAdmin"))
              }
              key={id}
              data={messageEntities[id]}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

MessageList.propTypes = {
  messageIds: PropTypes.array.isRequired,
  messageEntities: PropTypes.object.isRequired,
  getMessageList: PropTypes.func,
  hasMore: PropTypes.bool,
  currentUserId: PropTypes.string,
  currentUserRole: PropTypes.string,
};

export default memo(MessageList);
