import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Typography } from "antd";
// import LoadingIndicator from 'components/LoadingIndicator';
import "./styles.less";
import BackButton from "components/BackButton";
import CosmeticEdit from "components/Cosmetic/Edit";
import { createStoreRequest } from "providers/StoreProvider/slice";
import { STORE_TYPE } from "utils/constants";
import Helper from "utils/helpers";
import moment from "moment";

const CosmeticCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    open: 0,
    close: 0,
    banners: null,
    thumbnail: null,
    address: "",
    phone: "",
    startDay: "",
    endDay: "",
    description: "",
  };

  const handleSubmit = async (values) => {
    const latLng = await Helper.getLatLng(values.address, {
      lng: values.lng,
      lat: values.lat,
    });
    values.longitude = latLng.lng;
    values.latitude = latLng.lat;
    if (values.start) {
      values.customDiscountCondition = {
        start: values.start,
        end: values.end,
      };
    }
    dispatch(
      createStoreRequest({
        type: STORE_TYPE.COSMETIC,
        data: values,
      })
    ).then((data) => {
      navigate(`/cosmetic/${data._id}`, {
        state: { canGoBack: true },
        replace: true,
      });
    });
  };
  return (
    <div className="cosmetic-detail-wrapper">
      <Row
        className="cosmetic-detail-header"
        justify="space-between"
        align="middle"
      >
        <Row>
          <BackButton />
          <Typography.Title level={4}>Tạo cửa hàng làm đẹp</Typography.Title>
        </Row>
      </Row>
      <div className="detail-wrapper">
        <CosmeticEdit
          initialValues={initialValues}
          onSubmit={handleSubmit}
          isCreating
        />
      </div>
    </div>
  );
};

export default CosmeticCreate;
