import moment from "moment";
import React from "react";
import _get from "lodash/get";
import { Image } from "antd";
import { useNavigate } from "react-router-dom";

const getTransactionText = (status) => {
  switch (status) {
    case "Daibiki":
      return "Daibiki";
    case "cash":
      return "GD cửa hàng";
    case "transfer":
      return "Trả trước";
    default:
      return "---";
  }
};

const getTransactionColor = (status) => {
  switch (status) {
    case "Daibiki":
      return "orange";
    case "cash":
      return "green";
    case "transfer":
      return "#20BFD8";
    default:
      return "---";
  }
};

const OrderMessage = ({ orderDetail }) => {
  const navigate = useNavigate();
  const containerStyle = {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    marginTop: "5px",
    width: "450px",
    cursor: "pointer",
  };

  const headerStyle = {
    textAlign: "center",
    marginBottom: "20px",
  };

  const statusStyle = {
    color: "green",
  };

  const orderIdStyle = {
    fontWeight: "bold",
  };

  const orderStatusStyle = {
    float: "right",
    color: "grey",
  };

  const detailsStyle = {
    display: "flex",
    marginBottom: "20px",
  };

  const productInfoStyle = {
    position: "relative",
    width: "100px",
    flex: 1,
  };

  const productLabelStyle = {
    backgroundColor: "orange",
    padding: "5px",
    borderRadius: "5px",
    color: "#fff",
    display: "flex", // Make the container a flexbox
    alignItems: "center", // Center items vertically
    justifyContent: "center", // Center items horizontally
    width: "100%", // Ensure the label takes the full width of the parent
    marginTop: "5px",
  };

  const storeInfoStyle = {
    marginLeft: "20px",
    flex: 2,
  };

  const customerInfoStyle = {
    marginBottom: "20px",
  };

  const customerInfoTextStyle = {
    margin: "5px 0",
  };

  const totalStyle = {
    textAlign: "center",
    fontSize: "1.2em",
  };

  const totalAmountStyle = {
    color: "blue",
    fontWeight: "bold",
  };

  const deliveryFeeStyle = {
    color: "red",
    fontWeight: "bold",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <div
        style={containerStyle}
        onClick={() => {
          navigate(`/fonehouse/billing_list/${orderDetail._id}`);
        }}
      >
        <div style={headerStyle}>
          <h2>
            🎉 Bạn đã đặt <span style={statusStyle}>hoàn tất</span> đơn hàng 🎉
          </h2>
          <p>
            Mã đơn hàng{" "}
            <span style={orderIdStyle}>#{orderDetail?.orderId}</span>{" "}
            <span style={orderStatusStyle}>Chờ xác nhận</span>
          </p>
        </div>
        <div style={detailsStyle}>
          <div style={productInfoStyle}>
            <Image
              width={100}
              src={
                orderDetail && orderDetail?.products[0]?.imageMachine
                  ? orderDetail?.products[0]?.imageMachine
                  : "https://via.placeholder.com/100"
              }
            />

            <div
              style={{
                ...productLabelStyle,
                backgroundColor: getTransactionColor(
                  orderDetail.transactionType
                ),
              }}
            >
              {getTransactionText(orderDetail.transactionType)}
            </div>
          </div>
          <div style={storeInfoStyle}>
            <p>Cửa hàng: App</p>
            <p>
              Đặt ngày:{" "}
              {moment(orderDetail?.createdAt).format("DD/MM/yyyy HH:mm") ??
                "---"}
            </p>
            <p>Địa chỉ: {orderDetail?.address ?? "---"}</p>
          </div>
        </div>
        <div style={customerInfoStyle}>
          <p style={customerInfoTextStyle}>
            Tên khách hàng: {orderDetail.name}
          </p>
          <p style={customerInfoTextStyle}>
            Số điện thoại: {orderDetail?.phone ? orderDetail?.phone : "---"}
          </p>
          <p style={customerInfoTextStyle}>
            Mã đơn hàng:{" "}
            {orderDetail?.orderIdCustom ? orderDetail?.orderIdCustom : "---"}
          </p>
          <p style={customerInfoTextStyle}>
            Mã bưu kiện:{" "}
            {orderDetail?.postalCode ? orderDetail?.postalCode : "---"}
          </p>
          <p style={customerInfoTextStyle}>
            Giờ nhận hàng:{" "}
            {orderDetail?.timeFrame ? orderDetail?.timeFrame : "---"}
          </p>
          <p>
            Điểm nhận được:{" "}
            <span style={deliveryFeeStyle}>
              {orderDetail?.rewardedAmount
                ? orderDetail?.rewardedAmount
                : "---"}
            </span>
          </p>
        </div>
        <div style={totalStyle}>
          <p>
            Tổng tiền:
            <span style={totalAmountStyle}>
              {orderDetail?.totalPrice?.toLocaleString()} JPY
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderMessage;
