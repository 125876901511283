import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";

const identifyUserSlice = createSlice({
  name: "identifyUser",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    decentralizationModal: {
      isOpen: false,
    },
    unreadSummary: [],
    totalRating: 0,
    rater: {},
  },
  reducers: {
    getListIdentifyUserRequest(state) {
      return { ...state, isLoading: true };
    },
    getListIdentifyUserSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs,
      };
    },

    stopLoading(state) {
      return { ...state, isLoading: false };
    },
    setSelectedIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    updateIdentifyUserStatusRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateIdentifyUserStatusSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (meta) => ({ meta }),
    },
    resetSelectedRowKey: {
      reducer: (state) => {
        state.isLoading = false;
        state.selectedRows = [];
        return state;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
});

export const {
  getListIdentifyUserRequest,
  getListIdentifyUserSuccess,
  updateIdentifyUserStatusRequest,
  updateIdentifyUserStatusSuccess,
  stopLoading,
  setSelectedIds,
  resetSelectedRowKey,
} = identifyUserSlice.actions;

export default identifyUserSlice.reducer;
