import { object, string, number, date, ref } from "yup";
import i18n from "i18n";

export const simFormValidate = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
  type: string().required(i18n.t("errors.required")),
  price: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(0, i18n.t("errors.invalid"))
    .required(i18n.t("errors.required")),
  initialPrice: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid")),
  connectionFee: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid")),
  initialConnectionFee: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid")),
  description: string(),
});

export const simTypeFormValidate = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
});
export const promotionSimValidate = object().shape({
  displayText: string().trim().required(i18n.t("errors.required")),
  discountValue: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(0, i18n.t("errors.invalid"))
    .required(i18n.t("errors.required")),
  description: string(),
  discountPercentage: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(0, i18n.t("errors.invalid"))
    .max(100, i18n.t("errors.invalid"))
    .required(i18n.t("errors.required")),
  start: date()
    .min(new Date(), "Ngày bắt đầu không thể ở quá khứ")
    .required(i18n.t("errors.required")),
  end: date()
    .min(ref("start"), "Ngày kết thúc phải lớn hơn ngày bắt đầu")
    .required(i18n.t("errors.required")),
});
