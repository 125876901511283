import { useEffect, useRef, useCallback } from "react";
import _ from "lodash";

import useIsMounted from "./useIsMounted";

const useDebounceCallback = (cb, delay) => {
  const inputsRef = useRef(cb);
  const isMounted = useIsMounted();
  useEffect(() => {
    inputsRef.current = { cb, delay };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    _.debounce((...args) => {
      // Don't execute callback, if (1) component in the meanwhile
      // has been unmounted or (2) delay has changed
      if (inputsRef.current.delay === delay && isMounted()) {
        inputsRef.current.cb(...args);
      }
    }, delay),
    [delay, _.debounce]
  );
};

export default useDebounceCallback;
