import { useState } from "react";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { ROLES_LIST, ROLES_LIST_PERMISSION } from "utils/constants";
import { updateRolesRequest } from "providers/AdminProvider/slice";
import { Button, Checkbox } from "antd";

const DecentralizationForm = (props) => {
  const { roles, idUser } = props;
  const docs = useSelector((state) => _get(state, "admin.docs"));
  const isLoading = useSelector((state) => _get(state, "admin.isLoading"));
  const [selected, setSelected] = useState(roles);

  const dispatch = useDispatch();
  const handleUpdateRole = () => {
    dispatch(updateRolesRequest({ roles: selected, idUser, docs }));
  };

  return (
    <div className="decentralization-form">
      <Formik
        validateOnMount
        initialValues={{ roles: roles || [] }}
        onSubmit={handleUpdateRole}
      >
        <Form>
          <div className="checkbox-container">
            {ROLES_LIST_PERMISSION.map((option) => (
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setSelected((_selected) =>
                      _selected.concat([option.value])
                    );
                  } else {
                    setSelected((_selected) =>
                      _selected.filter(
                        (selectedOpt) => selectedOpt !== option.value
                      )
                    );
                  }
                }}
                key={option.value}
                checked={(selected || []).includes(option.value)}
              >
                {option.label}
              </Checkbox>
            ))}
          </div>
          <Button
            className="decentralization-btn"
            htmlType="submit"
            block
            type="primary"
            loading={isLoading}
          >
            PHÂN QUYỀN
          </Button>
        </Form>
      </Formik>
    </div>
  );
};

DecentralizationForm.propTypes = {
  roles: PropTypes.array,
  idUser: PropTypes.string,
};

export default DecentralizationForm;
