import { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Input, Form, Image, Row } from "antd";
import MaskedInput from "antd-mask-input";
import i18n from "i18n";
import _isEmpty from "lodash/isEmpty";
import PlacesAutocomplete from "react-places-autocomplete";
import HidePasswordIcon from "assets/images/hide-password-icon.svg";
import ShowPasswordIcon from "assets/images/show-password-icon.svg";
import "../style.less";

const TextInput = forwardRef(
  (
    {
      label,
      maxLength,
      inputType,
      size = "large",
      className = "",
      required,
      disabled,
      name,
      showError = true,
      helpComponent = null,
      ...props
    },
    ref
  ) => {
    const [field, meta, helper] = useField(name);
    const isError = meta.touched && meta.error;
    const handleChange = (address) => {
      helper.setValue(address);
    };

    const handleSelect = (address) => {
      helper.setValue(address);
    };
    const renderInput = () => {
      if (inputType === "password") {
        return (
          <Input.Password
            maxLength={maxLength}
            size={size}
            disabled={disabled}
            iconRender={(visible) =>
              visible ? (
                <Image
                  src={ShowPasswordIcon}
                  alt="show-password-icon"
                  preview={false}
                />
              ) : (
                <Image
                  src={HidePasswordIcon}
                  alt="hide-password-icon"
                  preview={false}
                />
              )
            }
            ref={ref}
            {...field}
            {...props}
          />
        );
      }
      if (inputType === "textarea") {
        return (
          <Input.TextArea
            maxLength={maxLength}
            disabled={disabled}
            {...field}
            {...props}
            ref={ref}
          />
        );
      }
      if (inputType === "search") {
        return (
          <Input.Search
            maxLength={maxLength}
            disabled={disabled}
            {...field}
            {...props}
            ref={ref}
          />
        );
      }
      if (inputType === "maskInput") {
        return (
          <MaskedInput
            maxLength={maxLength}
            disabled={disabled}
            {...field}
            {...props}
            ref={ref}
          />
        );
      }
      if (inputType === "autoComplete") {
        return (
          <PlacesAutocomplete
            value={field.value || ""}
            onChange={handleChange}
            onSelect={handleSelect}
            debounce={500}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div>
                <Input
                  maxLength={maxLength}
                  disabled={disabled}
                  size={size}
                  {...field}
                  {...props}
                  ref={ref}
                  {...getInputProps({
                    placeholder: "",
                    className: "location-search-input",
                  })}
                  autoComplete="off"
                />
                {!_isEmpty(suggestions) && (
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion) => {
                      const _className = suggestion.active
                        ? "suggestion-item suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <Row
                          key={suggestion.description}
                          {...getSuggestionItemProps(suggestion, {
                            className: _className,
                            styles: {
                              ...style,
                              borderBottom: "1px solid black",
                            },
                          })}
                        >
                          {suggestion.description}
                        </Row>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        );
      }
      return (
        <Input
          maxLength={maxLength}
          disabled={disabled}
          size={size}
          {...field}
          {...props}
          ref={ref}
        />
      );
    };
    return (
      <div
        className={`input-container text-field-container ${className} ${
          disabled ? "disabled-input" : ""
        }`}
      >
        {label && (
          <div className="label-wrapper">
            <>{label}</>
            {required && <div className="required-mark">*</div>}
          </div>
        )}
        <Form.Item
          validateStatus={isError ? "error" : ""}
          help={
            showError ? (
              <div className="error-text">
                {isError &&
                  meta.error !== i18n.t("message.required") &&
                  meta.error}
              </div>
            ) : (
              helpComponent
            )
          }
        >
          {renderInput()}
        </Form.Item>
      </div>
    );
  }
);
TextInput.displayName = "TextInput";

TextInput.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  inputType: PropTypes.string,
  size: PropTypes.string,
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  helpComponent: PropTypes.any,
  name: PropTypes.string,
};

export default memo(TextInput);
