import { Formik, Form } from "formik";
import RadioField from "components/common/Form/Radio";
import { Button, Checkbox } from "antd";
import { useSelector } from "react-redux";
import "./styles.less";
import i18n from "i18n";
import PropTypes from "prop-types";
import { PRODUCT_TAG_TEXT } from "utils/constants";
import Helper from "utils/helpers";
import { setTagValidate } from "./validate";

const TagForm = ({
  initialValues,
  onTagChange,
  tagAll,
  handleTagAllChange,
  onSubmit,
}) => {
  const isSettingTag = useSelector((state) => state.product.isSettingTag);
  return (
    <div className="set-tag-form">
      <Formik
        validateOnMount
        initialValues={initialValues}
        enableReinitialize
        validationSchema={setTagValidate}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid }) => (
          <Form>
            <RadioField
              onChange={onTagChange}
              name="tag"
              options={Helper.convertObjectToOptions(PRODUCT_TAG_TEXT)}
            />
            <div className="submit-wrapper">
              <div className="set-all-wrapper">
                <Checkbox onChange={handleTagAllChange} checked={tagAll}>
                  {i18n.t("products.setTagForAll")}
                </Checkbox>
              </div>
              <Button
                loading={isSettingTag}
                disabled={!isValid}
                htmlType="submit"
                type="primary"
                onClick={handleSubmit}
              >
                {i18n.t("products.setTag").toUpperCase()}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

TagForm.propTypes = {
  initialValues: PropTypes.object,
  onTagChange: PropTypes.func,
  handleTagAllChange: PropTypes.func,
  tagAll: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
export default TagForm;
