import { memo } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import Spin from "antd/lib/spin";
import Row from "antd/lib/row";
import { useDispatch } from "react-redux";
import {
  triggerModalSetTag,
  createTagRequest,
} from "providers/MessengerProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import ConversationCell from "./ConversationCell";
import "./styles.less";

const ConversationList = ({
  conversationIds,
  conversationEntities,
  onConversationCellClick,
  activeConversationId,
  getConversationList,
  hasMore = true,
  currentUserId,
}) => {
  const dispatch = useDispatch();

  const _onDeleteTag = (data) => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          title: "Xoá tag",
          content: "Bạn có muốn xoá tag cho người dùng này?",
          okText: "OK",
          cancelText: "Huỷ",
          onOk: () => {
            dispatch(
              createTagRequest({
                conversationId: data._id,
                name: " ",
                color: "blue",
              })
            );
          },
        },
      })
    );
  };

  return (
    <div
      className="conversation-list-container"
      id="scrollable-conversation-list"
    >
      <InfiniteScroll
        scrollThreshold="100px"
        dataLength={conversationIds.length} // This is important field to render the next data
        next={getConversationList}
        hasMore={hasMore}
        loader={
          <Row style={{ padding: 8 }} justify="center">
            <Spin />
          </Row>
        }
        scrollableTarget="scrollable-conversation-list"
      >
        {conversationIds.map((id) => (
          <ConversationCell
            className={activeConversationId === id ? "active-conversation" : ""}
            onClick={() => onConversationCellClick(id)}
            key={id}
            data={conversationEntities[id]}
            currentUserId={currentUserId}
            onClickTag={(data) => {
              dispatch(
                triggerModalSetTag({
                  visible: true,
                  data,
                })
              );
            }}
            onDeleteTag={_onDeleteTag}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

ConversationList.propTypes = {
  conversationIds: PropTypes.array.isRequired,
  conversationEntities: PropTypes.object.isRequired,
  onConversationCellClick: PropTypes.func.isRequired,
  activeConversationId: PropTypes.string.isRequired,
  getConversationList: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  currentUserId: PropTypes.string.isRequired,
};

export default memo(ConversationList);
