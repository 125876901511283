import { createSlice } from "@reduxjs/toolkit";
import _unionBy from "lodash/unionBy";

const initialState = {
  isLoading: false,
  list: [],
  total: 0,
  detail: {},
  simTypes: [],
  simPostDetail: {},
  simRegistrationDetail: {},
  wifiRegistrationDetail: {},
  internetRegistrationDetail: {},
  connectionFeeDiscount: [],
  priceDiscount: [],
  isOpenAddingCustomField: false,
  orderId: "",
  unReadNumberSim: {},
};

const simSlice = createSlice({
  name: "sim",
  initialState,
  reducers: {
    // CREATE
    createSimRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createSimSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    // UPDATE
    updateSimRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateSimSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    // GET_LIST
    getSimListRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    getSimListSuccess: {
      reducer: (state, action) => {
        const { docs, totalDocs } = action.payload;
        return {
          ...state,
          isLoading: false,
          list: docs,
          total: totalDocs,
        };
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },

    // GET_DETAIL
    getSimDetailRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    getSimDetailSuccess: {
      reducer: (state, action) => ({
        ...state,
        isLoading: false,
        detail: action.payload,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },

    // DELETE
    deleteSimRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    deleteSimSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    // DELETE SIM POST
    deleteSimPostRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    deleteSimPostSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    // RESTORE
    restoreSimRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    restoreSimSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    // RESTORE POST
    restoreSimPostRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    restoreSimPostSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    // GET_TYPE
    getSimTypesRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    getSimTypesSuccess: {
      reducer: (state, action) => {
        const { docs } = action.payload;
        return {
          ...state,
          isLoading: false,
          simTypes: docs,
        };
      },
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    // CREATE CONNECTIVITY_PRODUCT_POST
    createConnectivityProductPostRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createConnectivityProductPostSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    // CREATE_TYPE
    createSimTypeRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    createSimTypeSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    // UPDATE_TYPE
    updateSimTypeRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    updateSimTypeSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    // DELETE_TYPE
    deleteSimTypeRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    deleteSimTypeSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    // CHANGE_STATUS
    changeSimStatusRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    changeSimStatusSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    // ADDING_CUSTOM_FIELD
    updateCustomFieldRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    updateCustomFieldSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    // GET_REGISTRATION_DETAIL
    getSimRegistrationDetailRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    getSimRegistrationDetailSuccess: {
      reducer: (state, action) => ({
        ...state,
        isLoading: false,
        simRegistrationDetail: action.payload,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    // GET_INTERNET_REGISTRATION_DETAIL
    getInternetRegistrationDetailRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    getInternetRegistrationDetailSuccess: {
      reducer: (state, action) => ({
        ...state,
        isLoading: false,
        internetRegistrationDetail: action.payload,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    // GET_WIFI_REGISTRATION_DETAIL
    getWifiRegistrationDetailRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    getWifiRegistrationDetailSuccess: {
      reducer: (state, action) => ({
        ...state,
        isLoading: false,
        wifiRegistrationDetail: action.payload,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    updateSimPostRequest: {
      reducer: (state) => {
        state.isCreating = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateSimPostSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    getUnReadNumberSimTabRequest(state) {
      return { ...state, isLoading: true };
    },
    getUnReadNumberSimTabSuccess(state, action) {
      return { ...state, unReadNumberSim: action.payload };
    },
    getSimPostDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getSimPostDetailSuccess(state, action) {
      return { ...state, isLoading: false, simPostDetail: action.payload };
    },
    setReadWifiRecordRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    setReadWifiRecordSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (meta) => ({ meta }),
    },
    setReadInternetRecordRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    setReadInternetRecordSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (meta) => ({ meta }),
    },
    openAddingCustomFieldModal(state, action) {
      return {
        ...state,
        isOpenAddingCustomField: action.payload,
      };
    },

    updateOrderID(state, action) {
      return {
        ...state,
        orderId: action.payload,
      };
    },

    updateConnectionFeePromotion(state, action) {
      return {
        ...state,
        connectionFeeDiscount: _unionBy(state.connectionFeeDiscount, [
          action.payload,
        ]),
      };
    },
    getInitialArrayPromotion(state, action) {
      return {
        ...state,
        connectionFeeDiscount: action.payload.connectionFeeDiscount,
        priceDiscount: action.payload.priceDiscount,
      };
    },
    removeConnectionFeePromotion(state, action) {
      return {
        ...state,
        connectionFeeDiscount: state.connectionFeeDiscount.filter(
          (item, index) => index !== action.payload
        ),
      };
    },
    updatePriceDiscountPromotion(state, action) {
      return {
        ...state,
        priceDiscount: _unionBy(state.priceDiscount, [action.payload]),
      };
    },
    removePriceDiscountPromotion(state, action) {
      return {
        ...state,
        priceDiscount: state.priceDiscount.filter(
          (item, index) => index !== action.payload
        ),
      };
    },
    resetPromotionValues(state) {
      return { ...state, priceDiscount: [], connectionFeeDiscount: [] };
    },
    stopLoading(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateRegisteredSimRequest(state, action) {
      return { ...state };
    },
    updateRegisteredSimSuccess(state, action) {
      return { ...state };
    },
  },
});

export const {
  getUnReadNumberSimTabRequest,
  getUnReadNumberSimTabSuccess,
  updateOrderID,
  openAddingCustomFieldModal,
  updateCustomFieldRequest,
  updateCustomFieldSuccess,
  getInitialArrayPromotion,
  removeConnectionFeePromotion,
  removePriceDiscountPromotion,
  resetPromotionValues,
  updatePriceDiscountPromotion,
  updateConnectionFeePromotion,
  setReadWifiRecordRequest,
  setReadWifiRecordSuccess,
  setReadInternetRecordRequest,
  setReadInternetRecordSuccess,
  restoreSimPostRequest,
  restoreSimPostSuccess,
  deleteSimPostRequest,
  deleteSimPostSuccess,
  updateSimPostRequest,
  updateSimPostSuccess,
  getSimPostDetailRequest,
  getSimPostDetailSuccess,
  createConnectivityProductPostRequest,
  createConnectivityProductPostSuccess,
  createSimRequest,
  createSimSuccess,
  updateSimRequest,
  updateSimSuccess,
  getSimListRequest,
  getListConnectivityProductPostRequest,
  getListConnectivityProductPostSuccess,
  getSimListSuccess,
  getSimDetailRequest,
  getSimDetailSuccess,
  deleteSimRequest,
  deleteSimSuccess,
  restoreSimRequest,
  restoreSimSuccess,
  getSimTypesRequest,
  getSimTypesSuccess,
  createSimTypeRequest,
  createSimTypeSuccess,
  updateSimTypeRequest,
  updateSimTypeSuccess,
  deleteSimTypeRequest,
  deleteSimTypeSuccess,
  changeSimStatusRequest,
  changeSimStatusSuccess,
  getSimRegistrationDetailRequest,
  getSimRegistrationDetailSuccess,
  getInternetRegistrationDetailRequest,
  getInternetRegistrationDetailSuccess,
  getWifiRegistrationDetailRequest,
  getWifiRegistrationDetailSuccess,
  stopLoading,
  updateRegisteredSimRequest,
  updateRegisteredSimSuccess,
} = simSlice.actions;

export default simSlice.reducer;
