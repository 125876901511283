import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";
import { resetStoreState } from "providers/StoreProvider/slice";

const restaurantProductSlice = createSlice({
  name: "restaurantProduct",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    marketId: null,
    detail: {},
    marketProductModalData: {},
    productMarketTypeList: [],
    setTagProductArticleModalData: {},
    isSettingTag: false,
  },
  reducers: {
    getRestaurantProductListRequest(state) {
      return { ...state, isLoading: true };
    },
    getRestaurantProductListSuccess(state, action) {
      const { payload = {} } = action;
      const { products, total } = payload;
      return {
        ...state,
        marketId: _get(products, "[0]._id"),
        isLoading: false,
        docs: products.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs: total,
      };
    },

    setSelectedIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isSettingTag: false };
    },
    updateStatusRestaurantRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },

    updateStatusRestaurantSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
        selectedRowKeys: [],
        selectedRows: [],
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateRestaurantProductRequest: {
      reducer: (state) => {
        state.isCreating = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateRestaurantProductSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    getRestaurantDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getRestaurantDetailSuccess(state, action) {
      return { ...state, isLoading: false, detail: action.payload };
    },

    setMarketProductModal(state, action) {
      const { payload = {} } = action;
      const { visible, data, type } = payload;
      return {
        ...state,
        marketProductModalData: {
          visible,
          data,
          type,
        },
      };
    },
    getProductRestaurantTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    getProductRestaurantTypeSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        productMarketTypeList: action.payload,
      };
    },
    addProductRestaurantTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    addProductRestaurantTypeSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        productMarketTypeList: state.productMarketTypeList.concat(
          action.payload
        ),
      };
    },
    createProductRestaurantRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createProductRestaurantSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateProductRestaurantRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateProductRestaurantSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteProductRestaurantRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    deleteProductRestaurantSuccess: {
      reducer: (state) => ({
        ...state,
        selectedRowKeys: [],
        selectedRows: [],
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteProductRestaurantTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    deleteProductRestaurantTypeSuccess(state) {
      return { ...state, isLoading: false };
    },
    editProductRestaurantTypeRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    editProductRestaurantTypeSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    setTagProductArticleModalData(state, action) {
      const { payload = {} } = action;
      const { visible, data } = payload;
      return {
        ...state,
        setTagProductArticleModalData: {
          visible,
          data: data || state.setTagProductArticleModalData.data,
        },
      };
    },
    setTagProductRestaurantRequest: {
      reducer: (state) => {
        state.isSettingTag = true;
        state.selectedRowKeys = [];
        state.selectedRows = [];
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    setTagProductRestaurantSuccess: {
      reducer: (state) => ({ ...state, isSettingTag: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      resetStoreState.type,
      (state) => ({
        ...state,
        docs: [],
      })
      // action is inferred correctly here if using TS
    );
  },
});

export const {
  setSelectedIds,
  confirmBusinessRequest,
  confirmBusinessSuccess,
  getRestaurantProductListSuccess,
  getRestaurantProductListRequest,
  stopLoading,
  updateStatusRestaurantRequest,
  updateStatusRestaurantSuccess,
  getRestaurantDetailRequest,
  getRestaurantDetailSuccess,
  updateRestaurantProductSuccess,
  updateRestaurantProductRequest,
  switchDetailModal,
  updateMarketProductSuccess,
  updateMarketProductRequest,
  setMarketProductModal,
  getProductRestaurantTypeRequest,
  getProductRestaurantTypeSuccess,
  addProductRestaurantTypeRequest,
  addProductRestaurantTypeSuccess,
  createProductRestaurantRequest,
  createProductRestaurantSuccess,
  updateProductRestaurantRequest,
  updateProductRestaurantSuccess,
  deleteProductRestaurantRequest,
  deleteProductRestaurantSuccess,
  deleteProductRestaurantTypeRequest,
  deleteProductRestaurantTypeSuccess,
  editProductRestaurantTypeRequest,
  editProductRestaurantTypeSuccess,
  setTagProductArticleModalData,
  setTagProductRestaurantRequest,
  setTagProductRestaurantSuccess,
} = restaurantProductSlice.actions;

export default restaurantProductSlice.reducer;
