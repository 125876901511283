/* eslint-disable react/display-name */

import StatusTag from "components/StatusTag";
import { SwitchSelector } from "components/common/Form";
import { BANNER_STATUS_TEXT } from "utils/constants";
import i18n from "i18n";

const Columns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.id = {
  title: "#",
  dataIndex: "_id",
  width: 105,
  render: (id) => renderDataValue(`#${id}`),
};

Columns.image = {
  title: "Banner",
  dataIndex: "imageUrl",
  render: (imageUrl) =>
    renderDataValue(
      <div className="banner-image">
        <img src={imageUrl} alt="" />
      </div>
    ),
};

Columns.url = {
  title: "URL",
  dataIndex: "link",
  render: (imageUrl) =>
    renderDataValue(<div className="banner-url ">{imageUrl}</div>),
};

Columns.status = {
  title: "Trạng thái",
  dataIndex: "isActive",
  render: (isActive, record, onClick) =>
    renderDataValue(
      <div className="status-active">
        <StatusTag
          status={isActive}
          statusText={BANNER_STATUS_TEXT[isActive]}
        />
        <SwitchSelector onClick={onClick} checked={isActive} />
      </div>
    ),
  width: 180,
};

export default Columns;
