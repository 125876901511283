import { Row, Typography, Button, Dropdown, Menu } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import { NewsFonehouseColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import {
  getAllNewsFonehouseRequest,
  setSelectedNewsFonehouseIds,
  deleteNewsFonehouseRequest,
} from "providers/NewsFonehouseProvider/slice";
import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import SearchBar from "./SearchBar";

const NewsFonehouseList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newsFonehouseList = useSelector((state) =>
    _get(state, "newsFonehouse.docs")
  );
  const newsFonehouseTotal = useSelector((state) =>
    _get(state, "newsFonehouse.totalDocs")
  );
  const isLoading = useSelector((state) =>
    _get(state, "newsFonehouse.isLoading")
  );
  const [searchObject] = useQuery(getAllNewsFonehouseRequest);

  const selectedRowKeys = useSelector((state) =>
    _get(state, "newsFonehouse.selectedRowKeys", [])
  );
  const selectedRows = useSelector((state) =>
    _get(state, "newsFonehouse.selectedRows", [])
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedNewsFonehouseIds({
          record,
          selected,
          selectedRows: _selectedRows,
        })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedNewsFonehouseIds({
          selected,
          selectedRows: _selectedRows,
          changeRows,
        })
      );
    },
  };

  const menuAll = () => (
    <Menu>
      <Menu.Item
        disabled={_isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            deleteNewsFonehouseRequest({
              idNews: selectedRowKeys,
              newsId: selectedRows,
            })
          ).then(() => dispatch(getAllNewsFonehouseRequest(searchObject)))
        }
      >
        {i18n.t("newsFonehouse.removeNews")}
      </Menu.Item>
    </Menu>
  );

  const menu = (data) => (
    <Menu>
      <Menu.Item
        onClick={() =>
          navigate(`/fonehouse/news-fonehouse/${data._id}`, {
            state: { canGoBack: true },
          })
        }
      >
        {i18n.t("newsFonehouse.viewInformation")}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          navigate(`/fonehouse/news-fonehouse/${data._id}/edit`, {
            state: { canGoBack: true },
          })
        }
      >
        {i18n.t("newsFonehouse.edit")}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          dispatch(
            deleteNewsFonehouseRequest({ idNews: [data._id], newsId: [data] })
          ).then(() => dispatch(getAllNewsFonehouseRequest(searchObject)))
        }
      >
        {i18n.t("newsFonehouse.removeNews")}
      </Menu.Item>
    </Menu>
  );
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const columns = [
    NewsFonehouseColumn.id,
    NewsFonehouseColumn.createdAt,
    NewsFonehouseColumn.title,
    NewsFonehouseColumn.type,

    NewsFonehouseColumn.username,
    {
      title: (
        <Dropdown overlay={menuAll} placement="bottomLeft" arrow>
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  return (
    <div className="news-fonehouse-list-wrapper">
      <Row className="news-fonehouse-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("newsFonehouse.listTitle")}
        </Typography.Title>
        <Button
          size="large"
          type="primary"
          onClick={() =>
            navigate("/fonehouse/news-fonehouse/news-create", {
              state: { canGoBack: true },
            })
          }
        >
          Đăng bài
        </Button>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Table
          rowSelection={rowSelection}
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={newsFonehouseList}
          rowKey={(data) => data._id}
          total={newsFonehouseTotal}
          onRow={(record) => ({
            onDoubleClick: () => {
              navigate(`/fonehouse/news-fonehouse/${record._id}`, {
                state: { canGoBack: true },
              });
            },
          })}
        />
      </div>
    </div>
  );
};

export default NewsFonehouseList;
