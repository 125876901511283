/* eslint-disable no-nested-ternary */
import { forwardRef, useState, memo, useRef } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import { Upload, Form, message, Button } from "antd";
import { LoadingOutlined, InfoCircleOutlined } from "@ant-design/icons";
import UploadIcon from "assets/images/upload.svg";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import Helper from "utils/helpers";
import ImgCrop from "antd-img-crop";
import "../style.less";

const UploadImage = forwardRef(({ label, className = "", ...props }) => {
  const [field, meta, helper] = useField(props);
  const uploadRef = useRef(null);
  const isError = meta.error && meta.touched;
  const [isLoading, setIsloading] = useState(false);
  const image = !_isEmpty(field.value)
    ? [{ uid: field.value.objectId, url: _get(field.value, "url") }]
    : [];
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt5M;
  };

  const handleRemove = () => {
    helper.setValue(undefined);
  };
  const handleChange = (img) => {
    helper.setValue({ ...img, url: img.url });
  };
  const customRequest = async (option) => {
    const { file } = option;

    setIsloading(true);
    const img = await Helper.uploadFile({ file, type: "AVATAR" });
    if (img) {
      setIsloading(false);
      handleChange(img);
    }
  };

  return (
    <div className={`input-container upload-image-container ${className}`}>
      <Form.Item
        label={label}
        validateStatus={isError ? "error" : ""}
        help={
          <div className="helper-wrapper">
            <div className="error-text">
              {isError && (
                <>
                  <InfoCircleOutlined
                    className="info-icon"
                    type="info-circle"
                  />
                  {meta.error}
                </>
              )}
            </div>
          </div>
        }
      >
        <ImgCrop rotate aspect={4 / 3}>
          <Upload
            listType="picture-card"
            fileList={image}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onRemove={handleRemove}
            customRequest={customRequest}
          >
            <div ref={uploadRef}>
              {isLoading ? (
                <LoadingOutlined />
              ) : !_isEmpty(image) ? (
                image.map((img) => (
                  <img
                    className="uploaded-image"
                    key={img.uid}
                    alt=""
                    src={img.url}
                  />
                ))
              ) : (
                <img src={UploadIcon} alt="" />
              )}
            </div>
          </Upload>
        </ImgCrop>

        <Button
          size="small"
          type="primary"
          style={{ width: 100 }}
          onClick={() => uploadRef.current.click()}
        >
          Chọn hình
        </Button>
      </Form.Item>
    </div>
  );
});

UploadImage.displayName = "UploadImage";
UploadImage.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf([
    "AVATAR",
    "COVER_IMAGE",
    "POST_IMAGE",
    "COMPANY_AVATAR",
    "COMPANY_STORE_AVATAR",
  ]),
  className: PropTypes.string,
};
export default memo(UploadImage);
