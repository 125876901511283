import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import { Modal } from "antd";

const EditArticleTypeModal = (props) => {
  const { data, handleCloseModal, children, title } = props;
  const handleCancel = () => {
    handleCloseModal();
  };

  return (
    <Modal
      title={title}
      visible={!_isEmpty(data)}
      width={300}
      afterClose={handleCloseModal} // Remove popup data when popup is close
      onCancel={handleCancel}
      footer={false}
      maskClosable={false}
      centered
      className="general-confirm-modal"
      {...props}
    >
      {children && children}
    </Modal>
  );
};
EditArticleTypeModal.propTypes = {
  children: PropTypes.object,
  handleCloseModal: PropTypes.func,
  handleConfirmModal: PropTypes.func,
  data: PropTypes.object,
  title: PropTypes.string,
};
export default EditArticleTypeModal;
