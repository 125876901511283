import { array, number, object, string } from "yup";
import i18n from "i18n";

export const createAccessoryValidate = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
  price: number()
    .typeError("Dữ liệu phải là số")
    .test({
      name: "price",
      test(values) {
        if (values <= 0) {
          return false;
        }
        return true;
      },
      message: "Giá bán phải lớn hơn 0",
    })
    .nullable()
    .required(i18n.t("errors.required")),
  initialPrice: number()
    .typeError("Dữ liệu phải là số")
    .test({
      name: "initialPrice",
      test(values) {
        if (values <= 0) {
          return false;
        }
        return true;
      },
      message: "Giá ban đầu phải lớn hơn 0",
    })
    .nullable()
    .required(i18n.t("errors.required")),
  thumbnail: object().nullable().required(i18n.t("errors.required")),
  images: array()
    .min(1, "Phải có ít nhất 1 hình")
    .required(i18n.t("errors.required")),
});
