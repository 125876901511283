import { object, string } from "yup";
import i18n from "i18n";

export const editSupermarketValidate = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
  address: string().trim().required(i18n.t("errors.required")),
  phone: string(),
  thumbnail: object().nullable().required(i18n.t("errors.required")),
});

export const createSupermarketValidate = editSupermarketValidate.shape({
  banners: object().nullable().required(i18n.t("errors.required")),
});
