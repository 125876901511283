import { useState } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { arrayMoveImmutable } from "array-move";
import { Formik } from "formik";
import {
  deleteTypeProductRequest,
  getProductTypeRequest,
  createTypeProductRequest,
  updateTypeProductRequest,
  changeIndexProductTypeRequest,
} from "providers/ProductProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import { MultiImageUpload } from "components/common";
import SortableList from "./SortableList";
import ProductTypeForm from "../ProductTypeForm";

const ProductTypeList = ({ productTypes }) => {
  const dispatch = useDispatch();
  const [productTypeList, setProductTypeList] = useState(productTypes);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedMachineSeriesItem, setSelectedMachineSeriesItem] = useState(
    {}
  );
  const [machineSeriesList, setMachineSeriesList] = useState([]);
  const [machineImageList, setMachineImageList] = useState([]);

  function uuidv4() {
    return "xxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 || 0;
      const v = c === "x" ? r : (r && 0x3) || 0x8;
      return v.toString(16);
    });
  }

  const handleSelectedItem = (value) => {
    if (value._id === selectedItem._id) {
      setSelectedItem({});
      setSelectedMachineSeriesItem({});
      setMachineSeriesList([]);
      setMachineImageList([]);
    } else {
      setSelectedItem(value);
      const machineSeriesFilter = productTypeList.filter(
        (item) => item._id === value._id
      );
      const machineSeriesFormat = machineSeriesFilter[0].machineSeries.map(
        (item) => ({ ...item, _id: uuidv4() })
      );
      setSelectedMachineSeriesItem({});
      setMachineSeriesList(machineSeriesFormat);
      setMachineImageList([]);
    }
  };

  const handleSelectedMachineSeriesItem = (value) => {
    if (value._id === selectedMachineSeriesItem._id) {
      setSelectedMachineSeriesItem({});
      setMachineImageList([]);
    } else {
      setSelectedMachineSeriesItem(value);
      const machineImageFilter = machineSeriesList.filter(
        (item) => item._id === value._id
      );
      const machineImage = machineImageFilter[0].images?.slice();
      setMachineImageList(machineImage);
    }
  };

  const handleDeleteTypeProduct = (values) => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          title: "Xác nhận xoá loại",
          content: `Bạn muốn xoá loại ${values.name}`,
          onOk: () => {
            const productTypeListRemoved = productTypeList.filter(
              (item) => item._id !== values._id
            );
            dispatch(
              deleteTypeProductRequest({
                idProductType: [values._id],
                name: values.name,
              })
            ).then(() => {
              dispatch(getProductTypeRequest());
              setProductTypeList(productTypeListRemoved);
              setMachineSeriesList([]);
              setMachineImageList([]);
            });
          },
        },
      })
    );
  };

  const handleDeleteMachineSeriesProduct = (values) => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          title: "Xác nhận xoá dòng",
          content: `Bạn muốn xoá dòng ${values.name}`,
          onOk: () => {
            const productMachineSeriesListRemoved = machineSeriesList.filter(
              (item) => item._id !== values._id
            );
            const machineSeriesListUpdated =
              productMachineSeriesListRemoved.map((item) => ({
                name: item.name,
                image: item.image,
                images: item.images,
                id: item.id,
              }));
            const productTypeListUpdated = productTypeList.map((item) => {
              if (item._id === selectedItem._id) {
                return {
                  ...item,
                  machineSeries: productMachineSeriesListRemoved,
                };
              }
              return item;
            });
            dispatch(
              updateTypeProductRequest({
                idProductType: selectedItem._id,
                name: selectedItem.name,
                image: selectedItem.image,
                machineSeries: machineSeriesListUpdated,
                type: "remove-machine-series",
                machineSeriesName: values.name,
              })
            ).then(() => {
              dispatch(getProductTypeRequest());
              setMachineSeriesList(productMachineSeriesListRemoved);
              setProductTypeList(productTypeListUpdated);
              setMachineImageList([]);
            });
          },
        },
      })
    );
  };

  const handleCreateNewType = (values) => {
    dispatch(createTypeProductRequest({ ...values, machineSeries: [] })).then(
      (data) => {
        const productTypeListCreated = productTypeList.concat(data);
        dispatch(getProductTypeRequest());
        setProductTypeList(productTypeListCreated);
      }
    );
  };

  const handleCreateMachineSeries = (values) => {
    const valuesFormat = {
      name: values.name,
      image: values.image.url,
      images: _get(values, "images", []),
      id: uuidv4(),
    };
    const productType = productTypeList.filter(
      (item) => item._id === values.type
    );
    const machineSeriesListUpdated = productType.map((item) => ({
      name: item.name,
      image: item.image,
      machineSeries: item.machineSeries.concat(valuesFormat),
      idProductType: values.type,
    }));
    const productTypeListUpdated = productTypeList.map((item) => {
      if (item._id === values.type) {
        return {
          ...item,
          machineSeries: machineSeriesListUpdated[0].machineSeries,
        };
      }
      return item;
    });
    dispatch(
      updateTypeProductRequest({
        ...machineSeriesListUpdated[0],
        type: "create-machine-series",
        machineSeriesName: values.name,
      })
    ).then(() => {
      setProductTypeList(productTypeListUpdated);
      if (selectedItem._id === values.type) {
        setMachineSeriesList(
          machineSeriesList.concat({ ...valuesFormat, _id: valuesFormat.id })
        );
      }
    });
  };

  const handleOnSortEnd = ({ oldIndex, newIndex, type }) => {
    if (oldIndex !== newIndex) {
      if (type === "productType") {
        const newData = arrayMoveImmutable(
          productTypeList.slice(),
          oldIndex,
          newIndex
        ).filter((el) => !!el);
        setProductTypeList(newData);
        dispatch(
          changeIndexProductTypeRequest({
            idProductType: productTypeList[oldIndex]._id.toString(),
            idProductTypeInsert: productTypeList[newIndex]._id.toString(),
          })
        );
      }
      if (type === "machineSeries") {
        const newData = arrayMoveImmutable(
          machineSeriesList.slice(),
          oldIndex,
          newIndex
        ).filter((el) => !!el);
        setMachineSeriesList(newData);
        dispatch(
          updateTypeProductRequest({
            idProductType: selectedItem._id,
            name: selectedItem.name,
            image: selectedItem.image,
            machineSeries: newData,
          })
        ).then(() => {
          const newProductTypeList = productTypeList.map((product) => {
            if (product._id === selectedItem._id) {
              return {
                ...product,
                machineSeries: newData,
              };
            }
            return product;
          });
          setProductTypeList(newProductTypeList);
        });
      }
    }
  };

  const handleUpdateMachineSeriesImage = ({ images }) => {
    const newMachineSeries = machineSeriesList.map((item) => {
      if (item._id === selectedMachineSeriesItem._id) {
        return {
          ...item,
          images,
        };
      }
      return item;
    });
    setMachineImageList(images);
    dispatch(
      updateTypeProductRequest({
        idProductType: selectedItem._id,
        name: selectedItem.name,
        image: selectedItem.image,
        machineSeries: newMachineSeries,
        machineSeriesName: selectedMachineSeriesItem.name,
        type: "update-machine-series",
      })
    ).then(() => {
      const newProductTypeList = productTypeList.map((product) => {
        if (product._id === selectedItem._id) {
          return {
            ...product,
            machineSeries: newMachineSeries,
          };
        }
        return product;
      });
      setProductTypeList(newProductTypeList);
      setMachineSeriesList(newMachineSeries);
    });
  };

  return (
    <>
      <Row className="product-type-modal-container">
        <Col span={12} className="product-type-list-wrapper">
          <div className="product-type-list-container">
            <div className="type-title">Loại </div>
            <SortableList
              items={productTypeList}
              axis="xy"
              updateBeforeSortStart={(node) => {
                node.node.style.zIndex = 9999;
              }}
              pressDelay={200}
              pressThreshold={50}
              selectedItem={selectedItem}
              handleSelectedItem={handleSelectedItem}
              handleDeleteTypeProduct={handleDeleteTypeProduct}
              onSortEnd={(props) =>
                handleOnSortEnd({ ...props, type: "productType" })
              }
            />
          </div>
          {!_isEmpty(machineSeriesList) && (
            <div className="product-machine-series-list-container">
              <div className="type-title">Dòng </div>
              <SortableList
                items={machineSeriesList}
                axis="xy"
                updateBeforeSortStart={(node) => {
                  node.node.style.zIndex = 9999;
                }}
                pressDelay={200}
                pressThreshold={50}
                selectedItem={selectedMachineSeriesItem}
                handleSelectedItem={handleSelectedMachineSeriesItem}
                handleDeleteTypeProduct={handleDeleteMachineSeriesProduct}
                onSortEnd={(props) =>
                  handleOnSortEnd({ ...props, type: "machineSeries" })
                }
              />
            </div>
          )}
          {!_isEmpty(selectedMachineSeriesItem) && (
            <div className="product-machine-image-list-container">
              <Formik
                initialValues={{ images: machineImageList }}
                enableReinitialize
              >
                {() => (
                  <MultiImageUpload
                    name="images"
                    label="Ảnh màu máy"
                    handleChangeImages={handleUpdateMachineSeriesImage}
                  />
                )}
              </Formik>
            </div>
          )}
        </Col>
        <Col span={12} className="product-type-form-wrapper">
          <ProductTypeForm
            handleCreateNewType={handleCreateNewType}
            handleCreateMachineSeries={handleCreateMachineSeries}
            productTypeList={productTypeList}
          />
        </Col>
      </Row>
    </>
  );
};

ProductTypeList.propTypes = {
  productTypes: PropTypes.array,
};

export default ProductTypeList;
