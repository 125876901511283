import React, { forwardRef, useState, memo, useRef } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import { Form, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import Helper from "utils/helpers";
import "../style.less";

const UploadImageContainer = forwardRef(
  ({ label, className = "", children, ...props }) => {
    const [field, meta, helper] = useField(props);
    const uploadRef = useRef(null);
    const isError = meta.error && meta.touched;
    const [isLoading, setIsloading] = useState(false);
    const image = !_isEmpty(field.value)
      ? [{ uid: field.value.objectId, url: _get(field.value, "url") }]
      : [];
    const beforeUpload = (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("Image must smaller than 5MB!");
      }
      return isJpgOrPng && isLt5M;
    };

    const handleRemove = () => {
      helper.setValue(undefined);
    };
    const handleChange = (img) => {
      helper.setValue({ ...img, url: img.url });
    };
    const customRequest = async (option) => {
      const { file } = option;

      setIsloading(true);
      const img = await Helper.uploadFile({ file, type: "AVATAR" });
      if (img) {
        setIsloading(false);
        handleChange(img);
      }
    };
    return (
      <div className={`input-container upload-image-container ${className}`}>
        <Form.Item
          label={label}
          validateStatus={isError ? "error" : ""}
          help={
            <div className="helper-wrapper">
              <div className="error-text">
                {isError && (
                  <>
                    <InfoCircleOutlined
                      className="info-icon"
                      type="info-circle"
                    />
                    {meta.error}
                  </>
                )}
              </div>
            </div>
          }
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              handleRemove,
              customRequest,
              beforeUpload,
              uploadRef,
              isLoading,
              image,
            })
          )}
        </Form.Item>
      </div>
    );
  }
);

UploadImageContainer.displayName = "UploadImageContainer";
UploadImageContainer.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf([
    "AVATAR",
    "COVER_IMAGE",
    "POST_IMAGE",
    "COMPANY_AVATAR",
    "COMPANY_STORE_AVATAR",
    "BANNER",
  ]),
  className: PropTypes.string,
  children: PropTypes.object,
};
export default memo(UploadImageContainer);
