import { Row, Typography, Button, Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { BusinessColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import {
  confirmBusinessRequest,
  getBusinessListRequest,
  setSelectedBusinessIds,
} from "providers/BusinessProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import SearchBar from "./SearchBar";

const RegisterBusiness = () => {
  const dispatch = useDispatch();
  const businessList = useSelector((state) => _get(state, "business.docs"));
  const businessTotal = useSelector((state) =>
    _get(state, "business.totalDocs")
  );
  const isLoading = useSelector((state) => _get(state, "business.isLoading"));
  const selectedRowKeys = useSelector((state) =>
    _get(state, "business.selectedRowKeys", [])
  );
  const selectedRows = useSelector((state) =>
    _get(state, "business.selectedRows", [])
  );
  const isDisableApproveBtn = !!selectedRows.find(
    (item) => item.status === "approved"
  );
  const isDisableRefuseBtn = !!selectedRows.find(
    (item) => item.status === "refuse"
  );

  const [searchObject] = useQuery(getBusinessListRequest, {
    arrayParams: ["status"],
  });

  const menuAll = () => (
    <Menu>
      <Menu.Item
        disabled={isDisableApproveBtn || _isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            confirmBusinessRequest({
              idBusinesses: selectedRowKeys,
              status: "approved",
            })
          ).then(() => dispatch(getBusinessListRequest(searchObject)))
        }
      >
        {i18n.t("register.approve")}
      </Menu.Item>
      <Menu.Item
        disabled={isDisableRefuseBtn || _isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            confirmBusinessRequest({
              idBusinesses: selectedRowKeys,
              status: "refuse",
            })
          ).then(() => dispatch(getBusinessListRequest(searchObject)))
        }
      >
        {i18n.t("register.cancel")}
      </Menu.Item>
    </Menu>
  );
  const menu = (record) => {
    const { _id, status } = record;
    return (
      <Menu>
        <Menu.Item>
          <Link to={`/fonehouse/business/${_id}`} state={{ canGoBack: true }}>
            {i18n.t("register.seeDetail")}
          </Link>
        </Menu.Item>
        {["waiting", "refuse"].includes(status) && (
          <Menu.Item
            onClick={() =>
              dispatch(
                setPopup({
                  isOpen: true,
                  data: {
                    title: "Xác nhận duyệt đơn",
                    content: "Bạn xác nhận duyệt đơn đăng ký kinh doanh này?",
                    onOk: () =>
                      dispatch(
                        confirmBusinessRequest({
                          idBusinesses: [_id],
                          status: "approved",
                        })
                      ).then(() =>
                        dispatch(getBusinessListRequest(searchObject))
                      ),
                  },
                })
              )
            }
          >
            {i18n.t("register.approve")}
          </Menu.Item>
        )}
        {status === "waiting" && (
          <Menu.Item
            onClick={() =>
              dispatch(
                setPopup({
                  isOpen: true,
                  data: {
                    title: "Xác nhận từ chối đơn",
                    content:
                      "Bạn xác nhận từ chối duyệt đơn đăng ký kinh doanh này?",
                    onOk: () =>
                      dispatch(
                        confirmBusinessRequest({
                          idBusinesses: [_id],
                          status: "refuse",
                        })
                      ).then(() =>
                        dispatch(getBusinessListRequest(searchObject))
                      ),
                  },
                })
              )
            }
          >
            {i18n.t("register.cancel")}
          </Menu.Item>
        )}
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const columns = [
    BusinessColumn.businessId,
    BusinessColumn.createdAt,
    BusinessColumn.state,
    BusinessColumn.permission,
    BusinessColumn.account,
    BusinessColumn.address,
    BusinessColumn.status,
    {
      title: (
        <Dropdown overlay={menuAll} placement="bottomLeft" arrow>
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedBusinessIds({
          record,
          selected,
          selectedRows: _selectedRows,
        })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedBusinessIds({
          selected,
          selectedRows: _selectedRows,
          changeRows,
        })
      );
    },
  };
  return (
    <div className="register-list-wrapper">
      <Row className="register-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("register.listTitle")}
        </Typography.Title>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Table
          rowSelection={rowSelection}
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={businessList}
          rowKey={(data) => data._id}
          total={businessTotal}
        />
      </div>
    </div>
  );
};

export default RegisterBusiness;
