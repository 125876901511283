import PropTypes from "prop-types";
import { Modal } from "antd";

const CreatePromotionModal = (props) => {
  const { isVisible, handleCloseModal, children, title } = props;
  const handleCancel = () => {
    handleCloseModal();
  };

  return (
    <Modal
      title={title}
      visible={isVisible}
      width={300}
      afterClose={handleCloseModal} // Remove popup data when popup is close
      onCancel={handleCancel}
      footer={false}
      maskClosable={false}
      centered
      className="general-confirm-modal"
      {...props}
    >
      {children && children}
    </Modal>
  );
};
CreatePromotionModal.propTypes = {
  children: PropTypes.object,
  handleCloseModal: PropTypes.func,
  isVisible: PropTypes.bool,
  title: PropTypes.string,
};
export default CreatePromotionModal;
