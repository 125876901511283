import { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import BackIcon from "assets/images/back.svg";
import i18n from "i18n";
import { Modal } from "antd";
import {
  createMoneyManagementRequest,
  getAllMoneyManagementRequest,
  getBalanceRequest,
  getMoneyManagementRecordRequest,
  getOldBalanceRequest,
  updateMoneyManagementRequest,
} from "providers/FonehouseMoneyManagement/slice";
import moment from "moment";
import MoneyManagementForm from "./MoneyManagementForm";

const MoneyManagementRecord = (props) => {
  const dispatch = useDispatch();
  const { setMoneyManagementModal } = props;
  const moneyManagementModalData = useSelector(
    (state) => state.moneyManagementFonehouse.moneyManagementModalData
  );
  const moneyManagementRecord = useSelector(
    (state) => state.moneyManagementFonehouse.moneyManagementRecord
  );
  const balance = useSelector(
    (state) => state.moneyManagementFonehouse.balance
  );
  const dateFrom = useSelector(
    (state) => state.moneyManagementFonehouse.dateFrom
  );
  const dateTo = useSelector((state) => state.moneyManagementFonehouse.dateTo);
  const isLoading = useSelector(
    (state) => state.moneyManagementFonehouse.isLoading
  );
  const { visible, isCreate, id } = moneyManagementModalData;
  const initialValues = {
    type: moneyManagementRecord.type ?? "",
    value: moneyManagementRecord.value ?? "",
    payment: moneyManagementRecord.payment ?? "",
    description: moneyManagementRecord.description ?? "",
    defaultMoney: `${balance} JPY`,
    isCreate,
    id,
  };
  useEffect(() => {
    if (id) {
      dispatch(getMoneyManagementRecordRequest({ id }));
    }
  }, [dispatch, id]);

  const handleCloseModal = () => {
    dispatch(
      setMoneyManagementModal({
        visible: false,
      })
    );
  };

  const handleCreateMoneyRecord = (values) => {
    values.value = parseFloat(values.value);
    if (isCreate) {
      dispatch(createMoneyManagementRequest(values)).then(() => {
        dispatch(getBalanceRequest());
        dispatch(getOldBalanceRequest());
        dispatch(
          getAllMoneyManagementRequest({
            from: moment(dateFrom).valueOf(),
            to: moment(dateTo).valueOf(),
            page: 1,
            limit: 10,
          })
        );
      });
    } else {
      dispatch(updateMoneyManagementRequest(values)).then(() => {
        dispatch(getBalanceRequest());
        dispatch(getOldBalanceRequest());
        dispatch(
          getAllMoneyManagementRequest({
            from: moment(dateFrom).valueOf(),
            to: moment(dateTo).valueOf(),
            page: 1,
            limit: 10,
          })
        );
      });
    }
    handleCloseModal();
  };

  return (
    <div>
      <Modal
        title={
          isCreate
            ? i18n.t("moneyManagementFonehouse.createRecord")
            : i18n.t("moneyManagementFonehouse.updateRecord")
        }
        visible={visible}
        width={860}
        className="market-product-modal-wrapper"
        footer={null}
        onCancel={handleCloseModal}
        maskClosable={false}
        closeIcon={<img src={BackIcon} alt="" />}
      >
        <MoneyManagementForm
          balance={balance}
          isCreate={isCreate}
          loading={isLoading}
          initialValues={initialValues}
          onSubmit={handleCreateMoneyRecord}
        />
      </Modal>
    </div>
  );
};

MoneyManagementRecord.propTypes = {
  setMoneyManagementModal: PropTypes.func,
};

export default MoneyManagementRecord;
