import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { middleware as thunkMiddleware } from "redux-saga-thunk";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();

export default function configureAppStore(preloadedState = {}) {
  const store = configureStore(
    {
      reducer: rootReducer,
      middleware: [
        thunkMiddleware,
        sagaMiddleware,
        ...getDefaultMiddleware({
          serializableCheck: false,
        }),
      ],
      devTools: process.env.NODE_ENV === "development",
      preloadedState,
    }
    // applyMiddleware(sagaMiddleware, thunkMiddleware)
  );

  if (process.env.NODE_ENV === "development" && module.hot) {
    module.hot.accept("./rootReducer", () => store.replaceReducer(rootReducer));
  }
  sagaMiddleware.run(rootSaga);

  return store;
}
