import { useState } from "react";
import _get from "lodash/get";
import { Image, Col, List, Row, Modal, Button, Carousel } from "antd";
import "./styles.less";
import i18n from "i18n";
import { setDiscountDirectModal } from "providers/BillingProvider/slice";
import moment from "moment";
import DiscountDirectModal from "components/Bill/DiscountDirectModal";
import PropTypes from "prop-types";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import _isEmpty from "lodash/isEmpty";
import { PlaceHolderIMG } from "assets/images";

const LastestBillingModal = ({ billDetail, open, onClose }) => {
  const productData = _get(billDetail, "products", []);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const renderProductItem = (item) => (
    <Row style={{ marginTop: 20, marginBottom: 20 }}>
      <Carousel autoplay style={{ width: 150, height: 150 }} arrows>
        {(item.images || []).map((image, idx) => (
          <Image
            onClick={() => {
              setOpen(true);
              setPhotoIndex(idx);
            }}
            key={image}
            fallback={PlaceHolderIMG}
            width={150}
            height={150}
            src={image}
            preview={false}
          />
        ))}
        {isOpen && !_isEmpty(item?.images) && (
          <Lightbox
            mainSrc={item?.images[photoIndex]}
            nextSrc={item?.images[(photoIndex + 1) % item?.images.length]}
            prevSrc={
              item?.images[
                (photoIndex + item?.images.length - 1) % item?.images.length
              ]
            }
            onCloseRequest={() => setOpen(false)}
            onMovePrevRequest={() => {
              setPhotoIndex(
                (photoIndex + item?.images.length - 1) % item?.images.length
              );
            }}
            onMoveNextRequest={() => {
              setPhotoIndex((photoIndex + 1) % item?.images.length);
            }}
          />
        )}
      </Carousel>
      <Row style={{ flex: 1, marginLeft: 16 }}>
        <Col span={14}>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.productCode")}:
            </div>
            <div className="text-sub-content">{item.productId}</div>
          </Row>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.imei")}:
            </div>
            <div className="text-sub-content">{item.imei}</div>
          </Row>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.productName")}:
            </div>
            <div className="text-sub-content">{item.name}</div>
          </Row>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.accessories")}:
            </div>
            <div className="text-sub-content">{item.accessories}</div>
          </Row>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.amount")}:
            </div>
            <div className="text-sub-content">{item.amount}</div>
          </Row>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.promotion")}:
            </div>
            <div className="text-sub-content">
              {`${(
                (item.priceJPY * item.percentagePromotion) /
                100
              ).toLocaleString()} ${billDetail.currency}`}
            </div>
          </Row>
          <Row align="middle" style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.priceSale")}:
            </div>
            <div className="text-product-price">
              {item.promotionPriceJPY.toLocaleString()} {item.currency}
            </div>
          </Row>
        </Col>
        <Col
          span={10}
          style={{ borderLeft: "1px solid #909090", paddingLeft: 8 }}
        >
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.createAt")}:
            </div>
            <div className="text-sub-content">
              {moment(item.createdAt).format("DD/MM/YYYY")}
            </div>
          </Row>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.manufacture")}:
            </div>
            <div className="text-sub-content">{item.manufacture}</div>
          </Row>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.type")}:
            </div>
            <div className="text-sub-content">{item.state}</div>
          </Row>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.memory")}:
            </div>
            <div className="text-sub-content">{item.ram}</div>
          </Row>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.inputPrice")}:
            </div>
            <div className="text-input-price">
              {item.inputPriceJPY.toLocaleString()} {item.currency}
            </div>
          </Row>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.initialPrice")}:
            </div>
            <div className="text-input-price">
              {item.priceJPY.toLocaleString()} {item.currency}
            </div>
          </Row>
          <Row style={{ padding: "4px 0px" }}>
            <div className="text-sub-title">
              {i18n.t("billingDetail.extraPrice")}:
            </div>
            <div
              style={{
                color:
                  item.promotionPriceJPY - item.inputPriceJPY < 0
                    ? "red"
                    : "green",
              }}
              className="text-extra-price"
            >
              {(item.promotionPriceJPY - item.inputPriceJPY).toLocaleString()}{" "}
              {item.currency}
            </div>
          </Row>
        </Col>
      </Row>
    </Row>
  );

  return (
    <Modal
      title={
        <div className="flex items-center">
          Đơn hàng mới nhất
          <a
            target="_blank"
            rel="noreferrer"
            href={`${process.env.REACT_APP_DOMAIN_NAME}/fonehouse/billing_list/${billDetail._id}`}
          >
            <Button
              size="small"
              type="primary"
              style={{ width: "unset", marginLeft: 16 }}
            >
              Xem chi tiết
            </Button>
          </a>{" "}
        </div>
      }
      visible={open}
      width={860}
      className="lastest-billing-modal-wrapper"
      footer={null}
      onCancel={onClose}
    >
      <div className="latest-bill-detail-wrapper">
        <DiscountDirectModal setDiscountDirectModal={setDiscountDirectModal} />
        <Row>
          <Col span={14}>
            {Object.values(billDetail).length > 0 && (
              <div className="detail-wrapper">
                <Row style={{ padding: "4px 0px" }}>
                  <div className="text-sub-title">Mã đơn hàng:</div>
                  <div className="text-sub-content">{billDetail.orderId}</div>
                </Row>
                <Row style={{ padding: "4px 0px" }}>
                  <div className="text-sub-title">
                    {i18n.t("billingDetail.orderDate")}:
                  </div>
                  <div className="text-sub-content">
                    {moment(_get(billDetail, "createdAt", "")).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                </Row>
                <Row style={{ padding: "4px 0px" }}>
                  <div className="text-sub-title">
                    {i18n.t("billingDetail.customerName")}
                  </div>
                  <div className="text-sub-content">
                    {_get(billDetail, "name", "")}
                  </div>
                </Row>
                <Row style={{ padding: "4px 0px" }}>
                  <div className="text-sub-title">
                    {i18n.t("billingDetail.phone")}
                  </div>
                  <div className="text-sub-content">
                    {_get(billDetail, "phone", "")}
                  </div>
                </Row>
                <Row style={{ padding: "4px 0px" }}>
                  <div className="text-sub-title">
                    {i18n.t("billingDetail.postalCode")}:
                  </div>
                  <div className="text-sub-content">{billDetail.postCode}</div>
                </Row>
                <Row style={{ padding: "4px 0px" }}>
                  <div className="text-sub-title">
                    {i18n.t("billingDetail.address")}
                  </div>
                  <div className="text-sub-content">
                    {_get(billDetail, "address", "")}
                  </div>
                </Row>
                <Row style={{ padding: "4px 0px" }}>
                  <div className="text-sub-title">
                    {`${i18n.t("billingDetail.timeProduct")}:`}
                  </div>
                  <div className="text-sub-content">{billDetail.timeFrame}</div>
                </Row>
              </div>
            )}
          </Col>
          <Col span={10}>
            <div className="detail-wrapper">
              <div className="container-center">
                <div className="text-sub-title-2">
                  {i18n.t("billingDetail.total")}
                </div>
                <div className="text-price">
                  {billDetail?.promotionPrice?.toLocaleString()}{" "}
                  {billDetail.currency}{" "}
                </div>
              </div>
              <div className="container-center">
                <div className="text-sub-title-2">
                  {i18n.t("billingDetail.totalExtra")}
                </div>
                <div
                  style={{
                    color:
                      billDetail.promotionPrice - billDetail.initialPrice < 0
                        ? "red"
                        : "green",
                  }}
                  className="text-product-price top10"
                >
                  {(
                    billDetail.promotionPrice -
                    (billDetail.initialPrice + billDetail.shippingFee)
                  ).toLocaleString()}{" "}
                  {billDetail.currency}{" "}
                </div>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <List
              itemLayout="vertical"
              pagination={{
                pageSize: 4,
              }}
              dataSource={productData}
              renderItem={(item) => renderProductItem(item)}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

LastestBillingModal.propTypes = {
  billDetail: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
export default LastestBillingModal;
