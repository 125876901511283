import { memo } from "react";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";

import SearchBarComponent from "components/SearchBar";
import { TextInput, Filter, Select } from "components/common/Form";
import { PRODUCT_SEARCH_PARAM, PRODUCT_TAG_TEXT } from "utils/constants";
import Helper from "utils/helpers";

const SearchBar = ({ formattedproductTypes, searchObject }) => (
  <SearchBarComponent
    filterFields={[
      "keyword",
      "type",
      "tag",
      "status",
      "line",
      "productId",
      "imei",
      "searchParams",
    ]}
    isOnChangeSearch
  >
    <>
      <TextInput
        prefix={<SearchOutlined />}
        name={searchObject.searchParams ?? "keyword"}
        allowClear={false}
      />
      <Select
        name="tag"
        placeholder="Tag"
        options={Helper.convertObjectToOptions(PRODUCT_TAG_TEXT, true, "Tag")}
      />
      {/* <Select
        name="searchParams"
        placeholder="Tìm kiếm theo..."
        options={Helper.convertObjectToOptions(
          PRODUCT_SEARCH_PARAM,
          true,
          "Tên sản phẩm"
        )}
      /> */}

      <Filter
        name="line"
        options={formattedproductTypes}
        popConfirmClassName="gb-role-popconfirm"
        treeFilter
      />
    </>
  </SearchBarComponent>
);
SearchBar.propTypes = {
  name: PropTypes.string,
  companyValue: PropTypes.string,
  formattedproductTypes: PropTypes.array,
};
export default memo(SearchBar);
