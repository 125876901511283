import { Row, Typography, Button, Dropdown, Menu, Tabs } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { SupportColoumn } from "components/Columns";
import Table from "components/common/Table";
import TabHeader from "components/TabHeader";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import { ROLES } from "utils/constants";
import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import {
  deleteSupportRequest,
  getSupportListRequest,
  setSelectedIds,
} from "providers/SupportProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import SearchBar from "./SearchBar";

const { TabPane } = Tabs;

const supportTypeLabel = {
  supportRequest: "Hỗ trợ",
  errorReport: "Báo lỗi",
  feedback: "Góp ý",
  report: "Báo cáo",
};

const SupportList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const supportList = useSelector((state) => _get(state, "support.docs", []));
  const supportTotal = useSelector((state) => _get(state, "support.totalDocs"));
  const roleCurrentUser = useSelector((state) =>
    _get(state, "auth.currentUser.role")
  );
  const isAdminPermission = [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(
    roleCurrentUser
  );
  const isLoading = useSelector((state) => _get(state, "support.isLoading"));
  const [searchObject, setSearch] = useQuery(getSupportListRequest);
  const {
    allSupportUnread = 0,
    errorReportUnread = 0,
    feedbackUnread = 0,
    reportUnread = 0,
    supportRequestUnread = 0,
  } = useSelector((state) => _get(state, "support"));

  const unreadSupportNumber = {
    "": allSupportUnread,
    supportRequest: supportRequestUnread,
    errorReport: errorReportUnread,
    feedback: feedbackUnread,
    report: reportUnread,
  };

  const selectedRowKeys = useSelector((state) =>
    _get(state, "support.selectedRowKeys", [])
  );
  const selectedRows = useSelector((state) =>
    _get(state, "support.selectedRows", [])
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedIds({ record, selected, selectedRows: _selectedRows })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedIds({ selected, selectedRows: _selectedRows, changeRows })
      );
    },
  };

  const supportIdList = [];
  selectedRows.map((item) => supportIdList.push(item.MarketId));

  const menuAll = () => (
    <Menu>
      <Menu.Item
        className="delete-product-btn"
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: "Xác nhận",
                cancelText: "Huy",
                title: "Xác nhận xóa hỗ trợ",
                content: "Bạn có muốn xóa hỗ trợ",
                onOk: () =>
                  dispatch(
                    deleteSupportRequest({ feedbackIds: selectedRowKeys })
                  ).then(() => dispatch(getSupportListRequest(searchObject))),
              },
            })
          )
        }
        disabled={_isEmpty(selectedRowKeys)}
      >
        {i18n.t("support.delete")}
      </Menu.Item>
    </Menu>
  );
  const menu = (record) => {
    const { _id, type } = record;
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            navigate(`/support/${_id}`, { state: { canGoBack: true } })
          }
        >
          {i18n.t("support.seeDetail")}
        </Menu.Item>
        {isAdminPermission && (
          <>
            <Menu.Item
              className="delete-product-btn"
              onClick={() =>
                dispatch(
                  setPopup({
                    isOpen: true,
                    data: {
                      okText: "Xác nhận",
                      cancelText: "Huy",
                      title: "Xác nhận xóa hỗ trợ",
                      content: `Bạn có muốn xóa ${supportTypeLabel[type]}`,
                      onOk: () =>
                        dispatch(
                          deleteSupportRequest({ feedbackIds: [_id] })
                        ).then(() =>
                          dispatch(getSupportListRequest(searchObject))
                        ),
                    },
                  })
                )
              }
            >
              {i18n.t("support.delete")}
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const columns = [
    SupportColoumn.supportId,
    SupportColoumn.account,
    SupportColoumn.type,
    SupportColoumn.title,
    SupportColoumn.note,
    {
      title: (
        <Dropdown
          overlay={isAdminPermission ? menuAll : ""}
          placement="bottomLeft"
          arrow={isAdminPermission}
        >
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  return (
    <div className="support-list-wrapper">
      <Row className="support-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("support.listTitle")}
        </Typography.Title>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Tabs
          className="support-tabs-container"
          activeKey={searchObject.type}
          type="card"
          onChange={(key) => {
            setSearch(
              {
                type: key,
                keyword: searchObject.keyword,
              },
              true
            );
          }}
        >
          {["", "supportRequest", "errorReport", "feedback", "report"].map(
            (value) => (
              <TabPane
                className="status-tab-pane"
                tab={
                  <TabHeader
                    status={value}
                    total={100}
                    unreadNumbers={unreadSupportNumber[value]}
                  />
                }
                key={value}
              >
                <Table
                  rowSelection={rowSelection}
                  scroll={{ x: 1000 }}
                  loading={isLoading}
                  bordered
                  columns={columns}
                  dataSource={supportList}
                  rowKey={(data) => data._id}
                  total={supportTotal}
                  rowClassName={(data) =>
                    _get(data, "checked") ? "support-readed" : "support-unread"
                  }
                  onRow={(record) => ({
                    onDoubleClick: () => {
                      navigate(`/support/${record._id}`, {
                        state: { canGoBack: true },
                      });
                    },
                  })}
                />
              </TabPane>
            )
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default SupportList;
