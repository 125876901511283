import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Typography } from "antd";
import _isEmpty from "lodash/isEmpty";
// import _uniq from "lodash/uniq";
import _get from "lodash/get";

import "./styles.less";
import BackButton from "components/BackButton";
// import {
//   getCosmeticProductListRequest,
//   setSelectedIds,
//   setTagProductArticleModalData,
//   setTagProductCosmeticRequest,
// } from "providers/CosmeticProductProvider/slice";
import StoreComponent from "components/Store/Detail";
import StoreEdit from "components/Store/Edit";
import Table from "components/common/Table";
// import { useQuery } from "hooks";
// import { CosmeticProductColumn } from "components/Columns";
// import { IconSeeMore } from "assets/images";
import { ROLES, STORE_TYPE, DELETE_BANNER_CONTENT } from "utils/constants";
import { resetSelectedRowKey } from "providers/AdminProvider/slice";
import {
  setPopup,
  setChangeStoreOnwerModal,
} from "providers/GeneralProvider/slice";
import ArticleShopOwner from "components/ArticleShopOwner";
import Helper from "utils/helpers";
import {
  createStoreBannerRequest,
  deleteStoreBannerRequest,
  editStoreBannerRequest,
  getStoreDetailRequest,
  updateStoreRequest,
  changeStoreOwnerRequest,
  getStoreBannerListRequest,
} from "providers/StoreProvider/slice";
import { Tabs } from "components/common";
import StoreBannerList from "components/StoreBanner/List";
import StoreBannerDetail from "components/StoreBanner/Detail";
// import SetTagProductArticleModal from "components/SetTagProductArticleModal";
// import CosmeticProductModal from "../CosmeticProduct/CosmeticProductModal";
// import MenuOverlay from "./MenuOverlay";
// import MenuAllOverlay from "./MenuAllOverlay";
import ImageGallery from "components/Store/ImageGallery";
import moment from "moment";
import SearchBar from "./SearchBar";

const context = {
  [STORE_TYPE.CAR_AND_OLD_STUFF]: {
    title: "Thông tin cửa hàng",
    hasProduct: false,
    hasPhotoGallery: false,
  },
  [STORE_TYPE.TRAVEL]: {
    title: "Thông tin du lịch",
    hasProduct: false,
    hasPhotoGallery: true,
  },
  [STORE_TYPE.SHIPPER]: {
    title: "Thông tin dịch vụ vận chuyển",
    hasProduct: false,
    hasPhotoGallery: false,
  },
  [STORE_TYPE.ENTERTAINMENT]: {
    title: "Thông tin giải trí",
    hasProduct: false,
    hasPhotoGallery: false,
  },
};

const StoreDetail = ({ storeType }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const { detail, banners, isLoadingBanner } = useSelector(
    (state) => state.store
  );

  const storeContext = context[storeType];

  const [currentTab, setCurrentTab] = useState("detail");
  const [banner, setBanner] = useState({});
  const { userId } = detail;

  const { _id: idCurrentUser, role: roleCurrentUser } = useSelector(
    (state) => state.auth.currentUser
  );

  const isPermissionToEdit =
    idCurrentUser === userId ||
    [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(roleCurrentUser);

  // const {
  //   selectedRows = [],
  //   selectedRowKeys = [],
  //   docs: cosmeticProductList,
  //   totalDocs: cosmeticProductTotal,
  //   setTagProductArticleModalData: { visible = false, data: modalData },
  // } = useSelector((state) => state.cosmeticProduct);
  // const [searchObject, setSearch] = useQuery(getCosmeticProductListRequest, {
  //   arrayParams: ["cosmeticProductTypeId"],
  //   customParameter: {
  //     cosmeticId: id,
  //   },
  // });
  // const cosmeticProductIdList = [];
  // const productCosmeticNameList = [];
  // selectedRows.map((item) =>
  //   cosmeticProductIdList.push(item.cosmeticProductId)
  // );
  // selectedRows.map((item) => productCosmeticNameList.push(item.name));

  // const products = _get(modalData, "products", []);
  // const originalProducts = _get(modalData, "originalProducts", []);

  useEffect(() => {
    if (id) {
      dispatch(getStoreDetailRequest({ _id: id }));
      dispatch(getStoreBannerListRequest({ storeId: id }));
    }
  }, [id, dispatch, storeType]);

  // const refetchList = () => {
  //   dispatch(getCosmeticProductListRequest(searchObject));
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onSelect: (record, selected, _selectedRows) => {
  //     dispatch(
  //       setSelectedIds({ record, selected, selectedRows: _selectedRows })
  //     );
  //   },
  //   onSelectAll: (selected, _selectedRows, changeRows) => {
  //     dispatch(
  //       setSelectedIds({ selected, selectedRows: _selectedRows, changeRows })
  //     );
  //   },
  // };

  // const columns = [
  //   CosmeticProductColumn.productId,
  //   CosmeticProductColumn.name,
  //   CosmeticProductColumn.type,
  //   CosmeticProductColumn.element,
  //   CosmeticProductColumn.amount,
  //   CosmeticProductColumn.promotion,
  //   CosmeticProductColumn.price,
  //   CosmeticProductColumn.promotionPrice,
  //   CosmeticProductColumn.tag,
  //   {
  //     title: (
  //       <Dropdown
  //         overlay={
  //           <MenuAllOverlay
  //             isPermissionToEdit={isPermissionToEdit}
  //             refetchList={refetchList}
  //             selectedRowKeys={selectedRowKeys}
  //             selectedRows={selectedRows}
  //             productCosmeticNameList={productCosmeticNameList}
  //             cosmeticProductIdList={cosmeticProductIdList}
  //           />
  //         }
  //         placement="bottomLeft"
  //         arrow={isPermissionToEdit}
  //       >
  //         <Button
  //           size="large"
  //           className="see-more-btn"
  //           type="ghost"
  //           icon={<img src={IconSeeMore} alt="see-more" />}
  //         />
  //       </Dropdown>
  //     ),
  //     width: 100,
  //     render: (record) => (
  //       <Dropdown
  //         overlay={
  //           <MenuOverlay
  //             record={record}
  //             isPermissionToEdit={isPermissionToEdit}
  //             refetchList={refetchList}
  //           />
  //         }
  //         placement="bottomLeft"
  //         arrow
  //       >
  //         <Button
  //           className="see-more-btn"
  //           type="ghost"
  //           icon={<img src={IconSeeMore} alt="see-more" />}
  //         />
  //       </Dropdown>
  //     ),
  //   },
  // ];

  const handleSubmit = async (values) => {
    const latLng = await Helper.getLatLng(values.address, {
      lng: values.lng,
      lat: values.lat,
    });
    values.longitude = latLng.lng;
    values.latitude = latLng.lat;
    if (
      values.discountPercentage?.value === detail?.discountPercentage?.value &&
      values.discountPercentage?.value
    ) {
      values.discountPercentage = detail?.discountPercentage?.value;
    }
    if (values.start) {
      values.customDiscountCondition = {
        start: values.start,
        end: values.end,
      };
    }
    dispatch(
      updateStoreRequest({
        ...values,
        _id: id,
        type: storeType,
      })
    ).then(() => {
      dispatch(getStoreDetailRequest({ _id: id }));
      setShowEdit(false);
    });
  };

  const handleChangeStoreOnwer = ({ type, idNewOnwer }) => {
    dispatch(
      changeStoreOwnerRequest({
        type,
        _id: id,
        ownerId: idNewOnwer,
      })
    ).then(() => {
      dispatch(resetSelectedRowKey());
      dispatch(getStoreDetailRequest({ _id: id }));
      dispatch(setChangeStoreOnwerModal({ isOpen: false }));
    });
  };

  const handleChangeTab = (activeKey) => {
    setCurrentTab(activeKey);
    setBanner({});
  };

  const handleSubmitBanner = (values) => {
    const imageUrl = _get(values, "imageUrl.url", "");
    if (banner.imageUrl === null) {
      dispatch(
        createStoreBannerRequest({ ...values, imageUrl, storeId: id })
      ).then(() => {
        dispatch(getStoreBannerListRequest({ storeId: id }));
      });
    } else {
      dispatch(
        editStoreBannerRequest({
          ...values,
          imageUrl,
          storeId: id,
          bannerId: banner._id,
        })
      ).then(() => {
        dispatch(getStoreBannerListRequest({ storeId: id }));
      });
    }
    setBanner({});
  };

  const handleRemoveBanner = (record) => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          ...DELETE_BANNER_CONTENT,
          onOk: () =>
            dispatch(
              deleteStoreBannerRequest({
                storeId: id,
                bannerId: record._id,
              })
            ).then(() => {
              dispatch(getStoreBannerListRequest({ storeId: id }));
            }),
        },
      })
    );
  };

  const handleToggleBannerStatus = (record) => {
    dispatch(
      editStoreBannerRequest({
        ...record,
        storeId: id,
        bannerId: record._id,
        isActive: !record.isActive,
      })
    ).then(() => {
      dispatch(getStoreBannerListRequest({ storeId: id }));
    });
  };

  return (
    <div className="store-detail-wrapper">
      {/* <SetTagProductArticleModal
        category="cosmetic"
        setTagProductArticleModalData={setTagProductArticleModalData}
        visible={visible}
        products={_uniq(products)}
        originalProducts={_uniq(originalProducts)}
        setTagProductArticleRequest={setTagProductCosmeticRequest}
        getProductArticleListRequest={getCosmeticProductListRequest}
        searchObject={searchObject}
      /> */}
      {/* <CosmeticProductModal
        isPermissionToEdit={isPermissionToEdit}
        searchObject={searchObject}
        setCosmeticProductModal={setCosmeticProductModal}
        setSearch={setSearch}
      /> */}
      <Row className="store-detail-header" justify="start" align="middle">
        <BackButton />
        <Typography.Title level={4}>{storeContext.title}</Typography.Title>
      </Row>
      <div className="detail-wrapper">
        <ArticleShopOwner
          category="cosmetic"
          shopOwnerInfoDetail={_get(detail, "userId", {})}
          handleChangeStoreOnwer={handleChangeStoreOnwer}
        />
        <Tabs
          type="card"
          activeKey={currentTab}
          onChange={handleChangeTab}
          destroyInactiveTabPane
          contents={[
            {
              key: "detail",
              header: "Thông tin cơ bản",
              content: showEdit ? (
                <StoreEdit
                  initialValues={detail}
                  onCancel={() => setShowEdit(false)}
                  onSubmit={handleSubmit}
                  storeType={storeType}
                />
              ) : (
                <StoreComponent
                  isPermissionToEdit={isPermissionToEdit}
                  detail={detail}
                  onEdit={() => setShowEdit(true)}
                  storeType={storeType}
                />
              ),
            },
            {
              key: "banner",
              header: "Set banner",
              content: _isEmpty(banner) ? (
                <StoreBannerList
                  list={banners}
                  total={banners.length}
                  loading={isLoadingBanner}
                  handleOpenBannerDetail={(data) => setBanner(data)}
                  handleRemoveBanner={handleRemoveBanner}
                  handleToggleStatus={handleToggleBannerStatus}
                />
              ) : (
                <StoreBannerDetail
                  initialValues={banner}
                  isEditBanner={banner.imageUrl !== null}
                  handleCancel={() => setBanner({})}
                  handleSubmit={handleSubmitBanner}
                />
              ),
            },
          ]}
        />
        {storeContext.hasProduct && (
          <div className="content-container">
            <SearchBar isPermissionToEdit={isPermissionToEdit} />
            <Table
              rowSelection={{}}
              scroll={{ x: "max-content" }}
              loading={false}
              bordered
              columns={[]}
              dataSource={[]}
              rowKey={(data) => data._id}
              total={0}
            />
          </div>
        )}
        {storeContext.hasPhotoGallery && (
          <ImageGallery storeId={id} detail={detail} />
        )}
      </div>
    </div>
  );
};

export default StoreDetail;
