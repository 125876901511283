import { useState } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import "./styles.less";
import StatusBlock from "components/StatusBlock";

const ReportPermissionTooltip = (props) => {
  const { active } = props;
  const [status] = useState(active);
  return (
    <div className="article-permission-tooltip-wrapper">
      <Row align="middle">
        <Col style={{ marginRight: 10 }}>
          <StatusBlock status="APPROVED" />
        </Col>
        <Col className="tootltip-text">
          <div className={status === "APPROVED" ? "bold" : ""}>
            Bài viết đã duyệt
          </div>
        </Col>
      </Row>

      <Row align="middle" style={{ marginTop: 10, marginBottom: 10 }}>
        <Col style={{ marginRight: 10 }}>
          <StatusBlock status="NEW" />
        </Col>
        <Col className="tootltip-text">
          <div className={status === "NEW" ? "bold" : ""}>
            Bài viết đang chờ
          </div>
        </Col>
      </Row>
      <Row align="middle">
        <Col style={{ marginRight: 10 }}>
          <StatusBlock status="CANCEL" />
        </Col>
        <Col className="tootltip-text">
          <div className={status === "CANCEL" ? "bold" : ""}>Từ chối</div>
        </Col>
      </Row>
    </div>
  );
};

ReportPermissionTooltip.propTypes = {
  active: PropTypes.string,
};
export default ReportPermissionTooltip;
