import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const conversationAdapter = createEntityAdapter({
  selectId: (conversation) => conversation._id,
  sortComparer: (a, b) => b.updatedAt?.localeCompare(a.updatedAt),
});

const messageAdapter = createEntityAdapter({
  selectId: (message) => message._id,
  sortComparer: (a, b) => b.createdAt?.localeCompare(a.createdAt),
});

export const conversationSelectors = conversationAdapter.getSelectors(
  (state) => state.messenger.conversations
);

export const messageSelectors = messageAdapter.getSelectors(
  (state) => state.messenger.messages
);

const messengerSlice = createSlice({
  name: "messenger",
  initialState: {
    firstGetList: false,
    isGettingList: false,
    isGettingMessageList: false,
    isSendingMessage: false,
    isCreatingConversation: false,
    isGettingConversationDetail: false,
    conversations: conversationAdapter.getInitialState(),
    messages: messageAdapter.getInitialState({
      lastMessageTime: null,
    }),
    socket: null,
    isOpenModalTag: false,
    conversationId: "",
    conversationName: "",
    chosenConversation: {},
    isSending: false,
    isOpenModalSetTag: false,
    fonehouseTags: [],
    fonehouseTagsSelect: [],
    fonehouseTagsFilter: [],
  },
  reducers: {
    sendingMessage(state) {
      return { ...state, isSending: true };
    },
    finishSendMessage(state) {
      return { ...state, isSending: false };
    },
    firstGetListMessage(state) {
      return { ...state, firstGetList: true };
    },
    firstGetListMessageFalse(state) {
      return { ...state, firstGetList: false };
    },
    getConversationListRequest(state) {
      return { ...state, isGettingList: true };
    },
    getConversationListSuccess(state, action) {
      const { list, page } = action.payload;
      state.isGettingList = false;
      if (page === 1) {
        conversationAdapter.setAll(state.conversations, list);
        state.conversations.page = 1;
      } else {
        state.conversations.page += 1;
        conversationAdapter.addMany(state.conversations, list);
      }
      state.conversations.hasMore = list.length >= 5;
    },
    getMessageListRequest(state) {
      return { ...state, isGettingMessageList: true };
    },
    getMessageListSuccess(state, action) {
      const { list, time, conversationId } = action.payload;
      state.isGettingList = false;
      state.isGettingMessageList = false;
      if (!time) {
        messageAdapter.setAll(state.messages, list);
        state.messages.page = 1;
        state.firstGetList = true;
      } else {
        state.messages.page += 1;
        messageAdapter.addMany(state.messages, list);
      }
      const lastMessage = list[list.length - 1];
      if (lastMessage) {
        state.messages.lastMessageTime = new Date(
          list[list.length - 1].createdAt
        ).getTime();
      }

      state.messages.hasMore = list.length >= 15;
      const conversationEntity = conversationAdapter
        .getSelectors()
        .selectById(state.conversations, conversationId);
      conversationAdapter.setOne(state.conversations, {
        ...conversationEntity,
        unseenMessages: 0,
      });
    },
    stopLoading(state) {
      return { ...state, isGettingList: false };
    },
    sendMessageRequest: {
      reducer: (state) => {
        state.isSendingMessage = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    sendMessageSuccess: {
      reducer: (state) => {
        state.isSendingMessage = false;
        return state;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateMessage(state, action) {
      const { message } = action.payload;
      messageAdapter.upsertOne(state.messages, message);
    },
    createConversationRequest: {
      reducer: (state) => {
        state.isCreatingConversation = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createConversationSuccess: {
      reducer: (state) => {
        state.isCreatingConversation = false;
        return state;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    getConversationDetailRequest(state) {
      return { ...state, isGettingConversationDetail: true };
    },
    getConversationDetailSuccess(state, action) {
      const { detail } = action.payload;
      conversationAdapter.upsertOne(state.conversations, detail);
    },
    seenConversationRequest(state) {
      return { ...state };
    },
    triggerModalTag(state, action) {
      const conversationName = action.payload?.conversationEntities?.[
        action.payload?.conversationId
      ]?.users?.find((user) => user?.role === "user")?.name;
      return {
        ...state,
        isOpenModalTag: action.payload.visible,
        conversationId: action.payload?.conversationId,
        conversationName,
        chosenConversation:
          action.payload?.conversationEntities?.[
            action.payload?.conversationId
          ],
      };
    },
    createTagRequest(state) {
      return {
        ...state,
        isGettingList: true,
      };
    },
    triggerModalSetTag(state, action) {
      const userName = action.payload?.data?.users?.find(
        (user) => user?.role === "user"
      )?.name;
      return {
        ...state,
        isOpenModalSetTag: action.payload.visible,
        conversationName: userName,
        conversationId: action.payload?.data?._id,
      };
    },
    getFonehouseTagsRequest(state) {
      return {
        ...state,
      };
    },
    getFonehouseTagsSuccess(state, action) {
      const fonehouseTagsSelect = [];
      const fonehouseTagsFilter = [];
      action.payload?.tags?.forEach((tag, index) => {
        fonehouseTagsSelect.push({
          label: tag.name,
          value: `${tag.name}-${tag.color}`,
        });
        fonehouseTagsFilter.push({
          label: tag.name,
          value: `${tag.name}-${index}`,
        });
      });
      fonehouseTagsFilter.unshift({
        label: "Tất cả",
        value: "",
      });
      return {
        ...state,
        fonehouseTags: action.payload?.tags ?? [],
        fonehouseTagsSelect,
        fonehouseTagsFilter,
      };
    },
  },
});

export const {
  firstGetListMessageFalse,
  firstGetListMessage,
  getConversationListRequest,
  getConversationListSuccess,
  getMessageListRequest,
  getMessageListSuccess,
  stopLoading,
  sendMessageRequest,
  sendMessageSuccess,
  updateMessage,
  createConversationRequest,
  createConversationSuccess,
  getConversationDetailRequest,
  getConversationDetailSuccess,
  seenConversationRequest,
  triggerModalTag,
  createTagRequest,
  triggerModalSetTag,
  getFonehouseTagsRequest,
  getFonehouseTagsSuccess,
  sendingMessage,
  finishSendMessage,
} = messengerSlice.actions;

export default messengerSlice.reducer;
