/* eslint-disable react/display-name */

import i18n from "i18n";
import moment from "moment";
import SummaryTooltip from "components/SummaryTooltip";
import NewsSummaryTooltip from "components/SummaryTooltip/NewsSummaryTooltip";

const Columns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.id = {
  dataIndex: "_id",
  key: "_id",
  title: "#",
  render: (guildId, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<NewsSummaryTooltip newsDetail={data} role="fonehouse" />}
      >
        <div>{`#${guildId}`}</div>
      </SummaryTooltip>
    ),
  width: 120,
};

Columns.createdAt = {
  dataIndex: "createdAt",
  key: "createdAt",
  title: i18n.t("newsFonehouse.createAt"),
  sorter: true,
  render: (createdAt, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<NewsSummaryTooltip newsDetail={data} role="fonehouse" />}
      >
        <div>{moment(createdAt).format("DD/MM/YYYY")}</div>
      </SummaryTooltip>
    ),
  width: 130,
};

Columns.title = {
  title: i18n.t("newsFonehouse.title"),
  dataIndex: "title",
  key: "title",
  sorter: true,
  render: (title, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<NewsSummaryTooltip newsDetail={data} role="fonehouse" />}
      >
        <div>{title}</div>
      </SummaryTooltip>
    ),
};

Columns.username = {
  title: i18n.t("newsFonehouse.account"),
  dataIndex: "user",
  key: "user",
  render: (user = {}) => renderDataValue(user.name),
  width: 200,
};

Columns.pin = {
  title: "Quảng cáo",
  dataIndex: "pin",
  key: "pin",
  render: (pin) => {
    if (pin === 1) {
      return renderDataValue(<div className="store-advertising">Bài Ghim</div>);
    }
    return null;
  },
  width: 200,
};

export default Columns;
