import { put, takeLeading, call, takeLatest } from "redux-saga/effects";
import request from "utils/api";
import { safe } from "providers/GeneralProvider/saga";
import {
  getAccessoryListRequest,
  getAccessoryListSuccess,
  requestFail,
  createAccessorySuccess,
  createOrUpdateAccessoryRequest,
  deleteAccessoryRequest,
} from "providers/AccessoryProvider/slice";

function* handleGetAccessoryList(action) {
  const { page, limit, keyword } = action.payload;

  const endpoint = keyword
    ? `/api/accessories?page=${page}&limit=${limit}&keyword=${keyword}`
    : `/api/accessories?page=${page}&limit=${limit}`;
  const response = yield call(request, endpoint, undefined, {
    method: "GET",
  });
  yield put(getAccessoryListSuccess(response));
}

function* handleCreateOrUpdateAccessory(action) {
  const {
    name,
    price,
    initialPrice,
    thumbnail,
    images,
    callback,
    isCreating,
    id,
  } = action.payload;
  const params = {
    name,
    price,
    initialPrice,
    thumbnail,
    images,
    compatibleWithAll: true,
    compatibleWith: [],
  };
  const method = isCreating ? "POST" : "PUT";
  const endpoint = isCreating ? "/api/accessories" : `/api/accessories/${id}`;
  yield call(request, endpoint, params, { method });
  yield callback && callback();
  yield put(createAccessorySuccess());
}

function* handleDeleteAccessory(action) {
  try {
    const { id, callback } = action.payload;
    const endpoint = `/api/accessories/${id}`;
    yield call(request, endpoint, undefined, { method: "DELETE" });
    yield callback && callback();
  } catch (error) {
    yield put(requestFail());
  }
}

function* onError() {
  yield put(requestFail());
}

export default function* watchAccessory() {
  yield takeLeading(
    getAccessoryListRequest.type,
    safe(handleGetAccessoryList, onError)
  );
  yield takeLatest(
    createOrUpdateAccessoryRequest.type,
    safe(handleCreateOrUpdateAccessory, onError)
  );
  yield takeLatest(
    deleteAccessoryRequest.type,
    safe(handleDeleteAccessory, onError)
  );
}
