import SummaryTooltip from "components/SummaryTooltip";
import StorePhotoSummaryTooltip from "components/SummaryTooltip/StorePhotoSummaryTooltip";

const Columns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || "Trống"}</span>;
};

Columns.id = {
  title: "#",
  dataIndex: "_id",
  width: 120,
  render: (id, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<StorePhotoSummaryTooltip detail={data} />}
        widthTooltipInner={680}
      >
        <div>#{id}</div>
      </SummaryTooltip>
    ),
};

Columns.accountHolder = {
  dataIndex: ["createdBy", "name"],
  title: "Chủ tài khoản",
  render: (name, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<StorePhotoSummaryTooltip detail={data} />}
        widthTooltipInner={680}
      >
        <div>{name}</div>
      </SummaryTooltip>
    ),
  width: 140,
};

Columns.accountEmail = {
  dataIndex: ["createdBy", "email"],
  title: "Email",
  render: (email, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<StorePhotoSummaryTooltip detail={data} />}
        widthTooltipInner={680}
      >
        <div>{email}</div>
      </SummaryTooltip>
    ),
  width: 140,
};

Columns.accountAddress = {
  dataIndex: ["createdBy", "address"],
  title: "Địa chỉ",
  render: (address, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<StorePhotoSummaryTooltip detail={data} />}
        widthTooltipInner={680}
      >
        <div>{address}</div>
      </SummaryTooltip>
    ),
  width: 240,
};

Columns.accountPhone = {
  dataIndex: ["createdBy", "phone"],
  title: "Số điện thoại",
  render: (phone, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<StorePhotoSummaryTooltip detail={data} />}
        widthTooltipInner={680}
      >
        <div>{phone}</div>
      </SummaryTooltip>
    ),
  width: 140,
};

Columns.comment = {
  title: "Nội dung đóng góp",
  dataIndex: "comment",
  render: (comment, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<StorePhotoSummaryTooltip detail={data} />}
        widthTooltipInner={680}
      >
        <div>{comment}</div>
      </SummaryTooltip>
    ),
  width: 240,
};

Columns.status = {
  title: "Trạng thái",
  dataIndex: "isActive",
  render: (isActive) =>
    renderDataValue(
      <div
        style={{
          fontWeight: "bold",
          ...(isActive ? { color: "#2FBE50" } : { color: "#FF0000" }),
        }}
      >
        {isActive ? "Đã duyệt" : "Từ chối"}
      </div>
    ),
  width: 120,
};

export default Columns;
