import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";
import { resetStoreState } from "providers/StoreProvider/slice";

const marketProductSlice = createSlice({
  name: "marketProduct",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    marketId: null,
    detail: {},
    marketProductModalData: {},
    productMarketTypeList: [],
    setTagProductArticleModalData: {},
    isSettingTag: false,
  },
  reducers: {
    getMarketProductListRequest(state) {
      return { ...state, isLoading: true };
    },
    getMarketProductListSuccess(state, action) {
      const { payload = {} } = action;
      const { products, total } = payload;
      return {
        ...state,
        marketId: _get(products, "[0]._id"),
        isLoading: false,
        docs: products.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs: total,
      };
    },

    setSelectedIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isSettingTag: false };
    },
    updateStatusSupermarketRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },

    updateStatusSupermarketSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
        selectedRowKeys: [],
        selectedRows: [],
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateMarketProductRequest: {
      reducer: (state) => {
        state.isCreating = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateMarketProductSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    getSupermarketDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getSupermarketDetailSuccess(state, action) {
      return { ...state, isLoading: false, detail: action.payload };
    },
    setMarketProductModal(state, action) {
      const { payload = {} } = action;
      const { visible, data, type } = payload;
      return {
        ...state,
        marketProductModalData: {
          visible,
          data,
          type,
        },
      };
    },
    getProductMarketTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    getProductMarketTypeSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        productMarketTypeList: action.payload,
      };
    },
    addProductMarketTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    addProductMarketTypeSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        productMarketTypeList: state.productMarketTypeList.concat(
          action.payload
        ),
      };
    },
    createProductMarketRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createProductMarketSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateProductMarketRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateProductMarketSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteProductMarketRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    deleteProductMarketSuccess: {
      reducer: (state) => ({
        ...state,
        selectedRowKeys: [],
        selectedRows: [],
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteProductMarketTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    deleteProductMarketTypeSuccess(state) {
      return { ...state, isLoading: false };
    },
    editProductMarketTypeRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    editProductMarketTypeSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    setTagProductArticleModalData(state, action) {
      const { payload = {} } = action;
      const { visible, data } = payload;
      return {
        ...state,
        setTagProductArticleModalData: {
          visible,
          data: data || state.setTagProductArticleModalData.data,
        },
      };
    },
    setTagProductMarketRequest: {
      reducer: (state) => {
        state.isSettingTag = true;
        state.selectedRowKeys = [];
        state.selectedRows = [];
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    setTagProductMarketSuccess: {
      reducer: (state) => ({ ...state, isSettingTag: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      resetStoreState.type,
      (state) => ({
        ...state,
        docs: [],
      })
      // action is inferred correctly here if using TS
    );
  },
});

export const {
  setSelectedIds,
  confirmBusinessRequest,
  confirmBusinessSuccess,
  getMarketProductListSuccess,
  getMarketProductListRequest,
  stopLoading,
  updateStatusSupermarketRequest,
  updateStatusSupermarketSuccess,
  getSupermarketDetailRequest,
  getSupermarketDetailSuccess,
  updateMarketProductSuccess,
  updateMarketProductRequest,
  setMarketProductModal,
  getProductMarketTypeRequest,
  getProductMarketTypeSuccess,
  addProductMarketTypeRequest,
  addProductMarketTypeSuccess,
  createProductMarketRequest,
  createProductMarketSuccess,
  updateProductMarketRequest,
  updateProductMarketSuccess,
  deleteProductMarketRequest,
  deleteProductMarketSuccess,
  deleteProductMarketTypeRequest,
  deleteProductMarketTypeSuccess,
  editProductMarketTypeRequest,
  editProductMarketTypeSuccess,
  setTagProductArticleModalData,
  setTagProductMarketRequest,
  setTagProductMarketSuccess,
} = marketProductSlice.actions;

export default marketProductSlice.reducer;
