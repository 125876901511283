import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Typography, Menu, Dropdown, Button } from "antd";
import _isEmpty from "lodash/isEmpty";
import _uniq from "lodash/uniq";
import _get from "lodash/get";
import "./styles.less";
import BackButton from "components/BackButton";
import {
  getCosmeticProductListRequest,
  setSelectedIds,
  deleteProductCosmeticRequest,
  setCosmeticProductModal,
  setTagProductArticleModalData,
  setTagProductCosmeticRequest,
} from "providers/CosmeticProductProvider/slice";
import CosmeticComponent from "components/Cosmetic/Detail";
import CosmeticEdit from "components/Cosmetic/Edit";
import Table from "components/common/Table";
import { useQuery } from "hooks";
import { CosmeticProductColumn } from "components/Columns";
import i18n from "i18n";
import { IconSeeMore } from "assets/images";
import {
  ARTICLE_PRODUCT_MODAL_TYPE,
  ROLES,
  STORE_TYPE,
  DELETE_BANNER_CONTENT,
} from "utils/constants";
import { resetSelectedRowKey } from "providers/AdminProvider/slice";
import {
  setPopup,
  setChangeStoreOnwerModal,
} from "providers/GeneralProvider/slice";
import SetTagProductArticleModal from "components/SetTagProductArticleModal";
import ArticleShopOwner from "components/ArticleShopOwner";
import Helper from "utils/helpers";
import {
  createStoreBannerRequest,
  deleteStoreBannerRequest,
  editStoreBannerRequest,
  getStoreDetailRequest,
  updateStoreRequest,
  changeStoreOwnerRequest,
  getStoreBannerListRequest,
  resetStoreState,
} from "providers/StoreProvider/slice";
import { Tabs } from "components/common";
import StoreBannerList from "components/StoreBanner/List";
import StoreBannerDetail from "components/StoreBanner/Detail";
import moment from "moment";
import CosmeticProductModal from "../CosmeticProduct/CosmeticProductModal";
import SearchBar from "./SearchBar";

const CosmeticDetail = () => {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const { detail, isLoading, banners, isLoadingBanner } = useSelector(
    (state) => state.store
  );
  const [currentTab, setCurrentTab] = useState("detail");
  const [banner, setBanner] = useState({});
  const { userId } = detail;
  const idCurrentUser = useSelector((state) =>
    _get(state, "auth.currentUser._id")
  );
  const roleCurrentUser = useSelector((state) =>
    _get(state, "auth.currentUser.role")
  );
  const isPermissionToEdit =
    idCurrentUser === userId ||
    [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(roleCurrentUser);
  const selectedRows = useSelector((state) =>
    _get(state, "cosmeticProduct.selectedRows", [])
  );
  const selectedRowKeys = useSelector((state) =>
    _get(state, "cosmeticProduct.selectedRowKeys", [])
  );
  const { cosmeticId } = useParams();
  const [searchObject, setSearch] = useQuery(getCosmeticProductListRequest, {
    arrayParams: ["cosmeticProductTypeId"],
    customParameter: {
      cosmeticId,
    },
  });
  const cosmeticProductIdList = [];
  const productCosmeticNameList = [];
  selectedRows.map((item) =>
    cosmeticProductIdList.push(item.cosmeticProductId)
  );
  selectedRows.map((item) => productCosmeticNameList.push(item.name));

  useEffect(() => {
    if (cosmeticId) {
      dispatch(getStoreDetailRequest({ _id: cosmeticId }));
      dispatch(getStoreBannerListRequest({ storeId: cosmeticId }));
    }
    return () => {
      dispatch(resetStoreState());
    };
  }, [cosmeticId, dispatch]);

  const menuAll = () => (
    <Menu>
      <Menu.Item
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: "Xác nhận",
                cancelText: "Huy",
                title: "Xác nhận xóa sản phẩm",
                content: `Bạn có muốn xóa: ${productCosmeticNameList.join(
                  ", "
                )}`,
                onOk: () =>
                  dispatch(
                    deleteProductCosmeticRequest({
                      ids: selectedRowKeys,
                      cosmeticProductIds: _uniq(cosmeticProductIdList),
                    })
                  ).then(() =>
                    dispatch(getCosmeticProductListRequest(searchObject))
                  ),
              },
            })
          )
        }
        disabled={_isEmpty(selectedRowKeys)}
      >
        {i18n.t("cosmeticProduct.delete")}
      </Menu.Item>
      <Menu.Item
        disabled={_isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            setTagProductArticleModalData({
              visible: true,
              data: {
                products: selectedRows,
                originalProducts: selectedRows,
              },
            })
          )
        }
      >
        {i18n.t("products.setTag")}
      </Menu.Item>
    </Menu>
  );
  const menu = (record) => {
    const { _id, name, cosmeticProductId } = record;
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            dispatch(
              setCosmeticProductModal({
                visible: true,
                data: record,
                type: ARTICLE_PRODUCT_MODAL_TYPE.DETAIL,
              })
            )
          }
        >
          {i18n.t("cosmeticProduct.seeDetail")}
        </Menu.Item>
        {isPermissionToEdit && (
          <>
            <Menu.Item
              onClick={() =>
                dispatch(
                  setCosmeticProductModal({
                    visible: true,
                    data: record,
                    type: ARTICLE_PRODUCT_MODAL_TYPE.EDIT,
                  })
                )
              }
            >
              {i18n.t("cosmeticProduct.edit")}
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                dispatch(
                  setPopup({
                    isOpen: true,
                    data: {
                      okText: "Xác nhận",
                      cancelText: "Huy",
                      title: "Xác nhận xóa sản phẩm",
                      content: `Bạn có muốn xóa: ${name}`,
                      onOk: () =>
                        dispatch(
                          deleteProductCosmeticRequest({
                            ids: [_id],
                            cosmeticProductIds: [cosmeticProductId],
                          })
                        ).then(() =>
                          dispatch(getCosmeticProductListRequest(searchObject))
                        ),
                    },
                  })
                );
              }}
            >
              {i18n.t("cosmeticProduct.delete")}
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                dispatch(
                  setTagProductArticleModalData({
                    visible: true,
                    data: {
                      products: [record],
                      originalProducts: [record],
                    },
                  })
                )
              }
            >
              {i18n.t("products.setTag")}
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const cosmeticProductList = useSelector((state) =>
    _get(state, "cosmeticProduct.docs")
  );
  const cosmeticProductTotal = useSelector((state) =>
    _get(state, "cosmeticProduct.totalDocs")
  );
  const visible = useSelector(
    (state) => state.cosmeticProduct.setTagProductArticleModalData.visible
  );
  const products = useSelector((state) =>
    _get(
      state.cosmeticProduct.setTagProductArticleModalData,
      "data.products",
      []
    )
  );
  const originalProducts = useSelector((state) =>
    _get(
      state.cosmeticProduct.setTagProductArticleModalData,
      "data.originalProducts",
      []
    )
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedIds({ record, selected, selectedRows: _selectedRows })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedIds({ selected, selectedRows: _selectedRows, changeRows })
      );
    },
  };

  const columns = [
    CosmeticProductColumn.productId,
    CosmeticProductColumn.name,
    CosmeticProductColumn.type,
    CosmeticProductColumn.element,
    CosmeticProductColumn.amount,
    CosmeticProductColumn.promotion,
    CosmeticProductColumn.price,
    CosmeticProductColumn.promotionPrice,
    CosmeticProductColumn.tag,
    {
      title: (
        <Dropdown
          overlay={isPermissionToEdit ? menuAll : ""}
          placement="bottomLeft"
          arrow={isPermissionToEdit}
        >
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  const handleSubmit = async (values) => {
    const latLng = await Helper.getLatLng(values.address, {
      lng: values.lng,
      lat: values.lat,
    });

    if (
      values.discountPercentage?.value === detail?.discountPercentage?.value &&
      values.discountPercentage?.value
    ) {
      values.discountPercentage = detail?.discountPercentage?.value;
    }

    if (values?.start) {
      values.customDiscountCondition = {
        start: values?.start,
        end: values?.end,
      };
    }
    values.longitude = latLng.lng;
    values.latitude = latLng.lat;
    dispatch(
      updateStoreRequest({
        ...values,
        _id: cosmeticId,
        type: STORE_TYPE.COSMETIC,
      })
    ).then(() => {
      dispatch(getStoreDetailRequest({ _id: cosmeticId }));
      setShowEdit(false);
    });
  };

  const handleChangeStoreOnwer = ({ type, idNewOnwer }) => {
    dispatch(
      changeStoreOwnerRequest({
        type,
        _id: cosmeticId,
        ownerId: idNewOnwer,
      })
    ).then(() => {
      dispatch(resetSelectedRowKey());
      dispatch(getStoreDetailRequest({ _id: cosmeticId }));
      dispatch(setChangeStoreOnwerModal({ isOpen: false }));
    });
  };

  const handleChangeTab = (activeKey) => {
    setCurrentTab(activeKey);
    setBanner({});
  };

  const handleSubmitBanner = (values) => {
    const imageUrl = _get(values, "imageUrl.url", "");
    if (banner.imageUrl === null) {
      dispatch(
        createStoreBannerRequest({ ...values, imageUrl, storeId: cosmeticId })
      ).then(() => {
        dispatch(getStoreBannerListRequest({ storeId: cosmeticId }));
      });
    } else {
      dispatch(
        editStoreBannerRequest({
          ...values,
          imageUrl,
          storeId: cosmeticId,
          bannerId: banner._id,
        })
      ).then(() => {
        dispatch(getStoreBannerListRequest({ storeId: cosmeticId }));
      });
    }
    setBanner({});
  };

  const handleRemoveBanner = (record) => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          ...DELETE_BANNER_CONTENT,
          onOk: () =>
            dispatch(
              deleteStoreBannerRequest({
                storeId: cosmeticId,
                bannerId: record._id,
              })
            ).then(() => {
              dispatch(getStoreBannerListRequest({ storeId: cosmeticId }));
            }),
        },
      })
    );
  };

  const handleToggleBannerStatus = (record) => {
    dispatch(
      editStoreBannerRequest({
        ...record,
        storeId: cosmeticId,
        bannerId: record._id,
        isActive: !record.isActive,
      })
    ).then(() => {
      dispatch(getStoreBannerListRequest({ storeId: cosmeticId }));
    });
  };

  return (
    <div className="cosmetic-detail-wrapper">
      <SetTagProductArticleModal
        category="cosmetic"
        setTagProductArticleModalData={setTagProductArticleModalData}
        visible={visible}
        products={_uniq(products)}
        originalProducts={_uniq(originalProducts)}
        setTagProductArticleRequest={setTagProductCosmeticRequest}
        getProductArticleListRequest={getCosmeticProductListRequest}
        searchObject={searchObject}
      />
      <CosmeticProductModal
        isPermissionToEdit={isPermissionToEdit}
        searchObject={searchObject}
        setCosmeticProductModal={setCosmeticProductModal}
        setSearch={setSearch}
      />
      <Row
        className="cosmetic-detail-header"
        justify="space-between"
        align="middle"
      >
        <Row>
          <BackButton />
          <Typography.Title level={4}>
            Thông tin cửa hàng làm đẹp
          </Typography.Title>
        </Row>
      </Row>
      <div className="detail-wrapper">
        <ArticleShopOwner
          category="cosmetic"
          shopOwnerInfoDetail={_get(detail, "userId", {})}
          handleChangeStoreOnwer={handleChangeStoreOnwer}
        />
        <Tabs
          type="card"
          activeKey={currentTab}
          onChange={handleChangeTab}
          destroyInactiveTabPane
          contents={[
            {
              key: "detail",
              header: "Thông tin cơ bản",
              content: showEdit ? (
                <CosmeticEdit
                  initialValues={detail}
                  onCancel={() => setShowEdit(false)}
                  onSubmit={handleSubmit}
                />
              ) : (
                <CosmeticComponent
                  isPermissionToEdit={isPermissionToEdit}
                  detail={detail}
                  onEdit={() => setShowEdit(true)}
                />
              ),
            },
            {
              key: "banner",
              header: "Set banner",
              content: _isEmpty(banner) ? (
                <StoreBannerList
                  list={banners}
                  total={banners.length}
                  loading={isLoadingBanner}
                  handleOpenBannerDetail={(data) => setBanner(data)}
                  handleRemoveBanner={handleRemoveBanner}
                  handleToggleStatus={handleToggleBannerStatus}
                />
              ) : (
                <StoreBannerDetail
                  initialValues={banner}
                  isEditBanner={banner.imageUrl !== null}
                  handleCancel={() => setBanner({})}
                  handleSubmit={handleSubmitBanner}
                />
              ),
            },
          ]}
        />
        <div className="content-container">
          <SearchBar isPermissionToEdit={isPermissionToEdit} />
          <Table
            rowSelection={rowSelection}
            scroll={{ x: 1000 }}
            loading={isLoading}
            bordered
            columns={columns}
            dataSource={cosmeticProductList}
            rowKey={(data) => data._id}
            total={cosmeticProductTotal}
          />
        </div>
      </div>
    </div>
  );
};

export default CosmeticDetail;
