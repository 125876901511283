/* eslint-disable react/display-name */

import i18n from "i18n";
import { Tooltip, Row, Col } from "antd";
import ProductTag from "components/Product/Tag";
import _get from "lodash/get";
import CategoryTooltip from "components/Product/CategoryTooltip";
import moment from "moment";
import FoneHouseProductTooltip from "components/FoneHouseProductTooltip";

const Columns = {};

const renderDataValue = (value, renderStyle = {}) => {
  console.log(value, "value");
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.productId = {
  dataIndex: "productId",
  key: "productId",
  title: "#",
  render: (productId, data) =>
    renderDataValue(
      <Row>
        <Tooltip title={<FoneHouseProductTooltip data={data} />} color="white">
          <Col style={{ marginRight: 4 }}>{productId}</Col>
        </Tooltip>
      </Row>
    ),
};
Columns.author = {
  dataIndex: "userId",
  key: "userId",
  title: "Người xóa",
  render: (userId) => renderDataValue(userId.name),
};

Columns.imei = {
  dataIndex: "imei",
  key: "imei",
  title: "IMEI",
  render: (imei) => renderDataValue(`${imei}`),
};

Columns.name = {
  dataIndex: "name",
  key: "name",
  title: i18n.t("products.name"),
  width: 150,
  render: (name, data) =>
    renderDataValue(
      <Row>
        <Tooltip title={<FoneHouseProductTooltip data={data} />} color="white">
          <Col style={{ marginRight: 4 }}>{name}</Col>
        </Tooltip>
      </Row>
    ),
};

Columns.address = {
  dataIndex: "address",
  key: "address",
  title: i18n.t("products.address"),
  width: 350,
  render: (address, data) =>
    renderDataValue(
      <Row>
        <Tooltip title={<FoneHouseProductTooltip data={data} />} color="white">
          <Col style={{ marginRight: 4 }}>{address}</Col>
        </Tooltip>
      </Row>
    ),
};

Columns.state = {
  title: i18n.t("products.state"),
  dataIndex: "state",
  key: "state",
  sorter: true,
  render: (state, data) =>
    renderDataValue(
      <Row>
        <Tooltip title={<FoneHouseProductTooltip data={data} />} color="white">
          <Col style={{ marginRight: 4 }}>{state}</Col>
        </Tooltip>
      </Row>
    ),
  width: 120,
};

Columns.ram = {
  title: i18n.t("products.ram"),
  dataIndex: "ram",
  key: "ram",
  sorter: true,
  render: (ram, data) =>
    renderDataValue(
      <Row>
        <Tooltip title={<FoneHouseProductTooltip data={data} />} color="white">
          <Col style={{ marginRight: 4 }}>{ram}</Col>
        </Tooltip>
      </Row>
    ),
  width: 100,
};

Columns.updateAt = {
  title: "Ngày xóa",
  dataIndex: "deletedAt",
  key: "deletedAt",
  sorter: true,
  render: (deletedAt) =>
    renderDataValue(moment(deletedAt).format("DD/MM/YYYY - HH:MM")),
  width: 200,
};

Columns.entryDate = {
  title: i18n.t("products.entryDate"),
  dataIndex: "entryDate",
  key: "entryDate",
  sorter: true,
  render: (entryDate) =>
    renderDataValue(moment(entryDate).format("DD/MM/YYYY")),
  width: 200,
};

Columns.saleDate = {
  title: i18n.t("products.saleDate"),
  dataIndex: "saleDate",
  key: "saleDate",
  sorter: true,
  render: (saleDate) => renderDataValue(moment(saleDate).format("DD/MM/YYYY")),
  width: 200,
};

Columns.guarantee = {
  title: i18n.t("products.guarantee"),
  dataIndex: "guarantee",
  key: "guarantee",
  sorter: true,
  render: (guarantee, data) =>
    renderDataValue(
      <Row>
        <Tooltip title={<FoneHouseProductTooltip data={data} />} color="white">
          <Col style={{ marginRight: 4 }}>{guarantee} tháng</Col>
        </Tooltip>
      </Row>
    ),
  width: 120,
};

Columns.amount = {
  title: i18n.t("products.amount"),
  dataIndex: "amount",
  key: "amount",
  sorter: true,
  render: (amount, data) =>
    renderDataValue(
      <Row>
        <Tooltip title={<FoneHouseProductTooltip data={data} />} color="white">
          <Col style={{ marginRight: 4 }}>{amount}</Col>
        </Tooltip>
      </Row>
    ),
  width: 120,
};

Columns.type = {
  title: i18n.t("products.type"),
  dataIndex: "type",
  key: "type",
  render: (type) =>
    renderDataValue(
      <Row>
        <Tooltip
          title={<CategoryTooltip active={_get(type, "_id")} />}
          color="white"
        >
          <Col style={{ marginRight: 4 }}>{_get(type, "name")}</Col>
        </Tooltip>
      </Row>
    ),
  width: 140,
};

Columns.inputPrice = {
  title: i18n.t("productDetail.inputPrice"),
  dataIndex: "inputPrice",
  key: "inputPrice",
  sorter: true,
  width: 150,
  render: (inputPrice, record) =>
    renderDataValue(
      `${(inputPrice > 0
        ? inputPrice
        : record.inputPriceJPY
      )?.toLocaleString()} ${record.currency}`,
      { fontWeight: "bold" }
    ),
};

Columns.price = {
  title: i18n.t("productDetail.price"),
  dataIndex: "price",
  key: "price",
  sorter: true,
  width: 150,
  render: (price, record) =>
    renderDataValue(
      `${(price > 0 ? price : record.priceJPY)?.toLocaleString()} ${
        record.currency
      }`,
      { fontWeight: "bold" }
    ),
};

Columns.promotionPrice = {
  title: i18n.t("productDetail.promotionPrice"),
  dataIndex: "promotionPrice",
  key: "promotionPrice",
  sorter: true,
  width: 150,
  render: (promotionPrice, record) =>
    renderDataValue(
      `${(promotionPrice > 0
        ? promotionPrice
        : record.promotionPriceJPY
      )?.toLocaleString()} ${record.currency}`,
      { fontWeight: "bold" }
    ),
};
Columns.extraPrice = {
  title: i18n.t("productDetail.extraPrice"),
  dataIndex: "promotionPriceJPY",
  key: "promotionPriceJPY",
  sorter: true,
  width: 150,
  render: (promotionPriceJPY, record) =>
    renderDataValue(`${(promotionPriceJPY - record.price).toLocaleString()}`, {
      fontWeight: "bold",
      color: `${promotionPriceJPY - record.price > 0 ? "green" : "red"}`,
    }),
};
Columns.tag = {
  title: i18n.t("products.tag"),
  dataIndex: "tag",
  key: "tag",
  render: (tag) => renderDataValue(<ProductTag status={tag} />),
};

export default Columns;
