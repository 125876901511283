import PropTypes from "prop-types";

import "./styles.less";

const statusText = {
  [false]: "Còn hạn",
  [true]: "Hết hạn",
};

const TabHeaderCheckingInsurance = ({ status }) => (
  <div className="tab-header-wrapper">
    <div>{statusText[status]}</div>
  </div>
);

TabHeaderCheckingInsurance.propTypes = {
  status: PropTypes.string.isRequired,
  total: PropTypes.number,
};
export default TabHeaderCheckingInsurance;
