import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";

const initialState = {
  isLoading: false,
  list: [],
  total: 0,
  detail: {},
  selectedRowKeys: [],
  selectedRows: [],
  isLocking: false,
  isUpdating: false,
  isLoadingBanner: false,
  isCreatingBanner: false,
  banners: [],
  locationTags: [],
  photos: [],
  discountConditions: [],
  discountPercentages: [],
};

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    // CREATE
    createStoreRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createStoreSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    // UPDATE
    updateStoreRequest: {
      reducer: (state) => ({
        ...state,
        isUpdating: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateStoreSuccess: {
      reducer: (state) => ({
        ...state,
        isUpdating: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    // GET_LIST
    getStoreListRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    getStoreListSuccess: {
      reducer: (state, action) => {
        const { docs, totalDocs } = action.payload;
        return {
          ...state,
          isLoading: false,
          list: docs,
          total: totalDocs,
        };
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    // GET_DETAIL
    getStoreDetailRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    getStoreDetailSuccess: {
      reducer: (state, action) => ({
        ...state,
        isLoading: false,
        detail: action.payload,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    // LOCK
    lockStoreRequest: {
      reducer: (state) => {
        state.isLocking = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    lockStoreSuccess: {
      reducer: (state) => ({
        ...state,
        isLocking: false,
        selectedRowKeys: [],
        selectedRows: [],
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    // UNLOCK
    unlockStoreRequest: {
      reducer: (state) => ({
        ...state,
        isLocking: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    unlockStoreSuccess: {
      reducer: (state) => ({
        ...state,
        isLocking: false,
        selectedRowKeys: [],
        selectedRows: [],
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    // PIN
    pinPromotionalRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    pinPromotionalSuccess: {
      reducer: (state) => ({ ...state, isLoading: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    // CHANGE_OWNER
    changeStoreOwnerRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    changeStoreOwnerSuccess: {
      reducer: (state) => ({ ...state, isLoading: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },

    setSelectedIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },

    // STORE_BANNER
    createStoreBannerRequest: {
      reducer: (state) => ({
        ...state,
        isCreatingBanner: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    createStoreBannerSuccess: {
      reducer: (state) => ({
        ...state,
        isCreatingBanner: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    editStoreBannerRequest: {
      reducer: (state) => ({
        ...state,
        isCreatingBanner: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    editStoreBannerSuccess: {
      reducer: (state) => ({
        ...state,
        isCreatingBanner: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    deleteStoreBannerRequest: {
      reducer: (state) => ({
        ...state,
        isCreatingBanner: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    deleteStoreBannerSuccess: {
      reducer: (state) => ({
        ...state,
        isCreatingBanner: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    getStoreBannerListRequest: {
      reducer: (state) => ({
        ...state,
        isLoadingBanner: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    getStoreBannerListSuccess: {
      reducer: (state, action) => ({
        ...state,
        isLoadingBanner: false,
        banners: action.payload,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    // STORE_LOCATION
    getStoreLocationTagsRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    getStoreLocationTagsSuccess: {
      reducer: (state, action) => ({
        ...state,
        isLoading: false,
        locationTags: action.payload,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    createStoreLocationTagsRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    createStoreLocationTagsSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    editStoreLocationTagsRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    editStoreLocationTagsSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    deleteStoreLocationTagsRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    deleteStoreLocationTagsSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    // STORE_PHOTO
    getStorePhotoListRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    getStorePhotoListSuccess: {
      reducer: (state, action) => ({
        ...state,
        isLoading: false,
        photos: action.payload,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    createStorePhotoRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    createStorePhotoSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    approveStorePhotoRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    approveStorePhotoSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    getDiscountConditionsRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    getDiscountConditionsSuccess: {
      reducer: (state, payload) => ({
        ...state,
        isLoading: false,
        discountConditions: payload.payload.data,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    getDiscountPercentagesRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    getDiscountPercentagesSuccess: {
      reducer: (state, payload) => ({
        ...state,
        isLoading: false,
        discountPercentages: payload.payload.map((percentage) => {
          const obj = {
            label: percentage.displayText,
            value: percentage.value,
          };
          return obj;
        }),
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },
    denyStorePhotoRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({
        payload,
        meta: { thunk: true },
      }),
    },
    denyStorePhotoSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({
        payload,
        meta,
      }),
    },

    stopLoading(state) {
      return {
        ...state,
        isLoading: false,
        isLocking: false,
        isUpdating: false,
        isLoadingBanner: false,
        isCreatingBanner: false,
      };
    },

    resetStoreState() {
      return initialState;
    },
  },
});

export const {
  createStoreRequest,
  createStoreSuccess,
  updateStoreRequest,
  updateStoreSuccess,
  getStoreListRequest,
  getDiscountConditionsRequest,
  getDiscountConditionsSuccess,
  getDiscountPercentagesRequest,
  getDiscountPercentagesSuccess,
  getStoreListSuccess,
  getStoreDetailRequest,
  getStoreDetailSuccess,
  lockStoreRequest,
  lockStoreSuccess,
  unlockStoreRequest,
  unlockStoreSuccess,
  pinPromotionalRequest,
  pinPromotionalSuccess,
  setSelectedIds,
  resetStoreState,
  stopLoading,
  changeStoreOwnerRequest,
  changeStoreOwnerSuccess,
  // STORE_BANNER
  createStoreBannerRequest,
  createStoreBannerSuccess,
  editStoreBannerRequest,
  editStoreBannerSuccess,
  deleteStoreBannerRequest,
  deleteStoreBannerSuccess,
  getStoreBannerListRequest,
  getStoreBannerListSuccess,
  // STORE_LOCATION
  getStoreLocationTagsRequest,
  getStoreLocationTagsSuccess,
  createStoreLocationTagsRequest,
  createStoreLocationTagsSuccess,
  editStoreLocationTagsRequest,
  editStoreLocationTagsSuccess,
  deleteStoreLocationTagsRequest,
  deleteStoreLocationTagsSuccess,
  // STORE_PHOTO
  getStorePhotoListRequest,
  getStorePhotoListSuccess,
  createStorePhotoRequest,
  createStorePhotoSuccess,
  approveStorePhotoRequest,
  approveStorePhotoSuccess,
  denyStorePhotoRequest,
  denyStorePhotoSuccess,
} = storeSlice.actions;

export default storeSlice.reducer;
