/* eslint-disable react/display-name */

import i18n from "i18n";
import moment from "moment";
import SummaryTooltip from "components/SummaryTooltip";
import NewsSummaryTooltip from "components/SummaryTooltip/NewsSummaryTooltip";
import { Tag } from "antd";

const Columns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.id = {
  dataIndex: "newsId",
  key: "newsId",
  title: "#",
  render: (newsId, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<NewsSummaryTooltip newsDetail={data} role="fonehouse" />}
      >
        <div>{`#${newsId}`}</div>
      </SummaryTooltip>
    ),
  width: 120,
};
Columns.type = {
  dataIndex: "type",
  key: "type",
  title: "Loại tin tức",
  render: (value) => {
    switch (value) {
      case "ACQUISITION":
        return (
          <Tag color="blue" style={{}}>
            Thu mua
          </Tag>
        );
      case "NEWS":
        return (
          <Tag color="green" style={{}}>
            Tin tức
          </Tag>
        );

      default:
        return null;
    }
  },

  width: 195,
};
Columns.createdAt = {
  dataIndex: "createdAt",
  key: "createdAt",
  title: i18n.t("newsFonehouse.createAt"),
  sorter: true,
  render: (createdAt, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<NewsSummaryTooltip newsDetail={data} role="fonehouse" />}
      >
        <div>{moment(createdAt).format("DD/MM/YYYY")}</div>
      </SummaryTooltip>
    ),
  width: 130,
};

Columns.title = {
  title: i18n.t("newsFonehouse.title"),
  dataIndex: "title",
  key: "title",
  sorter: true,
  render: (title, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<NewsSummaryTooltip newsDetail={data} role="fonehouse" />}
      >
        <div>{title}</div>
      </SummaryTooltip>
    ),
};

Columns.username = {
  title: i18n.t("newsFonehouse.account"),
  dataIndex: "idUser",
  key: "idUser",
  render: (idUser = {}) => renderDataValue(idUser.name),
  width: 200,
};

export default Columns;
