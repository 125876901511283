import { Tag } from "antd";
import PropTypes from "prop-types";
import { TRANSACTION_TAG, TRANSACTION_TAG_TEXT } from "utils/constants";
import "./styles.less";

const tagColor = {
  [TRANSACTION_TAG.DAIBIKI]: "success",
  [TRANSACTION_TAG.CASH]: "blue",
  [TRANSACTION_TAG.TRANSFER]: "magenta",
};

const TagTransactionType = ({ status = "Daibiki" }) => (
  <>
    <Tag className="bill-transaction-tag" color={tagColor[status]}>
      {TRANSACTION_TAG_TEXT[status]}
    </Tag>
  </>
);

TagTransactionType.propTypes = {
  status: PropTypes.string.isRequired,
};
export default TagTransactionType;
