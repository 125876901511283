import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";
import { resetStoreState } from "providers/StoreProvider/slice";

const cosmeticProductSlice = createSlice({
  name: "cosmeticProduct",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    cosmeticId: null,
    detail: {},
    cosmeticProductModalData: {},
    productCosmeticTypeList: [],
    setTagProductArticleModalData: {},
    isSettingTag: false,
  },
  reducers: {
    getCosmeticProductListRequest(state) {
      return { ...state, isLoading: true };
    },
    getCosmeticProductListSuccess(state, action) {
      const { payload = {} } = action;
      const { cosmeticProducts, total } = payload;
      return {
        ...state,
        cosmeticProductId: _get(cosmeticProducts, "[0].cosmeticProductId"),
        isLoading: false,
        docs: cosmeticProducts.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs: total,
      };
    },

    setSelectedIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isSettingTag: false };
    },
    updateStatusCosmeticRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },

    updateStatusCosmeticSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
        selectedRowKeys: [],
        selectedRows: [],
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateCosmeticProductRequest: {
      reducer: (state) => {
        state.isCreating = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateCosmeticProductSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    getCosmeticDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getCosmeticDetailSuccess(state, action) {
      return { ...state, isLoading: false, detail: action.payload };
    },

    setCosmeticProductModal(state, action) {
      const { payload = {} } = action;
      const { visible, data, type } = payload;
      return {
        ...state,
        cosmeticProductModalData: {
          visible,
          data,
          type,
        },
      };
    },
    getProductCosmeticTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    getProductCosmeticTypeSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        productCosmeticTypeList: action.payload.data,
      };
    },
    addProductCosmeticTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    addProductCosmeticTypeSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        productCosmeticTypeList: state.productCosmeticTypeList.concat(
          action.payload
        ),
      };
    },
    createProductCosmeticRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createProductCosmeticSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateProductCosmeticRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateProductCosmeticSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteProductCosmeticRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    deleteProductCosmeticSuccess: {
      reducer: (state) => ({
        ...state,
        selectedRowKeys: [],
        selectedRows: [],
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteProductCosmeticTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    deleteProductCosmeticTypeSuccess(state) {
      return { ...state, isLoading: false };
    },
    editProductCosmeticTypeRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    editProductCosmeticTypeSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    setTagProductArticleModalData(state, action) {
      const { payload = {} } = action;
      const { visible, data } = payload;
      return {
        ...state,
        setTagProductArticleModalData: {
          visible,
          data: data || state.setTagProductArticleModalData.data,
        },
      };
    },
    setTagProductCosmeticRequest: {
      reducer: (state) => {
        state.isSettingTag = true;
        state.selectedRowKeys = [];
        state.selectedRows = [];
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    setTagProductCosmeticSuccess: {
      reducer: (state) => ({ ...state, isSettingTag: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      resetStoreState.type,
      (state) => ({
        ...state,
        docs: [],
      })
      // action is inferred correctly here if using TS
    );
  },
});

export const {
  setSelectedIds,
  confirmBusinessRequest,
  confirmBusinessSuccess,
  getCosmeticProductListSuccess,
  getCosmeticProductListRequest,
  stopLoading,
  updateStatusCosmeticRequest,
  updateStatusCosmeticSuccess,
  getCosmeticDetailRequest,
  getCosmeticDetailSuccess,
  updateCosmeticProductSuccess,
  updateCosmeticProductRequest,
  setCosmeticProductModal,
  getProductCosmeticTypeRequest,
  getProductCosmeticTypeSuccess,
  addProductCosmeticTypeRequest,
  addProductCosmeticTypeSuccess,
  createProductCosmeticRequest,
  createProductCosmeticSuccess,
  updateProductCosmeticRequest,
  updateProductCosmeticSuccess,
  deleteProductCosmeticRequest,
  deleteProductCosmeticSuccess,
  deleteProductCosmeticTypeRequest,
  deleteProductCosmeticTypeSuccess,
  editProductCosmeticTypeRequest,
  editProductCosmeticTypeSuccess,
  setTagProductArticleModalData,
  setTagProductCosmeticRequest,
  setTagProductCosmeticSuccess,
} = cosmeticProductSlice.actions;

export default cosmeticProductSlice.reducer;
