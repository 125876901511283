import { Tag } from "antd";
import PropTypes from "prop-types";
import { BILL_TYPE_TAG, BILL_TYPE_TAG_TEXT } from "utils/constants";
import "./styles.less";

const tagColor = {
  [BILL_TYPE_TAG.FACEBOOK]: "blue",
};

const TagTypeBillDetail = ({ status = "facebook" }) => (
  <>
    <Tag
      className="bill-type-tag"
      color={status === "facebook" ? tagColor[status] : "magenta"}
    >
      {status === "facebook" ? BILL_TYPE_TAG_TEXT[status] : status}
    </Tag>
  </>
);

TagTypeBillDetail.propTypes = {
  status: PropTypes.string.isRequired,
};
export default TagTypeBillDetail;
