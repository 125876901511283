import { Col, Modal, Row, Image, Rate } from "antd";
import i18n from "i18n";
import BackIcon from "assets/images/back.svg";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { PlaceHolderIMG } from "assets/images";
import moment from "moment";

const CommentAndRatingDetail = (props) => {
  const dispatch = useDispatch();
  const { setCommentRatingModal } = props;
  const rater = useSelector((state) => _get(state, "ratingComment.rater"));
  const CommentRatingData = useSelector((state) =>
    _get(state, "ratingComment.commentRatingData")
  );
  const { visible, data } = CommentRatingData;
  const handleCloseModal = () => {
    dispatch(
      setCommentRatingModal({
        visible: false,
        data: {},
      })
    );
  };
  return (
    <div>
      {visible && (
        <Modal
          title={i18n.t("ratingComment.title")}
          visible={visible}
          width={600}
          className="market-product-modal-wrapper"
          footer={null}
          onCancel={handleCloseModal}
          maskClosable={false}
          closeIcon={<img src={BackIcon} alt="" />}
        >
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Col style={{ display: "flex", flexDirection: "column" }}>
                <Image
                  style={{
                    width: 200,
                    height: 200,

                    resizeMode: "contain",
                  }}
                  fallback={PlaceHolderIMG}
                  src={rater?.userDetail?.avatar}
                />
                <a
                  style={{ alignSelf: "center" }}
                  target="_blank"
                  href={`${process.env.REACT_APP_DOMAIN_NAME}/home/${rater?._id}`}
                  rel="noopener noreferrer"
                >
                  Xem thông tin
                </a>
              </Col>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ color: "gray" }}>
                Đánh giá ngày: {moment(data.updatedAt).format("DD/MM/YYYY")}
              </div>
              <Rate disabled defaultValue={data.rating} />
              <div>
                <b>Tài khoản:</b>
                {rater?.name}
              </div>

              <div style={{ maxWidth: 400 }}>
                <b>Nội dung:</b> <br />
                {data?.commentText}
              </div>
              {data.commentImages.map((item) => (
                <Col style={{ marginTop: 10 }} key={item}>
                  <Image fallback={PlaceHolderIMG} src={item} />
                </Col>
              ))}
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
};
export default CommentAndRatingDetail;
