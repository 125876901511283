/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import { Formik, Form } from "formik";
import i18n from "i18n";
import PropTypes from "prop-types";
import { Row, Col, Button } from "antd";
import { TextInput, Select } from "components/common";
import Helper from "utils/helpers";
import {
  MONEY_MANAGEMENT_TYPE,
  MONEY_MANAGEMENT_TRANSFER_TYPE,
} from "utils/constants";
import { moneyManagementValidate } from "./validate";
import "./styles.less";

const MoneyManagementForm = ({
  initialValues,
  onSubmit,
  loading,
  isCreate,
}) => {
  const formikRef = useRef(null);
  const [disableTransfer, setDisableTransfer] = useState(false);
  const handleChangeType = (type, setFieldValue) => {
    if (type === "spend" || type === "collect") {
      setDisableTransfer(true);
      setFieldValue("payment", "cash");
    } else {
      setDisableTransfer(false);
    }
  };
  return (
    <div className="bill-create-form-wrapper">
      <Formik
        validateOnMount
        initialValues={initialValues}
        enableReinitialize
        validationSchema={moneyManagementValidate}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form>
            <TextInput
              disabled
              label={i18n.t("moneyManagementFonehouse.moneyLeft")}
              name="defaultMoney"
            />
            <Row className="form-grid">
              <Col span={12}>
                <Select
                  label={i18n.t("moneyManagementFonehouse.type")}
                  name="type"
                  inputType="state"
                  required
                  onChange={(value) => handleChangeType(value, setFieldValue)}
                  options={Helper.convertObjectToOptions(MONEY_MANAGEMENT_TYPE)}
                />
              </Col>
              <Col span={12}>
                <Select
                  disabled={disableTransfer}
                  label={i18n.t("moneyManagementFonehouse.transferType")}
                  name="payment"
                  inputType="state"
                  required
                  options={Helper.convertObjectToOptions(
                    MONEY_MANAGEMENT_TRANSFER_TYPE
                  )}
                />
              </Col>
            </Row>
            <Col span={24}>
              <TextInput
                label={i18n.t("moneyManagementFonehouse.value")}
                name="value"
                required
              />
            </Col>
            <Col span={24}>
              <TextInput
                inputType="textarea"
                autoSize={{ minRows: 5, maxRows: 5 }}
                label={i18n.t("moneyManagementFonehouse.content")}
                required
                name="description"
              />
            </Col>
            <Row justify="space-between" className="button-wrapper">
              <Button
                style={{ marginTop: 20 }}
                loading={loading}
                onClick={handleSubmit}
                type="primary"
                size="large"
              >
                {i18n.t(
                  isCreate
                    ? "moneyManagementFonehouse.createRecord"
                    : "moneyManagementFonehouse.updateRecord"
                )}
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

MoneyManagementForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  isCreate: PropTypes.bool,
};
export default MoneyManagementForm;
