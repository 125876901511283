import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import { TextInput } from "components/common";
import { Button } from "antd";
import i18n from "i18n";
import { editMarketProductTypeForm } from "./validate";
// import './style.less';

const EditMarketProductTypeForm = ({ initialValues, onSubmit }) => (
  <Formik
    validateOnMount
    initialValues={initialValues}
    validationSchema={editMarketProductTypeForm}
    onSubmit={onSubmit}
  >
    {({ handleSubmit }) => (
      <Form>
        <TextInput
          label={i18n.t("updateNameTypeModal.productType")}
          name="name"
        />
        <Button
          className="submit-btn"
          htmlType="submit"
          loading={false}
          type="primary"
          onClick={handleSubmit}
        >
          {i18n.t("actionButton.update")}
        </Button>
      </Form>
    )}
  </Formik>
);
EditMarketProductTypeForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};
export default EditMarketProductTypeForm;
