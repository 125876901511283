import BannerCreateFrom from "components/Banner/CreateForm";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { createBannerRequest } from "providers/BannerProvider/slice";
import "./styles.less";

const BannerCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = (values) => {
    dispatch(createBannerRequest(values)).then(() =>
      navigate("/banners", { state: { canGoBack: true }, replace: true })
    );
  };
  const initialValues = {
    url: "",
    type: [],
    image: null,
    name: "banner trang chủ",
  };

  return (
    <div className="banner-create-wrapper">
      <BannerCreateFrom initialValues={initialValues} onSubmit={handleSubmit} />
    </div>
  );
};

export default BannerCreate;
