import { Formik, Form } from "formik";
import { Button } from "antd";

import { TextInput, ImageUploadContainer } from "components/common";
import BannerImage from "components/common/Form/ImageUploadContainer/BannerImage";
import { createBannerValidate } from "./validate";

const ImageGalleryForm = ({ onSubmit, onCancel }) => (
  <Formik
    initialValues={{ imageUrl: "", comment: "" }}
    onSubmit={onSubmit}
    enableReinitialize
    validationSchema={createBannerValidate}
  >
    {() => (
      <Form className="image-gallery-form">
        <div className="gallery-header">
          <Button size="small" onClick={onCancel}>
            Huỷ
          </Button>
          <Button size="small" type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
        <ImageUploadContainer name="imageUrl">
          <BannerImage aspect={16 / 9} />
        </ImageUploadContainer>
        <TextInput
          inputType="text"
          autoSize={{ minRows: 11, maxRows: 11 }}
          label="Nội dung"
          name="comment"
        />
      </Form>
    )}
  </Formik>
);

export default ImageGalleryForm;
