import { createSlice } from "@reduxjs/toolkit";

const moneyManagementFonehouseSlice = createSlice({
  name: "moneyManagementFonehouse",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    isCreating: false,
    moneyManagementModalData: {},
    moneyManagementRecord: {},
    balance: 0,
    oldBalance: 0,
    dateFrom: "",
    dateTo: "",
  },
  reducers: {
    deleteAllInfoMoneyRequest(state) {
      return { ...state, isLoading: true };
    },
    deleteAllInfoMoneySuccess(state) {
      return { ...state, isLoading: false };
    },
    getAllMoneyManagementRequest(state) {
      return { ...state, isLoading: true };
    },
    getAllMoneyManagementSuccess(state, action) {
      const { payload = {} } = action;
      const { reports, total } = payload;
      return {
        ...state,
        isLoading: false,
        docs: reports.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs: total,
      };
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isCreating: false };
    },
    setMoneyManagementModal(state, action) {
      const { payload = {} } = action;
      const { visible, data, isCreate, id, dateFrom, dateTo } = payload;
      return {
        ...state,
        moneyManagementRecord: {},
        dateFrom,
        dateTo,
        moneyManagementModalData: {
          visible,
          data,
          isCreate,
          id,
        },
      };
    },
    getBalanceRequest(state) {
      return { ...state, isLoading: true };
    },
    getBalanceSuccess(state, action) {
      const { payload = {} } = action;
      const { balance } = payload;
      return {
        ...state,
        isLoading: false,
        balance: balance.toLocaleString(),
      };
    },
    getMoneyManagementRecordRequest(state) {
      return { ...state, isLoading: true };
    },
    getMoneyManagementRecordSuccess(state, action) {
      const { payload = {} } = action;
      const { data } = payload;
      return {
        ...state,
        isLoading: false,
        moneyManagementRecord: data,
      };
    },
    getOldBalanceRequest(state) {
      return { ...state, isLoading: true };
    },
    getOldBalanceSuccess(state, action) {
      const { payload = {} } = action;
      const { balance } = payload;
      return {
        ...state,
        oldBalance: balance.toLocaleString(),
      };
    },
    createMoneyManagementRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createMoneyManagementSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({
        payload,
        meta,
        moneyManagementRecord: {},
      }),
    },
    updateMoneyManagementRequest: {
      reducer: (state) => {
        state.isCreating = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateMoneyManagementSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({
        payload,
        meta,
        moneyManagementRecord: {},
      }),
    },
    deleteMoneyManagementRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    deleteMoneyManagementSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
        selectedRowKeys: [],
        selectedRows: [],
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteMoneyManagementDataRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    deleteMoneyManagementDataSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateBalanceRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateBalanceSuccess: {
      reducer: (state, action) => ({
        ...state,
        isLoading: false,
        balance: action.payload,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
});

export const {
  getOldBalanceRequest,
  getOldBalanceSuccess,
  getBalanceRequest,
  getBalanceSuccess,
  updateBalanceRequest,
  updateBalanceSuccess,
  setMoneyManagementModal,
  getAllMoneyManagementRequest,
  getAllMoneyManagementSuccess,
  setSelectedMoneyManagementIds,
  createMoneyManagementRequest,
  createMoneyManagementSuccess,
  updateMoneyManagementRequest,
  updateMoneyManagementSuccess,
  deleteMoneyManagementRequest,
  deleteMoneyManagementSuccess,
  deleteMoneyManagementDataRequest,
  deleteMoneyManagementDataSuccess,
  getMoneyManagementRecordRequest,
  getMoneyManagementRecordSuccess,
  stopLoading,
  deleteAllInfoMoneyRequest,
  deleteAllInfoMoneySuccess,
} = moneyManagementFonehouseSlice.actions;

export default moneyManagementFonehouseSlice.reducer;
