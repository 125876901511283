import PropTypes from "prop-types";
import { Modal } from "antd";
import _get from "lodash/get";
import BackIcon from "assets/images/back.svg";
import { useSelector, useDispatch } from "react-redux";
import { ARTICLE_PRODUCT_MODAL_TYPE } from "utils/constants";
import { useParams } from "react-router-dom";
import {
  createProductCosmeticRequest,
  getCosmeticProductListRequest,
  updateProductCosmeticRequest,
  deleteProductCosmeticRequest,
} from "providers/CosmeticProductProvider/slice";
import i18n from "i18n";
import CosmeticProductDetail from "./CosmeticProductDetail";
import CosmeticProductForm from "./CosmeticProductForm";
import "./styles.less";

const CosmeticProductModal = (props) => {
  const {
    setCosmeticProductModal,
    searchObject,
    isPermissionToEdit,
    setSearch,
  } = props;
  const dispatch = useDispatch();
  const cosmeticProductModalData = useSelector(
    (state) => state.cosmeticProduct.cosmeticProductModalData
  );
  const productCosmeticTypeList = useSelector((state) =>
    _get(state, "cosmeticProduct.productCosmeticTypeList")
  );

  const { visible, data = {}, type } = cosmeticProductModalData;
  const { cosmeticId } = useParams();
  const handleCloseModal = () => {
    dispatch(
      setCosmeticProductModal({
        visible: false,
        data: {},
        type: "",
      })
    );
  };

  const handleEditCosmeticProduct = (_data) => {
    dispatch(
      setCosmeticProductModal({
        visible: true,
        data: _data,
        type: ARTICLE_PRODUCT_MODAL_TYPE.EDIT,
      })
    );
  };

  const handleDeleteCosmeticProduct = () => {
    dispatch(
      deleteProductCosmeticRequest({
        ids: [data._id],
        cosmeticProductIds: [data.cosmeticProductId],
      })
    ).then(() => {
      dispatch(
        setCosmeticProductModal({
          visible: false,
          data: {},
          type: "",
        })
      );
      dispatch(getCosmeticProductListRequest(searchObject));
    });
  };

  const handleSubmit = (values) => {
    const productId = data._id;
    const { cosmeticProductId } = data;
    if (type === ARTICLE_PRODUCT_MODAL_TYPE.CREATE) {
      dispatch(
        createProductCosmeticRequest({
          ...values,
          cosmeticStoreId: cosmeticId,
        })
      ).then((_data) => {
        dispatch(
          setCosmeticProductModal({
            visible: true,
            data: {
              ..._data,
              type: productCosmeticTypeList.find(
                (item) => item._id === _data.productType
              ),
            },
            type: ARTICLE_PRODUCT_MODAL_TYPE.DETAIL,
          })
        );
        dispatch(getCosmeticProductListRequest(searchObject));
      });
    } else {
      dispatch(
        updateProductCosmeticRequest({
          ...values,
          cosmeticStoreId: cosmeticId,
          productId,
          cosmeticProductId,
        })
      ).then((res) => {
        dispatch(
          setCosmeticProductModal({
            visible: true,
            data: {
              ...res,
              type: productCosmeticTypeList.find(
                (item) => item._id === res.productType
              ),
              cosmeticProductId,
            },
            type: ARTICLE_PRODUCT_MODAL_TYPE.DETAIL,
          })
        );
        dispatch(getCosmeticProductListRequest(searchObject));
      });
    }
  };

  return (
    <div>
      {visible && (
        <Modal
          title={i18n.t("cosmeticProduct.productDetail")}
          visible={visible}
          width={860}
          className="cosmetic-product-modal-wrapper"
          footer={null}
          onCancel={handleCloseModal}
          maskClosable={false}
          closeIcon={<img src={BackIcon} alt="" />}
        >
          {type === ARTICLE_PRODUCT_MODAL_TYPE.DETAIL ? (
            <CosmeticProductDetail
              cosmeticProductDetail={data}
              setCosmeticProductModal={setCosmeticProductModal}
              handleDeleteCosmeticProduct={handleDeleteCosmeticProduct}
              handleEditCosmeticProduct={handleEditCosmeticProduct}
              isPermissionToEdit={isPermissionToEdit}
            />
          ) : (
            <CosmeticProductForm
              type={type}
              setCosmeticProductModal={setCosmeticProductModal}
              productCosmeticTypeList={productCosmeticTypeList}
              searchObject={searchObject}
              onSubmit={handleSubmit}
              initialValues={data}
              setSearch={setSearch}
            />
          )}
        </Modal>
      )}
    </div>
  );
};
CosmeticProductModal.propTypes = {
  setCosmeticProductModal: PropTypes.func,
  searchObject: PropTypes.object,
  isPermissionToEdit: PropTypes.bool,
  setSearch: PropTypes.func,
};

export default CosmeticProductModal;
