import { put, call, takeEvery } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import { safe } from "providers/GeneralProvider/saga";
import {
  getAllHistoryEditRequest,
  getAllHistoryEditSuccess,
  stopLoading,
} from "providers/FonehouseHistoryEdit/slice";
import moment from "moment";

function* getAllHistoryEdit(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "from",
    "to",
    "payment",
    "type",
    "keyword",
  ]);
  params.from = parseFloat(params.from ?? moment().valueOf());
  params.to = parseFloat(params.to ?? moment().valueOf());
  params.page = parseFloat(params.page);
  params.limit = parseFloat(params.limit);
  const data = yield call(
    request,
    "api/fonehouse/report/histories-filter",
    params,
    { method: "POST" }
  );
  yield put(getAllHistoryEditSuccess(data));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchHistoryEdit() {
  yield takeEvery(
    getAllHistoryEditRequest.type,
    safe(getAllHistoryEdit, onError)
  );
}
