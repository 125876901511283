import { Row, Typography, Tabs } from "antd";
import _get from "lodash/get";
import { InsuranceColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector } from "react-redux";
import { useQuery } from "hooks";
import { getInsuranceListRequest } from "providers/ProductProvider/slice";
import "./styles.less";
import i18n from "i18n";
import SetTagModal from "components/Product/SetTagModal";
import TabHeaderCheckingInsurance from "components/TabHeaderCheckingInsurance";
import SearchBar from "./SearchBar";

const { TabPane } = Tabs;

const CheckingInsurance = () => {
  const productList = useSelector((state) => _get(state, "product.docs"));
  const productTotal = useSelector((state) => _get(state, "product.totalDocs"));
  const isLoading = useSelector((state) => _get(state, "product.isLoading"));

  const [searchObject, setSearch] = useQuery(getInsuranceListRequest, {
    params: "warrantyExpires",
  });

  const columns = [
    InsuranceColumn.productId,
    InsuranceColumn.saleDate,
    InsuranceColumn.name,
    InsuranceColumn.imei,
    InsuranceColumn.insuranceDate,
    InsuranceColumn.warrantyExpirationDate,
    InsuranceColumn.status,
  ];
  return (
    <div className="product-list-wrapper">
      <SetTagModal />
      <Row className="product-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("products.insuranceTitle")}
        </Typography.Title>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Tabs
          className="restaurant-tabs-container"
          activeKey={searchObject.warrantyExpires === "1" ? "true" : "false"}
          type="card"
          onChange={(key) => {
            if (key === "true") {
              setSearch(
                {
                  keyword: searchObject.keyword,
                  warrantyExpires: 1,
                },
                true
              );
            } else {
              setSearch(
                {
                  keyword: searchObject.keyword,
                  warrantyExpires: 0,
                },
                true
              );
            }
          }}
        >
          {" "}
          {[false, true].map((value) => (
            <TabPane
              className="status-tab-pane"
              tab={<TabHeaderCheckingInsurance status={value} />}
              key={value}
            >
              <Table
                scroll={{ x: 1000 }}
                loading={isLoading}
                bordered
                columns={columns}
                dataSource={productList}
                rowKey={(data) => data._id}
                total={productTotal}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default CheckingInsurance;
