import { memo } from "react";
import { SearchOutlined } from "@ant-design/icons";
import SearchBarComponent from "components/SearchBar";
import { TextInput } from "components/common/Form";

const SearchBar = () => (
  <SearchBarComponent filterFields={["keyword"]} isOnChangeSearch>
    <>
      <TextInput
        size="middle"
        prefix={<SearchOutlined />}
        name="keyword"
        allowClear={false}
      />
    </>
  </SearchBarComponent>
);

export default memo(SearchBar);
