import { put, call, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import { safe } from "providers/GeneralProvider/saga";
import {
  getAllStatisticalFonehouseRequest,
  getAllStatisticalFonehouseSuccess,
  stopLoading,
  exportCSVRequest,
  exportCSVSuccess,
} from "providers/FonehouseStatistical/slice";
import moment from "moment";

function* getAllStatisticalFonehouse(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "from",
    "to",
    "payment",
    "transactionType",
    "keyword",
  ]);
  params.from = parseFloat(params.from ?? moment().valueOf());
  params.to = parseFloat(params.to ?? moment().valueOf());
  params.page = parseFloat(params.page);
  params.limit = parseFloat(params.limit);
  const { orderRecords, totalInterest, totalCost, totalRecord } = yield call(
    request,
    "api/fonehouse/report/order-report-filter",
    params,
    { method: "POST" }
  );
  yield put(
    getAllStatisticalFonehouseSuccess({
      orderRecords,
      totalInterest,
      totalCost,
      totalRecord,
    })
  );
}

function* exportCSV(action) {
  const params = _pick(action.payload, ["from", "to", "transactionType"]);
  const data = yield call(request, "api/fonehouse/report/export-csv", params, {
    method: "POST",
  });
  yield put(exportCSVSuccess(data));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchStatistical() {
  yield takeLeading(
    getAllStatisticalFonehouseRequest.type,
    safe(getAllStatisticalFonehouse, onError)
  );
  yield takeLeading(exportCSVRequest.type, safe(exportCSV, onError));
}
