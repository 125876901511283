import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Typography } from "antd";
import "./styles.less";
import BackButton from "components/BackButton";
import AccessoryEditForm from "components/Accessory/Edit";
import {
  createOrUpdateAccessoryRequest,
  getAccessoryListRequest,
} from "providers/AccessoryProvider/slice";
import _get from "lodash/get";

const AccessoryCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const accessory = location.state?.accessory;
  const isLoading = useSelector((state) =>
    _get(state, "accessorySlice.isLoading")
  );
  const isCreating = !accessory;

  const initialValues = {
    name: accessory ? accessory?.name : "",
    banners: null,
    thumbnail: accessory
      ? {
          url: accessory?.thumbnail,
          objectId: accessory?.thumbnail,
        }
      : null,
    price: accessory ? accessory?.price : null,
    initialPrice: accessory ? accessory?.initialPrice : null,
    images: accessory ? accessory?.images : [],
  };

  const handleSubmit = (values) => {
    dispatch(
      createOrUpdateAccessoryRequest({
        isCreating,
        name: values?.name,
        thumbnail: values?.thumbnail?.url,
        price: Number(values.price),
        initialPrice: Number(values.initialPrice),
        images: values.images,
        id: accessory?._id,
        callback: () => {
          dispatch(getAccessoryListRequest());
        },
      })
    );
    navigate(-1);
  };

  const _onCancel = () => {
    navigate(-1);
  };

  return (
    <div className="cosmetic-detail-wrapper">
      <Row
        className="cosmetic-detail-header"
        justify="space-between"
        align="middle"
      >
        <Row>
          <BackButton />
          <Typography.Title level={4}>Tạo PHỤ KIỆN</Typography.Title>
        </Row>
      </Row>
      <div className="detail-wrapper">
        <AccessoryEditForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          isCreating={isCreating}
          isLoading={isLoading}
          onCancel={_onCancel}
        />
      </div>
    </div>
  );
};

export default AccessoryCreate;
