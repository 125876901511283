import { Menu } from "antd";
import { useDispatch } from "react-redux";

import {
  approveStorePhotoRequest,
  denyStorePhotoRequest,
} from "providers/StoreProvider/slice";

const MenuOverlay = ({ record, refetchList, storeId }) => {
  const dispatch = useDispatch();
  const { _id, isActive } = record;

  return (
    <Menu>
      {!isActive && (
        <Menu.Item
          className="unlock-bussiness-btn"
          onClick={() => {
            dispatch(approveStorePhotoRequest({ storeId, _id })).then(() => {
              refetchList();
            });
          }}
        >
          Duyệt
        </Menu.Item>
      )}
      <Menu.Item
        className="lock-bussiness-btn"
        onClick={() => {
          dispatch(denyStorePhotoRequest({ storeId, _id })).then(() => {
            refetchList();
          });
        }}
      >
        Từ chối
      </Menu.Item>
    </Menu>
  );
};

export default MenuOverlay;
