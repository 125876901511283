import { Modal, Row, Col, Image, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import { useNavigate } from "react-router-dom";
import { LeftArrowIcon, PlaceHolderIMG } from "assets/images";
import DefaultImage from "assets/images/photo.png";
import { getSimRegistrationDetailSuccess } from "providers/SimProvider/slice";
import "./styles.less";
import { PromotionItem } from "../Form";

const PRICE_SIM_TEXT = "Giá SIM:";
const CONNECTION_FEE_TEXT = "Cước nhà mạng:";
const INITIAL_PRICE_SIM_TEXT = "Giá SIM (giá gốc):";
const INITIAL_CONNECTION_FEE_TEXT = "Cước nhà mạng (giá gốc):";
const DISCOUNTED_SIM_TEXT = "Khuyến mãi SIM:";
const DISCOUNTED_CONNECTION_TEXT = "Khuyến mãi cước nhà mạng:";

const RegistrationDetailModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { simRegistrationDetail } = useSelector((state) => state.sim);
  const priceDiscounts = _get(simRegistrationDetail, "sim.priceDiscount", []);
  const connectionFeeDiscounts = _get(
    simRegistrationDetail,
    "sim.connectionFeeDiscount",
    []
  );
  const handleCloseModal = () => {
    dispatch(getSimRegistrationDetailSuccess({}));
  };
  const renderSimPrice = () => {
    if (_get(simRegistrationDetail, "discountedPrice", 0) !== 0) {
      return _get(simRegistrationDetail, "discountedPrice", 0);
    }
    return _get(simRegistrationDetail, "sim.price", 0);
  };
  const renderConnectionFee = () => {
    if (_get(simRegistrationDetail, "discountedConnectionFee", 0) !== 0) {
      return _get(simRegistrationDetail, "discountedConnectionFee", 0);
    }
    return _get(simRegistrationDetail, "sim.connectionFee", 0);
  };

  return (
    <Modal
      visible={!_isEmpty(simRegistrationDetail)}
      footer={null}
      closable={false}
      centered
      width={1228}
      className="sim-registration-modal-wrapper"
    >
      <Row align="middle" className="modal-header">
        <Col>
          <img
            onClick={handleCloseModal}
            className="icon-close"
            src={LeftArrowIcon}
            alt=""
          />
        </Col>
        <Col>Xem yêu cầu đăng ký SIM</Col>
      </Row>
      <Row>
        <Button
          onClick={() => {
            navigate("/sim/editRegistered", {
              state: { canGoBack: true },
            });
          }}
          size="small"
          color="primary"
          style={{ marginBottom: 10, width: 350 }}
        >
          {"Chỉnh sửa đơn đăng ký"}
        </Button>
      </Row>
      <Row className="modal-content" wrap={false} justify="space-between">
        <Col className="sim-detail" span={14}>
          <div className="detail-content">
            <span className="label">Mã đơn hàng:</span>
            <span className="content">
              {_get(simRegistrationDetail, "customOrderId", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Tên người đăng ký:</span>
            <span className="content">
              {_get(simRegistrationDetail, "name", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Mã bưu điện:</span>
            <span className="content">
              {_get(simRegistrationDetail, "postalCode", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Email:</span>
            <span className="content">
              {_get(simRegistrationDetail, "email", "")}
            </span>
          </div>
          {simRegistrationDetail?.prefixPhoneNumber && (
            <div className="detail-content">
              <span className="label">Đầu số:</span>
              <span className="content">
                {_get(simRegistrationDetail, "prefixPhoneNumber", "")}
              </span>
            </div>
          )}
          <div className="detail-content">
            <span className="label">Tên SIM:</span>
            <span className="content">
              {_get(simRegistrationDetail, "sim.name", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Loại:</span>
            <span className="content">
              {_get(simRegistrationDetail, "sim.type.name", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Loại hình giao dịch:</span>
            <span className="content">
              {_get(simRegistrationDetail, "transactionType", "")}
            </span>
          </div>
          {simRegistrationDetail?.deliveryTime && (
            <div className="detail-content">
              <span className="label">Khung giờ giao hàng:</span>
              <span className="content">
                {_get(simRegistrationDetail, "deliveryTime", "")}
              </span>
            </div>
          )}
          {simRegistrationDetail?.deliveryAddress && (
            <div className="detail-content">
              <span className="label">Địa chỉ nhận Sim:</span>
              <span className="content">
                {_get(simRegistrationDetail, "deliveryAddress", "")}
              </span>
            </div>
          )}
          {simRegistrationDetail?.alienRegistrationAddress && (
            <div className="detail-content">
              <span className="label" style={{ flexBasis: "100%" }}>
                Địa chỉ trên thẻ ngoại kiều:
              </span>
              <span className="content">
                {_get(simRegistrationDetail, "alienRegistrationAddress", "")}
              </span>
            </div>
          )}
          {(simRegistrationDetail?.phoneNumber ||
            simRegistrationDetail?.phone) && (
            <div className="detail-content">
              <span className="label">Số điện thoại sau đăng ký:</span>
              <span className="content">
                {simRegistrationDetail?.phoneNumber ??
                  simRegistrationDetail?.phone ??
                  ""}
              </span>
            </div>
          )}
          {simRegistrationDetail?.facebookURL && (
            <div className="detail-content">
              <span className="label">Link facebook:</span>
              <span className="content">
                {_get(simRegistrationDetail, "facebookURL", "")}
              </span>
            </div>
          )}
          <div className="detail-content">
            <span className="label">{INITIAL_PRICE_SIM_TEXT}</span>
            <span className="content">
              {_get(simRegistrationDetail, "sim.price", 0).toLocaleString()}
            </span>
          </div>
          {priceDiscounts.length > 0 && (
            <div className="detail-content">
              <span className="label">{DISCOUNTED_SIM_TEXT}</span>
              {priceDiscounts?.map((item) =>
                PromotionItem(
                  item.start,
                  item.end,
                  item.discountValue,
                  item.discountPercentage
                )
              )}
            </div>
          )}
          <div className="detail-content">
            <span className="label">{INITIAL_CONNECTION_FEE_TEXT}</span>
            <span className="content">
              {_get(
                simRegistrationDetail,
                "sim.connectionFee",
                0
              ).toLocaleString()}
            </span>
          </div>
          {connectionFeeDiscounts.length > 0 && (
            <div className="detail-content">
              <span className="label">{DISCOUNTED_CONNECTION_TEXT}</span>
              {connectionFeeDiscounts?.map((item) =>
                PromotionItem(
                  item.start,
                  item.end,
                  item.discountValue,
                  item.discountPercentage
                )
              )}
            </div>
          )}
          <Row
            style={{
              width: "100%",
              borderTop: "1px solid #000000",
              paddingTop: 12,
            }}
          >
            <Col flex="1 1 50%">
              <div className="detail-content">
                <span className="label">{CONNECTION_FEE_TEXT}</span>
                <span className="content">
                  {renderConnectionFee().toLocaleString()}
                </span>
              </div>
              <div className="detail-content">
                <span className="label">Phí Ship:</span>
                <span className="content">
                  {(
                    _get(simRegistrationDetail, "shippingFee", 0) || 0
                  ).toLocaleString()}
                </span>
              </div>
              <div className="detail-content">
                <span className="label">{PRICE_SIM_TEXT}</span>
                <span className="content">
                  {renderSimPrice().toLocaleString()}
                </span>
              </div>
            </Col>
            <Col className="summary-content" flex="1 1 50%">
              <div className="label">Tổng tiền:</div>
              <div>
                {(
                  _get(simRegistrationDetail, "totalPrice", 0) || 0
                ).toLocaleString()}
                JPY
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="sim-images-wrapper" span={10}>
          <div className="sim-image">
            <div className="label">
              <b>Ảnh thẻ ngoại kiều</b> (mặt trước)
            </div>
            <Image
              fallback={PlaceHolderIMG}
              src={_get(
                simRegistrationDetail,
                "alienRegistrationFrontImages[0]",
                DefaultImage
              )}
            />
          </div>
          <div className="sim-image">
            <div className="label">
              <b>Ảnh thẻ mặt sau</b> (mặt sau)
            </div>
            <Image
              fallback={PlaceHolderIMG}
              src={_get(
                simRegistrationDetail,
                "alienRegistrationBackImages[0]",
                DefaultImage
              )}
            />
          </div>
          <div className="sim-image">
            <div className="label">
              <b>Ảnh chụp cùng thẻ ngoại kiều</b>
            </div>
            <Image
              fallback={PlaceHolderIMG}
              src={_get(
                simRegistrationDetail,
                "alienRegistrationsWithFaceImages[0]",
                DefaultImage
              )}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default RegistrationDetailModal;
