import { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Form, Input, Row } from "antd";
import i18n from "i18n";
import _isEmpty from "lodash/isEmpty";
import PlacesAutocomplete from "react-places-autocomplete";
import "../style.less";

const AutoComplete = forwardRef(
  (
    {
      label,
      maxLength,
      inputType,
      size = "large",
      className = "",
      required,
      disabled,
      name,
      showError = true,
      helpComponent = null,
      ...props
    },
    ref
  ) => {
    const [field, meta, helper] = useField(name);
    const isError = meta.touched && meta.error;

    const handleChange = (address) => {
      helper.setValue(address);
    };

    const handleSelect = (address) => {
      helper.setValue(address);
    };

    return (
      <div
        className={`input-container autocomplete-container ${className} ${
          disabled ? "disabled-input" : ""
        }`}
      >
        {label && (
          <div className="label-wrapper">
            <>{label}</>
            {required && <div className="required-mark">*</div>}
          </div>
        )}
        <Form.Item
          validateStatus={isError ? "error" : ""}
          help={
            showError ? (
              <div className="error-text">
                {isError &&
                  meta.error !== i18n.t("message.required") &&
                  meta.error}
              </div>
            ) : (
              helpComponent
            )
          }
        >
          <PlacesAutocomplete
            value={field.value || ""}
            onChange={handleChange}
            onSelect={handleSelect}
            debounce={500}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <div>
                <Input
                  maxLength={maxLength}
                  disabled={disabled}
                  size={size}
                  {...field}
                  {...props}
                  ref={ref}
                  {...getInputProps({
                    placeholder: "Địa chỉ",
                    className: "location-search-input",
                  })}
                  autoComplete="off"
                />
                {!_isEmpty(suggestions) && (
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion) => {
                      const classname = suggestion.active
                        ? "suggestion-item suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <Row
                          key={suggestion.description}
                          {...getSuggestionItemProps(suggestion, {
                            className: classname,
                            styles: {
                              ...style,
                              borderBottom: "1px solid black",
                            },
                          })}
                        >
                          {suggestion.description}
                        </Row>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </Form.Item>
      </div>
    );
  }
);
AutoComplete.displayName = "AutoComplete";

AutoComplete.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.number,
  inputType: PropTypes.string,
  size: PropTypes.string,
  allowClear: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  helpComponent: PropTypes.any,
  name: PropTypes.string,
};

export default memo(AutoComplete);
