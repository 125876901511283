import { useRef } from "react";
import { Col, Row, Button } from "antd";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import { TextInput, ImageUpload } from "components/common";
import "./styles.less";
import { MultiImageUpload } from "components/common/Form";
import { createAccessoryValidate } from "./validate";

const AccessoryEditForm = ({
  initialValues = {},
  onCancel,
  onSubmit,
  isCreating,
  isLoading,
}) => {
  const formikRef = useRef(null);

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      enableReinitialize
      validationSchema={createAccessoryValidate}
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ handleSubmit, isValid }) => (
        <Form>
          <Row className="cosmetic-edit-component-wrapper">
            <Row className="cosmetic-edit-info-container">
              <Col span={14} className="info-section">
                <Row style={{ flexWrap: "nowrap" }}>
                  <Col
                    flex="auto"
                    className="cosmetic-info"
                    style={{ paddingRight: "6%" }}
                  >
                    <Row className="info-row" gutter={8}>
                      <Col
                        style={{ width: 500 }}
                        className="info-col"
                        span={32}
                      >
                        <div className="s-text">Tên Phụ kiện</div>
                        <TextInput name="name" size="small" required />
                      </Col>
                    </Row>
                    <Row
                      style={{
                        width: 500,
                        justifyContent: "space-between",
                      }}
                      className="info-row"
                      gutter={8}
                    >
                      <Col style={{ width: 250 }}>
                        <div className="s-text">Giá gốc</div>
                        <TextInput name="initialPrice" size="small" required />
                      </Col>
                      <Col style={{ width: 250 }}>
                        <div className="s-text">Giá bán</div>
                        <TextInput name="price" size="small" required />
                      </Col>
                    </Row>
                    <Col className="info-row" gutter={8}>
                      <Row className="info-col" span={12}>
                        <MultiImageUpload label="Ảnh phụ kiện" name="images" />
                      </Row>
                    </Col>
                  </Col>
                </Row>
              </Col>
              <Col span={6} className="banner-section">
                <div className="m-title-text">Hình thumbnail</div>
                <ImageUpload name="thumbnail" />
              </Col>
              <Col flex="auto">
                <Row gutter={8} justify="end">
                  {!isCreating && (
                    <Col>
                      <Button onClick={onCancel} type="primary" size="small">
                        HỦY
                      </Button>
                    </Col>
                  )}
                  <Col>
                    <Button
                      onClick={handleSubmit}
                      size="small"
                      disabled={!isValid}
                      isLoading={isLoading}
                    >
                      {isCreating ? "Tạo" : "Cập nhật"}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
AccessoryEditForm.propTypes = {
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isCreating: PropTypes.bool,
  isLoading: PropTypes.bool,
};
export default AccessoryEditForm;
