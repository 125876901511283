/* eslint-disable no-nested-ternary */
import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import { Row, Typography, Button } from "antd";
import { Formik, Form } from "formik";
import { TextInput, ImageUploadContainer } from "components/common";
import BannerImage from "components/common/Form/ImageUploadContainer/BannerImage";
import {
  BANNER_TYPE_OPTIONS,
  BANNER_TYPE_HORIZONTAL_OPTIONS,
  BANNER_TYPE_VERTICAL_OPTIONS,
} from "utils/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetBannerDetail,
  getBannerListRequest,
} from "providers/BannerProvider/slice";
import Helper from "utils/helpers";
import i18n from "i18n";
import { createBannerValidate } from "./validate";
import SelectMultiTypeBanner from "./SelectMultiTypeBanner";

const BannerCreateForm = ({ initialValues, onSubmit, isEditBanner }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const bannerList = useSelector((state) => _get(state, "banner.docs", []));
  const verticalBannerExited = [];
  if (!_isEmpty(bannerList)) {
    bannerList.forEach((banner) => {
      if (["buy_now", "hot"].includes(banner.type[0])) {
        verticalBannerExited.push(banner.type[0]);
      }
      if (["buy_now", "hot"].includes(banner.type[1])) {
        verticalBannerExited.push(banner.type[1]);
      }
    });
  }

  useEffect(() => {
    dispatch(getBannerListRequest({ page: 1, limit: 10000 }));
  }, [dispatch]);

  const handleCancel = () => {
    navigate(-1);
    dispatch(resetBannerDetail());
  };

  const renderHeader = (handleSubmit, isValid) => (
    <Row className="banner-create-header" justify="space-between">
      <Typography.Title level={4}>
        {i18n.t("banner.createBanner")}
      </Typography.Title>
      <div className="create-banner-actions">
        <Button onClick={handleCancel}>{i18n.t("banner.cancelBtn")}</Button>
        <Button
          disabled={!isValid}
          onClick={() => handleSubmit()}
          type="primary"
        >
          {isEditBanner
            ? i18n.t("banner.updateBanner")
            : i18n.t("banner.createBanner")}
        </Button>
      </div>
    </Row>
  );

  const horizontalBannerList = [
    "home_1",
    "home_2",
    "home_3",
    "community_1",
    "community_2",
    "sim",
    "sim_2",
    "fonehouse",
  ];
  const isHorizontalBannerEdit = horizontalBannerList.includes(
    initialValues.type[0]
  );

  const bannerOptionTypeList = !isEditBanner
    ? Helper.convertObjectToOptions(BANNER_TYPE_OPTIONS).filter(
        (item) => !verticalBannerExited.includes(item.value)
      )
    : isHorizontalBannerEdit
    ? Helper.convertObjectToOptions(BANNER_TYPE_HORIZONTAL_OPTIONS)
    : Helper.convertObjectToOptions(BANNER_TYPE_VERTICAL_OPTIONS).filter(
        (item) => verticalBannerExited.includes(item.value)
      );

  return (
    <div className="create-banner-form-container">
      <Formik
        validateOnMount
        initialValues={initialValues}
        enableReinitialize
        validationSchema={createBannerValidate}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ handleSubmit, values, isValid }) => {
          const isSimOrFonehouse = ["sim_2", "fonehouse"].includes(
            values.type[0]
          );
          const isHorizontalBanner = horizontalBannerList.includes(
            values.type[0]
          );
          return (
            <Form>
              <div>
                {renderHeader(handleSubmit, isValid)}
                <div className="form-container">
                  <ImageUploadContainer name="image">
                    <BannerImage
                      aspect={
                        !_isEmpty(values.type) && isSimOrFonehouse
                          ? 1 / 1
                          : isHorizontalBanner
                          ? 3 / 1
                          : 110 / 280
                      }
                      disabled={_isEmpty(values.type)}
                    />
                  </ImageUploadContainer>
                  <TextInput
                    inputType="text"
                    autoSize={{ minRows: 11, maxRows: 11 }}
                    label={i18n.t("banner.fieldURL")}
                    name="url"
                  />
                  <SelectMultiTypeBanner
                    label={i18n.t("banner.fieldPosition")}
                    name="type"
                    inputType="state"
                    mode="multiple"
                    disabled={!isHorizontalBanner && isEditBanner}
                    showSearch={false}
                    options={bannerOptionTypeList}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
BannerCreateForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  isCreating: PropTypes.bool,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  userRole: PropTypes.string,
  isEditBanner: PropTypes.bool,
};
export default BannerCreateForm;
