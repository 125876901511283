import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import Helper from "utils/helpers";
import { safe } from "providers/GeneralProvider/saga";
import {
  getAllNewsFonehouseRequest,
  getAllNewsFonehouseSuccess,
  stopLoading,
  getNewsFonehouseDetailRequest,
  getNewsFonehouseDetailSuccess,
  createNewsFonehouseSuccess,
  createNewsFonehouseRequest,
  updateNewsFonehouseRequest,
  updateNewsFonehouseSuccess,
  deleteNewsFonehouseRequest,
  deleteNewsFonehouseSuccess,
} from "providers/NewsFonehouseProvider/slice";

function* getAllNewsFonehouse(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "keyword",
    "roles",
    "sortTitle",
    "status",
    "sort",
    "seen",
  ]);
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  const { data } = yield call(
    request,
    "api/fonehouse/news-manager/news/get-all",
    params,
    { method: "GET" }
  );
  yield put(getAllNewsFonehouseSuccess(data));
}

function* createNewsFonehouse(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "type",
    "title",
    "thumbnail",
    "content",
  ]);
  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  const data = yield call(request, "api/fonehouse/news-manager/news", params, {
    method: "POST",
  });
  yield put(createNewsFonehouseSuccess(data, meta));
  Helper.toastr(`ID: #${data.newsId}`, "Đăng tin tức thành công", "success");
}

function* getNewsFonehouseDetail(action) {
  const params = _pick(action.payload, ["idNews"]);
  const { data } = yield call(
    request,
    "api/fonehouse/news-manager/news",
    params,
    { method: "GET" }
  );
  yield put(getNewsFonehouseDetailSuccess(data));
}

function* updateNewsFonehouse(action) {
  const { meta } = action;

  const params = _pick(action.payload, [
    "type",
    "title",
    "thumbnail",
    "content",
    "idNews",
  ]);
  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  const result = yield call(request, "/api/admin/news-manager/news", params, {
    method: "PUT",
  });
  yield put(updateNewsFonehouseSuccess(result, meta));
  Helper.toastr(
    `ID: #${result.newsId}`,
    "Cập nhật tin tức thành công",
    "success"
  );
}

function* deleteNewsFonehouse(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idNews"]);
  const newsId = _get(action, "payload.newsId", []);
  const result = yield call(
    request,
    "api/fonehouse/news-manager/news",
    params,
    { method: "DELETE" }
  );
  const newIdList = [];
  newsId.forEach((item) => {
    newIdList.push(item.newsId);
  });
  yield put(deleteNewsFonehouseSuccess(result, meta));
  Helper.toastr(
    `ID:${newIdList.join(", ")}`,
    "Xóa tin tức thành công",
    "success"
  );
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchAuth() {
  yield takeEvery(
    getAllNewsFonehouseRequest.type,
    safe(getAllNewsFonehouse, onError)
  );
  yield takeEvery(
    getNewsFonehouseDetailRequest.type,
    safe(getNewsFonehouseDetail)
  );
  yield takeLeading(
    updateNewsFonehouseRequest.type,
    safe(updateNewsFonehouse, onError)
  );
  yield takeLeading(
    createNewsFonehouseRequest.type,
    safe(createNewsFonehouse, onError)
  );
  yield takeLeading(
    deleteNewsFonehouseRequest.type,
    safe(deleteNewsFonehouse, onError)
  );
}
