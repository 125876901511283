import { memo } from "react";
import { SearchOutlined } from "@ant-design/icons";

import SearchBarComponent from "components/SearchBar";
import { TextInput, Filter } from "components/common/Form";
import { BUSINESS_LIST } from "utils/constants";

const SearchBar = () => (
  <SearchBarComponent filterFields={["keyword", "status"]} isOnChangeSearch>
    <>
      <TextInput
        prefix={<SearchOutlined />}
        name="keyword"
        allowClear={false}
      />
      <Filter
        name="status"
        options={Object.values(BUSINESS_LIST)}
        popConfirmClassName="gb-role-popconfirm"
      />
    </>
  </SearchBarComponent>
);

export default memo(SearchBar);
