/* eslint-disable react/display-name */

import i18n from "i18n";
import moment from "moment";

const Columns = {};
const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

const renderValueMoney = (data, record, key) => {
  if (data === key) {
    return (
      <span>{record.value.toLocaleString() || i18n.t("general.none")}</span>
    );
  }
  return <span>--/--</span>;
};

const renderTransferType = (type) => {
  const style = {
    color: type === "transfer" ? "red" : "green",
    fontWeight: "bold",
  };
  return (
    <span style={style}>
      {i18n.t(`moneyManagementFonehouse.${type}`) || i18n.t("general.none")}
    </span>
  );
};
Columns.createdAt = {
  dataIndex: "createdAt",
  key: "createdAt",
  title: i18n.t("moneyManagementFonehouse.createdAt"),
  sorter: true,
  render: (createdAt) =>
    renderDataValue(moment(createdAt).format("DD/MM/YYYY")),
  width: 130,
};

Columns.value = {
  title: i18n.t("moneyManagementFonehouse.content"),
  dataIndex: "value",
  key: "value",
  sorter: true,
  render: (value) => renderDataValue(value),
};

Columns.description = {
  title: i18n.t("moneyManagementFonehouse.content"),
  dataIndex: "description",
  key: "description",
  sorter: true,
  render: (description) => renderDataValue(description),
  width: 500,
};

Columns.collect = {
  title: i18n.t("moneyManagementFonehouse.collect"),
  dataIndex: "type",
  key: "collect",
  render: (type, record) => renderValueMoney(type, record, "collect"),
  width: 200,
};

Columns.spend = {
  title: i18n.t("moneyManagementFonehouse.give"),
  dataIndex: "type",
  key: "spend",
  render: (type, record) => renderValueMoney(type, record, "spend"),
  width: 200,
};

Columns.cost = {
  title: i18n.t("moneyManagementFonehouse.price"),
  dataIndex: "type",
  key: "cost",
  render: (type, record) => renderValueMoney(type, record, "cost"),
  width: 200,
};

Columns.balance = {
  title: i18n.t("moneyManagementFonehouse.moneyLeft"),
  dataIndex: "balance",
  key: "balance",
  render: (balance = {}) => renderDataValue(`${balance.toLocaleString()} JPY`),
  width: 200,
};

Columns.payment = {
  title: i18n.t("moneyManagementFonehouse.transferType"),
  dataIndex: "payment",
  key: "payment",
  render: (payment = {}) => renderTransferType(payment),
  width: 200,
};

export default Columns;
