import { useState, useMemo } from "react";
import { Formik, Form } from "formik";
import _get from "lodash/get";
import { TextInput, Select } from "components/common";
import { Button, Row, Tabs, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {
  AppleFilled,
  GoogleOutlined,
  FacebookOutlined,
} from "@ant-design/icons";
import "./style.less";
import i18n from "i18n";
import {
  loginRequest,
  superAdminLoginRequest,
  resendOtpRequest,
  loginFacebookRequest,
  loginGoogleRequest,
  loginAppleRequest,
} from "providers/AuthProvider/slice";
import OtpInput from "components/OtpInput";
import firebase from "utils/firebase";
import { FIREBASE_ERROR } from "utils/constants";
import Helper from "utils/helpers";
import codes from "country-calling-code";
import { GoogleLogin } from "react-google-login";
import AppleSignin from "react-apple-signin-auth";
import sha256 from "js-sha256";
import { loginByMailValidate, loginByPhoneValidate } from "./validate";

const { TabPane } = Tabs;

const LoginPage = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth?.isLoading);
  const confirmation = useSelector((state) => state.auth?.confirmation);
  const showOtpScreen = useSelector((state) => state.auth?.showOtpScreen);
  const [otp, setOtp] = useState("");
  const [loginPayload, setLoginPayload] = useState();
  const [loginType, setLoginType] = useState("byMail");
  const options = useMemo(
    () =>
      codes.map((item) => ({
        value: `${item.isoCode2}-${item.countryCodes[0]}`,
        label: `${item.country} +${item.countryCodes[0]}`,
      })),
    []
  );

  const handleVerifyOtp = async () => {
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(
        confirmation.verificationId,
        otp
      );
      await firebase.auth().signInWithCredential(credential);
      const currentFirebaseUser = firebase.auth().currentUser;
      const token = await currentFirebaseUser.getIdTokenResult(true);

      dispatch(
        superAdminLoginRequest({
          ...loginPayload,
          accessToken: token.token,
          type: loginType,
        })
      );
    } catch (error) {
      const errorCode = _get(error, "code");
      Helper.toastr(FIREBASE_ERROR[errorCode], "", "error");
    }
  };

  const handleResendOtp = () => {
    dispatch(resendOtpRequest());
  };

  const handleChangeLoginType = (e) => {
    setLoginType(e);
  };

  return (
    <div className="login-page-wrapper">
      <Row className="logo-wrapper" justify="center">
        <img className="logo" src="/logovietgrp.png" size={52} alt="logo" />
      </Row>
      <div className="login-form">
        {!showOtpScreen ? (
          <>
            <div className="login-text">{i18n.t("loginScreen.login")}</div>
            <Formik
              validateOnMount
              initialValues={{
                email: "",
                password: "",
                countryCode: "VN-84",
                phone: "",
              }}
              validationSchema={
                loginType === "byMail"
                  ? loginByMailValidate
                  : loginByPhoneValidate
              }
              onSubmit={(values) => {
                setLoginPayload(values);
                dispatch(loginRequest({ ...values, type: loginType }));
              }}
            >
              {({ handleSubmit }) => (
                <Form>
                  <Tabs
                    defaultActiveKey={loginType}
                    onChange={handleChangeLoginType}
                  >
                    <TabPane tab={i18n.t("loginScreen.email")} key="byMail">
                      {loginType === "byMail" && (
                        <TextInput
                          label={i18n.t("loginScreen.email")}
                          name="email"
                        />
                      )}
                    </TabPane>
                    <TabPane
                      tab={i18n.t("loginScreen.phoneNumber")}
                      key="byPhone"
                    >
                      <Row className="phone-title">
                        {i18n.t("loginScreen.phoneNumber")}
                      </Row>
                      {loginType === "byPhone" && (
                        <Row className="phone-container">
                          <Select
                            className="country-code"
                            name="countryCode"
                            size="large"
                            options={options}
                          />
                          <TextInput name="phone" className="phone-number" />
                        </Row>
                      )}
                    </TabPane>
                  </Tabs>
                  <TextInput
                    label={i18n.t("loginScreen.password")}
                    name="password"
                    inputType="password"
                  />
                  <Button
                    className="submit-btn"
                    htmlType="submit"
                    loading={isLoading}
                    type="primary"
                    onClick={handleSubmit}
                  >
                    {i18n.t("loginScreen.login")}
                  </Button>
                  <Row
                    justify="space-between"
                    gutter={8}
                    style={{ marginTop: 8 }}
                  >
                    <Col span={8}>
                      <FacebookLogin
                        appId={process.env.REACT_APP_FB_APP_ID}
                        callback={(data) => {
                          dispatch(loginFacebookRequest(data));
                        }}
                        render={(renderProps) => (
                          <Button onClick={renderProps.onClick} type="primary">
                            <FacebookOutlined />
                            Đăng nhập Facebook
                          </Button>
                        )}
                      />
                    </Col>
                    <Col span={8}>
                      <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        render={(renderProps) => (
                          <Button onClick={renderProps.onClick}>
                            <GoogleOutlined />
                            Đăng nhập Google
                          </Button>
                        )}
                        buttonText="Login"
                        onSuccess={(data) => dispatch(loginGoogleRequest(data))}
                        onFailure={(data) => console.error("failure", data)}
                        cookiePolicy={"single_host_origin"}
                      />
                    </Col>
                    <Col span={8}>
                      <AppleSignin
                        onSuccess={(response) =>
                          dispatch(loginAppleRequest(response.authorization))
                        } // default = undefined
                        authOptions={{
                          clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                          redirectURI: process.env.REACT_APP_DOMAIN_NAME,
                          scope: "email name",
                          state: "state",
                          nonce: sha256("nonce"),
                          usePopup: true,
                        }}
                        render={(renderProps) => (
                          <Button type="primary" ghost {...renderProps}>
                            <AppleFilled />
                            Đăng nhập Apple
                          </Button>
                        )}
                      />
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <div className="login-text">{i18n.t("loginScreen.otpInput")}</div>
            <OtpInput
              isInputNum
              containerStyle="otp-input-container"
              className="otp-input"
              value={otp}
              onChange={(value) => setOtp(value)}
              numInputs={6}
            />
            <div className="resend-otp-btn-container">
              <Button
                onClick={handleResendOtp}
                htmlType="submit"
                loading={isLoading}
                type="link"
                size="small"
              >
                {i18n.t("loginScreen.resendOtp")}
              </Button>
            </div>
            <Button
              className="submit-btn"
              htmlType="submit"
              loading={isLoading}
              type="primary"
              onClick={handleVerifyOtp}
            >
              {i18n.t("loginScreen.verifyOtp")}
            </Button>
          </>
        )}
        <div id="recaptcha-container" />
      </div>
    </div>
  );
};

export default LoginPage;
