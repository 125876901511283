import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "antd";
import _get from "lodash/get";
import "../ProductCreateBillModal/styles.less";
import i18n from "i18n";
import BackIcon from "assets/images/back.svg";
import Table from "components/common/Table";
import { getProductListRequest } from "providers/ProductProvider/slice";
import { useQuery } from "hooks";
import {
  setSelectedBusinessIds,
  resetCreateBillState,
  setBillAccessoryModal,
  addAccessoryToBill,
} from "providers/BillingProvider/slice";
import { getAccessoryListRequest } from "providers/AccessoryProvider/slice";
import { AccessoryColumns } from "components/Columns";

const AccessoryCreateBillModal = () => {
  const dispatch = useDispatch();
  const { isAccessoryModal } = useSelector((state) => state.billingList);
  useQuery(getProductListRequest, { arrayParams: ["type", "status"] });
  const selectedRowKeys = useSelector((state) =>
    _get(state, "billingList.selectedRowKeys", [])
  );
  const totalDocs = useSelector((state) =>
    _get(state, "accessorySlice.totalDocs")
  );
  const isLoading = useSelector((state) =>
    _get(state, "accessorySlice.isLoading")
  );
  const accessoryList = useSelector((state) =>
    _get(state, "accessorySlice.accessoryList", [])
  );
  useQuery(getAccessoryListRequest);

  useEffect(() => {
    dispatch(resetCreateBillState());
  }, [dispatch]);
  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      dispatch(setSelectedBusinessIds({ record, selected, selectedRows }));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      dispatch(setSelectedBusinessIds({ selected, selectedRows, changeRows }));
    },
  };
  const handleCloseModal = () => {
    dispatch(
      setBillAccessoryModal({
        visible: false,
      })
    );
  };
  const handleAddBillingProduct = (key) => {
    dispatch(addAccessoryToBill({ key, data: accessoryList }));
    handleCloseModal();
  };

  const columns = [
    AccessoryColumns.id,
    AccessoryColumns.name,
    AccessoryColumns.thumbnail,
    AccessoryColumns.initialPrice,
    AccessoryColumns.price,
  ];

  return (
    <div>
      {isAccessoryModal && (
        <Modal
          title={i18n.t("createBill.addAccessoriesToBill")}
          visible={isAccessoryModal}
          width={1000}
          className="market-product-modal-wrapper"
          footer={null}
          onCancel={handleCloseModal}
          maskClosable={false}
          closeIcon={<img src={BackIcon} alt="" />}
        >
          <div
            style={{
              alignSelf: "center",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="small"
              type="primary"
              style={{ width: 150, marginRight: 16, marginBottom: 20 }}
              onClick={() => handleAddBillingProduct(selectedRowKeys)}
            >
              Thêm phụ kiện
            </Button>
          </div>
          <Table
            rowSelection={rowSelection}
            scroll={{ x: 1000 }}
            loading={isLoading}
            bordered
            columns={columns}
            dataSource={accessoryList}
            rowKey={(data) => data._id}
            total={totalDocs}
          />
        </Modal>
      )}
    </div>
  );
};

export default AccessoryCreateBillModal;
