import { Tag } from "antd";
import PropTypes from "prop-types";
import { PRODUCT_TAG, PRODUCT_TAG_TEXT } from "utils/constants";
import "./styles.less";

const tagColor = {
  [PRODUCT_TAG.HOT]: "error",
  [PRODUCT_TAG.BUY_NOW]: "success",
  [PRODUCT_TAG.HIGHLIGHTS]: "warning",
  [PRODUCT_TAG.FONEHOUSE]: "magenta",
};
const ProductTag = ({ status = PRODUCT_TAG.EMPTY }) => (
  <>
    {status === PRODUCT_TAG.EMPTY ? null : (
      <Tag className="product-tag" color={tagColor[status]}>
        {PRODUCT_TAG_TEXT[status]}
      </Tag>
    )}
  </>
);

ProductTag.propTypes = {
  status: PropTypes.string.isRequired,
};
export default ProductTag;
