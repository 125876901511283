import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import { Select, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getConversationListRequest } from "providers/MessengerProvider/slice";

const FilterTag = () => {
  const dispatch = useDispatch();
  const { fonehouseTagsFilter } = useSelector((state) => state.messenger);

  const onSubmit = (data) => {
    dispatch(
      getConversationListRequest({
        limit: 20,
        page: 1,
        type: "FONEHOUSE_TO_USER",
        role: "fonehouse",
        tags: data,
      })
    );
  };

  return (
    <Space className="search-bar-form">
      <Select
        placeholder="Lọc theo nhãn dán"
        style={{ borderRadius: 10 }}
        options={fonehouseTagsFilter}
        onChange={onSubmit}
      />
    </Space>
  );
};

FilterTag.propTypes = {
  filterFields: PropTypes.array,
  children: PropTypes.object,
  initVal: PropTypes.object,
  isOnChangeSearch: PropTypes.bool,
};
export default FilterTag;
