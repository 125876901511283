import PropTypes from "prop-types";
import { Modal } from "antd";
import _get from "lodash/get";
import BackIcon from "assets/images/back.svg";
import { useSelector, useDispatch } from "react-redux";
import { MARKET_PRODUCT_MODAL_TYPE } from "utils/constants";
import { useParams } from "react-router-dom";
import {
  createProductRestaurantRequest,
  getRestaurantProductListRequest,
  updateProductRestaurantRequest,
  deleteProductRestaurantRequest,
} from "providers/RestaurantProductProvider/slice";
import i18n from "i18n";
import RestaurantProductDetail from "./restaurantProductDetail";
import RestaurantProductForm from "./restaurantProductForm";
import "./styles.less";

const RestaurantProductModal = (props) => {
  const { setMarketProductModal, searchObject, isPermissionToEdit, setSearch } =
    props;
  const dispatch = useDispatch();
  const marketProductModalData = useSelector(
    (state) => state.restaurantProduct.marketProductModalData
  );
  const productMarketTypeList = useSelector((state) =>
    _get(state, "restaurantProduct.productMarketTypeList")
  );

  const { visible, data = {}, type } = marketProductModalData;
  const { restaurantId } = useParams();
  const handleCloseModal = () => {
    dispatch(
      setMarketProductModal({
        visible: false,
        data: {},
        type: "",
      })
    );
  };

  const handleEditMarketProduct = (_data) => {
    dispatch(
      setMarketProductModal({
        visible: true,
        data: _data,
        type: MARKET_PRODUCT_MODAL_TYPE.EDIT,
      })
    );
  };

  const handleDeleteMarketProduct = () => {
    dispatch(
      deleteProductRestaurantRequest({
        productIds: [data._id],
        storeId: restaurantId,
        productRestaurantIds: [data.productRestaurantId],
      })
    ).then(() => {
      dispatch(
        setMarketProductModal({
          visible: false,
          data: {},
          type: "",
        })
      );
      dispatch(getRestaurantProductListRequest(searchObject));
    });
  };

  const handleSubmit = (values) => {
    const productId = data._id;
    const idProductRestaurant = data._id;
    const { productRestaurantId } = data;
    if (type === MARKET_PRODUCT_MODAL_TYPE.CREATE) {
      dispatch(
        createProductRestaurantRequest({
          ...values,
          restaurantId,
        })
      ).then((_data) => {
        dispatch(
          setMarketProductModal({
            visible: true,
            data: {
              ..._data,
              productType: productMarketTypeList.find(
                (item) => item._id === _data.productCategory
              ),
            },
            type: MARKET_PRODUCT_MODAL_TYPE.DETAIL,
          })
        );
        dispatch(getRestaurantProductListRequest(searchObject));
      });
    } else {
      dispatch(
        updateProductRestaurantRequest({
          ...values,
          restaurantId,
          productId,
          productRestaurantId,
        })
      ).then((res) => {
        dispatch(
          setMarketProductModal({
            visible: true,
            data: {
              ...res,
              productType: productMarketTypeList.find(
                (item) => item._id === res.productCategory
              ),
              productRestaurantId,
            },
            type: MARKET_PRODUCT_MODAL_TYPE.DETAIL,
          })
        );
        dispatch(getRestaurantProductListRequest(searchObject));
      });
    }
  };

  return (
    <div>
      {visible && (
        <Modal
          title={i18n.t("marketProduct.productDetail")}
          visible={visible}
          width={860}
          className="market-product-modal-wrapper"
          footer={null}
          onCancel={handleCloseModal}
          maskClosable={false}
          closeIcon={<img src={BackIcon} alt="" />}
        >
          {type === MARKET_PRODUCT_MODAL_TYPE.DETAIL ? (
            <RestaurantProductDetail
              marketProductDetail={data}
              setMarketProductModal={setMarketProductModal}
              handleDeleteMarketProduct={handleDeleteMarketProduct}
              handleEditMarketProduct={handleEditMarketProduct}
              isPermissionToEdit={isPermissionToEdit}
            />
          ) : (
            <RestaurantProductForm
              type={type}
              setMarketProductModal={setMarketProductModal}
              productMarketTypeList={productMarketTypeList}
              searchObject={searchObject}
              onSubmit={handleSubmit}
              initialValues={data}
              setSearch={setSearch}
            />
          )}
        </Modal>
      )}
    </div>
  );
};
RestaurantProductModal.propTypes = {
  setMarketProductModal: PropTypes.func,
  searchObject: PropTypes.object,
  isPermissionToEdit: PropTypes.bool,
  setSearch: PropTypes.func,
};

export default RestaurantProductModal;
