import { put, takeEvery, call, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import { safe } from "providers/GeneralProvider/saga";
import {
  getNotificationListRequest,
  getNotificationListSuccess,
  getCountNotSeenNotificationRequest,
  getCountNotSeenNotificationSuccess,
  updateSeenNotificationRequest,
  updateSeenNotificationSuccess,
  stopLoading,
} from "providers/NotificationProvider/slice";

function* getNotificationList(action) {
  const params = _pick(action.payload, ["page", "limit"]);
  const { data } = yield call(
    request,
    "api/fonehouse/notification-manager/notification/get-all",
    params,
    { method: "GET" }
  );
  yield put(getNotificationListSuccess(data));
}

function* getCountNotSeenNotification(action) {
  const params = _pick(action.payload, ["page", "limit"]);
  const { count } = yield call(
    request,
    "api/fonehouse/notification-manager/notification/count-count-not-seen",
    params,
    { method: "GET" }
  );
  yield put(getCountNotSeenNotificationSuccess(count));
}

function* updateSeenNotification(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["notificationId"]);
  yield call(
    request,
    "api/fonehouse/notification-manager/notification",
    params,
    { method: "PUT" }
  );
  yield put(updateSeenNotificationSuccess(action.payload, meta));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchAuth() {
  yield takeEvery(
    getNotificationListRequest.type,
    safe(getNotificationList, onError)
  );
  yield takeEvery(
    getCountNotSeenNotificationRequest.type,
    safe(getCountNotSeenNotification, onError)
  );
  yield takeLeading(
    updateSeenNotificationRequest.type,
    safe(updateSeenNotification, onError)
  );
}
