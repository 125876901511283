import { memo } from "react";
import { SearchOutlined } from "@ant-design/icons";
import SearchBarComponent from "components/SearchBar";
import { TextInput, Filter } from "components/common/Form";
import { BILL_STATUS } from "utils/constants";

const SearchBar = () => (
  <SearchBarComponent filterFields={["keyword", "status"]} isOnChangeSearch>
    <>
      <div style={{ width: "100%", marginRight: 10 }}>
        <TextInput
          styles={{ marginRight: 8 }}
          prefix={<SearchOutlined />}
          name="keyword"
          allowClear={false}
        />
      </div>
      <Filter
        name="status"
        options={Object.values(BILL_STATUS)}
        popConfirmClassName="gb-role-popconfirm"
      />
    </>
  </SearchBarComponent>
);

export default memo(SearchBar);
