import { Navigate } from "react-router-dom";

import LoginPage from "containers/Auth/Login";
import Dashboard from "containers/Dashboard";
// import Home from 'containers/Dashboard/Home/Loadable';
import SuperAdmin from "containers/Dashboard/SuperAdmin";
import UserInfo from "containers/Dashboard/UserInfo";

import News from "containers/Dashboard/Articles/News";
import NewsCreate from "containers/Dashboard/Articles/News/Create";
import NewsEdit from "containers/Dashboard/Articles/News/Edit";
import NewsDetail from "containers/Dashboard/Articles/News/Detail";
// visa
import Visa from "containers/Dashboard/Articles/Visa";
import VisaCreate from "containers/Dashboard/Articles/Visa/Create";
import VisaEdit from "containers/Dashboard/Articles/Visa/Edit";
import VisaDetail from "containers/Dashboard/Articles/Visa/Detail";

import Supermarket from "containers/Dashboard/Articles/Supermarket";
import SupermarketDetail from "containers/Dashboard/Articles/Supermarket/Detail";
import SupermarketCreate from "containers/Dashboard/Articles/Supermarket/Create";

import Restaurant from "containers/Dashboard/Articles/Restaurant";
import RestaurantDetail from "containers/Dashboard/Articles/Restaurant/Detail";
import RestaurantCreate from "containers/Dashboard/Articles/Restaurant/Create";

import Cosmetic from "containers/Dashboard/Articles/Cosmetic";
import CosmeticDetail from "containers/Dashboard/Articles/Cosmetic/Detail";
import CosmeticCreate from "containers/Dashboard/Articles/Cosmetic/Create";

import ProductList from "containers/Dashboard/Fonehouse/Product";
import ProductCreate from "containers/Dashboard/Fonehouse/Product/Create";
import ProductDetail from "containers/Dashboard/Fonehouse/Product/Detail";

import NewsFonehouse from "containers/Dashboard/Fonehouse/News";
import NewsFonehouseDetail from "containers/Dashboard/Fonehouse/News/Detail";
import NewsFonehouseCreate from "containers/Dashboard/Fonehouse/News/Create";
import NewsFonehouseEdit from "containers/Dashboard/Fonehouse/News/Edit";

import NotFound from "containers/Dashboard/NotFound";
import Helper from "utils/helpers";
import RegisterBusiness from "containers/Dashboard/Fonehouse/RegisterBusiness";
import RegisterBusinessDetail from "containers/Dashboard/Fonehouse/RegisterBusiness/RegisterBusinessDetail";
import AdminManager from "containers/Dashboard/AdminManager";
import ReportManager from "containers/Dashboard/ReportManager";
import BillingList from "containers/Dashboard/Fonehouse/BillingList";
import BillDetail from "containers/Dashboard/Fonehouse/BillingList/Detail";
import CreateBill from "containers/Dashboard/Fonehouse/BillingList/Create";
import RewardPointManager from "containers/Dashboard/RewardPointManager";
import Accessories from "containers/Dashboard/Accessories";
import AccessoryCreate from "containers/Dashboard/Accessories/Create";
import LiquidWithViegoManager from "containers/Dashboard/LiquidWithViegoManager";

import BannerList from "containers/Dashboard/Banners/BannerList";
import BannerCreate from "containers/Dashboard/Banners/BannerCreate";
import BannerEdit from "containers/Dashboard/Banners/BannerEdit";
import CheckingInsurance from "containers/Dashboard/Fonehouse/CheckingInsurance";
import GuideFonehouseList from "containers/Dashboard/Fonehouse/Guide";
import GuideDetail from "containers/Dashboard/Fonehouse/Guide/Detail";
import GuideFonehouseCreate from "containers/Dashboard/Fonehouse/Guide/Create";
import GuideFonehouseEdit from "containers/Dashboard/Fonehouse/Guide/Edit";
import FonehouseStatistical from "containers/Dashboard/Fonehouse/Statistical";
import MoneyManagement from "containers/Dashboard/Fonehouse/MoneyManagement";
import HistoryEdit from "containers/Dashboard/Fonehouse/HistoryEdit";
import Messenger from "containers/Dashboard/Fonehouse/Messenger";
import SupportList from "containers/Dashboard/Support";
import SupportDetail from "containers/Dashboard/Support/Detail";
import ViegoSupport from "containers/Dashboard/ViegoSupport";
import CommentAndRating from "containers/Dashboard/CommentAndRating";
import StoreList from "containers/Dashboard/Store/List";
import StoreCreate from "containers/Dashboard/Store/Create";
import StoreDetail from "containers/Dashboard/Store/Detail";
import { STORE_TYPE } from "utils/constants";
// SIM
import SimList from "containers/Dashboard/Sim/List";
import SimDetail from "containers/Dashboard/Sim/Detail";
import IdentifyUser from "containers/Dashboard/IdentifyUser";
import SimPostCreate from "containers/Dashboard/Sim/Create";
import SimPostDetail from "containers/Dashboard/Sim/SimPostDetail";
import SimPostEdit from "containers/Dashboard/Sim/Edit";
import EditRegisteredSim from "containers/Dashboard/Sim/EditRegisteredSim";
import Policy from "containers/Dashboard/Policy";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoutes";

const RedirectToDashboard = () => {
  const token = Helper.getAuthToken();
  return <Navigate to={token ? "/home" : "/login"} replace />;
};
const dashboardRoutes = [
  {
    path: "/home",
    element: <SuperAdmin />,
  },
  {
    path: "/cms_admin_manager",
    element: <AdminManager />,
  },
  {
    path: "/cms_viego_support/viego_messengers",
    element: <ViegoSupport />,
  },
  {
    path: "/cms_viego_support/viego_messengers/:conversationId",
    element: <ViegoSupport />,
  },
  {
    path: "/cms_admin_report_manager",
    element: <ReportManager />,
  },
  {
    path: "/reward_point_manager",
    element: <RewardPointManager />,
  },
  {
    path: "/liquid_viego_manager",
    element: <LiquidWithViegoManager />,
  },
  {
    path: "/comment_and_rating",
    element: <CommentAndRating />,
  },
  {
    path: "/identify_user",
    element: <IdentifyUser />,
  },
  {
    path: "/news",
    element: <News />,
  },
  {
    path: "/news/:idNews",
    element: <NewsDetail />,
  },
  {
    path: "/news/news-create",
    element: <NewsCreate />,
  },
  {
    path: "/news/:idNews/edit",
    element: <NewsEdit />,
  },
  {
    path: "/visa",
    element: <Visa />,
  },
  {
    path: "/visa/:idVisa",
    element: <VisaDetail />,
  },
  {
    path: "/visa/visa-create",
    element: <VisaCreate />,
  },
  {
    path: "/visa/:idVisa/edit",
    element: <VisaEdit />,
  },
  {
    path: "/entertainment",
    element: <StoreList storeType={STORE_TYPE.ENTERTAINMENT} />,
  },
  {
    path: "/entertainment/create",
    element: <StoreCreate storeType={STORE_TYPE.ENTERTAINMENT} />,
  },
  {
    path: "/entertainment/:id",
    element: <StoreDetail storeType={STORE_TYPE.ENTERTAINMENT} />,
  },
  {
    path: "/supermarket",
    element: <Supermarket />,
  },
  {
    path: "/supermarket/:idMarket",
    element: <SupermarketDetail />,
  },
  {
    path: "/supermarket/create",
    element: <SupermarketCreate />,
  },
  {
    path: "/restaurant",
    element: <Restaurant />,
  },
  {
    path: "/restaurant/:restaurantId",
    element: <RestaurantDetail />,
  },
  {
    path: "/restaurant/create",
    element: <RestaurantCreate />,
  },
  {
    path: "/cosmetic",
    element: <Cosmetic />,
  },
  {
    path: "/cosmetic/:cosmeticId",
    element: <CosmeticDetail />,
  },
  {
    path: "/cosmetic/create",
    element: <CosmeticCreate />,
  },
  {
    path: "/fonehouse/billing_list",
    element: <BillingList />,
  },
  {
    path: "/fonehouse/billing_list/:idBill",
    element: <BillDetail />,
  },
  {
    path: "/fonehouse/create_bill",
    element: <CreateBill />,
  },
  {
    path: "/fonehouse/update_bill/:idBills",
    element: <CreateBill />,
  },
  {
    path: "/fonehouse/checking_insurance",
    element: <CheckingInsurance />,
  },
  {
    path: "/fonehouse/products",
    element: <ProductList />,
  },
  {
    path: "/fonehouse/products/:idProduct",
    element: <ProductDetail />,
  },
  {
    path: "/fonehouse/messengers",
    element: <Messenger />,
  },
  {
    path: "/fonehouse/messengers/:conversationId",
    element: <Messenger />,
  },
  {
    path: "/fonehouse/products/create",
    element: <ProductCreate />,
  },
  {
    path: "/fonehouse/news-fonehouse",
    element: <NewsFonehouse />,
  },
  {
    path: "/fonehouse/news-fonehouse/:idNews",
    element: <NewsFonehouseDetail />,
  },
  {
    path: "/fonehouse/news-fonehouse/news-create",
    element: <NewsFonehouseCreate />,
  },
  {
    path: "/fonehouse/news-fonehouse/:idNews/edit",
    element: <NewsFonehouseEdit />,
  },
  {
    path: "/guide-fonehouse",
    element: <GuideFonehouseList />,
  },
  {
    path: "/guide-fonehouse/:idGuild",
    element: <GuideDetail />,
  },
  {
    path: "/guide-fonehouse/guide-create",
    element: <GuideFonehouseCreate />,
  },
  {
    path: "/guide-fonehouse/:idGuild/edit",
    element: <GuideFonehouseEdit />,
  },
  {
    path: "/fonehouse/business/:idBusiness",
    element: <RegisterBusinessDetail />,
  },
  {
    path: "/fonehouse/statistical-fonehouse",
    element: <FonehouseStatistical />,
  },
  {
    path: "/fonehouse/moneyManagement-fonehouse",
    element: <MoneyManagement />,
  },
  {
    path: "/fonehouse/historyEditor-fonehouse",
    element: <HistoryEdit />,
  },
  {
    path: "/fonehouse/accessories",
    element: <Accessories />,
  },
  {
    path: "/fonehouse/accessory/create",
    element: <AccessoryCreate />,
  },
  {
    path: "/users/:articleId",
    element: <div />,
  },
  {
    path: "/home/:id",
    element: <UserInfo />,
  },
  {
    path: "/cms_admin_manager/:id",
    element: <UserInfo />,
  },
  {
    path: "/registerBusiness",
    element: <RegisterBusiness />,
  },
  {
    path: "/banners",
    element: <BannerList />,
  },
  {
    path: "/banners/create",
    element: <BannerCreate />,
  },
  {
    path: "/banners/:idBanner/edit",
    element: <BannerEdit />,
  },
  {
    path: "/support",
    element: <SupportList />,
  },
  {
    path: "/support/:id",
    element: <SupportDetail />,
  },
  {
    path: "/viego-support/messengers/:conversationId",
    element: <Messenger />,
  },
  {
    path: "/car-and-old-stuff",
    element: <StoreList storeType={STORE_TYPE.CAR_AND_OLD_STUFF} />,
  },
  {
    path: "/car-and-old-stuff/create",
    element: <StoreCreate storeType={STORE_TYPE.CAR_AND_OLD_STUFF} />,
  },
  {
    path: "/car-and-old-stuff/:id",
    element: <StoreDetail storeType={STORE_TYPE.CAR_AND_OLD_STUFF} />,
  },
  {
    path: "/sim",
    element: <SimList />,
  },
  {
    path: "/sim/editRegistered",
    element: <EditRegisteredSim />,
  },
  {
    path: "/sim/create",
    element: <SimDetail isCreating />,
  },
  {
    path: "/sim/sim-post-create",
    element: <SimPostCreate />,
  },
  {
    path: "/sim/post/:idPost",
    element: <SimPostDetail />,
  },
  {
    path: "/sim/:idPost/edit",
    element: <SimPostEdit />,
  },
  {
    path: "/sim/:id",
    element: <SimDetail />,
  },
  {
    path: "/travel",
    element: <StoreList storeType={STORE_TYPE.TRAVEL} />,
  },
  {
    path: "/travel/create",
    element: <StoreCreate storeType={STORE_TYPE.TRAVEL} />,
  },
  {
    path: "/travel/:id",
    element: <StoreDetail storeType={STORE_TYPE.TRAVEL} />,
  },
  {
    path: "/shipper",
    element: <StoreList storeType={STORE_TYPE.SHIPPER} />,
  },
  {
    path: "/shipper/create",
    element: <StoreCreate storeType={STORE_TYPE.SHIPPER} />,
  },
  {
    path: "/shipper/:id",
    element: <StoreDetail storeType={STORE_TYPE.SHIPPER} />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const indexRoutes = [
  { path: "/login", element: <PublicRoute Component={LoginPage} /> },
  { path: "/policy", element: <PublicRoute Component={Policy} /> },
  {
    path: "/",
    element: <PrivateRoute Component={Dashboard} />,
    children: dashboardRoutes,
  },
  {
    path: "*",
    element: <RedirectToDashboard />,
  },
];

export default indexRoutes;
