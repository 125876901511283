import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";
import _uniqBy from "lodash/uniqBy";

const productSlice = createSlice({
  name: "product",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    totalInitiatedPrice: 0,
    selectedRowKeys: [],
    selectedRows: [],
    detail: {},
    setTagModalData: {},
    isSettingTag: false,
    productTypes: [],
    productDetail: {},
    isCreating: false,
    isCreateTypeLoading: false,
    isUpdateTypeLoading: false,
    customizeProductTypeModalData: {
      visible: false,
      data: {
        productTypes: [],
      },
    },
    ViewedProductList: [],
    priceModalData: {},
    isSettingProductsPrice: false,
  },
  reducers: {
    getProductListRequest(state, action) {
      if (_get(action, "payload.page", "1") === "1") {
        return {
          ...state,
          isLoading: true,
          docs: [],
          ViewedProductList: [],
          totalDocs: 5,
        };
      }

      return {
        ...state,
        isLoading: true,
      };
    },
    getProductListSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs, totalInitiatedPrice } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({ ...doc, key: doc._id })),
        ViewedProductList: _uniqBy(
          state.ViewedProductList.concat(
            docs.map((doc) => ({ ...doc, key: doc._id }))
          ),
          "key"
        ),
        totalDocs,
        totalInitiatedPrice,
      };
    },
    getInsuranceListRequest(state) {
      return { ...state, isLoading: true };
    },
    getInsuranceListSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs,
      };
    },
    stopLoading(state) {
      return {
        ...state,
        isLoading: false,
        isSettingTag: false,
        isCreating: false,
      };
    },
    setSelectedNewsIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    setPriceModalData(state, action) {
      return {
        ...state,
        priceModalData: {
          visible: _get(action, "payload.visible", false),
        },
      };
    },
    setProductsPriceRequest: {
      reducer: (state) => {
        state.isSettingProductsPrice = false;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    setProductsPriceSuccess: {
      reducer: (state) => {
        state.isSettingProductsPrice = true;
        state.selectedRowKeys = [];
        state.selectedRow = [];
        return state;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    setTagModalData(state, action) {
      const { payload = {} } = action;
      const { visible, data } = payload;
      return {
        ...state,
        setTagModalData: {
          visible,
          data: data || state.setTagModalData.data,
        },
      };
    },
    setTagRequest: {
      reducer: (state) => {
        state.isSettingTag = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    setTagSuccess: {
      reducer: (state) => ({ ...state, isSettingTag: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteProductRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    deleteProductSuccess: {
      reducer: (state) => ({ ...state, isLoading: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    recoverProductRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    recoverProductSuccess: {
      reducer: (state) => ({ ...state, isLoading: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    getProductTypeRequest(state) {
      return state;
    },
    getProductTypeSuccess(state, action) {
      const { payload = {} } = action;
      return { ...state, productTypes: payload };
    },
    getProductDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getProductDetailSuccess(state, action) {
      const { payload = {} } = action;
      return { ...state, isLoading: false, productDetail: payload };
    },
    createProductRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createProductSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateProductRequest: {
      reducer: (state) => {
        state.isCreating = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateProductSuccess: {
      reducer: (state) => ({
        ...state,
        isCreating: false,
        productDetail: {},
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    resetProductDetai(state) {
      return {
        ...state,
        productDetail: {},
      };
    },
    setCustomizeProductTypeModal(state, action) {
      const { payload = {} } = action;
      const { visible, data } = payload;
      return {
        ...state,
        customizeProductTypeModalData: {
          visible,
          data,
        },
      };
    },

    changeIndexProductTypeRequest: {
      reducer: (state) => {
        state.isChangeIndex = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    changeIndexProductTypeSuccess: {
      reducer: (state) => ({ ...state, isChangeIndex: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    createTypeProductRequest: {
      reducer: (state) => {
        state.isCreateTypeLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createTypeProductSuccess: {
      reducer: (state) => ({ ...state, isCreateTypeLoading: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateTypeProductRequest: {
      reducer: (state) => {
        state.isUpdateTypeLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateTypeProductSuccess: {
      reducer: (state) => ({ ...state, isUpdateTypeLoading: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteTypeProductRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    deleteTypeProductSuccess: {
      reducer: (state) => ({ ...state, isLoading: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
});

export const {
  getInsuranceListRequest,
  getInsuranceListSuccess,
  getProductListRequest,
  getProductListSuccess,
  recoverProductRequest,
  recoverProductSuccess,
  stopLoading,
  setSelectedNewsIds,
  setTagModalData,
  setTagRequest,
  setTagSuccess,
  deleteProductRequest,
  deleteProductSuccess,
  getProductTypeRequest,
  getProductTypeSuccess,
  getProductDetailRequest,
  getProductDetailSuccess,
  createProductRequest,
  createProductSuccess,
  updateProductRequest,
  updateProductSuccess,
  resetProductDetai,
  setCustomizeProductTypeModal,
  changeIndexProductTypeRequest,
  changeIndexProductTypeSuccess,
  createTypeProductRequest,
  createTypeProductSuccess,
  updateTypeProductRequest,
  updateTypeProductSuccess,
  deleteTypeProductRequest,
  deleteTypeProductSuccess,
  setPriceModalData,
  setProductsPriceRequest,
  setProductsPriceSuccess,
} = productSlice.actions;

export default productSlice.reducer;
