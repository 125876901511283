import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Typography } from "antd";

import BackButton from "components/BackButton";
import StoreEdit from "components/Store/Edit";
import { createStoreRequest } from "providers/StoreProvider/slice";
import { STORE_TYPE } from "utils/constants";
import Helper from "utils/helpers";
import "./styles.less";
import moment from "moment/moment";

const context = {
  [STORE_TYPE.CAR_AND_OLD_STUFF]: {
    title: "Tạo cửa hàng",
    path: "/car-and-old-stuff",
  },
  [STORE_TYPE.TRAVEL]: {
    title: "Đăng bài",
    path: "/travel",
  },
  [STORE_TYPE.SHIPPER]: {
    title: "Tạo vận chuyển",
    path: "/shipper",
  },
  [STORE_TYPE.ENTERTAINMENT]: {
    title: "Tạo giải trí",
    path: "/entertainment",
  },
};

const StoreCreate = ({ storeType }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storeContext = context[storeType];

  const handleSubmit = async (values) => {
    const latLng = await Helper.getLatLng(values.address, {
      lng: values.lng,
      lat: values.lat,
    });
    values.longitude = latLng.lng;
    values.latitude = latLng.lat;
    if (values.start) {
      values.customDiscountCondition = {
        start: moment(values.start).format("HH:mm"),
        end: moment(values.end).format("HH:mm"),
      };
    }
    dispatch(
      createStoreRequest({
        type: storeType,
        data: values,
      })
    ).then((data) => {
      navigate(`${storeContext.path}/${data._id}`, {
        state: { canGoBack: true },
        replace: true,
      });
    });
  };
  return (
    <div className="store-detail-wrapper">
      <Row className="store-detail-header" justify="start" align="middle">
        <BackButton />
        <Typography.Title level={4}>{storeContext.title}</Typography.Title>
      </Row>
      <div className="detail-wrapper">
        <StoreEdit
          initialValues={{}}
          storeType={storeType}
          onSubmit={handleSubmit}
          isCreating
        />
      </div>
    </div>
  );
};

export default StoreCreate;
