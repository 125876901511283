import PropTypes from "prop-types";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import ProductTypeItem from "../ProductTypeItem";

const SortableItem = SortableElement(
  ({
    value,
    handleSelectedItem,
    selectedItem,
    itemId,
    handleDeleteTypeProduct,
  }) => (
    <ProductTypeItem
      item={value}
      itemId={itemId}
      selectedItem={selectedItem}
      handleDeleteTypeProduct={handleDeleteTypeProduct}
      handleSelectedItem={handleSelectedItem}
    />
  )
);
SortableItem.propTypes = {
  selectedItem: PropTypes.string,
  itemId: PropTypes.string,
  handleDeleteTypeProduct: PropTypes.func,
  handleSelectedItem: PropTypes.func,
  value: PropTypes.object,
};

const SortableList = (props) => {
  const { items, handleDeleteTypeProduct, handleSelectedItem, ...restProps } =
    props;

  return (
    <div className="sortable-list-wrapper">
      {items.map((item, index) => (
        <SortableItem
          key={`item-${item._id}`}
          index={index}
          value={item}
          itemId={item._id}
          handleDeleteTypeProduct={handleDeleteTypeProduct}
          handleSelectedItem={handleSelectedItem}
          {...restProps}
        />
      ))}
    </div>
  );
};
SortableList.propTypes = {
  items: PropTypes.array,
  handleDeleteTypeProduct: PropTypes.func,
  handleSelectedItem: PropTypes.func,
};
export default SortableContainer(SortableList);
