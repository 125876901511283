import {
  put,
  takeLeading,
  call,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import _set from "lodash/set";
import { safe } from "providers/GeneralProvider/saga";
import {
  getConversationListRequest,
  getConversationListSuccess,
  getMessageListRequest,
  getMessageListSuccess,
  stopLoading,
  sendMessageRequest,
  sendMessageSuccess,
  createConversationRequest,
  createConversationSuccess,
  getConversationDetailRequest,
  getConversationDetailSuccess,
  seenConversationRequest,
  createTagRequest,
  getFonehouseTagsRequest,
  getFonehouseTagsSuccess,
} from "providers/MessengerProvider/slice";

function* handleGetConversationList(action) {
  const params = _pick(action.payload, ["limit", "type", "role", "page"]);
  let endpoint = "api/chat/conversations";

  _set(params, "role", "fonehouse");
  if (action.payload?.tags) {
    const tag = action.payload?.tags.split("-")[0];
    endpoint += `?tags[]=${tag}`;
  }
  const { data } = yield call(request, endpoint, params, {
    method: "GET",
  });

  yield put(
    getConversationListSuccess({ list: data.docs, page: action.payload.page })
  );
}

function* handleGetMessageList(action) {
  const params = _pick(action.payload, [
    "limit",
    "time",
    "type",
    "conversationId",
  ]);
  const { data } = yield call(
    request,
    "api/chat-message/messages-admin",
    params,
    { method: "GET" }
  );

  yield put(
    getMessageListSuccess({
      list: data,
      time: action.payload.time,
      conversationId: params.conversationId,
    })
  );
}

function* handleSendMessage(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "text",
    "type",
    "conversationId",
    "attachments",
  ]);
  const { data } = yield call(
    request,
    "api/chat-message/message-admin",
    params,
    { method: "POST" }
  );
  yield put(
    seenConversationRequest({ conversationId: action.payload.conversationId })
  );
  yield put(sendMessageSuccess(data, meta));
}

function* handleCreateConversation(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["receiverId"]);
  const { data } = yield call(
    request,
    "api/chat/conversation-for-admin",
    params,
    { method: "POST" }
  );
  yield put(createConversationSuccess(data, meta));
}

function* handleGetConversationDetail(action) {
  const params = _pick(action.payload, ["conversationId"]);
  if (params.conversationId) {
    const { data } = yield call(
      request,
      `api/chat/conversation/${action.payload.conversationId}`,
      undefined,
      {
        method: "GET",
      }
    );
    yield put(getConversationDetailSuccess({ detail: data }));
  }
}

function* handleSeenConversation(action) {
  const params = _pick(action.payload, ["conversationId"]);
  if (params.conversationId) {
    yield call(request, "api/chat-message/seen-message", params, {
      method: "POST",
    });
  }
}

function* handleCreateTag(action) {
  const { conversationId, name, color } = action.payload;
  const tags = [
    {
      name,
      color,
    },
  ];
  const params = {
    tags,
  };
  const endpoint = `api/chat/conversations/${conversationId}/tags`;
  yield call(request, endpoint, params, { method: "POST" });
  const { data } = yield call(
    request,
    `api/chat/conversation/${conversationId}`,
    undefined,
    {
      method: "GET",
    }
  );
  yield put(getConversationDetailSuccess({ detail: data }));
  yield put(getFonehouseTagsRequest());
}

function* handleGetFonehouseTags() {
  const endpoint = "api/chat/conversations/fonehouse-tags";
  const response = yield call(request, endpoint, undefined, { method: "GET" });
  yield put(getFonehouseTagsSuccess({ tags: response?.tags }));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchAuth() {
  yield takeLeading(
    getConversationListRequest.type,
    safe(handleGetConversationList, onError)
  );
  yield takeLatest(
    getMessageListRequest.type,
    safe(handleGetMessageList, onError)
  );
  yield takeEvery(sendMessageRequest.type, safe(handleSendMessage, onError));
  yield takeLeading(
    createConversationRequest.type,
    safe(handleCreateConversation, onError)
  );
  yield takeEvery(
    getConversationDetailRequest.type,
    safe(handleGetConversationDetail, onError)
  );
  yield takeEvery(
    seenConversationRequest.type,
    safe(handleSeenConversation, onError)
  );
  yield takeLatest(createTagRequest.type, safe(handleCreateTag, onError));
  yield takeLatest(
    getFonehouseTagsRequest.type,
    safe(handleGetFonehouseTags, onError)
  );
}
