import PropTypes from "prop-types";
import DOMPurify from "dompurify";
import i18n from "i18n";
import { ROLES } from "utils/constants";
import { Link } from "react-router-dom";
import "./style.less";

export const VisaSummaryTooltip = (props) => {
  const { VisaDetail, role } = props;
  const { title, content, _id } = VisaDetail;
  const createMarkup = (html) => ({
    __html: DOMPurify.sanitize(html, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
    }),
  });

  return (
    <div className="news-summary-tooltip-wrapper">
      <div className="news-summary-title"> {title}</div>
      <div
        className="news-summary-content"
        dangerouslySetInnerHTML={createMarkup(content)}
      ></div>
      <Link
        to={`${
          role === ROLES.FONEHOUSE ? "/fonehouse/visa-fonehouse/" : "/visa/"
        }${_id}`}
        state={{ canGoBack: true }}
      >
        <div className="see-detail-btn">{i18n.t("visaSummary.seeDetail")} </div>
      </Link>
    </div>
  );
};

VisaSummaryTooltip.propTypes = {
  VisaDetail: PropTypes.object,
  role: PropTypes.string,
};

export default VisaSummaryTooltip;
