import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";

const reportManagerSlice = createSlice({
  name: "reportManager",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    decentralizationModal: {
      isOpen: false,
    },
    storeManagerData: {},
    unreadSummary: [],
    totalReport: 0,
  },
  reducers: {
    getListReportRequest(state) {
      return { ...state, isLoading: true };
    },
    getListReportSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs,
      };
    },
    stopLoading(state) {
      return { ...state, isLoading: false };
    },
    setSelectedIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },

    setDecentralizationPopup(state, action) {
      state.decentralizationModal.isOpen = action.payload.isOpen;
      if (action.payload.data) {
        state.decentralizationModal.data = action.payload.data;
      }
      state.decentralizationModal.form = action.payload.form;
      return state;
    },

    updateReportStatusRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateReportStatusSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (meta) => ({ meta }),
    },
    setReportStoreManagerModal(state, action) {
      const { payload = {} } = action;
      const { visible, data } = payload;
      return {
        ...state,
        storeManagerData: {
          visible,
          data,
        },
      };
    },
    getUnreadReportRequest(state) {
      return { ...state, isLoading: true };
    },
    getUnreadReportSuccess(state, action) {
      const { payload = {} } = action;
      return {
        ...state,
        isLoading: false,
        unreadSummary: payload[0].reportSummary,
        totalReport: payload[0].total,
      };
    },
    setReadReportRecordRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    setReadReportRecordSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (meta) => ({ meta }),
    },
    resetSelectedRowKey: {
      reducer: (state) => {
        state.isLoading = false;
        state.selectedRows = [];
        return state;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
});

export const {
  getUnreadReportSuccess,
  setReadReportRecordSuccess,
  getUnreadReportRequest,
  setReadReportRecordRequest,
  getListReportRequest,
  getListReportSuccess,
  stopLoading,
  setSelectedIds,
  setDecentralizationPopup,
  setReportStoreManagerModal,
  updateAccuracyRequest,
  updateAccuracySuccess,
  updateReportStatusRequest,
  updateReportStatusSuccess,
  blockUserRequest,
  unblockUserRequest,
  blockUserSuccess,
  unblockUserSuccess,
  resetSelectedRowKey,
} = reportManagerSlice.actions;

export default reportManagerSlice.reducer;
