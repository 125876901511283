import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";
import { resetStoreState } from "providers/StoreProvider/slice";

const entertainmentProductSlice = createSlice({
  name: "entertainmentProduct",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    entertainmentId: null,
    detail: {},
    entertainmentProductModalData: {},
    productEntertainmentTypeList: [],
    setTagProductArticleModalData: {},
    isSettingTag: false,
  },
  reducers: {
    getEntertainmentProductListRequest(state) {
      return { ...state, isLoading: true };
    },
    getEntertainmentProductListSuccess(state, action) {
      const { payload = {} } = action;
      const { products, total } = payload;
      return {
        ...state,
        entertainmentId: _get(products, "[0].entertainmentId"),
        isLoading: false,
        docs: products.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs: total,
      };
    },

    setSelectedIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isSettingTag: false };
    },
    updateStatusEntertainmentRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },

    updateStatusEntertainmentSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
        selectedRowKeys: [],
        selectedRows: [],
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateEntertainmentProductRequest: {
      reducer: (state) => {
        state.isCreating = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateEntertainmentProductSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    getEntertainmentDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getEntertainmentDetailSuccess(state, action) {
      return { ...state, isLoading: false, detail: action.payload };
    },

    setEntertainmentProductModal(state, action) {
      const { payload = {} } = action;
      const { visible, data, type } = payload;
      return {
        ...state,
        entertainmentProductModalData: {
          visible,
          data,
          type,
        },
      };
    },
    getProductEntertainmentTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    getProductEntertainmentTypeSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        productEntertainmentTypeList: action.payload,
      };
    },
    addProductEntertainmentTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    addProductEntertainmentTypeSuccess(state, action) {
      return {
        ...state,
        isLoading: false,
        productEntertainmentTypeList: state.productEntertainmentTypeList.concat(
          action.payload.type
        ),
      };
    },
    createProductEntertainmentRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createProductEntertainmentSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateProductEntertainmentRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateProductEntertainmentSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteProductEntertainmentRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    deleteProductEntertainmentSuccess: {
      reducer: (state) => ({
        ...state,
        selectedRowKeys: [],
        selectedRows: [],
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteProductEntertainmentTypeRequest(state) {
      return { ...state, isLoading: true };
    },
    deleteProductEntertainmentTypeSuccess(state) {
      return { ...state, isLoading: false };
    },
    editProductEntertainmentTypeRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    editProductEntertainmentTypeSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    setTagProductArticleModalData(state, action) {
      const { payload = {} } = action;
      const { visible, data } = payload;
      return {
        ...state,
        setTagProductArticleModalData: {
          visible,
          data: data || state.setTagProductArticleModalData.data,
        },
      };
    },
    setTagProductEntertainmentRequest: {
      reducer: (state) => {
        state.isSettingTag = true;
        state.selectedRowKeys = [];
        state.selectedRows = [];
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    setTagProductEntertainmentSuccess: {
      reducer: (state) => ({ ...state, isSettingTag: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      resetStoreState.type,
      (state) => ({
        ...state,
        docs: [],
      })
      // action is inferred correctly here if using TS
    );
  },
});

export const {
  setSelectedIds,
  confirmBusinessRequest,
  confirmBusinessSuccess,
  getEntertainmentProductListSuccess,
  getEntertainmentProductListRequest,
  stopLoading,
  updateStatusEntertainmentRequest,
  updateStatusEntertainmentSuccess,
  getEntertainmentDetailRequest,
  getEntertainmentDetailSuccess,
  updateEntertainmentProductSuccess,
  updateEntertainmentProductRequest,
  setEntertainmentProductModal,
  getProductEntertainmentTypeRequest,
  getProductEntertainmentTypeSuccess,
  addProductEntertainmentTypeRequest,
  addProductEntertainmentTypeSuccess,
  createProductEntertainmentRequest,
  createProductEntertainmentSuccess,
  updateProductEntertainmentRequest,
  updateProductEntertainmentSuccess,
  deleteProductEntertainmentRequest,
  deleteProductEntertainmentSuccess,
  deleteProductEntertainmentTypeRequest,
  deleteProductEntertainmentTypeSuccess,
  editProductEntertainmentTypeRequest,
  editProductEntertainmentTypeSuccess,
  setTagProductArticleModalData,
  setTagProductEntertainmentRequest,
  setTagProductEntertainmentSuccess,
} = entertainmentProductSlice.actions;

export default entertainmentProductSlice.reducer;
