// FormikOnChange.js file

import { useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useFormikContext } from "formik";

import { useDebounceCallback } from "hooks";

const FormikOnChange = ({ delay = 500, onChange, searchFields = [] }) => {
  const { values } = useFormikContext();
  const onChangeFields = _.pick(values, searchFields);
  const isFirstRun = useRef(true);
  const debouncedSave = useDebounceCallback(
    (nextValue) => onChange(nextValue),
    delay
  );
  useDeepCompareEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (delay > 0) {
      debouncedSave(values);
    } else {
      onChange(values);
    }
  }, [onChangeFields]);

  return null;
};

FormikOnChange.propTypes = {
  delay: PropTypes.number, // ms
  onChange: PropTypes.func.isRequired,
};

export default FormikOnChange;
