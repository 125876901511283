/* eslint-disable react/display-name */

import i18n from "i18n";
import { Tooltip, Row, Col } from "antd";
import StatusBlock from "components/StatusBlock";
import ArticlePermissionTooltip from "components/ArticlePermissionTooltip";
import PermissionTooltip from "components/PermissionTooltip";
import Helper from "utils/helpers";
import TypeBlock from "components/TypeBlock";
import _isEmpty from "lodash/isEmpty";
import moment from "moment";

const Columns = {};

const renderDataValue = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.businessId = {
  dataIndex: "businessId",
  key: "businessId",
  title: "#",
  render: (businessId) => renderDataValue(`#${businessId}`),
  width: 195,
};

Columns.createdAt = {
  dataIndex: "createdAt",
  key: "createdAt",
  title: i18n.t("register.createAt"),
  width: 150,
  render: (createdAt) =>
    renderDataValue(moment(createdAt).format("DD/MM/YYYY")),
};

Columns.state = {
  title: i18n.t("register.storeName"),
  dataIndex: "name",
  key: "name",
  sorter: true,
  render: (state) => renderDataValue(state),
  width: 200,
};

Columns.permission = {
  title: i18n.t("register.type"),
  dataIndex: "type",
  key: "type",
  render: (roles = []) =>
    renderDataValue(
      !_isEmpty([roles]) ? (
        <Row>
          {Helper.sortUserPermission([roles]).map((role) => (
            <Tooltip
              key={role}
              title={<PermissionTooltip active={role} />}
              color="white"
            >
              <Col style={{ marginRight: 4 }}>
                <TypeBlock type={role} />
              </Col>
            </Tooltip>
          ))}
        </Row>
      ) : null
    ),
};

Columns.account = {
  title: i18n.t("register.account"),
  dataIndex: "idUser",
  key: "idUser",
  render: (idUser) => renderDataValue(idUser.name),
};

Columns.address = {
  title: i18n.t("register.address"),
  dataIndex: "address",
  key: "address",
  render: (address) => renderDataValue(address),
};

Columns.status = {
  title: i18n.t("register.status"),
  dataIndex: "status",
  key: "status",
  width: 80,
  render: (status = []) =>
    renderDataValue(
      <Row>
        <Tooltip
          title={<ArticlePermissionTooltip active={status} />}
          color="white"
        >
          <Col style={{ marginRight: 4 }}>
            <StatusBlock status={status} />
          </Col>
        </Tooltip>
      </Row>
    ),
};

export default Columns;
