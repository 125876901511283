import { object, string, array } from "yup";
import i18n from "i18n";

export const editRegisteredSimValidate = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
  customOrderId: string().trim().nullable().notRequired(),
  postalCode: string().trim().nullable().notRequired(),
  alienRegistrationAddress: string().trim().nullable().notRequired(),
  deliveryAddress: string().trim().required(i18n.t("errors.required")),
  email: string().trim().nullable().notRequired(),
  transactionType: string().trim().required(i18n.t("errors.required")),
  prefixPhoneNumber: string().trim().nullable().notRequired(),
  phoneNumber: string().trim().nullable().notRequired(),
  facebookURL: string().trim().nullable().notRequired(),
  alienRegistrationFrontImages: array().min(0, i18n.t("errors.required")),
  alienRegistrationBackImages: array().min(0, i18n.t("errors.required")),
  alienRegistrationsWithFaceImages: array().min(0, i18n.t("errors.required")),
});
