import { useRef } from "react";
import { Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { TextInput, ImageUpload } from "components/common";
import { useSelector } from "react-redux";
import { createNewType } from "./validate";

const CreateTypeForm = (props) => {
  const { handleCreateNewType } = props;
  const isCreateTypeLoading = useSelector(
    (state) => state.product.isCreateTypeLoading
  );
  const formikRef = useRef();

  const onSubmit = async (values, { resetForm }) => {
    await handleCreateNewType(values);
    resetForm({});
  };

  return (
    <div className="create-type-form-wrapper">
      <Formik
        innerRef={formikRef}
        initialValues={{ name: "", image: null }}
        validationSchema={createNewType}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form className="type-form">
            <Row justify="space-between">
              <Col span={17} justify="space-between">
                <TextInput name="name" label="Tên loại" required />
                <Button
                  onClick={handleSubmit}
                  type="primary"
                  loading={isCreateTypeLoading}
                >
                  TẠO MỚI
                </Button>
              </Col>
              <Col
                span={6}
                justify="space-between"
                className="create-type-image"
              >
                <ImageUpload name="image" />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CreateTypeForm.propTypes = {
  handleCreateNewType: PropTypes.func,
};

export default CreateTypeForm;
