import { forwardRef, useState, memo, useEffect } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import { Upload, Form, message, Button, Modal } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import _isEmpty from "lodash/isEmpty";
import _invoke from "lodash/invoke";
import Helper from "utils/helpers";
import "../style.less";

const MultiImageUpload = forwardRef(
  ({ label, className = "", required, ...props }) => {
    const [field, meta, helper] = useField(props);
    const isError = meta.error && meta.touched;
    const [isLoading, setIsloading] = useState(false);
    const [values, setValues] = useState([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");

    useEffect(() => {
      if (!_isEmpty(values)) {
        helper.setValue([...new Set(field.value.concat(values))]);
        setValues([]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);
    const image = !_isEmpty(field.value)
      ? (field.value || [])
          .filter((img) => typeof img === "string")
          .map((img) =>
            typeof img === "string" ? { uid: img, url: img } : img
          )
      : [];

    const beforeUpload = (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("Bạn chỉ có đăng ảnh PNG hoặc JPEG");
      }
      const isLt5M = file.size / 1024 / 1024 <= 25;
      if (!isLt5M) {
        message.error("Ảnh phải nhỏ hơn 25MB!");
      }
      return isJpgOrPng && isLt5M;
    };

    const handleRemove = (file) => {
      const newValues = field.value.filter((img) =>
        typeof img === "string" ? img !== file.uid : img.uid !== file.uid
      );
      helper.setValue(newValues);
      _invoke(props, "handleChangeImages", {
        images: newValues.map((value) =>
          typeof value === "string" ? value : value.url
        ),
      });
    };

    const customRequest = async (option) => {
      const { file } = option;

      setIsloading(true);
      const img = await Helper.uploadFile({ file });
      setIsloading(false);
      if (img) {
        setValues((value) => value.concat(img.url));
        const fieldValue = field.value.map((item) =>
          typeof item === "string" ? item : item.url
        );
        _invoke(props, "handleChangeImages", {
          images: fieldValue.concat(img.url),
        });
      }
    };

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file) => {
      setPreviewImage(file.url);
      setPreviewVisible(true);
    };

    return (
      <div
        className={`input-container multi-upload-image-container ${className}`}
      >
        {label && (
          <div className="label-wrapper">
            <>{label}</>
            {required && <div className="required-mark">*</div>}
          </div>
        )}
        <Form.Item
          validateStatus={isError ? "error" : ""}
          help={
            <div className="helper-wrapper">
              <div className="error-text">{isError && <>{meta.error}</>}</div>
            </div>
          }
        >
          <Upload
            onPreview={handlePreview}
            listType="picture-card"
            fileList={image}
            showUploadList={{
              removeIcon: <CloseOutlined style={{ color: "#fff" }} />,
            }}
            beforeUpload={beforeUpload}
            onRemove={handleRemove}
            multiple
            customRequest={customRequest}
          >
            <Button disabled={isLoading} style={{ height: "100%" }}>
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Chọn ảnh</div>
              </div>
            </Button>
          </Upload>
        </Form.Item>
        <Modal
          visible={previewVisible}
          title="Preview Image"
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="preview-img" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }
);

MultiImageUpload.displayName = "MultiImageUpload";
MultiImageUpload.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
};
export default memo(MultiImageUpload);
