/* eslint-disable react/display-name */

import i18n from "i18n";
import SummaryTooltip from "components/SummaryTooltip";
import MarketProductTooltip from "components/SummaryTooltip/MarketProductTooltip";
import ProductTag from "components/Product/Tag";

const Columns = {};
const renderDataValue = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.productId = {
  dataIndex: "_id",
  key: "_id",
  title: "#",
  render: (_id, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<MarketProductTooltip marketProductDetail={data} />}
        widthTooltipInner={680}
      >
        <div>{_id}</div>
      </SummaryTooltip>
    ),
  width: 100,
};

Columns.name = {
  title: i18n.t("marketProduct.productName"),
  dataIndex: "name",
  key: "name",
  sorter: true,
  render: (state) => renderDataValue(state),
  width: 200,
};

Columns.type = {
  title: i18n.t("marketProduct.type"),
  dataIndex: ["productCategory", "name"],
  key: "productCategory",
  sorter: true,
  render: (productType) => renderDataValue(productType),
  width: 110,
};

Columns.mass = {
  title: i18n.t("marketProduct.mass"),
  dataIndex: "mass",
  key: "mass",
  sorter: true,
  render: (mass, record) =>
    renderDataValue(
      <div>
        {mass} {record.unit}
      </div>
    ),
  width: 120,
};

Columns.amount = {
  title: i18n.t("marketProduct.amount"),
  dataIndex: "amount",
  key: "amount",
  sorter: true,
  width: 120,
  render: (amount) => renderDataValue(amount),
};

Columns.promotion = {
  title: i18n.t("marketProduct.promotion"),
  dataIndex: "percentagePromotion",
  key: "percentagePromotion",
  width: 130,
  render: (percentagePromotion, record) =>
    renderDataValue(
      `${
        record.promotion !== 0
          ? `${record.promotion.toLocaleString()} ${record.currency}`
          : `${percentagePromotion}%`
      }`,
      { fontWeight: "bold" }
    ),
};

Columns.price = {
  title: i18n.t("marketProduct.price"),
  dataIndex: "price",
  key: "price",
  width: 140,
  sorter: true,
  render: (price, data) =>
    renderDataValue(
      <div>
        {price?.toLocaleString()}
        {` ${data.currency}`}
      </div>
    ),
};

Columns.promotionPrice = {
  title: i18n.t("marketProduct.promotionPrice"),
  dataIndex: "promotionPrice",
  key: "promotionPrice",
  width: 140,
  sorter: true,
  render: (promotionPrice, data) =>
    renderDataValue(
      <div>
        {promotionPrice?.toLocaleString()}
        {` ${data.currency}`}
      </div>
    ),
};

Columns.tag = {
  title: i18n.t("products.tag"),
  dataIndex: "tag",
  key: "tag",
  render: (tag) => renderDataValue(<ProductTag status={tag} />),
};

export default Columns;
