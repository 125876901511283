import { useEffect, useState } from "react";
import {
  Row,
  Typography,
  Button,
  Dropdown,
  Menu,
  Select,
  DatePicker,
} from "antd";
import _get from "lodash/get";
import { Link } from "react-router-dom";
import { StatisticalFonehouseColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import {
  exportCSVRequest,
  getAllStatisticalFonehouseRequest,
} from "providers/FonehouseStatistical/slice";
import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import moment from "moment";
import { CSVLink } from "react-csv";
import SearchBar from "./SearchBar";

const { Option } = Select;
const FonehouseStatistical = () => {
  const [type, setType] = useState("");
  const [dateFrom, setDateFrom] = useState(moment());
  const [dateTo, setDateTo] = useState(moment());
  const dispatch = useDispatch();
  const statisticalFonehouseList = useSelector((state) =>
    _get(state, "statisticalFonehouse.docs")
  );
  const statisticalFonehouseTotal = useSelector((state) =>
    _get(state, "statisticalFonehouse.totalDocs")
  );
  const csvData = useSelector((state) =>
    _get(state, "statisticalFonehouse.csvData")
  );
  const totalInterest = useSelector((state) =>
    _get(state, "statisticalFonehouse.totalInterest")
  );
  const totalCost = useSelector((state) =>
    _get(state, "statisticalFonehouse.totalCost")
  );
  const isLoading = useSelector((state) =>
    _get(state, "statisticalFonehouse.isLoading")
  );
  const [searchObject, setSearch] = useQuery(
    getAllStatisticalFonehouseRequest,
    { params: ["from", "to", "type", "keyword"] }
  );
  const headers = [
    { label: "Mã đơn hàng", key: "orderIdCustom" },
    { label: "Ngày tạo đơn", key: "createdAt" },
    { label: "Loại đơn", key: "transactionType" },
    { label: "Trạng thái", key: "status" },
    { label: "Tài khoản", key: "name" },
    { label: "Giá nhập", key: "initialPrice" },
    { label: "Giá bán", key: "promotionPrice" },
    { label: "Lãi suất", key: "interest" },
  ];
  const handleExportCSV = () => {
    dispatch(
      exportCSVRequest({
        from: moment(dateFrom).valueOf(),
        to: moment(dateTo).valueOf(),
        transactionType: type,
      })
    );
  };
  useEffect(() => {
    handleExportCSV();
    setSearch({
      ...searchObject,
      from: moment(dateFrom).valueOf(),
      to: moment(dateTo).valueOf(),
      transactionType: type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo, type]);
  const menu = (data) => {
    const { _id } = data;
    return (
      <Menu>
        <Menu.Item onClick={() => {}}>
          <Link
            to={`/fonehouse/billing_list/${_id}`}
            state={{ canGoBack: true }}
          >
            {i18n.t("statisticalFonehouse.moveDetail")}
          </Link>
        </Menu.Item>
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const columns = [
    StatisticalFonehouseColumn.id,
    StatisticalFonehouseColumn.createdAt,
    StatisticalFonehouseColumn.type,
    StatisticalFonehouseColumn.status,
    StatisticalFonehouseColumn.account,
    StatisticalFonehouseColumn.initialPrice,
    StatisticalFonehouseColumn.promotionPrice,
    StatisticalFonehouseColumn.interestRate,
    {
      render: (record) => actionButtons(record),
    },
  ];

  const handleChangeSelectType = (_type) => {
    setType(_type);
  };
  const handleOnchangeDateFrom = (_dateFrom) => {
    setDateFrom(_dateFrom);
  };
  const handleOnchangeDateTo = (_dateTo) => {
    setDateTo(_dateTo);
  };
  // const handleSubmitFilter = () => {
  //   dispatch(getAllStatisticalFonehouseRequest({ from: moment(dateFrom).valueOf(), to: moment(dateTo).valueOf(), transactionType: type, page: 1, limit: 10 }));
  // };

  const renderFilterStatistical = () => (
    <Row
      wrap={false}
      style={{ marginRight: 10 }}
      justify="center"
      align="middle"
    >
      <Select
        defaultValue=""
        style={{ width: 150 }}
        onChange={handleChangeSelectType}
      >
        <Option value="">Tất cả</Option>
        <Option value="Daibiki">Daibiki</Option>
        <Option value="cash">GD cửa hàng</Option>
        <Option value="transfer">Trả trước</Option>
      </Select>
      <div style={{ marginRight: 10, marginLeft: 10 }}> Từ </div>
      <DatePicker
        allowClear={false}
        defaultValue={dateFrom}
        placeholder="Chọn ngày"
        onChange={handleOnchangeDateFrom}
      />
      <div style={{ marginRight: 10, marginLeft: 10 }}> Đến </div>
      <DatePicker
        allowClear={false}
        defaultValue={dateTo}
        placeholder="Chọn ngày"
        onChange={handleOnchangeDateTo}
      />
      {/* <Button onClick={handleSubmitFilter} style={{ marginLeft: 10 }} type="primary">Lọc</Button> */}
    </Row>
  );
  return (
    <div className="statistical-fonehouse-list-wrapper">
      <Row
        className="statistical-fonehouse-list-header"
        justify="space-between"
      >
        <Typography.Title level={4}>
          {i18n.t("statisticalFonehouse.listTitle")}
        </Typography.Title>
        {renderFilterStatistical()}
      </Row>
      <div className="content-container">
        <SearchBar />
        <Table
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={statisticalFonehouseList}
          rowKey={(data) => data._id}
          total={statisticalFonehouseTotal}
        />
        <Row wrap={false} justify="space-between" align="middle">
          <Button style={{ width: 200 }} type="primary">
            <CSVLink
              headers={headers}
              className="btn btn-primary"
              filename={`Thongke${moment(dateFrom).format(
                "MM-DD-YYYY"
              )}-TO-${moment(dateTo).format("MM-DD-YYYY")}.csv`}
              data={csvData}
            >
              Xuất file CSV
            </CSVLink>
          </Button>
          <div>
            <b>Tổng lãi trước phí:</b> {totalInterest} JPY
          </div>
          <div>
            <b>Tổng chi phí:</b> {totalCost} JPY
          </div>
          <div>
            <b>Tổng lãi:</b> {totalInterest - totalCost} JPY
          </div>
        </Row>
      </div>
    </div>
  );
};

export default FonehouseStatistical;
