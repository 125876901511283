import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Typography, Button, Dropdown, Tabs } from "antd";
import _get from "lodash/get";

import { SimColumn } from "components/Columns";
import { useQuery } from "hooks";
import TabHeader from "components/TabHeader";
import { IconSeeMore } from "assets/images";
import {
  getInternetRegistrationDetailRequest,
  getInternetRegistrationDetailSuccess,
  getSimListRequest,
  getSimRegistrationDetailRequest,
  getSimRegistrationDetailSuccess,
  getUnReadNumberSimTabRequest,
  getWifiRegistrationDetailRequest,
  getWifiRegistrationDetailSuccess,
  openAddingCustomFieldModal,
  setReadInternetRecordRequest,
  setReadWifiRecordRequest,
  updateCustomFieldRequest,
  getSimTypesRequest,
} from "providers/SimProvider/slice";
import { Table } from "components/common";
import RegistrationDetailModal from "components/Sim/RegistrationDetailModal";
import RegistrationInternetDetailModal from "components/Sim/RegistrationInternetDetailModal";
import RegistrationWifiDetailModal from "components/Sim/RegistrationWifiDetailModal";
import "./styles.less";
import CreatePromotionModal from "components/CreatePromotionModal";
import AddingCustomFieldModal from "components/Sim/AddingCustomFieldModal";
import { useRef, useEffect } from "react";
import MenuOverlay from "./MenuOverlay";
import SearchBar from "./SearchBar";

const { TabPane } = Tabs;

const SimList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    list,
    total,
    isLoading,
    orderId,
    isOpenAddingCustomField,
    unReadNumberSim,
  } = useSelector((state) => state.sim);

  const addingCustomFieldRef = useRef(null);
  const [searchObject, setSearch] = useQuery(getSimListRequest, {
    arrayParams: ["status"],
  });
  useEffect(() => {
    dispatch(getUnReadNumberSimTabRequest());
  }, []);

  useEffect(() => {
    dispatch(getSimTypesRequest());
  }, []);

  const refetchList = () => {
    dispatch(
      getSimListRequest({
        ...searchObject,
      })
    );
    dispatch(getUnReadNumberSimTabRequest());
  };

  const handleOpenRegistationDetail = (record) => {
    dispatch(getSimRegistrationDetailSuccess(record));
    dispatch(getSimRegistrationDetailRequest({ _id: record._id }));
  };
  const handleOpenInternetRegistationDetail = (record) => {
    dispatch(getInternetRegistrationDetailRequest(record));
    dispatch(getInternetRegistrationDetailSuccess({ _id: record._id }));
  };
  const handleOpenWifiRegistationDetail = (record) => {
    dispatch(getWifiRegistrationDetailRequest(record));
    dispatch(getWifiRegistrationDetailSuccess({ _id: record._id }));
  };

  const handleCloseModal = () => {
    dispatch(openAddingCustomFieldModal(false));
  };
  const handleSubmitCustomField = (values) => {
    dispatch(updateCustomFieldRequest({ params: values, id: orderId }));
    dispatch(openAddingCustomFieldModal(false));
    addingCustomFieldRef?.current?.resetForm();

    refetchList();
  };
  const simListColumn = [
    SimColumn.id,
    SimColumn.simName,
    SimColumn.type,
    SimColumn.initialConnectionFee,
    SimColumn.connectionFee,
    SimColumn.initialPrice,
    SimColumn.price,
  ];
  const requestSimColumn = [
    SimColumn.simRegisterId,
    SimColumn.customOrderId,
    SimColumn.createdAt,
    SimColumn.simRegisterAccount,
    SimColumn.account,
    SimColumn.phone,
    SimColumn.simRegisterName,
    SimColumn.simRegisterType,
    SimColumn.discountedConnectionFee,
    SimColumn.subTotal,
    SimColumn.simRegisterPrice,
    SimColumn.simRegisterStatus,
  ];
  const postSimListColumn = [
    SimColumn.imageUrl,
    SimColumn.title,
    SimColumn.viewCount,
  ];
  const wifiListColumn = [
    SimColumn.createdAt,
    SimColumn.vietnameseName,
    SimColumn.japaneseName,
    SimColumn.postalCode,
    SimColumn.phoneNumber,
    SimColumn.email,
    SimColumn.gender,
  ];
  const internetListColumn = [
    SimColumn.createdAt,
    SimColumn.accountName,
    SimColumn.postalCode,
    SimColumn.phoneNumber,
    SimColumn.email,
    SimColumn.alienRegistrationAddress,
  ];
  const renderColumn = () => {
    switch (searchObject.type) {
      case "SIM":
        return simListColumn;
      case "POST":
        return postSimListColumn;
      case "DELETED":
        return postSimListColumn;
      case "REQUESTED":
      case "SIM_DATA_SOFT_BANK":
        return requestSimColumn;
      case "WIFI":
        return wifiListColumn;
      case "INTERNET":
        return internetListColumn;
      default:
        return simListColumn;
    }
  };
  const columns = [
    ...renderColumn(),
    {
      title: "",
      width: 100,
      align: "center",
      render: (record) => (
        <Dropdown
          overlay={
            <MenuOverlay
              type={searchObject.type}
              record={record}
              refetchList={refetchList}
              handleOpenRegistationDetail={handleOpenRegistationDetail}
              handleOpenInternetRegistationDetail={
                handleOpenInternetRegistationDetail
              }
              handleOpenWifiRegistationDetail={handleOpenWifiRegistationDetail}
            />
          }
          placement="bottomLeft"
          arrow
        >
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
            loading={isLoading}
          />
        </Dropdown>
      ),
    },
  ];

  const renderUnReadNumber = (value) => {
    switch (value) {
      case "REQUESTED":
        return unReadNumberSim.simRegistration;
      case "INTERNET":
        return unReadNumberSim.internetPackage;

      case "WIFI":
        return unReadNumberSim.wifiHotspot;

      case "POST":
        return unReadNumberSim.connectivityProductPost;

      default:
        return 0;
    }
  };

  return (
    <div className="sim-list-wrapper">
      {["REQUESTED", "SIM_DATA_SOFT_BANK"].includes(searchObject.type) && (
        <RegistrationDetailModal />
      )}
      {searchObject.type === "INTERNET" && <RegistrationInternetDetailModal />}
      {searchObject.type === "WIFI" && <RegistrationWifiDetailModal />}
      <CreatePromotionModal
        handleCloseModal={handleCloseModal}
        isVisible={isOpenAddingCustomField}
      >
        <AddingCustomFieldModal
          addingCustomFieldRef={addingCustomFieldRef}
          initialValues={{
            customOrderId: "",
            phone: "",
          }}
          isConnectionFee
          onSubmit={handleSubmitCustomField}
        />
      </CreatePromotionModal>
      <Row className="sim-list-header" justify="space-between">
        <Typography.Title level={4}>Danh sách SIM</Typography.Title>
        <Row
          style={{ width: 300 }}
          className="sim-list-header"
          justify="space-between"
        >
          <Button
            size="large"
            type="primary"
            onClick={() =>
              navigate(`${location.pathname}/create`, {
                state: { canGoBack: true },
              })
            }
          >
            Tạo Sim
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() =>
              navigate(`${location.pathname}/sim-post-create`, {
                state: { canGoBack: true },
              })
            }
          >
            Tạo bài viết
          </Button>
        </Row>
      </Row>
      <div className="content-container">
        <SearchBar searchObject={searchObject} type={searchObject.type} />
        <Tabs
          className="store-tabs-container"
          activeKey={searchObject.type}
          type="card"
          onChange={(key) => {
            setSearch(
              {
                type: key,
                keyword: searchObject.keyword,
                status: searchObject.status,
                activeStatus: searchObject.activeStatus,
              },
              true
            );
          }}
        >
          {[
            "SIM",
            "SIM_DELETED",
            "DELETED",
            "POST",
            "SIM_DATA_SOFT_BANK",
            "REQUESTED",
            "WIFI",
            "INTERNET",
          ].map((value) => {
            const unreadNumber = renderUnReadNumber(value);
            return (
              <TabPane
                className="status-tab-pane"
                tab={
                  <TabHeader
                    unreadNumbers={unreadNumber}
                    status={value}
                    total={100}
                  />
                }
                key={value}
              >
                <Table
                  scroll={{ x: "max-content" }}
                  loading={isLoading}
                  bordered
                  columns={columns}
                  dataSource={list}
                  rowKey={(data) => data._id}
                  rowClassName={(record) => {
                    if (
                      searchObject.type === "WIFI" ||
                      searchObject.type === "INTERNET"
                    ) {
                      if (!record.isRead) {
                        return "row-status-readed";
                      }
                    }
                    if (
                      ["REQUESTED", "SIM_DATA_SOFT_BANK"].includes(
                        searchObject.type
                      )
                    ) {
                      return `row-status-${_get(record, "status", "")}`;
                    }
                    return "row-status-unread";
                  }}
                  total={total}
                  onRow={(record) => ({
                    onDoubleClick: () => {
                      if (
                        ["REQUESTED", "SIM_DATA_SOFT_BANK"].includes(
                          searchObject.type
                        )
                      ) {
                        handleOpenRegistationDetail(record);
                      }

                      if (
                        searchObject.type === "SIM" ||
                        searchObject.type === "SIM_DELETED"
                      ) {
                        navigate(`/sim/${record._id}`, {
                          state: { canGoBack: true },
                        });
                      }
                      if (searchObject.type === "WIFI") {
                        dispatch(
                          setReadWifiRecordRequest({ idPost: record._id })
                        );
                        handleOpenWifiRegistationDetail(record);
                        refetchList();
                      }
                      if (searchObject.type === "INTERNET") {
                        dispatch(
                          setReadInternetRecordRequest({ idPost: record._id })
                        );
                        handleOpenInternetRegistationDetail(record);
                        refetchList();
                      }
                      if (
                        searchObject.type === "POST" ||
                        searchObject.type === "DELETED"
                      ) {
                        navigate(`/sim/post/${record._id}`, {
                          state: { canGoBack: true },
                        });
                      }
                      if (searchObject.type === "POST") {
                        navigate(`/sim/post/${record._id}`, {
                          state: { canGoBack: true },
                        });
                      }
                    },
                  })}
                />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default SimList;
