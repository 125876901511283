import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import _get from "lodash/get";
import _set from "lodash/set";
import _unset from "lodash/unset";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import Helper from "utils/helpers";
import moment from "moment";
import {
  getCosmeticProductListRequest,
  getCosmeticProductListSuccess,
  stopLoading,
  updateCosmeticProductRequest,
  updateCosmeticProductSuccess,
  getProductCosmeticTypeRequest,
  addProductCosmeticTypeRequest,
  createProductCosmeticRequest,
  updateProductCosmeticRequest,
  deleteProductCosmeticRequest,
  getProductCosmeticTypeSuccess,
  addProductCosmeticTypeSuccess,
  createProductCosmeticSuccess,
  updateProductCosmeticSuccess,
  deleteProductCosmeticSuccess,
  deleteProductCosmeticTypeRequest,
  deleteProductCosmeticTypeSuccess,
  editProductCosmeticTypeRequest,
  editProductCosmeticTypeSuccess,
  setTagProductCosmeticRequest,
  setTagProductCosmeticSuccess,
} from "./slice";

function* getCosmeticProductList(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "sortName",
    "sortMass",
    "sortPrice",
    "sortPromotionPrice",
    "productType",
    "cosmeticId",
    "keyword",
    "cosmeticProductTypeId",
  ]);
  _set(params, "page", Number(params.page));
  _set(params, "limit", Number(params.limit));
  _set(params, "cosmeticStoreId", params.cosmeticId);
  _unset(params, "cosmeticId");
  const { data } = yield call(
    request,
    "api/cms/cosmetic-product/filter",
    params,
    { method: "POST" }
  );
  yield put(getCosmeticProductListSuccess(data));
}

function* updateCosmeticProduct(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "sortName",
    "productType",
    "cosmeticId",
  ]);
  const { data } = yield call(
    request,
    "api/fonehouse/product-cosmetic-manager/product-cosmetic",
    params,
    { method: "PUT" }
  );
  yield put(updateCosmeticProductSuccess(data));
}

export function* getProductCosmeticType(action) {
  const params = _pick(action.payload, ["cosmeticStoreId"]);
  const data = yield call(request, "api/cms/cosmetic-product/type", params, {
    method: "GET",
  });
  yield put(getProductCosmeticTypeSuccess(data));
}

export function* addProductCosmeticType(action) {
  const params = _pick(action.payload, ["name", "cosmeticStoreId"]);
  const { data } = yield call(
    request,
    "api/cms/cosmetic-product/type",
    params,
    { method: "POST" }
  );
  yield put(addProductCosmeticTypeSuccess(data));
  Helper.toastr(
    `Loại:${_get(data, "name")}`,
    "Thêm loại sản phẩm thành công",
    "success"
  );
}

export function* deleteProductCosmeticType(action) {
  const params = _pick(action.payload, ["id", "cosmeticStoreId"]);
  yield call(
    request,
    `api/cms/cosmetic-product/type/${params.id}`,
    {},
    { method: "DELETE" }
  );
  yield put(deleteProductCosmeticTypeSuccess(params));
  yield put(
    getProductCosmeticTypeRequest({ cosmeticStoreId: params.cosmeticStoreId })
  );
  const searchParams = _get(action, "payload.searchObject");
  yield put(getCosmeticProductListRequest(searchParams));
  Helper.toastr(
    `Loại: ${_get(action, "payload.name")}`,
    "Xóa loại sản phẩm thành công",
    "success"
  );
}

function* createProductCosmetic(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "name",
    "currency",
    "percentagePromotion",
    "promotion",
    "price",
    "productType",
    "element",
    "images",
    "thumbnail",
    "fromDatePromotion",
    "toDatePromotion",
    "amount",
    "cosmeticStoreId",
  ]);
  if (params.thumbnail) {
    _set(params, "thumbnail", params.thumbnail.url);
  }
  if (_isEmpty(params.fromDatePromotion)) {
    _set(params, "fromDatePromotion", moment());
  }
  if (_isEmpty(params.toDatePromotion)) {
    _set(params, "toDatePromotion", moment());
  }
  _set(params, "inputPrice", 0);
  _set(params, "images", []);
  if (Number(params.price) === 0) {
    _set(params, "promotion", 0);
    _set(params, "percentagePromotion", 0);
  }
  const { data } = yield call(request, "api/cms/cosmetic-product", params, {
    method: "POST",
  });
  yield put(createProductCosmeticSuccess(data, meta));
  Helper.toastr(
    `ID:${_get(data, "cosmeticProductId")}`,
    "Tạo sản phẩm thành công",
    "success"
  );
}

function* updateProductCosmetic(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "name",
    "currency",
    "percentagePromotion",
    "promotion",
    "price",
    "productType",
    "element",
    "images",
    "thumbnail",
    "fromDatePromotion",
    "toDatePromotion",
    "amount",
    "cosmeticStoreId",
  ]);
  if (params.thumbnail) {
    _set(params, "thumbnail", params.thumbnail.url);
  }
  if (params.promotion === 0 && params.percentagePromotion === 0) {
    _set(params, "fromDatePromotion", moment());

    _set(params, "toDatePromotion", moment());
  }
  _set(params, "inputPrice", 0);
  _set(params, "images", []);
  if (Number(params.price) === 0) {
    _set(params, "promotion", 0);
    _set(params, "percentagePromotion", 0);
  }
  yield call(
    request,
    `api/cms/cosmetic-product/${_get(action, "payload.productId")}`,
    params,
    { method: "PUT" }
  );
  yield put(
    updateProductCosmeticSuccess(
      { ...params, _id: _get(action, "payload.productId") },
      meta
    )
  );
  Helper.toastr(
    `ID:${_get(action, "payload.cosmeticProductId")}`,
    "Cập nhật sản phẩm thành công",
    "success"
  );
}

function* deleteProductCosmetic(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["ids"]);
  const cosmeticProductIds = _get(action, "payload.cosmeticProductIds", []);
  const { data } = yield call(
    request,
    "api/cms/cosmetic-product/multi",
    params,
    { method: "DELETE_BODY" }
  );
  yield put(deleteProductCosmeticSuccess(data, meta));
  Helper.toastr(
    `ID:${cosmeticProductIds.join(", ")}`,
    "Xóa sản phẩm thành công",
    "success"
  );
}

export function* editProductCosmeticType(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "productTypeCosmeticId",
    "name",
    "cosmeticId",
  ]);
  const { data } = yield call(
    request,
    `api/cms/cosmetic-product/type/${params.productTypeCosmeticId}`,
    { name: params.name },
    { method: "PUT" }
  );
  yield put(editProductCosmeticTypeSuccess(data, meta));
  Helper.toastr(
    `Loại: ${_get(action, "payload.name")}`,
    "Cập nhật loại sản phẩm thành công",
    "success"
  );
}

function* setTagProductCosmetic(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idProducts", "tag"]);
  yield call(
    request,
    "api/cms/set-tag/cosmetic-product",
    { ids: params.idProducts, tag: params.tag },
    { method: "PUT" }
  );
  yield put(setTagProductCosmeticSuccess({}, meta));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchCosmeticProduct() {
  yield takeEvery(
    getCosmeticProductListRequest.type,
    safe(getCosmeticProductList, onError)
  );
  yield takeLeading(
    getProductCosmeticTypeRequest.type,
    safe(getProductCosmeticType, onError)
  );
  yield takeLeading(
    updateCosmeticProductRequest.type,
    safe(updateCosmeticProduct, onError)
  );
  yield takeLeading(
    addProductCosmeticTypeRequest.type,
    safe(addProductCosmeticType, onError)
  );
  yield takeLeading(
    createProductCosmeticRequest.type,
    safe(createProductCosmetic, onError)
  );
  yield takeLeading(
    updateProductCosmeticRequest.type,
    safe(updateProductCosmetic, onError)
  );
  yield takeLeading(
    deleteProductCosmeticRequest.type,
    safe(deleteProductCosmetic, onError)
  );
  yield takeLeading(
    deleteProductCosmeticTypeRequest.type,
    safe(deleteProductCosmeticType, onError)
  );
  yield takeLeading(
    editProductCosmeticTypeRequest.type,
    safe(editProductCosmeticType, onError)
  );
  yield takeLeading(
    setTagProductCosmeticRequest.type,
    safe(setTagProductCosmetic, onError)
  );
}
