import { useState } from "react";
// import { Formik, Form } from 'formik';
import PropTypes from "prop-types";
import _get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_ADMIN_FONEHOUSE } from "utils/constants";
import { updateUserRoleRequest } from "providers/AdminProvider/slice";
import { Button, Checkbox } from "antd";

const DecentralizationUserForm = (props) => {
  const { idUser } = props;
  const docs = useSelector((state) => _get(state, "admin.docs"));
  const totalDocs = useSelector((state) => _get(state, "admin.totalDocs"));
  const isLoading = useSelector((state) => _get(state, "admin.isLoading"));
  const [selected, setSelected] = useState([]);

  const dispatch = useDispatch();
  const handleUpdateRole = () => {
    dispatch(
      updateUserRoleRequest({
        role: selected[0],
        idUser: [idUser],
        docs,
        totalDocs,
      })
    );
  };

  return (
    <div className="decentralization-form">
      {ROLE_ADMIN_FONEHOUSE.map((option) => (
        <Checkbox
          onChange={(e) => {
            if (e.target.checked === true) {
              setSelected((_selected) => _selected.concat([option.value]));
            } else {
              setSelected((_selected) =>
                _selected.filter((selectedOpt) => selectedOpt !== option.value)
              );
            }
          }}
          key={option.value}
          checked={(selected || []).includes(option.value)}
        >
          {option.label}
        </Checkbox>
      ))}
      <Button
        className="decentralization-btn"
        block
        type="primary"
        loading={isLoading}
        onClick={handleUpdateRole}
      >
        {" "}
        PHÂN QUYỀN
      </Button>
    </div>
  );
};

DecentralizationUserForm.propTypes = {
  roles: PropTypes.array,
  idUser: PropTypes.string,
};

export default DecentralizationUserForm;
