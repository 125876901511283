import { useState } from "react";
import PropType from "prop-types";
import i18n from "i18n";
import { Modal, Col, Row, Image, Button } from "antd";
import { CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { PlaceHolderIMG } from "assets/images";
import TagProductArticleForm from "./TagProductArticleForm";
import "./styles.less";

const SetTagProductArticleModal = ({
  category,
  setTagProductArticleModalData,
  setTagProductArticleRequest,
  getProductArticleListRequest,
  visible,
  products,
  originalProducts,
  searchObject,
}) => {
  const dispatch = useDispatch();
  const [showingProduct, setShowingProduct] = useState(0);
  const [tagAll, setTagAll] = useState(false);
  const detail = useSelector((state) => state.store.detail);
  const handleTagAllChange = (value) => {
    if (value.target.checked) {
      const currentTag = products[showingProduct].tag;
      const updatedProducts = products.map((pro) => ({
        ...pro,
        tag: currentTag,
      }));
      dispatch(
        setTagProductArticleModalData({
          visible: true,
          data: { products: updatedProducts, originalProducts },
        })
      );
    } else {
      dispatch(
        setTagProductArticleModalData({
          visible: true,
          data: {
            products: originalProducts,
            originalProducts,
          },
        })
      );
    }
    setTagAll(!tagAll);
  };

  const handleCloseModal = () => {
    dispatch(
      setTagProductArticleModalData({
        visible: false,
        data: {},
      })
    );
  };

  const handleAfterClose = () => {
    dispatch(
      setTagProductArticleModalData({
        visible: false,
        data: {
          products: [],
          originalProducts: [],
        },
      })
    );
  };

  const handleSubmit = (values) => {
    dispatch(
      setTagProductArticleRequest({
        ...values,
        productIds: products.map((product) => product._id),
        storeId: detail._id,
      })
    ).then(() => {
      handleAfterClose();
      dispatch(getProductArticleListRequest(searchObject));
    });
  };

  const handleTagChange = (data) => {
    setTagAll(false);
    const updatedProducts = products.map((pro) => ({ ...pro }));
    updatedProducts[showingProduct].tag = data;
    dispatch(
      setTagProductArticleModalData({
        visible: true,
        data: { products: updatedProducts, originalProducts },
      })
    );
  };

  const displayedProduct = _get(products, `[${showingProduct}]`, {});
  const { promotionPrice = "", percentagePromotion = 0 } = displayedProduct;

  return (
    <div className="set-tag-modal-wrapper">
      <Modal
        maskClosable={false}
        afterClose={handleAfterClose}
        onCancel={handleCloseModal}
        title={i18n.t("products.setProductTag")}
        visible={visible}
        width={768}
        className="set-tag-modal"
        footer={null}
      >
        <div className="m-title-text">{i18n.t("products.basicInfo")}</div>
        <Row justify="space-between" className="product-carousel-wrapper">
          <Col span={6} style={{ maxWidth: 165 }}>
            <div className="product-image-wrapper">
              <Image
                width={165}
                fallback={PlaceHolderIMG}
                height={165}
                src={displayedProduct.thumbnail}
              />
              {percentagePromotion > 0 && (
                <div className="sale--price-wrapper">
                  -{percentagePromotion}%
                </div>
              )}
            </div>
            <Row
              align="middle"
              justify="center"
              className="carousel-btn-wrapper"
            >
              <Button
                disabled={showingProduct === 0}
                icon={<CaretLeftOutlined />}
                onClick={() => setShowingProduct(showingProduct - 1)}
              />
              {showingProduct + 1}/{products.length}
              <Button
                disabled={showingProduct + 1 === products.length}
                icon={<CaretRightOutlined />}
                onClick={() => setShowingProduct(showingProduct + 1)}
              />
            </Row>
          </Col>
          <Col span={8}>
            <div className="l-title-text">{displayedProduct.name}</div>
            <div className="detail-text s-text">
              <div className="fw-700">Loại: </div>
              <div>
                {category === "cosmetic"
                  ? _get(displayedProduct, "type.name", "")
                  : _get(displayedProduct, "productType.name", "")}
              </div>
            </div>
            {category === "restaurant" || category === "cosmetic" ? (
              <div className=" s-text product-size">
                <div className="fw-700">Mô tả (size):</div>
                <div>
                  {displayedProduct.mass} {displayedProduct.element}
                </div>
              </div>
            ) : (
              <div className="detail-text s-text">
                <div className="fw-700">Khối lượng: </div>
                <div>
                  {displayedProduct.mass} {displayedProduct.unit}
                </div>
              </div>
            )}
            <div className="detail-text s-text">
              <div className="fw-700">Số lượng: </div>
              <div>{displayedProduct.amount}</div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Khuyến mãi: </div>
              {!_isEmpty(displayedProduct) && (
                <div>{`${
                  displayedProduct.promotion !== 0
                    ? `${displayedProduct.promotion.toLocaleString()} ${
                        displayedProduct.currency
                      }`
                    : `${displayedProduct.percentagePromotion}%`
                }`}</div>
              )}
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Giá bán: </div>
              <div>
                {displayedProduct.price} {displayedProduct.currency}
              </div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Giá sau KM: </div>
              <div className="highlight-price-text">
                {promotionPrice.toLocaleString()} {displayedProduct.currency}
              </div>
            </div>
          </Col>
          <Col span={9}>
            <TagProductArticleForm
              onSubmit={handleSubmit}
              onTagChange={handleTagChange}
              handleTagAllChange={handleTagAllChange}
              tagAll={tagAll}
              initialValues={products[showingProduct]}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

SetTagProductArticleModal.propTypes = {
  category: PropType.string,
  setTagProductArticleModalData: PropType.func,
  setTagProductArticleRequest: PropType.func,
  getProductArticleListRequest: PropType.func,
  visible: PropType.bool,
  products: PropType.array,
  originalProducts: PropType.array,
  searchObject: PropType.object,
};

export default SetTagProductArticleModal;
