import PropTypes from "prop-types";
import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  setCustomizeProductTypeModal,
  getProductTypeRequest,
} from "providers/ProductProvider/slice";
import ProductTypeList from "./ProductTypeList";

// import Helper from 'utils/helpers';
import "./styles.less";

const EntertainmentModal = () => {
  const dispatch = useDispatch();
  const customizeProductTypeModalData = useSelector(
    (state) => state.product.customizeProductTypeModalData
  );
  const { visible, data } = customizeProductTypeModalData;

  const handleCloseModal = () => {
    dispatch(
      setCustomizeProductTypeModal({
        visible: false,
        data: {},
      })
    );
    dispatch(getProductTypeRequest());
  };

  return (
    <div>
      {visible && (
        <Modal
          title={"Tùy chỉnh"}
          visible={visible}
          width={920}
          className="customize-product-type-modal"
          footer={null}
          onCancel={handleCloseModal}
          maskClosable={false}
        >
          <ProductTypeList productTypes={data.productTypes} />
        </Modal>
      )}
    </div>
  );
};
EntertainmentModal.propTypes = {
  setEntertainmentModal: PropTypes.func,
  searchObject: PropTypes.object,
};

export default EntertainmentModal;
