import { memo } from "react";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";

import SearchBarComponent from "components/SearchBar";
import { TextInput, Filter, Select } from "components/common/Form";
// import i18n from 'i18n';
import { NEWS_ROLES } from "utils/constants";

const selectNewsOption = [
  { value: "", label: "Trạng thái" },
  { value: "1", label: "Đã xem" },
  { value: "0", label: "Chưa xem" },
];

const SearchBar = () => (
  <SearchBarComponent
    filterFields={["keyword", "status", "seen"]}
    isOnChangeSearch
  >
    <>
      <TextInput
        size="middle"
        prefix={<SearchOutlined />}
        name="keyword"
        allowClear={false}
        // placeholder={i18n.t('listUserScreen.searchName')}
      />
      <Select
        name="seen"
        className="select-read-news-wrapper"
        options={selectNewsOption}
      />
      <Filter
        styles={{ marginLeft: 8 }}
        name="status"
        width="20px"
        options={Object.values(NEWS_ROLES)}
        popConfirmClassName="gb-role-popconfirm"
      />
    </>
  </SearchBarComponent>
);

SearchBar.propTypes = {
  name: PropTypes.string,
  companyValue: PropTypes.string,
};
export default memo(SearchBar);
