import { Row, Typography, Button } from "antd";
import _get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQuery } from "hooks";
import Table from "components/common/Table";
import { BannerColumn } from "components/Columns";
import {
  getBannerListRequest,
  updateBannerRequest,
  deleteBannerRequest,
} from "providers/BannerProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import {
  ACTIVE_BANNER_CONTENT,
  DEACTIVE_BANNER_CONTENT,
  DELETE_BANNER_CONTENT,
} from "utils/constants";
import "./styles.less";
import i18n from "i18n";

const BannerList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bannerList = useSelector((state) => _get(state, "banner.docs"));
  const bannerListLoading = useSelector((state) =>
    _get(state, "banner.isLoading")
  );
  const bannerListTotal = useSelector((state) =>
    _get(state, "banner.totalDocs")
  );
  const [searchObject] = useQuery(getBannerListRequest);

  const handleToggleStatus = (record) => {
    const updateStatus = !record.active;
    const popupContent = record.active
      ? DEACTIVE_BANNER_CONTENT
      : ACTIVE_BANNER_CONTENT;
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          ...popupContent,
          onOk: () =>
            dispatch(
              updateBannerRequest({
                ...record,
                docs: bannerList,
                active: updateStatus,
                idBanner: record._id,
                bannerId: record.bannerId,
                image: { url: record.image },
              })
            ).then(() => dispatch(getBannerListRequest(searchObject))),
        },
      })
    );
  };

  const handleRemoveBanner = (record) => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          ...DELETE_BANNER_CONTENT,
          onOk: () =>
            dispatch(
              deleteBannerRequest({
                idBanner: record._id,
                bannerId: record.bannerId,
              })
            ).then(() => dispatch(getBannerListRequest(searchObject))),
        },
      })
    );
  };

  const actionButtons = (record) => (
    <div className="menu-actions">
      <Button
        onClick={() => navigate(`/banners/${record._id}/edit`)}
        className="edit-btn"
      >
        Chỉnh sửa
      </Button>
      <Button onClick={() => handleRemoveBanner(record)} className="remove-btn">
        Xóa
      </Button>
    </div>
  );

  const columns = [
    BannerColumn.bannerId,
    BannerColumn.imageUrl,
    BannerColumn.url,
    {
      ...BannerColumn.status,
      render: (text, record) =>
        BannerColumn.status.render(text, record, (checked, e) => {
          e.stopPropagation();
          handleToggleStatus(record);
        }),
    },
    BannerColumn.position,
    {
      render: (record) => actionButtons(record),
    },
  ];
  // Columns.actions = {
  //   dataIndex: 'actions',
  //   key: 'actions',
  //   render: (text, record, onClick) => {
  //     return renderDataValue(
  //       <div className="menu-actions">
  //         <Button onClick className="edit-btn" >Chỉnh sửa</Button>
  //         <Button onClick={onClick} className="remove-btn" >Xóa</Button>
  //       </div>,
  //     );
  //   },
  // };
  return (
    <div className="banner-list-wrapper">
      <Row className="banner-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("banner.setBannerAdmin")}
        </Typography.Title>
        <Button
          type="primary"
          onClick={() =>
            navigate("/banners/create", { state: { canGoBack: true } })
          }
        >
          {i18n.t("banner.createBanner")}
        </Button>
      </Row>
      <div className="banner-list-table">
        <Table
          // rowSelection={rowSelection}
          scroll={{ x: 1000 }}
          loading={bannerListLoading}
          columns={columns}
          dataSource={bannerList}
          rowKey={(data) => data._id}
          total={bannerListTotal}
        />
      </div>
    </div>
  );
};

export default BannerList;
