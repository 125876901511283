import { useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { Col, Row, Button, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import {
  MARKET_PRODUCT_UNIT_TYPE,
  CURRENCY,
  PROMOTION_TYPE,
  PROMOTION_TYPE_TEXT,
  MARKET_PRODUCT_MODAL_TYPE,
} from "utils/constants";
import { TextInput, ImageUpload, DatePicker, Select } from "components/common";
import EditArticleTypeModal from "components/EditArticleTypeModal";
import {
  addProductMarketTypeRequest,
  deleteProductMarketTypeRequest,
  editProductMarketTypeRequest,
  getProductMarketTypeRequest,
  getMarketProductListRequest,
} from "providers/MarketProductProvider/slice";
import { PlusIcon } from "assets/images";
import "./styles.less";
import i18n from "i18n";
import Helper from "utils/helpers";
import EditMarketProductTypeForm from "./EditMarketProductTypeForm";
import { createProductMarketValidate } from "./validate";

const MarketProductForm = ({
  initialValues,
  onSubmit,
  setMarketProductModal,
  type,
  productMarketTypeList,
  searchObject,
  setSearch,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const marketId = useSelector((state) => _get(state, "store.detail._id"));
  useEffect(() => {
    dispatch(getProductMarketTypeRequest({ marketId, limit: 1000, page: 1 }));
  }, []);

  const formattedProductMarketTypeList = useMemo(
    () =>
      productMarketTypeList.map((productType) => ({
        value: productType._id,
        label: productType.name,
      })),
    [productMarketTypeList]
  );
  const [hasPromotion, setHasPromotion] = useState(
    initialValues.promotion > 0 ||
      initialValues.percentagePromotion > 0 ||
      false
  );
  const [canGoBack, setCanGoBack] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState({});

  const handleAddProductMarketType = () => {
    const newProductType = formikRef.current?.values.newProductType;
    if (!_isEmpty(newProductType)) {
      dispatch(
        addProductMarketTypeRequest({ storeId: marketId, name: newProductType })
      );
      formikRef.current?.setFieldValue("newProductType", "");
    }
  };

  const handleRemoveProductMarketType = (event) => {
    if (canGoBack) {
      setCanGoBack(false);
    }
    const productType = _get(searchObject, "productCategory", []).filter(
      (item) => item !== event.value
    );
    dispatch(
      deleteProductMarketTypeRequest({
        marketId,
        idProductMarketType: [event.value],
        name: event.label,
        searchObject: { ...searchObject, productType },
      })
    );
    setSearch({ ...searchObject, productType });
    if (formikRef.current?.values.productType === event.value) {
      formikRef.current?.setFieldValue("productType", "");
    }
  };

  const handleEditProductMarketType = (event) => {
    dispatch(
      editProductMarketTypeRequest({
        marketId,
        productMarketTypeId: isOpenModal.value,
        name: event.name,
      })
    ).then(() => {
      dispatch(getProductMarketTypeRequest({ marketId, limit: 1000, page: 1 }));
      dispatch(getMarketProductListRequest(searchObject));
      setIsOpenModal({});
    });
  };

  const handleOpenEditTypeModal = (data) => {
    setIsOpenModal(data);
  };

  const formattedInitValues = {
    name: _get(initialValues, "name", ""),
    thumbnail: initialValues.thumbnail
      ? { url: initialValues.thumbnail, objectId: initialValues.thumbnail }
      : null,
    productType: _get(initialValues, "productCategory._id", ""),
    mass: _get(initialValues, "mass", ""),
    unit: _get(initialValues, "unit", ""),
    amount: _get(initialValues, "amount", ""),
    price: _get(initialValues, "price", ""),
    currency: _get(initialValues, "currency", CURRENCY.JPY),
    promotion: _get(initialValues, "promotion", 0),
    percentagePromotion: _get(initialValues, "percentagePromotion", 0),
    discountType:
      initialValues.promotion > 0
        ? PROMOTION_TYPE.CASH
        : PROMOTION_TYPE.PERCENT,
    fromDatePromotion: initialValues.fromDatePromotion
      ? moment(initialValues.fromDatePromotion)
      : null,
    toDatePromotion: initialValues.toDatePromotion
      ? moment(initialValues.toDatePromotion)
      : null,
  };

  const handlePromotionTypeChange = () => {
    formikRef.current?.setFieldValue("promotion", "0");
    formikRef.current?.setFieldValue("percentagePromotion", "0");
  };

  const handleDeletePromotion = () => {
    setHasPromotion(!hasPromotion);
    formikRef.current?.setFieldValue("promotion", "0");
    formikRef.current?.setFieldValue("percentagePromotion", "0");
  };
  return (
    <div className="entertainment-form-wrapper">
      {!_isEmpty(isOpenModal) && (
        <EditArticleTypeModal
          title={i18n.t("updateNameTypeModal.title")}
          data={isOpenModal}
          handleCloseModal={handleOpenEditTypeModal}
        >
          <EditMarketProductTypeForm
            initialValues={{ name: isOpenModal.label }}
            onSubmit={handleEditProductMarketType}
          />
        </EditArticleTypeModal>
      )}
      <Formik
        validateOnMount
        initialValues={formattedInitValues}
        enableReinitialize
        validationSchema={createProductMarketValidate}
        innerRef={formikRef}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values }) => (
          <Form>
            <Row className="market-product-form-container">
              <Col className="market-product-thumbnail">
                <ImageUpload name="thumbnail" />
              </Col>
              <Col className="market-product-form-info">
                <div className="m-title-text">
                  {i18n.t("marketProduct.basicInfomation")}
                </div>
                <Row className="info-row">
                  <Col style={{ width: "100%" }}>
                    <div className="item-title">Tên sản phẩm:</div>
                    <TextInput name="name" size="small" required />
                  </Col>
                </Row>
                <Row className="info-row">
                  <Col span={11}>
                    <div className="item-title">Loại sản phẩm:</div>
                    <Select
                      name="productType"
                      required
                      options={formattedProductMarketTypeList}
                      isRemove
                      handleRemoveItem={handleRemoveProductMarketType}
                      isEditProductType
                      handleEditItem={handleOpenEditTypeModal}
                    />
                  </Col>
                  <Col span={11}>
                    <div className="item-title">Thêm mới loại:</div>
                    <div className="add-new-product-type">
                      <TextInput name="newProductType" size="small" />
                      <img
                        className="plus-icon"
                        src={PlusIcon}
                        alt=""
                        onClick={() => handleAddProductMarketType()}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="info-row">
                  <Col span={11}>
                    <div className="item-title">Khối lượng:</div>
                    <TextInput name="mass" size="small" required />
                  </Col>
                  <Col span={11}>
                    <div className="item-title">Đơn vị:</div>
                    <Select
                      name="unit"
                      required
                      options={MARKET_PRODUCT_UNIT_TYPE}
                    />
                  </Col>
                </Row>
                <Row className="info-row">
                  <Col style={{ width: "100%" }}>
                    <div className="item-title">Số lượng:</div>
                    <TextInput name="amount" size="small" required />
                  </Col>
                </Row>
                <Row className="info-row">
                  <Col span={11}>
                    <div className="item-title">Giá bán:</div>
                    <TextInput name="price" size="small" required />
                  </Col>
                  <Col span={11}>
                    <div className="item-title">Đơn vị:</div>
                    <Select
                      name="currency"
                      required
                      disabled
                      options={Helper.convertObjectToOptions(CURRENCY)}
                    />
                  </Col>
                </Row>
                <Row className="promotion-info-row">
                  <Switch
                    checked={hasPromotion}
                    disabled={Number(values.price) === 0}
                    onClick={() => handleDeletePromotion()}
                  />
                  <div className="promotion"> Khuyến mãi</div>
                </Row>
                {hasPromotion && (
                  <Row>
                    <Row className="info-row">
                      <Col span={11}>
                        <div className="item-title">Khuyến mãi:</div>
                        <Select
                          allowClear
                          name="discountType"
                          onChange={handlePromotionTypeChange}
                          options={Helper.convertObjectToOptions(
                            PROMOTION_TYPE_TEXT
                          )}
                          disabled={Number(values.price) === 0}
                        />
                      </Col>
                      <Col span={11}>
                        <div className="item-title">Giá Khuyến mãi:</div>
                        <TextInput
                          name={
                            formikRef.current?.values.discountType ===
                            PROMOTION_TYPE.CASH
                              ? "promotion"
                              : "percentagePromotion"
                          }
                          size="small"
                          required
                          disabled={Number(values.price) === 0}
                        />
                      </Col>
                    </Row>
                    <Row className="info-row">
                      <Col span={11}>
                        <div className="item-title">Từ ngày:</div>
                        <DatePicker
                          name="fromDatePromotion"
                          format="DD/MM/YYYY"
                          required
                          size="small"
                          disabled={
                            !(
                              values.promotion > 0 ||
                              values.percentagePromotion > 0
                            ) || Number(values.price) === 0
                          }
                        />
                      </Col>
                      <Col span={11}>
                        <div className="item-title">Đến ngày:</div>
                        <DatePicker
                          name="toDatePromotion"
                          format="DD/MM/YYYY"
                          required
                          size="small"
                          disabled={
                            !(
                              values.promotion > 0 ||
                              values.percentagePromotion > 0
                            ) || Number(values.price) === 0
                          }
                        />
                      </Col>
                    </Row>
                  </Row>
                )}
                {type === MARKET_PRODUCT_MODAL_TYPE.CREATE ? (
                  <Row className="add-product-actions">
                    <Button type="primary" size="small" onClick={handleSubmit}>
                      TẠO SẢN PHẨM
                    </Button>
                  </Row>
                ) : (
                  <Row className="save-back-product-actions">
                    <Button type="primary" size="small" onClick={handleSubmit}>
                      LƯU
                    </Button>
                    <Button
                      disabled={!canGoBack}
                      size="small"
                      onClick={() =>
                        dispatch(
                          setMarketProductModal({
                            visible: true,
                            data: initialValues,
                            type: MARKET_PRODUCT_MODAL_TYPE.DETAIL,
                          })
                        )
                      }
                    >
                      TRỞ LẠI
                    </Button>
                  </Row>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

MarketProductForm.propTypes = {
  initialValues: PropTypes.object,
  onTagChange: PropTypes.func,
  handleTagAllChange: PropTypes.func,
  tagAll: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setMarketProductModal: PropTypes.func,
  type: PropTypes.string,
  productMarketTypeList: PropTypes.array,
  searchObject: PropTypes.object,
  setSearch: PropTypes.func,
};
export default MarketProductForm;
