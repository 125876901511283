import { memo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Table as AntTable, Empty } from "antd";
import i18n from "i18n";
import { useQuery } from "hooks";
import LoadingIndicator from "components/LoadingIndicator";
import "./style.less";

const Table = ({ totalText, loading, total, ...props }) => {
  const [searchQuery, setSearchQuery] = useQuery();
  const { order, orderBy, limit, page } = searchQuery;
  let columns = [];
  if (order && orderBy && !_.isEmpty(props.columns)) {
    columns = props.columns.map((col) => {
      if (col.key === orderBy) {
        return { ...col, defaultSortOrder: order };
      }
      return col;
    });
  } else {
    columns = props.columns;
  }

  const handleTableChange = (pagination, filter, sorter) => {
    setSearchQuery({
      ...searchQuery,
      ...filter,
      page: pagination.current,
      limit: pagination.pageSize,
      order: sorter.order || searchQuery.order,
      orderBy: order ? _.get(sorter, "column.key") : "createdAt",
    });
  };

  return (
    <>
      <AntTable
        {...props}
        loading={{
          spinning: loading,
          indicator: <LoadingIndicator />,
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={i18n.t("errors.noData")}
            />
          ),
        }}
        pagination={{
          pageSize: limit,
          total,
          showTotal: (_total, range) => `${range[0]}-${range[1]} / ${_total}`,
          current: Number(page),
        }}
        columns={columns}
        onChange={handleTableChange}
      />
    </>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  search: PropTypes.object,
  loading: PropTypes.bool,
  totalText: PropTypes.string,
  total: PropTypes.number,
};
export default memo(Table);
