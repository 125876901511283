/* eslint-disable react/display-name */

import i18n from "i18n";
import { Col, Row, Tooltip } from "antd";
import ReportPermissionTooltip from "components/ReportPermissionTooltip";
import StatusBlock from "components/ReportPermissionTooltip/StatusBlock";

const Columns = {};

const storeType = {
  restaurants: "Nhà hàng",
  markets: "Siêu thị",
  entertainments: "Giải trí",
  cosmetics: "Làm đẹp",
};

const renderDataValue = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

const renderReportType = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  switch (value) {
    case "STORE_DOES_NOT_EXIST":
      return (
        <span style={{ color: "red" }}>
          {i18n.t("reportManager.STORE_DOES_NOT_EXIST") ||
            i18n.t("general.none")}
        </span>
      );
    case "WRONG_ADDRESS":
      return (
        <span style={{ color: "orange" }}>
          {i18n.t("reportManager.WRONG_ADDRESS") || i18n.t("general.none")}
        </span>
      );
    case "WRONG_PHONE":
      return (
        <span style={{ color: "blue" }}>
          {i18n.t("reportManager.WRONG_PHONE") || i18n.t("general.none")}
        </span>
      );
    case "REQUEST_TO_BECOME_OWNER":
      return (
        <span style={{ color: "green" }}>
          {i18n.t("reportManager.REQUEST_TO_BECOME_OWNER") ||
            i18n.t("general.none")}
        </span>
      );
    case "OTHER":
      return (
        <span style={{ color: "purple" }}>
          {i18n.t("reportManager.OTHER") || i18n.t("general.none")}
        </span>
      );
    default:
      return <span style={style}>{i18n.t("general.none")}</span>;
  }
};

Columns._id = {
  dataIndex: "_id",
  key: "_id",
  title: "#",
  render: (_id) => renderDataValue(`#${_id}`),
  width: 195,
};
Columns.reportType = {
  title: i18n.t("reportManager.reportType"),
  dataIndex: "type",
  key: "reportType",
  render: (type) => renderReportType(type),
  width: 200,
};
Columns.name = {
  dataIndex: ["storeDetail", "name"],
  title: i18n.t("reportManager.storeName"),
  width: 150,
  render: (name) => renderDataValue(name),
};

Columns.type = {
  title: i18n.t("reportManager.storeType"),
  dataIndex: ["storeDetail", "type"],
  render: (type) => renderDataValue(storeType[type]),
  width: 200,
};

Columns.phone = {
  title: i18n.t("reportManager.phone"),
  dataIndex: ["storeDetail", "phone"],
  render: (phone) => renderDataValue(phone),
  width: 200,
};
Columns.address = {
  title: i18n.t("reportManager.address"),
  dataIndex: ["storeDetail", "address"],
  render: (address) => renderDataValue(address),
  width: 200,
};
Columns.content = {
  title: i18n.t("reportManager.content"),
  dataIndex: "content",
  key: "content",
  render: (content) => renderDataValue(content),
  width: 200,
};

Columns.status = {
  title: i18n.t("reportManager.status"),
  dataIndex: "status",
  key: "status",
  width: 80,
  render: (status = []) =>
    renderDataValue(
      <Row>
        <Tooltip
          title={<ReportPermissionTooltip active={status} />}
          color="white"
        >
          <Col style={{ marginRight: 4 }}>
            <StatusBlock status={status} />
          </Col>
        </Tooltip>
      </Row>
    ),
};

export default Columns;
