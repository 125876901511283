import { notification } from "antd";
import { call } from "redux-saga/effects";
import _get from "lodash/get";
// import Helper from 'utils/helpers';
import { ERRORS } from "utils/constants";
import i18n from "i18n";

notification.config({
  placement: "bottomRight",
  duration: 3,
});

const errorNotification = (error) => {
  notification.error({
    message: i18n.t("alert.error"),
    description: _get(error, "message") || _get(error, "statusText"),
  });
};

export const safe = (
  saga,
  errorHandler,
  customAlert,
  showAlert = true,
  ...args
) =>
  // eslint-disable-next-line func-names
  function* (action) {
    try {
      yield call(saga, ...args, action);
    } catch (err) {
      if (errorHandler) {
        yield* errorHandler(err);
      }
      const errorCode = _get(err, "code");

      if (errorCode === ERRORS.INVALID_SESSION_TOKEN.code) {
        // Helper.removeAuthToken();
        // window.location = '/';
      }
      if (showAlert) {
        if (err === i18n.t("alert.noNetwork")) {
          yield call(errorNotification, err);
        } else if (errorCode === ERRORS.EXISTED_EMAIL.code) {
          yield call(errorNotification, i18n.t("alert.existedEmail"));
        }
      }
    } finally {
      /**/
    }
  };
