import PropTypes from "prop-types";
import "./styles.less";

const typeClassName = {
  news: "news-block",
  supermarket: "supermarket-block",
  markets: "supermarket-block",
  jobs: "jobs-block",
  transport: "transport-block",
  tourist: "tourist-block",
  visa: "visa-block",
  tutorial: "tutorial-block",
  rent: "rent-block",
  restaurants: "restaurant-block",
  restaurant: "restaurant-block",
  entertainment: "entertainment-block",
  entertainments: "entertainment-block",
  fonehouse: "fonehouse-block",
  cosmetics: "cosmetic-block",
  cosmetic: "cosmetic-block",
  car_and_old_stuff: "car-and-old-stuff-block",
  other: "other",
};
const TypeBlock = ({ type }) => {
  console.log(type, "type");
  return <div className={`type-block-wrapper ${typeClassName[type]}`} />;
};

TypeBlock.propTypes = {
  type: PropTypes.string.isRequired,
};

export default TypeBlock;
