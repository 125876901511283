import { useRef } from "react";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import i18n from "i18n";
import { Button } from "antd";
import _get from "lodash/get";

import { TextInput, ImageUploadContainer } from "components/common";
import BannerImage from "components/common/Form/ImageUploadContainer/BannerImage";
import { createBannerValidate } from "./validate";
import "./styles.less";

const StoreBannerDetail = ({
  initialValues,
  handleSubmit,
  handleCancel,
  isEditBanner = false,
}) => {
  const formikRef = useRef(null);

  return (
    <div className="store-banner-detail-wrapper">
      <div className="store-banner-actions">
        <div>
          <Button onClick={handleCancel} size="small">
            {i18n.t("banner.cancelBtn")}
          </Button>
          <Button
            // disabled={!formikRef?.current?.isValid}
            onClick={() => formikRef?.current?.submitForm()}
            type="primary"
            size="small"
          >
            {isEditBanner
              ? i18n.t("banner.updateBanner")
              : i18n.t("banner.createBanner")}
          </Button>
        </div>
      </div>
      <Formik
        validateOnMount
        initialValues={{
          ...initialValues,
          link: _get(initialValues, "link", ""),
          imageUrl: { url: _get(initialValues, "imageUrl", "") },
        }}
        enableReinitialize
        validationSchema={createBannerValidate}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {() => (
          <Form>
            <ImageUploadContainer name="imageUrl">
              <BannerImage aspect={3 / 1} />
            </ImageUploadContainer>
            <TextInput
              inputType="text"
              autoSize={{ minRows: 11, maxRows: 11 }}
              label={i18n.t("banner.fieldURL")}
              name="link"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

StoreBannerDetail.propTypes = {
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  isEditBanner: PropTypes.bool,
};

export default StoreBannerDetail;
