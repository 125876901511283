import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import _get from "lodash/get";
import _set from "lodash/set";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import Helper from "utils/helpers";
import moment from "moment";
import {
  getEntertainmentProductListRequest,
  getEntertainmentProductListSuccess,
  stopLoading,
  updateEntertainmentProductRequest,
  updateEntertainmentProductSuccess,
  getProductEntertainmentTypeRequest,
  addProductEntertainmentTypeRequest,
  createProductEntertainmentRequest,
  updateProductEntertainmentRequest,
  deleteProductEntertainmentRequest,
  getProductEntertainmentTypeSuccess,
  addProductEntertainmentTypeSuccess,
  createProductEntertainmentSuccess,
  updateProductEntertainmentSuccess,
  deleteProductEntertainmentSuccess,
  deleteProductEntertainmentTypeRequest,
  deleteProductEntertainmentTypeSuccess,
  editProductEntertainmentTypeRequest,
  editProductEntertainmentTypeSuccess,
  setTagProductEntertainmentRequest,
  setTagProductEntertainmentSuccess,
} from "./slice";

function* getEntertainmentProductList(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "sortName",
    "sortMass",
    "sortPrice",
    "sortPromotionPrice",
    "productType",
    "entertainmentId",
    "keyword",
    "productTypeIds",
  ]);
  if (_isEmpty(params.productTypeIds)) {
    _set(params, "productTypeIds", []);
  }
  const data = yield call(
    request,
    "api/fonehouse/entertainment-manager/entertainment/product/filter",
    params,
    { method: "POST" }
  );
  yield put(getEntertainmentProductListSuccess(data));
}

function* updateEntertainmentProduct(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "sortName",
    "productType",
    "entertainmentId",
  ]);
  const { data } = yield call(
    request,
    "api/fonehouse/product-entertainment-manager/product-entertainment",
    params,
    { method: "PUT" }
  );
  yield put(updateEntertainmentProductSuccess(data));
}

export function* getProductEntertainmentType(action) {
  const params = _pick(action.payload, [
    "entertainmentId",
    "limit",
    "page",
    "keyword",
  ]);
  const data = yield call(
    request,
    "api/fonehouse/entertainment-manager/entertainment/product-type/get-all-by-entertainment-id",
    params,
    { method: "GET" }
  );
  yield put(getProductEntertainmentTypeSuccess(data.listType));
}

export function* addProductEntertainmentType(action) {
  const params = _pick(action.payload, ["name", "entertainmentId"]);
  const data = yield call(
    request,
    "api/fonehouse/entertainment-manager/entertainment/product-type/create",
    params,
    { method: "POST" }
  );
  yield put(addProductEntertainmentTypeSuccess(data));
  Helper.toastr(
    `Loại:${_get(data, "type.name")}`,
    "Thêm loại sản phẩm thành công",
    "success"
  );
}

export function* deleteProductEntertainmentType(action) {
  const params = _pick(action.payload, ["id", "entertainmentId"]);
  yield call(
    request,
    `api/fonehouse/entertainment-manager/entertainment/product-type/delete/${params.id}`,
    {},
    { method: "DELETE" }
  );
  yield put(deleteProductEntertainmentTypeSuccess(params));
  yield put(
    getProductEntertainmentTypeRequest({
      entertainmentId: params.entertainmentId,
      limit: 1000,
      page: 1,
    })
  );
  const searchParams = _get(action, "payload.searchObject");
  yield put(getEntertainmentProductListRequest(searchParams));
  Helper.toastr(
    `Loại:${_get(action, "payload.name")}`,
    "Xóa loại sản phẩm thành công",
    "success"
  );
}

function* createProductEntertainment(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "name",
    "currency",
    "percentagePromotion",
    "promotion",
    "price",
    "productType",
    "element",
    "images",
    "thumbnail",
    "fromDatePromotion",
    "toDatePromotion",
    "amount",
    "entertainmentId",
  ]);
  if (params.thumbnail) {
    _set(params, "thumbnail", params.thumbnail.url);
  }
  if (_isEmpty(params.fromDatePromotion)) {
    _set(params, "fromDatePromotion", moment());
  }
  if (_isEmpty(params.toDatePromotion)) {
    _set(params, "toDatePromotion", moment());
  }
  _set(params, "inputPrice", 0);
  _set(params, "images", []);
  if (Number(params.price) === 0) {
    _set(params, "promotion", 0);
    _set(params, "percentagePromotion", 0);
  }
  const { data } = yield call(
    request,
    "api/fonehouse/entertainment-manager/entertainment/product/create",
    params,
    { method: "POST" }
  );
  yield put(createProductEntertainmentSuccess(data, meta));
  Helper.toastr(
    `ID:${_get(data, "productEntertainmentId")}`,
    "Tạo sản phẩm thành công",
    "success"
  );
}

function* updateProductEntertainment(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "name",
    "currency",
    "percentagePromotion",
    "promotion",
    "price",
    "productType",
    "element",
    "images",
    "thumbnail",
    "fromDatePromotion",
    "toDatePromotion",
    "amount",
    "entertainmentId",
    "productId",
  ]);
  if (params.thumbnail) {
    _set(params, "thumbnail", params.thumbnail.url);
  }
  if (params.promotion === 0 && params.percentagePromotion === 0) {
    _set(params, "fromDatePromotion", moment());

    _set(params, "toDatePromotion", moment());
  }
  _set(params, "inputPrice", 0);
  _set(params, "images", []);
  if (Number(params.price) === 0) {
    _set(params, "promotion", 0);
    _set(params, "percentagePromotion", 0);
  }
  yield call(
    request,
    "api/fonehouse/entertainment-manager/entertainment/product/update",
    params,
    { method: "PUT" }
  );
  yield put(
    updateProductEntertainmentSuccess(
      { ...params, _id: params.productId },
      meta
    )
  );
  Helper.toastr(
    `ID:${_get(action, "payload.productEntertainmentId")}`,
    "Cập nhật sản phẩm thành công",
    "success"
  );
}

function* deleteProductEntertainment(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["ids"]);
  const productEntertainmentIds = _get(
    action,
    "payload.productEntertainmentIds",
    []
  );
  const { data } = yield call(
    request,
    "api/fonehouse/entertainment-manager/entertainment/product/delete",
    params,
    { method: "DELETE_BODY" }
  );
  yield put(deleteProductEntertainmentSuccess(data, meta));
  Helper.toastr(
    `ID:${productEntertainmentIds.join(", ")}`,
    "Xóa sản phẩm thành công",
    "success"
  );
}

export function* editProductEntertainmentType(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "productTypeEntertainmentId",
    "name",
    "entertainmentId",
  ]);
  const { data } = yield call(
    request,
    "api/fonehouse/entertainment-manager/entertainment/product-type/update",
    params,
    { method: "PUT" }
  );
  yield put(editProductEntertainmentTypeSuccess(data, meta));
  Helper.toastr(
    `Loại: ${_get(action, "payload.name")}`,
    "Cập nhật loại sản phẩm thành công",
    "success"
  );
}

function* setTagProductEntertainment(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idProducts", "tag"]);
  yield call(
    request,
    "api/fonehouse/entertainment-manager/entertainment/product/set-tag",
    { productIds: params.idProducts, tag: params.tag },
    { method: "PUT" }
  );
  yield put(setTagProductEntertainmentSuccess({}, meta));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchEntertainmentProduct() {
  yield takeEvery(
    getEntertainmentProductListRequest.type,
    safe(getEntertainmentProductList, onError)
  );
  yield takeLeading(
    getProductEntertainmentTypeRequest.type,
    safe(getProductEntertainmentType, onError)
  );
  yield takeLeading(
    updateEntertainmentProductRequest.type,
    safe(updateEntertainmentProduct, onError)
  );
  yield takeLeading(
    addProductEntertainmentTypeRequest.type,
    safe(addProductEntertainmentType, onError)
  );
  yield takeLeading(
    createProductEntertainmentRequest.type,
    safe(createProductEntertainment, onError)
  );
  yield takeLeading(
    updateProductEntertainmentRequest.type,
    safe(updateProductEntertainment, onError)
  );
  yield takeLeading(
    deleteProductEntertainmentRequest.type,
    safe(deleteProductEntertainment, onError)
  );
  yield takeLeading(
    deleteProductEntertainmentTypeRequest.type,
    safe(deleteProductEntertainmentType, onError)
  );
  yield takeLeading(
    editProductEntertainmentTypeRequest.type,
    safe(editProductEntertainmentType, onError)
  );
  yield takeLeading(
    setTagProductEntertainmentRequest.type,
    safe(setTagProductEntertainment, onError)
  );
}
