import { put, call, takeLeading } from "redux-saga/effects";
import _pick from "lodash/pick";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import { safe } from "providers/GeneralProvider/saga";
import {
  createSimRequest,
  createSimSuccess,
  updateSimRequest,
  updateSimSuccess,
  getSimListRequest,
  getSimListSuccess,
  getSimDetailRequest,
  getSimDetailSuccess,
  deleteSimRequest,
  deleteSimSuccess,
  restoreSimRequest,
  restoreSimSuccess,
  getSimTypesRequest,
  getSimTypesSuccess,
  createSimTypeRequest,
  createSimTypeSuccess,
  updateSimTypeRequest,
  updateSimTypeSuccess,
  deleteSimTypeRequest,
  deleteSimTypeSuccess,
  changeSimStatusRequest,
  changeSimStatusSuccess,
  getSimRegistrationDetailRequest,
  getSimRegistrationDetailSuccess,
  stopLoading,
  getSimPostDetailSuccess,
  getSimPostDetailRequest,
  updateSimPostSuccess,
  updateSimPostRequest,
  createConnectivityProductPostSuccess,
  createConnectivityProductPostRequest,
  getInternetRegistrationDetailSuccess,
  getWifiRegistrationDetailSuccess,
  getWifiRegistrationDetailRequest,
  getInternetRegistrationDetailRequest,
  deleteSimPostRequest,
  deleteSimPostSuccess,
  restoreSimPostSuccess,
  restoreSimPostRequest,
  setReadWifiRecordSuccess,
  setReadInternetRecordSuccess,
  setReadInternetRecordRequest,
  setReadWifiRecordRequest,
  updateCustomFieldRequest,
  updateCustomFieldSuccess,
  getUnReadNumberSimTabSuccess,
  getUnReadNumberSimTabRequest,
  updateRegisteredSimRequest,
  updateRegisteredSimSuccess,
} from "providers/SimProvider/slice";
import Helper from "utils/helpers";
import request from "utils/api";

function* createSim({ meta, payload }) {
  const params = _pick(payload, [
    "name",
    "type",
    "price",
    "initialPrice",
    "connectionFee",
    "initialConnectionFee",
    "description",
    "connectionFeeDiscount",
    "priceDiscount",
  ]);
  const { data } = yield call(request, "api/sims", params, { method: "POST" });
  yield put(createSimSuccess(data, meta));
}

function* updateSim({ meta, payload }) {
  const params = _pick(payload, [
    "name",
    "type",
    "price",
    "initialPrice",
    "connectionFee",
    "initialConnectionFee",
    "description",
    "connectionFeeDiscount",
    "priceDiscount",
  ]);
  const { data } = yield call(request, `api/sims/${payload._id}`, params, {
    method: "PUT",
  });
  yield put(updateSimSuccess(data, meta));
  Helper.toastr(`ID:${payload._id}`, "Cập nhật sim thành công", "success");
}

function* getSimList({ meta, payload }) {
  const params = _pick(payload, [
    "page",
    "limit",
    "keyword",
    "price",
    "type",
    "status",
    "isDeleted",
    "customOrderId",
    "phone",
  ]);
  let apiPath = "api/sims";

  if (_isEmpty(params.status)) {
    params.status = [];
  }
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  if (params.type === "DELETED") {
    apiPath = "api/connectivity-product/posts";
    params.isDeleted = true;
  }
  if (params.type === "SIM_DELETED") {
    params.isDeleted = true;
  }
  if (params.type === "REQUESTED") {
    apiPath = "api/sim-registrations";
  }
  if (params.type === "WIFI") {
    params.status = ["NEW", "PROCESSING", "CANCELLED", "DONE"];
    apiPath = "api/connectivity-product/register/wifi-hotspot";
  }
  if (params.type === "INTERNET") {
    apiPath = "api/connectivity-product/register/internet-package";
    params.status = ["NEW", "PROCESSING", "CANCELLED", "DONE"];
  }
  if (params.type === "POST") {
    apiPath = "api/connectivity-product/posts";
    params.category = "SIMCARD";
  }
  if (params.type === "SIM_DATA_SOFT_BANK") {
    apiPath = "api/sim-registrations";
    params.carrier = "SOFTBANK";
  }
  delete params.type;
  const { data } = yield call(request, apiPath, params, {
    method: "GET",
  });
  yield put(getSimListSuccess(data, meta));
}

function* getSimDetail({ meta, payload }) {
  const { data } = yield call(
    request,
    `api/sims/${payload._id}`,
    {},
    { method: "GET" }
  );
  yield put(getSimDetailSuccess(data, meta));
}

function* deleteSim(action) {
  const { data } = yield call(
    request,
    `api/sims/${_get(action, "payload._id")}`,
    {},
    { method: "DELETE" }
  );
  Helper.toastr(
    `ID:${_get(action, "payload._id")}`,
    "Xóa sim thành thông",
    "success"
  );
  yield put(deleteSimSuccess(data, action.meta));
}

function* restoreSim(action) {
  const { data } = yield call(
    request,
    `api/sims/restore/${_get(action, "payload._id")}`,
    {},
    { method: "PUT" }
  );
  Helper.toastr(
    `ID:${_get(action, "payload._id")}`,
    "Khôi phục sim thành thông",
    "success"
  );
  yield put(restoreSimSuccess(data, action.meta));
}

function* getSimTypes({ payload, meta }) {
  const { data } = yield call(request, "api/simTypes", payload, {
    method: "GET",
  });
  yield put(getSimTypesSuccess(data, meta));
}

function* createSimType({ payload, meta }) {
  const { data } = yield call(request, "api/simTypes", payload, {
    method: "POST",
  });
  yield put(createSimTypeSuccess(data, meta));
  Helper.toastr(
    `ID:${_get(payload, "_id")}`,
    "Tạo loại sim thành công",
    "success"
  );
}

function* updateSimType({ payload, meta }) {
  const { data } = yield call(
    request,
    `api/simTypes/${_get(payload, "_id")}`,
    {
      name: _get(payload, "name"),
    },
    {
      method: "PUT",
    }
  );
  yield put(updateSimTypeSuccess(data, meta));
  Helper.toastr(
    `ID:${_get(payload, "_id")}`,
    "Cập nhật loại sim thành công",
    "success"
  );
}

function* deleteSimType({ payload, meta }) {
  const { data } = yield call(
    request,
    `api/simTypes/${_get(payload, "_id")}`,
    {},
    { method: "DELETE" }
  );
  yield put(deleteSimTypeSuccess(data, meta));
  Helper.toastr(
    `ID:${_get(payload, "_id")}`,
    "Xóa loại sim thành thông",
    "success"
  );
}
function* getUnReadNumberSimTab() {
  const { data } = yield call(
    request,
    `api/connectivity-product/statistics`,
    {},
    { method: "GET" }
  );
  yield put(getUnReadNumberSimTabSuccess(data));
}
function* changeSimStatus({ payload, meta }) {
  const { data } = yield call(
    request,
    `api/sim-registrations/${_get(payload, "_id")}/status`,
    {
      status: _get(payload, "status"),
    },
    {
      method: "PUT",
    }
  );
  yield put(changeSimStatusSuccess(data, meta));
}

function* updateCustomField({ payload, meta }) {
  const { params } = payload;
  const { data } = yield call(
    request,
    `api/sim-registrations/${_get(payload, "id")}/custom-order`,

    params,

    {
      method: "PUT",
    }
  );
  yield put(updateCustomFieldSuccess(data, meta));
}

function* getSimRegistrationDetail({ payload, status }) {
  const { data } = yield call(
    request,
    `api/sim-registrations/${_get(payload, "_id")}`,
    {},
    { method: "GET" }
  );
  yield put(getSimRegistrationDetailSuccess(data, status));
}

function* getInternetRegistrationDetail({ payload, status }) {
  const { data } = yield call(
    request,
    `api/connectivity-product/internet-package/${_get(payload, "_id")}`,
    {},
    { method: "GET" }
  );
  yield put(getInternetRegistrationDetailSuccess(data, status));
}

function* getWifiRegistrationDetail({ payload, status }) {
  const { data } = yield call(
    request,
    `api/connectivity-product/wifi-hotspot/${_get(payload, "_id")}`,
    {},
    { method: "GET" }
  );
  yield put(getWifiRegistrationDetailSuccess(data, status));
}

function* getSimPostDetail({ payload, status }) {
  const { data } = yield call(
    request,
    `api/connectivity-product/posts/${_get(payload, "idPost")}`,
    {},
    { method: "GET" }
  );
  yield put(getSimPostDetailSuccess(data, status));
}
function* createSimPost(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "description",
    "title",
    "thumbnail",
    "content",
  ]);
  params.description = params.title;
  params.category = "SIMCARD";
  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  const data = yield call(request, `api/connectivity-product/posts`, params, {
    method: "POST",
  });
  yield put(createConnectivityProductPostSuccess(data, meta));
}
function* updateSimPost(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "type",
    "title",
    "thumbnail",
    "content",
    "description",
    "image",
    "thumbnail",
  ]);
  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  params.description = params.title;
  params.category = "SIMCARD";
  const result = yield call(
    request,
    `api/connectivity-product/posts/${action.payload._id}`,
    params,
    { method: "PUT" }
  );
  yield put(updateSimPostSuccess(result, meta));
  Helper.toastr("Cập nhật bài viết thành công", "Thành công", "success");
}

function* restoreSimPost(action) {
  const { data } = yield call(
    request,
    `api/connectivity-product/posts/restore/${_get(action, "payload._id")}`,
    {},
    { method: "PUT" }
  );
  Helper.toastr(
    `ID:${_get(action, "payload._id")}`,
    "Khôi phục bài đăng thành thông",
    "success"
  );
  yield put(restoreSimPostSuccess(data, action.meta));
}

function* deleteSimPost(action) {
  const { data } = yield call(
    request,
    `api/connectivity-product/posts/${_get(action, "payload.postId")}`,
    {},
    { method: "DELETE" }
  );
  Helper.toastr(
    `ID:${_get(action, "payload.postId")}`,
    "Xóa bài đăng thành thông",
    "success"
  );
  yield put(deleteSimPostSuccess(data, action.meta));
}
function* setReadWifiRecord(action) {
  const { meta } = action;
  const idPost = _get(action, "payload.idPost");
  yield call(
    request,
    `/api/connectivity-product/wifi-hotspot/${idPost}/read`,
    undefined,
    { method: "PATCH" }
  );
  yield put(setReadWifiRecordSuccess(meta));
}
function* setReadInternetRecord(action) {
  const { meta } = action;
  const idPost = _get(action, "payload.idPost");
  yield call(
    request,
    `api/connectivity-product/internet-package/${idPost}/read`,
    undefined,
    { method: "PATCH" }
  );
  yield put(setReadInternetRecordSuccess(meta));
}
function* onError() {
  yield put(stopLoading());
}

function* handleUpdateRegisteredSim(action) {
  try {
    const {
      simRegistrationId,
      name,
      customOrderId,
      postalCode,
      alienRegistrationAddress,
      deliveryAddress,
      email,
      transactionType,
      prefixPhoneNumber,
      phoneNumber,
      facebookURL,
      alienRegistrationFrontImages,
      alienRegistrationBackImages,
      alienRegistrationsWithFaceImages,
      callback,
    } = action.payload;
    const endpoint = `/api/sim-registrations/${simRegistrationId}`;
    const params = {
      name,
      customOrderId,
      postalCode,
      alienRegistrationAddress,
      deliveryAddress,
      email,
      transactionType,
      prefixPhoneNumber,
      phoneNumber,
      facebookURL,
      alienRegistrationFrontImages,
      alienRegistrationBackImages,
      alienRegistrationsWithFaceImages,
    };
    yield call(request, endpoint, params, { method: "PUT" });
    yield put(updateRegisteredSimSuccess());
    yield callback && callback();
  } catch (error) {
    yield put(stopLoading());
  }
}

export default function* watchSim() {
  yield takeLeading(
    getUnReadNumberSimTabRequest.type,
    safe(getUnReadNumberSimTab, onError)
  );

  yield takeLeading(updateSimPostRequest.type, safe(updateSimPost, onError));
  yield takeLeading(
    updateCustomFieldRequest.type,
    safe(updateCustomField, onError)
  );

  yield takeLeading(
    createConnectivityProductPostRequest.type,
    safe(createSimPost, onError)
  );

  yield takeLeading(createSimRequest.type, safe(createSim, onError));
  yield takeLeading(getSimListRequest.type, safe(getSimList, onError));
  yield takeLeading(
    getSimPostDetailRequest.type,
    safe(getSimPostDetail, onError)
  );
  yield takeLeading(
    setReadInternetRecordRequest.type,
    safe(setReadInternetRecord, onError)
  );
  yield takeLeading(
    setReadWifiRecordRequest.type,
    safe(setReadWifiRecord, onError)
  );

  yield takeLeading(deleteSimPostRequest.type, safe(deleteSimPost, onError));
  yield takeLeading(restoreSimPostRequest.type, safe(restoreSimPost, onError));

  yield takeLeading(updateSimRequest.type, safe(updateSim, onError));
  yield takeLeading(getSimDetailRequest.type, safe(getSimDetail, onError));
  yield takeLeading(deleteSimRequest.type, safe(deleteSim, onError));
  yield takeLeading(restoreSimRequest.type, safe(restoreSim, onError));
  yield takeLeading(getSimTypesRequest.type, safe(getSimTypes, onError));
  yield takeLeading(createSimTypeRequest.type, safe(createSimType, onError));
  yield takeLeading(updateSimTypeRequest.type, safe(updateSimType, onError));
  yield takeLeading(deleteSimTypeRequest.type, safe(deleteSimType, onError));
  yield takeLeading(
    changeSimStatusRequest.type,
    safe(changeSimStatus, onError)
  );
  yield takeLeading(
    getSimRegistrationDetailRequest.type,
    safe(getSimRegistrationDetail, onError)
  );
  yield takeLeading(
    getWifiRegistrationDetailRequest.type,
    safe(getWifiRegistrationDetail, onError)
  );
  yield takeLeading(
    getInternetRegistrationDetailRequest.type,
    safe(getInternetRegistrationDetail, onError)
  );
  yield takeLeading(
    updateRegisteredSimRequest.type,
    safe(handleUpdateRegisteredSim, onError)
  );
}
