import { put, call, takeLatest } from "redux-saga/effects";
import request from "utils/api";
import { safe } from "providers/GeneralProvider/saga";
import {
  getLiquidWithViegoListRequest,
  getLiquidWithViegoListSuccess,
  requestFail,
  changeStateOfLiquidWithViegoRequest,
} from "providers/LiquidWithViegoProvider/slice";

const BASE_ENDPOINT = "/api/reward/withdraw-requests";
function* handleGetLiquidWithViegoList(action) {
  const { page, limit } = action.payload;
  const endpoint = `${BASE_ENDPOINT}?limit=${limit}&page=${page}`;
  const response = yield call(request, endpoint, undefined, { method: "GET" });
  yield put(getLiquidWithViegoListSuccess(response));
}

function* handleChangeStateOfLiquidWithViego(action) {
  const { requestIds, status, callback } = action.payload;
  for (let index = 0; index < requestIds.length; index += 1) {
    const requestId = requestIds[index];
    const endpoint = `${BASE_ENDPOINT}/${requestId}`;
    const params = { status };
    yield call(request, endpoint, params, { method: "PUT" });
  }
  yield callback && callback();
}

function* onError() {
  yield put(requestFail());
}

export default function* watchLiquidWithViego() {
  yield takeLatest(
    getLiquidWithViegoListRequest.type,
    safe(handleGetLiquidWithViegoList, onError)
  );
  yield takeLatest(
    changeStateOfLiquidWithViegoRequest.type,
    safe(handleChangeStateOfLiquidWithViego, onError)
  );
}
