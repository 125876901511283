import { Tag } from "antd";
import PropTypes from "prop-types";
import { BILL_TAG, BILL_TAG_TEXT } from "utils/constants";
import "./styles.less";

const tagColor = {
  [BILL_TAG.CANCEL]: "error",
  [BILL_TAG.SUCCESS]: "success",
  [BILL_TAG.NEW]: "blue",
  [BILL_TAG.SHIPPED]: "gold",
};

const TagBillDetail = ({ status = "new" }) => (
  <>
    <Tag className="bill-tag" color={tagColor[status]}>
      {BILL_TAG_TEXT[status]}
    </Tag>
  </>
);

TagBillDetail.propTypes = {
  status: PropTypes.string.isRequired,
};
export default TagBillDetail;
