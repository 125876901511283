import { memo } from "react";
import { Layout, Drawer } from "antd";
import PropTypes from "prop-types";
import { useViewportSize } from "hooks";

import { VIEW_PORT_SIZE } from "utils/constants";
import NavBar from "./Navbar";
import SideMenu from "./SideMenu";
import "./styles.less";

const DashboardLayout = ({
  collapseMenu,
  toggleCollapseMenu,
  currentUser,
  menu,
  children,
}) => {
  const size = useViewportSize();
  const isDesktopViewport = size.width > VIEW_PORT_SIZE.MOBILE;

  return (
    <Layout className="layout">
      {isDesktopViewport ? (
        <SideMenu collapseMenu={collapseMenu} width={256} />
      ) : (
        <Drawer
          placement="left"
          closable={false}
          onClose={toggleCollapseMenu}
          bodyStyle={{ padding: 0 }}
          visible={collapseMenu}
          key="left"
        >
          <SideMenu />
        </Drawer>
      )}
      <Layout style={{ minHeight: "100vh" }}>
        <NavBar
          collapseMenu={collapseMenu}
          toggleCollapseMenu={toggleCollapseMenu}
          menu={menu}
          currentUser={currentUser}
        />
        <div className="layout-content">{children}</div>
      </Layout>
    </Layout>
  );
};

DashboardLayout.propTypes = {
  collapseMenu: PropTypes.bool,
  toggleCollapseMenu: PropTypes.func,
  children: PropTypes.any,
  currentUser: PropTypes.object,
  menu: PropTypes.func,
};
export default memo(DashboardLayout);
