import { object, string, number } from "yup";
import i18n from "i18n";

export const createProductMarketValidate = object().shape({
  thumbnail: object().nullable().required(i18n.t("errors.required")),
  name: string().trim().required(i18n.t("errors.required")),
  productType: string().required(i18n.t("errors.required")),
  mass: number()
    .typeError(i18n.t("errors.invalid"))
    .min(1, i18n.t("errors.invalid"))
    .required(i18n.t("errors.required")),
  unit: string().required(i18n.t("errors.required")),
  amount: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(1, i18n.t("errors.invalid"))
    .required(i18n.t("errors.required")),
  price: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(0, i18n.t("errors.invalid"))
    .required(i18n.t("errors.required")),
  currency: string().required(i18n.t("errors.required")),
  percentagePromotion: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(0, i18n.t("errors.invalid"))
    .max(100, i18n.t("errors.invalid"))
    .nullable(),
  promotion: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(0, i18n.t("errors.invalid")),
});

export const editMarketProductTypeForm = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
});
