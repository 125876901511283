import { Formik, Form } from "formik";
import RadioField from "components/common/Form/Radio";
import { Button } from "antd";
import { useSelector } from "react-redux";
import "./styles.less";
import i18n from "i18n";
import PropTypes from "prop-types";
import { PRODUCT_ARTICLE_TAG_TEXT } from "utils/constants";
import Helper from "utils/helpers";
import { setTagValidate } from "./validate";

const TagForm = ({ initialValues, onTagChange, onSubmit }) => {
  const isSettingTag = useSelector((state) => state.product.isSettingTag);
  return (
    <div className="set-tag-form">
      <Formik
        validateOnMount
        initialValues={{ ...initialValues, tag: initialValues.tag || "EMPTY" }}
        enableReinitialize
        validationSchema={setTagValidate}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid }) => (
          <Form>
            <RadioField
              onChange={onTagChange}
              name="tag"
              options={Helper.convertObjectToOptions(PRODUCT_ARTICLE_TAG_TEXT)}
            />
            <div className="submit-wrapper">
              <Button
                loading={isSettingTag}
                disabled={!isValid}
                htmlType="submit"
                type="primary"
                onClick={handleSubmit}
              >
                {i18n.t("products.setTag").toUpperCase()}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

TagForm.propTypes = {
  initialValues: PropTypes.object,
  onTagChange: PropTypes.func,
  handleTagAllChange: PropTypes.func,
  tagAll: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
export default TagForm;
