import PropTypes from "prop-types";
import { Image, Row, Col } from "antd";
// import i18n from 'i18n';
import "./style.less";
import { PlaceHolderIMG } from "assets/images";

export const RestaurantProductTooltip = (props) => {
  const { restaurantProductDetail = {} } = props;
  const {
    name,
    productType = {},
    thumbnail,
    percentagePromotion,
    promotion,
    currency,
    element,
    amount,
  } = restaurantProductDetail;
  return (
    <div className="restaurant-product-summary-tooltip-wrapper">
      <div className="restaurant-product-row">
        <div className="restaurant-product-shop-thumbnail">
          <Image
            src={thumbnail}
            alt=""
            fallback={PlaceHolderIMG}
            width={110}
            height={90}
          />
          {(percentagePromotion > 0 || promotion > 0) && (
            <div className="promotion">
              -
              {percentagePromotion > 0
                ? `${percentagePromotion} %`
                : `${Intl.NumberFormat("de-DE").format(promotion)} ${currency}`}
            </div>
          )}
        </div>
        <Row
          className="restaurant-product-shop-detail"
          justify="space-between"
          align="middle"
        >
          <Col>
            <div className="name-shop"> Tên sản phẩm</div>
            <div className="address-shop">{name}</div>
          </Col>
          <Col>
            <div className="name-shop">Loại</div>
            <div className="address-shop">{productType.name}</div>
          </Col>
          <Col>
            <div className="name-shop">Số lượng</div>
            <div className="address-shop">{amount}</div>
          </Col>
          <Col>
            <div className="name-shop">Mô tả (Size)</div>
            <div className="address-shop">{element}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

RestaurantProductTooltip.propTypes = {
  restaurantProductDetail: PropTypes.object,
};

export default RestaurantProductTooltip;
