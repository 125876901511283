import { forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { Form, Image, TimePicker, DatePicker as AntdDatePicker } from "antd";

import CalendarIcon from "assets/images/calendar-icon.svg";
import moment from "moment";
import i18n from "i18n";

const DatePicker = forwardRef(
  (
    {
      label,
      size = "large",
      className = "",
      placeholder = "",
      format = "YYYY-MM-DD",
      required,
      name,
      type = "date",
      disabled = false,
      ...props
    },
    ref
  ) => {
    const [field, meta, helper] = useField(name);
    const { value } = field;
    const isError = meta.touched && meta.error;
    return (
      <div className={`input-container date-picker-container ${className}`}>
        {label && (
          <div className="label-wrapper">
            <>{label}</>
            {required && <div className="required-mark">*</div>}
          </div>
        )}
        <Form.Item
          validateStatus={isError ? "error" : ""}
          help={
            <div className="error-text">
              {isError &&
                meta.error !== i18n.t("message.required") &&
                meta.error}
            </div>
          }
        >
          {type === "date" ? (
            <AntdDatePicker
              {...field}
              {...props}
              allowClear={false}
              size={size}
              ref={ref}
              placeholder={placeholder}
              value={typeof value === "string" ? moment(value, format) : value}
              format={format}
              onChange={(data) => helper.setValue(data)}
              disabled={disabled}
              suffixIcon={
                <Image
                  src={CalendarIcon}
                  width="24px"
                  alt="calendar-icon"
                  preview={false}
                />
              }
            ></AntdDatePicker>
          ) : (
            <TimePicker
              {...field}
              {...props}
              allowClear={false}
              size={size}
              ref={ref}
              placeholder={placeholder}
              format={format}
              value={typeof value === "string" ? moment(value, format) : value}
              onChange={(data) => helper.setValue(data)}
            />
          )}
        </Form.Item>
      </div>
    );
  }
);
DatePicker.displayName = "DatePicker";
DatePicker.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};
export default memo(DatePicker);
