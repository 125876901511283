import { put, call, takeEvery, takeLeading, select } from "redux-saga/effects";
import _pick from "lodash/pick";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import { safe } from "providers/GeneralProvider/saga";
import {
  createStoreRequest,
  createStoreSuccess,
  updateStoreRequest,
  updateStoreSuccess,
  getStoreListRequest,
  getStoreListSuccess,
  getStoreDetailRequest,
  getStoreDetailSuccess,
  lockStoreRequest,
  lockStoreSuccess,
  unlockStoreRequest,
  unlockStoreSuccess,
  pinPromotionalRequest,
  pinPromotionalSuccess,
  changeStoreOwnerRequest,
  changeStoreOwnerSuccess,
  stopLoading,
  // STORE_BANNER
  createStoreBannerRequest,
  createStoreBannerSuccess,
  editStoreBannerRequest,
  editStoreBannerSuccess,
  deleteStoreBannerRequest,
  deleteStoreBannerSuccess,
  getStoreBannerListRequest,
  getStoreBannerListSuccess,
  // STORE_LOCATION
  getStoreLocationTagsRequest,
  getStoreLocationTagsSuccess,
  createStoreLocationTagsRequest,
  createStoreLocationTagsSuccess,
  editStoreLocationTagsRequest,
  editStoreLocationTagsSuccess,
  deleteStoreLocationTagsRequest,
  deleteStoreLocationTagsSuccess,
  // STORE_PHOTO
  getStorePhotoListRequest,
  getStorePhotoListSuccess,
  createStorePhotoRequest,
  createStorePhotoSuccess,
  approveStorePhotoRequest,
  approveStorePhotoSuccess,
  denyStorePhotoRequest,
  denyStorePhotoSuccess,
  getDiscountConditionsSuccess,
  getDiscountConditionsRequest,
  getDiscountPercentagesSuccess,
  getDiscountPercentagesRequest,
} from "providers/StoreProvider/slice";
import Helper from "utils/helpers";
import request from "utils/api";
import { getProductCosmeticTypeRequest } from "providers/CosmeticProductProvider/slice";
import { STORE_TYPE } from "utils/constants";
import moment from "moment";

function* createStore({ meta, payload }) {
  const params = _pick(payload.data, [
    "name",
    "description",
    "thumbnail",
    "address",
    "phone",
    "longitude",
    "latitude",
    "banners",
    "subType",
    "workingTimeMethod",
    "workingTime",
    "locationTags",
    "photos",
    "discountCondition",
    "discountPercentage",
    "businessImages",
    "isAllowedPayWithVieGo",
    "isPromotionActive",
    "customDiscountCondition",
  ]);
  if (moment(params.customDiscountCondition.start, moment.ISO_8601).isValid()) {
    params.customDiscountCondition = {
      start: moment(params.customDiscountCondition.start).format("HH:mm"),
      end: moment(params.customDiscountCondition.end).format("HH:mm"),
    };
  }

  const discountConditionsData = yield select(
    (state) => state.store.discountConditions
  );
  const discountCondition = discountConditionsData.find(
    (condition) => condition.value === params.discountCondition
  );
  params.discountCondition = discountCondition
    ? {
        value: discountCondition.value,
        displayText: discountCondition.displayText,
      }
    : {};

  params.phone = params.phone ? params.phone : undefined;
  params.discountPercentage = params.discountPercentage
    ? {
        value: params.discountPercentage,
        displayText: `${params.discountPercentage}%`,
      }
    : {};
  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  if (params.banners) {
    params.banners = [
      {
        imageUrl: params.banners.url,
        isActive: true,
      },
    ];
  }
  if (params.workingTimeMethod !== "custom") {
    params.workingTime = [];
  }
  if (
    params.customDiscountCondition.start === "Invalid date" ||
    params.discountCondition.value !== "CUSTOM"
  ) {
    delete params.customDiscountCondition;
  }
  if (_isEmpty(params.workingTimeMethod)) {
    params.workingTimeMethod = "no_time";
  }
  if (params.discountCondition === "EVERY_DAY") {
    params.discountCondition = {
      value: "EVERY_DAY",
      displayText: "Every day of the week, including weekends and holidays",
    };
  }
  if (params.discountCondition === "EVERY_DAY_EXCLUDING_HOLIDAYS") {
    params.discountCondition = {
      value: "EVERY_DAY_EXCLUDING_HOLIDAYS",
      displayText:
        "Every day of the week, including weekends but excluding holidays",
    };
  }
  if (params.discountCondition === "MONDAY_TO_THURSDAY") {
    params.discountCondition = {
      value: "MONDAY_TO_THURSDAY",
      displayText: "Weekdays from Monday to Thursday, excluding holidays",
    };
  }
  if (params.discountCondition === "MONDAY_TO_FRIDAY") {
    params.discountCondition = {
      value: "MONDAY_TO_FRIDAY",
      displayText: "Weekdays from Monday to Friday, excluding holidays",
    };
  }
  if (params.discountCondition === "CUSTOM") {
    params.discountCondition = {
      value: "CUSTOM",
      displayText: "Custom",
    };
  }
  if (!params.isPromotionActive) {
    delete params.customDiscountCondition;
    delete params.discountPercentage;
    delete params.discountCondition;
  }

  const { data } = yield call(request, "api/store", {
    data: params,
    type: payload.type,
  });
  yield put(createStoreSuccess(data, meta));
}

function* updateStore({ meta, payload }) {
  const params = _pick(payload, [
    "name",
    "description",
    "thumbnail",
    "address",
    "longitude",
    "latitude",
    "phone",
    "subType",
    "workingTimeMethod",
    "workingTime",
    "locationTags",
    "photos",
    "discountCondition",
    "discountPercentage",
    "businessImages",
    "isAllowedPayWithVieGo",
    "isPromotionActive",
    "customDiscountCondition",
  ]);
  if (moment(params.customDiscountCondition.start, moment.ISO_8601).isValid()) {
    params.customDiscountCondition = {
      start: moment(params.customDiscountCondition.start).format("HH:mm"),
      end: moment(params.customDiscountCondition.end).format("HH:mm"),
    };
  }

  params.phone = params.phone ? params.phone : undefined;
  params.discountPercentage = params.discountPercentage
    ? {
        value: params.discountPercentage,
        displayText: `${params.discountPercentage}%`,
      }
    : {};
  params.phone = params.phone ? params.phone : undefined;

  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  if (params.workingTimeMethod !== "custom") {
    params.workingTime = [];
  }
  if (_isEmpty(params.workingTimeMethod)) {
    params.workingTimeMethod = "no_time";
  }
  if (
    params.customDiscountCondition.start === "Invalid date" ||
    !params.customDiscountCondition.start
  ) {
    delete params.customDiscountCondition;
  }
  if (params.discountCondition === "EVERY_DAY") {
    params.discountCondition = {
      value: "EVERY_DAY",
      displayText: "Every day of the week, including weekends and holidays",
    };
  }
  if (params.discountCondition === "EVERY_DAY_EXCLUDING_HOLIDAYS") {
    params.discountCondition = {
      value: "EVERY_DAY_EXCLUDING_HOLIDAYS",
      displayText:
        "Every day of the week, including weekends but excluding holidays",
    };
  }
  if (params.discountCondition === "MONDAY_TO_THURSDAY") {
    params.discountCondition = {
      value: "MONDAY_TO_THURSDAY",
      displayText: "Weekdays from Monday to Thursday, excluding holidays",
    };
  }
  if (params.discountCondition === "MONDAY_TO_FRIDAY") {
    params.discountCondition = {
      value: "MONDAY_TO_FRIDAY",
      displayText: "Weekdays from Monday to Friday, excluding holidays",
    };
  }
  if (params.discountCondition === "CUSTOM") {
    params.discountCondition = {
      value: "CUSTOM",
      displayText: "Custom",
    };
  }
  if (!params.isPromotionActive) {
    delete params.customDiscountCondition;
    delete params.discountPercentage;
    delete params.discountCondition;
  }

  const { data } = yield call(
    request,
    `api/store/${payload._id}`,
    {
      type: payload.type,
      data: params,
    },
    { method: "PUT" }
  );
  yield put(updateStoreSuccess(data, meta));
  Helper.toastr(`ID:${payload._id}`, "Cập nhật cửa hàng thành công", "success");
}

function* getStoreList({ meta, payload }) {
  const params = _pick(payload, [
    "page",
    "limit",
    "keyword",
    "status",
    "sortAddress",
    "sortName",
    "sortRating",
    "sortStatus",
    "activeStatus",
    "type",
    "subType",
    "pin",
    "mine",
  ]);
  if (_isEmpty(params.status)) {
    params.status = [];
  }
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  if (
    [
      STORE_TYPE.COSMETIC,
      STORE_TYPE.CAR_AND_OLD_STUFF,
      STORE_TYPE.TRAVEL,
      STORE_TYPE.SHIPPER,
      STORE_TYPE.ENTERTAINMENT,
    ].includes(params.type)
  ) {
    if (params.subType === "true") {
      delete params.subType;
      params.mine = true;
    }
    if (params.subType === "pin") {
      delete params.subType;
      params.pin = 1;
    }
  }
  if ([STORE_TYPE.RESTAURANT, STORE_TYPE.MARKET].includes(params.type)) {
    if (params.mine === "pin") {
      delete params.mine;
      params.pin = 1;
    }
  }
  const { data } = yield call(request, "api/store/managed-list", params, {
    method: "GET",
  });
  yield put(getStoreListSuccess(data, meta));
}

function* lockStore({ meta, payload }) {
  const { data } = yield call(
    request,
    "api/store/locked-store",
    {
      storeIds: _get(payload, "storeIds", []),
    },
    {
      method: "POST",
    }
  );
  yield put(lockStoreSuccess(data, meta));
  Helper.toastr(
    `ID:${payload.storeIds.join(", ")}`,
    "Khóa cửa hàng thành công",
    "success"
  );
}

function* unlockStore({ meta, payload }) {
  const { data } = yield call(
    request,
    "api/store/locked-store",
    {
      storeIds: _get(payload, "storeIds", []),
    },
    {
      method: "DELETE_BODY",
    }
  );
  yield put(unlockStoreSuccess(data, meta));
  Helper.toastr(
    `ID:${payload.storeIds.join(", ")}`,
    "Mở khóa cửa hàng thành công",
    "success"
  );
}

function* pinPromotional({ meta, payload }) {
  yield call(
    request,
    `api/store/${payload._id}/pin`,
    { pin: payload.pin === 1 },
    { method: "PUT" }
  );
  yield put(pinPromotionalSuccess({}, meta));
  Helper.toastr(
    `ID:${payload._id}`,
    `${payload.pin === 1 ? "Ghim" : "Bỏ ghim"} cửa hàng thành công`,
    "success"
  );
}

function* getStoreDetail({ meta, payload }) {
  const { data } = yield call(
    request,
    `api/store/${payload._id}`,
    {},
    { method: "GET" }
  );
  yield put(getStoreDetailSuccess(data, meta));
  // yield put(getProductCosmeticTypeRequest({ cosmeticStoreId: data._id }));
}

function* changeStoreOwner({ meta, payload }) {
  const params = _pick(payload, ["type", "ownerId"]);
  yield call(request, `api/admin/store/${payload._id}/owner`, params, {
    method: "PUT",
  });
  yield put(changeStoreOwnerSuccess({}, meta));
  Helper.toastr(
    `ID:${params.ownerId}`,
    "Thay đổi chủ cửa hàng thành công",
    "success"
  );
}

function* createStoreBanner(action) {
  const params = _pick(action.payload, ["imageUrl", "link", "isActive"]);
  const { data } = yield call(
    request,
    `api/store/${_get(action, "payload.storeId")}/banner`,
    params
  );
  Helper.toastr(`ID:${_get(data, "_id")}`, "Tạo banner thành thông", "success");
  yield put(createStoreBannerSuccess(data, action.meta));
}

function* editStoreBanner(action) {
  const params = _pick(action.payload, ["imageUrl", "link", "isActive"]);
  const { data } = yield call(
    request,
    `api/store/${_get(action, "payload.storeId")}/banner/${_get(
      action,
      "payload.bannerId"
    )}`,
    params,
    { method: "PUT" }
  );
  Helper.toastr(
    `ID:${_get(data, "_id")}`,
    "Cập nhật banner thành thông",
    "success"
  );
  yield put(editStoreBannerSuccess(data, action.meta));
}

function* deleteStoreBanner(action) {
  const { data } = yield call(
    request,
    `api/store/${_get(action, "payload.storeId")}/banner/${_get(
      action,
      "payload.bannerId"
    )}`,
    {},
    { method: "DELETE" }
  );
  Helper.toastr(`ID:${_get(data, "_id")}`, "Xóa banner thành thông", "success");
  yield put(deleteStoreBannerSuccess(data, action.meta));
}

function* getStoreBannerList({ payload, meta }) {
  const result = yield call(
    request,
    `api/store/${_get(payload, "storeId")}/banner/list-all`,
    {},
    {
      method: "GET",
    }
  );
  yield put(getStoreBannerListSuccess(result, meta));
}

function* getStoreLocationTags({ meta }) {
  const { data } = yield call(
    request,
    "api/store/location-tag/list",
    {},
    {
      method: "GET",
    }
  );
  yield put(getStoreLocationTagsSuccess(data, meta));
}

function* createStoreLocationTags({ payload, meta }) {
  const { data } = yield call(
    request,
    "api/store/location-tag",
    {
      name: payload.name,
    },
    {
      method: "POST",
    }
  );
  yield put(createStoreLocationTagsSuccess(data, meta));
}

function* editStoreLocationTags({ payload, meta }) {
  const { data } = yield call(
    request,
    `api/store/location-tag/${payload._id}`,
    {
      name: payload.name,
    },
    {
      method: "PUT",
    }
  );
  yield put(editStoreLocationTagsSuccess(data, meta));
}

function* deleteStoreLocationTags({ payload, meta }) {
  const { data } = yield call(
    request,
    `api/store/location-tag/${payload._id}`,
    {},
    {
      method: "DELETE",
    }
  );
  yield put(deleteStoreLocationTagsSuccess(data, meta));
}

function* getStorePhotoList({ payload, meta }) {
  const result = yield call(
    request,
    `/api/store/${payload._id}/photo/managed-list`,
    {},
    {
      method: "GET",
    }
  );
  yield put(getStorePhotoListSuccess(result, meta));
}

function* createStorePhoto({ payload: { storeId, ...params }, meta }) {
  const result = yield call(request, `/api/store/${storeId}/photo`, params, {
    method: "POST",
  });
  yield put(createStorePhotoSuccess(result, meta));
}

function* approveStorePhoto({ payload, meta }) {
  const result = yield call(
    request,
    `/api/store/${payload.storeId}/photo/${payload._id}`,
    { isActive: true },
    {
      method: "PUT",
    }
  );
  yield put(approveStorePhotoSuccess(result, meta));
}

function* denyStorePhoto({ payload, meta }) {
  const result = yield call(
    request,
    `/api/store/${payload.storeId}/photo/${payload._id}`,
    {},
    {
      method: "DELETE",
    }
  );
  yield put(denyStorePhotoSuccess(result, meta));
}
function* handleGetDiscountConditions({ payload, meta }) {
  const result = yield call(
    request,
    `/api/config/discount-conditions`,
    {},
    {
      method: "GET",
    }
  );

  yield put(getDiscountConditionsSuccess(result, meta));
}
function* handleGetDiscountPercentages({ payload, meta }) {
  const result = yield call(
    request,
    `/api/config/discount-percentages`,
    {},
    {
      method: "GET",
    }
  );
  yield put(getDiscountPercentagesSuccess(result.data, meta));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchStore() {
  yield takeEvery(createStoreRequest.type, safe(createStore, onError));
  yield takeLeading(getStoreListRequest.type, safe(getStoreList, onError));
  yield takeLeading(updateStoreRequest.type, safe(updateStore, onError));
  yield takeLeading(
    getDiscountConditionsRequest.type,
    safe(handleGetDiscountConditions, onError)
  );
  yield takeLeading(
    getDiscountPercentagesRequest.type,
    safe(handleGetDiscountPercentages, onError)
  );

  yield takeLeading(lockStoreRequest.type, safe(lockStore, onError));
  yield takeLeading(unlockStoreRequest.type, safe(unlockStore, onError));
  yield takeLeading(pinPromotionalRequest.type, safe(pinPromotional, onError));
  yield takeLeading(getStoreDetailRequest.type, safe(getStoreDetail, onError));
  yield takeLeading(
    changeStoreOwnerRequest.type,
    safe(changeStoreOwner, onError)
  );
  // STORE_BANNER
  yield takeLeading(
    createStoreBannerRequest.type,
    safe(createStoreBanner, onError)
  );
  yield takeLeading(
    editStoreBannerRequest.type,
    safe(editStoreBanner, onError)
  );
  yield takeLeading(
    deleteStoreBannerRequest.type,
    safe(deleteStoreBanner, onError)
  );
  yield takeLeading(
    getStoreBannerListRequest.type,
    safe(getStoreBannerList, onError)
  );
  // STORE_LOCATION
  yield takeLeading(
    getStoreLocationTagsRequest.type,
    safe(getStoreLocationTags, onError)
  );
  yield takeLeading(
    createStoreLocationTagsRequest.type,
    safe(createStoreLocationTags, onError)
  );
  yield takeLeading(
    editStoreLocationTagsRequest.type,
    safe(editStoreLocationTags, onError)
  );
  yield takeLeading(
    deleteStoreLocationTagsRequest.type,
    safe(deleteStoreLocationTags, onError)
  );
  // STORE_PHOTO
  yield takeLeading(
    getStorePhotoListRequest.type,
    safe(getStorePhotoList, onError)
  );
  yield takeLeading(
    createStorePhotoRequest.type,
    safe(createStorePhoto, onError)
  );
  yield takeLeading(
    approveStorePhotoRequest.type,
    safe(approveStorePhoto, onError)
  );
  yield takeLeading(denyStorePhotoRequest.type, safe(denyStorePhoto, onError));
}
