import { useRef, useEffect } from "react";
import { Row, Button, Typography } from "antd";
import { Formik, Form } from "formik";
import _get from "lodash/get";
import { useParams, useNavigate } from "react-router-dom";
import { TextInput, TextEditor, ImageUpload } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "components/BackButton";
import {
  getGuideFonehouseDetailRequest,
  updateGuideFonehouseRequest,
} from "providers/GuideFonehouseProvider/slice";
import { updateNewsFonehouseValidate } from "./validate";
import "./styles.less";

const GuideFonehouseEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCreating = useSelector((state) =>
    _get(state, "guideFonehouse.isCreating")
  );
  const detail = useSelector((state) => _get(state, "guideFonehouse.detail"));
  const { idGuild } = useParams();

  useEffect(() => {
    dispatch(getGuideFonehouseDetailRequest({ guideId: idGuild }));
  }, [idGuild, dispatch]);

  const handleUpdateGuideFonehouse = (values) => {
    dispatch(
      updateGuideFonehouseRequest({
        ...values,
        idGuild,
      })
    ).then(() =>
      navigate(`/guide-fonehouse/${idGuild}`, {
        replace: true,
        state: { canGoBack: true },
      })
    );
  };
  const formikRef = useRef();
  return (
    <div className="news-fonehouse-update-page">
      <Row className="news-fonehouse-update-header" justify="space-between">
        <Row>
          <BackButton />
          <Typography.Title level={4}>Cập nhật cẩm nang</Typography.Title>
        </Row>
        <Button
          loading={isCreating}
          size="large"
          type="primary"
          onClick={() => formikRef.current.submitForm()}
        >
          Cập nhật
        </Button>
      </Row>
      <div className="update-form-wrapper">
        <Formik
          validateOnMount
          innerRef={formikRef}
          enableReinitialize
          initialValues={{ ...detail, thumbnail: { url: detail.thumbnail } }}
          validationSchema={updateNewsFonehouseValidate}
          onSubmit={handleUpdateGuideFonehouse}
        >
          {() => (
            <Form>
              <Row justify="space-between">
                <TextInput name="title" placeholder="Tiêu đề" size="large" />
              </Row>
              <Row justify="space-between" className="editor-wrapper">
                <ImageUpload name="thumbnail" />
                <TextEditor name="content" />
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default GuideFonehouseEdit;
