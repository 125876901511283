import { Col, Row, Image, Button } from "antd";
import _isEmpty from "lodash/isEmpty";
import { MinusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";

import DefaultImage from "assets/images/photo.png";
import { COSMETIC_TYPE, WORKING_TIME_TEXT } from "utils/constants";
import i18n from "i18n";
import "./styles.less";
import { PlaceHolderIMG } from "assets/images";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getDiscountConditionsRequest,
  getDiscountPercentagesRequest,
} from "providers/StoreProvider/slice";
import Helper from "utils/helpers";

// eslint-disable-next-line react/prop-types
const CosmeticDetail = ({ isPermissionToEdit, detail = {}, onEdit }) => {
  const dispatch = useDispatch();

  const createMarkup = (html) => ({
    __html: DOMPurify.sanitize(html, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
    }),
  });
  useEffect(() => {
    dispatch(getDiscountConditionsRequest());
    dispatch(getDiscountPercentagesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderWorkingTime = () => {
    const d = new Date();
    const day = d.getDay();
    const workingTimeOfDay = detail.workingTime[day === 0 ? 6 : day - 1];
    return (
      <>
        {_isEmpty(detail.workingTime) || workingTimeOfDay.alwaysClose ? (
          <div className="s-text">Đóng cửa</div>
        ) : (
          <>
            {workingTimeOfDay.time?.map((item, index) => (
              <div key={index} className="s-text">
                {item.from} <MinusOutlined /> {item.to}
              </div>
            ))}
            )
          </>
        )}
      </>
    );
  };

  return (
    <div className="cosmetic-detail-component-wrapper">
      <Row>
        <Col span={14}>
          <Row>
            <Col>
              <Image
                fallback={PlaceHolderIMG}
                className="banner-wrapper"
                width={160}
                height={100}
                src={detail.thumbnail || DefaultImage}
              />
            </Col>
            <Col flex="auto" className="cosmetic-info">
              <Row className="info-row">
                <Col className="info-col" span={16}>
                  <div className="s-text">Tên cửa hàng</div>
                  <div className="s-text">{detail.name}</div>
                </Col>
                <Col className="info-col" span={8}>
                  <div className="s-text">Loại hình</div>
                  <div className="s-text">{COSMETIC_TYPE[detail.subType]}</div>
                </Col>
              </Row>
              <Row className="info-row">
                <Col className="info-col">
                  <div className="s-text">Giờ làm việc</div>
                  {detail.workingTimeMethod !== "custom" ? (
                    <div className="s-text">
                      {WORKING_TIME_TEXT[detail.workingTimeMethod]}
                    </div>
                  ) : (
                    renderWorkingTime()
                  )}
                </Col>
              </Row>
              <Row className="info-row">
                <Col className="info-col">
                  <div className="s-text">Địa chỉ</div>
                  <div className="s-text">{detail.address}</div>
                </Col>
              </Row>
              <Row className="info-row">
                <Col className="info-col" span={8}>
                  <div className="s-text">Số điện thoại</div>
                  <div className="s-text">{detail.phone}</div>
                </Col>
                <Col className="info-col" span={8}>
                  <div className="s-text">Đánh giá</div>
                  <div className="s-text bold">{detail.rating}</div>
                </Col>
              </Row>
              <Row className="info-row">
                <Col className="info-col" span={8}>
                  <div className="s-text">
                    Thời gian khuyến mãi kết thúc sau:
                  </div>
                  <div className="s-text">
                    {Helper.calculateRemainingTime(detail.promotionEndedAt)}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col flex="auto">
          <Row justify="end">
            {isPermissionToEdit && (
              <Button
                style={{ maxWidth: 140 }}
                onClick={onEdit}
                type="primary"
                size="small"
              >
                CẬP NHẬT
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <div className="business-description-container">
          <div className="title">{i18n.t("cosmetic.businessDescription")}</div>
          <div
            className="business-description-content"
            dangerouslySetInnerHTML={createMarkup(detail.description)}
          ></div>
        </div>
      </Row>
    </div>
  );
};

CosmeticDetail.propTypes = {
  detail: PropTypes.object,
  onEdit: PropTypes.func,
  isPermissionToEdit: PropTypes.bool,
};
export default CosmeticDetail;
