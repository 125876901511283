import PropTypes from "prop-types";
import { Rate } from "antd";

import { STORE_SUB_TYPE } from "utils/constants";
import i18n from "i18n";
import "./style.less";

export const StoreSummaryTooltip = ({ detail }) => {
  const {
    name,
    address,
    rating,
    thumbnail,
    activeStatus,
    status,
    subType,
    locationTags = [],
  } = detail;
  const isOpen = activeStatus === "open";
  const location = locationTags.map((tag) => tag.name || "").join(", ");

  return (
    <div className="store-summary-tooltip-wrapper">
      <div className="store-row">
        <div className="store-shop-thumbnail">
          <img src={thumbnail} alt="" />
        </div>
        <div className="store-shop-detail">
          <div className="name-shop"> {name}</div>
          <div className="address-shop">{address}</div>
          {STORE_SUB_TYPE[subType] && (
            <div className="address-shop">Loại: {STORE_SUB_TYPE[subType]}</div>
          )}
          {location && <div className="address-shop">Địa điểm: {location}</div>}
          <Rate disabled defaultValue={rating} />
        </div>
        <div
          className={`shop-status ${
            status === "active"
              ? `${isOpen ? "shop-opening" : "shop-closed"}`
              : "shop-lock"
          }`}
        >
          {status === "active" ? (
            <div>
              {isOpen
                ? i18n.t("bussinessStatus.bussinessOpening")
                : i18n.t("bussinessStatus.bussinessClosed")}{" "}
            </div>
          ) : (
            i18n.t("bussinessStatus.bussinessLock")
          )}
        </div>
      </div>
    </div>
  );
};

StoreSummaryTooltip.propTypes = {
  detail: PropTypes.object,
};

export default StoreSummaryTooltip;
