import { useRef, useState } from "react";
import { Modal, Carousel, Col, Row, Image, Button } from "antd";
import { CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import _get from "lodash/get";
import moment from "moment";
import {
  setTagModalData,
  setTagRequest,
  getProductListRequest,
} from "providers/ProductProvider/slice";

import "./styles.less";
import i18n from "i18n";
import { PlaceHolderIMG } from "assets/images";
import TagForm from "./TagForm";

const SetTagModal = () => {
  const visible = useSelector((state) => state.product.setTagModalData.visible);
  const carouselRef = useRef(null);
  const dispatch = useDispatch();
  const [query] = useQuery();
  const products = useSelector((state) =>
    _get(state.product.setTagModalData, "data.products", [])
  );
  const originalProducts = useSelector((state) =>
    _get(state.product.setTagModalData, "data.originalProducts", [])
  );

  const [showingProduct, setShowingProduct] = useState(0);
  const [tagAll, setTagAll] = useState(false);

  const handleTagAllChange = (value) => {
    if (value.target.checked) {
      const currentTag = products[showingProduct].tag;
      const updatedProducts = products.map((pro) => ({
        ...pro,
        tag: currentTag,
      }));

      dispatch(
        setTagModalData({
          visible: true,
          data: { products: updatedProducts, originalProducts },
        })
      );
    } else {
      dispatch(
        setTagModalData({
          visible: true,
          data: {
            products: originalProducts,
            originalProducts,
          },
        })
      );
    }
    setTagAll(!tagAll);
  };

  const handleCloseModal = () => {
    dispatch(
      setTagModalData({
        visible: false,
        data: {},
      })
    );
  };

  const handleAfterClose = () => {
    dispatch(
      setTagModalData({
        visible: false,
        data: {
          products: [],
          originalProducts: [],
        },
      })
    );
  };

  const handleSubmit = (values) => {
    dispatch(
      setTagRequest({
        ...values,
        idProducts: products.map((product) => product._id),
      })
    ).then(() => {
      handleAfterClose();
      dispatch(getProductListRequest(query));
    });
  };

  const handleTagChange = (data) => {
    setTagAll(false);
    const updatedProducts = products.map((pro) => ({ ...pro }));
    updatedProducts[showingProduct].tag = data;
    dispatch(
      setTagModalData({
        visible: true,
        data: { products: updatedProducts, originalProducts },
      })
    );
  };

  const displayedProduct = _get(products, `[${showingProduct}]`, {});
  const {
    promotionPrice = "",
    inputPrice = "",
    percentagePromotion = 0,
    type,
    memory,
  } = displayedProduct;

  return (
    <div className="set-tag-modal-wrapper">
      <Modal
        afterClose={handleAfterClose}
        onCancel={handleCloseModal}
        title={i18n.t("products.setProductTag")}
        visible={visible}
        width={768}
        className="set-tag-modal"
        footer={null}
      >
        <div className="m-title-text">{i18n.t("products.basicInfo")}</div>
        <Row justify="space-between" className="product-carousel-wrapper">
          <Col span={6} style={{ maxWidth: 165 }}>
            <Carousel autoplay ref={carouselRef}>
              {(displayedProduct.images || []).map((image) => (
                <div key={image} className="product-image-wrapper">
                  <Image
                    fallback={PlaceHolderIMG}
                    width={165}
                    height={165}
                    src={image}
                  />
                  {percentagePromotion > 0 && (
                    <div className="sale--price-wrapper">
                      -{percentagePromotion}%
                    </div>
                  )}
                </div>
              ))}
            </Carousel>
            <Row
              align="middle"
              justify="center"
              className="carousel-btn-wrapper"
            >
              <Button
                disabled={showingProduct === 0}
                icon={<CaretLeftOutlined />}
                onClick={() => setShowingProduct(showingProduct - 1)}
              />
              {showingProduct + 1}/{products.length}
              <Button
                disabled={showingProduct + 1 === products.length}
                icon={<CaretRightOutlined />}
                onClick={() => setShowingProduct(showingProduct + 1)}
              />
            </Row>
          </Col>
          <Col span={8}>
            <div className="l-title-text">{displayedProduct.name}</div>
            <div className="detail-text s-text">
              <div className="fw-700">Mã sản phẩm: </div>
              <div>{displayedProduct.productId}</div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">IMEI: </div>
              <div>{displayedProduct.imei}</div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Phiên bản phần mềm: </div>
              <div>{displayedProduct.softwareVersion}</div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Phụ kiện: </div>
              <div>{displayedProduct.accessories}</div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Số lượng: </div>
              <div>{displayedProduct.amount}</div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Giá trên đơn: </div>
              <div className="highlight-price-text">
                {promotionPrice.toLocaleString()} {displayedProduct.currency}
              </div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Ngày nhập: </div>
              <div>
                {moment(displayedProduct.createdAt).format("DD/MM/YYYY")}
              </div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Giá đầu vào: </div>
              <div>
                {inputPrice.toLocaleString()} {displayedProduct.currency}
              </div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Loại: </div>
              <div>{type?.name}</div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Bộ nhớ: </div>
              <div>{memory}</div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Giá thực: </div>
              <div className="price-text highlight-price-text">
                {inputPrice.toLocaleString()} {displayedProduct.currency}
              </div>
            </div>
            <div className="detail-text s-text">
              <div className="fw-700">Lãi suất: </div>
              <div className="price-text">
                {inputPrice.toLocaleString()} {displayedProduct.currency}
              </div>
            </div>
          </Col>
          <Col span={9}>
            <TagForm
              onSubmit={handleSubmit}
              onTagChange={handleTagChange}
              handleTagAllChange={handleTagAllChange}
              tagAll={tagAll}
              initialValues={products[showingProduct]}
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default SetTagModal;
