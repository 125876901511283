import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { Button, Col, Modal, Row, Radio } from "antd";
import TimeOption from "assets/images/time-option.png";
import { LeftArrowIcon } from "assets/images";
import { WORKING_TIME_OPTIONS } from "utils/constants";
import { setChangeWorkingTimeModal } from "providers/GeneralProvider/slice";
import CreateCustomWorkingTime from "./CreateCustomWorkingTime";

import "./styles.less";

const initWorkingTime = [
  {
    key: 0,
    label: "Thứ hai:",
    time: [],
  },
  {
    key: 1,
    label: "Thứ ba:",
    time: [],
  },
  {
    key: 2,
    label: "Thứ tư:",
    time: [],
  },
  {
    key: 3,
    label: "Thứ năm:",
    time: [],
  },
  {
    key: 4,
    label: "Thứ sáu:",
    time: [],
  },
  {
    key: 5,
    label: "Thứ bảy:",
    time: [],
  },
  {
    key: 6,
    label: "Chủ nhật:",
    time: [],
  },
];

const ChangeWorkingTimeModal = ({
  workingTimeModal,
  handleChangeWorkingTime,
}) => {
  const { isOpen, data, category } = workingTimeModal;
  const dispatch = useDispatch();
  const modalProps = _get(workingTimeModal, "data", {});
  const { okButtonProps, onOk, onCancel, ...props } = modalProps;
  const { workingTime = [] } = data;
  const workingTimeDisplayed = [];
  initWorkingTime.forEach((item) => {
    workingTime.forEach((element, index) => {
      if (index === item.key) {
        if (!_isEmpty(element.time)) {
          const timeFormat = element.time.map((i, idx) => ({
            ...i,
            shift: idx,
          }));
          workingTimeDisplayed.push({ ...item, time: timeFormat });
        } else {
          workingTimeDisplayed.push(item);
        }
      }
    });
  });

  const [workingTimeType, setWorkingTimeType] = useState(
    data.workingTimeMethod || "no_time"
  );
  const [workingTimeOfWeek, setWorkingTimeOfWeek] = useState(
    _isEmpty(workingTime) ? initWorkingTime : workingTimeDisplayed
  );

  const handleOnchangeWorkingTimeType = (e) => {
    setWorkingTimeType(e.target.value);
  };

  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
    dispatch(
      setChangeWorkingTimeModal({
        isOpen: false,
        data: {},
        category,
      })
    );
  };

  const handleChangeWorkingTimeStore = () => {
    const _workingTime = workingTimeOfWeek.map((item) => {
      if (!_isEmpty(item.time) && !_isEmpty(item.time[0].from)) {
        const itemTimeFormat = item.time.reduce((itemTime, items) => {
          if (!_isEmpty(items.from)) {
            return [...itemTime, { from: items.from, to: items.to }];
          }
          return itemTime;
        }, []);
        return { time: itemTimeFormat };
      }
      return { alwaysClose: true };
    });

    handleChangeWorkingTime({
      workingTimeMethod: workingTimeType,
      workingTime: _workingTime,
    });
    dispatch(
      setChangeWorkingTimeModal({
        isOpen: false,
        data: {},
        category,
      })
    );
  };

  return (
    <Modal
      visible={isOpen}
      afterClose={() =>
        dispatch(
          setChangeWorkingTimeModal({ data: {}, isOpen: false, category })
        )
      } // Remove popup data when popup is close
      footer={null}
      closable={false}
      centered
      className="change-working-time-popup-wrapper"
      {...props}
    >
      <div className="change-working-time-popup-container">
        <div className="change-working-time-popup-header">
          <div className="header-title-container">
            <img
              onClick={handleCancel}
              className="icon-close"
              src={LeftArrowIcon}
              alt=""
            />
            <div className="header-content">Cài đặt thời gian làm việc</div>
          </div>
          <Button
            onClick={handleChangeWorkingTimeStore}
            size="small"
            className="confirm-btn"
            type="primary"
          >
            Xác nhận
          </Button>
        </div>
        <Row className="change-working-time-popup-body">
          <Col span={12} className="type-working-time-container">
            <div className="choose-type-title">CHỌN LOẠI HÌNH</div>
            <Radio.Group
              className="working-time-options"
              onChange={handleOnchangeWorkingTimeType}
              value={workingTimeType}
            >
              {WORKING_TIME_OPTIONS.map((item) => (
                <Radio
                  className="option-item"
                  key={item.value}
                  value={item.value}
                >
                  <div className="option-label">
                    <div className="title">{item.title}</div>
                    <div className="content">{item.content}</div>
                  </div>
                </Radio>
              ))}
            </Radio.Group>
          </Col>
          {workingTimeType !== "custom" ? (
            <Col span={12} className="optional-working-time-container">
              <img className="working-time-img" src={TimeOption} alt="" />
              <Button
                onClick={() => setWorkingTimeType("custom")}
                className="create-working-time-btn"
                type="primary"
                size="small"
              >
                TẠO KHUNG GIỜ TỰ CHỌN
              </Button>
            </Col>
          ) : (
            <Col span={12} className="create-custom-working-time-container">
              <CreateCustomWorkingTime
                workingTimeOfWeek={workingTimeOfWeek}
                setWorkingTimeOfWeek={setWorkingTimeOfWeek}
              />
            </Col>
          )}
        </Row>
      </div>
    </Modal>
  );
};
ChangeWorkingTimeModal.propTypes = {
  workingTimeModal: PropTypes.object,
  handleChangeWorkingTime: PropTypes.func,
};

export default ChangeWorkingTimeModal;
