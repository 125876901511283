import { put, takeLeading, call, takeEvery } from "redux-saga/effects";
import request from "utils/api";
import Helper from "utils/helpers";
import _pick from "lodash/pick";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import {
  getAllVisaRequest,
  getAllVisaSuccess,
  stopLoading,
  createVisaRequest,
  createVisaSuccess,
  updateStatusVisaSuccess,
  updateStatusVisaRequest,
  getVisaDetailRequest,
  getVisaDetailSuccess,
  updateVisaRequest,
  updateVisaSuccess,
  getCountNotSeenSuccess,
  getCountNotSeenRequest,
} from "providers/VisaProvider/slice";

function* getAllVisa(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "keyword",
    "roles",
    "sortTitle",
    "status",
    "sort",
    "seen",
  ]);
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  const { data } = yield call(request, "api/visa/get-all", params, {
    method: "GET",
  });
  yield put(getAllVisaSuccess(data));
}

function* createVisa(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "type",
    "title",
    "thumbnail",
    "content",
  ]);
  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  const data = yield call(
    request,
    "api/visa",
    {
      data: params,
      type: "visa",
    },
    {
      method: "POST",
    }
  );
  yield put(createVisaSuccess(data, meta));
}

function* getVisaDetail(action) {
  const params = _pick(action.payload, ["idVisa"]);
  const { data } = yield call(request, `api/visa/${params.idVisa}`, undefined, {
    method: "GET",
  });
  yield put(getCountNotSeenRequest());
  yield put(getVisaDetailSuccess(data));
}

function* onError() {
  yield put(stopLoading());
}

function* updateStatusVisa(action) {
  const { meta } = action;
  const statusVisa = _get(action, "payload.status");
  const idVisa = _get(action, "payload.idVisa", []);
  const result = yield call(
    request,
    `api/visa/${idVisa}`,
    {
      data: {
        status: statusVisa,
      },
    },
    {
      method: "PUT",
    }
  );
  yield put(updateStatusVisaSuccess(result, meta));
  Helper.toastr(
    `ID:${idVisa.join(", ")}`,
    `${statusVisa === "refuse" ? "Từ chối" : "Duyệt bài"} thành công`,
    "success"
  );
}

function* updateVisa(action) {
  const { meta } = action;

  const params = _pick(action.payload, [
    "type",
    "title",
    "thumbnail",
    "content",
    "status",
  ]);
  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  const result = yield call(
    request,
    `/api/visa/${action.payload.idVisa}`,
    { data: params },
    {
      method: "PUT",
    }
  );
  yield put(updateVisaSuccess(result, meta));
  Helper.toastr("Cập nhật bài viết thành công", "Thành công", "success");
}

function* getCountNotSeen() {
  // const { count } = yield call(
  //   request,
  //   "api/admin/Visa-manager/Visa/get-count-not-seen",
  //   {},
  //   { method: "GET" }
  // );
  // yield put(getCountNotSeenSuccess(count));
}

// function * onGetCurrentVisaError () {
//   yield put(stopLoading());
//   Helper.removeAuthToken();
// }

export default function* watchAuth() {
  yield takeEvery(getAllVisaRequest.type, safe(getAllVisa, onError));
  yield takeLeading(
    updateStatusVisaRequest.type,
    safe(updateStatusVisa, onError)
  );
  yield takeLeading(createVisaRequest.type, safe(createVisa, onError));
  yield takeLeading(updateVisaRequest.type, safe(updateVisa, onError));
  yield takeEvery(getVisaDetailRequest.type, safe(getVisaDetail));
  // yield takeLeading(getCountNotSeenRequest.type, safe(getCountNotSeen));
}
