import { useRef } from "react";
import { Row, Button, Typography } from "antd";
import { Formik, Form } from "formik";
import _get from "lodash/get";
import { useNavigate } from "react-router-dom";

import { TextInput, TextEditor, ImageUpload, Select } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "components/BackButton";
import { createNewsFonehouseRequest } from "providers/NewsFonehouseProvider/slice";
import "./styles.less";
import Helper from "utils/helpers";
import { NEWS_TAG } from "utils/constants";
import { createNewsFonehouseValidate } from "./validate";

const NewsFonehouseCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCreating = useSelector((state) =>
    _get(state, "newsFonehouse.isCreating")
  );
  const formikRef = useRef();

  const handleCreateNewsFonehouse = (values) => {
    dispatch(createNewsFonehouseRequest(values)).then((data) =>
      navigate(`/fonehouse/news-fonehouse/${data._id}`, {
        state: { canGoBack: true },
        replace: true,
      })
    );
  };

  return (
    <div className="news-fonehouse-create-page">
      <Row className="news-fonehouse-create-header" justify="space-between">
        <Row>
          <BackButton />
          <Typography.Title level={4}>Đăng bài</Typography.Title>
        </Row>
        <Button
          loading={isCreating}
          size="large"
          type="primary"
          onClick={() => formikRef.current.handleSubmit()}
        >
          Đăng bài
        </Button>
      </Row>
      <div className="create-form-wrapper">
        <Formik
          innerRef={formikRef}
          initialValues={{ content: "", title: "", thumbnail: null }}
          validationSchema={createNewsFonehouseValidate}
          onSubmit={handleCreateNewsFonehouse}
        >
          {() => (
            <Form>
              <Row justify="space-between">
                <TextInput name="title" placeholder="Tiêu đề" size="large" />
              </Row>
              <Select
                label="Tag bài viết"
                name="type"
                required
                options={Helper.convertObjectToOptions(NEWS_TAG)}
              />
              <Row justify="space-between" className="editor-wrapper">
                <ImageUpload name="thumbnail" />
                <TextEditor name="content" />
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewsFonehouseCreate;
