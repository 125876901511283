import { Row, Typography, Button, Dropdown, Menu, Tabs } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import Table from "components/common/Table";
import TabHeader from "components/TabHeader";
import { StoreColumn } from "components/Columns";
import { ROLES, STORE_TYPE } from "utils/constants";
import { useQuery } from "hooks";
import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import {
  getStoreListRequest,
  lockStoreRequest,
  unlockStoreRequest,
  pinPromotionalRequest,
  setSelectedIds,
  resetStoreState,
} from "providers/StoreProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import SearchBar from "./SearchBar";

const { TabPane } = Tabs;

const Restaurant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list, total, isLoading, selectedRowKeys, selectedRows } = useSelector(
    (state) => state.store
  );
  const roleCurrentUser = useSelector((state) =>
    _get(state, "auth.currentUser.role")
  );
  const isAdminPermission = [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(
    roleCurrentUser
  );

  const [searchObject, setSearch] = useQuery(getStoreListRequest, {
    arrayParams: ["status"],
    customParameter: {
      type: STORE_TYPE.RESTAURANT,
    },
  });

  const restaurantIdList = [];
  const restaurantNameList = [];
  selectedRows.map((item) => restaurantIdList.push(item._id));
  selectedRows.map((item) => restaurantNameList.push(item.name));

  const isDisableLockBtn = !!selectedRows.find(
    (item) => item.status === "lock"
  );
  const isDisableUnlockBtn = !!selectedRows.find(
    (item) => item.status === "active"
  );

  useEffect(
    () => () => {
      dispatch(resetStoreState());
    },
    [dispatch]
  );

  const menuAll = () => (
    <Menu>
      <Menu.Item
        className={
          isDisableLockBtn || _isEmpty(selectedRowKeys)
            ? ""
            : "lock-bussiness-btn"
        }
        disabled={isDisableLockBtn || _isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: "Xác nhận",
                cancelText: "Huy",
                title: "Xác nhận khóa doanh nghiệp",
                content: `Bạn có muốn khóa ${restaurantNameList.join(", ")}`,
                onOk: () =>
                  dispatch(
                    lockStoreRequest({
                      storeIds: selectedRowKeys,
                    })
                  ).then(() =>
                    dispatch(
                      getStoreListRequest({
                        ...searchObject,
                        type: STORE_TYPE.RESTAURANT,
                      })
                    )
                  ),
              },
            })
          )
        }
      >
        {i18n.t("restaurant.lock")}
      </Menu.Item>
      <Menu.Item
        className={
          isDisableUnlockBtn || _isEmpty(selectedRowKeys)
            ? ""
            : "unlock-bussiness-btn"
        }
        disabled={isDisableUnlockBtn || _isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: "Xác nhận",
                cancelText: "Huy",
                title: "Xác nhận mở khóa doanh nghiệp",
                content: `Bạn có muốn mở khóa ${restaurantNameList.join(", ")}`,
                onOk: () =>
                  dispatch(
                    unlockStoreRequest({
                      storeIds: selectedRowKeys,
                    })
                  ).then(() =>
                    dispatch(
                      getStoreListRequest({
                        ...searchObject,
                        type: STORE_TYPE.RESTAURANT,
                      })
                    )
                  ),
              },
            })
          )
        }
      >
        {i18n.t("restaurant.unlock")}
      </Menu.Item>
    </Menu>
  );
  const menu = (record) => {
    const { _id, status, pin } = record;
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            navigate(`/restaurant/${_id}`, { state: { canGoBack: true } })
          }
        >
          {i18n.t("restaurant.seeDetail")}
        </Menu.Item>
        {isAdminPermission && (
          <>
            <Menu.Item
              onClick={() => {
                dispatch(
                  setPopup({
                    isOpen: true,
                    data: {
                      okText: "Xác nhận",
                      cancelText: "Huy",
                      title: `Xác nhận ${
                        pin === 0 ? "ghim" : "bỏ ghim"
                      } doanh nghiệp`,
                      content: `Bạn có muốn ${pin === 0 ? "ghim" : "bỏ ghim"} ${
                        record.name
                      }`,
                      onOk: () =>
                        dispatch(
                          pinPromotionalRequest({
                            _id,
                            pin: pin === 0 ? 1 : 0,
                          })
                        ).then(() =>
                          dispatch(
                            getStoreListRequest({
                              ...searchObject,
                              type: STORE_TYPE.RESTAURANT,
                            })
                          )
                        ),
                    },
                  })
                );
              }}
            >
              {pin === 0
                ? i18n.t("restaurant.pinPromotional")
                : i18n.t("restaurant.unpinPromotional")}
            </Menu.Item>
            <Menu.Item
              className={status === "active" ? "" : "unlock-bussiness-btn"}
              disabled={status === "active"}
              onClick={() => {
                dispatch(
                  setPopup({
                    isOpen: true,
                    data: {
                      okText: "Xác nhận",
                      cancelText: "Huy",
                      title: "Xác nhận mở khóa doanh nghiệp",
                      content: `Bạn có muốn mở khóa ${record.name}`,
                      onOk: () =>
                        dispatch(
                          unlockStoreRequest({
                            storeIds: [_id],
                          })
                        ).then(() =>
                          dispatch(
                            getStoreListRequest({
                              ...searchObject,
                              type: STORE_TYPE.RESTAURANT,
                            })
                          )
                        ),
                    },
                  })
                );
              }}
            >
              {i18n.t("restaurant.unlock")}
            </Menu.Item>
            <Menu.Item
              className={status === "lock" ? "" : "lock-bussiness-btn"}
              disabled={status === "lock"}
              onClick={() => {
                dispatch(
                  setPopup({
                    isOpen: true,
                    data: {
                      okText: "Xác nhận",
                      cancelText: "Huy",
                      title: "Xác nhận khóa doanh nghiệp",
                      content: `Bạn có muốn khóa ${record.name}`,
                      onOk: () =>
                        dispatch(
                          lockStoreRequest({
                            storeIds: [_id],
                          })
                        ).then(() =>
                          dispatch(
                            getStoreListRequest({
                              ...searchObject,
                              type: STORE_TYPE.RESTAURANT,
                            })
                          )
                        ),
                    },
                  })
                );
              }}
            >
              {i18n.t("restaurant.lock")}
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const columns = StoreColumn({});

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedIds({ record, selected, selectedRows: _selectedRows })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedIds({ selected, selectedRows: _selectedRows, changeRows })
      );
    },
  };
  return (
    <div className="restaurant-list-wrapper">
      <Row className="restaurant-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("restaurant.listTitle")}
        </Typography.Title>
        <Button
          size="large"
          type="primary"
          onClick={() =>
            navigate("/restaurant/create", { state: { canGoBack: true } })
          }
        >
          Tạo nhà hàng
        </Button>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Tabs
          className="restaurant-tabs-container"
          activeKey={searchObject.mine}
          type="card"
          onChange={(key) => {
            setSearch(
              {
                mine: key,
                keyword: searchObject.keyword,
                status: searchObject.status,
                activeStatus: searchObject.activeStatus,
              },
              true
            );
          }}
        >
          {["", true, "pin"].map((value) => (
            <TabPane
              className="status-tab-pane"
              tab={<TabHeader status={value} total={100} />}
              key={value}
            >
              <Table
                rowSelection={rowSelection}
                scroll={{ x: "max-content" }}
                loading={isLoading}
                bordered
                columns={[
                  columns.id,
                  columns.email,
                  columns.phone,
                  columns.accountHolder,
                  columns.name,
                  columns.address,
                  columns.rating,
                  columns.status,
                  columns.pin,
                  columns.isPromotionActive,
                  {
                    title: (
                      <Dropdown
                        overlay={isAdminPermission ? menuAll : ""}
                        placement="bottomLeft"
                        arrow={isAdminPermission}
                      >
                        <Button
                          size="large"
                          className="see-more-btn"
                          type="ghost"
                          icon={<img src={IconSeeMore} alt="see-more" />}
                        />
                      </Dropdown>
                    ),
                    width: 100,
                    render: (record) => actionButtons(record),
                  },
                ]}
                dataSource={list}
                rowKey={(data) => data._id}
                total={total}
                onRow={(record) => ({
                  onDoubleClick: () => {
                    navigate(`/restaurant/${record._id}`, {
                      state: { canGoBack: true },
                    });
                  },
                })}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default Restaurant;
