import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";

const guideFonehouseSlice = createSlice({
  name: "guideFonehouse",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    isCreating: false,
    detail: {},
    unreadGuide: 0,
  },
  reducers: {
    getAllGuideFonehouseRequest(state) {
      return { ...state, isLoading: true };
    },
    getAllGuideFonehouseSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs,
      };
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isCreating: false };
    },
    setSelectedGuideFonehouseIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    getGuideFonehouseDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getGuideFonehouseDetailSuccess(state, action) {
      return { ...state, isLoading: false, detail: action.payload };
    },
    createGuideFonehouseRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createGuideFonehouseSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateGuideFonehouseRequest: {
      reducer: (state) => {
        state.isCreating = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateGuideFonehouseSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    deleteGuideFonehouseRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    deleteGuideFonehouseSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
        selectedRowKeys: [],
        selectedRows: [],
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    pinGuideFonehouseRequest: {
      reducer: (state) => ({
        ...state,
        isLoading: true,
      }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    pinGuideFonehouseSuccess: {
      reducer: (state) => ({ ...state, isLoading: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
});

export const {
  pinGuideFonehouseRequest,
  pinGuideFonehouseSuccess,
  getAllGuideFonehouseRequest,
  getAllGuideFonehouseSuccess,
  setSelectedGuideFonehouseIds,
  getGuideFonehouseDetailRequest,
  getGuideFonehouseDetailSuccess,
  createGuideFonehouseRequest,
  createGuideFonehouseSuccess,
  updateGuideFonehouseRequest,
  updateGuideFonehouseSuccess,
  deleteGuideFonehouseRequest,
  deleteGuideFonehouseSuccess,
  stopLoading,
} = guideFonehouseSlice.actions;

export default guideFonehouseSlice.reducer;
