import { Row, Typography, Button, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import _get from "lodash/get";
import { AdminManagerColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import {
  updateChatAdminRequest,
  getAdminManagerListRequest,
  setSelectedBusinessIds,
  updateAccuracyRequest,
} from "providers//AdminManagerProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import SearchBar from "./SearchBar";

const AdminManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminList = useSelector((state) => _get(state, "adminManager.docs"));
  const adminTotal = useSelector((state) =>
    _get(state, "adminManager.totalDocs")
  );
  const isLoading = useSelector((state) =>
    _get(state, "adminManager.isLoading")
  );
  const selectedRowKeys = useSelector((state) =>
    _get(state, "adminManager.selectedRowKeys", [])
  );

  const [searchObject] = useQuery(getAdminManagerListRequest, {
    arrayParams: ["status"],
  });

  const menuAll = () => (
    <Menu>
      <Menu.Item
        onClick={() =>
          dispatch(
            updateChatAdminRequest({ idUser: selectedRowKeys, roleChat: true })
          ).then(() => dispatch(getAdminManagerListRequest(searchObject)))
        }
      >
        {i18n.t("adminManager.accuracy")}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          dispatch(
            updateChatAdminRequest({ idUser: selectedRowKeys, roleChat: false })
          ).then(() => dispatch(getAdminManagerListRequest(searchObject)))
        }
      >
        {i18n.t("adminManager.deninedAccuracy")}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          dispatch(
            updateAccuracyRequest({ idUser: selectedRowKeys, role: "user" })
          ).then(() => dispatch(getAdminManagerListRequest(searchObject)))
        }
      >
        {i18n.t("adminManager.denineAdmin")}
      </Menu.Item>
    </Menu>
  );
  const menu = (record) => {
    const { _id, roleChat } = record;
    return (
      <Menu>
        <Menu.Item>
          <Link to={`/cms_admin_manager/${_id}`} state={{ canGoBack: true }}>
            {i18n.t("adminManager.seeDetail")}
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={roleChat}
          onClick={() =>
            dispatch(
              setPopup({
                isOpen: true,
                data: {
                  title: "Xác nhận phân quyền Chat",
                  content: "Xác nhận phân quyền Chat cho những User này?",
                  onOk: () =>
                    dispatch(
                      updateChatAdminRequest({
                        idUser: [_id],
                        roleChat: true,
                      })
                    ).then(() =>
                      dispatch(getAdminManagerListRequest(searchObject))
                    ),
                },
              })
            )
          }
        >
          {i18n.t("adminManager.accuracy")}
        </Menu.Item>
        <Menu.Item
          disabled={!roleChat}
          onClick={() =>
            dispatch(
              setPopup({
                isOpen: true,
                data: {
                  title: "Xác nhận thu hồi quyền Chat",
                  content: "Xác nhận thu hồi quyền Chat cho những User này?",
                  onOk: () =>
                    dispatch(
                      updateChatAdminRequest({
                        idUser: [_id],
                        roleChat: false,
                      })
                    ).then(() =>
                      dispatch(getAdminManagerListRequest(searchObject))
                    ),
                },
              })
            )
          }
        >
          {i18n.t("adminManager.deninedAccuracy")}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            dispatch(
              setPopup({
                isOpen: true,
                data: {
                  title: "Xác nhận thu hồi quyền Admin",
                  content: "Xác nhận thu hồi quyền Admin cho những User này?",
                  onOk: () =>
                    dispatch(
                      updateAccuracyRequest({
                        idUser: [_id],
                        role: "user",
                      })
                    ).then(() =>
                      dispatch(getAdminManagerListRequest(searchObject))
                    ),
                },
              })
            )
          }
        >
          {i18n.t("adminManager.denineAdmin")}
        </Menu.Item>
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const columns = [
    AdminManagerColumn.idUser,
    AdminManagerColumn.phone,
    AdminManagerColumn.name,
    AdminManagerColumn.email,
    AdminManagerColumn.roleChat,
    {
      title: (
        <Dropdown overlay={menuAll} placement="bottomLeft" arrow>
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      dispatch(setSelectedBusinessIds({ record, selected, selectedRows }));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      dispatch(setSelectedBusinessIds({ selected, selectedRows, changeRows }));
    },
  };
  return (
    <div className="register-list-wrapper">
      <Row className="register-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("adminManager.listTitle")}
        </Typography.Title>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Table
          rowSelection={rowSelection}
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={adminList}
          rowKey={(data) => data._id}
          total={adminTotal}
          onRow={(record) => ({
            onDoubleClick: () => {
              navigate(`/cms_admin_manager/${record._id}`, {
                state: { canGoBack: true },
              });
            },
          })}
        />
      </div>
    </div>
  );
};

export default AdminManager;
