import { useRef, useState } from "react";
import { Formik, Form } from "formik";
import i18n from "i18n";
import PropTypes from "prop-types";
import { Row, Col, Button, DatePicker } from "antd";
import { TextInput, Select } from "components/common";
import Helper from "utils/helpers";
import moment from "moment";
import {
  TIME_FRAME_TYPE,
  ORDER_FROM_TYPE,
  TRANSACTION_TYPE,
  CURRENCY_TYPE,
} from "utils/constants";
import { useParams } from "react-router";
import { cashBillType, createBillValidate } from "./validate";
import "./styles.less";

const BillCreateForm = ({ initialValues, onSubmit, loading }) => {
  const formikRef = useRef(null);
  const [billType, setBillType] = useState("");
  const params = useParams();
  const idBill = params.idBills ?? "";
  if (!initialValues.currency) initialValues.currency = CURRENCY_TYPE.JPY;
  const parseDate = (dateString) => {
    if (!dateString) return null;
    const parsedDate = moment(dateString, "DD-MM-YYYY", true);
    return parsedDate.isValid() ? parsedDate : null;
  };
  return (
    <div className="bill-create-form-wrapper">
      <Formik
        validateOnMount
        initialValues={initialValues}
        enableReinitialize
        validationSchema={
          billType === "cash" ? cashBillType : createBillValidate
        }
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ handleSubmit, values, setFieldValue, errors, touched }) => {
          if (values.transactionType) {
            setBillType(values.transactionType);
          }
          return (
            <Form>
              <Row className="form-grid">
                <Col span={12}>
                  <Select
                    label={i18n.t("createBill.transactionType")}
                    name="transactionType"
                    inputType="state"
                    required
                    options={Helper.convertObjectToOptions(TRANSACTION_TYPE)}
                  />
                  <>
                    <TextInput
                      label={i18n.t("createBill.shippingFee")}
                      name="shippingFee"
                    />
                    <TextInput
                      label={i18n.t("createBill.customId")}
                      name="orderIdCustom"
                      required={values.transactionType !== "cash"}
                    />
                  </>
                  <div style={{ marginBottom: 14 }} className="form-field">
                    <label htmlFor="expectedDeliveryDate">Ngày nhận hàng</label>
                    <DatePicker
                      id="expectedDeliveryDate"
                      name="expectedDeliveryDate"
                      format="DD-MM-YYYY"
                      style={{ width: 280, height: 60, borderRadius: 8 }}
                      value={parseDate(values.expectedDeliveryDate)}
                      onChange={(date, dateString) => {
                        setFieldValue("expectedDeliveryDate", dateString);
                      }}
                    />
                    {errors.expectedDeliveryDate &&
                      touched.expectedDeliveryDate && (
                        <div className="error-message">
                          {errors.expectedDeliveryDate}
                        </div>
                      )}
                  </div>
                  <TextInput
                    label={i18n.t("createBill.customerName")}
                    name="name"
                    required
                  />

                  <TextInput
                    label={i18n.t("createBill.postCode")}
                    name="postCode"
                    required={values.transactionType !== "cash"}
                  />
                  <Select
                    label={i18n.t("createBill.timeFrame")}
                    name="timeFrame"
                    inputType="state"
                    required
                    options={Helper.convertObjectToOptions(TIME_FRAME_TYPE)}
                  />
                </Col>
                <Col span={12}>
                  <Select
                    label={i18n.t("createBill.orderFrom")}
                    name="orderFrom"
                    required
                    options={Helper.convertObjectToOptions(ORDER_FROM_TYPE)}
                  />
                  <TextInput label={i18n.t("createBill.phone")} name="phone" />
                  <TextInput
                    inputType="autoComplete"
                    label={i18n.t("createBill.address")}
                    name="address"
                    required={values.transactionType !== "cash"}
                  />
                  <Select
                    label={i18n.t("createBill.currency")}
                    name="currency"
                    required
                    options={Helper.convertObjectToOptions(CURRENCY_TYPE)}
                  />
                </Col>
              </Row>
              <Row justify="space-between" className="button-wrapper">
                <Button
                  style={{ marginTop: 20 }}
                  loading={loading}
                  onClick={handleSubmit}
                  type="primary"
                  size="large"
                >
                  {idBill
                    ? i18n.t("createBill.updateBill")
                    : i18n.t("createBill.create")}
                </Button>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

BillCreateForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
export default BillCreateForm;
