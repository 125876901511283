import PropTypes from "prop-types";
import "./style.less";
import { Image } from "antd";

export const IdentifySummaryTooltip = (props) => {
  const { userDetail } = props;
  const { alienCards } = userDetail;

  return (
    <div className="news-summary-tooltip-wrapper">
      <div style={{ color: "black", fontWeight: "bold", fontSize: 24 }}>
        Ảnh mặt trước
      </div>
      <Image src={alienCards[0]} width="300px" preview />
      <div style={{ color: "black", fontWeight: "bold", fontSize: 24 }}>
        Ảnh mặt sau
      </div>
      <Image src={alienCards[1]} width="300px" preview />
    </div>
  );
};

IdentifySummaryTooltip.propTypes = {
  userDetail: PropTypes.object,
};

export default IdentifySummaryTooltip;
