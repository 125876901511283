import { createContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

import i18n from "i18n";

export const LocalizationContext = createContext({
  locale: "jp",
  setLocale: () => null,
  t: () => "",
});

const LocalizeProvider = ({ children }) => {
  const [locale, setLocale] = useState("jp");

  const localizationContext = useMemo(
    () => ({
      t: (scope, options) => i18n.t(scope, { locale, ...options }),
      locale,
      setLocale,
    }),
    [locale]
  );

  return (
    <LocalizationContext.Provider value={localizationContext}>
      {children}
    </LocalizationContext.Provider>
  );
};

LocalizeProvider.propTypes = {
  children: PropTypes.object,
};
export default LocalizeProvider;
