import { memo, useMemo } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import SearchBarComponent from "components/SearchBar";
import { Filter } from "components/common/Form";
import { useDispatch, useSelector } from "react-redux";
import { ARTICLE_PRODUCT_MODAL_TYPE } from "utils/constants";
import { Col, Row, Button } from "antd";
import { setCosmeticProductModal } from "providers/CosmeticProductProvider/slice";

const SearchBar = ({ isPermissionToEdit }) => {
  const dispatch = useDispatch();
  const productCosmeticTypeList = useSelector((state) =>
    _get(state, "cosmeticProduct.productCosmeticTypeList")
  );
  const formattedProductCosmeticTypeList = useMemo(
    () =>
      productCosmeticTypeList.map((productType) => ({
        value: productType._id,
        label: productType.name,
      })),
    [productCosmeticTypeList]
  );
  return (
    <SearchBarComponent
      filterFields={["cosmeticProductTypeId"]}
      isOnChangeSearch
    >
      <Col span={24} className="store-product-searchbar">
        <Row justify="space-between" align="middle">
          <Col className="l-title-text">Danh sách mặt hàng</Col>
          <Row>
            <Col flex="auto">
              {isPermissionToEdit && (
                <Button
                  type="primary"
                  size="large"
                  onClick={() =>
                    dispatch(
                      setCosmeticProductModal({
                        visible: true,
                        data: {},
                        type: ARTICLE_PRODUCT_MODAL_TYPE.CREATE,
                      })
                    )
                  }
                >
                  Tạo sản phẩm
                </Button>
              )}
            </Col>
            <Col>
              <Filter
                name="cosmeticProductTypeId"
                options={Object.values(formattedProductCosmeticTypeList)}
                popConfirmClassName="gb-role-popconfirm"
                hasSearching
              />
            </Col>
          </Row>
        </Row>
      </Col>
    </SearchBarComponent>
  );
};

SearchBar.propTypes = {
  name: PropTypes.string,
  companyValue: PropTypes.string,
  isPermissionToEdit: PropTypes.bool,
};
export default memo(SearchBar);
