/* eslint-disable react/display-name */

import i18n from "i18n";
import _get from "lodash/get";
import moment from "moment";
import { Tag } from "antd";

const Columns = {};

const renderDataValue = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

const renderTransferValue = (value) => {
  switch (value) {
    case "cash":
      return (
        <Tag color="blue" style={{}}>
          {i18n.t("billingList.cash") || i18n.t("general.none")}
        </Tag>
      );
    case "Daibiki":
      return (
        <Tag color="green" style={{}}>
          {i18n.t("billingList.daibiki") || i18n.t("general.none")}
        </Tag>
      );
    case "transfer":
      return (
        <Tag color="purple" style={{}}>
          {i18n.t("billingList.transfer") || i18n.t("general.none")}
        </Tag>
      );
    default:
      return null;
  }
};
const renderBillStatus = (value) => {
  let style;
  switch (value) {
    case "new":
      style = { color: "#26415D", fontWeight: "bold" };
      return (
        <span style={style}>
          {i18n.t("billingList.new") || i18n.t("general.none")}
        </span>
      );
    case "cancel":
      style = { color: "#fff", fontWeight: "bold" };
      return (
        <div
          style={{
            backgroundColor: "red",
            width: "fit-content",
            padding: 5,
            borderRadius: 3,
          }}
        >
          <span style={style}>
            {i18n.t("billingList.cancel") || i18n.t("general.none")}
          </span>
        </div>
      );
    case "shipped":
      style = { color: "#FFA50E", fontWeight: "bold" };
      return (
        <span style={style}>
          {i18n.t("billingList.waitting") || i18n.t("general.none")}
        </span>
      );
    case "success":
      style = { color: "#0F9154", fontWeight: "bold" };
      return (
        <span style={style}>
          {i18n.t("billingList.ordered") || i18n.t("general.none")}
        </span>
      );
    default:
      return null;
  }
};

Columns.idBill = {
  dataIndex: "orderIdCustom",
  key: "orderIdCustom",
  title: i18n.t("billingList.billCode"),
  render: (orderId) =>
    orderId ? renderDataValue(`#${orderId}`) : renderDataValue("--/--"),
  width: 120,
};

Columns.userPhone = {
  dataIndex: ["user", "phone"],
  key: "userPhone",
  title: i18n.t("billingList.phone"),
  width: 150,
  render: (phone) => renderDataValue(phone),
};

Columns.phone = {
  dataIndex: "phone",
  key: "phone",
  title: i18n.t("billingList.phone"),
  width: 120,
  render: (phone) => renderDataValue(phone),
};

Columns.createAt = {
  title: i18n.t("billingList.createAt"),
  dataIndex: "createdAt",
  key: "createdAt",
  sorter: true,
  render: (createdAt) =>
    renderDataValue(moment(createdAt).format("DD/MM/YYYY")),
  width: 120,
};

Columns.status = {
  title: i18n.t("billingList.status"),
  dataIndex: "status",
  key: "status",
  sorter: true,
  render: (status) => renderBillStatus(status),
  width: 180,
};

Columns.type = {
  title: i18n.t("billingList.tranfer"),
  dataIndex: "transactionType",
  key: "transactionType",
  render: (type) => renderTransferValue(type),
  width: 120,
};

Columns.price = {
  title: i18n.t("billingList.price"),
  dataIndex: "totalPrice",
  key: "totalPrice",
  render: (price, data) =>
    renderDataValue(
      <div>
        {price?.toLocaleString()}
        {` ${data.currency}`}
      </div>,
      { fontWeight: "bold" }
    ),
  width: 140,
  sorter: true,
};

Columns.name = {
  dataIndex: "name",
  key: "name",
  title: i18n.t("billingList.name"),
  width: 150,
  render: (name, record) =>
    renderDataValue(
      name === ""
        ? _get(record, "user.name") || _get(record, "admin.name")
        : name
    ),
};

Columns.products = {
  dataIndex: "products",
  key: "products",
  title: i18n.t("billingList.products"),
  width: 200,
  render: (products) => {
    const productName = products.map((item) => item.name);
    return (
      <div title={productName.join(", ")} className="ellipsis-text-one-line">
        {" "}
        {productName.join(", ")}{" "}
      </div>
    );
  },
};

export default Columns;
