import { Row, Col } from "antd";
import PropTypes from "prop-types";

import { ROLES_LIST, ROLES_LIST_PERMISSION } from "utils/constants";
import TypeBlock from "components/TypeBlock";
import "./styles.less";

const PermissionTooltip = ({ active }) => (
  <div className="permission-tooltip-wrapper">
    {ROLES_LIST_PERMISSION.map((role) => (
      <Row key={role.value}>
        <Col>
          <TypeBlock type={role.value} />
        </Col>
        <Col className={`tootltip-text ${active === role.value ? "bold" : ""}`}>
          {role.label}
        </Col>
      </Row>
    ))}
  </div>
);

PermissionTooltip.propTypes = {
  active: PropTypes.string,
};
export default PermissionTooltip;
