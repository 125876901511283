import { Row, Typography, Button, Dropdown, Menu } from "antd";
import _get from "lodash/get";
import { useNavigate } from "react-router-dom";
import { AccessoryColumns } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import "../Fonehouse/Guide/styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import {
  getAccessoryListRequest,
  deleteAccessoryRequest,
} from "providers/AccessoryProvider/slice";
import { useQuery } from "hooks";
import { setPopup } from "providers/GeneralProvider/slice";
import SearchBar from "./SearchBar";

const TITLE = "Danh sách Phụ Kiện";
const TITLE_DELETE = "Xác nhận xoá Phụ Kiện";
const CONTENT_DELETE = "Bạn có chắc chắn muốn xoá Phụ Kiện này không?";
const CANCEL_TEXT = "Huỷ";
const Accessories = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalDocs = useSelector((state) =>
    _get(state, "accessorySlice.totalDocs")
  );
  const isLoading = useSelector((state) =>
    _get(state, "accessorySlice.isLoading")
  );
  const accessoryList = useSelector((state) =>
    _get(state, "accessorySlice.accessoryList", [])
  );
  const [searchObject] = useQuery(getAccessoryListRequest);

  const _onNavigateToUpdate = (data) => {
    navigate("/fonehouse/accessory/create", {
      state: { canGoBack: true, accessory: data },
    });
  };

  const _onConfirmDelete = (data) => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          title: TITLE_DELETE,
          content: CONTENT_DELETE,
          okText: i18n.t("loginScreen.verifyOtp"),
          cancelText: CANCEL_TEXT,
          onOk: () => {
            dispatch(
              deleteAccessoryRequest({
                id: data._id,
                callback: () => {
                  if (totalDocs === 11) {
                    dispatch(getAccessoryListRequest({ limit: 10, page: 1 }));
                  } else {
                    dispatch(getAccessoryListRequest(searchObject));
                  }
                },
              })
            );
          },
        },
      })
    );
  };

  const menu = (data) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          _onNavigateToUpdate(data);
        }}
      >
        {i18n.t("products.editProduct")}
      </Menu.Item>
      <Menu.Item
        style={{ color: "red" }}
        onClick={() => {
          _onConfirmDelete(data);
        }}
      >
        {i18n.t("support.delete")}
      </Menu.Item>
    </Menu>
  );
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement={"bottomCenter"} arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const columns = [
    AccessoryColumns.id,
    AccessoryColumns.name,
    AccessoryColumns.thumbnail,
    AccessoryColumns.initialPrice,
    AccessoryColumns.price,
    {
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  return (
    <div className="news-fonehouse-list-wrapper">
      <Row className="news-fonehouse-list-header" justify="space-between">
        <Typography.Title level={4}>{TITLE}</Typography.Title>
        <Button
          size="large"
          type="primary"
          onClick={() =>
            navigate("/fonehouse/accessory/create", {
              state: { canGoBack: true },
            })
          }
        >
          Tạo phụ kiện
        </Button>
      </Row>
      <div className="content-container">
        <SearchBar searchObject={searchObject} />
        <Table
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={accessoryList}
          rowKey={(data) => data._id}
          total={totalDocs}
        />
      </div>
    </div>
  );
};

export default Accessories;
