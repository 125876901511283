import i18n from "i18n";
import moment from "moment";
import { renderStatusValue } from "./LiquidWithViegoColumn";

const RewardPointColumns = {};
const POINT_TO_MONEY = 3;

const renderDataValue = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

const renderTypeValue = (data) => {
  let text = "Đang chờ";
  switch (data.type) {
    case "transfer_to_store":
      text = `Chuyển điểm tới cửa hàng ${data?.store?.name}`;
      break;

    case "transfer_to_user":
      text = `Hoàn điểm từ cửa hàng ${data?.store?.name}`;
      break;

    case "order_reward":
      text = `Mua hàng Fonehouse - Đơn hàng ${data?.order?.orderId}`;
      break;

    default:
      break;
  }
  return <span>{text}</span>;
};

const renderPointValue = (point) => {
  let color = "#7C590A";
  if (point > 0) {
    color = "#00A206";
  } else {
    color = "#FF0000";
  }
  return (
    <span style={{ color, fontWeight: "bold" }}>{point?.toLocaleString()}</span>
  );
};

const renderStateValue = (value) => {
  let color = "#7C590A";
  let text = "Đang chờ";
  switch (value) {
    case "pending":
      color = "#7C590A";
      text = "Đang chờ";
      break;

    case "success":
      color = "#00A206";
      text = "Thành công";
      break;

    case "transfer_to_user":
      color = "#FF0000";
      text = "Hoàn điểm tới user";
      break;

    default:
      break;
  }
  return <span style={{ color, fontWeight: "bold" }}>{text}</span>;
};

RewardPointColumns._id = {
  dataIndex: "transaction",
  key: "_id",
  title: "#",
  render: (transaction) => renderDataValue(`#${transaction._id}`),
  width: 50,
};
RewardPointColumns.time = {
  dataIndex: "transaction",
  key: "createdAt",
  title: "Thời điểm",
  width: 150,
  render: (transaction) =>
    renderDataValue(moment(transaction.createdAt).format("DD/MM/YYYY HH:mm")),
};

RewardPointColumns.type = {
  title: "Tiêu đề",
  dataIndex: ["type", "store"],
  render: (_, store) => renderTypeValue(store),
};

RewardPointColumns.totalPrice = {
  title: "Tổng tiền",
  dataIndex: ["storeDetail", "phone"],
  render: (totalPrice) => renderDataValue(totalPrice),
  width: 200,
};
RewardPointColumns.pointRanking = {
  title: "Tích lũy thăng hạng",
  dataIndex: ["storeDetail", "address"],
  render: (address) => renderDataValue(address),
  width: 200,
};
RewardPointColumns.point = {
  title: "Số điểm",
  dataIndex: "transaction",
  key: "amount",
  render: (transaction) => renderPointValue(transaction?.amount),
};
RewardPointColumns.timeLiquid = {
  title: "Thời điểm TK",
  dataIndex: "transaction",
  key: "createdAt",
  render: (transaction) =>
    renderDataValue(moment(transaction.createdAt).format("DD/MM/YYYY HH:mm")),
  width: 200,
};
RewardPointColumns.contentTransferStore = {
  title: "Nội dung",
  dataIndex: "content",
  key: "content",
  render: (content) => renderDataValue(content),
  width: 200,
};
RewardPointColumns.email = {
  title: "Email",
  dataIndex: "email",
  key: "email",
  render: (content) => renderDataValue(content),
  width: 200,
};
RewardPointColumns.phone = {
  title: "SDT",
  dataIndex: "phone",
  key: "phone",
  render: (content) => renderDataValue(content),
  width: 200,
};
RewardPointColumns.store = {
  title: "Cửa hàng",
  dataIndex: "store",
  key: "name",
  render: (store) => renderDataValue(store?.name),
  width: 200,
};
RewardPointColumns.bankAccount = {
  title: "STK ngân hàng",
  dataIndex: "bankAccount",
  key: "bankAccount",
  render: (content) => renderDataValue(content),
  width: 200,
};
RewardPointColumns.state = {
  title: "Trạng thái",
  dataIndex: "transaction",
  key: "status",
  render: (transaction) => renderStateValue(transaction?.status),
  width: 200,
};
RewardPointColumns.status = {
  title: "Trạng thái",
  dataIndex: "transaction",
  key: "state",
  render: (transaction) => renderStatusValue(transaction.status),
};
RewardPointColumns.storePointToMoney = {
  title: `Số tiền qui đổi (${POINT_TO_MONEY} điểm 1 JPY)`,
  dataIndex: "transaction",
  key: "amount",
  render: (transaction) =>
    renderDataValue(
      `${(transaction?.amount / POINT_TO_MONEY)?.toLocaleString()} JPY`
    ),
};
RewardPointColumns.viegoPointToMoney = {
  title: `Số tiền qui đổi (${POINT_TO_MONEY} điểm 1 JPY)`,
  dataIndex: "transaction",
  key: "amount",
  render: (transaction) =>
    renderDataValue(
      `${(transaction?.amount / POINT_TO_MONEY)?.toLocaleString()} JPY`
    ),
};

export default RewardPointColumns;
