import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Typography, Menu, Dropdown, Button } from "antd";
import _isEmpty from "lodash/isEmpty";
import _uniq from "lodash/uniq";
import _get from "lodash/get";
import "./styles.less";
import BackButton from "components/BackButton";
import {
  getRestaurantProductListRequest,
  setSelectedIds,
  deleteProductRestaurantRequest,
  setMarketProductModal,
  setTagProductArticleModalData,
  setTagProductRestaurantRequest,
} from "providers/RestaurantProductProvider/slice";
import RestaurantDetailComponent from "components/Restaurant/Detail";
import RestaurantEdit from "components/Restaurant/Edit";
import Table from "components/common/Table";
import { useQuery } from "hooks";
import { RestaurantProductColumn } from "components/Columns";
import i18n from "i18n";
import { IconSeeMore } from "assets/images";
import {
  ARTICLE_PRODUCT_MODAL_TYPE,
  ROLES,
  STORE_TYPE,
  DELETE_BANNER_CONTENT,
} from "utils/constants";
import { resetSelectedRowKey } from "providers/AdminProvider/slice";
import {
  setPopup,
  setChangeStoreOnwerModal,
} from "providers/GeneralProvider/slice";
import {
  getStoreDetailRequest,
  updateStoreRequest,
  changeStoreOwnerRequest,
  resetStoreState,
  createStoreBannerRequest,
  deleteStoreBannerRequest,
  editStoreBannerRequest,
  getStoreBannerListRequest,
  getDiscountConditionsRequest,
  getDiscountPercentagesRequest,
} from "providers/StoreProvider/slice";
import SetTagProductArticleModal from "components/SetTagProductArticleModal";
import ArticleShopOwner from "components/ArticleShopOwner";
import { Tabs } from "components/common";
import StoreBannerList from "components/StoreBanner/List";
import StoreBannerDetail from "components/StoreBanner/Detail";
import Helper from "utils/helpers";
import moment from "moment";
import RestaurantProductModal from "../RestaurantProduct/restaurantProductModal";
import SearchBar from "./SearchBar";

const RestaurantDetail = () => {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const { detail, isLoading, banners, isLoadingBanner } = useSelector(
    (state) => state.store
  );
  const [banner, setBanner] = useState({});
  const [currentTab, setCurrentTab] = useState("detail");
  const { userId = {} } = detail;
  const idCurrentUser = useSelector((state) =>
    _get(state, "auth.currentUser._id")
  );
  const roleCurrentUser = useSelector((state) =>
    _get(state, "auth.currentUser.role")
  );
  const isPermissionToEdit =
    idCurrentUser === userId._id ||
    [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(roleCurrentUser);
  const selectedRows = useSelector((state) =>
    _get(state, "restaurantProduct.selectedRows", [])
  );
  const selectedRowKeys = useSelector((state) =>
    _get(state, "restaurantProduct.selectedRowKeys", [])
  );
  const { restaurantId } = useParams();
  const [searchObject, setSearch] = useQuery(getRestaurantProductListRequest, {
    arrayParams: ["productType"],
    customParameter: {
      restaurantId,
    },
  });
  const productMarketIdList = [];
  const productMarketNameList = [];
  selectedRows.map((item) =>
    productMarketIdList.push(item.productRestaurantId)
  );
  selectedRows.map((item) => productMarketNameList.push(item.name));
  useEffect(() => {
    dispatch(getDiscountConditionsRequest());
    dispatch(getDiscountPercentagesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (restaurantId) {
      dispatch(getStoreDetailRequest({ _id: restaurantId }));
      dispatch(getStoreBannerListRequest({ storeId: restaurantId }));
    }
    return () => {
      dispatch(resetStoreState());
    };
  }, [restaurantId, dispatch]);

  const menuAll = () => (
    <Menu>
      <Menu.Item
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: "Xác nhận",
                cancelText: "Huy",
                title: "Xác nhận xóa sản phẩm",
                content: `Bạn có muốn xóa: ${productMarketNameList.join(", ")}`,
                onOk: () =>
                  dispatch(
                    deleteProductRestaurantRequest({
                      productIds: selectedRowKeys,
                      storeId: restaurantId,
                      productRestaurantIds: _uniq(productMarketIdList),
                    })
                  ).then(() =>
                    dispatch(getRestaurantProductListRequest(searchObject))
                  ),
              },
            })
          )
        }
        disabled={_isEmpty(selectedRowKeys)}
      >
        {i18n.t("marketProduct.delete")}
      </Menu.Item>
      <Menu.Item
        disabled={_isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            setTagProductArticleModalData({
              visible: true,
              data: {
                products: selectedRows,
                originalProducts: selectedRows,
              },
            })
          )
        }
      >
        {i18n.t("products.setTag")}
      </Menu.Item>
    </Menu>
  );
  const menu = (record) => {
    const { _id, name, productRestaurantId } = record;
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            dispatch(
              setMarketProductModal({
                visible: true,
                data: record,
                type: ARTICLE_PRODUCT_MODAL_TYPE.DETAIL,
              })
            )
          }
        >
          {i18n.t("marketProduct.seeDetail")}
        </Menu.Item>
        {isPermissionToEdit && (
          <>
            <Menu.Item
              onClick={() =>
                dispatch(
                  setMarketProductModal({
                    visible: true,
                    data: record,
                    type: ARTICLE_PRODUCT_MODAL_TYPE.EDIT,
                  })
                )
              }
            >
              {i18n.t("marketProduct.edit")}
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                dispatch(
                  setPopup({
                    isOpen: true,
                    data: {
                      okText: "Xác nhận",
                      cancelText: "Huy",
                      title: "Xác nhận xóa sản phẩm",
                      content: `Bạn có muốn xóa: ${name}`,
                      onOk: () =>
                        dispatch(
                          deleteProductRestaurantRequest({
                            productIds: [_id],
                            storeId: restaurantId,
                            productRestaurantIds: [productRestaurantId],
                          })
                        ).then(() =>
                          dispatch(
                            getRestaurantProductListRequest(searchObject)
                          )
                        ),
                    },
                  })
                );
              }}
            >
              {i18n.t("marketProduct.delete")}
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                dispatch(
                  setTagProductArticleModalData({
                    visible: true,
                    data: {
                      products: [record],
                      originalProducts: [record],
                    },
                  })
                )
              }
            >
              {i18n.t("products.setTag")}
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const marketProductList = useSelector((state) =>
    _get(state, "restaurantProduct.docs")
  );
  const marketProductTotal = useSelector((state) =>
    _get(state, "restaurantProduct.totalDocs")
  );
  const visible = useSelector(
    (state) => state.restaurantProduct.setTagProductArticleModalData.visible
  );
  const products = useSelector((state) =>
    _get(
      state.restaurantProduct.setTagProductArticleModalData,
      "data.products",
      []
    )
  );
  const originalProducts = useSelector((state) =>
    _get(
      state.restaurantProduct.setTagProductArticleModalData,
      "data.originalProducts",
      []
    )
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedIds({ record, selected, selectedRows: _selectedRows })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedIds({ selected, selectedRows: _selectedRows, changeRows })
      );
    },
  };

  const columns = [
    RestaurantProductColumn.productId,
    RestaurantProductColumn.name,
    RestaurantProductColumn.type,
    RestaurantProductColumn.amount,
    RestaurantProductColumn.promotion,
    RestaurantProductColumn.price,
    RestaurantProductColumn.promotionPrice,
    RestaurantProductColumn.tag,
    {
      title: (
        <Dropdown
          overlay={isPermissionToEdit ? menuAll : ""}
          placement="bottomLeft"
          arrow={isPermissionToEdit}
        >
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  const handleSubmit = async (values) => {
    const latLng = await Helper.getLatLng(values.address, {
      lng: values.lng,
      lat: values.lat,
    });
    values.longitude = latLng.lng;
    values.latitude = latLng.lat;
    if (
      values.discountPercentage?.value === detail?.discountPercentage?.value &&
      values.discountPercentage?.value
    ) {
      values.discountPercentage = detail?.discountPercentage?.value;
    }
    if (values.start) {
      values.customDiscountCondition = {
        start: values.start,
        end: values.end,
      };
    }
    dispatch(
      updateStoreRequest({
        ...values,
        _id: restaurantId,
        type: STORE_TYPE.RESTAURANT,
      })
    ).then(() => {
      dispatch(getStoreDetailRequest({ _id: restaurantId }));
      setShowEdit(false);
    });
  };

  const handleChangeStoreOnwer = ({ type, idNewOnwer }) => {
    dispatch(
      changeStoreOwnerRequest({
        type,
        _id: restaurantId,
        ownerId: idNewOnwer,
      })
    ).then(() => {
      dispatch(resetSelectedRowKey());
      dispatch(getStoreDetailRequest({ _id: restaurantId }));
      dispatch(setChangeStoreOnwerModal({ isOpen: false }));
    });
  };

  const handleChangeTab = (activeKey) => {
    setCurrentTab(activeKey);
    setBanner({});
  };

  const handleSubmitBanner = (values) => {
    const imageUrl = _get(values, "imageUrl.url", "");
    if (banner.imageUrl === null) {
      dispatch(
        createStoreBannerRequest({ ...values, imageUrl, storeId: restaurantId })
      ).then(() => {
        dispatch(getStoreBannerListRequest({ storeId: restaurantId }));
      });
    } else {
      dispatch(
        editStoreBannerRequest({
          ...values,
          imageUrl,
          storeId: restaurantId,
          bannerId: banner._id,
        })
      ).then(() => {
        dispatch(getStoreBannerListRequest({ storeId: restaurantId }));
      });
    }
    setBanner({});
  };

  const handleRemoveBanner = (record) => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          ...DELETE_BANNER_CONTENT,
          onOk: () =>
            dispatch(
              deleteStoreBannerRequest({
                storeId: restaurantId,
                bannerId: record._id,
              })
            ).then(() => {
              dispatch(getStoreBannerListRequest({ storeId: restaurantId }));
            }),
        },
      })
    );
  };

  const handleToggleBannerStatus = (record) => {
    dispatch(
      editStoreBannerRequest({
        ...record,
        storeId: restaurantId,
        bannerId: record._id,
        isActive: !record.isActive,
      })
    ).then(() => {
      dispatch(getStoreBannerListRequest({ storeId: restaurantId }));
    });
  };

  return (
    <div className="restaurant-detail-wrapper">
      <SetTagProductArticleModal
        category="restaurant"
        setTagProductArticleModalData={setTagProductArticleModalData}
        visible={visible}
        products={_uniq(products)}
        originalProducts={_uniq(originalProducts)}
        setTagProductArticleRequest={setTagProductRestaurantRequest}
        getProductArticleListRequest={getRestaurantProductListRequest}
        searchObject={searchObject}
      />
      <RestaurantProductModal
        isPermissionToEdit={isPermissionToEdit}
        searchObject={searchObject}
        setMarketProductModal={setMarketProductModal}
        setSearch={setSearch}
      />
      <Row
        className="restaurant-detail-header"
        justify="space-between"
        align="middle"
      >
        <Row>
          <BackButton />
          <Typography.Title level={4}>Thông tin nhà hàng</Typography.Title>
        </Row>
      </Row>
      <div className="detail-wrapper">
        <ArticleShopOwner
          category="restaurant"
          shopOwnerInfoDetail={_get(detail, "userId", {})}
          handleChangeStoreOnwer={handleChangeStoreOnwer}
        />
        <Tabs
          type="card"
          activeKey={currentTab}
          onChange={handleChangeTab}
          destroyInactiveTabPane
          contents={[
            {
              key: "detail",
              header: "Thông tin cơ bản",
              content: showEdit ? (
                <RestaurantEdit
                  initialValues={detail}
                  onCancel={() => setShowEdit(false)}
                  onSubmit={handleSubmit}
                />
              ) : (
                <RestaurantDetailComponent
                  isPermissionToEdit={isPermissionToEdit}
                  detail={detail}
                  onEdit={() => setShowEdit(true)}
                />
              ),
            },
            {
              key: "banner",
              header: "Set banner",
              content: _isEmpty(banner) ? (
                <StoreBannerList
                  list={banners}
                  total={banners.length}
                  loading={isLoadingBanner}
                  handleOpenBannerDetail={(data) => setBanner(data)}
                  handleRemoveBanner={handleRemoveBanner}
                  handleToggleStatus={handleToggleBannerStatus}
                />
              ) : (
                <StoreBannerDetail
                  initialValues={banner}
                  isEditBanner={banner.imageUrl !== null}
                  handleCancel={() => setBanner({})}
                  handleSubmit={handleSubmitBanner}
                />
              ),
            },
          ]}
        />
        <div className="content-container">
          <SearchBar isPermissionToEdit={isPermissionToEdit} />
          <Table
            rowSelection={rowSelection}
            scroll={{ x: 1000 }}
            loading={isLoading}
            bordered
            columns={columns}
            dataSource={marketProductList}
            rowKey={(data) => data._id}
            total={marketProductTotal}
          />
        </div>
      </div>
    </div>
  );
};

export default RestaurantDetail;
