import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  deleteSimRequest,
  restoreSimRequest,
  changeSimStatusRequest,
  deleteSimPostRequest,
  restoreSimPostRequest,
  setReadWifiRecordRequest,
  setReadInternetRecordRequest,
  openAddingCustomFieldModal,
  updateOrderID,
} from "providers/SimProvider/slice";
import { createConversationRequest } from "providers/MessengerProvider/slice";

const MenuOverlay = ({
  type,
  record,
  refetchList,
  handleOpenRegistationDetail,
  handleOpenInternetRegistationDetail,
  handleOpenWifiRegistationDetail,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToChat = (userId) => {
    dispatch(createConversationRequest({ receiverId: userId })).then(
      (result) => {
        navigate(`/fonehouse/messengers/${result._id}`);
      }
    );
  };
  return (
    <Menu>
      {(type === "WIFI" || type === "INTERNET") && (
        <>
          <Menu.Item
            onClick={() => {
              if (type === "WIFI") {
                dispatch(setReadWifiRecordRequest({ idPost: record._id }));
                handleOpenWifiRegistationDetail(record);
              }
              if (type === "INTERNET") {
                dispatch(setReadInternetRecordRequest({ idPost: record._id }));
                handleOpenInternetRegistationDetail(record);
              }
            }}
          >
            Xem chi tiết
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              navigateToChat(record.createdBy);
            }}
          >
            Nhắn tin cho khách
          </Menu.Item>
        </>
      )}
      {(type === "SIM" || !type) && (
        <>
          <Menu.Item
            onClick={() => {
              navigate(`/sim/${record._id}`, {
                state: { canGoBack: true },
              });
            }}
          >
            Chỉnh sửa
          </Menu.Item>
          <Menu.Item
            className="lock-bussiness-btn"
            onClick={() => {
              dispatch(deleteSimRequest(record)).then(() => {
                refetchList();
              });
            }}
          >
            Xóa SIM
          </Menu.Item>
        </>
      )}
      {type === "POST" && (
        <>
          <Menu.Item
            className="unlock-bussiness-btn"
            onClick={() => {
              navigate(`/sim/${record._id}/edit`, {
                state: { canGoBack: true },
              });
            }}
          >
            Chỉnh sửa bài viết
          </Menu.Item>
          <Menu.Item
            className="lock-bussiness-btn"
            onClick={() => {
              dispatch(deleteSimPostRequest({ postId: record._id })).then(
                () => {
                  refetchList();
                }
              );
            }}
          >
            Xoá bài viết
          </Menu.Item>
        </>
      )}
      {type === "DELETED" && (
        <Menu.Item
          className="unlock-bussiness-btn"
          onClick={() => {
            dispatch(restoreSimPostRequest(record)).then(() => {
              refetchList();
            });
          }}
        >
          Khôi phục
        </Menu.Item>
      )}
      {type === "SIM_DELETED" && (
        <Menu.Item
          className="unlock-bussiness-btn"
          onClick={() => {
            dispatch(restoreSimRequest(record)).then(() => {
              refetchList();
            });
          }}
        >
          Khôi phục
        </Menu.Item>
      )}
      {["REQUESTED", "SIM_DATA_SOFT_BANK"].includes(type) && (
        <>
          <Menu.Item
            onClick={() => {
              handleOpenRegistationDetail(record);
            }}
          >
            Xem chi tiết
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              dispatch(openAddingCustomFieldModal(true));
              dispatch(updateOrderID(record._id));
              dispatch(
                changeSimStatusRequest({
                  _id: record._id,
                  status: "BEING_TRANSPORTED",
                })
              ).then(() => {
                refetchList();
              });
            }}
          >
            Đã gửi hàng
          </Menu.Item>
          <Menu.Item
            className="unlock-bussiness-btn"
            onClick={() => {
              dispatch(
                changeSimStatusRequest({
                  _id: record._id,
                  status: "DELIVERED",
                })
              ).then(() => {
                refetchList();
              });
            }}
          >
            Đã giao
          </Menu.Item>
          <Menu.Item
            className="lock-bussiness-btn"
            onClick={() => {
              dispatch(
                changeSimStatusRequest({
                  _id: record._id,
                  status: "CANCELLED",
                })
              ).then(() => {
                refetchList();
              });
            }}
          >
            Đã huỷ
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              navigateToChat(record.createdBy._id);
            }}
          >
            Nhắn tin cho khách
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};

export default MenuOverlay;
