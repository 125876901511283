import { forwardRef, useEffect, useRef, useState } from "react";
import { Button } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _invoke from "lodash/invoke";
import { Formik, Form } from "formik";
import {
  CameraOutlined,
  CloseCircleFilled,
  FileAddFilled,
} from "@ant-design/icons";
import { TextInput } from "components/common";
import "./styles.less";
import SendIcon from "assets/images/send.svg";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

// eslint-disable-next-line react/display-name
const MessageInput = forwardRef(
  ({ onClick, conversationId, uploading }, ref) => {
    const textFieldRef = useRef();
    const uploadFileRef = useRef();
    const [previewFiles, setPreview] = useState([]);
    const [isAddMore, setAddMore] = useState(false);
    useEffect(() => {
      textFieldRef.current.focus();
    }, [conversationId]);

    // eslint-disable-next-line space-before-function-paren
    function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }

    const handleRemove = (index) => {
      setPreview((previews) => previews.filter((pre, idx) => idx !== index));
    };

    return (
      <div className="message-input-container">
        <Formik
          initialValues={{ message: "", attachments: [] }}
          onSubmit={(values) => {
            const trimmedMessage = _invoke(values.message, "trim");
            setPreview([]);
            onClick({ ...values, message: trimmedMessage });
          }}
          innerRef={ref}
        >
          {({ handleSubmit, values, setFieldValue }) => {
            const attachments = _get(values, "attachments", []);
            return (
              <Form
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
              >
                <div className="message-form-container">
                  <TextInput
                    name="message"
                    inputType="textarea"
                    required
                    ref={textFieldRef}
                    autoSize={{ minRows: 1, maxRows: 3 }}
                  />
                  <input
                    accept="image/* video/*"
                    multiple
                    type="file"
                    ref={uploadFileRef}
                    style={{ display: "none" }}
                    onChange={async (e) => {
                      if (!isAddMore) {
                        const promises = Array.from(e.target.files).map(
                          async (file) => {
                            if (file.type.startsWith("video")) {
                              return {
                                type: "video",
                                localUrl: URL.createObjectURL(file),
                                file,
                              };
                            }
                            return {
                              type: "image",
                              localUrl: await getBase64(file),
                              file,
                            };
                          }
                        );
                        const previews = await Promise.all(promises);
                        setFieldValue("attachments", previews);
                        setPreview(previews);
                      } else {
                        setAddMore(false);
                        if (!_isEmpty(e.target.files)) {
                          setFieldValue(
                            "attachments",
                            Array.from(attachments).concat(e.target.files)
                          );
                          const promises = Array.from(e.target.files).map(
                            async (file) => {
                              if (file.type.startsWith("video")) {
                                return {
                                  type: "video",
                                  localUrl: URL.createObjectURL(file),
                                  file,
                                };
                              }
                              return {
                                type: "image",
                                localUrl: await getBase64(file),
                                file,
                              };
                            }
                          );
                          const previews = await Promise.all(promises);
                          setFieldValue(
                            "attachments",
                            Array.from(attachments).concat(previews)
                          );
                          setPreview((prev) => prev.concat(previews));
                        }
                      }
                    }}
                  />
                  {_isEmpty(previewFiles) && (
                    <Button
                      loading={uploading}
                      onClick={() => uploadFileRef.current.click("aa")}
                      className="upload-file-btn"
                      icon={<CameraOutlined />}
                    ></Button>
                  )}
                  <Button
                    htmlType="submit"
                    className="send-message-btn"
                    icon={<img src={SendIcon} alt="" />}
                  ></Button>
                </div>
                {!_isEmpty(previewFiles) && (
                  <div className="preview-container">
                    {previewFiles.map((file, index) => (
                      <div key={file.name} className="preview-file-container">
                        <Button
                          onClick={() => {
                            handleRemove(index);
                            setFieldValue(
                              "attachments",
                              Array.from(attachments).filter(
                                (att, idx) => idx !== index
                              )
                            );
                          }}
                          type="text"
                          icon={<CloseCircleFilled />}
                          className="close-icon"
                        />
                        {file.type === "image" ? (
                          <img
                            alt=""
                            src={file.localUrl}
                            style={{ width: 48, height: 48 }}
                          />
                        ) : (
                          <ReactPlayer
                            width={48}
                            height={48}
                            url={file.localUrl}
                          />
                        )}
                      </div>
                    ))}
                    <div className="preview-file-container">
                      <Button
                        onClick={() => {
                          setAddMore(true);
                          uploadFileRef.current.click();
                        }}
                        icon={<FileAddFilled />}
                        style={{ height: "100%" }}
                      />
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
);

MessageInput.propTypes = {
  onClick: PropTypes.func.isRequired,
  conversationId: PropTypes.string.isRequired,
  uploading: PropTypes.bool,
};

export default MessageInput;
