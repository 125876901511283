import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import ListLoadMore from "components/ListLoadMore";
import {
  getNotificationListRequest,
  getCountNotSeenNotificationRequest,
} from "providers/NotificationProvider/slice";
import { useDispatch, useSelector } from "react-redux";
import NotificationCell from "./NotificationCell";
import "./styles.less";

const NotificationList = (props) => {
  const { loading, hidePopover } = props;
  const dispatch = useDispatch();
  const notificationList = useSelector((state) =>
    _get(state, "notification.notificationList")
  );
  const hasMore = useSelector((state) => _get(state, "notification.hasMore"));
  const page = useSelector((state) => _get(state, "notification.page"));
  const roleChat = useSelector((state) =>
    _get(state, "auth.currentUser.roleChat")
  );
  const userRole = useSelector((state) => _get(state, "auth.currentUser.role"));

  const notificationListRef = useRef(null);

  const [isLoadMore, setIsLoadMore] = useState(false);

  useEffect(() => {
    dispatch(getNotificationListRequest({ page: 1, limit: 20 }));
    dispatch(getCountNotSeenNotificationRequest());
  }, [dispatch]);

  const handleScroll = () => {
    if (isLoadMore) return;
    if (
      notificationListRef.current.scrollTop +
        notificationListRef.current.clientHeight >=
      notificationListRef.current.scrollHeight - 1
    ) {
      setIsLoadMore(true);
    }
  };

  useEffect(() => {
    const listNotification = notificationListRef.current;
    listNotification.addEventListener("scroll", handleScroll);
    return () => {
      listNotification.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadMore) return;
    if (hasMore) {
      dispatch(getNotificationListRequest({ page: page + 1, limit: 20 }));
      setIsLoadMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadMore]);

  return (
    <div className="company-notification-list">
      <div
        ref={notificationListRef}
        className={`notification-list ${
          _isEmpty(notificationList) ? "initial-empty-list" : ""
        }`}
      >
        <ListLoadMore hasMore={hasMore} loading={loading}>
          {notificationList.map((notification) => (
            <NotificationCell
              key={notification._id}
              notificationDetail={notification}
              roleChat={roleChat}
              userRole={userRole}
              hidePopover={hidePopover}
            />
          ))}
        </ListLoadMore>
      </div>
    </div>
  );
};

NotificationList.propTypes = {
  getNotificationListRequest: PropTypes.func,
  notificationList: PropTypes.array,
  hasMore: PropTypes.bool,
  page: PropTypes.number,
  onCellClick: PropTypes.func,
  loading: PropTypes.bool,
  currentUser: PropTypes.object,
  hidePopover: PropTypes.func,
};
export default NotificationList;
