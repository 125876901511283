import {
  createContext,
  useContext,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import PropTypes from "prop-types";

const HistoryContext = createContext({});
export const useHistory = () => useContext(HistoryContext);

// Temporary workaround
const BrowserRouter = ({ children }) => {
  const { current: history } = useRef(createBrowserHistory({ window }));
  const [{ action, location }, setHistoryState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => history.listen(setHistoryState), [history]);
  return (
    <Router action={action} location={location} navigator={history}>
      <HistoryContext.Provider value={history}>
        {children}
      </HistoryContext.Provider>
    </Router>
  );
};

BrowserRouter.propTypes = {
  children: PropTypes.any,
};
export default BrowserRouter;
