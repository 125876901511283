import { object, string } from "yup";
import i18n from "i18n";
import Helper from "utils/helpers";

export const createNewsFonehouseValidate = object().shape({
  title: string().trim().required(i18n.t("errors.required")),
  content: string()
    .test(
      "empty-content",
      i18n.t("errors.required"),
      (value) => !Helper.checkEmptyHtmlRaw(value)
    )
    .required(i18n.t("errors.required")),
  thumbnail: object().nullable().required(i18n.t("errors.required")),
});
