import { Row, Typography, Button, Dropdown, Menu } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import { GuideFonehouseColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import {
  getAllGuideFonehouseRequest,
  setSelectedGuideFonehouseIds,
  deleteGuideFonehouseRequest,
  pinGuideFonehouseRequest,
} from "providers/GuideFonehouseProvider/slice";
import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import SearchBar from "./SearchBar";

const GuideFonehouseList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const guideFonehouseList = useSelector((state) =>
    _get(state, "guideFonehouse.docs")
  );
  const guideFonehouseTotal = useSelector((state) =>
    _get(state, "guideFonehouse.totalDocs")
  );
  const isLoading = useSelector((state) =>
    _get(state, "guideFonehouse.isLoading")
  );
  const [searchObject] = useQuery(getAllGuideFonehouseRequest);

  const selectedRowKeys = useSelector((state) =>
    _get(state, "guideFonehouse.selectedRowKeys", [])
  );
  const selectedRows = useSelector((state) =>
    _get(state, "guideFonehouse.selectedRows", [])
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedGuideFonehouseIds({
          record,
          selected,
          selectedRows: _selectedRows,
        })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedGuideFonehouseIds({
          selected,
          selectedRows: _selectedRows,
          changeRows,
        })
      );
    },
  };

  const menuAll = () => (
    <Menu>
      <Menu.Item
        disabled={_isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            deleteGuideFonehouseRequest({
              guideIds: selectedRowKeys,
            })
          ).then(() => dispatch(getAllGuideFonehouseRequest(searchObject)))
        }
      >
        {i18n.t("guideFonehouse.removeGuide")}
      </Menu.Item>
    </Menu>
  );

  const menu = (data) => (
    <Menu>
      <Menu.Item
        onClick={() =>
          dispatch(
            pinGuideFonehouseRequest({
              guideId: data._id,
              pin: data.pin !== 1,
            })
          ).then(() => dispatch(getAllGuideFonehouseRequest(searchObject)))
        }
      >
        {data.pin === 1
          ? i18n.t("guideFonehouse.unpinGuide")
          : i18n.t("guideFonehouse.pinGuide")}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          navigate(`/guide-fonehouse/${data._id}`, {
            state: { canGoBack: true },
          })
        }
      >
        {i18n.t("guideFonehouse.viewInformation")}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          navigate(`/guide-fonehouse/${data._id}/edit`, {
            state: { canGoBack: true },
          })
        }
      >
        {i18n.t("guideFonehouse.edit")}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          dispatch(
            deleteGuideFonehouseRequest({
              guideIds: [data._id],
            })
          ).then(() => dispatch(getAllGuideFonehouseRequest(searchObject)))
        }
      >
        {i18n.t("guideFonehouse.removeGuide")}
      </Menu.Item>
    </Menu>
  );
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const columns = [
    GuideFonehouseColumn.id,
    GuideFonehouseColumn.createdAt,
    GuideFonehouseColumn.title,
    GuideFonehouseColumn.username,
    GuideFonehouseColumn.pin,
    {
      title: (
        <Dropdown overlay={menuAll} placement="bottomLeft" arrow>
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  return (
    <div className="news-fonehouse-list-wrapper">
      <Row className="news-fonehouse-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("guideFonehouse.listTitle")}
        </Typography.Title>
        <Button
          size="large"
          type="primary"
          onClick={() =>
            navigate("/guide-fonehouse/guide-create", {
              state: { canGoBack: true },
            })
          }
        >
          Đăng bài
        </Button>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Table
          rowSelection={rowSelection}
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={guideFonehouseList}
          rowKey={(data) => data._id}
          total={guideFonehouseTotal}
          onRow={(record) => ({
            onDoubleClick: () => {
              navigate(`/guide-fonehouse/${record._id}`, {
                state: { canGoBack: true },
              });
            },
          })}
        />
      </div>
    </div>
  );
};

export default GuideFonehouseList;
