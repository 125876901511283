import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import _get from "lodash/get";
import _set from "lodash/set";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import Helper from "utils/helpers";
import moment, { lang } from "moment";
import {
  getMarketProductListRequest,
  getMarketProductListSuccess,
  stopLoading,
  updateMarketProductRequest,
  updateMarketProductSuccess,
  getProductMarketTypeRequest,
  getProductMarketTypeSuccess,
  addProductMarketTypeRequest,
  addProductMarketTypeSuccess,
  createProductMarketRequest,
  createProductMarketSuccess,
  updateProductMarketRequest,
  updateProductMarketSuccess,
  deleteProductMarketRequest,
  deleteProductMarketSuccess,
  deleteProductMarketTypeRequest,
  deleteProductMarketTypeSuccess,
  editProductMarketTypeRequest,
  editProductMarketTypeSuccess,
  setTagProductMarketRequest,
  setTagProductMarketSuccess,
} from "./slice";

function* getSuperMarketList(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "sortName",
    "sortMass",
    "sortPrice",
    "sortPromotionPrice",
    "productType",
    "storeId",
    "keyword",
  ]);
  const { data } = yield call(request, "api/products", params, {
    method: "GET",
  });
  yield put(getMarketProductListSuccess(data));
}

function* updateMarketProduct(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "sortName",
    "productType",
    "marketId",
  ]);
  params.storeId = params.restaurantId;
  delete params.restaurantId;
  const { data } = yield call(request, "api/products", params, {
    method: "PUT",
  });
  yield put(updateMarketProductSuccess(data));
}

export function* getProductMarketType(action) {
  const params = _pick(action.payload, [
    "marketId",
    "limit",
    "page",
    "keyword",
  ]);
  params.storeId = params.marketId;
  delete params.marketId;
  yield;
  const { data } = yield call(
    request,
    `api/products/categories?storeId=${params.storeId}`,
    undefined,
    { method: "GET" }
  );
  yield put(getProductMarketTypeSuccess(data));
}

export function* addProductMarketType(action) {
  const params = _pick(action.payload, ["name", "storeId"]);
  const { data } = yield call(request, "api/products/categories", params, {
    method: "POST",
  });
  yield put(addProductMarketTypeSuccess(data));
  Helper.toastr(
    `Loại:${_get(data, "name")}`,
    "Thêm loại sản phẩm thành công",
    "success"
  );
}

export function* deleteProductMarketType(action) {
  const params = _pick(action.payload, ["idProductMarketType", "marketId"]);
  params.storeId = params.marketId;
  delete params.marketId;
  params.categoryIds = [params.idProductMarketType];
  delete params.idProductMarketType;
  yield call(request, "/products/categories", params, { method: "DELETE" });
  yield put(deleteProductMarketTypeSuccess(params));
  yield put(
    getProductMarketTypeRequest({
      storeId: params.storeId,
      limit: 1000,
      page: 1,
    })
  );
  const searchParams = _get(action, "payload.searchObject");
  yield put(getMarketProductListRequest(searchParams));
  Helper.toastr(
    `Loại:${_get(action, "payload.name")}`,
    "Xóa loại sản phẩm thành công",
    "success"
  );
}

function* createProductMarket(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "name",
    "percentagePromotion",
    "promotion",
    "price",
    "productType",
    "element",
    "images",
    "thumbnail",
    "mass",
    "unit",
    "fromDatePromotion",
    "toDatePromotion",
    "amount",
    "marketId",
  ]);
  params.storeId = params.marketId;
  delete params.marketId;
  params.productCategory = params.productType;
  delete params.productType;
  if (params.thumbnail) {
    _set(params, "thumbnail", params.thumbnail.url);
  }
  if (_isEmpty(params.fromDatePromotion)) {
    _set(params, "fromDatePromotion", moment());
  }
  if (_isEmpty(params.toDatePromotion)) {
    _set(params, "toDatePromotion", moment());
  }
  if (Number(params.price) === 0) {
    _set(params, "promotion", 0);
    _set(params, "percentagePromotion", 0);
  }
  _set(params, "inputPrice", 0);
  _set(params, "images", []);
  const { data } = yield call(request, "api/products", params, {
    method: "POST",
  });
  yield put(createProductMarketSuccess(data, meta));
  Helper.toastr(
    `Sản phẩm:${_get(data, "name")}`,
    "Tạo sản phẩm thành công",
    "success"
  );
}

function* updateProductMarket(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "name",
    "currency",
    "percentagePromotion",
    "promotion",
    "price",
    "productType",
    "mass",
    "images",
    "unit",
    "thumbnail",
    "fromDatePromotion",
    "toDatePromotion",
    "amount",
    "marketId",
    "idProductMarket",
  ]);
  if (params.thumbnail) {
    _set(params, "thumbnail", params.thumbnail.url);
  }
  if (params.promotion === 0 && params.percentagePromotion === 0) {
    _set(params, "fromDatePromotion", moment());

    _set(params, "toDatePromotion", moment());
  }
  _set(params, "inputPrice", 0);
  _set(params, "images", []);
  if (Number(params.price) === 0) {
    _set(params, "promotion", 0);
    _set(params, "percentagePromotion", 0);
  }
  const data = yield call(request, `api/products/${params.productId}`, params, {
    method: "PUT",
  });
  yield put(
    updateProductMarketSuccess({ ...params, _id: params.idProductMarket }, meta)
  );
  Helper.toastr(
    `Sản phẩm:${_get(data, "data.name")}`,
    "Cập nhật sản phẩm thành công",
    "success"
  );
}

function* deleteProductMarket(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["productIds", "storeId"]);
  const productMarketIds = _get(action, "payload.productMarketIds", []);
  const { data } = yield call(request, "api/products", params, {
    method: "DELETE",
  });
  yield put(deleteProductMarketSuccess(data, meta));
  Helper.toastr(
    `ID:${productMarketIds.join(", ")}`,
    "Xóa sản phẩm thành công",
    "success"
  );
}

export function* editProductMarketType(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["productMarketTypeId", "name"]);
  const { data } = yield call(
    request,
    "api/fonehouse/product-market-manager/product-market-type",
    params,
    { method: "PUT" }
  );
  yield put(editProductMarketTypeSuccess(data, meta));
  Helper.toastr(
    `Loại: ${_get(action, "payload.name")}`,
    "Cập nhật loại sản phẩm thành công",
    "success"
  );
}

function* setTagProductMarket(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["productIds", "tag", "storeId"]);
  yield call(request, "api/products/tags", params, { method: "PUT" });
  yield put(setTagProductMarketSuccess({}, meta));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchMarketProduct() {
  yield takeEvery(
    getMarketProductListRequest.type,
    safe(getSuperMarketList, onError)
  );
  yield takeLeading(
    getProductMarketTypeRequest.type,
    safe(getProductMarketType, onError)
  );
  yield takeLeading(
    updateMarketProductRequest.type,
    safe(updateMarketProduct, onError)
  );
  yield takeLeading(
    addProductMarketTypeRequest.type,
    safe(addProductMarketType, onError)
  );
  yield takeLeading(
    createProductMarketRequest.type,
    safe(createProductMarket, onError)
  );
  yield takeLeading(
    updateProductMarketRequest.type,
    safe(updateProductMarket, onError)
  );
  yield takeLeading(
    deleteProductMarketRequest.type,
    safe(deleteProductMarket, onError)
  );
  yield takeLeading(
    deleteProductMarketTypeRequest.type,
    safe(deleteProductMarketType, onError)
  );
  yield takeLeading(
    editProductMarketTypeRequest.type,
    safe(editProductMarketType, onError)
  );
  yield takeLeading(
    setTagProductMarketRequest.type,
    safe(setTagProductMarket, onError)
  );
}
