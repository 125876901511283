import React, { useRef } from "react";
import { Modal, Button, Form as FormColorPicker } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Colorpicker } from "antd-colorpicker";
import * as Yup from "yup";
import "antd/dist/antd.css";
import "./styles.less";
import { useSelector, useDispatch } from "react-redux";
import {
  triggerModalTag,
  createTagRequest,
} from "providers/MessengerProvider/slice";

const CreateTagModal = () => {
  const { isOpenModalTag, conversationId, conversationName } = useSelector(
    (state) => state.messenger
  );
  const dispatch = useDispatch();
  const formikRef = useRef();
  const initialValues = { color: { r: 26, g: 14, b: 85, a: 1 } };

  const handleCloseModal = () => {
    dispatch(
      triggerModalTag({
        visible: false,
      })
    );
  };

  const onSubmit = (values) => {
    handleCloseModal();
    dispatch(
      createTagRequest({
        conversationId,
        name: values.labelName,
        color: values.colorCode,
      })
    );
  };

  return (
    <div>
      {isOpenModalTag && (
        <Modal
          title={`Tạo nhãn dán cho ${conversationName}`}
          visible={isOpenModalTag}
          onCancel={handleCloseModal}
          footer={null}
        >
          <Formik
            innerRef={formikRef}
            initialValues={{ labelName: "", colorCode: "#F523AF" }}
            validationSchema={Yup.object({
              labelName: Yup.string().required("Yêu cầu dữ liệu"),
            })}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="labelName">Tên nhãn dán</label>
                  <Field name="labelName" type="text" />
                  <ErrorMessage
                    name="labelName"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="form-group">
                  <label>Mã màu</label>
                  <div className="color-box-container">
                    <FormColorPicker
                      className="test-code"
                      initialValues={initialValues}
                    >
                      <FormColorPicker.Item name={`color`}>
                        <Colorpicker
                          popup
                          onChangeComplete={(color) => {
                            setFieldValue("colorCode", color.hex);
                          }}
                          blockStyles={{
                            marginTop: "10px",
                            marginLeft: "10px",
                          }}
                        />
                      </FormColorPicker.Item>
                    </FormColorPicker>
                    <div style={{ marginLeft: 10 }}>
                      {formikRef.current?.values?.colorCode}
                    </div>
                  </div>
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                  style={{ width: "100%" }}
                >
                  XÁC NHẬN
                </Button>
              </Form>
            )}
          </Formik>
        </Modal>
      )}
    </div>
  );
};

export default CreateTagModal;
