import "./style.less";

const LoadingIndicator = () => (
  <div className="loader-background">
    <div className="loader">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
);

export default LoadingIndicator;
