/* eslint-disable react/display-name */

import i18n from "i18n";
import SummaryTooltip from "components/SummaryTooltip";
import RestaurantProductTooltip from "components/SummaryTooltip/RestaurantProductTooltip";
import ProductTag from "components/Product/Tag";

const Columns = {};
const renderDataValue = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.productId = {
  dataIndex: "_id",
  key: "_id",
  title: "#",
  render: (_id, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<RestaurantProductTooltip restaurantProductDetail={data} />}
        widthTooltipInner={680}
      >
        <div>{_id}</div>
      </SummaryTooltip>
    ),
  width: 100,
};

Columns.name = {
  title: i18n.t("restaurantProduct.productName"),
  dataIndex: "name",
  key: "name",
  sorter: true,
  render: (state) => renderDataValue(state),
  width: 200,
};

Columns.type = {
  title: i18n.t("restaurantProduct.type"),
  dataIndex: ["productCategory", "name"],
  key: "productCategory",
  render: (productType) => renderDataValue(productType),
  width: 110,
};

Columns.amount = {
  title: i18n.t("restaurantProduct.element"),
  dataIndex: "element",
  key: "element",
  width: 160,
  render: (element) => renderDataValue(element),
};

Columns.promotion = {
  title: i18n.t("restaurantProduct.promotion"),
  dataIndex: "percentagePromotion",
  key: "percentagePromotion",
  width: 130,
  render: (percentagePromotion, record) =>
    renderDataValue(
      `${
        record.promotion !== 0
          ? `${record.promotion.toLocaleString()} ${record.currency}`
          : `${percentagePromotion}%`
      }`,
      { fontWeight: "bold" }
    ),
};

Columns.price = {
  title: i18n.t("restaurantProduct.price"),
  dataIndex: "price",
  key: "price",
  width: 140,
  render: (price, data) =>
    renderDataValue(
      <div>
        {price?.toLocaleString()}
        {` ${data.currency}`}
      </div>
    ),
  sorter: true,
};

Columns.promotionPrice = {
  title: i18n.t("restaurantProduct.promotionPrice"),
  dataIndex: "promotionPrice",
  key: "promotionPrice",
  width: 140,
  render: (promotionPrice, data) =>
    renderDataValue(
      <div>
        {promotionPrice?.toLocaleString()}
        {` ${data.currency}`}
      </div>
    ),
  sorter: true,
};

Columns.tag = {
  title: i18n.t("products.tag"),
  dataIndex: "tag",
  key: "tag",
  render: (tag) => renderDataValue(<ProductTag status={tag} />),
};

export default Columns;
