import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import LocalizeProvider from "providers/LocalizeProvider";
import configureStore from "redux/store";
import BrowserRouter from "containers/App/routes/BrowserRouter";
import reportWebVitals from "./reportWebVitals";
import App from "./containers/App";
import "./styles/global.less";

const initialState = {};
const store = configureStore(initialState);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <LocalizeProvider>
    <Provider store={store}>
      <ConfigProvider autoInsertSpaceInButton={false}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </LocalizeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
