import _get from "lodash/get";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import BackIcon from "assets/images/back.svg";
import "./styles.less";

const BackButton = ({
  className = "",
  backUrl = "/home",
  customGoBackHandler,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const canGoBack = _get(location, "state.canGoBack");
  const handleGoBack = () => {
    if (canGoBack) {
      if (customGoBackHandler) {
        customGoBackHandler();
      } else {
        navigate(-1);
      }
    } else navigate(backUrl);
  };

  return (
    <div className={`back-btn-wrapper ${className}`}>
      <img onClick={handleGoBack} alt="back" src={BackIcon} />
    </div>
  );
};

BackButton.propTypes = {
  backUrl: PropTypes.string,
  className: PropTypes.string,
  customGoBackHandler: PropTypes.func,
};
export default BackButton;
