import qs from "querystring";
import { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _has from "lodash/has";
import _isEmpty from "lodash/isEmpty";
import _invoke from "lodash/invoke";
import _clone from "lodash/clone";
import Helper from "utils/helpers";

const defaultOrderBy = "createdAt";
const defaultOrder = "descend";

const useQuery = (action, options = {}) => {
  const { customParameter = {}, arrayParams = [] } = options;
  const location = useLocation();
  const navigate = useNavigate();
  const { search, pathname } = location;

  let searchObject = search.replace(/^\?/, "");
  searchObject = qs.parse(searchObject);
  const dispatch = useDispatch();
  const apiCall = useCallback(() => {
    if (action) {
      dispatch(action(Helper.formatParams(searchObject, { arrayParams })));
    }
  }, [action, searchObject, dispatch, arrayParams]);
  useEffect(() => {
    let reHref = false; // define whether the url lack default parameters

    if (!_isEmpty(customParameter)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in customParameter) {
        if (searchObject[key] !== customParameter[key].toString()) {
          searchObject[key] = customParameter[key];
          reHref = true;
        }
      }
    }

    if (
      !_has(searchObject, "page") ||
      !_has(searchObject, "limit") ||
      !_has(searchObject, "orderBy") ||
      !_has(searchObject, "order")
    ) {
      searchObject.orderBy = searchObject.orderBy || defaultOrderBy;
      searchObject.order = searchObject.order || defaultOrder;
      searchObject.page = searchObject.page || 1;
      searchObject.limit = searchObject.limit || 10;
      reHref = true;
    }
    if (reHref) {
      navigate(
        `${pathname}?${qs.stringify(
          Helper.formatParams(searchObject, { arrayParams })
        )}`,
        { replace: true, state: location.state }
      );
    } else {
      apiCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleSetSearch = (data, replace = false) => {
    const params = { ...data };
    if (
      !_has(params, "page") ||
      !_has(params, "limit") ||
      !_has(params, "orderBy") ||
      !_has(params, "order")
    ) {
      params.orderBy = data.orderBy || defaultOrderBy;
      params.order = data.order || defaultOrder;
      params.page = data.page || 1;
      params.limit = data.limit || 10;
    }
    Object.keys(params).forEach((key) => {
      if (_isEmpty(_invoke(_clone(params[key]), "toString"))) {
        delete params[key];
      }
    });
    navigate(
      `${pathname}?${qs.stringify(
        Helper.formatParams(params, { arrayParams })
      )}`,
      { replace, state: location.state }
    );
  };
  return [Helper.formatParams(searchObject, { arrayParams }), handleSetSearch];
};

export default useQuery;
