/* eslint-disable react/display-name */

import i18n from "i18n";

const AccessoryColumns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

const renderImg = (src) => (
  <img style={{ width: 50, height: 50 }} src={src} alt="Thumbnail" />
);

AccessoryColumns.id = {
  dataIndex: "_id",
  key: "_id",
  title: "#",
  render: (guildId) => renderDataValue(guildId),
  width: 120,
};

AccessoryColumns.name = {
  title: "Tên phụ kiện",
  dataIndex: "name",
  key: "name",
  // sorter: true,
  render: (name) => renderDataValue(name),
};

AccessoryColumns.thumbnail = {
  title: "Thumbnail",
  dataIndex: "thumbnail",
  key: "thumbnail",
  render: (thumbnail) => renderImg(thumbnail),
};

AccessoryColumns.initialPrice = {
  title: "Giá gốc",
  dataIndex: "initialPrice",
  key: "initialPrice",
  render: (initialPrice) => renderDataValue(initialPrice?.toLocaleString()),
};

AccessoryColumns.price = {
  title: "Giá bán",
  dataIndex: "price",
  key: "price",
  render: (price) => renderDataValue(price?.toLocaleString()),
};

export default AccessoryColumns;
