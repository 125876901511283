import { memo } from "react";
import { Avatar } from "antd";
import _get from "lodash/get";
import PropTypes from "prop-types";
import moment from "moment";
import DefaultLogo from "assets/images/logovietgrp.svg";
import "./styles.less";

const ConversationCell = ({ data, onClick, className = "" }) => {
  const messageCreatedDate = _get(data, "latestMessage.createdAt");
  const unseenMessages = _get(data, "unseenMessages");
  const user = _get(data, "users[0]", {});
  const avatar = _get(user, "userDetail.avatar") || DefaultLogo;

  return (
    <div
      className={`conversation-cell-container ${className} ${
        unseenMessages ? "unseen-conversation" : ""
      }`}
      onClick={onClick}
    >
      <Avatar size={40} src={avatar} className="avatar" />
      <div className="conversation-cell-content">
        <div className="username">{_get(data, "users[0].name")}</div>
        <div className="last-message">{_get(data, "latestMessage.text")}</div>
        <div className="message-date">
          {messageCreatedDate
            ? moment(messageCreatedDate).format("DD/MM/YYYY HH:MM")
            : ""}
        </div>
      </div>
    </div>
  );
};

ConversationCell.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default memo(ConversationCell);
