import { memo, useRef, useLayoutEffect, useState } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import Spin from "antd/lib/spin";
import Row from "antd/lib/row";
import { useDispatch, useSelector } from "react-redux";
import { firstGetListMessage } from "providers/MessengerProvider/slice";
import LoadingIndicator from "components/LoadingIndicator";
import { LoadingOutlined } from "@ant-design/icons";
import MessageCell from "./MessageCell";

const MessageList = ({
  messageIds = [],
  messageEntities = {},
  getMessageList,
  hasMore,
  currentUserId,
  currentUserRole,
  onSendMessage,
}) => {
  const dispatch = useDispatch();
  const latestMessage = messageIds[0];
  const messageListRef = useRef();
  const firstGetList = useSelector((state) => state.messenger.firstGetList);
  const [optimisticMessages, setOptimisticMessages] = useState([]);
  const isSending = useSelector((state) => state.messenger.isSending);

  useLayoutEffect(() => {
    if (messageListRef.current) {
      dispatch(firstGetListMessage());
      messageListRef.current.scrollTo(0, 0);
    }
  }, [dispatch, latestMessage]);

  const handleSendMessage = (message) => {
    // Create an optimistic message
    const optimisticMessage = {
      id: `optimistic-${Date.now()}`,
      text: message.text,
      sender: { _id: currentUserId },
      createdAt: new Date().toISOString(),
      isOptimistic: true,
    };

    // Add the optimistic message to the state
    setOptimisticMessages((prev) => [optimisticMessage, ...prev]);

    // Send the actual message
    onSendMessage(message)
      .then(() => {
        // On success, remove the optimistic message
        setOptimisticMessages((prev) =>
          prev.filter((msg) => msg.id !== optimisticMessage.id)
        );
      })
      .catch((error) => {
        // On error, you might want to show an error message or retry
        console.error("Failed to send message:", error);
        // Remove the optimistic message
        setOptimisticMessages((prev) =>
          prev.filter((msg) => msg.id !== optimisticMessage.id)
        );
        // Optionally, show an error message to the user
      });
  };

  if (!firstGetList) {
    return (
      <Row
        style={{
          padding: 8,
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
        justify="center"
      >
        <Spin />
      </Row>
    );
  }

  const allMessages = [...optimisticMessages, ...messageIds];

  return (
    <div
      className="message-list-container"
      id="scrollable-message-list"
      ref={messageListRef}
    >
      {isSending && <LoadingOutlined />}
      <InfiniteScroll
        scrollThreshold="100px"
        dataLength={allMessages.length}
        next={getMessageList}
        hasMore={hasMore}
        loader={
          <Row style={{ padding: 8 }} justify="center">
            <Spin />
          </Row>
        }
        style={{ display: "flex", flexDirection: "column-reverse" }}
        inverse
        scrollableTarget="scrollable-message-list"
      >
        {allMessages.map((id) => {
          const message =
            optimisticMessages.find((m) => m.id === id) || messageEntities[id];
          const senderId =
            _get(message, "sender._id") || _get(message, "sender");
          return (
            <MessageCell
              isOwnCell={
                senderId === currentUserId ||
                (["admin", "super_admin"].includes(currentUserRole) &&
                  _get(message, "isAdmin"))
              }
              key={id}
              data={message}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

MessageList.propTypes = {
  messageIds: PropTypes.array.isRequired,
  messageEntities: PropTypes.object.isRequired,
  getMessageList: PropTypes.func,
  hasMore: PropTypes.bool,
  currentUserId: PropTypes.string,
  currentUserRole: PropTypes.string,
  onSendMessage: PropTypes.func.isRequired,
};

export default memo(MessageList);
