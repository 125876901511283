/* eslint-disable react/display-name */

import i18n from "i18n";
import { IconChecked } from "assets/images";

const Columns = {};

const renderDataValue = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.idUser = {
  dataIndex: "_id",
  key: "_id",
  title: "#",
  render: (idUser) => renderDataValue(`#${idUser}`),
  width: 195,
};

Columns.phone = {
  dataIndex: "phone",
  key: "phone",
  title: i18n.t("adminManager.phone"),
  width: 150,
  render: (phone) => renderDataValue(phone),
};

Columns.name = {
  title: i18n.t("adminManager.displayName"),
  dataIndex: "name",
  key: "name",
  sorter: true,
  render: (name) => renderDataValue(name),
  width: 200,
};

Columns.email = {
  title: i18n.t("adminManager.email"),
  dataIndex: "email",
  key: "email",
  sorter: true,
  render: (email) => renderDataValue(email),
  width: 200,
};

Columns.roleChat = {
  title: i18n.t("adminManager.accuracy"),
  dataIndex: "roleChat",
  key: "roleChat",
  render: (accuracy) => renderDataValue(accuracy ? <IconChecked /> : <div />),
};

export default Columns;
