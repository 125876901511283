import { Modal, Row, Col, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import { LeftArrowIcon, PlaceHolderIMG } from "assets/images";
import DefaultImage from "assets/images/photo.png";
import { getInternetRegistrationDetailSuccess } from "providers/SimProvider/slice";
import "./styles.less";

const RegistrationInternetDetailModal = () => {
  const dispatch = useDispatch();
  const { internetRegistrationDetail } = useSelector((state) => state.sim);

  const handleCloseModal = () => {
    dispatch(getInternetRegistrationDetailSuccess({}));
  };

  return (
    <Modal
      visible={!_isEmpty(internetRegistrationDetail)}
      footer={null}
      closable={false}
      centered
      width={1228}
      className="sim-registration-modal-wrapper"
    >
      <Row align="middle" className="modal-header">
        <Col>
          <img
            onClick={handleCloseModal}
            className="icon-close"
            src={LeftArrowIcon}
            alt=""
          />
        </Col>
        <Col>Xem yêu cầu đăng ký Internet</Col>
      </Row>
      <Row className="modal-content" wrap={false} justify="space-between">
        <Col className="sim-detail" span={14}>
          <div className="detail-content">
            <span className="label">Khách hàng:</span>
            <span className="content">
              {_get(internetRegistrationDetail, "name", "")}
            </span>
          </div>
          <div className="detail-content">
            <span className="label">Email:</span>
            <span className="content">
              {_get(internetRegistrationDetail, "email", "")}
            </span>
          </div>

          <div className="detail-content">
            <span className="label">Số điện thoại:</span>
            <span className="content">
              {_get(internetRegistrationDetail, "phoneNumber", "")}
            </span>
          </div>

          <div className="detail-content">
            <span className="label" style={{ flexBasis: "100%" }}>
              Địa chỉ thẻ ngoại kiều:
            </span>
            <span className="content">
              {_get(internetRegistrationDetail, "alienRegistrationAddress", "")}
            </span>
          </div>
        </Col>
        <Col className="sim-images-wrapper" span={10}>
          <div className="sim-image">
            <div className="label">
              <b>Ảnh thẻ ngoại kiều</b> (mặt trước)
            </div>
            <Image
              fallback={PlaceHolderIMG}
              src={_get(
                internetRegistrationDetail,
                "alienRegistrationFrontImages[0]",
                DefaultImage
              )}
            />
          </div>
          <div className="sim-image">
            <div className="label">
              <b>Ảnh thẻ mặt sau</b> (mặt sau)
            </div>
            <Image
              fallback={PlaceHolderIMG}
              src={_get(
                internetRegistrationDetail,
                "alienRegistrationBackImages[0]",
                DefaultImage
              )}
            />
          </div>
          <div className="sim-image">
            <div className="label">
              <b>Ảnh hộ chiếu (Trang có ảnh ghi số hộ chiếu)</b>
            </div>
            <Image
              fallback={PlaceHolderIMG}
              src={_get(
                internetRegistrationDetail,
                "passportImages[0]",
                DefaultImage
              )}
            />
          </div>
          <div className="sim-image">
            <div className="label">
              <b>Ảnh thẻ ngân hàng</b>
            </div>
            <Image
              fallback={PlaceHolderIMG}
              src={_get(
                internetRegistrationDetail,
                "bankCardImages[0]",
                DefaultImage
              )}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default RegistrationInternetDetailModal;
