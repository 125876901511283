import PropTypes from "prop-types";
import _get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import { NOTIFICATION_TYPE, ROLES } from "utils/constants";
import { useNavigate } from "react-router";
import {
  updateSeenNotificationRequest,
  getCountNotSeenNotificationRequest,
} from "providers/NotificationProvider/slice";
import Helper from "utils/helpers";
import IconOrder from "assets/images/shipped.png";
import IconCancelOrder from "assets/images/cancelorder.jpg";
import { Avatar } from "antd";

const NotificationCell = ({
  notificationDetail,
  roleChat,
  hidePopover,
  userRole,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUserId = useSelector((state) =>
    _get(state, "auth.currentUser._id")
  );

  const {
    type,
    message,
    seen = [],
    user = {},
    createdAt,
    restaurants = {},
    supermarkets = {},
    entertainments = {},
    _id,
    conversationId,
    order = {},
  } = notificationDetail;

  const handleClickNotification = () => {
    if (!seen.includes(currentUserId)) {
      dispatch(
        updateSeenNotificationRequest({ notificationId: _id, currentUserId })
      ).then(() => {
        dispatch(getCountNotSeenNotificationRequest());
      });
    }
    if (roleChat && type === NOTIFICATION_TYPE.MESSAGE && conversationId) {
      navigate(`/fonehouse/messengers/${conversationId}`);
      hidePopover();
    }
    if (
      [
        NOTIFICATION_TYPE.CANCEL_ORDER,
        NOTIFICATION_TYPE.SHIPPED_ORDER,
      ].includes(type)
    ) {
      if (userRole !== ROLES.USER) {
        navigate(`/fonehouse/billing_list/${_get(order, "_id", "")}`);
        hidePopover();
      } else {
        Helper.toastr(
          "Không thể xem đơn hàng",
          "Order permission",
          "error",
          "bottomLeft"
        );
      }
    }
    if (!roleChat && type === NOTIFICATION_TYPE.MESSAGE && conversationId) {
      Helper.toastr(
        "Không thể xem tin nhắn",
        "Chat permission",
        "error",
        "bottomLeft"
      );
    }
    if (type === NOTIFICATION_TYPE.FOLLOW_RESTAURANT) {
      navigate(`/restaurant/${_get(restaurants, "_id", "")}`);
      hidePopover();
    }
    if (type === NOTIFICATION_TYPE.FOLLOW_ENTERTAINMENT) {
      navigate(`/entertainment/${_get(entertainments, "_id", "")}`);
      hidePopover();
    }
    if (type === NOTIFICATION_TYPE.supermarkets) {
      navigate(`/supermarket/${_get(supermarkets, "_id", "")}`);
      hidePopover();
    }
  };

  const renderThumbnailNotification = () => {
    // eslint-disable-next-line default-case
    switch (type) {
      case NOTIFICATION_TYPE.FOLLOW_RESTAURANT:
        return <Avatar src={_get(restaurants, "thumbnail", "")} />;

      case NOTIFICATION_TYPE.FOLLOW_ENTERTAINMENT:
        return <Avatar src={_get(supermarkets, "thumbnail", "")} />;

      case NOTIFICATION_TYPE.FOLLOW_SUPERMARKET:
        return <Avatar src={_get(entertainments, "thumbnail", "")} />;

      case NOTIFICATION_TYPE.SHIPPED_ORDER:
        return <Avatar src={IconOrder} />;

      case NOTIFICATION_TYPE.CANCEL_ORDER:
        return <Avatar src={IconCancelOrder} />;
    }
    return <Avatar src={_get(user, "userDetail.avatar", "")} />;
  };

  const renderNotificationCell = () => (
    <div className="notification-content"> {message} </div>
  );
  return (
    <div
      role="presentation"
      onClick={handleClickNotification}
      className={`notification-cell-wrapper ${
        seen.includes(currentUserId)
          ? "notification-readed"
          : "notification-unread"
      }`}
    >
      <div className="notification-image">{renderThumbnailNotification()}</div>
      <div className="notification-info">
        {renderNotificationCell()}
        <div className="notification-timeline">
          {Helper.getTimeNotification(createdAt)}{" "}
        </div>
      </div>
    </div>
  );
};

NotificationCell.propTypes = {
  notificationDetail: PropTypes.object,
  roleChat: PropTypes.bool,
  userRole: PropTypes.string,
  hidePopover: PropTypes.func,
};

export default NotificationCell;
