import { Row, Typography, Menu, Dropdown, Button, Tabs } from "antd";
import _get from "lodash/get";
import { IdentifyUserColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import { setSelectedBusinessIds } from "providers//AdminManagerProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import "./styles.less";
import {} from "providers/CommentAndRatingProvider/slice";
import { IconSeeMore } from "assets/images";
import {
  getListIdentifyUserRequest,
  updateIdentifyUserStatusRequest,
} from "providers/IdentifyUserProvider/slice";
import TabHeader from "components/TabHeader";

const { TabPane } = Tabs;
const IdentifyUser = () => {
  const dispatch = useDispatch();

  const identifyList = useSelector((state) => _get(state, "identifyUser.docs"));
  const ratingTotal = useSelector((state) =>
    _get(state, "identifyUser.totalDocs")
  );
  const isLoading = useSelector((state) =>
    _get(state, "identifyUser.isLoading")
  );
  const selectedRowKeys = useSelector((state) =>
    _get(state, "identifyUser.selectedRowKeys", [])
  );

  const [searchObject, setSearch] = useQuery(getListIdentifyUserRequest);

  const menu = (record) => {
    const { _id } = record;
    if (searchObject.type === "WAITING") {
      return (
        <Menu>
          <Menu.Item
            onClick={() =>
              dispatch(
                setPopup({
                  isOpen: true,
                  data: {
                    title: "Xác thực User",
                    content: "Bạn xác thực User này?",
                    onOk: () => {
                      dispatch(
                        updateIdentifyUserStatusRequest({
                          idUser: _id,
                          action: "approved",
                        })
                      ).then(() => {
                        dispatch(getListIdentifyUserRequest(searchObject));
                      });
                    },
                  },
                })
              )
            }
          >
            <div style={{ color: "green", fontWeight: "bolder" }}>
              Chấp thuận
            </div>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              dispatch(
                setPopup({
                  isOpen: true,
                  data: {
                    title: "Từ chối xác thực",
                    content: "Bạn từ chối xác thực User này?",
                    onOk: () => {
                      dispatch(
                        updateIdentifyUserStatusRequest({
                          idUser: _id,
                          action: "rejected",
                        })
                      ).then(() => {
                        dispatch(getListIdentifyUserRequest(searchObject));
                      });
                    },
                  },
                })
              )
            }
          >
            <div style={{ color: "red", fontWeight: "bolder" }}>Từ chối</div>
          </Menu.Item>
        </Menu>
      );
    }
    return <></>;
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );
  const columns = [
    IdentifyUserColumn._id,
    IdentifyUserColumn.username,
    IdentifyUserColumn.date,
    IdentifyUserColumn.email,
    IdentifyUserColumn.phone,
    {
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      dispatch(setSelectedBusinessIds({ record, selected, selectedRows }));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      dispatch(setSelectedBusinessIds({ selected, selectedRows, changeRows }));
    },
  };

  return (
    <div className="register-list-wrapper">
      <Row className="register-list-header" justify="space-between">
        <Typography.Title level={4}>Xác minh danh tính</Typography.Title>
      </Row>
      <div className="content-container">
        <Tabs
          className="cosmetic-tabs-container"
          activeKey={searchObject.mine}
          type="card"
          style={{ marginTop: 12 }}
          onChange={(key) => {
            setSearch(
              {
                type: key,
                keyword: searchObject.keyword,
                status: searchObject.status,
                activeStatus: searchObject.activeStatus,
              },
              true
            );
          }}
        >
          {["CONFIRM", "WAITING"].map((value) => (
            <TabPane
              className="status-tab-pane"
              tab={<TabHeader status={value} total={100} />}
              key={value}
            >
              <Table
                rowSelection={rowSelection}
                scroll={{ x: 1000 }}
                loading={isLoading}
                bordered
                columns={columns}
                dataSource={identifyList}
                rowKey={(data) => data._id}
                total={ratingTotal}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default IdentifyUser;
