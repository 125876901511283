import { useRef, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import i18n from "i18n";
import PropTypes from "prop-types";
import { Row, Col, Button } from "antd";
import { TextInput, Select, MultiImageUpload } from "components/common";
import { CURRENCY } from "utils/constants";
import { editRegisteredSimValidate } from "./validate";
import "./editStyles.less";

const TRANSACTION_TYPES = [
  { value: "cash", label: i18n.t("simRegistered.transaction.cash") },
  { value: "transfer", label: i18n.t("simRegistered.transaction.transfer") },
  { value: "Daibiki", label: i18n.t("simRegistered.transaction.Daibiki") },
];

const EditForm = ({ initialValues, onSubmit, isCreating, loading }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const formikRef = useRef(null);

  useEffect(() => {
    if (initialValues.imageMachine) {
      setSelectedItem(initialValues.imageMachine);
    }
  }, [initialValues.imageMachine]);

  const handleSubmitForm = (values) => {
    onSubmit({ ...values, imageMachine: selectedItem });
  };

  if (!initialValues.currency) initialValues.currency = CURRENCY.JPY;

  return (
    <div className="product-create-form-wrapper">
      <Formik
        validateOnMount
        initialValues={initialValues}
        enableReinitialize
        validationSchema={editRegisteredSimValidate}
        onSubmit={handleSubmitForm}
        innerRef={formikRef}
      >
        {({ handleSubmit, values }) => (
          <Form>
            <Row className="form-grid">
              <Col span={24}>
                <TextInput
                  label={i18n.t("simRegistered.name")}
                  name="name"
                  inputType="name"
                  required
                />
                <TextInput
                  label={i18n.t("simRegistered.customOrderId")}
                  name="customOrderId"
                  inputType="name"
                  required
                />
                <TextInput
                  label={i18n.t("simRegistered.postalCode")}
                  name="postalCode"
                  inputType="name"
                  required
                />
                <TextInput
                  label={i18n.t("simRegistered.prefixPhoneNumber")}
                  name="prefixPhoneNumber"
                  inputType="name"
                />
                <TextInput
                  label={i18n.t("simRegistered.deliveryAddress")}
                  name="deliveryAddress"
                  inputType="name"
                  required
                />
                <TextInput
                  label={i18n.t("simRegistered.alienRegistrationAddress")}
                  name="alienRegistrationAddress"
                  inputType="name"
                />
                <TextInput
                  label={i18n.t("simRegistered.email")}
                  name="email"
                  inputType="name"
                  required
                />
                <TextInput
                  label={i18n.t("ratingComment.phone")}
                  name="phoneNumber"
                  inputType="name"
                  required
                />
                <TextInput
                  label={i18n.t("simRegistered.facebookUrl")}
                  name="facebookUrl"
                  inputType="name"
                  required
                />
                <Select
                  label={i18n.t("simRegistered.transferType")}
                  name="transactionType"
                  options={TRANSACTION_TYPES}
                  // onChange={(value) => handleSelectTypeOnchange(value)}
                  required
                />
                <Col>
                  <MultiImageUpload
                    name="alienRegistrationFrontImages"
                    label={i18n.t("simRegistered.alienRegistrationFrontImages")}
                  />
                </Col>
                <Col>
                  <MultiImageUpload
                    name="alienRegistrationBackImages"
                    label={i18n.t("simRegistered.alienRegistrationBackImages")}
                  />
                </Col>
                <Col>
                  <MultiImageUpload
                    name="alienRegistrationsWithFaceImages"
                    label={i18n.t(
                      "simRegistered.alienRegistrationsWithFaceImages"
                    )}
                  />
                </Col>
              </Col>
            </Row>
            <Row justify="space-between" className="button-wrapper">
              <Col span={24}>
                <Button
                  loading={loading}
                  onClick={handleSubmit}
                  type="primary"
                  size="large"
                >
                  {isCreating ? "Đăng bài" : "Cập nhật"}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

EditForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  isCreating: PropTypes.bool,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  userRole: PropTypes.string,
  valueTypeList: PropTypes.array,
};
export default EditForm;
