/* eslint-disable react/display-name */

import StatusTag from "components/StatusTag";
import { SwitchSelector } from "components/common/Form";
import { BANNER_STATUS_TEXT, BANNER_TYPE_POSITION } from "utils/constants";
import i18n from "i18n";

const Columns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.bannerId = {
  title: "#",
  dataIndex: "bannerId",
  key: "bannerId",
  width: 105,
  render: (bannerId) => renderDataValue(`#${bannerId}`),
};

Columns.imageUrl = {
  title: "Banner",
  dataIndex: "image",
  key: "image",
  width: 330,
  render: (image, record) => {
    const horizontalBannerList = [
      "home_1",
      "home_2",
      "home_3",
      "community_1",
      "community_2",
    ];
    const isHorizontalBanner = horizontalBannerList.includes(record.type[0]);
    return renderDataValue(
      <div
        className={`banner-image ${
          isHorizontalBanner ? "" : "vertical-banner"
        }`}
      >
        <img src={image} alt="" />
      </div>
    );
  },
};

Columns.url = {
  title: "URL",
  dataIndex: "url",
  key: "url",
  render: (url) => renderDataValue(<div className="banner-url ">{url}</div>),
};

Columns.status = {
  title: "Trạng thái",
  dataIndex: "active",
  key: "active",
  render: (active, record, onClick) =>
    renderDataValue(
      <div className="status-active">
        <StatusTag status={active} statusText={BANNER_STATUS_TEXT[active]} />
        <SwitchSelector onClick={onClick} checked={active} />
      </div>
    ),
  width: 180,
};

Columns.position = {
  title: "Vị trí",
  dataIndex: "type",
  key: "type",
  render: (type = []) =>
    renderDataValue(
      <div>
        {type &&
          type.map((item, index) => (
            <div key={index}>{BANNER_TYPE_POSITION[item]}</div>
          ))}
      </div>
    ),
  width: 180,
};

export default Columns;
