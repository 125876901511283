/* eslint-disable react/display-name */

import { Row, Col, Tooltip, Tag } from "antd";
// import _get from 'lodash/get';
// import _isEmpty from 'lodash/isEmpty';
// import "./style.less";
import i18n from "i18n";
import moment from "moment";
import StatusBlock from "components/StatusBlock";
import ArticlePermissionTooltip from "components/ArticlePermissionTooltip";
import SummaryTooltip from "components/SummaryTooltip";
import NewsSummaryTooltip from "components/SummaryTooltip/NewsSummaryTooltip";

const Columns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.id = {
  dataIndex: "newsId",
  key: "newsId",
  title: "#",
  render: (newsId, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<NewsSummaryTooltip newsDetail={data} />}
      >
        <div>#{newsId}</div>
      </SummaryTooltip>
    ),
  width: 195,
};
Columns.type = {
  dataIndex: "type",
  key: "type",
  title: "Loại tin tức",
  render: (value) => {
    switch (value) {
      case "ACQUISITION":
        return (
          <Tag color="blue" style={{}}>
            Thu mua
          </Tag>
        );
      case "NEWS":
        return (
          <Tag color="green" style={{}}>
            Tin tức
          </Tag>
        );

      default:
        return null;
    }
  },

  width: 195,
};
Columns.createdAt = {
  dataIndex: "createdAt",
  key: "createdAt",
  title: i18n.t("newScreen.createAt"),
  width: 110,
  render: (createdAt, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<NewsSummaryTooltip newsDetail={data} />}
      >
        <div>{moment(createdAt).format("DD/MM/YYYY")}</div>
      </SummaryTooltip>
    ),
};

Columns.title = {
  title: i18n.t("newScreen.title"),
  dataIndex: "title",
  key: "title",
  sorter: true,
  render: (title, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<NewsSummaryTooltip newsDetail={data} />}
      >
        <div>{title}</div>
      </SummaryTooltip>
    ),
  width: 400,
};

Columns.username = {
  title: i18n.t("newScreen.account"),
  dataIndex: "idUser",
  key: "idUser",
  render: (idUser = {}, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<NewsSummaryTooltip newsDetail={data} />}
      >
        <div>{idUser.name}</div>
      </SummaryTooltip>
    ),
};
Columns.status = {
  title: i18n.t("newScreen.status"),
  dataIndex: "status",
  key: "status",
  width: 80,
  render: (status = []) =>
    renderDataValue(
      <Row>
        <Tooltip
          title={<ArticlePermissionTooltip active={status} />}
          color="white"
        >
          <Col style={{ marginRight: 4 }}>
            <StatusBlock status={status} />
          </Col>
        </Tooltip>
      </Row>
    ),
};

export default Columns;
