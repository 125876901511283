/* eslint-disable react/display-name */

import i18n from "i18n";
import moment from "moment";

const Columns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

const renderActionType = (value) => {
  const style = {
    color: value === "created" ? "green" : "red",
    fontWeight: "bold",
  };
  return (
    <span style={style}>
      {i18n.t(`historyEditFonehouse.${value}`) || i18n.t("general.none")}
    </span>
  );
};
Columns.createdAt = {
  dataIndex: "createdAt",
  key: "createdAt",
  title: i18n.t("historyEditFonehouse.createdAt"),
  sorter: true,
  render: (createdAt) =>
    renderDataValue(moment(createdAt).format("DD/MM/YYYY")),
  width: 130,
};

Columns.account = {
  title: i18n.t("historyEditFonehouse.account"),
  dataIndex: ["user", "name"],
  key: "name",
  sorter: true,
  render: (account) => renderDataValue(account),
};

Columns.email = {
  title: i18n.t("historyEditFonehouse.email"),
  dataIndex: ["user", "email"],
  key: "email",
  render: (email = {}) => renderDataValue(email),
  width: 200,
};

Columns.time = {
  title: i18n.t("historyEditFonehouse.time"),
  dataIndex: "updatedAt",
  key: "updatedAt",
  render: (time = {}) => renderDataValue(time),
  width: 200,
};

Columns.action = {
  title: i18n.t("historyEditFonehouse.action"),
  dataIndex: "type",
  key: "type",
  render: (type = {}) => renderActionType(type),
  width: 200,
};

Columns.id = {
  title: i18n.t("historyEditFonehouse.id"),
  dataIndex: "_id",
  key: "_id",
  render: (id = {}) => renderDataValue(id),
  width: 200,
};

export default Columns;
