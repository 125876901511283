// eslint-disable-next-line import/no-anonymous-default-export
export default {
  menu: {
    article: "Bài viết",
    news: "Tin tức",
    jobs: "Việc làm",
    tutorial: "Cẩm nang",
    tourist: "Du lịch",
    visa: "Visa du lịch",
    rent: "Thuê nhà",
    restaurant: "Nhà hàng",
    entertainment: "Giải trí",
    supermarket: "Siêu thị",
    transport: "Vận chuyển",
    fonehouse: "Fonehouse",
    products: "Sản phẩm",
    registerBusiness: "Đăng ký kinh doanh",
    newsFonehouse: "Tin tức Fonehouse",
    statisticalFonehouse: "Thống kê",
    list_bill: "Danh sách đơn hàng",
    checking_insurance: "Kiểm tra bảo hành",
    guideFonehouse: "Cẩm nang",
    moneyManagementFonehouse: "Thu chi",
    historyEditor: "Lịch sử chỉnh sửa",
    messengers: "Tin nhắn",
    cosmetic: "Làm đẹp",
    accessories: "Danh sách phụ kiện",
  },
  reportManager: {
    listTitle: "Quản lý báo cáo",
    goToStore: "Xem cửa hàng",
    message: "Gửi tin nhắn",
    approveReport: "Duyệt",
    cancelReport: "Từ chối",
    storeName: "Tên cửa hàng",
    storeType: "Loại cửa hàng",
    phone: "Số điện thoại",
    address: "Địa chỉ",
    content: "Nội dung",
    status: "Trạng thái",
    seeDetail: "Xem chi tiết",
    reportType: "Loại báo cáo",
    STORE_DOES_NOT_EXIST: "Cửa hàng không tồn tại",
    WRONG_ADDRESS: "Địa chỉ cửa hàng không đúng",
    WRONG_PHONE: "Số điện thoại cửa hàng không đúng",
    REQUEST_TO_BECOME_OWNER: "Yêu cầu trở thành quản lý cửa hàng",
    OTHER: "Khác",
    seeReport: "Báo cáo chi tiết",
  },
  loginScreen: {
    login: "ĐĂNG NHẬP",
    username: "Tên đăng nhập",
    password: "Mật khẩu",
    otpInput: "NHẬP OTP",
    resendOtp: "Gửi lại otp",
    verifyOtp: "Xác nhận",
    email: "Email",
    phoneNumber: "Số điện Thoại",
  },
  errors: {
    required: "Yêu cầu dữ liệu",
    invalidEmail: "Email không hợp lệ",
    invalid: "Giá trị không hợp lệ",
    noData: "Trống",
  },
  admin: {},
  newScreen: {
    createAt: "Ngày đăng",
    title: "Tiêu đề",
    account: "Tài khoản",
    status: "Duyệt",
    publishNews: "Duyệt bài",
    newDetail: "Xem thông tin",
    unPublishNews: "Từ chối",
  },
  visacreen: {
    createAt: "Ngày đăng",
    title: "Tiêu đề",
    account: "Tài khoản",
    status: "Duyệt",
    publishVisa: "Duyệt bài",
    newDetail: "Xem thông tin",
    unPublishVisa: "Từ chối",
  },
  newSummary: {
    seeDetail: "Xem chi tiết",
  },
  visaSummary: {
    seeDetail: "Xem chi tiết",
  },
  general: {
    none: "Trống",
  },
  menuUserInfo: {
    viewInformation: " Xem thông tin",
    accuracy: "Xác thực",
    unAccuracy: "Huỷ xác thực",
    decentralization: "Phân quyền",
    decentralizationUser: "Phân quyền User",
    blockUser: "Khoá tài khoản",
    unblockUser: "Mở khoá tài khoản",
  },
  alert: {
    error: "Lỗi",
  },
  menuAccount: {
    logOut: "Đăng xuất",
  },
  titleUserInfo: {
    basicInfomation: "THÔNG TIN CƠ BẢN",
    followers: "NGƯỜI THEO DÕI",
    decentralization: "PHÂN QUYỀN",
    introduce: "GIỚI THIỆU",
    contactInfo: "THÔNG TIN LIÊN HỆ",
    memberOfFamily: "THÀNH VIÊN TRONG GIA ĐÌNH",
    relationshipStatus: "TÌNH TRẠNG MỐI QUAN HỆ",
    group: "NHÓM",
  },
  products: {
    insuranceTitle: "Kiểm tra bảo hành",
    expire: "Hết hạn",
    noExpire: "Còn hạn",
    saleDate: "Ngày mua",
    insuranceDate: "Bảo hành",
    warrantyExpirationDate: "Kết thúc",
    status: "Trạng thái",
    entryDate: "Ngày nhập",
    ram: "Bộ nhớ",
    listTitle: "Sản phẩm Fonehouse",
    id: "Mã sản phẩm",
    name: "Tên sản phẩm",
    state: "Tình trạng",
    guarantee: "Bảo hành",
    amount: "Số lượng",
    type: "Loại sản phẩm",
    price: "Giá trị gốc",
    promotionPrice: "Giá khuyến mãi",
    tag: "Tag",
    seeProduct: "Xem sản phẩm",
    deleteProduct: "Xóa sản phẩm",
    editProduct: "Chỉnh sửa",
    setTag: "Set tag",
    deleteConfirm: "Xác nhận xóa sản phẩm",
    setTagForAll: "Set tag cho tất cả sản phẩm",
    setProductTag: "Set tag sản phẩm",
    basicInfo: "Thông tin cơ bản",
    recoverProduct: "Phục hồi",
    address: "Địa chỉ",
  },
  productDetail: {
    extraPrice: "Lãi/Lỗ",
    createProduct: "Đăng bài",
    productDetail: "Sản phẩm chi tiết",
    inputDate: "Ngày nhập",
    description: "Mô tả chi tiết",
    storage: "Bộ nhớ",
    battery: "Pin",
    capacity: "Dung lượng",
    softwareVersion: "Phiên bản phần mềm",
    imei: "IMEI (seri)",
    discount: "Khuyến mãi",
    discountPrice: "Giá khuyến mãi",
    inputPrice: "Giá gốc",
    price: "Giá bán",
    promotionPrice: "Giá sau khuyến mãi",
    type: "Loại",
    amount: "Số lượng",
    contact: "Liên hệ",
    address: "Địa chỉ",
    state: "Tình trạng",
    guarantee: "Bảo hành",
    name: "Tên sản phẩm",
    accessories: "Phụ kiện",
    id: "Mã sản phẩm",
    currency: "Đơn vị",
    entryDate: "Ngày nhập",
    howToBuy: "Cách thức mua hàng",
    machineSeries: "Dòng",
  },
  register: {
    listTitle: "Đăng ký kinh doanh",
    approve: "Duyệt đơn",
    cancel: "Từ chối",
    createAt: "Ngày gửi",
    storeName: "Tên cửa hàng",
    type: "Loại hình",
    account: "Tài khoản",
    address: "Địa chỉ",
    status: "Trạng thái",
    seeDetail: "Xem thông tin",
    businessName: "Tên doanh nghiệp",
    businessType: "Loại hình kinh doanh",
    businessPhone: "Số điện thoại",
    banner: "Ảnh bìa",
    businessLicenses: "Ảnh giấy phép kinh doanh",
    businessImages: "Ảnh doanh nghiệp",
    identityCard: "Ảnh thẻ ngoại kiều",
    note: "Ghi chú",
    file: "File doanh nghiệp",
    headerTitle: "Đơn đăng ký kinh doanh chi tiết",
    avatar: "Ảnh đại diện",
  },
  newsFonehouse: {
    listTitle: "Danh sách tin tức",
    viewInformation: " Xem thông tin",
    removeNews: "Xóa tin tức",
    edit: "Chỉnh sửa",
    createAt: "Ngày đăng",
    title: "Tiêu đề",
    account: "Tài khoản",
  },
  supermarket: {
    listTitle: "Danh sách siêu thị",
    lock: "Khoá doanh nghiệp",
    seeDetail: "Xem thông tin",
    storeName: "Tên siêu thị",
    address: "Địa chỉ",
    rating: "Đánh giá",
    status: "Trạng thái",
    open: "Giờ mở cửa",
    close: "Giờ đóng cửa",
    phone: "Số điện thoại",
    supermarketDetail: "Thông tin siêu thị",
    unlock: "Mở khóa doanh nghiệp",
    pinPromotional: "Ghim quảng cáo",
    unpinPromotional: "Bỏ ghim quảng cáo",
    advertise: "Quảng cáo",
  },
  restaurant: {
    listTitle: "Danh sách nhà hàng",
    lock: "Khoá doanh nghiệp",
    seeDetail: "Xem thông tin",
    storeName: "Tên nhà hàng",
    address: "Địa chỉ",
    rating: "Đánh giá",
    status: "Trạng thái",
    open: "Giờ mở cửa",
    close: "Giờ đóng cửa",
    phone: "Số điện thoại",
    supermarketDetail: "Thông tin nhà hàng",
    shopName: "Tên quán",
    shopOpening: "Mở cửa",
    shopClosed: "Đóng cửa",
    lockBussiness: "Khóa doanh nghiệp",
    entertainmentDetail: "Giải trí chi tiết",
    businessDescription: "Mô tả doanh nghiệp",
    unlock: "Mở khóa doanh nghiệp",
    pinPromotional: "Ghim quảng cáo",
    unpinPromotional: "Bỏ ghim quảng cáo",
    advertise: "Quảng cáo",
  },
  marketProduct: {
    productList: "Danh sách mặt hàng",
    productName: "Tên sản phẩm",
    type: "Loại",
    mass: "Khối lượng",
    amount: "Số lượng",
    promotion: "Khuyến mãi",
    price: "Giá bán",
    promotionPrice: "Giá sau KM",
    profit: "Tiền lãi",
    seeDetail: "Xem chi tiết",
    edit: "Chỉnh sửa",
    delete: "Xóa sản phẩm",
    productDetail: "Sản phẩm chi tiết",
    basicInfomation: "Thông Tin Cơ bản",
  },
  restaurantProduct: {
    productList: "Danh sách mặt hàng",
    productName: "Tên sản phẩm",
    type: "Loại",
    mass: "Khối lượng",
    amount: "Số lượng",
    promotion: "Khuyến mãi",
    price: "Giá bán",
    promotionPrice: "Giá sau khuyến mãi",
    profit: "Tiền lãi",
    seeDetail: "Xem chi tiết",
    edit: "Chỉnh sửa",
    delete: "Xóa sản phẩm",
    element: "Mô tả (Size)",
  },
  entertainment: {
    shopName: "Tên quán",
    address: "Địa chỉ",
    rating: "Đánh giá",
    status: "Trạng thái",
    lockBussiness: "Khóa doanh nghiệp",
    entertainmentDetail: "Giải trí chi tiết",
    businessDescription: "Mô tả doanh nghiệp",
    unlock: "Mở khóa doanh nghiệp",
    pinPromotional: "Ghim quảng cáo",
    unpinPromotional: "Bỏ ghim quảng cáo",
    advertise: "Quảng cáo",
    seeDetail: "Xem thông tin",
  },
  bussinessStatus: {
    bussinessOpening: "Mở cửa",
    bussinessClosed: "Đóng cửa",
    bussinessLock: "Khóa",
  },
  adminManager: {
    phone: "Số điện thoại",
    displayName: "Tên hiển thị",
    email: "Email",
    accuracy: "Phân quyền chat",
    listTitle: "QUẢN LÝ ADMIN",
    seeDetail: "Xem thông tin",
    denineAdmin: "Huỷ quyền Admin",
    deninedAccuracy: "Thu hồi quyền Chat",
    approve: "Duyệt",
    cancel: "Trở lại",
  },
  billingList: {
    discountPercentValue: "Phần trăm giá trị",
    discountConfirm: "Xác nhận giảm giá",
    discountValue: "Giá khuyến mãi",
    discount: "Khuyến mãi",
    discountDirectTitle: "Giảm giá trực tiếp vào đơn hàng",
    transfer: "GD trả trước",
    daibiki: "Daibiki",
    cash: "GD cửa hàng",
    seeDetail: "Xem thông tin",
    cancelOrder: "Huỷ giao dịch",
    title: "Danh sách đơn hàng",
    billCode: "Mã đơn hàng",
    phone: "Số điện thoại",
    createAt: "Ngày đặt",
    status: "Trạng thái đơn hàng",
    tranfer: "Giao dịch",
    price: "Giá trị",
    new: "Đơn hàng mới",
    cancel: "Đã huỷ",
    waitting: "Đã gửi hàng",
    ordered: "Đã giao",
    name: "Khách hàng",
    products: "Sản phẩm",
  },
  billingDetail: {
    orderCustomId: "Mã đơn hàng",
    promotion: "Giảm giá",
    initialPrice: "Giá bán ra",
    totalInitial: "Tổng giá đơn hàng gốc",
    shippingFee: "Phí ship",
    title: "Đơn hàng chi tiết",
    message: "Nhắn tin",
    discountDirect: "Giảm giá trực tiếp vào đơn hàng",
    sendProduct: "Gửi hàng",
    cancelOrder: "Huỷ giao dịch",
    alreadySend: "Đã giao",
    productCode: "Mã sản phẩm",
    imei: "IMEI",
    softwareVersion: "Phiên bản phần mềm",
    accessories: "Phụ kiện",
    amount: "Số lượng",
    priceSale: "Giá khuyến mãi",
    createAt: "Ngày nhập",
    manufacture: "Nhà cung cấp",
    type: "Loại",
    memory: "Bộ nhớ",
    inputPrice: "Giá nhập kho",
    extraPrice: "Lãi suất",
    basicInformation: "Thông tin cơ bản",
    edit: "Chỉnh sửa",
    customerName: "Tên khách hàng:",
    phone: "Số điện thoại:",
    address: "Địa chỉ:",
    timeProduct: "Khung giờ nhận hàng",
    billDetail: "Chi tiết thanh toán",
    totalPrice: "Tổng giá đơn hàng",
    discount: "Voucher giảm giá",
    total: "Tổng giá trị đơn hàng",
    totalExtra: "Tổng lãi",
    orderDate: "Ngày đặt hàng",
    postalCode: "Mã bưu điện",
    productName: "Tên sản phẩm",
    pointToMoney: "Số tiền qui đổi từ điểm",
    productPrice: "Giá trị sản phẩm",
    accessoryDetail: "Chi tiết phụ kiện",
    quantityChoose: "Số lượng chọn",
    imageMachine: "Ảnh màu máy",
    editProduct: "Chỉnh sửa sản phẩm",
  },
  createBill: {
    customId: "Mã đơn hàng",
    shippingFee: "Phí Ship",
    updateBill: "Cập nhật đơn hàng",
    titleEdit: "Chỉnh sửa đơn hàng",
    title: "Tạo đơn hàng mới",
    transactionType: "Kiểu giao dịch",
    orderFrom: "Nguồn đơn",
    customerName: "Tên khách hàng",
    phone: "Số điện thoại",
    postCode: "Mã bưu điện",
    address: "Địa chỉ",
    timeFrame: "Khung giờ nhận",
    billDetail: "Chi tiết đơn hàng",
    add: "Thêm sản phẩm",
    create: "Tạo đơn hàng",
    addProductToBill: "Thêm sản phẩm vào đơn hàng",
    currency: "Đơn vị",
    remove: "Xoá sản phẩm",
    addAccessories: "Thêm phụ kiện",
    addAccessoriesToBill: "Thêm phụ kiện vào đơn hàng",
    productList: "Danh sách sản phẩm",
    accessoryList: "Danh sách phụ kiện",
    accessoryName: "Tên phụ kiện",
  },
  banner: {
    setBannerAdmin: "Set Banner Admin",
    createBanner: "TẠO BANNER",
    cancelBtn: "Hủy bỏ",
    fieldURL: "URL",
    fieldPosition: "Vị trí",
    updateBanner: "CẬP NHẬT",
  },
  guideFonehouse: {
    removeGuide: "Xoá bài đăng",
    edit: "Chỉnh sửa",
    viewInformation: "Xem chi tiết",
    listTitle: "Danh sách cẩm nang",
    pinGuide: "Ghim bài viết",
    unpinGuide: "Bỏ ghim bài viết",
  },
  statisticalFonehouse: {
    id: "Mã đơn hàng",
    type: "Loại đơn",
    status: "Trạng thái",
    account: "Tài khoản",
    promotionPrice: "Giá bán",
    initialPrice: "Giá nhập",
    interestRate: "Lãi suất",
    listTitle: "Thống kê đơn hàng Fonehouse",
    edit: "Chỉnh sửa",
    remove: "Xoá",
    createdAt: "Ngày tạo đơn",
    moveDetail: "Xem chi tiết",
  },
  moneyManagementFonehouse: {
    listTitle: "Quản lý thu chi",
    createdAt: "Ngày nhập",
    content: "Diễn giải",
    collect: "Thu",
    give: "Chi",
    price: "Chi phí",
    moneyLeft: "Tồn",
    transferType: "Hình thức TT",
    confirm: "Xác nhận",
    type: "Loại",
    createRecord: "Tạo mới",
    updateRecord: "Cập nhật",
    value: "Giá trị",
    transfer: "Chuyển khoản",
    cash: "Tiền mặt",
    edit: "Chỉnh sửa",
    remove: "Xoá",
  },
  historyEditFonehouse: {
    moveDetail: "Đi tới trang chi tiết",
    listTitle: "Lịch sử chỉnh sửa",
    createdAt: "Ngày log",
    account: "Tài khoản",
    email: "Email",
    time: "Thời gian",
    action: "Hành động",
    id: "ID",
    created: "Tạo mới",
    updated: "Chỉnh sửa",
  },
  checking_insurance: {},
  messenger: {
    pageTitle: "Danh sách tin nhắn",
  },
  constants: {
    dateCharacter: {
      day: "日",
      month: "月",
      year: "年",
    },
  },
  actionButton: {
    update: "Cập Nhật",
  },
  updateNameTypeModal: {
    title: "Thay đổi tên loại",
    productType: "Loại sản phẩm",
  },
  entertainmentProduct: {
    productList: "Danh sách mặt hàng",
    productName: "Tên sản phẩm",
    type: "Loại",
    mass: "Khối lượng",
    amount: "Số lượng",
    promotion: "Khuyến mãi",
    price: "Giá bán",
    promotionPrice: "Giá sau khuyến mãi",
    profit: "Tiền lãi",
    seeDetail: "Xem chi tiết",
    edit: "Chỉnh sửa",
    delete: "Xóa sản phẩm",
    element: "Thành phần",
    basicInfomation: "Thông Tin Cơ bản",
    productDetail: "Sản phẩm chi tiết",
  },
  cosmetic: {
    shopName: "Tên quán",
    address: "Địa chỉ",
    rating: "Đánh giá",
    status: "Trạng thái",
    lockBussiness: "Khóa doanh nghiệp",
    cosmeticDetail: "Làm đẹp chi tiết",
    businessDescription: "Mô tả doanh nghiệp",
    unlock: "Mở khóa doanh nghiệp",
    pinPromotional: "Ghim quảng cáo",
    unpinPromotional: "Bỏ ghim quảng cáo",
    advertise: "Quảng cáo",
    seeDetail: "Xem thông tin",
    accountHolder: "Chủ tài khoản",
  },
  cosmeticProduct: {
    productList: "Danh sách mặt hàng",
    productName: "Tên sản phẩm",
    type: "Loại",
    mass: "Khối lượng",
    amount: "Số lượng",
    promotion: "Khuyến mãi",
    price: "Giá bán",
    promotionPrice: "Giá sau khuyến mãi",
    profit: "Tiền lãi",
    seeDetail: "Xem chi tiết",
    edit: "Chỉnh sửa",
    delete: "Xóa sản phẩm",
    element: "Thành phần",
    basicInfomation: "Thông Tin Cơ bản",
    productDetail: "Sản phẩm chi tiết",
  },
  support: {
    listTitle: "Danh sách hỗ trợ",
    seeDetail: "Xem chi tiết",
    account: "Tài khoản",
    type: "Loại hình",
    title: "Tiêu đề",
    note: "Ghi chú",
    delete: "Xóa",
    supportFeedback: "Hỗ trợ - Phản hồi",
  },
  ratingComment: {
    deleteComment: "Xoá đánh giá",
    listTitle: "Danh sách bình luận và đánh giá",
    storeName: "Tên cửa hàng",
    storeType: "Loại cửa hàng",
    phone: "Số điện thoại",
    address: "Địa chỉ",
    content: "Nội dung",
    status: "Trạng thái",
    rating: "Đánh giá",
    userName: "Tài khoản",
    title: "Đánh giá chi tiết",
  },
  simRegistered: {
    name: "Tên người đăng kí",
    customOrderId: "Mã đơn hàng",
    postalCode: "Mã bưu điện",
    email: "Email",
    simName: "Tên SIM",
    type: "Loại",
    transferType: "Loại hình giao dịch",
    timeFrame: "Khung giờ giao hàng",
    simAddress: "Địa chỉ nhận Sim",
    facebookUrl: "Link facebook",
    originalPrice: "Giá SIM (giá gốc)",
    promotion: "Khuyến mãi SIM",
    connectionFee: "Cước nhà mạng (giá gốc)",
    promotionConnection: "Khuyến mãi cước nhà mạng",
    deliveryAddress: "Địa chỉ nhận Sim",
    alienRegistrationAddress: "Địa chỉ trên thẻ ngoại kiều",
    transaction: {
      cash: "Giao dịch tại cửa hàng",
      transfer: "Chuyển tiền trước (miễn ship)",
      Daibiki: "Daibiki thanh toán tại nhà ",
    },
    alienRegistrationFrontImages: "Ảnh thẻ ngoại kiều (mặt trước)",
    alienRegistrationBackImages: "Ảnh thẻ mặt sau (mặt sau)",
    alienRegistrationsWithFaceImages: "Ảnh chụp cùng thẻ ngoại kiều",
    prefixPhoneNumber: "Đầu số",
  },
  // Generic
  "generic.add": "Thêm",
  "generic.cancel": "Hủy",

  // BlockType
  "components.controls.blocktype.h1": "H1",
  "components.controls.blocktype.h2": "H2",
  "components.controls.blocktype.h3": "H3",
  "components.controls.blocktype.h4": "H4",
  "components.controls.blocktype.h5": "H5",
  "components.controls.blocktype.h6": "H6",
  "components.controls.blocktype.blockquote": "Blockquote",
  "components.controls.blocktype.code": "Code",
  "components.controls.blocktype.blocktype": "Block Type",
  "components.controls.blocktype.normal": "Normal",

  // Color Picker
  "components.controls.colorpicker.colorpicker": "Chọn màu",
  "components.controls.colorpicker.text": "Text",
  "components.controls.colorpicker.background": "Highlight",

  // Embedded
  "components.controls.embedded.embedded": "Embedded",
  "components.controls.embedded.embeddedlink": "Youtube Link",
  "components.controls.embedded.enterlink": "Youtube link",

  // Emoji
  "components.controls.emoji.emoji": "Emoji",

  // FontFamily
  "components.controls.fontfamily.fontfamily": "Font",

  // FontSize
  "components.controls.fontsize.fontsize": "Font Size",

  // History
  "components.controls.history.history": "History",
  "components.controls.history.undo": "Undo",
  "components.controls.history.redo": "Redo",

  // Image
  "components.controls.image.image": "Hình",
  "components.controls.image.fileUpload": "Upload hình",
  "components.controls.image.byURL": "URL",
  "components.controls.image.dropFileText": "Kéo hoặc chọn hình",

  // Inline
  "components.controls.inline.bold": "Bold",
  "components.controls.inline.italic": "Italic",
  "components.controls.inline.underline": "Underline",
  "components.controls.inline.strikethrough": "Strikethrough",
  "components.controls.inline.monospace": "Monospace",
  "components.controls.inline.superscript": "Superscript",
  "components.controls.inline.subscript": "Subscript",

  // Link
  "components.controls.link.linkTitle": "Link Title",
  "components.controls.link.linkTarget": "Link Target",
  "components.controls.link.linkTargetOption": "Mở link trong tab mới",
  "components.controls.link.link": "Link",
  "components.controls.link.unlink": "Unlink",

  // List
  "components.controls.list.list": "List",
  "components.controls.list.unordered": "Unordered",
  "components.controls.list.ordered": "Ordered",
  "components.controls.list.indent": "Indent",
  "components.controls.list.outdent": "Outdent",

  // Remove
  "components.controls.remove.remove": "Remove",

  // TextAlign
  "components.controls.textalign.textalign": "Text Align",
  "components.controls.textalign.left": "Left",
  "components.controls.textalign.center": "Center",
  "components.controls.textalign.right": "Right",
  "components.controls.textalign.justify": "Justify",
};
