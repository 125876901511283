import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    decentralizationModal: {
      isOpen: false,
    },
  },
  reducers: {
    getAllUserRequest(state) {
      return { ...state, isLoading: true };
    },
    getAllUserSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs,
      };
    },
    stopLoading(state) {
      return { ...state, isLoading: false };
    },
    setSelectedIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    updateAccuracyRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateAccuracySuccess: {
      reducer: (state) => {
        state.isLoading = false;
        state.selectedRowKeys = [];
        return state;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    setDecentralizationPopup(state, action) {
      state.decentralizationModal.isOpen = action.payload.isOpen;
      if (action.payload.data) {
        state.decentralizationModal.data = action.payload.data;
      }
      state.decentralizationModal.form = action.payload.form;
      return state;
    },
    updateRolesRequest(state) {
      return { ...state, isLoading: true };
    },

    updateRolesSuccess(state, action) {
      const { payload = {} } = action;
      const { data, idUser, docs } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({
          ...doc,
          roles: idUser === doc._id ? data.roles : doc.roles,
        })),
      };
    },

    updateUserRoleRequest(state) {
      return { ...state, isLoading: true };
    },

    updateUserRoleSuccess(state, action) {
      const { payload = {} } = action;
      const { idUser, docs, totalDocs } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.filter((item) => idUser[0] !== item._id),
        totalDocs: totalDocs - 1,
      };
    },
    blockUserRequest: {
      reducer: (state) => {
        state.isLoading = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    blockUserSuccess: {
      reducer: (state) => {
        state.isLoading = false;
        state.selectedRowKeys = [];
        return state;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    unblockUserRequest: {
      reducer: (state) => {
        state.isLoading = true;
        state.selectedRowKeys = [];
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    unblockUserSuccess: {
      reducer: (state) => {
        state.isLoading = false;
        state.selectedRowKeys = [];
        return state;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
    resetSelectedRowKey: {
      reducer: (state) => {
        state.isLoading = false;
        state.selectedRows = [];
        return state;
      },
      prepare: (payload, meta) => ({ payload, meta }),
    },
  },
});

export const {
  getAllUserRequest,
  getAllUserSuccess,
  stopLoading,
  setSelectedIds,
  setDecentralizationPopup,
  updateRolesRequest,
  updateRolesSuccess,
  updateAccuracyRequest,
  updateAccuracySuccess,
  updateUserRoleRequest,
  updateUserRoleSuccess,
  blockUserRequest,
  unblockUserRequest,
  blockUserSuccess,
  unblockUserSuccess,
  resetSelectedRowKey,
} = adminSlice.actions;

export default adminSlice.reducer;
