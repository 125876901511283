import { useMemo, useRef, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import i18n from "i18n";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Row, Col, Button } from "antd";
import {
  TextInput,
  Select,
  MultiImageUpload,
  DatePicker,
  MultiVideoUpload,
} from "components/common";
import Helper from "utils/helpers";
import {
  CURRENCY,
  PRODUCT_STATE,
  GUARANTEE,
  PROMOTION_TYPE,
  PROMOTION_TYPE_TEXT,
  ROLES,
  OS_TYPE,
  MEMORY_TYPE,
  PRODUCT_TAG_TEXT,
} from "utils/constants";
import { createProductValidate } from "./validate";
import "./styles.less";

const ProductCreatForm = ({
  initialValues,
  onSubmit,
  isCreating,
  loading,
  onDelete,
  userRole,
  valueTypeList,
}) => {
  const productTypes = useSelector((state) => state.product.productTypes);
  const [machineSeriesOptions, setMachineSeriesOptions] = useState([]);
  const [machineSeriesList, setMachineSeriesList] = useState([]);
  const [machineImageList, setMachineImageList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const formikRef = useRef(null);
  const formattedAccessories = useMemo(
    () => productTypes.map((acc) => ({ value: acc._id, label: acc.name })),
    [productTypes]
  );
  function uuidv4() {
    return "xxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 || 0;
      const v = c === "x" ? r : (r && 0x3) || 0x8;
      return v.toString(16);
    });
  }

  useEffect(() => {
    if (initialValues.imageMachine) {
      setSelectedItem(initialValues.imageMachine);
    }
  }, [initialValues.imageMachine]);
  useEffect(() => {
    if (
      formikRef.current.values.type &&
      valueTypeList &&
      !valueTypeList.includes(formikRef.current.values.type)
    ) {
      formikRef.current?.setFieldValue("type", "");
      formikRef.current?.setFieldValue("line", "");
      setMachineSeriesOptions([]);
      setMachineImageList([]);
      setSelectedItem("");
    }
  }, [valueTypeList]);

  useEffect(() => {
    if (initialValues.type || formikRef.current.values.type) {
      if (formikRef.current.values.type) {
        const productTypeFilter = productTypes.filter(
          (item) => item._id === formikRef.current.values.type
        );
        if (!_isEmpty(productTypeFilter)) {
          const formattedMachineSeriesOptions =
            productTypeFilter[0].machineSeries.map((item) => ({
              value: item.id,
              label: item.name,
            }));
          setMachineSeriesOptions(formattedMachineSeriesOptions);
          setMachineSeriesList(productTypeFilter[0].machineSeries);
          const valueMachineSeriesList = formattedMachineSeriesOptions.map(
            (item) => item.value
          );
          if (!valueMachineSeriesList.includes(formikRef.current.values.line)) {
            formikRef.current?.setFieldValue("line", "");
            setMachineImageList([]);
            setSelectedItem("");
          }
        }
      } else {
        const productTypeFilter = productTypes.filter(
          (item) => item._id === initialValues.type
        );
        if (!_isEmpty(productTypeFilter)) {
          const formattedMachineSeriesOptions =
            productTypeFilter[0].machineSeries.map((item) => ({
              value: item.id,
              label: item.name,
            }));
          setMachineSeriesOptions(formattedMachineSeriesOptions);
          setMachineSeriesList(productTypeFilter[0].machineSeries);
          const valueMachineSeriesList = formattedMachineSeriesOptions.map(
            (item) => item.value
          );
          if (!valueMachineSeriesList.includes(initialValues.line)) {
            formikRef.current?.setFieldValue("line", "");
            setMachineImageList([]);
            setSelectedItem("");
          }
        }
      }
    }
  }, [initialValues.line, initialValues.type, productTypes]);

  useEffect(() => {
    if (initialValues.line || formikRef.current.values.line) {
      const machineImageFilter = machineSeriesList.filter(
        (item) => item.id === formikRef.current.values.line
      );
      if (!_isEmpty(machineImageFilter)) {
        const machineImage = machineImageFilter[0].images.map((item) => ({
          image: item,
          _id: uuidv4(),
        }));
        setMachineImageList(machineImage);
      }
    }
  }, [initialValues.line, machineSeriesList]);

  const handlePromotionTypeChange = () => {
    formikRef.current?.setFieldValue("promotion", "0");
    formikRef.current?.setFieldValue("percentagePromotion", "0");
  };
  const handleSelectTypeOnchange = (value) => {
    const productTypeFilter = productTypes.filter((item) => item._id === value);
    const formattedMachineSeriesOptions =
      productTypeFilter[0].machineSeries.map((item) => ({
        value: item.id,
        label: item.name,
      }));
    setMachineSeriesOptions(formattedMachineSeriesOptions);
    setMachineSeriesList(productTypeFilter[0].machineSeries);
    formikRef.current?.setFieldValue("line", "");
    formikRef.current?.setFieldValue("name", "");
    setMachineImageList([]);
  };

  const handleSelectMachineSeriesOnchange = (value) => {
    const machineImageFilter = machineSeriesList.filter(
      (item) => item.id === value
    );
    formikRef.current?.setFieldValue("name", machineImageFilter[0]?.name);
    if (!_isEmpty(machineImageFilter)) {
      const machineImage = machineImageFilter[0].images.map((item) => ({
        image: item,
        _id: uuidv4(),
      }));
      setMachineImageList(machineImage);
      setSelectedItem("");
    }
  };

  const handleSelectImage = (value) => {
    if (selectedItem === value) {
      setSelectedItem("");
    } else {
      setSelectedItem(value);
    }
  };
  const handleSubmitForm = (values) => {
    onSubmit({ ...values, imageMachine: selectedItem });
  };

  if (!initialValues.currency) initialValues.currency = CURRENCY.JPY;

  return (
    <div className="product-create-form-wrapper">
      <Formik
        validateOnMount
        initialValues={initialValues}
        enableReinitialize
        validationSchema={createProductValidate}
        onSubmit={handleSubmitForm}
        innerRef={formikRef}
      >
        {({ handleSubmit, values }) => (
          <Form>
            <Row className="form-grid">
              <Col span={12}>
                <Select
                  label={i18n.t("productDetail.type")}
                  name="type"
                  options={formattedAccessories}
                  onChange={(value) => handleSelectTypeOnchange(value)}
                  required
                />
                <Select
                  label={i18n.t("productDetail.machineSeries")}
                  name="line"
                  options={machineSeriesOptions}
                  onChange={(value) => handleSelectMachineSeriesOnchange(value)}
                  required
                />
                <Row className="machine-image-list-wrapper">
                  {machineImageList.map((item) => {
                    const isSelectedItem = selectedItem === item.image;
                    return (
                      <div
                        key={item._id}
                        className={`machine-image-item ${
                          isSelectedItem ? "selected-image" : ""
                        }`}
                        onClick={() => handleSelectImage(item.image)}
                      >
                        <img src={item.image} alt="" />
                      </div>
                    );
                  })}
                </Row>
                {!isCreating && (
                  <TextInput
                    disabled
                    label={i18n.t("productDetail.id")}
                    name="productId"
                    required
                  />
                )}
                <TextInput
                  label={i18n.t("productDetail.name")}
                  name="name"
                  inputType="name"
                  required
                />
                <Select
                  label={i18n.t("productDetail.state")}
                  name="state"
                  inputType="state"
                  required
                  options={Helper.convertObjectToOptions(PRODUCT_STATE)}
                />
                <TextInput
                  label={i18n.t("productDetail.amount")}
                  name="amount"
                />
                <TextInput
                  label={i18n.t("productDetail.imei")}
                  name="imei"
                  required
                />
                <Row className="software-version-wrapper">
                  <div className="label">Phiên bản phần mềm</div>
                  <Row justify="space-between">
                    <Col span={8}>
                      <Select
                        name="os"
                        options={Helper.convertObjectToOptions(OS_TYPE)}
                      />
                    </Col>
                    <Col span={15}>
                      <TextInput
                        name="softwareVersion"
                        className="software-version"
                      />
                    </Col>
                  </Row>
                </Row>
                <TextInput
                  label={i18n.t("productDetail.battery")}
                  name="battery"
                />
                <Row className="software-version-wrapper">
                  <div className="label">{i18n.t("productDetail.storage")}</div>
                  <Row justify="space-between">
                    <Col span={15}>
                      <TextInput name="ram" required />
                    </Col>
                    <Col span={8}>
                      <Select
                        name="ramType"
                        options={Helper.convertObjectToOptions(MEMORY_TYPE)}
                      />
                    </Col>
                  </Row>
                </Row>
                <TextInput label={"Nhà cung cấp"} name="manufacture" required />
                <Select
                  label={i18n.t("products.setTag")}
                  name="tag"
                  required
                  options={Helper.convertObjectToOptions(PRODUCT_TAG_TEXT)}
                />
              </Col>
              <Col span={12}>
                <DatePicker
                  label={i18n.t("productDetail.entryDate")}
                  name="entryDate"
                  format="DD/MM/YYYY"
                  required
                />
                <Select
                  label={`${i18n.t("productDetail.guarantee")}( Tháng )`}
                  name="guarantee"
                  required
                  options={Helper.convertObjectToOptions(GUARANTEE)}
                />
                <TextInput
                  label={i18n.t("productDetail.address")}
                  name="address"
                  required
                  disabled={userRole !== ROLES.SUPER_ADMIN}
                />
                <TextInput
                  label={i18n.t("productDetail.contact")}
                  name="contact"
                  required
                  disabled={userRole !== ROLES.SUPER_ADMIN}
                />
                <TextInput
                  label={i18n.t("productDetail.accessories")}
                  name="accessories"
                />

                <Select
                  label={i18n.t("productDetail.currency")}
                  name="currency"
                  required
                  disabled
                  options={Helper.convertObjectToOptions(CURRENCY)}
                />
                <TextInput
                  label={i18n.t("productDetail.inputPrice")}
                  name="inputPrice"
                  required
                />
                <TextInput
                  label={i18n.t("productDetail.price")}
                  name="price"
                  required
                />
                <Row justify="space-between">
                  <Col span={11}>
                    <Select
                      allowClear
                      label={i18n.t("productDetail.discount")}
                      name="discountType"
                      onChange={handlePromotionTypeChange}
                      options={Helper.convertObjectToOptions(
                        PROMOTION_TYPE_TEXT
                      )}
                      disabled={Number(values.price) === 0}
                    />
                  </Col>
                  <Col span={12}>
                    <TextInput
                      label={i18n.t("productDetail.discountPrice")}
                      name={
                        formikRef.current?.values.discountType ===
                        PROMOTION_TYPE.CASH
                          ? "promotion"
                          : "percentagePromotion"
                      }
                      disabled={Number(values.price) === 0}
                    />
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col span={11}>
                    <DatePicker
                      label="Ngày bắt đầu"
                      name="promotionStartDate"
                      format="DD/MM/YYYY"
                    />
                  </Col>
                  <Col span={12}>
                    <DatePicker
                      label="Ngày kết thúc"
                      name="promotionExpirationDate"
                      format="DD/MM/YYYY"
                    />
                  </Col>
                </Row>
                <TextInput
                  label={i18n.t("productDetail.description")}
                  name="description"
                  inputType="textarea"
                  rows={5}
                />
              </Col>
            </Row>
            <Col>
              <MultiImageUpload name="images" label="Ảnh sản phẩm" required />
            </Col>
            <Col>
              <MultiVideoUpload name="videos" label="Video" />
            </Col>
            <Col>
              <TextInput
                inputType="textarea"
                autoSize={{ minRows: 11, maxRows: 11 }}
                label={i18n.t("productDetail.howToBuy")}
                name="howToBuy"
              />
            </Col>
            <Row justify="space-between" className="button-wrapper">
              <Col span={12}>
                <Button
                  loading={loading}
                  onClick={handleSubmit}
                  type="primary"
                  size="large"
                >
                  {isCreating ? "Đăng bài" : "Cập nhật"}
                </Button>
              </Col>
              {!isCreating && (
                <Col span={12}>
                  <Button
                    onClick={onDelete}
                    className="cancel-button"
                    size="large"
                  >
                    Xóa
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ProductCreatForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  isCreating: PropTypes.bool,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  userRole: PropTypes.string,
  valueTypeList: PropTypes.array,
};
export default ProductCreatForm;
