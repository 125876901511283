import { put, takeLeading, call } from "redux-saga/effects";
import request from "utils/api";
import Helper from "utils/helpers";
import _pick from "lodash/pick";
import _set from "lodash/set";
import { safe } from "providers/GeneralProvider/saga";
import { ROLES } from "utils/constants";
import {
  loginRequest,
  loginSuccess,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  logoutRequest,
  stopLoading,
  getUserDetailRequest,
  getUserDetailSuccess,
  showOtpScreen,
  setConfirmation,
  superAdminLoginRequest,
  superAdminLoginSuccess,
  resendOtpRequest,
  resendOtpSuccess,
  loginFacebookRequest,
  loginGoogleRequest,
  loginAppleRequest,
  loginAppleSuccess,
  loginFacebookSuccess,
  loginGoogleSuccess,
  getResourceSuccess,
  getResourceRequest,
  getUserDetailbyIdRequest,
  getUserDetailbyIdSuccess,
} from "providers/AuthProvider/slice";
import firebase from "utils/firebase";
import SocketClient from "utils/socket";
import { getCountNotSeenNotificationRequest } from "../NotificationProvider/slice";

function* getResources() {
  const { data } = yield call(request, "/api/resources", undefined, {
    method: "GET",
  });
  yield put(getResourceSuccess(data));
}
function* handleLogin(action) {
  const params = _pick(action.payload, [
    "email",
    "password",
    "phone",
    "countryCode",
    "type",
  ]);
  if (params.type === "byPhone") {
    const formatCountrycode = params.countryCode.split("-");
    _set(params, "email", "");
    _set(params, "countryCode", `+${formatCountrycode[1]}`);
  } else {
    _set(params, "phone", "");
    _set(params, "countryCode", "");
  }
  const result = yield call(request, "api/auth/login", params);
  if (!result.token) {
    return yield put(stopLoading());
  }
  if (result.userInfo.role === "super_admin") {
    if (!window.appVerifier) {
      window.appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
    }
    const confirmation = yield call(
      [firebase.auth(), firebase.auth().signInWithPhoneNumber],
      Helper.formatPhone(result.userInfo.phone, result.userInfo.countryCode),
      window.appVerifier
    );
    yield put(setConfirmation(confirmation));
    return yield put(showOtpScreen(true));
  }

  yield put(loginSuccess(result));
  yield put(getCurrentUserSuccess(result.userInfo));
  Helper.storeAuthToken(result.token);
  SocketClient.connect();
  window.navigate("/home", { replace: true });
  return yield put(getCountNotSeenNotificationRequest());
}

export function* handleLogout() {
  yield call(request, "/api/auth/logout");
  Helper.removeAuthToken();
  window.location = "/login";
}

export function* handleGetCurrentUser() {
  if (!Helper.getAuthToken()) {
    yield put(stopLoading());
    return;
  }
  const { data } = yield call(
    request,
    "api/user/getUserInfo",
    {},
    { method: "GET" }
  );
  if (
    ![ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.MANAGER, ROLES.USER].includes(
      data.role
    )
  ) {
    Helper.removeAuthToken();
    window.location = "/login";
  }
  yield put(getCurrentUserSuccess(data));
}

export function* handleGetUserDetail(action) {
  const { payload } = action;
  const { idUser } = payload;
  const { data } = yield call(
    request,
    "api/admin/userManager/getUserDetail",
    { idUser },
    { method: "GET" }
  );
  yield put(getUserDetailSuccess(data));
}
export function* handleGetUserDetailbyId(action) {
  const { payload } = action;
  const { idUser } = payload;
  const { data } = yield call(
    request,
    "api/admin/userManager/getUserDetail",
    { idUser },
    { method: "GET" }
  );
  yield put(getUserDetailbyIdSuccess(data));
}

export function* handleSuperAdminLogin(action) {
  const { payload } = action;
  const params = _pick(action.payload, [
    "phoneOrEmail",
    "password",
    "accessToken",
  ]);
  if (payload.type === "byPhone") {
    _set(params, "phoneOrEmail", payload.phone);
  } else {
    _set(params, "phoneOrEmail", payload.email);
  }
  const result = yield call(request, "api/auth/login-for-super-admin", params, {
    method: "POST",
  });

  yield put(superAdminLoginSuccess(result));
  yield put(getCurrentUserSuccess(result.userInfo));
  Helper.storeAuthToken(result.token);
  window.navigate("/home", { replace: true });
  SocketClient.connect();
}

export function* resendOtp() {
  const confirmation = yield call(
    [firebase.auth(), firebase.auth().signInWithPhoneNumber],
    "+840908444782",
    window.appVerifier
  );
  yield put(setConfirmation(confirmation));
  yield put(resendOtpSuccess());
  Helper.toastr("Đã gửi OTP");
}

export function* handleLoginFacebook(action) {
  const { accessToken } = action.payload;
  const facebookCredential =
    firebase.auth.FacebookAuthProvider.credential(accessToken);
  yield call(
    [firebase.auth(), firebase.auth().signInWithCredential],
    facebookCredential
  );

  const { currentUser } = firebase.auth();
  if (currentUser) {
    const result = yield call(
      [currentUser, currentUser.getIdTokenResult],
      true
    );
    const res = yield call(request, "api/auth/facebook", {
      accessToken: result.token,
    });
    Helper.storeAuthToken(res.token);
    const { data } = yield call(
      request,
      "api/user/getUserInfo",
      {},
      { method: "GET" }
    );
    if (
      ![ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.MANAGER, ROLES.USER].includes(
        data.role
      )
    ) {
      Helper.removeAuthToken();
      window.location = "/login";
    }
    yield put(getCurrentUserSuccess(data));
    yield put(loginFacebookSuccess());
    yield call([firebase.auth(), firebase.auth().signOut]);
  }
}

export function* handleLoginGoogle(action) {
  const { tokenId } = action.payload;
  const googleCredential = firebase.auth.GoogleAuthProvider.credential(tokenId);
  yield call(
    [firebase.auth(), firebase.auth().signInWithCredential],
    googleCredential
  );

  const { currentUser } = firebase.auth();
  if (currentUser) {
    const result = yield call(
      [currentUser, currentUser.getIdTokenResult],
      true
    );
    const res = yield call(request, "api/auth/google", {
      accessToken: result.token,
    });
    Helper.storeAuthToken(res.token);
    const { data } = yield call(
      request,
      "api/user/getUserInfo",
      {},
      { method: "GET" }
    );
    if (
      ![ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.MANAGER, ROLES.USER].includes(
        data.role
      )
    ) {
      Helper.removeAuthToken();
      window.location = "/login";
    }
    yield put(getCurrentUserSuccess(data));
    yield put(loginGoogleSuccess());
    yield call([firebase.auth(), firebase.auth().signOut]);
  }
}

export function* handleLoginApple(action) {
  const provider = new firebase.auth.OAuthProvider("apple.com");
  const authCredential = provider.credential({
    idToken: action.payload.id_token,
    rawNonce: "nonce",
  });
  yield call(
    [firebase.auth(), firebase.auth().signInWithCredential],
    authCredential
  );
  const { currentUser } = firebase.auth();
  if (currentUser) {
    const result = yield call(
      [currentUser, currentUser.getIdTokenResult],
      true
    );
    const res = yield call(request, "api/auth/apple", {
      accessToken: result.token,
    });

    Helper.storeAuthToken(res.token);
    const { data } = yield call(
      request,
      "api/user/getUserInfo",
      {},
      { method: "GET" }
    );
    if (
      ![ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.MANAGER, ROLES.USER].includes(
        data.role
      )
    ) {
      Helper.removeAuthToken();
      window.location = "/login";
    }
    yield put(getCurrentUserSuccess(data));
    yield put(loginAppleSuccess());
    yield call([firebase.auth(), firebase.auth().signOut]);
  }
}

function* onError() {
  yield put(stopLoading());
}

function* onGetCurrentUserError() {
  yield put(stopLoading());
  Helper.removeAuthToken();
}

export default function* watchAuth() {
  yield takeLeading(getResourceRequest.type, safe(getResources, onError));
  yield takeLeading(loginRequest.type, safe(handleLogin, onError));
  yield takeLeading(logoutRequest.type, safe(handleLogout, onError));
  yield takeLeading(
    getCurrentUserRequest.type,
    safe(handleGetCurrentUser, onGetCurrentUserError)
  );
  yield takeLeading(
    getUserDetailRequest.type,
    safe(handleGetUserDetail, onError)
  );
  yield takeLeading(
    getUserDetailbyIdRequest.type,
    safe(handleGetUserDetailbyId, onError)
  );
  yield takeLeading(
    superAdminLoginRequest.type,
    safe(handleSuperAdminLogin, onError)
  );
  yield takeLeading(resendOtpRequest.type, safe(resendOtp, onError));
  yield takeLeading(
    loginFacebookRequest.type,
    safe(handleLoginFacebook, onError)
  );
  yield takeLeading(loginGoogleRequest.type, safe(handleLoginGoogle, onError));
  yield takeLeading(loginAppleRequest.type, safe(handleLoginApple, onError));
}
