import { memo } from "react";
import { SearchOutlined } from "@ant-design/icons";

import SearchBarComponent from "components/SearchBar";
import { TextInput, Filter } from "components/common/Form";
// import i18n from 'i18n';
import { ARTICLE_ROLES } from "utils/constants";

const SearchBar = () => (
  <SearchBarComponent filterFields={["keyword", "roles"]} isOnChangeSearch>
    <>
      <TextInput
        styles={{ marginRight: 8 }}
        prefix={<SearchOutlined />}
        name="keyword"
        allowClear={false}
        // placeholder={i18n.t('listUserScreen.searchName')}
      />
      <Filter
        name="roles"
        options={Object.values(ARTICLE_ROLES)}
        popConfirmClassName="gb-role-popconfirm"
      />
    </>
  </SearchBarComponent>
);

export default memo(SearchBar);
