import { useEffect, useState } from "react";
import {
  Row,
  Typography,
  Button,
  Dropdown,
  Menu,
  Select,
  DatePicker,
  Input,
} from "antd";
import _get from "lodash/get";
import { MoneyManagementFonehouseColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import MoneyManagementRecord from "components/MoneyManagementRecord";
import {
  getAllMoneyManagementRequest,
  deleteMoneyManagementRequest,
  setMoneyManagementModal,
  getBalanceRequest,
  updateBalanceRequest,
  getOldBalanceRequest,
  deleteMoneyManagementDataRequest,
  deleteAllInfoMoneyRequest,
} from "providers/FonehouseMoneyManagement/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import moment from "moment";
import Helper from "utils/helpers";
import { ROLES } from "utils/constants";

const { Option } = Select;
const { Search } = Input;
const MoneyManagement = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState("collect");
  const [dateFrom, setDateFrom] = useState(moment());
  const [dateTo, setDateTo] = useState(moment());
  const MoneyManagementList = useSelector((state) =>
    _get(state, "moneyManagementFonehouse.docs")
  );
  const MoneyManagementTotal = useSelector((state) =>
    _get(state, "moneyManagementFonehouse.totalDocs")
  );
  const balance = useSelector((state) =>
    _get(state, "moneyManagementFonehouse.balance")
  );
  const oldBalance = useSelector((state) =>
    _get(state, "moneyManagementFonehouse.oldBalance")
  );
  const isLoading = useSelector((state) =>
    _get(state, "moneyManagementFonehouse.isLoading")
  );
  const role = useSelector((state) => _get(state, "auth.currentUser.role", ""));
  const [searchObject, setSearch] = useQuery(getAllMoneyManagementRequest, {
    params: ["from", "to"],
  });

  const handleDeleteAllMoneyManageInfo = () => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          title: "Xoá toàn bộ dữ liệu thu chi",
          content: "Bạn có chắc chắn muốn xoá toàn bộ dữ liệu?",
          cancelText: "Huỷ",
          onOk: () => {
            dispatch(
              deleteAllInfoMoneyRequest({
                callback: () => {
                  dispatch(
                    getAllMoneyManagementRequest({
                      from: moment(dateFrom).valueOf(),
                      to: moment(dateTo).valueOf(),
                      page: 1,
                      limit: 10,
                    })
                  );
                },
              })
            );
          },
        },
      })
    );
  };

  const handleOpenCreateMoneyManagementRecord = () => {
    dispatch(
      setMoneyManagementModal({
        visible: true,
        isCreate: true,
      })
    );
  };

  const handleChangeSelectType = (_type) => {
    setType(_type);
  };
  const handleOnchangeDateFrom = (_dateFrom) => {
    setDateFrom(_dateFrom);
  };
  const handleOnchangeDateTo = (_dateTo) => {
    setDateTo(_dateTo);
  };
  const handleRemoveData = () => {
    dispatch(
      deleteMoneyManagementDataRequest({
        from: moment(dateFrom).valueOf(),
        to: moment(dateTo).valueOf(),
      })
    );
  };
  useEffect(() => {
    dispatch(getOldBalanceRequest());
    dispatch(getBalanceRequest());
    setSearch({
      ...searchObject,
      from: moment(dateFrom).valueOf(),
      to: moment(dateTo).valueOf(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);
  const menu = (data) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          dispatch(
            setMoneyManagementModal({
              visible: true,
              isCreate: false,
              id: data._id,
              dateFrom,
              dateTo,
            })
          );
        }}
      >
        {i18n.t("moneyManagementFonehouse.edit")}
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          dispatch(deleteMoneyManagementRequest({ id: data._id })).then(() => {
            dispatch(getBalanceRequest());
            dispatch(getOldBalanceRequest());
            dispatch(
              getAllMoneyManagementRequest({
                from: moment(dateFrom).valueOf(),
                to: moment(dateTo).valueOf(),
                page: 1,
                limit: 10,
              })
            );
          })
        }
      >
        {i18n.t("moneyManagementFonehouse.remove")}
      </Menu.Item>
    </Menu>
  );

  const renderFilterMoneyManagement = () => (
    <Row
      wrap={false}
      style={{ marginRight: 10 }}
      justify="center"
      align="middle"
    >
      <Select
        defaultValue="collect"
        style={{ width: 150 }}
        onChange={handleChangeSelectType}
      >
        <Option value="collect">Lọc</Option>
        <Option value="delete">Xoá</Option>
      </Select>
      <div style={{ marginRight: 10, marginLeft: 10 }}> Từ </div>
      <DatePicker
        allowClear={false}
        defaultValue={dateFrom}
        placeholder="Chọn ngày"
        onChange={handleOnchangeDateFrom}
      />
      <div style={{ marginRight: 10, marginLeft: 10 }}> Đến </div>
      <DatePicker
        allowClear={false}
        defaultValue={dateTo}
        placeholder="Chọn ngày"
        onChange={handleOnchangeDateTo}
      />
      <Button
        size="middle"
        type="primary"
        onClick={handleOpenCreateMoneyManagementRecord}
        style={{ marginLeft: 10 }}
      >
        TẠO MỚI
      </Button>
      {role === ROLES.SUPER_ADMIN && (
        <Button
          onClick={handleDeleteAllMoneyManageInfo}
          style={{ marginLeft: 10, backgroundColor: "red" }}
          type="primary"
          size="middle"
        >
          XOÁ
        </Button>
      )}
      {type === "delete" && (
        <Button
          onClick={handleRemoveData}
          style={{ marginLeft: 10 }}
          type="primary"
        >
          XOÁ
        </Button>
      )}
    </Row>
  );

  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const handleUpdateTotalCostRequest = (value) => {
    const totalCost = parseFloat(value);
    if (totalCost) {
      dispatch(updateBalanceRequest({ value: totalCost })).then(() =>
        dispatch(getBalanceRequest())
      );
    } else {
      Helper.toastr(
        "Bạn đã nhập sai định dạng",
        "Xin nhập định dạng số",
        "error"
      );
    }
  };

  const columns = [
    MoneyManagementFonehouseColumn.createdAt,
    MoneyManagementFonehouseColumn.description,
    MoneyManagementFonehouseColumn.collect,
    MoneyManagementFonehouseColumn.spend,
    MoneyManagementFonehouseColumn.cost,
    MoneyManagementFonehouseColumn.balance,
    MoneyManagementFonehouseColumn.payment,
    {
      render: (record) => actionButtons(record),
    },
  ];
  return (
    <div className="moneyManagement-fonehouse-list-wrapper">
      <MoneyManagementRecord
        setMoneyManagementModal={setMoneyManagementModal}
      />
      <Row
        className="moneyManagement-fonehouse-list-header"
        justify="space-between"
      >
        <Typography.Title level={4}>
          {i18n.t("moneyManagementFonehouse.listTitle")}
        </Typography.Title>
        <Row>{renderFilterMoneyManagement()}</Row>
      </Row>
      <div className="content-container">
        <Search
          style={{ width: 300, marginTop: 10, marginBottom: 10 }}
          onSearch={handleUpdateTotalCostRequest}
          placeholder="Tổng tồn"
          enterButton={"Cập nhật"}
        />
        <Row style={{ marginTop: 10, marginBottom: 10 }} align="middle">
          <div style={{ fontSize: 18 }}>
            <b>Tổng tồn:</b> {balance} JPY
          </div>
          <div style={{ marginLeft: 20, fontSize: 18 }}>
            <b>Dư đầu:</b> {oldBalance} JPY
          </div>
        </Row>
        <Table
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={MoneyManagementList}
          rowKey={(data) => data._id}
          total={MoneyManagementTotal}
        />
      </div>
    </div>
  );
};

export default MoneyManagement;
