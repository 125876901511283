import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { Modal } from "antd";
import { setPopup } from "providers/GeneralProvider/slice";
import "./styles.less";

const ConfirmModal = () => {
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.general.modal);
  const isOpen = _get(modalData, "isOpen", false);
  const modalProps = _get(modalData, "data", {});
  const {
    title,
    okText,
    cancelText,
    okType,
    okButtonProps,
    onOk,
    onCancel,
    closeAfterConfirm = true,
    content,
    ...props
  } = modalProps;
  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
    dispatch(setPopup({ isOpen: false }));
  };

  const handleConfirm = () => {
    if (typeof onOk === "function") {
      onOk();
    }
    if (closeAfterConfirm) {
      // Close popup first
      dispatch(setPopup({ isOpen: false }));
    }
  };

  return (
    <Modal
      title={title}
      visible={isOpen}
      width={660}
      afterClose={() => dispatch(setPopup({ data: {} }))} // Remove popup data when popup is close
      onCancel={handleCancel}
      onOk={handleConfirm}
      cancelText={cancelText}
      okText={okText}
      okType={okType}
      okButtonProps={{ size: "large", ...okButtonProps }}
      cancelButtonProps={{ size: "large", className: "cancel-button" }}
      centered
      className="general-confirm-modal"
      {...props}
    >
      {content && content}
    </Modal>
  );
};

export default ConfirmModal;
