import PropTypes from "prop-types";
import { Menu } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import i18n from "i18n";

const AccountMenu = ({ logOut }) => (
  <Menu className="user-setting-menu">
    <Menu.Item icon={<PoweroffOutlined />} onClick={() => logOut()}>
      {i18n.t("menuAccount.logOut")}
    </Menu.Item>
  </Menu>
);

AccountMenu.propTypes = {
  viewProfile: PropTypes.func,
  logOut: PropTypes.func.isRequired,
};
export default AccountMenu;
