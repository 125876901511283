import { Avatar } from "antd";

import "./styles.less";

const InformationBox = () => (
  <div className="information-box-wrapper">
    <Avatar size={40} src="/logovietgrp.png" />
    <div className="information-text">
      <div className="upper-text">Vie-Go CMS</div>
      <div className="sub-text">Vie-Go copyright 2021</div>
    </div>
  </div>
);

export default InformationBox;
