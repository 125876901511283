import { useEffect, useState } from "react";
import { LeftArrowIcon } from "assets/images";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { Button, Modal, Table, Input } from "antd";
// import { Table } from 'components/common';
import { UserColumn } from "components/Columns";
import {
  getAllUserRequest,
  setSelectedIds,
  resetSelectedRowKey,
} from "providers/AdminProvider/slice";
import { setChangeStoreOnwerModal } from "providers/GeneralProvider/slice";

import "./styles.less";
import { useDebounce } from "hooks";

const ChangeStoreOnwerModal = ({
  category,
  isOpen,
  handleChangeStoreOnwer,
}) => {
  const dispatch = useDispatch();
  const agentList = useSelector((state) => _get(state, "admin.docs"));
  const agentTotal = useSelector((state) => _get(state, "admin.totalDocs"));
  const isLoading = useSelector((state) => _get(state, "admin.isLoading"));
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const debouncedSearchKeyword = useDebounce(searchKeyword, 500);

  useEffect(() => {
    dispatch(
      getAllUserRequest({ page, limit: 10, roles: [category], sort: -1 })
    );
  }, [dispatch, page, category]);

  useEffect(() => {
    dispatch(
      getAllUserRequest({
        page,
        limit: 10,
        roles: [category],
        sort: -1,
        keyword: debouncedSearchKeyword,
      })
    );
  }, [category, debouncedSearchKeyword, dispatch, page]);

  const selectedRows = useSelector((state) =>
    _get(state, "admin.selectedRows", [])
  );

  const handleChangeStoreOnwerByRole = () => {
    handleChangeStoreOnwer({
      type: category,
      idNewOnwer: _get(selectedRows[0], "_id"),
    });
  };

  const handleCancel = () => {
    dispatch(setChangeStoreOnwerModal({ isOpen: false }));
    dispatch(resetSelectedRowKey());
  };

  const rowSelection = {
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedIds({ record, selected, selectedRows: _selectedRows })
      );
    },
    hideSelectAll: true,
    columnWidth: 50,
    getCheckboxProps: (record) => ({
      disabled: selectedRows.length > 0 && selectedRows[0]._id !== record._id, // Column configuration not to be checked
    }),
  };

  const columns = [
    {
      ...UserColumn.objectId,
      width: 220,
    },
    UserColumn.phone,
    {
      ...UserColumn.displayName,
      sorter: false,
    },
    {
      ...UserColumn.email,
      sorter: false,
    },
    UserColumn.permission,
    {
      ...UserColumn.auth,
      sorter: false,
      width: 100,
    },
  ];

  const handleOnChangeTable = (pagination) => {
    setPage(pagination.current);
  };

  return (
    <Modal
      visible={isOpen}
      footer={null}
      closable={false}
      centered
      className="change-store-onwer-modal-wrapper"
    >
      <div className="change-store-onwer-modal-container">
        <div className="change-store-onwer-modal-header">
          <div className="header-title-container">
            <img
              onClick={handleCancel}
              className="icon-close"
              src={LeftArrowIcon}
              alt=""
            />
            <div className="header-content">Thay đổi quản lý cửa hàng</div>
          </div>
          <Button
            disabled={_isEmpty(selectedRows)}
            onClick={handleChangeStoreOnwerByRole}
            size="small"
            className="confirm-btn"
            type="primary"
          >
            Xác nhận
          </Button>
        </div>
        <div className="change-store-onwer-modal-body">
          <Input
            className="searchbar-container"
            size="large"
            onChange={(e) => setSearchKeyword(e.target.value)}
            prefix={<SearchOutlined />}
            allowClear={false}
          />
          <Table
            rowSelection={rowSelection}
            scroll={{ y: 400 }}
            loading={isLoading}
            bordered
            columns={columns}
            dataSource={agentList}
            rowKey={(data) => data._id}
            total={agentTotal}
            onChange={handleOnChangeTable}
            pagination={{
              pageSize: 10,
              total: agentTotal,
              showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
              current: Number(page),
              showSizeChanger: false,
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
ChangeStoreOnwerModal.propTypes = {
  category: PropTypes.string,
  isOpen: PropTypes.bool,
  handleChangeStoreOnwer: PropTypes.func,
};

export default ChangeStoreOnwerModal;
