import { useMemo } from "react";
import { Col, Row, Typography, Button } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import ProductCreatForm from "components/Product/CreateForm";
import i18n from "i18n";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "components/BackButton";
import {
  createProductRequest,
  setCustomizeProductTypeModal,
} from "providers/ProductProvider/slice";
import {
  DEFAULT_CONTACT,
  DEFAULT_ADDRESS,
  GUIDE,
  PROMOTION_TYPE,
} from "utils/constants";
import CustomizeModal from "../CustomizeModal";
import "./styles.less";

const ProductCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const productTypes = useSelector((state) =>
    _get(state, "product.productTypes", [])
  );
  const valueTypeList = useMemo(
    () => productTypes.map((element) => element._id),
    [productTypes]
  );
  const isCreating = useSelector((state) => _get(state, "product.isCreating"));
  const userRole = useSelector((state) => _get(state, "auth.currentUser.role"));
  const handleCreateProduct = (values) => {
    let ram = "";
    let os = "";
    if (!_isEmpty(values.ram)) {
      ram = `${values.ram}`.concat(values.ramType);
    }
    if (!_isEmpty(values.softwareVersion)) {
      os = values.os;
    }
    dispatch(createProductRequest({ ...values, ram, os })).then(() =>
      navigate(-1)
    );
  };

  const handleOpenCustomizeProductTypeModal = () => {
    dispatch(
      setCustomizeProductTypeModal({
        visible: true,
        data: { productTypes },
      })
    );
  };

  const initialValues = {
    name: "",
    state: "",
    imei: "",
    softwareVersion: "",
    battery: "",
    entryDate: null,
    accessories: "",
    guarantee: "12",
    address: DEFAULT_ADDRESS,
    contact: DEFAULT_CONTACT,
    type: "",
    currency: "",
    percentagePromotion: 0,
    promotion: 0,
    price: "",
    inputPrice: "",
    description: "",
    howToBuy: GUIDE,
    images: [],
    ram: "",
    amount: "",
    manufacture: "",
    os: "",
    discountType: PROMOTION_TYPE.PERCENT,
    line: "",
    ramType: "",
    videos: [],
    tag: "",
    promotionStartDate: null,
    promotionExpirationDate: null,
  };

  return (
    <div className="products-create-page">
      <CustomizeModal />
      <Row className="products-create-header" justify="space-between">
        <Col className="back-btn">
          <BackButton />
          <Typography.Title level={4}>
            {i18n.t("productDetail.createProduct")}
          </Typography.Title>
        </Col>
        <Col>
          <Button onClick={handleOpenCustomizeProductTypeModal} type="primary">
            TÙY CHỈNH
          </Button>
        </Col>
      </Row>
      <div className="form-section">
        <Row justify="center">
          <Col span={20} className="form-wrapper">
            <ProductCreatForm
              userRole={userRole}
              onSubmit={handleCreateProduct}
              loading={isCreating}
              isCreating
              initialValues={initialValues}
              valueTypeList={valueTypeList}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProductCreate;
