import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import { TextInput } from "components/common";
import { Button } from "antd";
import i18n from "i18n";
import { simCustomField } from "./validate";

const AddingCustomFieldModal = ({
  initialValues,
  onSubmit,
  addingCustomFieldRef,
}) => (
  <Formik
    innerRef={addingCustomFieldRef}
    validateOnMount
    initialValues={initialValues}
    validationSchema={simCustomField}
    onSubmit={onSubmit}
  >
    {({ handleSubmit }) => (
      <Form>
        <TextInput label="Mã đơn hàng" name="customOrderId" />
        <TextInput label="Số điện thoại" name="phone" />

        <Button
          className="submit-btn"
          htmlType="submit"
          loading={false}
          type="primary"
          onClick={(value) => {
            handleSubmit(value);
          }}
        >
          {i18n.t("actionButton.update")}
        </Button>
      </Form>
    )}
  </Formik>
);
AddingCustomFieldModal.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};
export default AddingCustomFieldModal;
