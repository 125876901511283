import PropTypes from "prop-types";
import _isUndefined from "lodash/isUndefined";

import "./styles.less";

const statusText = {
  [false]: "Tất cả",
  [true]: "Của tôi",
  pin: "Đã Ghim",
  "": "Tất cả",
  hair: "Tóc",
  spa: "Spa",
  supportRequest: "Hỗ trợ",
  errorReport: "Báo lỗi",
  feedback: "Góp ý",
  report: "Báo cáo",
  STORE_DOES_NOT_EXIST: "CH Không tồn tại",
  WRONG_ADDRESS: "Địa chỉ",
  WRONG_PHONE: "Số điện thoại",
  REQUEST_TO_BECOME_OWNER: "Quản lý cửa hàng",
  INVALID_PROMOTION: "Giảm giá không chính xác",
  CAR: "Xe",
  OLD_STUFF: "Đồ cũ",
  karaoke: "Karaoke",
  game: "Game",
  club: "Club",
  other: "Khác",
  OTHER: "Khác",
  SIM: "Danh sách SIM",
  POST: "Bài đăng",
  SIM_DELETED: "SIM đã xoá",
  DELETED: "Bài đăng Đã xoá",
  REQUESTED: "Danh sách đăng ký SIM",
  INTERNET: "Danh sách đăng ký Internet",
  WIFI: "Danh sách đăng ký Wifi",
  CONFIRM: "Đã xác minh",
  WAITING: "Đang chờ duyệt",
  1: "1 sao",
  2: "2 sao",
  3: "3 sao",
  4: "4 sao",
  5: "5 sao",
  POINT_STORE_MANAGE: "Quản lý điểm cửa hàng",
  POINT_PERSONAL_MANAGE: "Quản lý điểm cá nhân",
  LIQUID_VIEGO: "Thanh khoản với VieGo",
  SIM_DATA_SOFT_BANK: "SIM Data SoftBank",
};

const TabHeader = ({ status, headerTitle, unreadNumbers }) => (
  <div className="tab-header-wrapper">
    <div>{headerTitle ? headerTitle[status] : statusText[status]}</div>
    {!_isUndefined(unreadNumbers) && unreadNumbers > 0 && (
      <div className="unread-number">{unreadNumbers}</div>
    )}
  </div>
);

TabHeader.propTypes = {
  status: PropTypes.string.isRequired,
  total: PropTypes.number,
  headerTitle: PropTypes.number,
  unreadNumbers: PropTypes.number,
};
export default TabHeader;
