import { memo } from "react";
import { SearchOutlined } from "@ant-design/icons";
import SearchBarComponent from "components/SearchBar";
import { TextInput, Filter, Select } from "components/common/Form";
import { SUPER_MARKET_LIST } from "utils/constants";

const selectNewsOption = [
  { value: "", label: "Trạng thái" },
  { value: "1", label: "Mở cửa" },
  { value: "0", label: "Đóng cửa" },
];

const SearchBar = () => (
  <SearchBarComponent
    filterFields={["keyword", "status", "activeStatus"]}
    isOnChangeSearch
  >
    <>
      <TextInput
        size="middle"
        prefix={<SearchOutlined />}
        name="keyword"
        allowClear={false}
      />
      <div className="filter-container">
        <Select name="activeStatus" required options={selectNewsOption} />
        <Filter
          name="status"
          options={Object.values(SUPER_MARKET_LIST)}
          popConfirmClassName="gb-role-popconfirm"
        />
      </div>
    </>
  </SearchBarComponent>
);

export default memo(SearchBar);
