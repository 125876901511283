import PropTypes from "prop-types";
import { Tooltip } from "antd";

export const SummaryTooltip = (props) => {
  const { component, children, placement, widthTooltipInner = 800 } = props;
  return (
    <Tooltip
      title={component}
      color="white"
      placement={placement}
      overlayInnerStyle={{ width: widthTooltipInner }}
    >
      {children}
    </Tooltip>
  );
};

SummaryTooltip.propTypes = {
  component: PropTypes.object,
  children: PropTypes.any,
  placement: PropTypes.string,
  widthTooltipInner: PropTypes.number,
};

export default SummaryTooltip;
