import { createSlice } from "@reduxjs/toolkit";
import _get from "lodash/get";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    selectedRowKeys: [],
    selectedRows: [],
    isCreating: false,
    detail: {},
    unreadVisa: 0,
  },
  reducers: {
    getAllVisaRequest(state) {
      return { ...state, isLoading: true };
    },
    getAllVisaSuccess(state, action) {
      const { payload = {} } = action;
      const { docs, totalDocs } = payload;
      return {
        ...state,
        isLoading: false,
        docs: docs.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs,
      };
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isCreating: false };
    },
    setSelectedVisaIds(state, action) {
      const { payload = {} } = action;
      const { selected, selectedRows, changeRows, record } = payload;

      // Select & deselect 1
      if (record) {
        if (selected) {
          state.selectedRows = state.selectedRows.concat(record);
          state.selectedRowKeys = state.selectedRowKeys.concat(record._id);
        } else {
          state.selectedRows = state.selectedRows.filter(
            (item) => item._id !== record._id
          );
          state.selectedRowKeys = state.selectedRowKeys.filter(
            (item) => item !== record._id
          );
        }
      } else if (selected) {
        const selectedRowKeys = selectedRows
          .map((i) => _get(i, "_id"))
          .filter((i) => i);
        const filterdSelectedRows = selectedRows.filter((i) => i);
        state.selectedRows = state.selectedRows.concat(filterdSelectedRows);
        state.selectedRowKeys = state.selectedRowKeys.concat(selectedRowKeys);
      } else {
        const changeRowKeys = changeRows.map((i) => i._id).filter((i) => i);
        state.selectedRows = state.selectedRows.filter(
          (item) => !changeRowKeys.includes(_get(item, "_id"))
        );
        state.selectedRowKeys = state.selectedRowKeys.filter(
          (item) => !changeRowKeys.includes(item)
        );
      }
      return state;
    },
    setSelectedId() {},
    updateStatusVisaRequest: {
      reducer: (state) => ({ ...state, isLoading: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },

    updateStatusVisaSuccess: {
      reducer: (state) => ({
        ...state,
        isLoading: false,
        selectedRowKeys: [],
        selectedRows: [],
      }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    createVisaRequest: {
      reducer: (state) => ({ ...state, isCreating: true }),
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    createVisaSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    updateVisaRequest: {
      reducer: (state) => {
        state.isCreating = true;
        return state;
      },
      prepare: (payload) => ({ payload, meta: { thunk: true } }),
    },
    updateVisaSuccess: {
      reducer: (state) => ({ ...state, isCreating: false }),
      prepare: (payload, meta) => ({ payload, meta }),
    },
    getVisaDetailRequest(state) {
      return { ...state, isLoading: true };
    },
    getVisaDetailSuccess(state, action) {
      return { ...state, isLoading: false, detail: action.payload };
    },
    getCountNotSeenRequest(state) {
      return { ...state, isLoading: true };
    },
    getCountNotSeenSuccess(state, action) {
      return { ...state, isLoading: false, unreadVisa: action.payload };
    },
  },
});

export const {
  getAllVisaRequest,
  getAllVisaSuccess,
  stopLoading,
  setSelectedVisaIds,
  updateStatusVisaRequest,
  updateStatusVisaSuccess,
  createVisaRequest,
  createVisaSuccess,
  getVisaDetailRequest,
  getVisaDetailSuccess,
  updateVisaRequest,
  updateVisaSuccess,
  getCountNotSeenRequest,
  getCountNotSeenSuccess,
} = adminSlice.actions;

export default adminSlice.reducer;
