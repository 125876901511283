import { createSlice } from "@reduxjs/toolkit";

const statisticalFonehouseSlice = createSlice({
  name: "statisticalFonehouse",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
    totalCost: 0,
    totalInterest: 0,
    csvData: "",
  },
  reducers: {
    getAllStatisticalFonehouseRequest(state) {
      return { ...state, isLoading: true };
    },
    getAllStatisticalFonehouseSuccess(state, action) {
      const { orderRecords, totalRecord, totalCost, totalInterest } =
        action.payload;
      return {
        ...state,
        isLoading: false,
        docs: orderRecords.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs: totalRecord,
        totalCost,
        totalInterest,
      };
    },
    exportCSVRequest(state) {
      return {
        ...state,
      };
    },
    exportCSVSuccess(state, action) {
      return {
        ...state,
        csvData: action.payload.orderRecords,
      };
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isCreating: false };
    },
  },
});

export const {
  exportCSVRequest,
  exportCSVSuccess,
  getAllStatisticalFonehouseRequest,
  getAllStatisticalFonehouseSuccess,
  stopLoading,
} = statisticalFonehouseSlice.actions;

export default statisticalFonehouseSlice.reducer;
