import { memo } from "react";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import SearchBarComponent from "components/SearchBar";
import { TextInput } from "components/common/Form";

const SearchBar = () => (
  <SearchBarComponent
    filterFields={["keyword", "warrantyExpires", "tag", "status"]}
    isOnChangeSearch
  >
    <>
      <TextInput
        prefix={<SearchOutlined />}
        name="keyword"
        allowClear={false}
      />
    </>
  </SearchBarComponent>
);

SearchBar.propTypes = {
  name: PropTypes.string,
  companyValue: PropTypes.string,
  formattedproductTypes: PropTypes.array,
};
export default memo(SearchBar);
