import { useState } from "react";
import PropTypes from "prop-types";
import { Table, Empty, Row, Button, Col } from "antd";

import { StoreBannerColumn } from "components/Columns";
import i18n from "i18n";
import LoadingIndicator from "components/LoadingIndicator";
import "./styles.less";

const StoreBannerList = ({
  loading = false,
  list = [],
  total = 0,
  handleOpenBannerDetail,
  handleRemoveBanner,
  handleToggleStatus,
}) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const columns = [
    StoreBannerColumn.id,
    StoreBannerColumn.image,
    StoreBannerColumn.url,
    {
      ...StoreBannerColumn.status,
      dataIndex: "isActive",
      render: (text, record) =>
        StoreBannerColumn.status.render(text, record, (checked, e) => {
          e.stopPropagation();
          handleToggleStatus(record);
        }),
    },
    {
      render: (record) => (
        <div className="menu-actions">
          <Button
            onClick={() => handleOpenBannerDetail(record)}
            className="edit-btn"
          >
            Chỉnh sửa
          </Button>
          <Button
            onClick={() => handleRemoveBanner(record)}
            className="remove-btn"
          >
            Xóa
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="store-banner-list-wrapper">
      <Row className="store-banner-list-header" justify="end">
        <Col>
          <Button
            type="primary"
            size="small"
            onClick={() =>
              handleOpenBannerDetail({
                link: "",
                imageUrl: null,
                isActive: true,
              })
            }
          >
            {i18n.t("banner.createBanner")}
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={list}
        columns={columns}
        rowKey="_id"
        scroll={{ x: "max-content" }}
        loading={{
          spinning: loading,
          indicator: <LoadingIndicator />,
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={i18n.t("errors.noData")}
            />
          ),
        }}
        className="banner-list-table"
        pagination={{
          pageSize: limit,
          total,
          showTotal: (totalCount, range) =>
            `${range[0]}-${range[1]} / ${totalCount}`,
          current: Number(page),
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

StoreBannerList.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.array,
  list: PropTypes.array,
  total: PropTypes.number,
  handleOpenBannerDetail: PropTypes.func,
  handleRemoveBanner: PropTypes.func,
};

export default StoreBannerList;
