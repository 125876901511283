import { useRef } from "react";
import { Formik, Form } from "formik";
import i18n from "i18n";
import PropTypes from "prop-types";
import { Row, Col, Button } from "antd";
import { TextInput, Select } from "components/common";
import Helper from "utils/helpers";
import { DISCOUNT_TYPE } from "utils/constants";
import { discountDirectValidate } from "./validate";
import "./styles.less";

const DiscountDirectForm = ({ initialValues, onSubmit, loading }) => {
  const formikRef = useRef(null);
  const renderPromotionInput = (promotionType) => {
    if (promotionType === "percentagePromotion") {
      return (
        <TextInput
          label={i18n.t("billingList.discountPercentValue")}
          name="percentagePromotion"
          required
        />
      );
    }
    return (
      <TextInput
        label={i18n.t("billingList.discountValue")}
        name="promotion"
        required
      />
    );
  };
  return (
    <div className="bill-create-form-wrapper">
      <Formik
        validateOnMount
        initialValues={initialValues}
        enableReinitialize
        validationSchema={discountDirectValidate}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ handleSubmit, values }) => (
          <Form>
            <Row className="form-grid">
              <Col span={12}>
                <Select
                  label={i18n.t("billingList.discount")}
                  name="promotionType"
                  inputType="state"
                  required
                  options={Helper.convertObjectToOptions(DISCOUNT_TYPE)}
                />
              </Col>
              <Col span={12}>{renderPromotionInput(values.promotionType)}</Col>
            </Row>
            <Row justify="space-between" className="button-wrapper">
              <Button
                style={{ marginTop: 20 }}
                loading={loading}
                onClick={handleSubmit}
                type="primary"
                size="large"
              >
                {i18n.t("billingList.discountConfirm")}
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

DiscountDirectForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
export default DiscountDirectForm;
