import { useState } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import "./style.less";
import StatusBlock from "components/StatusBlock";

const ArticlePermissionTooltip = (props) => {
  const { active } = props;
  const [status] = useState(active);
  return (
    <div className="article-permission-tooltip-wrapper">
      <Row align="middle">
        <Col style={{ marginRight: 10 }}>
          <StatusBlock status="approved" />
        </Col>
        <Col className="tootltip-text">
          <div className={status === "approved" ? "bold" : ""}>
            Bài viết đã duyệt
          </div>
        </Col>
      </Row>

      <Row align="middle" style={{ marginTop: 10, marginBottom: 10 }}>
        <Col style={{ marginRight: 10 }}>
          <StatusBlock status="waiting" />
        </Col>
        <Col className="tootltip-text">
          <div className={status === "waiting" ? "bold" : ""}>
            Bài viết đang chờ
          </div>
        </Col>
      </Row>
      <Row align="middle">
        <Col style={{ marginRight: 10 }}>
          <StatusBlock status="refuse" />
        </Col>
        <Col className="tootltip-text">
          <div className={status === "refuse" ? "bold" : ""}>Từ chối</div>
        </Col>
      </Row>
    </div>
  );
};

ArticlePermissionTooltip.propTypes = {
  active: PropTypes.string,
};
export default ArticlePermissionTooltip;
