import { forwardRef, useState, memo, useEffect } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import { Upload, Form, message, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _find from "lodash/find";
import Helper from "utils/helpers";
import ReactPlayer from "react-player";

import "../style.less";

const MultiVideoUpload = forwardRef(
  ({ label, className = "", required, ...props }) => {
    const [field, meta, helper] = useField(props);
    const isError = meta.error && meta.touched;
    const [isLoading, setIsloading] = useState(false);
    const [values, setValues] = useState([]);

    useEffect(() => {
      if (!_isEmpty(values)) {
        helper.setValue([...new Set(field.value.concat(values))]);
        setValues([]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const video = !_isEmpty(field.value)
      ? (field.value || []).map((vid) =>
          typeof vid === "string" ? { uid: vid, url: vid } : vid
        )
      : [];

    const beforeUpload = (file, fileList) => {
      const invalidVideo = _find(
        fileList,
        (_file) => _file.size / (1024 * 1024) > 20
      );
      if (invalidVideo) {
        message.error("Video phải nhỏ hơn hoặc bằng 20MB");
        return false;
      }
      return true;
    };

    const handleRemove = (file) => {
      helper.setValue(field.value.filter((vid) => vid !== file.uid));
    };

    const customRequest = async (option) => {
      const { file } = option;

      setIsloading(true);
      const vid = await Helper.uploadVideo({ file });
      setIsloading(false);
      if (vid) {
        const newValues = values.concat(vid.url);
        setValues(newValues);
      }
    };

    return (
      <div
        className={`input-container multi-upload-video-container ${className}`}
      >
        {label && (
          <div className="label-wrapper">
            <>{label}</>
            {required && <div className="required-mark">*</div>}
          </div>
        )}
        <Form.Item
          validateStatus={isError ? "error" : ""}
          help={
            <div className="helper-wrapper">
              <div className="error-text">{isError && <>{meta.error}</>}</div>
            </div>
          }
        >
          <Upload
            listType="picture-card"
            fileList={video}
            accept=".mp4, .mkv, .mov, .webm"
            showUploadList={{
              removeIcon: (
                <CloseOutlined style={{ color: "#fff", fontSize: "20px" }} />
              ),
            }}
            beforeUpload={beforeUpload}
            onRemove={handleRemove}
            multiple
            customRequest={customRequest}
            itemRender={(node, file) => (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                }}
              >
                <ReactPlayer
                  style={{ marginLeft: "auto" }}
                  controls
                  key={_get(file, "uid")}
                  url={_get(file, "url")}
                  width="100%"
                  height="100%"
                />
                <CloseOutlined
                  onClick={() => handleRemove(file)}
                  style={{
                    fontSize: "20px",
                    position: "absolute",
                    right: 0,
                    backgroundColor: "#26415d",
                    color: "#fff",
                    borderRadius: "50%",
                    padding: 4,
                  }}
                />
              </div>
            )}
          >
            <Button loading={isLoading} type="primary">
              Chọn video
            </Button>
          </Upload>
        </Form.Item>
      </div>
    );
  }
);

MultiVideoUpload.displayName = "MultiVideoUpload";
MultiVideoUpload.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
};
export default memo(MultiVideoUpload);
