import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { useParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { Row, Button, Typography } from "antd";
import LoadingIndicator from "components/LoadingIndicator";
import "./styles.less";
import Helper from "utils/helpers";
import BackButton from "components/BackButton";
import { getGuideFonehouseDetailRequest } from "providers/GuideFonehouseProvider/slice";

const GuideDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const detail = useSelector((state) => _get(state, "guideFonehouse.detail"));
  const isLoading = useSelector((state) =>
    _get(state, "guideFonehouse.isLoading")
  );

  const { idGuild } = useParams();

  useEffect(() => {
    dispatch(getGuideFonehouseDetailRequest({ guideId: idGuild }));
  }, [idGuild, dispatch]);

  const createMarkup = (html) => ({
    __html: DOMPurify.sanitize(html, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling"],
    }),
  });
  if (isLoading) {
    return <LoadingIndicator />;
  }
  return (
    <div className="news-fonehouse-detail-wrapper">
      <Row
        className="news-fonehouse-detail-header"
        justify="space-between"
        align="middle"
      >
        <Row>
          <BackButton />
          <Typography.Title level={4}>Cẩm nang chi tiết</Typography.Title>
        </Row>
        <Button
          size="large"
          type="primary"
          onClick={() =>
            navigate(`/guide-fonehouse/${idGuild}/edit`, {
              state: { canGoBack: true },
            })
          }
        >
          Cập nhật
        </Button>
      </Row>
      <div className="detail-wrapper">
        <div className="detail-title-wrapper">
          <div className="detail-title">{detail.title}</div>
          <div className="detail-time">
            <div className="time-dot" />
            {Helper.getTimeStamp(detail.createdAt)}
          </div>
        </div>
        <div
          className="content-wrapper"
          dangerouslySetInnerHTML={createMarkup(detail.content)}
        ></div>
      </div>
    </div>
  );
};

export default GuideDetail;
