import { Row, Typography, Button, Dropdown, Menu } from "antd";
import _get from "lodash/get";
import { LiquidWithViegoColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import "./styles.less";
import { IconSeeMore } from "assets/images";
import { useQuery } from "hooks";
import {
  getLiquidWithViegoListRequest,
  changeStateOfLiquidWithViegoRequest,
  setSelectedIds,
} from "providers/LiquidWithViegoProvider/slice";
import _isEmpty from "lodash/isEmpty";
import { setPopup } from "providers/GeneralProvider/slice";

const TITLE = "Quản lý thanh khoản VieGo";
const TITLE_DONE = "Hoàn tất";
const TITLE_CANCELLED = "Hủy giao dịch";
const CONFIRM_TITLE = "Xác nhận hoàn tất thanh khoản";
const CANCELLED_TITLE = "Xác nhận hủy giao dịch thanh khoản";
const CANCEL = "Huỷ";
const CONTENT_CONFIRM = "Bạn có muốn hoàn tất thanh khoản?";
const CONFIRM = "Xác nhận";
const CONTENT_CANCELLED = "Bạn có muốn hủy giao dịch thanh khoản?";
const LiquidWithViegoManager = () => {
  const dispatch = useDispatch();
  const totalDocs = useSelector((state) =>
    _get(state, "liquidWithViegoManager.totalDocs")
  );
  const isLoading = useSelector((state) =>
    _get(state, "liquidWithViegoManager.isLoading")
  );
  const liquidWithViegoList = useSelector((state) =>
    _get(state, "liquidWithViegoManager.liquidWithViegoList", [])
  );
  const selectedRowKeys = useSelector((state) =>
    _get(state, "liquidWithViegoManager.selectedRowKeys", [])
  );
  const isDisableActionAll = useSelector((state) =>
    _get(state, "liquidWithViegoManager.isDisableActionAll", false)
  );
  const disableAll = _isEmpty(selectedRowKeys) || isDisableActionAll;
  const [searchObject] = useQuery(getLiquidWithViegoListRequest);

  const handleChangeStatusLiquidWithViego = (status, requestIds) => {
    dispatch(
      changeStateOfLiquidWithViegoRequest({
        requestIds,
        status,
        callback: () =>
          dispatch(getLiquidWithViegoListRequest({ ...searchObject })),
      })
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      dispatch(
        setSelectedIds({
          record,
          selected,
          selectedRows,
        })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedIds({ selected, selectedRows: _selectedRows, changeRows })
      );
    },
  };

  const menu = (data) => (
    <Menu>
      <Menu.Item
        style={{ color: "green", fontWeight: "bold" }}
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: CONFIRM,
                cancelText: CANCEL,
                title: CONFIRM_TITLE,
                content: CONTENT_CONFIRM,
                onOk: () => {
                  handleChangeStatusLiquidWithViego("success", [data._id]);
                },
              },
            })
          )
        }
      >
        {TITLE_DONE}
      </Menu.Item>
      <Menu.Item
        style={{ color: "red", fontWeight: "bold" }}
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: CONFIRM,
                cancelText: CANCEL,
                title: CANCELLED_TITLE,
                content: CONTENT_CANCELLED,
                onOk: () => {
                  handleChangeStatusLiquidWithViego("cancelled", [data._id]);
                },
              },
            })
          )
        }
      >
        {TITLE_CANCELLED}
      </Menu.Item>
    </Menu>
  );
  const actionButtons = (record) => {
    if (record?.transaction?.status) {
      return <div></div>;
    }
    return (
      <Dropdown overlay={() => menu(record)} placement={"bottomCenter"} arrow>
        <Button
          className="see-more-btn"
          type="ghost"
          icon={<img src={IconSeeMore} alt="see-more" />}
        />
      </Dropdown>
    );
  };

  const menuAll = () => (
    <Menu>
      <Menu.Item
        className={disableAll ? "" : "unlock-bussiness-btn"}
        disabled={disableAll}
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: CONFIRM,
                cancelText: CANCEL,
                title: CONFIRM_TITLE,
                content: CONTENT_CONFIRM,
                onOk: () => {
                  handleChangeStatusLiquidWithViego("success", selectedRowKeys);
                },
              },
            })
          )
        }
      >
        {TITLE_DONE}
      </Menu.Item>
      <Menu.Item
        className={disableAll ? "" : "lock-bussiness-btn"}
        disabled={disableAll}
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: CONFIRM,
                cancelText: CANCEL,
                title: CANCELLED_TITLE,
                content: CONTENT_CANCELLED,
                onOk: () => {
                  handleChangeStatusLiquidWithViego(
                    "cancelled",
                    selectedRowKeys
                  );
                },
              },
            })
          )
        }
      >
        {TITLE_CANCELLED}
      </Menu.Item>
    </Menu>
  );

  const columns = [
    LiquidWithViegoColumn.id,
    LiquidWithViegoColumn.createdAt,
    LiquidWithViegoColumn.account,
    LiquidWithViegoColumn.email,
    LiquidWithViegoColumn.phone,
    LiquidWithViegoColumn.bankName,
    LiquidWithViegoColumn.branchName,
    LiquidWithViegoColumn.accountNumber,
    LiquidWithViegoColumn.points,
    LiquidWithViegoColumn.viegoPointToMoney,
    LiquidWithViegoColumn.state,
    {
      title: (
        <Dropdown overlay={menuAll} placement="bottomLeft" arrow>
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  return (
    <div className="register-list-wrapper">
      <Row className="register-list-header" justify="space-between">
        <Typography.Title level={4}>{TITLE}</Typography.Title>
      </Row>
      <div className="content-container">
        <Table
          rowSelection={rowSelection}
          scroll={{ x: 1800 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={liquidWithViegoList}
          rowKey={(data) => data._id}
          total={totalDocs}
        />
      </div>
    </div>
  );
};

export default LiquidWithViegoManager;
