import Tabs from "antd/lib/tabs";
import PropTypes from "prop-types";
import _get from "lodash/get";

import "./styles.less";

const { TabPane } = Tabs;

const CustomTabs = ({ className = "", contents = [], ...rest }) => (
  <Tabs
    className={`${className} custom-tabs`}
    defaultActiveKey={_get(contents[0], "key", "")}
    {...rest}
  >
    {contents.map(({ key, header, content }) => (
      <TabPane key={key} tab={header}>
        {content}
      </TabPane>
    ))}
  </Tabs>
);

CustomTabs.propTypes = {
  className: PropTypes.string,
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ),
};

export default CustomTabs;
