import { put, takeLeading, call } from "redux-saga/effects";
import request from "utils/api";
import Helper from "utils/helpers";
import _pick from "lodash/pick";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import {
  stopLoading,
  setDecentralizationPopup,
  getListReportSuccess,
  getListReportRequest,
  updateReportStatusSuccess,
  updateReportStatusRequest,
  getUnreadReportSuccess,
  setReadReportRecordSuccess,
  getUnreadReportRequest,
  setReadReportRecordRequest,
} from "providers/ReportManagerProvider/slice";

function* getListReport(action) {
  const params = _pick(action.payload, ["page", "limit", "type", "keyword"]);
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  const { data } = yield call(
    request,
    "/api/report-store/admin/reports",
    params,
    { method: "GET" }
  );
  yield put(getListReportSuccess(data));
}

function* updateReportStatus(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["status"]);
  const idPost = _get(action, "payload.idPost");
  yield call(
    request,
    `/api/report-store/admin/report/status/${idPost}`,
    params,
    {
      method: "PUT",
    }
  );
  yield put(updateReportStatusSuccess(meta));
  yield put(setDecentralizationPopup({ isOpen: false }));
  Helper.toastr(`Thông báo`, "Hoàn tất thay đổi", "success");
}

function* getUnreadReport() {
  const { data } = yield call(
    request,
    "/api/report-store/admin/reports/unread",
    undefined,
    { method: "GET" }
  );
  yield put(getUnreadReportSuccess(data));
}

function* setReadReportRecord(action) {
  const { meta } = action;
  const idPost = _get(action, "payload.idPost");
  yield call(
    request,
    `/api/report-store/admin/reports/read/${idPost}`,
    undefined,
    {
      method: "PUT",
    }
  );
  yield put(setReadReportRecordSuccess(meta));
}
function* onError() {
  yield put(stopLoading());
}

export default function* watchAuth() {
  yield takeLeading(getListReportRequest.type, safe(getListReport, onError));
  yield takeLeading(
    updateReportStatusRequest.type,
    safe(updateReportStatus, onError)
  );
  yield takeLeading(
    setReadReportRecordRequest.type,
    safe(setReadReportRecord, onError)
  );
  yield takeLeading(
    getUnreadReportRequest.type,
    safe(getUnreadReport, onError)
  );
}
