// eslint-disable-next-line import/no-anonymous-default-export
export default {
  menu: {
    article: "Article",
    news: "News",
    jobs: "Jobs",
    tutorial: "Tutorial",
    tourist: "Tourist",
    visa: "Visa",
    rent: "Renting House",
    restaurant: "Restaurant",
    entertainment: "Entertainment",
    supermarket: "Supermarket",
    transport: "Transport",
    fonehouse: "Fonehouse",
    products: "Product",
    registerBusiness: "Register Business",
    newsFonehouse: "Fonehouse News",
    list_bill: "Billing List",
    checking_insurance: "Checking Insurance",
    guideFonehouse: "Guide",
    moneyManagementFonehouse: "Money Management",
    historyEditor: "History Edit",
    cosmetic: "Cosmetic",
    accessories: "Accessory List",
  },
  global: {
    agents: "Agents",
    companies: "Companies",
    customers: "Customers",
    stores: "Stores",
    company: "Company",
    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    delete: "Delete",
    action: "Action",
    storeName: "Store name",
    companyName: "Company name",
    storeAddress: "Store address",
    companyAddress: "Company address",
    nearestStation: "Nearest Station",
    store: "Store",
    search: "Search",
    success: "Success",
    noData: "No data",
  },
  homeScreen: {
    dashboard: "Dashboard",
  },
  menuAccount: {
    accountSetting: "Account setting",
    changePassword: "Change password",
    logOut: "Log out",
  },
  listUserScreen: {
    createAgent: "Create agent",
    createCompany: "Create Company",
    searchName: "Search name or email",
    searchCompany: "Search company name",
    customerName: "Customer name (nick name)",
    agentName: "Agent Name (nick name)",
    address: "Address",
    email: "Email",
    status: "Status",
    agentLowerCase: "agents",
    customerLowerCase: "customers",
    companiesLowerCase: "companies",
    active: "Active",
    inactive: "Inactive",
    notAvailable: "This function is not available now",
    resend: "Resend",
  },
  editAgentModal: {
    editAgent: "Edit agent",
    fullName: "Full name",
    phoneticName: "Phonetic name",
    userName: "User name",
    email: "Email",
  },
  editCompanyScreen: {
    editCompany: "Edit company",
    established: "Established",
    prContent: "PR content",
    profilePicture: "Company's profile picture",
    postCode: "Post Code",
    buildingName: "Building name",
    city: "City",
    prefecture: "Prefecture",
    addStore: "ADD STORE",
    selectDate: "Select Date",
  },
  editStorePopup: {
    editStore: "Edit store",
    createStore: "Create store",
    buildingName: "Building name",
    city: "City",
    prefecture: "Prefecture",
    profilePicture: "Store's profile picture",
  },
  alert: {
    verifiedEmailSuccess: "Verified email was sent successfully",
    createAgentSuccess: "Create agent successfully",
    createSuccess: "Create successfully",
    deleteSuccess: "Delete successfully",
    editSuccess: "Edit successfully",
    createCompanySuccess: "Create company success",
    editCompanySuccess: "Edit company success",
    deleteCompanySuccess: "Delete company success",
    addStoreSuccess: "Add store success",
    editStoreSuccess: "Edit store success",
    deleteStoreSuccess: "Delete store success",
    editAgentSuccess: "Edit agent success",
    error: "Error",
    existedEmail: "Account already exists for this username.",
    noNetwork: "No network",
  },
  comfirmDeleteCompany: {
    content: "Are you sure you want to delete this company",
    no: "No",
    yes: "Yes",
  },
  popupEditProfile: {
    fullName: "Full name",
  },
  changePassword: {
    oldPassword: "Old password",
    newPassword: "New password",
    confirmPassword: "Confirm new password",
    passwordChanged: "Password changed",
  },
  agentProfile: {
    information: "Information",
    bio: "Bio",
    basicInfo: "Basic information",
    contactEmail: "Contact e-mail",
    phoneNumber: "Phone number",
    workingExperience: "Working experience",
    work: "Work",
    skills: "Skills",
    areaOfExpertise: "Area of expertise",
    specialtyPrice: "Specially price range",
    specialtyFloor: "Specializing in floor plans",
    specialtyBuilding: "Specialty building types",
    otherInfo: "Other information",
    eligibility: "Eligibility",
    language: "Language",
    education: "Education",
    livingPlace: "Living place",
    originPlace: "Place of origin",
    bloodType: "Blood type",
    personality: "Personality",
    hobby: "Hobby",
    alcoholicDrink: "Alcoholic drink",
    noVideo: "No video",
  },
  companyProfile: {
    establishedIn: "Established in ",
    locateAt: "Locate at ",
    getDirection: "Get direction",
  },
  products: {
    insuranceTitle: "Checking Insurance",
    expire: "Expire",
    noExpire: "No Expire",
    saleDate: "Order Date",
    insuranceDate: "Insurance Date",
    warrantyExpirationDate: "Expiration Date",
    status: "Status",
    entryDate: "Create At",
    ram: "Memory",
    listTitle: "Fonehouse Products",
    id: "ID Product",
    name: "Product Name",
    state: "State",
    guarantee: "Guarantee",
    amount: "Amount",
    type: "Product Type",
    price: "Base Price",
    promotionPrice: "Promotion Price",
    tag: "Tag",
    seeProduct: "See Detail",
    deleteProduct: "Remove Product",
    editProduct: "Edit Product",
    setTag: "Set Tag",
    deleteConfirm: "Confirm Delete Product",
    setTagForAll: "Set tag for all product",
    setProductTag: "Set tag for product",
    basicInfo: "Basic Information",
    recoverProduct: "Recover",
  },
  loginScreen: {
    headerTitle: "Login to YOUR AGENT",
    email: "Email",
    password: "Password",
    btnLogin: "Log In",
    forgotPassword: "Forgot Password",
    resetPassword: "Reset password",
    checkEmail: "Please check your email",
    phoneNumber: "Phone number",
  },
  newScreen: {
    createAt: "Date",
    title: "Title",
    account: "Account",
    status: "Status",
    publishNews: "Publish",
    newDetail: "See detail",
    unPublishNews: "Unpublish",
  },
  visacreen: {
    createAt: "Date",
    title: "Title",
    account: "Account",
    status: "Status",
    publishVisa: "Publish",
    newDetail: "See detail",
    unPublishVisa: "Unpublish",
  },
  newSummary: {
    seeDetail: "See detail",
  },
  visaSummary: {
    seeDetail: "See detail",
  },
  newsFonehouse: {
    listTitle: "News list",
    viewInformation: "View information",
    removeNews: "Remove news",
    edit: "Edit",
    createAt: "Date",
    title: "Title",
    account: "Account",
  },
  entertainment: {
    shopName: "Shop name",
    Address: "Address",
    Rating: "Rating",
    Status: "Status",
    lockBussiness: "Lock Bussiness",
    entertainmentDetail: "Entertainment detail",
    businessDescription: "Business description",
    unlock: "Unlock bissiness",
    pinPromotional: "Pin promotional",
    unpinPromotional: "Unpin promotional",
    advertise: "Advertise",
    seeDetail: "See Detail",
  },
  bussinessStatus: {
    bussinessOpening: "Open",
    bussinessClosed: "Close",
    bussinessLock: "Lock",
  },
  adminManager: {
    phone: "Phone",
    displayName: "Display Name",
    email: "Email",
    accuracy: "Chat Permission",
    listTitle: "Admin Manager",
    seeDetail: "See Detail",
    denineAdmin: "Drop Admin",
    deninedAccuracy: "Drop Chat Permission",
    approve: "Approve",
    cancel: "Cancel",
    approveReport: "Approve",
    cancelReport: "Cancel",
  },
  reportManager: {
    listTitle: "Report Manager",
    goToStore: "Go to Store",
    message: "Send message",
    approveReport: "Approve",
    cancelReport: "Cancel",
    storeName: "Store name",
    storeType: "Store type",
    phone: "Phone",
    address: "Address",
    content: "Content",
    status: "Status",
    seeDetail: "See detail",
    reportType: "Report type",
    STORE_DOES_NOT_EXIST: "Store does not exit",
    WRONG_ADDRESS: "Wrong address",
    WRONG_PHONE: "Wrong phone",
    REQUEST_TO_BECOME_OWNER: "Request to become store manager",
    OTHER: "Other",
  },
  supermarket: {
    listTitle: "Supermarket list",
    lock: "Lock business",
    seeDetail: "See detail",
    storeName: "Supermarket name",
    address: "Address",
    rating: "Rating",
    status: "Status",
    open: "Open time",
    close: "Close time",
    phone: "Phone",
    supermarketDetail: "Supermarket information ",
    unlock: "Unlock bissiness",
    pinPromotional: "Pin promotional",
    unpinPromotional: "Unpin promotional",
    advertise: "Advertise",
  },
  restaurant: {
    listTitle: "Restaurant list",
    lockBussiness: "Lock business",
    seeDetail: "See detail",
    storeName: "Restaurant name",
    address: "Address",
    rating: "Rating",
    status: "Status",
    open: "Open time",
    close: "Close time",
    phone: "Phone",
    RestaurantDetail: "Restaurant detail",
    unlock: "Unlock bissiness",
    pinPromotional: "Pin promotional",
    unpinPromotional: "Unpin promotional",
    advertise: "Advertise",
  },
  billingList: {
    discountPercentValue: "Percent Value",
    discountConfirm: "Confirm",
    discountValue: "Discount value",
    discount: "Discount type",
    discountDirectTitle: "Directly reduce price on bill",
    transfer: "Pay first",
    daibiki: "Daibiki",
    cash: "Transfer at store",
    seeDetail: "See Detail",
    cancelOrder: "Cancel Order",
    title: "Billing List",
    billCode: "Bill Code",
    phone: "Phone",
    createAt: "Order At",
    status: "Status",
    tranfer: "Type",
    price: "Total",
    new: "New Order",
    cancel: "Cancel Order",
    waitting: "Sending",
    ordered: "Ordered",
    name: "Khách hàng",
    products: "Sản phẩm",
  },
  billingDetail: {
    orderCustomId: "Order Id",
    promotion: "Promotion",
    initialPrice: "Initial Price",
    totalInitial: "Total initial bill fee",
    shippingFee: "Shipping Fee",
    title: "Bill Detail",
    message: "Message",
    discountDirect: "Discount direct to order",
    sendProduct: "Send Products",
    cancelOrder: "Cancel Order",
    alreadySend: "Already Send Products",
    productCode: "Product Code",
    imei: "IMEI",
    softwareVersion: "Software Version",
    accessories: "Accessories",
    amount: "Amount",
    priceSale: "Price to Sale",
    createAt: "Create At",
    manufacture: "Manufacture",
    type: "Type",
    memory: "Memory",
    inputPrice: "Input Price",
    extraPrice: "Extra Price",
    basicInformation: "Basic Information",
    edit: "Edit",
    customerName: "Customer name",
    phone: "Phone",
    address: "Address",
    timeProduct: "Time to get",
    billDetail: "Bill detail",
    totalPrice: "Total price",
    discount: "Discount",
    total: "Total",
    totalExtra: "Total Extra price",
  },
  createBill: {
    customId: "Bill Code",
    shippingFee: "Ship Fee",
    updateBill: "Update",
    titleEdit: "Update Bill",
    title: "Create new bill",
    transactionType: "Transaction Type",
    orderFrom: "Order From",
    customerName: "Customer Name",
    phone: "Phone",
    postCode: "Post code",
    address: "Address",
    timeFrame: "Time to get",
    billDetail: "Bill detail",
    add: "Add Product",
    create: "Create",
    addProductToBill: "Add Product to bill",
    statisticalFonehouse: "Statistical",
    currency: "Đơn vị",
    remove: "Remove",
  },
  banner: {
    setBannerAdmin: "Set Banner Admin",
    createBanner: "CREATE BANNER",
    cancelBtn: "Cancel",
    fieldURL: "URL",
    fieldPosition: "Position",
    updateBanner: "UPDATE BANNER",
  },
  guideFonehouse: {
    removeGuide: "Remove",
    edit: "Edit",
    viewInformation: "See detail",
    listTitle: "Guide list",
  },
  statisticalFonehouse: {
    id: "Bill ID",
    type: "Type",
    status: "Status",
    account: "Account",
    promotionPrice: "Price sale",
    initialPrice: "Initial Price",
    interestRate: "Interest Rate",
    listTitle: "Bill statistical",
    edit: "Edit",
    remove: "Remove",
    createdAt: "Create At",
    moveDetail: "Move Detail",
  },
  moneyManagementFonehouse: {
    listTitle: "Money Management",
    createdAt: "Create At",
    content: "Content",
    collect: "Collect",
    give: "Give",
    price: "Price",
    moneyLeft: "MoneyLeft",
    transferType: "TransferType",
    confirm: "Confirm",
    type: "Type",
    createRecord: "Create Record",
    updateRecord: "Update Record",
    value: "Value",
    transfer: "Transfer",
    cash: "Cash",
    edit: "Edit",
    remove: "Remove",
  },
  historyEditFonehouse: {
    moveDetail: "Move to detail",
    listTitle: "History Edit",
    createdAt: "Date log",
    account: "Account",
    email: "Email",
    time: "Time",
    action: "Action",
    id: "ID",
    created: "Created",
    updated: "Update",
  },
  productDetail: {
    extraPrice: "Lãi/Lỗ",
    createProduct: "Đăng bài",
    productDetail: "Sản phẩm chi tiết",
    inputDate: "Ngày nhập",
    description: "Mô tả chi tiết",
    storage: "Bộ nhớ",
    battery: "Pin",
    capacity: "Dung lượng",
    softwareVersion: "Phiên bản phần mềm",
    imei: "IMEI (seri)",
    discount: "Khuyến mãi",
    discountPrice: "Giá khuyến mãi",
    inputPrice: "Giá gốc",
    price: "Giá bán",
    promotionPrice: "Giá sau khuyến mãi",
    type: "Loại 2",
    amount: "Số lượng",
    contact: "Liên hệ",
    address: "Địa chỉ",
    state: "Tình trạng",
    guarantee: "Bảo hành",
    name: "Tên sản phẩm",
    accessories: "Phụ kiện",
    id: "Mã sản phẩm",
    currency: "Đơn vị",
    entryDate: "Ngày nhập",
    howToBuy: "Cách thức mua hàng",
    machineSeries: "Dòng",
  },
  general: {
    none: "Empty",
  },
  constants: {
    dateCharacter: {
      day: "日",
      month: "月",
      year: "年",
    },
  },
  actionButton: {
    update: "Update",
  },
  updateNameTypeModal: {
    title: "Change type name",
    productType: "Product type",
  },
  entertainmentProduct: {
    productList: "Product list",
    productName: "Product name",
    type: "Type",
    mass: "Mass",
    amount: "Amount",
    promotion: "Promotion",
    price: "Price",
    promotionPrice: "Promotion price",
    profit: "Profit",
    seeDetail: "See detail",
    edit: "Edit",
    delete: "Delete",
    element: "Element",
  },
  cosmetic: {
    shopName: "Shop name",
    Address: "Address",
    Rating: "Rating",
    Status: "Status",
    lockBussiness: "Lock Bussiness",
    cosmeticDetail: "Cosmetic detail",
    businessDescription: "Business description",
    unlock: "Unlock bissiness",
    pinPromotional: "Pin promotional",
    unpinPromotional: "Unpin promotional",
    advertise: "Advertise",
    seeDetail: "See Detail",
    accountHolder: "Account holder",
  },
  cosmeticProduct: {
    productList: "Product list",
    productName: "Product name",
    type: "Type",
    mass: "Mass",
    amount: "Amount",
    promotion: "Promotion",
    price: "Price",
    promotionPrice: "Promotion price",
    profit: "Profit",
    seeDetail: "See detail",
    edit: "Edit",
    delete: "Delete",
    element: "Element",
  },
  support: {
    listTitle: "Support list",
    seeDetail: "See detail",
    account: "Account",
    type: "Type",
    title: "Title",
    note: "Note",
    delete: "Delete",
    supportFeedback: "Support - Feedback",
  },
  ratingComment: {
    deleteComment: "Remove Comment",
    listTitle: "List comments and ratings",
    storeName: "Store name",
    storeType: "Store type",
    phone: "Phone",
    address: "Address",
    status: "Status",
    rating: "Rating",
    userName: "User name",
    title: "Rating Detail",
  },
};
