/* eslint-disable react/display-name */

import { Col, Row, Tooltip } from "antd";
import IdentifyUserTooltip from "components/IdentifyUserTooltip";
import SummaryTooltip from "components/SummaryTooltip";
import IdentifySummaryTooltip from "components/SummaryTooltip/IdentifySummaryTooltip";
import i18n from "i18n";

const Columns = {};

const renderDataValue = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns._id = {
  dataIndex: "_id",
  key: "_id",
  title: "#",
  render: (_id, data) =>
    renderDataValue(
      <Row>
        <Tooltip
          title={<IdentifySummaryTooltip userDetail={data.userDetail} />}
          color="white"
        >
          <Col style={{ marginRight: 4 }}>{data._id}</Col>
        </Tooltip>
      </Row>
    ),
  width: 195,
};

Columns.username = {
  dataIndex: ["name"],
  key: "name",
  title: "Tài khoản",
  width: 150,
  render: (name, data) =>
    renderDataValue(
      <SummaryTooltip
        placement="bottomLeft"
        component={<IdentifySummaryTooltip userDetail={data.userDetail} />}
      >
        <div>{name}</div>
      </SummaryTooltip>
    ),
};

Columns.date = {
  dataIndex: "createdAt",
  key: "store",
  title: "Ngày gửi",
  width: 150,
  render: (name) => renderDataValue(name),
};

Columns.email = {
  title: "Email",
  dataIndex: "email",
  key: "storeType",
  sorter: true,
  render: (type) => renderDataValue(type),
  width: 200,
};

Columns.phone = {
  title: "Số điện thoại",
  dataIndex: "phone",
  key: "store.ref.phoneNumber",
  sorter: true,
  render: (phone) => renderDataValue(phone),
  width: 200,
};

export default Columns;
