import { Fragment } from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import _isArray from "lodash/isArray";
import { Empty, Spin } from "antd";

const ListLoadMore = (props) => {
  const { children, loading, hasMore } = props;

  if (!loading && _isEmpty(children)) {
    return (
      <div className="empty-filter-data-page-wrapper">
        <div>
          <Empty />{" "}
        </div>
      </div>
    );
  }
  if (_isArray(children)) {
    return (
      <Fragment>
        {children}
        {hasMore && !_isEmpty(children) && (
          <div key="loading" className="notification-loading">
            <Spin size={24} />
          </div>
        )}
      </Fragment>
    );
  }
  return children;
};

ListLoadMore.propTypes = {
  children: PropTypes.array,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  emptyText: PropTypes.string,
  emptyIcon: PropTypes.string,
  queryValue: PropTypes.object,
  subEmptyText: PropTypes.string,
};
export default ListLoadMore;
