import { useEffect } from "react";
import _get from "lodash/get";
import BannerCreateFrom from "components/Banner/CreateForm";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import {
  updateBannerRequest,
  getBannerDetailRequest,
  resetBannerDetail,
} from "providers/BannerProvider/slice";
import "../BannerCreate/styles.less";

const BannerEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idBanner } = useParams();
  const bannerDetail = useSelector((state) =>
    _get(state, "banner.bannerDetail", { type: [] })
  );

  useEffect(() => {
    dispatch(getBannerDetailRequest({ idBanner }));
  }, [dispatch, idBanner]);

  const handleSubmit = (values) => {
    dispatch(updateBannerRequest({ ...values, idBanner })).then(() => {
      navigate(-1);
      dispatch(resetBannerDetail());
    });
  };

  return (
    <div className="banner-create-wrapper">
      <BannerCreateFrom
        initialValues={{
          ...bannerDetail,
          image: { url: bannerDetail.image },
          type: bannerDetail.type || [],
        }}
        onSubmit={handleSubmit}
        isEditBanner
      />
    </div>
  );
};

export default BannerEdit;
