import { Col, Modal, Row, Image } from "antd";
import i18n from "i18n";
import BackIcon from "assets/images/back.svg";
import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { PlaceHolderIMG } from "assets/images";

const ReportStoreManagerModal = (props) => {
  const dispatch = useDispatch();
  const { setReportStoreManagerModal } = props;
  const userDetail = useSelector((state) => _get(state, "auth.userDetailById"));
  const reportStoreData = useSelector((state) =>
    _get(state, "reportManager.storeManagerData")
  );
  const { visible, data } = reportStoreData;
  const handleCloseModal = () => {
    dispatch(
      setReportStoreManagerModal({
        visible: false,
        data: {},
      })
    );
  };
  return (
    <div>
      {visible && (
        <Modal
          title={i18n.t("reportManager.seeReport")}
          visible={visible}
          width={1000}
          className="market-product-modal-wrapper"
          footer={null}
          onCancel={handleCloseModal}
          maskClosable={false}
          closeIcon={<img src={BackIcon} alt="" />}
        >
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col>
              <Image
                style={{ width: 200, height: 200, resizeMode: "contain" }}
                fallback={PlaceHolderIMG}
                src={data.store.ref.banner}
              />
              <div className="store-name">{data.store.ref.name}</div>
              <div>
                <b>Địa chỉ:</b>
                {data.store.ref.address}
              </div>
              <div>
                <b>Số điện thoại:</b>
                {data.store.ref.phoneNumber}
              </div>
              <div>
                <b>Loại cửa hàng:</b>
                {data.store.type}
              </div>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContet: "center",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div className="store-name">Người gửi báo cáo</div>
              <Col style={{ display: "flex", flexDirection: "column" }}>
                <Image
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: 100,
                    resizeMode: "contain",
                  }}
                  fallback={PlaceHolderIMG}
                  src={userDetail?.avatar}
                />
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_DOMAIN_NAME}/home/${data.reporter}`}
                  rel="noopener noreferrer"
                >
                  Xem thông tin
                </a>
              </Col>
              <Col>
                <div>
                  <b>Tài khoản:</b>
                  {userDetail?.idUser?.name}
                </div>
                <div>
                  <b>Địa chỉ:</b>
                  {userDetail?.idUser?.address}
                </div>
                <div>
                  <b>Số điện thoại:</b>
                  {userDetail?.idUser?.phone}
                </div>
              </Col>
            </Col>
          </Row>
          <div className="store-name">Ảnh giấy phép kinh doanh</div>
          {data.storeOwnerInfo.businessLicenses.map((item) => (
            <Col style={{ marginTop: 10 }} key={item}>
              <Image fallback={PlaceHolderIMG} src={item} />
            </Col>
          ))}
          <div className="store-name">Ảnh thẻ ngoại kiều</div>
          {data.storeOwnerInfo.identityCard.map((item) => (
            <Col style={{ marginTop: 10 }} key={item}>
              <Image fallback={PlaceHolderIMG} src={item} />
            </Col>
          ))}
          <div className="store-name">Ảnh doanh nghiệp</div>
          {data.storeOwnerInfo.businessImages.map((item) => (
            <Col style={{ marginTop: 10 }} key={item}>
              <Image fallback={PlaceHolderIMG} src={item} />
            </Col>
          ))}
        </Modal>
      )}
    </div>
  );
};
export default ReportStoreManagerModal;
