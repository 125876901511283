import { memo, useMemo } from "react";
import _get from "lodash/get";
import PropTypes from "prop-types";
import SearchBarComponent from "components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { Filter } from "components/common/Form";
import { MARKET_PRODUCT_MODAL_TYPE } from "utils/constants";
import { Col, Row, Button } from "antd";
import { setMarketProductModal } from "providers/MarketProductProvider/slice";

const SearchBar = ({ isPermissionToEdit }) => {
  const dispatch = useDispatch();
  const productMarketTypeList = useSelector((state) =>
    _get(state, "marketProduct.productMarketTypeList")
  );
  const formattedProductMarketTypeList = useMemo(
    () =>
      productMarketTypeList.map((productType) => ({
        value: productType._id,
        label: productType.name,
      })),
    [productMarketTypeList]
  );
  return (
    <SearchBarComponent filterFields={["productType"]} isOnChangeSearch>
      <Col span={24} className="market-product-searchbar">
        <Row justify="space-between" align="middle">
          <Col className="l-title-text">Danh sách mặt hàng</Col>
          <Row>
            <Col flex="auto">
              {isPermissionToEdit && (
                <Button
                  type="primary"
                  size="large"
                  onClick={() =>
                    dispatch(
                      setMarketProductModal({
                        visible: true,
                        data: {},
                        type: MARKET_PRODUCT_MODAL_TYPE.CREATE,
                      })
                    )
                  }
                >
                  Tạo sản phẩm
                </Button>
              )}
            </Col>

            <Col>
              <Filter
                name="productType"
                options={Object.values(formattedProductMarketTypeList)}
                popConfirmClassName="gb-role-popconfirm"
                hasSearching
              />
            </Col>
          </Row>
        </Row>
      </Col>
    </SearchBarComponent>
  );
};

SearchBar.propTypes = {
  isPermissionToEdit: PropTypes.bool,
};
export default memo(SearchBar);
