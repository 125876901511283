/* eslint-disable no-unused-vars */
import { useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { Col, Row, Button, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import Helper from "utils/helpers";
import {
  CURRENCY,
  PROMOTION_TYPE,
  PROMOTION_TYPE_TEXT,
  ARTICLE_PRODUCT_MODAL_TYPE,
} from "utils/constants";
import i18n from "i18n";

import { TextInput, ImageUpload, DatePicker, Select } from "components/common";
import EditArticleTypeModal from "components/EditArticleTypeModal";
import {
  addProductCosmeticTypeRequest,
  deleteProductCosmeticTypeRequest,
  editProductCosmeticTypeRequest,
  getProductCosmeticTypeRequest,
  getCosmeticProductListRequest,
} from "providers/CosmeticProductProvider/slice";
import { PlusIcon } from "assets/images";
import EditCosmeticProductTypeForm from "./EditCosmeticProductTypeForm";
import { createProductCosmeticValidate } from "./validate";
import "./styles.less";

const CosmeticProductForm = ({
  initialValues,
  onSubmit,
  setCosmeticProductModal,
  type,
  productCosmeticTypeList,
  searchObject,
  setSearch,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const cosmeticStoreId = useSelector((state) =>
    _get(state, "cosmetic.detail._id")
  );
  const formattedProductCosmeticTypeList = useMemo(
    () =>
      productCosmeticTypeList.map((_type) => ({
        value: _type._id,
        label: _type.name,
      })),
    [productCosmeticTypeList]
  );
  const [hasPromotion, setHasPromotion] = useState(
    initialValues.promotion > 0 ||
      initialValues.percentagePromotion > 0 ||
      false
  );
  const [canGoBack, setCanGoBack] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState({});
  const handleAddProductCosmeticType = () => {
    const newProductType = formikRef.current?.values.newProductType;
    if (!_isEmpty(newProductType)) {
      dispatch(
        addProductCosmeticTypeRequest({ cosmeticStoreId, name: newProductType })
      );
      formikRef.current?.setFieldValue("newProductType", "");
    }
  };

  const handleRemoveProductCosmeticType = (event) => {
    if (canGoBack) {
      setCanGoBack(false);
    }
    const cosmeticProductTypeId = _get(
      searchObject,
      "cosmeticProductTypeId",
      []
    ).filter((item) => item !== event.value);
    dispatch(
      deleteProductCosmeticTypeRequest({
        cosmeticStoreId,
        id: event.value,
        name: event.label,
        searchObject: { ...searchObject, cosmeticProductTypeId },
      })
    );
    setSearch({ ...searchObject, cosmeticProductTypeId });
    if (formikRef.current?.values.productType === event.value) {
      formikRef.current?.setFieldValue("productType", "");
    }
  };

  const handleEditProductCosmeticType = (event) => {
    dispatch(
      editProductCosmeticTypeRequest({
        cosmeticStoreId,
        productTypeCosmeticId: isOpenModal.value,
        name: event.name,
      })
    ).then(() => {
      dispatch(getProductCosmeticTypeRequest({ cosmeticStoreId }));
      dispatch(getCosmeticProductListRequest(searchObject));
      setIsOpenModal({});
    });
  };

  const handleOpenEditTypeModal = (data) => {
    setIsOpenModal(data);
  };

  const formattedInitValues = {
    name: _get(initialValues, "name", ""),
    thumbnail: initialValues.thumbnail
      ? { url: initialValues.thumbnail, objectId: initialValues.thumbnail }
      : null,
    productType: _get(initialValues, "type._id", ""),
    element: _get(initialValues, "element", ""),
    amount: _get(initialValues, "amount", ""),
    price: _get(initialValues, "price", ""),
    currency: _get(initialValues, "currency", CURRENCY.JPY),
    promotion: _get(initialValues, "promotion", 0),
    percentagePromotion: _get(initialValues, "percentagePromotion", 0),
    discountType:
      initialValues.promotion > 0
        ? PROMOTION_TYPE.CASH
        : PROMOTION_TYPE.PERCENT,
    fromDatePromotion: initialValues.fromDatePromotion
      ? moment(initialValues.fromDatePromotion)
      : null,
    toDatePromotion: initialValues.toDatePromotion
      ? moment(initialValues.toDatePromotion)
      : null,
  };

  const handlePromotionTypeChange = () => {
    formikRef.current?.setFieldValue("promotion", "0");
    formikRef.current?.setFieldValue("percentagePromotion", "0");
  };

  const handleDeletePromotion = () => {
    setHasPromotion(!hasPromotion);
    formikRef.current?.setFieldValue("promotion", "0");
    formikRef.current?.setFieldValue("percentagePromotion", "0");
  };
  return (
    <div className="cosmetic-form-wrapper">
      {!_isEmpty(isOpenModal) && (
        <EditArticleTypeModal
          title={i18n.t("updateNameTypeModal.title")}
          data={isOpenModal}
          handleCloseModal={handleOpenEditTypeModal}
        >
          <EditCosmeticProductTypeForm
            initialValues={{ name: isOpenModal.label }}
            onSubmit={handleEditProductCosmeticType}
          />
        </EditArticleTypeModal>
      )}
      <Formik
        validateOnMount
        initialValues={formattedInitValues}
        enableReinitialize
        validationSchema={createProductCosmeticValidate}
        innerRef={formikRef}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isValid, values }) => (
          <Form>
            <Row className="cosmetic-product-form-container">
              <Col className="cosmetic-product-thumbnail">
                <ImageUpload name="thumbnail" />
              </Col>
              <Col className="cosmetic-product-form-info">
                <div className="m-title-text">
                  {i18n.t("cosmeticProduct.basicInfomation")}
                </div>
                <Row className="info-row">
                  <Col style={{ width: "100%" }}>
                    <div className="item-title">Tên sản phẩm:</div>
                    <TextInput name="name" size="small" required />
                  </Col>
                </Row>
                <Row className="info-row">
                  <Col span={11}>
                    <div className="item-title">Loại sản phẩm:</div>
                    <Select
                      name="productType"
                      required
                      options={formattedProductCosmeticTypeList}
                      isRemove
                      handleRemoveItem={handleRemoveProductCosmeticType}
                      isEditProductType
                      handleEditItem={handleOpenEditTypeModal}
                    />
                  </Col>
                  <Col span={11}>
                    <div className="item-title">Thêm mới loại:</div>
                    <div className="add-new-product-type">
                      <TextInput name="newProductType" size="small" />
                      <img
                        className="plus-icon"
                        src={PlusIcon}
                        alt=""
                        onClick={() => handleAddProductCosmeticType()}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="info-row">
                  <Col style={{ width: "100%" }}>
                    <div className="item-title">Thành phần:</div>
                    <TextInput name="element" size="small" />
                  </Col>
                </Row>
                <Row className="info-row">
                  <Col style={{ width: "100%" }}>
                    <div className="item-title">Số lượng:</div>
                    <TextInput name="amount" size="small" required />
                  </Col>
                </Row>
                <Row className="info-row">
                  <Col span={11}>
                    <div className="item-title">Giá bán:</div>
                    <TextInput name="price" size="small" required />
                  </Col>
                  <Col span={11}>
                    <div className="item-title">Đơn vị:</div>
                    <Select
                      name="currency"
                      required
                      disabled
                      options={Helper.convertObjectToOptions(CURRENCY)}
                    />
                  </Col>
                </Row>
                <Row className="promotion-info-row">
                  <Switch
                    checked={hasPromotion}
                    disabled={Number(values.price) === 0}
                    onClick={() => handleDeletePromotion()}
                  />
                  <div className="promotion"> Khuyến mãi</div>
                </Row>
                {hasPromotion && (
                  <Row>
                    <Row className="info-row">
                      <Col span={11}>
                        <div className="item-title">Khuyến mãi:</div>
                        <Select
                          allowClear
                          name="discountType"
                          onChange={handlePromotionTypeChange}
                          options={Helper.convertObjectToOptions(
                            PROMOTION_TYPE_TEXT
                          )}
                          disabled={Number(values.price) === 0}
                        />
                      </Col>
                      <Col span={11}>
                        <div className="item-title">Giá Khuyến mãi:</div>
                        <TextInput
                          name={
                            formikRef.current?.values.discountType ===
                            PROMOTION_TYPE.CASH
                              ? "promotion"
                              : "percentagePromotion"
                          }
                          size="small"
                          required
                          disabled={Number(values.price) === 0}
                        />
                      </Col>
                    </Row>
                    <Row className="info-row">
                      <Col span={11}>
                        <div className="item-title">Từ ngày:</div>
                        <DatePicker
                          name="fromDatePromotion"
                          format="DD/MM/YYYY"
                          required
                          size="small"
                          disabled={
                            !(
                              values.promotion > 0 ||
                              values.percentagePromotion > 0
                            ) || Number(values.price) === 0
                          }
                        />
                      </Col>
                      <Col span={11}>
                        <div className="item-title">Đến ngày:</div>
                        <DatePicker
                          name="toDatePromotion"
                          format="DD/MM/YYYY"
                          required
                          size="small"
                          disabled={
                            !(
                              values.promotion > 0 ||
                              values.percentagePromotion > 0
                            ) || Number(values.price) === 0
                          }
                        />
                      </Col>
                    </Row>
                  </Row>
                )}
                {type === ARTICLE_PRODUCT_MODAL_TYPE.CREATE ? (
                  <Row className="add-product-actions">
                    <Button type="primary" size="small" onClick={handleSubmit}>
                      TẠO SẢN PHẨM
                    </Button>
                  </Row>
                ) : (
                  <Row className="save-back-product-actions">
                    <Button type="primary" size="small" onClick={handleSubmit}>
                      LƯU
                    </Button>
                    <Button
                      disabled={!canGoBack}
                      size="small"
                      onClick={() =>
                        dispatch(
                          setCosmeticProductModal({
                            visible: true,
                            data: initialValues,
                            type: ARTICLE_PRODUCT_MODAL_TYPE.DETAIL,
                          })
                        )
                      }
                    >
                      TRỞ LẠI
                    </Button>
                  </Row>
                )}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CosmeticProductForm.propTypes = {
  initialValues: PropTypes.object,
  onTagChange: PropTypes.func,
  handleTagAllChange: PropTypes.func,
  tagAll: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setCosmeticProductModal: PropTypes.func,
  type: PropTypes.string,
  productCosmeticTypeList: PropTypes.array,
  searchObject: PropTypes.object,
  setSearch: PropTypes.func,
};
export default CosmeticProductForm;
