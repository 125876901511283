import { put, takeLeading, call, takeEvery } from "redux-saga/effects";
import request from "utils/api";
import Helper from "utils/helpers";
import _pick from "lodash/pick";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import {
  getAllNewsRequest,
  getAllNewsSuccess,
  stopLoading,
  createNewsRequest,
  createNewsSuccess,
  updateStatusNewsSuccess,
  updateStatusNewsRequest,
  getNewsDetailRequest,
  getNewsDetailSuccess,
  updateNewsRequest,
  updateNewsSuccess,
  getCountNotSeenSuccess,
  getCountNotSeenRequest,
} from "providers/NewsProvider/slice";

function* getAllNews(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "keyword",
    "roles",
    "sortTitle",
    "status",
    "sort",
    "seen",
  ]);
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  const { data } = yield call(
    request,
    "api/admin/news-manager/news/get-all",
    params,
    { method: "GET" }
  );
  yield put(getAllNewsSuccess(data));
}

function* createNews(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "type",
    "title",
    "thumbnail",
    "content",
  ]);
  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  const data = yield call(request, "api/admin/news-manager/news", params, {
    method: "POST",
  });
  yield put(createNewsSuccess(data, meta));
}

function* getNewsDetail(action) {
  const params = _pick(action.payload, ["idNews"]);
  const { data } = yield call(request, "api/admin/news-manager/news", params, {
    method: "GET",
  });
  yield put(getCountNotSeenRequest());
  yield put(getNewsDetailSuccess(data));
}

function* onError() {
  yield put(stopLoading());
}

function* updateStatusNews(action) {
  const { meta } = action;
  const statusNews = _get(action, "payload.status");
  const newsId = _get(action, "payload.newsId", []);
  const params = _pick(action.payload, ["status", "idNews"]);
  const result = yield call(
    request,
    "api/admin/news-manager/news/update-status",
    params,
    {
      method: "PUT",
    }
  );
  yield put(updateStatusNewsSuccess(result, meta));
  Helper.toastr(
    `ID:${newsId.join(", ")}`,
    `${statusNews === "refuse" ? "Từ chối" : "Duyệt bài"} thành công`,
    "success"
  );
}

function* updateNews(action) {
  const { meta } = action;

  const params = _pick(action.payload, [
    "type",
    "title",
    "thumbnail",
    "content",
    "idNews",
  ]);
  if (params.thumbnail) {
    params.thumbnail = params.thumbnail.url;
  }
  const result = yield call(request, "/api/admin/news-manager/news", params, {
    method: "PUT",
  });
  yield put(updateNewsSuccess(result, meta));
  Helper.toastr("Cập nhật bài viết thành công", "Thành công", "success");
}

function* getCountNotSeen() {
  const { count } = yield call(
    request,
    "api/admin/news-manager/news/get-count-not-seen",
    {},
    { method: "GET" }
  );
  yield put(getCountNotSeenSuccess(count));
}

// function * onGetCurrentNewsError () {
//   yield put(stopLoading());
//   Helper.removeAuthToken();
// }

export default function* watchAuth() {
  yield takeEvery(getAllNewsRequest.type, safe(getAllNews, onError));
  yield takeLeading(
    updateStatusNewsRequest.type,
    safe(updateStatusNews, onError)
  );
  yield takeLeading(createNewsRequest.type, safe(createNews, onError));
  yield takeLeading(updateNewsRequest.type, safe(updateNews, onError));
  yield takeEvery(getNewsDetailRequest.type, safe(getNewsDetail));
  yield takeLeading(getCountNotSeenRequest.type, safe(getCountNotSeen));
}
