import PropTypes from "prop-types";
import { Image, Col, Row, Button } from "antd";
import moment from "moment";
import DefaultImage from "assets/images/photo.png";

import i18n from "i18n";
import { PlaceHolderIMG } from "assets/images";

const restaurantProductDetail = (props) => {
  const {
    marketProductDetail = {},
    handleEditMarketProduct,
    handleDeleteMarketProduct,
    isPermissionToEdit,
  } = props;
  const {
    thumbnail,
    name,
    productCategory,
    element,
    amount,
    price,
    percentagePromotion,
    promotion,
    fromDatePromotion,
    toDatePromotion,
    currency,
  } = marketProductDetail;
  const renderItemInfo = (title, content) => (
    <Col className="info-col" span={6}>
      <div className="title-text">{title}</div>
      <div className="content-text">{content}</div>
    </Col>
  );

  return (
    <Row className="market-product-detail-container">
      <Col className="market-product-thumbnail">
        <Image
          width={200}
          height={150}
          fallback={PlaceHolderIMG}
          src={thumbnail || DefaultImage}
        />
        {(percentagePromotion > 0 || promotion > 0) && (
          <div className="sale-price-wrapper">
            -
            {promotion > 0
              ? `${Intl.NumberFormat("de-DE").format(promotion)} ${currency}`
              : `${percentagePromotion}%`}
          </div>
        )}
      </Col>
      <Col className="market-product-detail-info">
        <div className="m-title-text">
          {i18n.t("marketProduct.basicInfomation")}
        </div>
        <div className="detail-info-wrapper">
          <Row className="detail-info-row">
            {renderItemInfo("Tên sản phẩm:", name)}
            {productCategory && renderItemInfo("Loại:", productCategory.name)}
            {renderItemInfo("Số lượng:", amount)}
            {renderItemInfo("Mô tả (Size):", `${element}`)}
          </Row>
          <Row className="detail-info-row">
            {renderItemInfo(
              "Giá bán:",
              `${Intl.NumberFormat("de-DE").format(price)} ${currency}`
            )}
            {renderItemInfo(
              "Khuyến mãi:",
              promotion > 0
                ? `${Intl.NumberFormat("de-DE").format(promotion)} ${currency}`
                : `${percentagePromotion}%`
            )}
            {(promotion > 0 || percentagePromotion > 0) && (
              <>
                {renderItemInfo(
                  "Từ ngày:",
                  moment(fromDatePromotion).format("DD/MM/YYYY")
                )}
                {renderItemInfo(
                  "Đến ngày:",
                  moment(toDatePromotion).format("DD/MM/YYYY")
                )}
              </>
            )}
          </Row>
        </div>
        {isPermissionToEdit && (
          <div className="edit-remove-product-actions">
            <Button
              type="primary"
              size="small"
              onClick={() => handleEditMarketProduct(marketProductDetail)}
            >
              CHỈNH SỬA
            </Button>
            <Button
              type="primary"
              danger
              size="small"
              onClick={() => handleDeleteMarketProduct()}
            >
              XÓA SẢN PHẨM
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
};
restaurantProductDetail.propTypes = {
  entertainmentDetail: PropTypes.object,
  setMarketProductModal: PropTypes.func,
  handleEditMarketProduct: PropTypes.func,
  handleDeleteMarketProduct: PropTypes.func,
  isPermissionToEdit: PropTypes.bool,
};

export default restaurantProductDetail;
