/* eslint-disable react/display-name */

import i18n from "i18n";
import _get from "lodash/get";

const supportTypeLabel = {
  supportRequest: "Hỗ trợ",
  errorReport: "Báo lỗi",
  feedback: "Góp ý",
  report: "Báo cáo",
};

const Columns = {};

const renderDataValue = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns.supportId = {
  dataIndex: "customId",
  key: "customId",
  title: "#",
  render: (customId) => renderDataValue(<div>#{customId}</div>),
  width: 120,
};

Columns.account = {
  title: i18n.t("support.account"),
  dataIndex: "userId",
  key: "userId",
  render: (userId, data) =>
    renderDataValue(<div>{_get(data, "userId.name", "")}</div>),
  width: 200,
};

Columns.type = {
  title: i18n.t("support.type"),
  dataIndex: "type",
  key: "type",
  render: (type) => renderDataValue(<div>{supportTypeLabel[type]}</div>),
  sorter: true,
  width: 120,
};

Columns.title = {
  title: i18n.t("support.title"),
  dataIndex: "title",
  key: "tile",
  render: (title) => renderDataValue(title),
};

Columns.note = {
  title: i18n.t("support.note"),
  dataIndex: "content",
  key: "content",
  render: (content) => renderDataValue(content),
};

export default Columns;
