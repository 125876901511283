import PropTypes from "prop-types";
import _get from "lodash/get";
import { Image } from "antd";
import { useSelector } from "react-redux";

import DefaultImage from "assets/images/photo.png";
import { PlaceHolderIMG } from "assets/images";
import "./style.less";

export const StorePhotoSummaryTooltip = ({ detail }) => {
  const { imageUrl, comment, createdBy } = detail;
  const { detail: storeDetail } = useSelector((state) => state.store);
  return (
    <div className="store-photo-summary-tooltip-wrapper">
      <Image src={imageUrl || DefaultImage} fallback={PlaceHolderIMG} />
      <div>
        <div className="bold" style={{ fontSize: 24, marginBottom: 8 }}>
          {_get(storeDetail, "name", "")}
        </div>
        <div className="bold" style={{ marginBottom: 8 }}>
          Chủ tài khoản: {_get(createdBy, "name", "")}
        </div>
        <div>Nội dung:</div>
        <div>{comment}</div>
      </div>
    </div>
  );
};

StorePhotoSummaryTooltip.propTypes = {
  detail: PropTypes.object,
};

export default StorePhotoSummaryTooltip;
