import { useMemo } from "react";
import { Row, Typography, Button, Dropdown, Menu, Tabs } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useNavigate, Link } from "react-router-dom";
import { ProductColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import {
  getProductListRequest,
  setSelectedNewsIds,
  setTagModalData,
  deleteProductRequest,
  recoverProductRequest,
  setPriceModalData,
} from "providers/ProductProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";
import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import SetTagModal from "components/Product/SetTagModal";
import SetPriceModal from "components/Product/SetPriceModal";
import TabHeader from "components/TabHeaderFonehouse";
import SearchBar from "./SearchBar";

const { TabPane } = Tabs;

const ProductList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productList = useSelector((state) => _get(state, "product.docs"));
  const productTotal = useSelector((state) => _get(state, "product.totalDocs"));
  const totalInitiatedPrice = useSelector((state) =>
    _get(state, "product.totalInitiatedPrice")
  );
  const isLoading = useSelector((state) => _get(state, "product.isLoading"));
  const productTypes = useSelector((state) => state.product.productTypes);
  const formattedproductTypes = useMemo(
    () =>
      productTypes.map((acc) => ({
        key: _get(acc, "_id"),
        title: _get(acc, "name"),
        children: _get(acc, "machineSeries", []).map((child) => ({
          key: _get(child, "id"),
          title: _get(child, "name"),
        })),
        disabled: _isEmpty(_get(acc, "machineSeries", [])),
      })),
    [productTypes]
  );
  const selectedRowKeys = useSelector((state) =>
    _get(state, "product.selectedRowKeys", [])
  );
  const selectedRows = useSelector((state) =>
    _get(state, "product.selectedRows", [])
  );
  const isDisableApproveBtn = !!selectedRows.find(
    (item) => item.status === "approved"
  );
  const isDisableRefuseBtn = !!selectedRows.find(
    (item) => item.status === "refuse"
  );

  const [searchObject, setSearch] = useQuery(getProductListRequest, {
    arrayParams: ["line"],
  });

  const menuAll = () => (
    <Menu>
      <Menu.Item
        disabled={isDisableApproveBtn || _isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                title: "Xác nhận xóa sản phẩm",
                content: "Xác nhận xóa sản phẩm",
                onOk: () =>
                  dispatch(
                    deleteProductRequest({ idProduct: selectedRowKeys })
                  ).then(() => dispatch(getProductListRequest(searchObject))),
              },
            })
          )
        }
      >
        {i18n.t("products.deleteProduct")}
      </Menu.Item>
      <Menu.Item
        disabled={isDisableRefuseBtn || _isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            setTagModalData({
              visible: true,
              data: {
                products: selectedRows,
                originalProducts: selectedRows,
              },
            })
          )
        }
      >
        {i18n.t("products.setTag")}
      </Menu.Item>
      <Menu.Item
        onClick={() => dispatch(setPriceModalData({ visible: true }))}
        disabled={selectedRowKeys.length === 0}
      >
        Tăng/giảm giá
      </Menu.Item>
    </Menu>
  );
  const menuRemovedAll = () => (
    <Menu>
      <Menu.Item
        onClick={() =>
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                title: "Xác nhận phục hồi sản phẩm",
                content: "Xác nhận phục hồi sản phẩm",
                onOk: () =>
                  dispatch(
                    recoverProductRequest({ idProduct: selectedRowKeys })
                  ).then(() => dispatch(getProductListRequest(searchObject))),
              },
            })
          )
        }
      >
        {i18n.t("products.recoverProduct")}
      </Menu.Item>
    </Menu>
  );

  const menuRemoved = (record) => {
    const { _id } = record;
    return (
      <Menu>
        <Menu.Item
          onClick={() =>
            dispatch(
              setPopup({
                isOpen: true,
                data: {
                  title: "Xác nhận phục hồi sản phẩm",
                  content: "Xác nhận phục hồi sản phẩm",
                  onOk: () =>
                    dispatch(recoverProductRequest({ idProduct: [_id] })).then(
                      () => dispatch(getProductListRequest(searchObject))
                    ),
                },
              })
            )
          }
        >
          {i18n.t("products.recoverProduct")}
        </Menu.Item>
      </Menu>
    );
  };
  const menu = (record) => {
    const { _id } = record;
    return (
      <Menu>
        <Menu.Item>
          <Link to={`/fonehouse/products/${_id}`} state={{ canGoBack: true }}>
            {i18n.t("products.seeProduct")}
          </Link>
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            dispatch(
              setPopup({
                isOpen: true,
                data: {
                  title: "Xác nhận xóa sản phẩm",
                  content: "Xác nhận xóa sản phẩm",
                  onOk: () =>
                    dispatch(deleteProductRequest({ idProduct: [_id] })).then(
                      () => dispatch(getProductListRequest(searchObject))
                    ),
                },
              })
            )
          }
        >
          {i18n.t("products.deleteProduct")}
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            dispatch(
              setTagModalData({
                visible: true,
                data: {
                  products: [record],
                  originalProducts: [record],
                },
              })
            )
          }
        >
          {i18n.t("products.setTag")}
        </Menu.Item>
      </Menu>
    );
  };

  const menuSold = (record) => {
    const { _id } = record;
    return (
      <Menu>
        <Menu.Item>
          <Link to={`/fonehouse/products/${_id}`} state={{ canGoBack: true }}>
            {i18n.t("products.seeProduct")}
          </Link>
        </Menu.Item>
      </Menu>
    );
  };

  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const actionButtonsRemove = (record) => (
    <Dropdown overlay={() => menuRemoved(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );
  const actionButtonsInSold = (record) => (
    <Dropdown overlay={() => menuSold(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedNewsIds({ record, selected, selectedRows: _selectedRows })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedNewsIds({
          selected,
          selectedRows: _selectedRows,
          changeRows,
        })
      );
    },
  };

  const columns = [
    ProductColumn.productId,
    ProductColumn.name,
    ProductColumn.state,
    ProductColumn.ram,
    ProductColumn.guarantee,
    ProductColumn.amount,
    ProductColumn.type,
    ProductColumn.inputPrice,
    ProductColumn.price,
    ProductColumn.promotionPrice,
    ProductColumn.tag,
    {
      title: (
        <Dropdown overlay={menuAll} placement="bottomLeft" arrow>
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  const columnsRemove = [
    ProductColumn.productId,
    ProductColumn.author,

    ProductColumn.updateAt,
    ProductColumn.name,
    ProductColumn.state,
    ProductColumn.guarantee,
    ProductColumn.amount,
    ProductColumn.type,
    ProductColumn.inputPrice,
    ProductColumn.price,
    ProductColumn.promotionPrice,
    ProductColumn.tag,
    {
      title: (
        <Dropdown overlay={menuRemovedAll} placement="bottomLeft" arrow>
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtonsRemove(record),
    },
  ];

  const columnsSold = [
    ProductColumn.entryDate,
    ProductColumn.name,
    ProductColumn.ram,
    ProductColumn.state,
    ProductColumn.imei,
    ProductColumn.address,
    ProductColumn.type,
    ProductColumn.inputPrice,
    ProductColumn.saleDate,
    { ...ProductColumn.productId, title: "Mã đơn hàng" },
    ProductColumn.price,
    ProductColumn.extraPrice,
    {
      title: (
        <Dropdown overlay={menuAll} placement="bottomLeft" arrow>
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtonsInSold(record),
    },
  ];
  const renderColumn = {
    active: columns,
    remove: columnsRemove,
    sold: columnsSold,
  };
  return (
    <div className="product-list-wrapper">
      <SetTagModal />
      <SetPriceModal />
      <Row className="product-list-header" justify="space-between">
        <Typography.Title level={4}>
          {i18n.t("products.listTitle")}
        </Typography.Title>
        <Button
          type="primary"
          onClick={() => {
            navigate("/fonehouse/products/create", {
              state: { canGoBack: true },
            });
          }}
        >
          {i18n.t("productDetail.createProduct")}
        </Button>
      </Row>
      <div className="content-container">
        <SearchBar
          searchObject={searchObject}
          formattedproductTypes={formattedproductTypes}
        />
        {totalInitiatedPrice > 0 && (
          <div className="total-price">
            <strong>Tổng giá trị sản phẩm:</strong>
            {` ${totalInitiatedPrice.toLocaleString()} JPY`}
          </div>
        )}
        <Tabs
          className="superMarket-tabs-container"
          type="card"
          activeKey={searchObject.status}
          onChange={(key) => {
            setSearch(
              {
                status: key,
                keyword: searchObject.keyword,
              },
              true
            );
          }}
        >
          {["active", "remove", "sold"].map((value) => (
            <TabPane
              className="status-tab-pane"
              tab={<TabHeader status={value} total={100} />}
              key={value}
            >
              <Table
                rowSelection={rowSelection}
                scroll={{ x: 1000 }}
                loading={isLoading}
                bordered
                columns={renderColumn[value]}
                dataSource={productList}
                rowKey={(data) => data._id}
                total={productTotal}
                onRow={(record) => ({
                  onDoubleClick: () => {
                    navigate(`/fonehouse/products/${record._id}`, {
                      state: { canGoBack: true },
                    });
                  },
                })}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default ProductList;
