import { useRef } from "react";
import { Row, Button, Typography } from "antd";
import { Formik, Form } from "formik";
import _get from "lodash/get";
import { useNavigate } from "react-router-dom";

import { TextInput, TextEditor, ImageUpload } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "components/BackButton";
import { createConnectivityProductPostRequest } from "providers/SimProvider/slice";
import { createConnectivityProductPostValidate } from "./validate";
import "./styles.less";

const SimPostCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCreating = useSelector((state) => _get(state, "Visa.isCreating"));
  const formikRef = useRef();

  const handleCreateSimPost = (values) => {
    dispatch(createConnectivityProductPostRequest(values)).then((data) =>
      navigate(`/sim/post/${data.data._id}`, {
        state: { canGoBack: true },
        replace: true,
      })
    );
  };

  return (
    <div className="Visa-create-page">
      <Row className="Visa-create-header" justify="space-between">
        <Row>
          <BackButton />
          <Typography.Title level={4}>Đăng bài</Typography.Title>
        </Row>
        <Button
          loading={isCreating}
          size="large"
          type="primary"
          onClick={() => formikRef.current.handleSubmit()}
        >
          Đăng bài
        </Button>
      </Row>
      <div className="create-form-wrapper">
        <Formik
          innerRef={formikRef}
          initialValues={{ content: "", title: "", thumbnail: null }}
          validationSchema={createConnectivityProductPostValidate}
          onSubmit={handleCreateSimPost}
        >
          {() => (
            <Form>
              <Row justify="space-between">
                <TextInput name="title" placeholder="Tiêu đề" size="large" />
              </Row>
              <Row justify="space-between" className="editor-wrapper">
                <ImageUpload name="thumbnail" />
                <TextEditor name="content" />
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SimPostCreate;
