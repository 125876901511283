import {
  put,
  call,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import Helper from "utils/helpers";
import { safe } from "providers/GeneralProvider/saga";
import {
  getAllMoneyManagementRequest,
  getAllMoneyManagementSuccess,
  stopLoading,
  getOldBalanceRequest,
  getOldBalanceSuccess,
  deleteMoneyManagementDataRequest,
  createMoneyManagementSuccess,
  createMoneyManagementRequest,
  deleteMoneyManagementDataSuccess,
  updateMoneyManagementRequest,
  updateMoneyManagementSuccess,
  getMoneyManagementRecordRequest,
  getMoneyManagementRecordSuccess,
  deleteMoneyManagementRequest,
  deleteMoneyManagementSuccess,
  updateBalanceRequest,
  getBalanceRequest,
  updateBalanceSuccess,
  getBalanceSuccess,
  deleteAllInfoMoneySuccess,
  deleteAllInfoMoneyRequest,
} from "providers/FonehouseMoneyManagement/slice";
import moment from "moment";

function* getAllMoneyManagement(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "from",
    "to",
    "payment",
    "type",
  ]);
  params.from = parseFloat(params.from ?? moment().valueOf());
  params.to = parseFloat(params.to ?? moment().valueOf());
  params.page = parseFloat(params.page);
  params.limit = parseFloat(params.limit);
  const data = yield call(
    request,
    "api/fonehouse/report/list-report-filter",
    params,
    { method: "POST" }
  );
  yield put(getAllMoneyManagementSuccess(data));
}

function* createMoneyManagement(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "type",
    "value",
    "payment",
    "description",
  ]);
  const data = yield call(
    request,
    "api/fonehouse/report/create-report",
    params,
    { method: "POST" }
  );
  yield put(createMoneyManagementSuccess(data, meta));
  Helper.toastr(`ID: #${data._id}`, "Tạo record thu chi thành công", "success");
}

function* updateMoneyManagement(action) {
  const { meta } = action;
  const { id } = action.payload;
  const params = _pick(action.payload, [
    "type",
    "value",
    "payment",
    "description",
  ]);
  params.value = parseFloat(params.value);
  const result = yield call(
    request,
    `api/fonehouse/report/update-report/${id}`,
    params,
    {
      method: "PUT",
    }
  );
  yield put(updateMoneyManagementSuccess(result, meta));
  Helper.toastr(`ID: #${id}`, "Cập nhật record thu chi thành công", "success");
}

function* deleteMoneyManagement(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["id"]);
  const result = yield call(
    request,
    `api/fonehouse/report/delete-report/${params.id}`,
    {},
    { method: "DELETE" }
  );
  yield put(deleteMoneyManagementSuccess(result, meta));
  Helper.toastr("Xóa record thành công", "success");
}

function* deleteMoneyManagementData(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "from",
    "to",
    "id",
    "type",
  ]);
  const { meta } = action;
  params.from = parseFloat(params.from ?? moment().valueOf());
  params.to = parseFloat(params.to ?? moment().valueOf());
  params.page = parseFloat(params.page);
  params.limit = parseFloat(params.limit);
  const result = yield call(
    request,
    "api/fonehouse/report/delete-multil-report",
    params,
    { method: "DELETE" }
  );
  yield put(deleteMoneyManagementDataSuccess(result, meta));
  Helper.toastr("Xóa record thành công", "success");
}

function* updateBalance(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["value"]);
  yield call(request, "api/fonehouse/report/update-balance", params, {
    method: "PUT",
  });
  yield put(updateBalanceSuccess(params.value, meta));
  Helper.toastr("Cập nhật thành công", "success");
}

function* getMoneyManagementRecord(action) {
  const params = _pick(action.payload, ["id"]);
  const data = yield call(
    request,
    `api/fonehouse/report/statiscal/detail?id=${params.id}`,
    {},
    { method: "GET" }
  );
  yield put(getMoneyManagementRecordSuccess(data));
}

function* getBalance(action) {
  const params = _pick(action.payload, ["value"]);
  const data = yield call(request, "api/fonehouse/report/balance", params, {
    method: "GET",
  });
  yield put(getBalanceSuccess(data));
}

function* getOldBalance(action) {
  const params = _pick(action.payload, ["value"]);
  const data = yield call(request, "api/fonehouse/report/old-balance", params, {
    method: "GET",
  });
  yield put(getOldBalanceSuccess(data));
}

function* handleDeleteAllMoneyManageInfo(action) {
  try {
    const { callback } = action.payload;
    const endpoint = "api/fonehouse/report/delete-all-report";
    yield call(request, endpoint, undefined, { method: "DELETE" });
    if (callback && typeof callback === "function") {
      yield callback();
    }
    yield put(deleteAllInfoMoneySuccess());
  } catch (error) {
    yield put(stopLoading());
  }
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchMoneyManagement() {
  yield takeEvery(
    getAllMoneyManagementRequest.type,
    safe(getAllMoneyManagement, onError)
  );
  yield takeEvery(getBalanceRequest.type, safe(getBalance, onError));
  yield takeLeading(
    updateMoneyManagementRequest.type,
    safe(updateMoneyManagement, onError)
  );
  yield takeLeading(
    createMoneyManagementRequest.type,
    safe(createMoneyManagement, onError)
  );
  yield takeLeading(
    deleteMoneyManagementRequest.type,
    safe(deleteMoneyManagement, onError)
  );
  yield takeLeading(updateBalanceRequest.type, safe(updateBalance, onError));
  yield takeLeading(getOldBalanceRequest.type, safe(getOldBalance, onError));
  yield takeLeading(
    deleteMoneyManagementDataRequest.type,
    safe(deleteMoneyManagementData, onError)
  );
  yield takeLeading(
    getMoneyManagementRecordRequest.type,
    safe(getMoneyManagementRecord, onError)
  );
  yield takeLeading(
    deleteAllInfoMoneyRequest.type,
    handleDeleteAllMoneyManageInfo
  );
}
