import { useRef, useEffect } from "react";
import { Col, Row, Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { Formik, Form } from "formik";
import _get from "lodash/get";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  TextInput,
  ImageUpload,
  TextEditor,
  MultiImageUpload,
  Select,
  DatePicker,
} from "components/common";
import Helper from "utils/helpers";
import {
  ALLOW_PAY_VIEGO,
  PROMOTION_ACTIVE,
  WORKING_TIME_TEXT,
} from "utils/constants";
import AutoComplete from "components/common/Form/AutoComplete";
import ChangeWorkingTimeModal from "components/ChangeWorkingTimeModal";
import { setChangeWorkingTimeModal } from "providers/GeneralProvider/slice";
import i18n from "i18n";
import {
  getDiscountConditionsRequest,
  getDiscountPercentagesRequest,
} from "providers/StoreProvider/slice";
import { RadioField } from "components/common/Form";
import { editRestaurantValidate, createRestaurantValidate } from "./validate";
import "./styles.less";

const RestaurantEditForm = ({
  initialValues = {},
  onCancel,
  onSubmit,
  isCreating,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const discountConditions = useSelector(
    (state) => state.store.discountConditions
  );
  const discountPercentages = useSelector(
    (state) => state.store.discountPercentages
  );
  const discountConditionsArr = discountConditions.map((item) => {
    if (item.value === "EVERY_DAY") {
      return {
        value: item.value,
        label: "Hàng ngày",
      };
    }
    if (item.value === "EVERY_DAY_EXCLUDING_HOLIDAYS") {
      return {
        value: item.value,
        label: "Các ngày trong tuần không bao gồm lễ, tết",
      };
    }
    if (item.value === "MONDAY_TO_THURSDAY") {
      return {
        value: item.value,
        label: "Từ thứ 2 tới thứ 5 không bao gồm ngày nghỉ, lễ, tết ",
      };
    }
    if (item.value === "MONDAY_TO_FRIDAY") {
      return {
        value: item.value,
        label: "Từ thứ 2 đến thứ 6 không bao gồm lễ, tết",
      };
    }
    if (item.value === "CUSTOM") {
      return {
        value: item.value,
        label: "Tùy chọn",
      };
    }
    return null;
  });
  useEffect(() => {
    dispatch(getDiscountConditionsRequest());
    dispatch(getDiscountPercentagesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const workingTimeModal = useSelector(
    (state) => state.general.workingTimeModal
  );
  const formattedInitValues = {
    name: _get(initialValues, "name", ""),
    address: _get(initialValues, "address", ""),
    phone: _get(initialValues, "phone", ""),
    banners: null,
    thumbnail: initialValues.thumbnail
      ? {
          url: initialValues.thumbnail,
          objectId: initialValues.thumbnail,
        }
      : null,
    open: Helper.convertMinutesToMoment(_get(initialValues, "open", "")),
    close: Helper.convertMinutesToMoment(_get(initialValues, "close", "")),
    startDay: _get(initialValues, "startDay", ""),
    endDay: _get(initialValues, "endDay", ""),
    description: _get(initialValues, "description", ""),
    workingTimeMethod: _get(initialValues, "workingTimeMethod", ""),
    workingTime: _get(initialValues, "workingTime", []),
    businessImages: _get(initialValues, "businessImages", []),
    discountCondition: _get(initialValues, "discountCondition", {}),
    discountPercentage: _get(initialValues, "discountPercentage", {}),
    isPromotionActive: _get(initialValues, "isPromotionActive", false),
    isAllowedPayWithVieGo: _get(initialValues, "isAllowedPayWithVieGo", false),
    customDiscountCondition: _get(initialValues, "customDiscountCondition", {}),
    start: _get(initialValues, "customDiscountCondition.start"),
    end: _get(initialValues, "customDiscountCondition.end"),
  };

  const handleChangeWorkingTimeModal = (values) => {
    dispatch(
      setChangeWorkingTimeModal({
        isOpen: true,
        data: {
          workingTimeMethod: values.workingTimeMethod,
          workingTime: values.workingTime,
        },
        category: "restaurant",
      })
    );
  };

  const handleChangeWorkingTime = (values) => {
    formikRef.current?.setFieldValue(
      "workingTimeMethod",
      values.workingTimeMethod
    );
    formikRef.current?.setFieldValue("workingTime", values.workingTime);
  };

  return (
    <Formik
      validateOnMount
      initialValues={formattedInitValues}
      enableReinitialize
      validationSchema={
        isCreating ? createRestaurantValidate : editRestaurantValidate
      }
      onSubmit={onSubmit}
      innerRef={formikRef}
    >
      {({ handleSubmit, values, isValid }) => (
        <Form>
          {workingTimeModal.isOpen && (
            <ChangeWorkingTimeModal
              workingTimeModal={workingTimeModal}
              handleChangeWorkingTime={handleChangeWorkingTime}
            />
          )}
          <Row className="restaurant-edit-component-wrapper">
            <Row className="restaurant-edit-info-container">
              <Col span={14} className="info-section">
                {isCreating && (
                  <div className="m-title-text">Thông tin cơ bản</div>
                )}
                <Row style={{ flexWrap: "nowrap" }}>
                  <Col>
                    <ImageUpload name="thumbnail" />
                  </Col>
                  <Col
                    flex="auto"
                    className="restaurant-info"
                    style={{ paddingRight: "6%" }}
                  >
                    <Row className="info-row" gutter={8}>
                      <Col className="info-col" span={16}>
                        <div className="s-text">Tên nhà hàng</div>
                        <TextInput name="name" size="small" required />
                      </Col>
                    </Row>
                    <Row className="info-row" gutter={8}>
                      <Col className="info-col" span={24}>
                        <div className="s-text">Giờ làm việc</div>
                        <Row className="time-working-wrapper">
                          <TextInput
                            className="time-working-title"
                            name="workingTimeMethod"
                            size="small"
                            required
                            disabled
                            value={WORKING_TIME_TEXT[values.workingTimeMethod]}
                          />
                          <SettingOutlined
                            className="setting-icon"
                            onClick={() => handleChangeWorkingTimeModal(values)}
                          />
                        </Row>
                      </Col>
                    </Row>
                    <Row className="info-row" gutter={8}>
                      <Col className="info-col" span={24}>
                        <div className="s-text">Địa chỉ</div>
                        <AutoComplete name="address" size="small" required />
                      </Col>
                    </Row>
                    <Row className="info-row" gutter={8}>
                      <Col className="info-col" span={8}>
                        <div className="s-text">Số điện thoại</div>
                        <TextInput name="phone" size="small" required />
                      </Col>
                    </Row>
                    <Col className="info-row" gutter={8}>
                      <Col className="info-col" span={8}>
                        <div className="s-text">Bật/Tắt giảm giá</div>
                        <RadioField
                          name="isPromotionActive"
                          className="working-time-options"
                          // onChange={handleOnchangeIsPromotionActive}
                          options={PROMOTION_ACTIVE}
                        />
                      </Col>
                    </Col>
                    {values.isPromotionActive && (
                      <>
                        <Col className="info-row" gutter={8}>
                          <Col className="info-col" span={16}>
                            <div className="s-text">Điều kiện khuyến mãi</div>
                            <Select
                              style={{ width: 400 }}
                              name="discountCondition"
                              required
                              options={discountConditionsArr}
                            />
                          </Col>
                        </Col>
                        {(values.discountCondition === "CUSTOM" ||
                          formattedInitValues?.customDiscountCondition
                            ?.start) && (
                          <Col className="info-row" gutter={8}>
                            <Col className="info-col" span={16}>
                              <div className="s-text">
                                Thời gian khuyến mãi tùy chọn
                              </div>
                              <DatePicker
                                label="Giờ bắt đầu"
                                name="start"
                                type="time"
                                format="HH:mm"
                              />
                              <DatePicker
                                label="
                                Giờ kết thúc (Không được set 00:00)"
                                name="end"
                                type="time"
                                format="HH:mm"
                              />
                            </Col>
                          </Col>
                        )}

                        <Col className="info-row" gutter={8}>
                          <Col className="info-col" span={16}>
                            <div className="s-text">Tỉ lệ khuyến mãi</div>
                            <Select
                              style={{ width: 400 }}
                              name="discountPercentage"
                              required
                              options={discountPercentages}
                            />
                          </Col>
                        </Col>
                      </>
                    )}

                    <Col className="info-row" gutter={8}>
                      <Col className="info-col" span={12}>
                        <div className="s-text">Thanh toán bằng điểm VieGo</div>
                        <RadioField
                          name="isAllowedPayWithVieGo"
                          className="working-time-options"
                          // onChange={handleOnchangeIsPromotionActive}
                          options={ALLOW_PAY_VIEGO}
                        />
                      </Col>
                    </Col>
                    <Col className="info-row" gutter={8}>
                      <Row className="info-col" span={12}>
                        <MultiImageUpload
                          label="Ảnh cửa hàng"
                          name="businessImages"
                        />
                      </Row>
                    </Col>
                  </Col>
                </Row>
              </Col>
              {isCreating && (
                <Col span={6} className="banner-section">
                  <div className="m-title-text">Hình banner</div>
                  <ImageUpload name="banners" />
                </Col>
              )}
              <Col flex="auto">
                <Row gutter={8} justify="end">
                  {!isCreating && (
                    <Col>
                      <Button onClick={onCancel} type="primary" size="small">
                        HỦY
                      </Button>
                    </Col>
                  )}
                  <Col>
                    <Button
                      onClick={handleSubmit}
                      size="small"
                      disabled={!isValid}
                    >
                      LƯU
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="business-description-container">
              <div className="title">
                {i18n.t("entertainment.businessDescription")}
              </div>
              <TextEditor name="description" />
            </Row>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
RestaurantEditForm.propTypes = {
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isCreating: PropTypes.bool,
};
export default RestaurantEditForm;
