import PropTypes from "prop-types";
import _isEmpty from "lodash/isEmpty";
import { Formik } from "formik";
import { Button } from "antd";
import { useQuery } from "hooks";
import "./style.less";
import i18n from "i18n";
import FormikOnChange from "./FormikChange";

const SearchBar = ({
  filterFields = [],
  children,
  initVal = {},
  isOnChangeSearch,
}) => {
  const [searchQuery, setSearchQuery] = useQuery();

  const onSubmit = (data) => {
    const query = { ...searchQuery, ...data, page: 1 };
    setSearchQuery(query);
  };

  const initialValues = filterFields.reduce((acc, currentValue) => {
    if (searchQuery[currentValue]) {
      if (
        [
          "roles",
          "status",
          "type",
          "line",
          "productType",
          "productTypeIds",
          "cosmeticProductTypeId",
        ].includes(currentValue) &&
        typeof searchQuery[currentValue] === "string"
      ) {
        acc[currentValue] = [searchQuery[currentValue]];
      } else acc[currentValue] = searchQuery[currentValue];
    } else {
      acc[currentValue] = [
        "roles",
        "status",
        "type",
        "line",
        "productType",
        "productTypeIds",
        "cosmeticProductTypeId",
      ].includes(currentValue)
        ? []
        : "";
    }
    return acc;
  }, {});

  return (
    <div className="search-bar-form">
      <Formik
        validateOnMount
        initialValues={!_isEmpty(filterFields) ? initialValues : initVal}
        onSubmit={onSubmit}
        className="search-bar-form"
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <>
            {children}
            {isOnChangeSearch ? (
              <FormikOnChange onChange={onSubmit} searchFields={filterFields} />
            ) : (
              <Button type="primary" onClick={handleSubmit}>
                {i18n.t("global.search")}
              </Button>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

SearchBar.propTypes = {
  filterFields: PropTypes.array,
  children: PropTypes.object,
  initVal: PropTypes.object,
  isOnChangeSearch: PropTypes.bool,
};
export default SearchBar;
