import { put, takeLeading, call } from "redux-saga/effects";
import request from "utils/api";
import Helper from "utils/helpers";
import _pick from "lodash/pick";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import {
  stopLoading,
  setDecentralizationPopup,
  getListCommentRatingRequest,
  getListCommentRatingSuccess,
  deleteRatingRequest,
  deleteRatingSuccess,
  getUnreadRatingRequest,
  getUnreadRatingSuccess,
  getRatingDetailSuccess,
  getRatingDetailRequest,
  setReadRatingRecordRequest,
  setReadRatingRecordSuccess,
} from "providers/CommentAndRatingProvider/slice";

function* getListCommentRating(action) {
  const params =
    action.payload.rating !== "All"
      ? _pick(action.payload, ["page", "limit", "rating", "keyword"])
      : _pick(action.payload, ["page", "limit", "keyword"]);
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }

  const { data } = yield call(
    request,
    "/api/rating-store/admin/rates",
    params,
    { method: "GET" }
  );
  yield put(getListCommentRatingSuccess(data));
}

function* deleteRatingRecord(action) {
  const { meta } = action;
  const idPost = _get(action, "payload.idPost");
  yield call(request, `/api/rating-store/admin/rates/${idPost}`, undefined, {
    method: "DELETE",
  });
  yield put(deleteRatingSuccess(meta));
  yield put(setDecentralizationPopup({ isOpen: false }));
  Helper.toastr(`Thông báo`, "Xoá đánh giá thành công", "success");
}

function* getUnreadRating() {
  const { data } = yield call(
    request,
    "/api/rating-store/admin/rates/unread",
    undefined,
    { method: "GET" }
  );
  yield put(getUnreadRatingSuccess(data));
}

function* getRatingDetail(action) {
  const idPost = _get(action, "payload.idPost");
  const { data } = yield call(
    request,
    `/api/rating-store/admin/rates/${idPost}`,
    undefined,
    { method: "GET" }
  );
  yield put(getRatingDetailSuccess(data));
}

function* setReadRatingRecord(action) {
  const { meta } = action;
  const idPost = _get(action, "payload.idPost");
  yield call(
    request,
    `/api/rating-store/admin/rates/read/${idPost}`,
    undefined,
    { method: "PUT" }
  );
  yield put(setReadRatingRecordSuccess(meta));
}
function* onError() {
  yield put(stopLoading());
}

export default function* watchAuth() {
  yield takeLeading(
    getListCommentRatingRequest.type,
    safe(getListCommentRating, onError)
  );
  yield takeLeading(
    deleteRatingRequest.type,
    safe(deleteRatingRecord, onError)
  );
  yield takeLeading(
    getUnreadRatingRequest.type,
    safe(getUnreadRating, onError)
  );
  yield takeLeading(
    getRatingDetailRequest.type,
    safe(getRatingDetail, onError)
  );
  yield takeLeading(
    setReadRatingRecordRequest.type,
    safe(setReadRatingRecord, onError)
  );
}
