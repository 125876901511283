import { Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  lockStoreRequest,
  pinPromotionalRequest,
  unlockStoreRequest,
} from "providers/StoreProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";

const MenuOverlay = ({ record, isAdminPermission, refetchList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { _id, status, name, pin } = record;

  return (
    <Menu>
      <Menu.Item
        onClick={() => {
          navigate(`${location.pathname}/${_id}`, {
            state: { canGoBack: true },
          });
        }}
      >
        Xem thông tin
      </Menu.Item>
      {isAdminPermission && (
        <>
          <Menu.Item
            onClick={() => {
              dispatch(
                setPopup({
                  isOpen: true,
                  data: {
                    okText: "Xác nhận",
                    cancelText: "Hủy",
                    title: `Xác nhận ${
                      pin === 0 ? "ghim" : "bỏ ghim"
                    } doanh nghiệp`,
                    content: `Bạn có muốn ${
                      pin === 0 ? "ghim" : "bỏ ghim"
                    } ${name}`,
                    onOk: () => {
                      dispatch(
                        pinPromotionalRequest({
                          _id,
                          pin: pin === 0 ? 1 : 0,
                        })
                      ).then(() => {
                        refetchList();
                      });
                    },
                  },
                })
              );
            }}
          >
            {pin === 0 ? "Ghim quảng cáo" : "Bỏ ghim quảng cáo"}
          </Menu.Item>
          <Menu.Item
            className={status === "active" ? "" : "unlock-bussiness-btn"}
            disabled={status === "active"}
            onClick={() => {
              dispatch(
                setPopup({
                  isOpen: true,
                  data: {
                    okText: "Xác nhận",
                    cancelText: "Huỷ",
                    title: "Xác nhận mở khóa doanh nghiệp",
                    content: `Bạn có muốn mở khóa ${name}`,
                    onOk: () => {
                      dispatch(
                        unlockStoreRequest({
                          storeIds: [_id],
                        })
                      ).then(() => {
                        refetchList();
                      });
                    },
                  },
                })
              );
            }}
          >
            Mở khóa doanh nghiệp
          </Menu.Item>
          <Menu.Item
            className={status === "lock" ? "" : "lock-bussiness-btn"}
            disabled={status === "lock"}
            onClick={() => {
              dispatch(
                setPopup({
                  isOpen: true,
                  data: {
                    okText: "Xác nhận",
                    cancelText: "Hủy",
                    title: "Xác nhận khóa doanh nghiệp",
                    content: `Bạn có muốn khóa ${name}`,
                    onOk: () => {
                      dispatch(
                        lockStoreRequest({
                          storeIds: [_id],
                        })
                      ).then(() => {
                        refetchList();
                      });
                    },
                  },
                })
              );
            }}
          >
            Khóa doanh nghiệp
          </Menu.Item>
        </>
      )}
    </Menu>
  );
};

export default MenuOverlay;
