import { useEffect, useState } from "react";
import { Row, Typography, DatePicker, Select } from "antd";
import _get from "lodash/get";
import { HistoryEditColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector } from "react-redux";
import { useQuery } from "hooks";
import { getAllHistoryEditRequest } from "providers/FonehouseHistoryEdit/slice";
import "./styles.less";
import i18n from "i18n";
import moment from "moment";
import SearchBar from "./SearchBar";

const { Option } = Select;
const HistoryEdit = () => {
  // const dispatch = useDispatch();
  const [type, setType] = useState("created");
  const [dateFrom, setDateFrom] = useState(moment());
  const [dateTo, setDateTo] = useState(moment());
  const historyEditList = useSelector((state) =>
    _get(state, "historyEditFonehouse.docs")
  );
  const historyEditTotal = useSelector((state) =>
    _get(state, "historyEditFonehouse.totalDocs")
  );
  const isLoading = useSelector((state) =>
    _get(state, "historyEditFonehouse.isLoading")
  );
  const [searchObject, setSearch] = useQuery(getAllHistoryEditRequest, {
    params: ["from", "to", "type", "keyword"],
  });
  useEffect(() => {
    setSearch({
      ...searchObject,
      from: moment(dateFrom).valueOf(),
      to: moment(dateTo).valueOf(),
      type,
    });
    // dispatch(getAllHistoryEditRequest({ from: moment(dateFrom).valueOf(), to: moment(dateTo).valueOf(), type: type, page: 1, limit: 10 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  const columns = [
    HistoryEditColumn.createdAt,
    HistoryEditColumn.account,
    HistoryEditColumn.email,
    HistoryEditColumn.time,
    HistoryEditColumn.action,
    HistoryEditColumn.id,
  ];
  const handleChangeSelectType = (_type) => {
    setType(_type);
  };
  const handleOnchangeDateFrom = (_dateFrom) => {
    setDateFrom(_dateFrom);
  };
  const handleOnchangeDateTo = (_dateTo) => {
    setDateTo(_dateTo);
  };
  // const handleSubmitFilter = () => {
  //   dispatch(getAllHistoryEditRequest({ from: moment(dateFrom).valueOf(), to: moment(dateTo).valueOf(), transactionType: type, page: 1, limit: 10 }));
  // };
  const renderFilterHistoryEdit = () => (
    <Row
      wrap={false}
      style={{ marginRight: 10 }}
      justify="center"
      align="middle"
    >
      <Select
        defaultValue="created"
        style={{ width: 150 }}
        onChange={handleChangeSelectType}
      >
        <Option value="created">Tạo mới</Option>
        <Option value="update">Chỉnh sửa</Option>
      </Select>
      <div style={{ marginRight: 10, marginLeft: 10 }}> Từ </div>
      <DatePicker
        allowClear={false}
        defaultValue={dateFrom}
        placeholder="Chọn ngày"
        onChange={handleOnchangeDateFrom}
      />
      <div style={{ marginRight: 10, marginLeft: 10 }}> Đến </div>
      <DatePicker
        allowClear={false}
        defaultValue={dateTo}
        placeholder="Chọn ngày"
        onChange={handleOnchangeDateTo}
      />
      {/* <Button onClick={handleSubmitFilter} style={{ marginLeft: 10 }} type="primary">Lọc</Button> */}
    </Row>
  );

  return (
    <div className="historyEdit-fonehouse-list-wrapper">
      <Row
        className="historyEdit-fonehouse-list-header"
        justify="space-between"
      >
        <Typography.Title level={4}>
          {i18n.t("historyEditFonehouse.listTitle")}
        </Typography.Title>
        {renderFilterHistoryEdit()}
      </Row>
      <div className="content-container">
        <SearchBar />
        <Table
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={historyEditList}
          rowKey={(data) => data._id}
          total={historyEditTotal}
        />
      </div>
    </div>
  );
};

export default HistoryEdit;
