import { combineReducers } from "redux";
import auth from "providers/AuthProvider/slice";
import post from "providers/PostProvider/slice";
import general from "providers/GeneralProvider/slice";
import admin from "providers/AdminProvider/slice";
import news from "providers/NewsProvider/slice";
import visa from "providers/VisaProvider/slice";
import product from "providers/ProductProvider/slice";
import business from "providers/BusinessProvider/slice";
import newsFonehouse from "providers/NewsFonehouseProvider/slice";
import marketProduct from "providers/MarketProductProvider/slice";
import restaurantProduct from "providers/RestaurantProductProvider/slice";
import adminManager from "providers/AdminManagerProvider/slice";
import reportManager from "providers/ReportManagerProvider/slice";
import billingList from "providers/BillingProvider/slice";
import banner from "providers/BannerProvider/slice";
import guideFonehouse from "providers/GuideFonehouseProvider/slice";
import statisticalFonehouse from "providers/FonehouseStatistical/slice";
import moneyManagementFonehouse from "providers/FonehouseMoneyManagement/slice";
import historyEditFonehouse from "providers/FonehouseHistoryEdit/slice";
import messenger from "providers/MessengerProvider/slice";
import notification from "providers/NotificationProvider/slice";
import entertainmentProduct from "providers/EntertainmentProductProvider/slice";
import cosmeticProduct from "providers/CosmeticProductProvider/slice";
import support from "providers/SupportProvider/slice";
import ratingComment from "providers/CommentAndRatingProvider/slice";
import store from "providers/StoreProvider/slice";
import sim from "providers/SimProvider/slice";
import identifyUser from "providers/IdentifyUserProvider/slice";
import rewardPointManagerSlice from "providers/RewardPointManager/slice";
import accessorySlice from "providers/AccessoryProvider/slice";
import liquidWithViegoManager from "providers/LiquidWithViegoProvider/slice";

export default combineReducers({
  auth,
  post,
  general,
  admin,
  news,
  product,
  business,
  newsFonehouse,
  marketProduct,
  restaurantProduct,
  adminManager,
  reportManager,
  billingList,
  banner,
  guideFonehouse,
  statisticalFonehouse,
  moneyManagementFonehouse,
  historyEditFonehouse,
  messenger,
  notification,
  entertainmentProduct,
  cosmeticProduct,
  ratingComment,
  support,
  store,
  sim,
  visa,
  identifyUser,
  rewardPointManagerSlice,
  accessorySlice,
  liquidWithViegoManager,
});
