import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import Helper from "utils/helpers";
import {
  confirmBusinessRequest,
  confirmBusinessSuccess,
  getBusinessByIdRequest,
  getBusinessByIdSuccess,
  getBusinessListRequest,
  getBusinessListSuccess,
  stopLoading,
} from "./slice";

function* getBusinessList(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "keyword",
    "roles",
    "sortTitle",
    "status",
    "seen",
  ]);
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  const { data } = yield call(
    request,
    "api/fonehouse/business-manager/business/get-all",
    params,
    { method: "GET" }
  );
  yield put(getBusinessListSuccess(data));
}

function* getBusinessById(action) {
  const params = _pick(action.payload, ["idBusiness"]);
  const data = yield call(
    request,
    "api/fonehouse/business-manager/business?",
    params,
    { method: "GET" }
  );
  yield put(getBusinessByIdSuccess(data));
}

function* confirmBusiness(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idBusinesses", "status"]);
  const { data } = yield call(
    request,
    "api/fonehouse/business-manager/business/update-status",
    params,
    { method: "PUT" }
  );
  yield put(confirmBusinessSuccess(data, meta));
  Helper.toastr(`ID:${params.idBusinesses}`, "Cấp quyền thành công", "success");
}
function* onError() {
  yield put(stopLoading());
}

export default function* watchBusiness() {
  yield takeEvery(getBusinessListRequest.type, safe(getBusinessList, onError));
  yield takeLeading(
    confirmBusinessRequest.type,
    safe(confirmBusiness, onError)
  );
  yield takeLeading(
    getBusinessByIdRequest.type,
    safe(getBusinessById, onError)
  );
}
