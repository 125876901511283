import PropTypes from "prop-types";
import { Modal } from "antd";
import _get from "lodash/get";
import BackIcon from "assets/images/back.svg";
import { useSelector, useDispatch } from "react-redux";
import { MARKET_PRODUCT_MODAL_TYPE } from "utils/constants";
import { useParams } from "react-router-dom";
import {
  createProductMarketRequest,
  getMarketProductListRequest,
  updateProductMarketRequest,
  deleteProductMarketRequest,
} from "providers/MarketProductProvider/slice";
import i18n from "i18n";
import MarketProductDetail from "./marketProductDetail";
import MarketProductForm from "./marketProductForm";
import "./styles.less";

const MarketProductModal = (props) => {
  const { setMarketProductModal, searchObject, isPermissionToEdit, setSearch } =
    props;
  const dispatch = useDispatch();
  const marketProductModalData = useSelector(
    (state) => state.marketProduct.marketProductModalData
  );
  const productMarketTypeList = useSelector((state) =>
    _get(state, "marketProduct.productMarketTypeList")
  );

  const { visible, data = {}, type } = marketProductModalData;
  const { idMarket } = useParams();

  const handleCloseModal = () => {
    dispatch(
      setMarketProductModal({
        visible: false,
        data: {},
        type: "",
      })
    );
  };

  const handleEditMarketProduct = (_data) => {
    dispatch(
      setMarketProductModal({
        visible: true,
        data: _data,
        type: MARKET_PRODUCT_MODAL_TYPE.EDIT,
      })
    );
  };

  const handleDeleteMarketProduct = () => {
    dispatch(
      deleteProductMarketRequest({
        storeId: idMarket,
        productIds: [data._id],
      })
    ).then(() => {
      dispatch(
        setMarketProductModal({
          visible: false,
          data: {},
          type: "",
        })
      );
      dispatch(getMarketProductListRequest(searchObject));
    });
  };

  const handleSubmit = (values) => {
    const idProductMarket = data._id;
    const { productMarketId } = data;
    if (type === MARKET_PRODUCT_MODAL_TYPE.CREATE) {
      dispatch(
        createProductMarketRequest({
          ...values,
          marketId: idMarket,
        })
      ).then((_data) => {
        dispatch(
          setMarketProductModal({
            visible: true,
            data: {
              ..._data,
              productType: productMarketTypeList.find(
                (item) => item._id === _data.productCategory
              ),
            },
            type: MARKET_PRODUCT_MODAL_TYPE.DETAIL,
          })
        );
        dispatch(getMarketProductListRequest(searchObject));
      });
    } else {
      dispatch(
        updateProductMarketRequest({
          ...values,
          marketId: idMarket,
          idProductMarket,
          productMarketId,
        })
      ).then((_data) => {
        dispatch(
          setMarketProductModal({
            visible: true,
            data: {
              ..._data,
              productType: productMarketTypeList.find(
                (item) => item._id === _data.productCategory
              ),
              productMarketId,
            },
            type: MARKET_PRODUCT_MODAL_TYPE.DETAIL,
          })
        );
        dispatch(getMarketProductListRequest(searchObject));
      });
    }
  };

  return (
    <div>
      {visible && (
        <Modal
          title={i18n.t("marketProduct.productDetail")}
          visible={visible}
          width={860}
          className="market-product-modal-wrapper"
          footer={null}
          onCancel={handleCloseModal}
          maskClosable={false}
          closeIcon={<img src={BackIcon} alt="" />}
        >
          {type === MARKET_PRODUCT_MODAL_TYPE.DETAIL ? (
            <MarketProductDetail
              marketProductDetail={data}
              setMarketProductModal={setMarketProductModal}
              handleDeleteMarketProduct={handleDeleteMarketProduct}
              handleEditMarketProduct={handleEditMarketProduct}
              isPermissionToEdit={isPermissionToEdit}
            />
          ) : (
            <MarketProductForm
              type={type}
              setMarketProductModal={setMarketProductModal}
              productMarketTypeList={productMarketTypeList}
              onSubmit={handleSubmit}
              searchObject={searchObject}
              initialValues={data}
              setSearch={setSearch}
            />
          )}
        </Modal>
      )}
    </div>
  );
};
MarketProductModal.propTypes = {
  setMarketProductModal: PropTypes.func,
  searchObject: PropTypes.object,
  isPermissionToEdit: PropTypes.bool,
  setSearch: PropTypes.func,
};

export default MarketProductModal;
