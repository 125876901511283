import { put, takeEvery, call } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import { safe } from "providers/GeneralProvider/saga";
import {
  getPostListRequest,
  getPostListSuccess,
  stopLoading,
} from "providers/PostProvider/slice";

function* handleLogin(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "search",
    "companyId",
    "storeId",
  ]);
  const { result } = yield call(request, "functions/op_getAgentList", params);
  yield put(getPostListSuccess(result));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchAuth() {
  yield takeEvery(getPostListRequest.type, safe(handleLogin, onError));
}
