import PropTypes from "prop-types";
import FailIcon from "../../assets/images/FailIcon.png";
import SuccessIcon from "../../assets/images/SuccessIcon.png";
import OutDateIcon from "../../assets/images/OutDateIcon.png";

import "./styles.less";

const StatusBlock = (props) => {
  const { status, showText = false } = props;
  const statusIcon = {
    NEW: OutDateIcon,
    CANCEL: FailIcon,
    APPROVED: SuccessIcon,
  };
  const statusText = {
    NEW: "Đang chờ",
    CANCEL: "Từ chối",
    APPROVED: "Đã duyệt",
  };
  return (
    <div className="status-block-wrapper flex">
      <img src={statusIcon[status]} alt="" />
      {showText && <div className="status-text">{statusText[status]}</div>}
    </div>
  );
};

StatusBlock.propTypes = {
  status: PropTypes.string,
  showText: PropTypes.bool,
};
export default StatusBlock;
