import { Row, Typography, Button, Dropdown, Menu } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _uniq from "lodash/uniq";
import { useNavigate, Link } from "react-router-dom";

import { NewsColumn } from "components/Columns";
import Table from "components/common/Table";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "hooks";
import {
  getAllNewsRequest,
  setSelectedNewsIds,
  updateStatusNewsRequest,
} from "providers/NewsProvider/slice";

import "./styles.less";
import { IconSeeMore } from "assets/images";
import i18n from "i18n";
import SearchBar from "./SearchBar";

const NewsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const agentList = useSelector((state) => _get(state, "news.docs"));
  const agentTotal = useSelector((state) => _get(state, "news.totalDocs"));
  const isLoading = useSelector((state) => _get(state, "news.isLoading"));
  const userId = useSelector((state) => _get(state, "auth.currentUser._id"));
  const selectedRowKeys = useSelector((state) =>
    _get(state, "news.selectedRowKeys", [])
  );
  const selectedRows = useSelector((state) =>
    _get(state, "news.selectedRows", [])
  );
  const isDisableApproveBtn = !!selectedRows.find(
    (item) => item.status === "approved"
  );
  const isDisableRefuseBtn = !!selectedRows.find(
    (item) => item.status === "refuse"
  );
  const newsIdList = [];
  selectedRows.map((item) => newsIdList.push(item.newsId));

  const [searchObject] = useQuery(getAllNewsRequest, {
    arrayParams: ["status"],
  });

  const menuAll = () => (
    <Menu>
      <Menu.Item
        disabled={isDisableApproveBtn || _isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            updateStatusNewsRequest({
              status: "approved",
              idNews: selectedRowKeys,
              newsId: _uniq(newsIdList),
            })
          ).then(() => dispatch(getAllNewsRequest(searchObject)))
        }
      >
        {i18n.t("newScreen.publishNews")}
      </Menu.Item>
      <Menu.Item
        disabled={isDisableRefuseBtn || _isEmpty(selectedRowKeys)}
        onClick={() =>
          dispatch(
            updateStatusNewsRequest({
              status: "refuse",
              idNews: selectedRowKeys,
              newsId: _uniq(newsIdList),
            })
          ).then(() => dispatch(getAllNewsRequest(searchObject)))
        }
      >
        {i18n.t("newScreen.unPublishNews")}
      </Menu.Item>
    </Menu>
  );
  const menu = (record) => {
    const { _id, newsId } = record;
    return (
      <Menu>
        <Menu.Item>
          <Link to={`/news/${_id}`} state={{ canGoBack: true }}>
            {i18n.t("newScreen.newDetail")}
          </Link>
        </Menu.Item>
        <Menu.Item
          disabled={record.status === "approved"}
          onClick={() =>
            dispatch(
              updateStatusNewsRequest({
                status: "approved",
                idNews: [_id],
                newsId: [newsId],
              })
            ).then(() => dispatch(getAllNewsRequest(searchObject)))
          }
        >
          {i18n.t("newScreen.publishNews")}
        </Menu.Item>
        <Menu.Item
          disabled={record.status === "refuse"}
          onClick={() =>
            dispatch(
              updateStatusNewsRequest({
                status: "refuse",
                idNews: [_id],
                newsId: [newsId],
              })
            ).then(() => dispatch(getAllNewsRequest(searchObject)))
          }
        >
          {i18n.t("newScreen.unPublishNews")}
        </Menu.Item>
      </Menu>
    );
  };
  const actionButtons = (record) => (
    <Dropdown overlay={() => menu(record)} placement="bottomLeft" arrow>
      <Button
        className="see-more-btn"
        type="ghost"
        icon={<img src={IconSeeMore} alt="see-more" />}
      />
    </Dropdown>
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, _selectedRows) => {
      dispatch(
        setSelectedNewsIds({ record, selected, selectedRows: _selectedRows })
      );
    },
    onSelectAll: (selected, _selectedRows, changeRows) => {
      dispatch(
        setSelectedNewsIds({
          selected,
          selectedRows: _selectedRows,
          changeRows,
        })
      );
    },
  };

  const columns = [
    NewsColumn.id,
    NewsColumn.createdAt,
    NewsColumn.title,
    NewsColumn.type,
    NewsColumn.username,
    NewsColumn.status,
    {
      title: (
        <Dropdown overlay={menuAll} placement="bottomLeft" arrow>
          <Button
            size="large"
            className="see-more-btn"
            type="ghost"
            icon={<img src={IconSeeMore} alt="see-more" />}
          />
        </Dropdown>
      ),
      width: 100,
      render: (record) => actionButtons(record),
    },
  ];

  return (
    <div className="artcle-list-wrapper">
      <Row className="artcle-list-header" justify="space-between">
        <Typography.Title level={4}>Danh sách tin tức</Typography.Title>
        <Button
          size="large"
          type="primary"
          onClick={() =>
            navigate("/news/news-create", { state: { canGoBack: true } })
          }
        >
          Đăng bài
        </Button>
      </Row>
      <div className="content-container">
        <SearchBar />
        <Table
          rowSelection={rowSelection}
          scroll={{ x: 1000 }}
          loading={isLoading}
          bordered
          columns={columns}
          dataSource={agentList}
          rowKey={(data) => data._id}
          total={agentTotal}
          rowClassName={(data) =>
            _get(data, "seen", []).includes(userId)
              ? "news-readed"
              : "news-unread"
          }
          onRow={(record) => ({
            onDoubleClick: () => {
              navigate(`/news/${record._id}`, { state: { canGoBack: true } });
            },
          })}
        />
      </div>
    </div>
  );
};

export default NewsList;
