import { useDispatch, useSelector } from "react-redux";
import _get from "lodash/get";
import { Modal } from "antd";
import { setDecentralizationPopup } from "providers/AdminProvider/slice";
import { LeftArrowIcon, UserIcon } from "assets/images";
import DecentralizationForm from "./DecentralizationForm";
import DecentralizationUserForm from "./DecentralizationUserForm";

import "./style.less";

const DecentralizationModal = () => {
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.admin.decentralizationModal);
  const isOpen = _get(modalData, "isOpen", false);
  const useInfo = _get(modalData, "data", {});
  const modalProps = _get(modalData, "data", {});
  const formName = _get(modalData, "form", "");
  const { name, phone, roles, _id, address } = useInfo;
  const { okButtonProps, onOk, onCancel, ...props } = modalProps;

  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
    dispatch(setDecentralizationPopup({ isOpen: false }));
  };

  return (
    <Modal
      visible={isOpen}
      afterClose={() =>
        dispatch(setDecentralizationPopup({ data: {}, isOpen: false }))
      } // Remove popup data when popup is close
      footer={null}
      closable={false}
      centered
      className="decentralization-popup-wrapper"
      {...props}
    >
      <div className="decentralization-popup-container">
        <div className="decentralization-popup-header">
          <img
            onClick={handleCancel}
            className="icon-close"
            src={LeftArrowIcon}
            alt=""
          />
          <div className="header-content">Phân quyền User </div>
        </div>
        <div className="decentralization-popup-body">
          <div className="user-info-container">
            <div className="title">Thông tin cơ bản</div>
            <div className="user-info-detail">
              <div className="user-avatar">
                <img
                  src={_get(useInfo, "userDetail.avatar", "") || UserIcon}
                  alt=""
                />
              </div>
              <div className="user-name-contact-contaier">
                <div className="user-name">{name}</div>
                <div className="user-contact">
                  {phone && <div className="phone">Phone: {phone} </div>}
                  {address && <div>Adress: {address}</div>}
                </div>
              </div>
            </div>
          </div>
          <div className="roles-list-container">
            {formName === "decentralizationForm" && (
              <DecentralizationForm roles={roles} idUser={_id} />
            )}
            {formName === "decentralizationUserForm" && (
              <DecentralizationUserForm roles={roles} idUser={_id} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DecentralizationModal;
