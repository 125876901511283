import { useEffect, useMemo } from "react";
import { Col, Row, Typography, Button } from "antd";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useParams, useNavigate } from "react-router-dom";
import ProductCreatForm from "components/Product/CreateForm";
import i18n from "i18n";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "components/BackButton";
import {
  setCustomizeProductTypeModal,
  getProductDetailRequest,
  updateProductRequest,
  deleteProductRequest,
  resetProductDetai,
} from "providers/ProductProvider/slice";

import {
  PROMOTION_TYPE,
  DEFAULT_CONTACT,
  DEFAULT_ADDRESS,
} from "utils/constants";
import { setPopup } from "providers/GeneralProvider/slice";
import "./styles.less";
import moment from "moment";
import CustomizeModal from "../CustomizeModal";

const ProductCreate = () => {
  const dispatch = useDispatch();
  const productDetail = useSelector((state) =>
    _get(state, "product.productDetail", {})
  );
  const productTypes = useSelector((state) =>
    _get(state, "product.productTypes", [])
  );
  const isCreating = useSelector((state) => _get(state, "product.isCreating"));
  const valueTypeList = useMemo(
    () => productTypes.map((element) => element._id),
    [productTypes]
  );

  const { idProduct } = useParams();
  const navigate = useNavigate();

  const handleUpdateProduct = (values) => {
    let ram = "";
    let os = "";
    if (values.ram) {
      ram = `${values.ram}`.concat(values.ramType);
    }
    if (!_isEmpty(values.softwareVersion)) {
      os = values.os;
    }
    dispatch(updateProductRequest({ ...values, idProduct, ram, os })).then(() =>
      navigate(-1)
    );
  };

  const handleDeleteProduct = () => {
    dispatch(
      setPopup({
        isOpen: true,
        data: {
          title: "Xác nhận xóa sản phẩm",
          content: "Xác nhận xóa sản phẩm",
          onOk: () =>
            dispatch(deleteProductRequest({ idProduct: [idProduct] })).then(
              () => navigate(-1)
            ),
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getProductDetailRequest({ idProduct }));
  }, [idProduct, dispatch]);

  useEffect(() => {
    if (idProduct !== productDetail._id) {
      dispatch(resetProductDetai());
    }
  }, [idProduct, productDetail._id, dispatch]);

  const handleOpenCustomizeProductTypeModal = () => {
    dispatch(
      setCustomizeProductTypeModal({
        visible: true,
        data: { productTypes },
      })
    );
  };

  const initialValues = {
    ...productDetail,
    address: DEFAULT_ADDRESS,
    contact: DEFAULT_CONTACT,
    price:
      productDetail.price > 0 ? productDetail.price : productDetail.priceJPY,
    inputPrice:
      productDetail.inputPrice > 0
        ? productDetail.inputPrice
        : productDetail.inputPriceJPY,
    promotion:
      productDetail.promotion > 0
        ? productDetail.promotion
        : productDetail.promotionJPY,
    entryDate: productDetail.entryDate ? moment(productDetail.entryDate) : null,
    discountType:
      productDetail.promotion > 0 || productDetail.promotionJPY > 0
        ? PROMOTION_TYPE.CASH
        : PROMOTION_TYPE.PERCENT,
    type: valueTypeList.includes(productDetail.type) ? productDetail.type : "",
    ram: productDetail.ram ? parseFloat(productDetail.ram) : "",
    ramType: productDetail.ram
      ? productDetail.ram.replace(/[^A-Za-z]/g, "")
      : "",
    videos: productDetail.videos || [],
    guarantee: productDetail.guarantee ? `${productDetail.guarantee}` : "",
    promotionStartDate: productDetail.promotionStartDate
      ? moment(productDetail.promotionStartDate)
      : null,
    promotionExpirationDate: productDetail.promotionExpirationDate
      ? moment(productDetail.promotionExpirationDate)
      : null,
  };

  return (
    <div className="product-detail-page">
      <CustomizeModal />
      <Row className="product-detail-header" justify="space-between">
        <Col className="back-btn">
          <BackButton />
          <Typography.Title level={4}>
            {i18n.t("productDetail.productDetail")}
          </Typography.Title>
        </Col>
        <Col>
          <Button onClick={handleOpenCustomizeProductTypeModal} type="primary">
            TÙY CHỈNH
          </Button>
        </Col>
      </Row>
      <div className="form-section">
        <Row justify="center">
          <Col span={20} className="form-wrapper">
            <ProductCreatForm
              onSubmit={handleUpdateProduct}
              title="Sản phẩm chi tiết"
              initialValues={initialValues}
              loading={isCreating}
              onDelete={handleDeleteProduct}
              valueTypeList={valueTypeList}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProductCreate;
