const IconChecked = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.175"
    height="24.175"
    viewBox="0 0 24.175 24.175"
  >
    <path
      id="Icon_awesome-check-circle"
      data-name="Icon awesome-check-circle"
      d="M24.737,12.65A12.087,12.087,0,1,1,12.65.563,12.087,12.087,0,0,1,24.737,12.65Zm-13.485,6.4,8.968-8.968a.78.78,0,0,0,0-1.1l-1.1-1.1a.78.78,0,0,0-1.1,0L10.7,15.19,7.286,11.775a.78.78,0,0,0-1.1,0l-1.1,1.1a.78.78,0,0,0,0,1.1l5.069,5.069a.78.78,0,0,0,1.1,0Z"
      transform="translate(-0.563 -0.563)"
      fill="#2fbe50"
    />
  </svg>
);

export default IconChecked;
