import PropTypes from "prop-types";
import _get from "lodash/get";
import "./style.less";

export const SimSummaryTooltip = ({ detail }) => {
  const { name, type, price } = detail;

  return (
    <div className="sim-summary-tooltip-wrapper">
      <div className="bold">{name}</div>
      <div>
        <span className="bold">Loại: </span>
        {_get(type, "name")}
      </div>
      <div>
        <span className="bold">Tổng tiền: </span>
        {price.toLocaleString()} JPY
      </div>
    </div>
  );
};

SimSummaryTooltip.propTypes = {
  detail: PropTypes.object,
};

export default SimSummaryTooltip;
