import PropTypes from "prop-types";
import "./style.less";
import { Image } from "antd";
import { PlaceHolderIMG } from "assets/images";

const FoneHouseProductTooltip = (props) => {
  const { data } = props;
  return (
    <div className="fonehouse-product-tooltip-wrapper">
      <Image fallback={PlaceHolderIMG} width={200} src={data.images[0]} />
    </div>
  );
};

FoneHouseProductTooltip.propTypes = {
  data: PropTypes.string,
};
export default FoneHouseProductTooltip;
