import PropTypes from "prop-types";
import { Switch } from "antd";

const SwitchSelector = (props) => {
  const { checked = false, onClick, ...otherProps } = props;
  return (
    <div className="switch-field-container">
      <Switch checked={checked} onClick={onClick} {...otherProps} />
    </div>
  );
};

SwitchSelector.propTypes = {
  checked: PropTypes.bool,
  otherProps: PropTypes.object,
  onClick: PropTypes.func,
};

export default SwitchSelector;
