import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Helper from "utils/helpers";
import PropTypes from "prop-types";

const PublicRoutes = ({ Component, ...props }) => {
  const navigate = useNavigate();
  const token = Helper.getAuthToken();
  useEffect(() => {
    if (token) {
      navigate("/home", { replace: true });
    }
  }, [token, navigate]);

  return <Component {...props} />;
};

PublicRoutes.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

export default PublicRoutes;
