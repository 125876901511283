import { memo, forwardRef, useState, useEffect, useRef } from "react";
import { useField } from "formik";
import _isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import Empty from "antd/lib/empty";
import Tree from "antd/lib/tree";
import Form from "antd/lib/form";
// import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Checkbox, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { IconFilter } from "assets/images";
// const { Option } = Select;

const FilterField = forwardRef(
  ({
    label,
    name,
    className,
    options = [],
    required,
    popConfirmClassName = "",
    hasSearching = false,
    treeFilter = false,
  }) => {
    const [field, meta, helper] = useField(name);
    const isError = meta.touched && meta.error;
    const [selected, setSelected] = useState(field.value || []);
    const [, setVisible] = useState(false);
    const searchRef = useRef(null);
    // useOnClickOutside(popconfirmRef, () => {setSelected(field.value); setVisible(false)});

    useEffect(() => {
      setSelected(field.value || []);
    }, [field.value]);
    const searchOptions = () => {
      const filter = searchRef.current.state.value.toUpperCase();
      const div = document.getElementById("list-options");
      const _label = div.getElementsByTagName("label");
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < _label.length; i++) {
        const span = label[i].getElementsByTagName("span")[2];
        const txtValue = span.textContent || span.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          label[i].style.display = "";
        } else {
          label[i].style.display = "none";
        }
      }
    };

    const _renderOptions = () => {
      if (treeFilter) {
        return (
          <Tree
            treeData={options}
            checkable
            onCheck={(checkedKeys) => {
              setSelected(checkedKeys);
            }}
            selectable={false}
            height={300}
            checkedKeys={selected}
          />
        );
      }

      return (
        <>
          {!_isEmpty(options) && hasSearching && (
            <Input
              type="text"
              prefix={<SearchOutlined />}
              id="searchOption"
              ref={searchRef}
              onKeyUp={searchOptions}
              placeholder="Tìm kiếm..."
              title="Type in a name"
            />
          )}
          <div id="list-options">
            {_isEmpty(options) && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {options.map((option) => (
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked === true) {
                    setSelected((oldSelected) =>
                      oldSelected.concat([option.value])
                    );
                  } else {
                    setSelected((oldSelected) =>
                      oldSelected.filter(
                        (selectedOpt) => selectedOpt !== option.value
                      )
                    );
                  }
                }}
                key={option.value}
                checked={(selected || []).includes(option.value)}
                id="checkboxId"
              >
                {option.label}
              </Checkbox>
            ))}
          </div>
        </>
      );
    };
    return (
      <div className={`input-container filter-field-container ${className}`}>
        {label && (
          <div className="label-wrapper">
            <>{label}</>
            {required && <div className="required-mark"> *</div>}
          </div>
        )}
        <Form.Item
          validateStatus={isError ? "error" : ""}
          help={
            null
            // <div className="helper-wrapper">
            //   <div className="error-text">
            //     {isError && (
            //       <>
            //         <InfoCircleOutlined
            //           className="info-icon"
            //           type="info-circle"
            //         />
            //         {meta.error}
            //       </>
            //     )}
            //   </div>
            // </div>
          }
        >
          <Popconfirm
            overlayClassName={popConfirmClassName}
            placement="bottomRight"
            title={_renderOptions()}
            onConfirm={() => {
              setVisible(false);
              helper.setValue(selected);
            }}
            onCancel={() => {
              setVisible(false);
              helper.setValue([]);
              if (hasSearching) {
                searchRef.current.state.value = "";
                searchOptions();
              }
            }}
            okText="OK"
            cancelText="Reset"
            icon={null}
            onVisibleChange={(visible, e) => {
              if (!e) {
                setSelected(field.value);
              }
            }}
          >
            <Button
              size="large"
              icon={<IconFilter />}
              style={{ fontSize: 20 }}
            />
          </Popconfirm>
        </Form.Item>
      </div>
    );
  }
);

FilterField.displayName = "FilterField";

FilterField.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  handleExternalAction: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  mode: PropTypes.string,
  popConfirmClassName: PropTypes.string,
  hasSearching: PropTypes.bool,
};

export default memo(FilterField);
