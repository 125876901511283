// import { Editor } from 'react-draft-wysiwyg';
import { useField } from "formik";
import { Form, Radio } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const RadioField = ({ name, options, onChange }) => {
  const [field, meta, helper] = useField(name);
  const isError = meta.touched && meta.error; // const isError = meta.touched && meta.error;

  const handleOnChange = (e) => {
    helper.setValue(e.target.value);
    if (typeof onChange === "function") {
      onChange(e.target.value);
    }
  };
  return (
    <div className="radio-field-container">
      <Form.Item
        validateStatus={isError ? "error" : ""}
        help={
          <div className="helper-wrapper">
            <div className="error-text">
              {isError && (
                <>
                  <InfoCircleOutlined
                    className="info-icon"
                    type="info-circle"
                  />
                  {meta.error}
                </>
              )}
            </div>
          </div>
        }
      >
        <Radio.Group value={field.value} onChange={handleOnChange}>
          {options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

RadioField.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};
export default RadioField;
