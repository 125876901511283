/* eslint-disable react/display-name */

import i18n from "i18n";
import moment from "moment";
import { Tag } from "antd";

const Columns = {};

const renderDataValue = (value) => {
  const style = value ? {} : { color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

const renderTransferValue = (value) => {
  switch (value) {
    case "cash":
      return (
        <Tag color="blue" style={{}}>
          {i18n.t("billingList.cash") || i18n.t("general.none")}
        </Tag>
      );
    case "Daibiki":
      return (
        <Tag color="green" style={{}}>
          {i18n.t("billingList.daibiki") || i18n.t("general.none")}
        </Tag>
      );
    case "transfer":
      return (
        <Tag color="purple" style={{}}>
          {i18n.t("billingList.transfer") || i18n.t("general.none")}
        </Tag>
      );
    default:
      return null;
  }
};

const renderBillStatus = (value) => {
  let style;
  switch (value) {
    case "new":
      style = { color: "#26415D", fontWeight: "bold" };
      return (
        <span style={style}>
          {i18n.t("billingList.new") || i18n.t("general.none")}
        </span>
      );
    case "cancel":
      style = { color: "#fff", fontWeight: "bold" };
      return (
        <div
          style={{
            backgroundColor: "red",
            width: "fit-content",
            padding: 5,
            borderRadius: 3,
          }}
        >
          <span style={style}>
            {i18n.t("billingList.cancel") || i18n.t("general.none")}
          </span>
        </div>
      );
    case "shipped":
      style = { color: "#FFA50E", fontWeight: "bold" };
      return (
        <span style={style}>
          {i18n.t("billingList.waitting") || i18n.t("general.none")}
        </span>
      );
    default:
      style = { color: "#0F9154", fontWeight: "bold" };
      return (
        <span style={style}>
          {i18n.t("billingList.ordered") || i18n.t("general.none")}
        </span>
      );
  }
};

const renderInterestRate = (value) => {
  const style = { color: value < 0 ? "red" : "green", fontWeight: "bold" };
  return (
    <span style={style}>
      {value.toLocaleString() || i18n.t("general.none")}
    </span>
  );
};
Columns.createdAt = {
  dataIndex: "createdAt",
  key: "createdAt",
  title: i18n.t("statisticalFonehouse.createdAt"),
  sorter: true,
  render: (createdAt) =>
    renderDataValue(moment(createdAt).format("DD/MM/YYYY")),
  width: 130,
};

Columns.id = {
  title: i18n.t("statisticalFonehouse.id"),
  dataIndex: "orderIdCustom",
  key: "orderIdCustom",
  sorter: true,
  render: (id) => (id ? renderDataValue(id) : renderDataValue("--/--")),
};

Columns.type = {
  title: i18n.t("statisticalFonehouse.type"),
  dataIndex: "transactionType",
  key: "transactionType",
  render: (transactionType = {}) => renderTransferValue(transactionType),
  width: 200,
};

Columns.status = {
  title: i18n.t("statisticalFonehouse.status"),
  dataIndex: "status",
  key: "status",
  render: (status = {}) => renderBillStatus(status),
  width: 200,
};

Columns.account = {
  title: i18n.t("statisticalFonehouse.account"),
  dataIndex: "name",
  key: "name",
  render: (name = {}) => renderDataValue(name),
  width: 200,
};

Columns.initialPrice = {
  title: i18n.t("statisticalFonehouse.initialPrice"),
  dataIndex: "initialPrice",
  key: "initialPrice",
  render: (initialPrice = {}) => renderDataValue(initialPrice.toLocaleString()),
  width: 200,
};

Columns.promotionPrice = {
  title: i18n.t("statisticalFonehouse.promotionPrice"),
  dataIndex: "promotionPrice",
  key: "promotionPrice",
  render: (promotionPrice = {}) =>
    renderDataValue(promotionPrice.toLocaleString()),
  width: 200,
};

Columns.interestRate = {
  title: i18n.t("statisticalFonehouse.interestRate"),
  dataIndex: "interest",
  key: "interest",
  render: (interest = {}) => renderInterestRate(interest),
  width: 200,
};

export default Columns;
