import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import _get from "lodash/get";
import _set from "lodash/set";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import Helper from "utils/helpers";
import moment from "moment";
import {
  getRestaurantProductListRequest,
  getRestaurantProductListSuccess,
  stopLoading,
  updateRestaurantProductRequest,
  updateRestaurantProductSuccess,
  getProductRestaurantTypeRequest,
  addProductRestaurantTypeRequest,
  createProductRestaurantRequest,
  updateProductRestaurantRequest,
  deleteProductRestaurantRequest,
  getProductRestaurantTypeSuccess,
  addProductRestaurantTypeSuccess,
  createProductRestaurantSuccess,
  updateProductRestaurantSuccess,
  deleteProductRestaurantSuccess,
  deleteProductRestaurantTypeRequest,
  deleteProductRestaurantTypeSuccess,
  editProductRestaurantTypeRequest,
  editProductRestaurantTypeSuccess,
  setTagProductRestaurantRequest,
  setTagProductRestaurantSuccess,
} from "./slice";

function* getRestaurantProductList(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "sortName",
    "sortMass",
    "sortPrice",
    "sortPromotionPrice",
    "productType",
    "restaurantId",
    "keyword",
  ]);
  params.storeId = params.restaurantId;
  delete params.restaurantId;
  const { data } = yield call(request, "api/products", params, {
    method: "GET",
  });
  yield put(getRestaurantProductListSuccess(data));
}

function* updateRestaurantProduct(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "sortName",
    "productType",
    "restaurantId",
  ]);
  params.storeId = params.restaurantId;
  delete params.restaurantId;
  const { data } = yield call(request, "api/products", params, {
    method: "PUT",
  });
  yield put(updateRestaurantProductSuccess(data));
}

export function* getProductRestaurantType(action) {
  const params = _pick(action.payload, [
    "restaurantId",
    "limit",
    "page",
    "keyword",
  ]);
  params.storeId = params.restaurantId;
  delete params.restaurantId;
  yield;
  const { data } = yield call(
    request,
    `api/products/categories?storeId=${params.storeId}`,
    undefined,
    { method: "GET" }
  );
  yield put(getProductRestaurantTypeSuccess(data));
}

export function* addProductRestaurantType(action) {
  const params = _pick(action.payload, ["name", "storeId"]);
  const { data } = yield call(request, "api/products/categories", params, {
    method: "POST",
  });
  yield put(addProductRestaurantTypeSuccess(data));
  Helper.toastr(
    `Loại:${_get(data, "name")}`,
    "Thêm loại sản phẩm thành công",
    "success"
  );
}

export function* deleteProductRestaurantType(action) {
  const params = _pick(action.payload, [
    "idProductRestaurantType",
    "restaurantId",
  ]);
  params.storeId = params.restaurantId;
  delete params.restaurantId;
  params.categoryIds = [params.idProductRestaurantType];
  delete params.idProductRestaurantType;
  yield call(request, "/products/categories", params, { method: "DELETE" });
  yield put(deleteProductRestaurantTypeSuccess(params));
  yield put(
    getProductRestaurantTypeRequest({
      storeId: params.storeId,
      limit: 1000,
      page: 1,
    })
  );
  const searchParams = _get(action, "payload.searchObject");
  yield put(getRestaurantProductListRequest(searchParams));
  Helper.toastr(
    `Loại:${_get(action, "payload.name")}`,
    "Xóa loại sản phẩm thành công",
    "success"
  );
}

function* createProductRestaurant(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "name",
    "percentagePromotion",
    "promotion",
    "price",
    "productType",
    "element",
    "images",
    "thumbnail",
    "fromDatePromotion",
    "toDatePromotion",
    "amount",
    "restaurantId",
  ]);
  params.storeId = params.restaurantId;
  delete params.restaurantId;
  params.productCategory = params.productType;
  delete params.productType;
  if (params.thumbnail) {
    _set(params, "thumbnail", params.thumbnail.url);
  }
  if (_isEmpty(params.fromDatePromotion)) {
    _set(params, "fromDatePromotion", moment());
  }
  if (_isEmpty(params.toDatePromotion)) {
    _set(params, "toDatePromotion", moment());
  }
  _set(params, "inputPrice", 0);
  _set(params, "images", []);
  if (Number(params.price) === 0) {
    _set(params, "promotion", 0);
    _set(params, "percentagePromotion", 0);
  }
  const { data } = yield call(request, "api/products", params, {
    method: "POST",
  });
  yield put(createProductRestaurantSuccess(data, meta));

  Helper.toastr(
    `Sản phẩm:${_get(data, "name")}`,
    "Tạo sản phẩm thành công",
    "success"
  );
}

function* updateProductRestaurant(action) {
  const { meta } = action;
  const params = _pick(action.payload, [
    "name",
    "currency",
    "percentagePromotion",
    "promotion",
    "price",
    "productCategory",
    "element",
    "images",
    "thumbnail",
    "fromDatePromotion",
    "toDatePromotion",
    "amount",
    "restaurantId",
    "productId",
  ]);
  if (params.thumbnail) {
    _set(params, "thumbnail", params.thumbnail.url);
  }
  if (params.promotion === 0 && params.percentagePromotion === 0) {
    _set(params, "fromDatePromotion", moment());

    _set(params, "toDatePromotion", moment());
  }
  _set(params, "inputPrice", 0);
  _set(params, "images", []);
  if (Number(params.price) === 0) {
    _set(params, "promotion", 0);
    _set(params, "percentagePromotion", 0);
  }
  const data = yield call(request, `api/products/${params.productId}`, params, {
    method: "PUT",
  });
  yield put(
    updateProductRestaurantSuccess({ ...params, _id: params.productId }, meta)
  );

  Helper.toastr(
    `Sản phẩm:${_get(data, "data.name")}`,
    "Cập nhật sản phẩm thành công",
    "success"
  );
}

function* deleteProductRestaurant(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["productIds", "storeId"]);
  const { data } = yield call(request, "api/products", params, {
    method: "DELETE",
  });
  yield put(deleteProductRestaurantSuccess(data, meta));
  Helper.toastr("Xóa sản phẩm thành công", "success");
}

export function* editProductRestaurantType(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idProductRestaurantType", "name"]);
  const { data } = yield call(
    request,
    "api/fonehouse/product-restaurant-manager/product-restaurant-type",
    params,
    { method: "PUT" }
  );
  yield put(editProductRestaurantTypeSuccess(data, meta));
  Helper.toastr(
    `Loại: ${_get(action, "payload.name")}`,
    "Cập nhật loại sản phẩm thành công",
    "success"
  );
}

function* setTagProductRestaurant(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["productIds", "tag", "storeId"]);
  yield call(request, "api/products/tags", params, { method: "PUT" });
  yield put(setTagProductRestaurantSuccess({}, meta));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchRestaurantProduct() {
  yield takeEvery(
    getRestaurantProductListRequest.type,
    safe(getRestaurantProductList, onError)
  );
  yield takeLeading(
    getProductRestaurantTypeRequest.type,
    safe(getProductRestaurantType, onError)
  );
  yield takeLeading(
    updateRestaurantProductRequest.type,
    safe(updateRestaurantProduct, onError)
  );
  yield takeLeading(
    addProductRestaurantTypeRequest.type,
    safe(addProductRestaurantType, onError)
  );
  yield takeLeading(
    createProductRestaurantRequest.type,
    safe(createProductRestaurant, onError)
  );
  yield takeLeading(
    updateProductRestaurantRequest.type,
    safe(updateProductRestaurant, onError)
  );
  yield takeLeading(
    deleteProductRestaurantRequest.type,
    safe(deleteProductRestaurant, onError)
  );
  yield takeLeading(
    deleteProductRestaurantTypeRequest.type,
    safe(deleteProductRestaurantType, onError)
  );
  yield takeLeading(
    editProductRestaurantTypeRequest.type,
    safe(editProductRestaurantType, onError)
  );
  yield takeLeading(
    setTagProductRestaurantRequest.type,
    safe(setTagProductRestaurant, onError)
  );
}
