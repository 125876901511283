import { object, string } from "yup";
import i18n from "i18n";
import moment from "moment";

export const createBillValidate = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
  transactionType: string().trim().required(i18n.t("errors.required")),
  postCode: string().trim().required(i18n.t("errors.required")),
  timeFrame: string().trim().required(i18n.t("errors.required")),
  orderFrom: string().trim().required(i18n.t("errors.required")),
  address: string().trim().required(i18n.t("errors.required")),
  orderIdCustom: string().trim().required(i18n.t("errors.required")),
  currency: string().trim().required(i18n.t("errors.required")),
  expectedDeliveryDate: string()
    .required(i18n.t("errors.required"))
    .test("is-date-format", i18n.t("errors.invalidDateFormat"), (value) => {
      if (!value) return true; // Let required handle empty values
      return moment(value, "DD-MM-YYYY", true).isValid();
    }),
});
export const cashBillType = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
  transactionType: string().trim().required(i18n.t("errors.required")),
  timeFrame: string().trim().required(i18n.t("errors.required")),
  orderFrom: string().trim().required(i18n.t("errors.required")),
  currency: string().trim().required(i18n.t("errors.required")),
});
