import PropTypes from "prop-types";
import { Popover, Empty } from "antd";
import _get from "lodash/get";
import { useSelector } from "react-redux";
import BellIcon from "assets/images/bell-icon.png";
import NotificationList from "containers/Dashboard/NotificationList";

import "./styles.less";

const NotificationPopover = ({ handleOpenPopover }) => {
  const unreadDataNumber = useSelector((state) =>
    _get(state, "notification.unreadDataNumber", 0)
  );
  const isOpenNotification = useSelector((state) =>
    _get(state, "notification.isOpenNotification")
  );

  const hidePopover = () => {
    handleOpenPopover(false);
  };

  return (
    <div>
      <Popover
        overlayClassName="notification-popover-wrapper"
        placement="bottom"
        title="THÔNG BÁO"
        trigger="click"
        visible={isOpenNotification}
        content={
          isOpenNotification ? (
            <NotificationList hidePopover={hidePopover} />
          ) : (
            <Empty />
          )
        }
        onVisibleChange={(e) => handleOpenPopover(e)}
      >
        <div className="notification-icon-wrapper">
          <div className="notification-icon">
            <img src={BellIcon} alt="" />
            {unreadDataNumber > 0 && (
              <div className="unread-number">
                {unreadDataNumber > 99 ? 99 : unreadDataNumber}
              </div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};
NotificationPopover.propTypes = {
  handleOpenPopover: PropTypes.func,
};
export default NotificationPopover;
