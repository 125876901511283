import { Menu } from "antd";
import { useDispatch } from "react-redux";
import _isEmpty from "lodash/isEmpty";

import {
  lockStoreRequest,
  unlockStoreRequest,
} from "providers/StoreProvider/slice";
import { setPopup } from "providers/GeneralProvider/slice";

const MenuAllOverlay = ({
  isAdminPermission,
  isDisableUnlockBtn,
  isDisableLockBtn,
  refetchList,
  selectedRowKeys,
  storeNameList,
}) => {
  const dispatch = useDispatch();

  if (!isAdminPermission) return <></>;

  return (
    <Menu>
      <Menu.Item
        className={
          isDisableLockBtn || _isEmpty(selectedRowKeys)
            ? ""
            : "close-bussiness-btn"
        }
        disabled={isDisableLockBtn || _isEmpty(selectedRowKeys)}
        onClick={() => {
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: "Xác nhận",
                cancelText: "Hủy",
                title: "Xác nhận khóa doanh nghiệp",
                content: `Bạn có muốn khóa ${storeNameList.join(", ")}`,
                onOk: () => {
                  dispatch(
                    lockStoreRequest({
                      storeIds: selectedRowKeys,
                    })
                  ).then(() => {
                    refetchList();
                  });
                },
              },
            })
          );
        }}
      >
        Khóa doanh nghiệp
      </Menu.Item>
      <Menu.Item
        className={
          isDisableUnlockBtn || _isEmpty(selectedRowKeys)
            ? ""
            : "unlock-bussiness-btn"
        }
        disabled={isDisableUnlockBtn || _isEmpty(selectedRowKeys)}
        onClick={() => {
          dispatch(
            setPopup({
              isOpen: true,
              data: {
                okText: "Xác nhận",
                cancelText: "Huy",
                title: "Xác nhận mở khóa doanh nghiệp",
                content: `Bạn có muốn mở khóa ${storeNameList.join(", ")}`,
                onOk: () => {
                  dispatch(
                    unlockStoreRequest({
                      storeIds: selectedRowKeys,
                    })
                  ).then(() => {
                    refetchList();
                  });
                },
              },
            })
          );
        }}
      >
        Mở khóa doanh nghiệp
      </Menu.Item>
    </Menu>
  );
};

export default MenuAllOverlay;
