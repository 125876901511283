import PropTypes from "prop-types";
import Badge from "antd/lib/badge";

import "./styles.less";

const statusText = {
  [false]: "Tất cả",
  [true]: "Đơn mới",
};

const TabHeaderFonehouseBill = ({ status, totalNewOrders }) => (
  <Badge
    count={status ? totalNewOrders : undefined}
    className="tab-header-wrapper"
  >
    <div>{statusText[status]}</div>
  </Badge>
);

TabHeaderFonehouseBill.propTypes = {
  status: PropTypes.string.isRequired,
  total: PropTypes.number,
  totalNewOrders: PropTypes.number,
};
export default TabHeaderFonehouseBill;
