import PropTypes from "prop-types";

import "./styles.less";

const statusText = {
  [false]: "Tất cả",
  [true]: "Đã xoá",
  active: "Tất cả",
  remove: "Đã xoá",
  sold: "Đã bán",
};

const TabHeader = ({ status }) => (
  <div className="tab-header-wrapper">
    <div>{statusText[status]}</div>
  </div>
);

TabHeader.propTypes = {
  status: PropTypes.string.isRequired,
  total: PropTypes.number,
};
export default TabHeader;
