import { createSlice } from "@reduxjs/toolkit";

const historyEditFonehouseSlice = createSlice({
  name: "historyEditFonehouse",
  initialState: {
    isLoading: false,
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    nextPage: null,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 5,
    totalPages: 1,
  },
  reducers: {
    getAllHistoryEditRequest(state) {
      return { ...state, isLoading: true };
    },
    getAllHistoryEditSuccess(state, action) {
      const { payload = {} } = action;
      const { histories, total } = payload;
      return {
        ...state,
        isLoading: false,
        docs: histories.map((doc) => ({ ...doc, key: doc._id })),
        totalDocs: total,
      };
    },
    stopLoading(state) {
      return { ...state, isLoading: false, isCreating: false };
    },
  },
});

export const {
  getAllHistoryEditRequest,
  getAllHistoryEditSuccess,
  stopLoading,
} = historyEditFonehouseSlice.actions;

export default historyEditFonehouseSlice.reducer;
