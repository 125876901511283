import { useRef, useMemo } from "react";
import { Row, Col, Button } from "antd";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { TextInput, ImageUpload, Select } from "components/common";
import { useSelector } from "react-redux";
import { createMachineSeries } from "./validate";

const CreateTypeForm = (props) => {
  const { handleCreateMachineSeries, productTypeList } = props;
  const isUpdateTypeLoading = useSelector(
    (state) => state.product.isUpdateTypeLoading
  );
  const formikRef = useRef();
  const formattedProductTypeList = useMemo(
    () =>
      productTypeList.map((productType) => ({
        value: productType._id,
        label: productType.name,
      })),
    [productTypeList]
  );

  const onSubmit = async (values, { resetForm }) => {
    await handleCreateMachineSeries(values);
    resetForm({});
  };

  return (
    <div className="create-machine-series-form-wrapper">
      <Formik
        innerRef={formikRef}
        initialValues={{ name: "", image: null, type: "", images: [] }}
        validationSchema={createMachineSeries}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <Form className="machine-series-form">
            <Row justify="space-between">
              <Col span={17} justify="space-between">
                <Select
                  name="type"
                  label="Tên loại"
                  required
                  options={formattedProductTypeList}
                />
                <TextInput name="name" label="Tên dòng" required />
              </Col>
              <Col
                span={6}
                justify="space-between"
                className="create-type-image"
              >
                <ImageUpload name="image" />
              </Col>
            </Row>
            <Row>
              <Button
                onClick={handleSubmit}
                type="primary"
                loading={isUpdateTypeLoading}
              >
                TẠO MỚI
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CreateTypeForm.propTypes = {
  handleCreateMachineSeries: PropTypes.func,
  productTypeList: PropTypes.array,
};

export default CreateTypeForm;
