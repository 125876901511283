/* eslint-disable react/display-name */

import i18n from "i18n";

const Columns = {};

const renderDataValue = (value, renderStyle = {}) => {
  const style = value ? renderStyle : { ...renderStyle, color: "#c0c0c0" };
  return <span style={style}>{value || i18n.t("general.none")}</span>;
};

Columns._id = {
  dataIndex: "_id",
  key: "_id",
  title: "#",
  render: (_id) => renderDataValue(`#${_id}`),
  width: 195,
};

Columns.username = {
  dataIndex: ["rater", "name"],
  key: "store",
  title: i18n.t("ratingComment.userName"),
  width: 150,
  render: (name) => renderDataValue(name),
};

Columns.storeName = {
  dataIndex: ["store", "ref", "name"],
  key: "store",
  title: i18n.t("ratingComment.storeName"),
  width: 150,
  render: (name) => renderDataValue(name),
};

Columns.type = {
  title: i18n.t("ratingComment.storeType"),
  dataIndex: ["store", "type"],
  key: "storeType",
  sorter: true,
  render: (type) => renderDataValue(type),
  width: 200,
};

Columns.address = {
  title: i18n.t("ratingComment.address"),
  dataIndex: ["store", "ref", "address"],
  key: "store.ref.address",
  sorter: true,
  render: (address) => renderDataValue(address),
  width: 200,
};
Columns.phone = {
  title: i18n.t("ratingComment.phone"),
  dataIndex: ["store", "ref", "phoneNumber"],
  key: "store.ref.phoneNumber",
  sorter: true,
  render: (phone) => renderDataValue(phone),
  width: 200,
};
Columns.rating = {
  title: i18n.t("ratingComment.rating"),
  dataIndex: "rating",
  key: "rating",
  sorter: true,
  render: (rating) => renderDataValue(rating),
  width: 200,
};

export default Columns;
