import PropTypes from "prop-types";
import _last from "lodash/last";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import { Button, TimePicker } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";

const CreateCustomWorkingTime = (props) => {
  const { workingTimeOfWeek, setWorkingTimeOfWeek } = props;
  const formatHours = "HH:mm";

  const handleChangeWorkingTimeOfWeek = (e) => {
    const workingTimeOfWeekUpdated = workingTimeOfWeek.map((item) => {
      if (e.day === item.key) {
        const timeWorkingUpdated = item.time.concat({ shift: e.shift });
        return { ...item, time: timeWorkingUpdated };
      }
      return item;
    });
    setWorkingTimeOfWeek(workingTimeOfWeekUpdated);
  };

  const handleRemoveWorkingTimeOfWeek = (e) => {
    const workingTimeOfWeekUpdated = workingTimeOfWeek.map((item) => {
      if (e.day === item.key) {
        const timeWorkingUpdated = item.time.filter((i) => i.shift !== e.shift);
        return { ...item, time: timeWorkingUpdated };
      }
      return item;
    });
    setWorkingTimeOfWeek(workingTimeOfWeekUpdated);
  };

  const handleOnchange = ({ e, day, shift }) => {
    const workingTimeOfWeekUpdated = workingTimeOfWeek.map((item) => {
      if (day === item.key) {
        const timeWorkingUpdated = item.time.map((i) => {
          if (shift === i.shift) {
            return {
              ...i,
              from: moment(e[0]).format(formatHours),
              to: moment(e[1]).format(formatHours),
            };
          }
          return i;
        });
        return { ...item, time: timeWorkingUpdated };
      }
      return item;
    });
    setWorkingTimeOfWeek(workingTimeOfWeekUpdated);
  };

  return (
    <div className="working-time-of-week-wrapper">
      {workingTimeOfWeek.map((day) => (
        <div key={day.key} className="working-time-of-day-container">
          <div className="working-time-of-day-label">{day.label} </div>
          <div className="working-time-of-day-value">
            {_isEmpty(day.time) ? (
              <div className="no-working-time-wrapper">
                <div className="close-time-label">
                  <ClockCircleOutlined /> Đóng cửa
                </div>
                <Button
                  onClick={() =>
                    handleChangeWorkingTimeOfWeek({ day: day.key, shift: 0 })
                  }
                  className="add-working-time-btn"
                  type="primary"
                >
                  +
                </Button>
              </div>
            ) : (
              <div className="day-working-time-wrapper">
                {day.time.map((item, index) => {
                  const beforeLastItem = _get(day, "time", []).slice(-2)[0];
                  const lastItem = _get(day, "time", []).slice(-1)[0];
                  let beforeLastItemTime = moment(
                    _get(beforeLastItem, "to", undefined)
                  );
                  if (
                    !beforeLastItemTime.isValid() &&
                    moment(
                      _get(beforeLastItem, "to", undefined),
                      formatHours
                    ).isValid()
                  ) {
                    beforeLastItemTime = moment(
                      _get(beforeLastItem, "to", undefined),
                      formatHours
                    );
                  }
                  const disableHours = Array.from(
                    Array(
                      Number(
                        beforeLastItemTime.format(formatHours).split(":")[0]
                      )
                    ).keys()
                  );
                  return (
                    <div
                      key={item.shift}
                      className="working-time-item-container"
                    >
                      <TimePicker.RangePicker
                        disabled={index !== day.time.length - 1}
                        disabledHours={() => (index === 0 ? [] : disableHours)}
                        onChange={(e) =>
                          handleOnchange({ e, day: day.key, shift: index })
                        }
                        format={formatHours}
                        defaultValue={
                          _isEmpty(item.from)
                            ? []
                            : [
                                moment(
                                  _get(item, "from"),
                                  formatHours
                                ).isValid()
                                  ? moment(_get(item, "from"), formatHours)
                                  : moment(_get(item, "from")),
                                moment(_get(item, "to"), formatHours).isValid()
                                  ? moment(_get(item, "to"), formatHours)
                                  : moment(_get(item, "to")),
                              ]
                        }
                        allowClear={false}
                      />
                      {index === 0 && (
                        <Button
                          disabled={_isEmpty(lastItem.from)}
                          onClick={() =>
                            handleChangeWorkingTimeOfWeek({
                              day: day.key,
                              shift: _last(day.time).shift + 1,
                            })
                          }
                          className="add-working-time-btn"
                          type="primary"
                        >
                          +
                        </Button>
                      )}
                      <Button
                        onClick={() =>
                          handleRemoveWorkingTimeOfWeek({
                            day: day.key,
                            shift: item.shift,
                          })
                        }
                        className="remove-working-time-btn"
                        danger
                        type="primary"
                      >
                        x
                      </Button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

CreateCustomWorkingTime.propTypes = {
  workingTimeOfWeek: PropTypes.array,
  setWorkingTimeOfWeek: PropTypes.func,
};

export default CreateCustomWorkingTime;
