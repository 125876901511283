import { useEffect } from "react";
import "./styles.less";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import { getBusinessByIdRequest } from "providers/BusinessProvider/slice";
import { useNavigate, useParams } from "react-router";
import i18n from "i18n";
import { Image, Empty, Button } from "antd";
import { ROLES_LIST, STORE_SUB_TYPE, WORKING_TIME_TEXT } from "utils/constants";
import {
  UserIcon,
  IconPhoneCycle,
  IconMailCycle,
  IconUserCycle,
  FollowersCycle,
  PlaceHolderIMG,
} from "assets/images";
import BackButton from "components/BackButton";
import StatusBlock from "components/StatusBlock";
import moment from "moment";
import { createConversationRequest } from "providers/MessengerProvider/slice";
import DOMPurify from "dompurify";

const RegisterBusinessDetail = () => {
  const dispatch = useDispatch();
  const { idBusiness } = useParams();
  const navigate = useNavigate();
  const businessDetail = useSelector((state) => _get(state, "business.detail"));
  const businessAvatar = _get(businessDetail, "avatar");
  const roles = _get(businessDetail, "idUser.roles", []);
  const idUser = _get(businessDetail, "idUser._id", []);
  const avatar = _get(businessDetail, "idUser.userDetail.avatar", "");
  const userName = _get(businessDetail, "idUser.name", "");
  const email = _get(businessDetail, "idUser.email", "");
  const phone = _get(businessDetail, "idUser.phone", "");
  const businessType = _get(businessDetail, "type", "");
  const status = _get(businessDetail, "status", "");
  const createdAt = _get(businessDetail, "idUser.createdAt", null);
  const type =
    ROLES_LIST.length > 0
      ? ROLES_LIST.find((item) => item.value === businessType)
      : "";
  useEffect(() => {
    dispatch(getBusinessByIdRequest({ idBusiness }));
  }, [idBusiness, dispatch]);
  const navigateToChat = (userId) => {
    dispatch(createConversationRequest({ receiverId: userId })).then(
      (result) => {
        navigate(`/cms_viego_support/viego_messengers/${result._id}`);
      }
    );
  };
  const renderItemInfoContact = (data) => {
    const { icon, name, value } = data;
    return (
      <div className="item-info-wrapper">
        <img src={icon} alt="" className="icon-info" />
        <div className="item-info-detail-wrapper">
          <div className="item-value">{value}</div>
          <div className="item-name">{name}</div>
        </div>
      </div>
    );
  };

  const renderUserInfoBasic = () => (
    <>
      <div className="user-info-basic-wrapper">
        <div className="info-basic-detail">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            className="user-avatar marginRight40"
          >
            <img src={avatar || UserIcon} alt="" />
            <Button
              onClick={() => navigateToChat(idUser)}
              style={{ backgroundColor: "#5C9FF9", border: "none" }}
              type="primary"
              size="small"
            >
              <div>
                <b>Nhắn tin</b>
              </div>
            </Button>
          </div>
          <div className="user-basic">
            {renderItemInfoContact({
              icon: IconUserCycle,
              name: "Username",
              value: userName ?? "---",
            })}
            {renderItemInfoContact({
              icon: IconMailCycle,
              name: "Email",
              value: email ?? "---",
            })}
            {renderItemInfoContact({
              icon: IconPhoneCycle,
              name: "Di động",
              value: phone || "---",
            })}
            {renderItemInfoContact({
              icon: FollowersCycle,
              name: "Tạo ngày",
              value: moment(createdAt).format("DD/MM/YYYY") ?? "---",
            })}
          </div>
        </div>
      </div>
    </>
  );

  const renderUserInfoDecentralization = () => (
    <div className="user-info-decentralization-wrapper">
      <div className="info-decentralization-title">
        {i18n.t("titleUserInfo.decentralization")}
      </div>
      <div className="info-decentralization-list">
        {!_isEmpty(roles) ? (
          <>
            {roles.map((role, index) => (
              <div key={index} className="item-role">
                <div className={`type-role ${role}-role`}></div>
                <div className="type-text">
                  {ROLES_LIST.find((item) => item.value === role)?.label}{" "}
                </div>
              </div>
            ))}
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </div>
  );

  const renderWorkingTime = (workingTimeMethod, workingTime) => {
    console.log(workingTimeMethod, workingTime);
    if (workingTimeMethod === "custom") {
      if (workingTime?.time) {
        return (workingTime?.time || []).map((time) => (
          <div key={`${time.from}-${time.to}`}>
            {time.from} - {time.to}
          </div>
        ));
      }
      if (workingTime?.alwaysOpen) {
        return "Luôn mở cửa";
      }
      if (workingTime?.alwaysClose) {
        return "Luon dong cua";
      }

      return null;
    }
    return WORKING_TIME_TEXT[workingTimeMethod] || "---";
  };
  const renderBusinessInformation = () => {
    const currentDay = new Date().getDay();
    const workingTimeMethod = _get(businessDetail, "workingTimeMethod");
    const workingTime = _get(businessDetail, "workingTime", []);
    const currentWorkingTime = workingTime[currentDay];
    return (
      <div>
        <div className="business-info-row-wrapper marginTop20">
          <div className="business-item">
            <div className>{i18n.t("register.businessName")}</div>
            <div className="business-value">{businessDetail.name ?? "---"}</div>
          </div>
          <div className="business-item paddingHorizon20 row">
            <div>
              <div>{i18n.t("register.businessType")}</div>
              <div className="item-role">
                <div className={`type-role ${_get(type, "value")}-role`}></div>
                <div className="type-text">
                  <strong>{_get(type, "label")}</strong>
                </div>
              </div>
            </div>
            <div>
              <div>{i18n.t("register.status")}</div>
              <div className="business-value">
                <StatusBlock status={status} showText />
              </div>
            </div>
          </div>
          <div className="business-item">
            <div>{i18n.t("register.businessPhone")}</div>
            <div className="business-value">
              {_get(businessDetail, "phoneNumber", "---")}
            </div>
          </div>
        </div>
        <div className="business-info-row-wrapper marginTop20 marginBottom20">
          <div className="business-item">
            <div className>Loại cửa hàng</div>
            <div className="business-value">
              {STORE_SUB_TYPE[businessDetail?.storeType?.toUpperCase()] ||
                "---"}
            </div>
          </div>
          <div className="business-item paddingHorizon20">
            <div className>Giờ làm việc</div>
            <div className="business-value">
              {renderWorkingTime(workingTimeMethod, currentWorkingTime)}
            </div>
          </div>
          <div className="business-item">
            <div>Địa chỉ</div>
            <div className="business-value">
              {businessDetail.address ?? "---"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBanner = (banner, title, className) => (
    <div>
      <div className={`business-banner-wrapper ${className}`}>
        <div className="marginBottom20">{title}</div>
        <Image fallback={PlaceHolderIMG} src={banner} />
      </div>
    </div>
  );

  const renderImages = (arr, title) => {
    const array = arr ?? [];
    return array.map((item, index) => (
      <div className="marginTop20" key={index}>
        <div className="business-banner-wrapper">
          <div className="marginBottom20">{title}</div>
          <Image fallback={PlaceHolderIMG} src={item} />
        </div>
      </div>
    ));
  };

  const renderNote = () => {
    const sanitizedNote = DOMPurify.sanitize(businessDetail.note || "---");

    // Custom sanitization function to add inline styles to img tags
    const addStylesToImages = (html) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const images = Array.from(doc.getElementsByTagName("img"));

      images.forEach((img) => {
        img.style.width = "100%";
        img.style.height = "auto";
        img.style.objectFit = "contain";
        img.style.maxHeight = "500px";
      });

      return doc.body.innerHTML;
    };

    const noteWithStyledImages = addStylesToImages(sanitizedNote);

    return (
      <div className="marginTop20">
        <div className="business-banner-wrapper">
          <div className="marginBottom20">{i18n.t("register.note")}</div>
          <div dangerouslySetInnerHTML={{ __html: noteWithStyledImages }} />
        </div>
      </div>
    );
  };

  const renderFile = (arr, title) => {
    const array = arr ?? [];
    return array.map((item, index) => (
      <div key={index} className="marginTop20">
        <div className="business-banner-wrapper">
          <div className="marginBottom20">{title}</div>
          <a target="_blank" href={item} rel="noopener noreferrer">
            {item}
          </a>
        </div>
      </div>
    ));
  };
  return (
    <div className="business-info-wrapper">
      <div className="flex">
        <div className="user-info-header">
          <BackButton className="backButton" />
          {i18n.t("register.headerTitle")}
        </div>
      </div>
      <div className="user-info-follower-decentralization-wrapper">
        {renderUserInfoBasic()}
        {renderUserInfoDecentralization()}
      </div>
      {renderBusinessInformation()}
      {businessAvatar &&
        renderBanner(
          businessAvatar,
          i18n.t("register.avatar"),
          "marginBottom20"
        )}
      {businessDetail.banner &&
        renderBanner(businessDetail.banner, i18n.t("register.banner"))}
      {renderImages(
        businessDetail.businessLicensesImages,
        i18n.t("register.businessLicenses")
      )}
      {renderImages(
        businessDetail.businessImages,
        i18n.t("register.businessImages")
      )}
      {renderImages(
        businessDetail.identityCard,
        i18n.t("register.identityCard")
      )}
      {renderFile(businessDetail.businessLicenses, i18n.t("register.file"))}
      {renderNote()}
    </div>
  );
};

export default RegisterBusinessDetail;
const styles = `
  .note-content img {
    width: 200px;
    height: auto;
    object-fit: cover;
    max-height: 500px; /* Adjust this value as needed */
  }
`;
