import PropTypes from "prop-types";
import { CloseSquareOutlined } from "@ant-design/icons";

const ProductTypeItem = (props) => {
  const {
    item,
    selectedItem,
    itemId,
    handleDeleteTypeProduct,
    handleSelectedItem,
  } = props;
  const isSelectedItem = selectedItem._id === itemId;

  return (
    <div
      className={`product-type-item-wrapper ${
        isSelectedItem ? "item-selected" : ""
      }`}
    >
      {isSelectedItem && (
        <CloseSquareOutlined
          className="icon-close"
          onClick={() => handleDeleteTypeProduct(item)}
        />
      )}
      <div
        className="handle-item-wrapper"
        onClick={() => handleSelectedItem(item)}
        title={item.name}
      >
        <div className="type-image">
          <img src={item.image} />{" "}
        </div>
        <div className="type-name ellipsis-text-one-line">{item.name} </div>
      </div>
    </div>
  );
};

ProductTypeItem.propTypes = {
  item: PropTypes.object,
  selectedItem: PropTypes.string,
  itemId: PropTypes.string,
  handleDeleteTypeProduct: PropTypes.func,
  handleSelectedItem: PropTypes.func,
};

export default ProductTypeItem;
