import { createSlice } from "@reduxjs/toolkit";

const generalSlice = createSlice({
  name: "general",
  initialState: {
    collapseMenu: false,
    currentUser: {},
    modal: {
      isOpen: false,
      data: {},
    },
    workingTimeModal: {
      isOpen: false,
    },
    storeOnwerModal: {
      isOpen: false,
    },
  },
  reducers: {
    toggleCollapseMenu(state, action) {
      return { ...state, collapseMenu: action.payload };
    },
    setPopup(state, action) {
      state.modal.isOpen = action.payload.isOpen;
      if (action.payload.data) {
        state.modal.data = action.payload.data;
      }
      return state;
    },
    setChangeWorkingTimeModal(state, action) {
      return {
        ...state,
        workingTimeModal: {
          isOpen: action.payload.isOpen,
          data: action.payload.data,
          category: action.payload.category,
        },
      };
    },
    setChangeStoreOnwerModal(state, action) {
      return {
        ...state,
        storeOnwerModal: {
          isOpen: action.payload.isOpen,
        },
      };
    },
  },
});

export const {
  toggleCollapseMenu,
  setPopup,
  setChangeWorkingTimeModal,
  setChangeStoreOnwerModal,
} = generalSlice.actions;

export default generalSlice.reducer;
