import { object, string, number, array } from "yup";
import i18n from "i18n";

export const createProductValidate = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
  state: string().trim().required(i18n.t("errors.required")),
  ram: number()
    .typeError(i18n.t("errors.invalid"))
    .min(1, i18n.t("errors.invalid")),
  amount: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(0, i18n.t("errors.invalid"))
    .required(i18n.t("errors.required")),
  imei: string().trim().nullable().required(i18n.t("errors.required")),
  battery: string().trim().nullable(),
  memory: string().trim().nullable(),
  entryDate: string().nullable().required(i18n.t("errors.required")),
  accessories: string().trim().nullable(),
  guarantee: string().required(i18n.t("errors.required")),
  address: string().trim().required(i18n.t("errors.required")),
  contact: string().trim().nullable().required(i18n.t("errors.required")),
  type: string().required(i18n.t("errors.required")),
  currency: string().required(i18n.t("errors.required")),
  price: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(0, i18n.t("errors.invalid"))
    .required(i18n.t("errors.required")),
  inputPrice: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(0, i18n.t("errors.invalid"))
    .required(i18n.t("errors.required")),
  percentagePromotion: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(0, i18n.t("errors.invalid"))
    .max(100, i18n.t("errors.invalid"))
    .nullable(),
  promotionPrice: number()
    .typeError(i18n.t("errors.invalid"))
    .integer(i18n.t("errors.invalid"))
    .min(0, i18n.t("errors.invalid")),
  description: string().trim().nullable(),
  images: array().min(1, i18n.t("errors.required")),
  howToBuy: string().trim().nullable(),
  line: string().required(i18n.t("errors.required")),
  videos: array().min(0, i18n.t("errors.required")),
  manufacture: string().trim().nullable().required(i18n.t("errors.required")),
  tag: string().trim().required(i18n.t("errors.required")),
});
