import PropTypes from "prop-types";
import CreateTypeForm from "./CreateTypeForm";
import CreateMachineSeriesForm from "./CreateMachineSeriesForm";

const ProductTypeForm = ({
  handleCreateNewType,
  handleCreateMachineSeries,
  productTypeList,
}) => (
  <>
    <CreateTypeForm handleCreateNewType={handleCreateNewType} />
    <CreateMachineSeriesForm
      handleCreateMachineSeries={handleCreateMachineSeries}
      productTypeList={productTypeList}
    />
  </>
);

ProductTypeForm.propTypes = {
  handleCreateNewType: PropTypes.func,
  handleCreateMachineSeries: PropTypes.func,
  productTypeList: PropTypes.array,
};

export default ProductTypeForm;
