import { Button, Col, Row } from "antd";
import _get from "lodash/get";
import PropTypes from "prop-types";
import { UserIcon } from "assets/images";
import { useSelector, useDispatch } from "react-redux";
import ChangeStoreOnwerModal from "components/ChangeStoreOnwerModal";
import { setChangeStoreOnwerModal } from "providers/GeneralProvider/slice";
import "./styles.less";
import { ROLES } from "utils/constants";

const ArticleShopOwner = ({
  shopOwnerInfoDetail,
  category,
  handleChangeStoreOnwer,
}) => {
  const dispatch = useDispatch();
  const { userDetail, address, phone, email, name } = shopOwnerInfoDetail;
  const currentUserRole = useSelector((state) => state.auth.currentUser.role);
  const isOpen = useSelector((state) => state.general.storeOnwerModal.isOpen);

  const handleChangeStoreOnwerModal = () => {
    dispatch(setChangeStoreOnwerModal({ isOpen: true }));
  };

  const renderInfoDetail = (title, content) => (
    <div className="item-info-detail">
      <div className="title">{title}:</div>
      <div className="content">{content}</div>
    </div>
  );

  return (
    <div className="article-shop-owner-wrapper">
      {isOpen && (
        <ChangeStoreOnwerModal
          handleChangeStoreOnwer={handleChangeStoreOnwer}
          isOpen={isOpen}
          category={category}
        />
      )}
      <Row className="article-manager-shop-container">
        <div className="manager-shop-title">Quản lý cửa hàng </div>
        {[ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(currentUserRole) && (
          <Button
            onClick={handleChangeStoreOnwerModal}
            type="primary"
            size="small"
            className="change-manager-shop-btn"
          >
            THAY ĐỔi
          </Button>
        )}
      </Row>
      <Row className="shop-owner-info-wrapper">
        <Col className="shop-owener-avatar-container">
          <div className="user-avatar marginRight40">
            <img src={_get(userDetail, "avatarThumb", "") || UserIcon} alt="" />
          </div>
        </Col>
        <Col className="shop-owner-info-detail-container">
          <Row className="shop-owner-name-email-phone-container">
            {renderInfoDetail("Họ và tên", name)}
            {renderInfoDetail("Email", email)}
            {renderInfoDetail("Số điện thoại", phone)}
          </Row>
          <Row className="shop-owner-address-container">
            {renderInfoDetail("Địa chỉ", address)}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
ArticleShopOwner.propTypes = {
  shopOwnerInfoDetail: PropTypes.object,
  category: PropTypes.string,
  handleChangeStoreOnwer: PropTypes.func,
};

export default ArticleShopOwner;
