import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import "./styles.less";

const CategoryTooltip = ({ active }) => {
  const productTypes = useSelector((state) => state.product.productTypes);
  return (
    <div className="category-tooltip-wrapper">
      {productTypes.map((type) => (
        <Row key={type._id}>
          <Col className={`tootltip-text ${active === type._id ? "bold" : ""}`}>
            {type.name}
          </Col>
        </Row>
      ))}
    </div>
  );
};

CategoryTooltip.propTypes = {
  active: PropTypes.string,
};
export default CategoryTooltip;
