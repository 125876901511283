import { Empty } from "antd";
import "./style.less";

const NotFound = () => (
  <div className="container-not-found">
    <Empty />
  </div>
);

export default NotFound;
