import { put, takeLeading, call } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import _get from "lodash/get";
import { safe } from "providers/GeneralProvider/saga";
import {
  stopLoading,
  getListIdentifyUserRequest,
  getListIdentifyUserSuccess,
  updateIdentifyUserStatusSuccess,
  updateIdentifyUserStatusRequest,
} from "providers/IdentifyUserProvider/slice";

function* getListIdentify(action) {
  const params = _pick(action.payload, ["page", "limit"]);
  params.verify = action.payload.type !== "WAITING";
  const { data } = yield call(
    request,
    "/api/admin/userManager/getAllUser",
    params,
    { method: "GET" }
  );
  yield put(getListIdentifyUserSuccess(data));
}
function* updateIdentifyUserStatus(action) {
  const { meta } = action;
  const idUser = _get(action, "payload.idUser");
  const actionIdentify = _get(action, "payload.action");

  yield call(
    request,
    `/api/admin/userManager/updateUserIdentity`,
    {
      idUser,
      action: actionIdentify,
    },
    {
      method: "PUT",
    }
  );
  yield put(updateIdentifyUserStatusSuccess(meta));
}

function* onError() {
  yield put(stopLoading());
}

export default function* watchIdentify() {
  yield takeLeading(
    getListIdentifyUserRequest.type,
    safe(getListIdentify, onError)
  );
  yield takeLeading(
    updateIdentifyUserStatusRequest.type,
    safe(updateIdentifyUserStatus, onError)
  );
}
