import { memo } from "react";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import SearchBarComponent from "components/SearchBar";
import { TextInput } from "components/common/Form";

const SearchBar = () => (
  <SearchBarComponent filterFields={["keyword"]} isOnChangeSearch>
    <>
      <div style={{ width: "100%", marginRight: 10 }}>
        <TextInput
          styles={{ marginRight: 8 }}
          prefix={<SearchOutlined />}
          name="keyword"
          allowClear={false}
        />
      </div>
    </>
  </SearchBarComponent>
);

SearchBar.propTypes = {
  name: PropTypes.string,
  companyValue: PropTypes.string,
};
export default memo(SearchBar);
