import { useSelector, useDispatch } from "react-redux";
import { useEffect, memo } from "react";
import { getRewardBalanceRequest } from "providers/RewardPointManager/slice";
import _get from "lodash/get";

const CURRENT_POINTS = "Điểm hiện có:";
const STORE_LIST_POINTS = "Danh sách điểm cửa hàng hiện có:";

const RewardBalanceSection = () => {
  const dispatch = useDispatch();
  const rewardPointTabType = useSelector((state) =>
    _get(
      state,
      "rewardPointManagerSlice.rewardPointTabType",
      "POINT_PERSONAL_MANAGE"
    )
  );
  const personalPoints = useSelector((state) =>
    _get(state, "rewardPointManagerSlice.personalPoints", 0)
  );
  const storePoints = useSelector((state) =>
    _get(state, "rewardPointManagerSlice.storePoints", [])
  );

  useEffect(() => {
    dispatch(getRewardBalanceRequest());
  }, []);

  return (
    <div>
      {rewardPointTabType === "POINT_PERSONAL_MANAGE" && (
        <span>{CURRENT_POINTS}</span>
      )}
      {rewardPointTabType === "POINT_STORE_MANAGE" && (
        <span>{STORE_LIST_POINTS}</span>
      )}
      {rewardPointTabType === "POINT_PERSONAL_MANAGE" && (
        <span style={{ color: "green", paddingLeft: 5 }}>
          {personalPoints.toLocaleString()}
        </span>
      )}
      {rewardPointTabType === "POINT_STORE_MANAGE" &&
        storePoints.map((item) => (
          <div style={{ marginLeft: 10 }} key={item.storeId}>
            <span>- {item.name}:</span>
            <span style={{ color: "green", paddingLeft: 5 }}>
              {item.account.balance.toLocaleString()}
            </span>
          </div>
        ))}
    </div>
  );
};

export default memo(RewardBalanceSection);
