import { put, call, takeEvery, takeLeading } from "redux-saga/effects";
import request from "utils/api";
import _pick from "lodash/pick";
import _isEmpty from "lodash/isEmpty";
import { safe } from "providers/GeneralProvider/saga";
import Helper from "utils/helpers";
import {
  getAdminManagerListRequest,
  updateChatAdminRequest,
  updateAccuracyRequest,
  getAdminManagerListSuccess,
  stopLoading,
  updateChatAdminSuccess,
  updateAccuracySuccess,
} from "./slice";

function* getAdminManagerList(action) {
  const params = _pick(action.payload, [
    "page",
    "limit",
    "keyword",
    "roles",
    "sortTitle",
    "status",
    "sortName",
    "sortEmail",
  ]);
  if (!_isEmpty(params.keyword)) {
    params.keyword =
      Helper.sortStringWithoutSpecialChars(params.keyword) || undefined;
  }
  const { data } = yield call(
    request,
    "api/admin/userManager/getAllAdmin",
    params,
    { method: "GET" }
  );
  yield put(getAdminManagerListSuccess(data));
}

function* updateChatAdmin(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idUser", "roleChat"]);
  const { data } = yield call(
    request,
    "api/admin/userManager/update-chat-admin",
    params,
    { method: "PUT" }
  );
  yield put(updateChatAdminSuccess(data, meta));
  Helper.toastr(`ID:${params.idUser}`, "Cấp quyền thành công", "success");
}
function* updateAccuracy(action) {
  const { meta } = action;
  const params = _pick(action.payload, ["idUser", "role"]);
  const { data } = yield call(
    request,
    "api/admin/userManager/updateUserRole",
    params,
    { method: "PUT" }
  );
  yield put(updateAccuracySuccess(data, meta));
  Helper.toastr(`ID:${params.idUser}`, "Cấp quyền thành công", "success");
}
function* onError() {
  yield put(stopLoading());
}

export default function* watchAdminManager() {
  yield takeEvery(
    getAdminManagerListRequest.type,
    safe(getAdminManagerList, onError)
  );
  yield takeLeading(updateAccuracyRequest.type, safe(updateAccuracy, onError));
  yield takeLeading(
    updateChatAdminRequest.type,
    safe(updateChatAdmin, onError)
  );
}
