import { object, string } from "yup";
import i18n from "i18n";

export const editCosmeticValidate = object().shape({
  name: string().trim().required(i18n.t("errors.required")),
  address: string().trim().required(i18n.t("errors.required")),
  phone: string(),
  thumbnail: object().nullable().required(i18n.t("errors.required")),
  subType: string().trim().required(i18n.t("errors.required")),
});

export const createCosmeticValidate = editCosmeticValidate.shape({
  banners: object().nullable().required(i18n.t("errors.required")),
});
